import { LanguageState, LanguageActionTypes } from './languages/types';
import { TranslationsState, TranslationActionTypes } from './translations/types';

export const UPDATE_INTERNATIONALIZATION_DATA = 'UPDATE_INTERNATIONALIZATION_DATA';

export interface InternationalizationState {
    languages: LanguageState,
    translations: TranslationsState
}

interface IUpdateInternationalizationData {
    type: typeof UPDATE_INTERNATIONALIZATION_DATA,
    data: InternationalizationState,
}

export type InternationalizationActionTypes = LanguageActionTypes | TranslationActionTypes | IUpdateInternationalizationData;
import { SELECT_GROUP_TYPE_ACTION, UN_SELECT_GROUP_TYPE_ACTION, RE_ORDER_GROUP_TYPE_ACTIONS, ADD_GROUP_TYPE_ACTION, DELETE_GROUP_TYPE_ACTION, UPDATE_GROUP_TYPE_ACTION, INewGroupTypeActionData, IUpdateableGroupTypeActionData, SelectGroupTypeActionAction, UnSelectGroupTypeActionAction, ReOrderGroupTypeActionAction, AddGroupTypeActionAction, DeleteGroupTypeActionAction, UpdateGroupTypeActionAction, InstantiateGroupTypeActionAction, INSTANTIATE_GROUP_TYPE_ACTION } from './types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export function selectGroupTypeAction(id: string): SelectGroupTypeActionAction {
    return {
        type: SELECT_GROUP_TYPE_ACTION,
        id,
    }
}

export function unSelectGroupTypeAction(): UnSelectGroupTypeActionAction {
    return {
        type: UN_SELECT_GROUP_TYPE_ACTION,
    }
}

export function reOrderGroupTypeActions(sourceIndex: number, destinationIndex: number, parentId: string): ReOrderGroupTypeActionAction {
    return {
        type: RE_ORDER_GROUP_TYPE_ACTIONS,
        sourceIndex,
        destinationIndex,
        parentId,
    }
}

export function addGroupTypeAction(payload: INewGroupTypeActionData, parentId: string): AddGroupTypeActionAction {
    const now = moment.utc().format();

    return {
        type: ADD_GROUP_TYPE_ACTION,
        payload: {
            id: uuidv4(),
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            parentId,
        },
        parentId,
        currentTime: now,
    }
}

export function instantiateGroupTypeAction(payload: IUpdateableGroupTypeActionData, parentId: string): InstantiateGroupTypeActionAction {
    const now = moment.utc().format();

    return {
        type: INSTANTIATE_GROUP_TYPE_ACTION,
        payload: {
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            parentId,
        },
        parentId,
        currentTime: now,
    }
}

export function deleteGroupTypeAction(id: string, parentId: string): DeleteGroupTypeActionAction {
    return {
        type: DELETE_GROUP_TYPE_ACTION,
        id,
        parentId,
        currentTime: moment.utc().format(),
    }
}

export function updateGroupTypeAction(payload: IUpdateableGroupTypeActionData): UpdateGroupTypeActionAction {
    return {
        type: UPDATE_GROUP_TYPE_ACTION,
        payload,
        currentTime: moment.utc().format(),
    }
}
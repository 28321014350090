import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { IGroup, GroupState } from 'src/shared/store/groups/types';
import { IMember, MemberState } from '../../shared/store/members/types';
import { getReadableDataForCustomField } from 'src/shared/store/custom-fields';
import { DefaultFlowchartProcessState } from 'src/shared/store/flowchart/types';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { getMemberComputedFieldValue } from 'src/shared/store/flowchart/helpers/custom-fields/member';
import { NgRedux } from '@angular-redux/store';
import { ApplicationState } from '../../shared/store/types';
import { updateGroupRequest } from 'src/shared/store/groups/actions';
import { translatePhrase } from 'src/shared/helpers/translation';
import { VariableValueType } from 'src/shared/helpers/common-types';
import { ToastService, ToastType } from '../services/toast.service';

@Component({
    selector: 'app-toggle-group-members',
    templateUrl: './toggle-group-members.page.html',
    styleUrls: ['./toggle-group-members.page.scss'],
})
export class ToggleGroupMembersPage implements OnInit, OnDestroy {
    @select('members') MembersDataSource: Observable<MemberState>;
    @select(state => state) applicationStateSource: Observable<ApplicationState>;
    applicationState: ApplicationState;
    membersData: MemberState;
	groupsData: GroupState;
    group_details: IGroup;
    all_groups: Array<IGroup> = [];
    all_members: Array<IMember>;
    applicationStateSubscription: Subscription;
	memberSearchTerm: string = '';

    constructor(
        private modalController: ModalController,
        private navParams: NavParams,
        private ngRedux: NgRedux<ApplicationState>,
        private toastService: ToastService
    ) { }

	translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

    ngOnInit() {
        let group_id: string = this.navParams.get('group_id');

        this.applicationStateSubscription = this.applicationStateSource.subscribe(applicationState => {
			this.applicationState = applicationState;

			this.membersData = this.applicationState.members;

			this.groupsData = this.applicationState.groups;

            this.groupsData.allEntries.map((groupId) => {
                this.all_groups.push(this.groupsData.byId[groupId]);
            });

            this.group_details = this.all_groups.find((group) => {
                return group.id == group_id;
            });

            this.all_members = this.membersData.allEntries.map(memberId => this.membersData.byId[memberId]);
		});
    }

	searchMembers() {
		let all_members: Array<IMember> = this.membersData.allEntries.map(memberId => this.membersData.byId[memberId]);
		this.all_members = all_members.filter((member) => {
			return this.getMemberName(member.id).toString().toLowerCase().includes(this.memberSearchTerm.toLowerCase());
		});
	}

    getMemberName(memberId: string) {
        const member = this.membersData.byId[memberId];
        const memberType = this.membersData.types.byId[member.type];
        let memberName: VariableValueType;

        const nameField = this.membersData.types.customFields.byId[memberType.nameFieldId];

        if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
            const processState: DefaultFlowchartProcessState = {
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                customFields: {...member.customFields},
                variables: {
                    [nameField.seedEntityVariable]: member.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
            };

            memberName = getMemberComputedFieldValue(this.applicationState, processState, nameField.startPiece.piece, member.id, nameField);
        } else {
            memberName = member.customFields[memberType.nameFieldId];
        }

        if (Array.isArray(memberName)) {

            if (memberName.length > 0 && Array.isArray(memberName[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            memberName = memberName as Array<string>;
        }

        memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

        return memberName;
    }

    isMember(id: string) {
        if (this.group_details.members.includes(id)) {
            return true;
        } else {
            return false;
        }
    }

    isRepresentative(id: string) {
        if (this.group_details.representatives.includes(id)) {
            return true;
        } else {
            return false;
        }
    }

    toggleGroupMember(id: string) {
        if (this.group_details.members.includes(id)) {
            let index = this.group_details.members.indexOf(id);
            this.group_details.members.splice(index, 1);

            if (this.group_details.representatives.includes(id)) {
                let rep_index = this.group_details.representatives.indexOf(id);
                this.group_details.representatives.splice(rep_index, 1);
            }
        } else {
            if (this.group_details.members.length === 20) {
                this.toastService.presentToastWithOptions("Cannot add more than 20 Members to the group", ToastType.INFO);
            } else {
                this.group_details.members.push(id);
            }
        }
    }

    toggleGroupRepresentative(id: string) {
        if (this.group_details.representatives.includes(id)) {
            let index = this.group_details.representatives.indexOf(id);
            this.group_details.representatives.splice(index, 1);
        } else {
            if (this.group_details.members.includes(id)) {
                this.group_details.representatives.push(id);
            }
        }
    }

    save () {
        this.ngRedux.dispatch(updateGroupRequest(this.group_details));
        this.back();
    }

    back() {
        if (this.applicationStateSubscription) {
            this.applicationStateSubscription.unsubscribe();
        }

        this.modalController.dismiss();
    }

    ngOnDestroy() {
        if (this.applicationStateSubscription) {
            this.applicationStateSubscription.unsubscribe();
        }
    }

}

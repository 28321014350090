import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { IMember, MemberState } from '../../shared/store/members/types';
import { ILocation } from '../../shared/store/structure/location/types';
import { GroupDetailsPage } from '../group-details/group-details.page';
import { IWorkflow, IUpdateableWorkflowData, WorkflowState } from '../../shared/store/workflows/types';
import { IWorkflowType } from '../../shared/store/workflows/types/types';
import { IStatus } from '../../shared/store/workflows/types/statuses/types';
import { v4 as uuid } from 'uuid';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { ActionSheetController } from '@ionic/angular';
import { addWorkflow } from '../../shared/store/workflows/actions';
import * as moment from 'moment';
import { AlertController } from '@ionic/angular';
import { WorkflowExecutorPage } from '../workflow-executor/workflow-executor.page';
import { getReadableDataForCustomField } from 'src/shared/store/custom-fields';
import { CustomFieldValueType, IUpdateableCustomFieldData } from 'src/shared/store/custom-fields/types';
import { getMemberComputedFieldValue } from 'src/shared/store/flowchart/helpers/custom-fields/member';
import { NgRedux } from '@angular-redux/store';
import { ApplicationState } from 'src/shared/store/types';
import { DefaultFlowchartProcessState } from 'src/shared/store/flowchart/types';
import { updateDueDate } from '../../shared/store/workflows/actions';
import { translatePhrase } from 'src/shared/helpers/translation';
import { PermissionSet, Permissions } from 'src/shared/store/permissions/types';
import { AddOrEditMemberPage } from '../add-or-edit-member/add-or-edit-member.page';
import { GroupState, IGroup } from 'src/shared/store/groups/types';
import { ShareService } from '../services/share.service';
import { ToastService, ToastType } from '../services/toast.service';
import { AddWorkflowPage } from '../add-workflow/add-workflow.page';
import { getVisibleGroupIds, getVisibleWorkflowIds } from 'src/shared/helpers/visible-entities';
import { ICard } from '../models/list-card';
import isValidCoordinates from 'is-valid-coordinates';
import { completionPercentageOfWorkflow } from 'src/shared/store/flowchart/helpers/progress';
import { VariableValueType } from 'src/shared/helpers/common-types';

@Component({
	selector: 'app-member-profile-details',
	templateUrl: './member-profile-details.page.html',
	styleUrls: ['./member-profile-details.page.scss'],
})
export class MemberProfileDetailsPage implements OnInit, OnDestroy {
	@select(state => state) applicationStateSource: Observable<ApplicationState>;

	@ViewChild('clipboardInput') clipboardInput: ElementRef;

	isReadOnly: boolean;

	my_id: string;
	applicationState: ApplicationState;
	membersData: MemberState;
	workflowsData: WorkflowState;
	groupsData: GroupState;
	selected_member: IMember;
	selectedFireWorkflowType: IWorkflowType;
	locations: Array<ILocation> = [];
	temp_variables = {
		calculated_location: {}
	};
	workflows: Array<IWorkflow> = [];
	selected_workflow: IWorkflow;
	workflow_types: Array<IWorkflowType> = [];
	allowed_statuses: Array<IStatus> = [];
	affiliated_workflow_types: Array<IWorkflowType> = [];
	affiliated_group_workflow_types: Array<IWorkflowType> = [];
	new_workflow: IUpdateableWorkflowData;
	show_popup: boolean = false;
	customFields: Array<IUpdateableCustomFieldData> = [];
	applicationStateSubscription: Subscription;
	phoneFieldId: string;
	userPermission: PermissionSet;
	groupList: Array<IGroup> = [];
	selected_group: IGroup;
	selected_affiliated_group: IGroup;
	show_group_popup: boolean = false;
	isEditWorkflowPresent: boolean = false;

	selectedDataToCopy: string = '';

	workflowCardList: Array<ICard> = [];

	selectedWorkflowForDueDateChange: {
		id: string,
		preSelectedDate: string
	} = {
			id: null,
			preSelectedDate: null
		}

	constructor(
		public modalController: ModalController,
		private navParams: NavParams,
		public actionSheetController: ActionSheetController,
		private ngRedux: NgRedux<ApplicationState>,
		public alertController: AlertController,
		public shareService: ShareService,
		public toastService: ToastService
	) { }

	isPhoneNumber(value: any) {
		try {
			if ((value.split(' ')[0] && value.split(' ')[1]) && (value.split(' ')[0].includes('+') && value.split(' ')[1].length >= 10 && value.split(' ')[1].length <= 15)) {
				return true;
			}

			return false;
		} catch {
			return false;
		}
	}

	isLocation(value: any) {
		try {
			if ((value.split(' ')[0] && isValidCoordinates.latitude(Number(value.split(' ')[0]))) && (value.split(' ')[1] && isValidCoordinates.longitude(Number(value.split(' ')[1])))) {
				return true;
			}

			return false;
		} catch {
			return false;
		}
	}

	copyMessage(val: any) {
		this.selectedDataToCopy = val;

		if (this.isLocation(val)) {
			this.selectedDataToCopy = 'https://maps.google.com?q=loc:' + val.split(' ')[0] + '+' + val.split(' ')[1];

			setTimeout(() => {
				window.open('https://maps.google.com?q=loc:' + val.split(' ')[0] + '+' + val.split(' ')[1]);
			}, 300);
		}

		setTimeout(() => {
			this.clipboardInput.nativeElement.select();
			document.execCommand('copy');
			this.clipboardInput.nativeElement.setSelectionRange(0, 0);
			this.toastService.presentToastWithOptions("Copied to clipboard", ToastType.SUCCESS);

			if (this.isPhoneNumber(val)) {
				window.open("tel:" + val, '_blank');
			}
		}, 100);
	}

	copyMultiSelect(data: any, choices: any) {
		this.selectedDataToCopy = '';

		for (let i = 0; i < choices.length; i += 1) {
			if (data.includes(choices[i])) {
				this.selectedDataToCopy += (this.selectedDataToCopy !== '' ? ', ' : '') + this.getMemberCustomFieldOptionDetails(choices[i]).name;
			}
		}

		setTimeout(() => {
			this.clipboardInput.nativeElement.select();
			document.execCommand('copy');
			this.clipboardInput.nativeElement.setSelectionRange(0, 0);
			this.toastService.presentToastWithOptions("Copied to clipboard", ToastType.SUCCESS);
		}, 100);
	}

	toggleFireWorkflowForMembers() {
		let affiliated_workflow_types = this.affiliated_workflow_types;

		affiliated_workflow_types = affiliated_workflow_types.filter((type) => {
			if (type.affiliatedEntity && type.affiliatedEntity !== this.selected_member.type) {
				return false;
			}

			return this.getWorkflowPermission(type.id) === 'WRITE';
		});

		if (affiliated_workflow_types.length > 0) {
			this.show_popup = true;
		} else {
			this.toastService.presentToastWithOptions(this.translate('No Workflows to fire'), ToastType.INFO);
		}
	}

	isMemberFireWorkflowAvailable() {
		let affiliated_workflow_types = this.affiliated_workflow_types;

		affiliated_workflow_types = affiliated_workflow_types.filter((type) => {
			if (type.affiliatedEntity && type.affiliatedEntity !== this.selected_member.type) {
				return false;
			}

			return this.getWorkflowPermission(type.id) === 'WRITE';
		});

		if (affiliated_workflow_types.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	isGroupFireWorkflowAvailable(groupTypeId: string) {
		let affiliated_group_workflow_types = this.workflow_types.filter((workflow) => {
			return workflow.affiliatedEntity === groupTypeId;
		});

		affiliated_group_workflow_types = affiliated_group_workflow_types.filter((type) => {
			if (type.affiliatedEntity && type.affiliatedEntity !== groupTypeId) {
				return false;
			}

			return this.getWorkflowPermission(type.id) === 'WRITE';
		});

		if (affiliated_group_workflow_types.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	toggleFireWorkflowForGroups() {
		let affiliated_group_workflow_types = this.affiliated_group_workflow_types;

		affiliated_group_workflow_types = affiliated_group_workflow_types.filter((type) => {
			if (type.affiliatedEntity && type.affiliatedEntity !== this.selected_affiliated_group.type) {
				return false;
			}

			return this.getWorkflowPermission(type.id) === 'WRITE';
		});

		if (affiliated_group_workflow_types.length > 0) {
			this.show_group_popup = true;
		} else {
			this.toastService.presentToastWithOptions(this.translate('No Workflows to fire'), ToastType.INFO);
		}
	}

	isActionAvailable(typeId: string) {
		let actions = this.getMemberType(typeId).actions;
		if (actions[1] && this.membersData.types.actions.byId[actions[1]].workflowType && this.getMemberTypePermission(typeId) !== 'NONE') {
			return true;
		} else {
			return false;
		}
	}

	getMemberType(type_id: string) {
		let type = this.membersData.types.byId[type_id];
		return type;
	}

	shareMemberCard() {
		this.shareService.share('Member',
			'Member: ' + this.getMemberName(this.selected_member.id) + '\n' +
				'Subtitle: ' + this.getMemberSubtitle(this.selected_member.id) + '\n' +
				'Type: ' + this.getMemberType(this.membersData.byId[this.selected_member.id].type) ? this.getMemberType(this.membersData.byId[this.selected_member.id].type).name : '-'
			, null);
	}

	shareGroupCard() {
		this.shareService.share('Group',
			'Group: ' + this.getGroupName(this.selected_group.id) + '\n' +
			'Subtitle: ' + this.getGroupSubtitle(this.selected_group.id) + '\n' +
			'Type: ' + this.getGroupType(this.selected_group.type)
			, null);
	}

	shareWorkflowCard() {
		this.shareService.share('Workflow',
			'Workflow: ' + this.getWorkflowName(this.selected_workflow.type) + '\n' +
			'Affiliated to: ' + this.getAffiliatedEntityName(this.selected_workflow.affiliatedEntity) + '\n' +
			'Status: ' + this.workflowsData.types.statuses.byId[this.selected_workflow.status].name
			+ '\n' + 'Due on: ' + moment(this.selected_workflow.dueDate).format('DD-MM-YYYY'), null);
	}

	getWorkflowName(type: string) {
		return this.workflowsData.types.byId[type].name;
	}

	async selectedGroupDetails(group: IGroup) {
		if (this.isReadOnly) {
			return;
		}

		const modal = await this.modalController.create({
			component: GroupDetailsPage,
			componentProps: {
				group_id: group.id
			}
		});

		return await modal.present();
	}

	getGroupSubtitle(groupId: string) {
		const group = this.groupsData.byId[groupId];
		const groupType = this.groupsData.types.byId[group.type];
		let groupSubtitle: CustomFieldValueType;
		const subTitleField = this.groupsData.types.customFields.byId[groupType.subTitleFieldId];

		groupSubtitle = group.customFields[groupType.subTitleFieldId];

		groupSubtitle = getReadableDataForCustomField(groupSubtitle, subTitleField, group.id, 'group');

		return groupSubtitle;
	}

	getGroupType(type_id: string) {
		let type = this.groupsData.types.byId[type_id].name;
		return type ? type : '-';
	}

	getGroupName(groupId: string) {
		const group = this.groupsData.byId[groupId];
		const groupType = this.groupsData.types.byId[group.type];
		let groupName: CustomFieldValueType;
		const nameField = this.groupsData.types.customFields.byId[groupType.nameFieldId];

		groupName = group.customFields[groupType.nameFieldId];

		groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');
		return groupName;
	}


	translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

	getWorkflowPermission(id: string) {
		if (this.userPermission.workflows[id]) {
			return this.userPermission.workflows[id];
		} else {
			return Permissions.WRITE;
		}
	}

	ngOnInit() {
		let selected_member = this.navParams.get('selected_member_data');
		this.isReadOnly = this.navParams.get('read_only');

		this.applicationStateSubscription = this.applicationStateSource.subscribe((applicationState) => {
			this.applicationState = applicationState;

			this.my_id = this.applicationState.myData.id;
			const userData = this.applicationState.users.byId[this.my_id];

			this.userPermission = this.applicationState.permissions.myPermissions;

			this.membersData = this.applicationState.members;

			this.selected_member = this.membersData.byId[selected_member.id];

			this.groupsData = this.applicationState.groups;

			const visibleGroups = getVisibleGroupIds(this.applicationState);

			this.groupList = visibleGroups.map((id) => {
				return this.groupsData.byId[id];
			});

			this.groupList = this.groupList.filter((group) => {
				return this.isPresentInGroup(group.id);
			});

			this.temp_variables.calculated_location = this.applicationState.structure.locations.byId[this.selected_member.location];

			this.workflowsData = this.applicationState.workflows;

			const workflowIds = getVisibleWorkflowIds(this.applicationState);

			this.workflows = this.workflowsData.allEntries
				.filter(workflowId => workflowIds.includes(workflowId))
				.map((workflowId) => {
					return this.workflowsData.byId[workflowId];
				}).filter((workflow) => {
					return workflow.affiliatedEntity === this.selected_member.id;
				});

			this.workflowCardList = this.getWorkflowCardList();

			this.workflow_types = this.workflowsData.types.allEntries
				.map((typeId) => {
					return this.workflowsData.types.byId[typeId];
				})
				.filter(workflowType => {
					return userData.projects.includes(workflowType.project);
				});

			this.affiliated_workflow_types = this.workflow_types.filter((workflow) => {
				return workflow.affiliation === 'member';
			});

			this.updateCustomFields();
		});
	}

	executeWorkflowCardActions(clicked_param: { button_type: string, id: string }) {
		let card: any;

		switch (clicked_param.button_type) {
			case 'profile_details': this.executeWorkflow(clicked_param.id, true); break;
			case 'action_button': this.workflowsData.byId[clicked_param.id].user === this.my_id ? this.executeWorkflow(clicked_param.id) : this.alertUser('You cannot resume other user\'s workflows'); break;
			case 'view_report': this.executeWorkflow(clicked_param.id, true); break;
			case 'share':
				card = this.workflowCardList.find(card => card.id === clicked_param.id);
				this.shareService.share('Workflow',
					'Workflow: ' + card.name + '\n' +
					'Affiliated to: ' + card.subtitle + '\n' +
					'Status: ' + this.workflowsData.types.statuses.byId[this.workflowsData.byId[card.id].status].name
					+ '\n' + 'Due on: ' + moment(card.details[0].value).format('DD-MM-YYYY'), null); break;
			case 'date':
				card = this.workflowCardList.find(card => card.id === clicked_param.id);
				this.selectedWorkflowForDueDateChange.id = clicked_param.id; this.selectedWorkflowForDueDateChange.preSelectedDate = card.details[0].value;
				break;
		}
	}

	getWorkflowCardList(workflowList?: Array<IWorkflow>) {
		let workflowlist: Array<IWorkflow>;
		workflowList ? workflowlist = workflowList : workflowlist = this.workflows;

		return workflowlist.map(workflow => {
			let type, allCustomFields, workflowProcessState, customFieldValue, readableValue, details;

			type = this.applicationState.workflows.types.byId[workflow.type];

			details = '-';
			
            if (!workflow.affiliatedEntity) {                
                try {
                    allCustomFields = type.customFields.map(customFieldId => this.applicationState.workflows.types.customFields.byId[customFieldId]).filter((field) => {
                        return field.isInTable;
                    });
					workflowProcessState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
                    customFieldValue = workflowProcessState.customFields[allCustomFields[0].id];
                    readableValue = getReadableDataForCustomField(customFieldValue as CustomFieldValueType, allCustomFields[0], workflow.id, 'workflow');
                } catch {
                    readableValue = '-';
                }
			}

			if (type.subTitleFieldId) {
                const subTitleField = this.applicationState.workflows.types.customFields.byId[type.subTitleFieldId];
				const workflowProcessState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
				const detailsValue = workflowProcessState.customFields[type.subTitleFieldId];
								
                // Only allow fields that are not member-affiliated in group workflows
                if (Array.isArray(detailsValue) || typeof detailsValue !== 'object') {
                    details = getReadableDataForCustomField(detailsValue, subTitleField, workflow.id, 'workflow');
                }
			} else if (workflow.affiliatedEntity) {
				details = this.getAffiliatedEntityName(workflow.affiliatedEntity);
			}

			let completionRate = this.getCompletionRate(workflow.id);

			return {
				id: workflow.id,
				name: this.workflowsData.types.byId[workflow.type].name,
				subtitle: details,
				type: workflow.type,
				details: [{
					name: 'Due Date',
					value: moment(workflow.dueDate).format('YYYY-MM-DD')
				}, {
					name: '',
					value: this.workflowsData.types.statuses.byId[workflow.status].name
				}],
				buttons: [],
				actionButton: {
					name: this.isWorkflowTerminal(workflow.status) ? 'View Details' : 'Resume',
					iconSrc: this.isWorkflowTerminal(workflow.status) ? 'assets/new-custom-icons/eye.svg' : 'assets/new-revision/refresh.svg'
				},
				user: workflow.user,
				isCompleted: this.isWorkflowTerminal(workflow.status),
				isTransferable: workflow.user === this.applicationState.myData.id ? true : false,
				infoSideButtonType: this.applicationState.workflows.types.byId[workflow.type].affiliation,
				completionPercentage: completionRate === 0 ? 1 : completionRate,
			}
		}).sort((flow1, flow2) => {
			var key1 = flow1.isCompleted;
			var key2 = flow2.isCompleted;

			if (key1 < key2) {
				return -1;
			} else if (key1 == key2) {
				return 0;
			} else {
				return 1;
			}
		});
	}

	getCompletionRate(workflowId: string) {
		try {
			const completionPercentage = completionPercentageOfWorkflow(workflowId);
			return Math.round(completionPercentage / 5) * 5;
		} catch {
			return 0;
		}
	}

	showGroupWorkflows(group: IGroup) {
		this.selected_affiliated_group = group;
		let workflow_types = this.workflowsData.types.allEntries.map((typeId) => {
			return this.workflowsData.types.byId[typeId];
		});

		this.affiliated_group_workflow_types = workflow_types.filter((workflow) => {
			return workflow.affiliatedEntity === group.type;
		});
	}

	isPresentInGroup(groupId: string) {
		return this.groupsData.byId[groupId].members.includes(this.selected_member.id);
	}

	async addNewWorkflowWithType(actionId: string) {
		let selected_affiliated_entity = {
			id: this.selected_member.id,
			type: this.selected_member.type,
			name: this.getMemberName(this.selected_member.id)
		}

		const modal = await this.modalController.create({
			component: AddWorkflowPage,
			componentProps: {
				workflow_type_id: this.membersData.types.actions.byId[actionId].workflowType,
				selected_affiliated_entity: JSON.stringify(selected_affiliated_entity)
			}
		});

		return await modal.present();
	}

	editSelectedMemberCheckActions() {
		if (this.selected_member.type) {
			let memberType = this.membersData.types.byId[this.selected_member.type];

			if (memberType.actions && memberType.actions[1] &&
				this.membersData.types.actions.byId[memberType.actions[1]].workflowType) {
				this.addNewWorkflowWithType(memberType.actions[1])
			} else if (this.getMemberPermission(this.selected_member.id) === 'WRITE') {
				this.editSelectedMember();
			} else {
				this.toastService.presentToastWithOptions("You do not have permission to edit this member", ToastType.INFO);
			}
		}
	}

	async editSelectedMember() {
		const modal = await this.modalController.create({
			component: AddOrEditMemberPage,
			componentProps: {
				member: JSON.stringify(this.selected_member)
			}
		});

		return await modal.present();
	}

	getMemberPermission(id: string) {
		if (this.userPermission.members && this.userPermission.members[this.applicationState.members.byId[id].type]) {
			return this.userPermission.members[this.applicationState.members.byId[id].type];
		} else {
			return Permissions.WRITE;
		}
	}

	getMemberSubtitle(memberId: string) {
		try {
			const member = this.membersData.byId[memberId];
			const memberType = this.membersData.types.byId[member.type];
			let memberSubtitle: VariableValueType;
			const subTitleField = this.membersData.types.customFields.byId[memberType.subTitleFieldId];

			if (subTitleField.isComputed && typeof subTitleField.startPiece !== 'undefined') {
				const processState: DefaultFlowchartProcessState = {
					displayingContinuePieceId: undefined,
					displayingAddWorkflowPieceId: undefined,
					customFields: { ...member.customFields },
					variables: {
						[subTitleField.seedEntityVariable]: member.id,
					},
					lastComputedPiece: undefined,
					executionStack: [],
					forIterationCounts: {},
					displayingQuestionPieceId: undefined,
					displayingShowPieceId: undefined,
					displayingGroupPieceId: undefined,
					displayingTransferPieceId: undefined,
					createdWorkflowId: undefined,
				};

				memberSubtitle = getMemberComputedFieldValue(this.applicationState, processState, subTitleField.startPiece.piece, member.id, subTitleField);
			} else {
				memberSubtitle = member.customFields[memberType.subTitleFieldId];
			}

			if (Array.isArray(memberSubtitle)) {

				if (memberSubtitle.length > 0 && Array.isArray(memberSubtitle[0])) {
					// Cannot be a multidimensional array
					throw new Error('The value cannot be a multi-dimensional array')
				}

				memberSubtitle = memberSubtitle as Array<string>;
			}

			memberSubtitle = getReadableDataForCustomField(memberSubtitle, subTitleField, member.id, 'member');

			return memberSubtitle;
		} catch {
			return '-';
		}
	}

	getMemberPhoneNumber(memberId: string) {
		if (this.phoneFieldId) {
			if (this.membersData.byId[memberId].customFields[this.phoneFieldId]) {
				return this.membersData.byId[memberId].customFields[this.phoneFieldId].toString();
			} else {
				return '-';
			}
		} else {
			return '-';
		}
	}

	openCallApp(phone: string) {
		var url = "tel:" + phone;
		window.open(url);
	}

	getMemberCustomFieldOptionDetails(id: string) {
		return this.membersData.types.customFieldOptions.byId[id];
	}

	showCalendar(workflow: IUpdateableWorkflowData, preSelectedDate?: string) {
		this.selectedWorkflowForDueDateChange = {
			id: workflow.id,
			preSelectedDate: preSelectedDate
		};
	}

	getDateInput(tempDateInput: any) {
		if (!moment(tempDateInput, 'DD-MM-YYYY', true).isValid()) {
			this.toastService.presentToastWithOptions("Invalid Date, Format: DD-MM-YYYY", ToastType.ERROR);
		} else {
			if (this.selectedWorkflowForDueDateChange.preSelectedDate !== moment(tempDateInput, 'DD-MM-YYYY').format('YYYY-MM-DD')) {
				this.ngRedux.dispatch(updateDueDate(this.selectedWorkflowForDueDateChange.id, moment(tempDateInput, 'DD-MM-YYYY').format('YYYY-MM-DD')));
				this.toastService.presentToastWithOptions('The due date has been updated!', ToastType.SUCCESS);

				this.selectedWorkflowForDueDateChange = {
					id: null,
					preSelectedDate: null
				};
			} else {
				this.selectedWorkflowForDueDateChange = {
					id: null,
					preSelectedDate: null
				};
			}

		}
	}

	isWorkflowTerminal(status: string) {
		return this.workflowsData.types.statuses.byId[status].isTerminal;
	}

	getStatusDetail(status: string) {
		return this.workflowsData.types.statuses.byId[status];
	}

	getAffiliatedEntityName(affiliatedEntity: string) {
		let member = this.membersData.allEntries.find(member => affiliatedEntity === member);
		return member ? this.getMemberName(member) : '-';
	}

	updateCustomFields() {
		this.customFields = this.selected_member.type ?
			this.membersData.types.byId[this.selected_member.type].customFields.map((fieldId) => {
				return this.membersData.types.customFields.byId[fieldId];
			}) : [];

		this.customFields.forEach((field) => {
			if (field.type === 'PHONE') {
				this.phoneFieldId = field.id;
				return;
			}
		});
	}

	getFormattedDate(date: any) {
		return moment(date).format('DD MMM YYYY');
	}

	back() {
		if (this.applicationStateSubscription) {
			this.applicationStateSubscription.unsubscribe();
		}

		this.modalController.dismiss();
	}

	ngOnDestroy() {
		if (this.applicationStateSubscription) {
			this.applicationStateSubscription.unsubscribe();
		}
	}

	showMap(latlng: string) {
		var url = "https://maps.google.com/?q=" + latlng.split(' ')[0] + latlng.split(' ')[1];
		window.open(url);
	}

	getMemberLocation(memberId: string) {
		const member = this.membersData.byId[memberId];
		const memberType = this.membersData.types.byId[member.type];
		let memberLocation: VariableValueType;

		const locationField = this.membersData.types.customFields.byId[memberType.locationFieldId];

		if (locationField.isComputed && typeof locationField.startPiece !== 'undefined') {
			const processState: DefaultFlowchartProcessState = {
				displayingContinuePieceId: undefined,
				displayingAddWorkflowPieceId: undefined,
				customFields: { ...member.customFields },
				variables: {
					[locationField.seedEntityVariable]: member.id,
				},
				lastComputedPiece: undefined,
				executionStack: [],
				forIterationCounts: {},
				displayingQuestionPieceId: undefined,
				displayingShowPieceId: undefined,
				displayingGroupPieceId: undefined,
				displayingTransferPieceId: undefined,
				createdWorkflowId: undefined,
			};

			memberLocation = getMemberComputedFieldValue(this.applicationState, processState, locationField.startPiece.piece, member.id, locationField);
		} else {
			memberLocation = member.customFields[memberType.locationFieldId];
		}

		if (Array.isArray(memberLocation)) {

			if (memberLocation.length > 0 && Array.isArray(memberLocation[0])) {
				// Cannot be a multidimensional array
				throw new Error('The value cannot be a multi-dimensional array')
			}

			memberLocation = memberLocation as Array<string>;
		}

		memberLocation = getReadableDataForCustomField(memberLocation, locationField, member.id, 'member');

		return memberLocation;
	}

	getComputedFieldValue(fieldId: string) {
		const field = this.membersData.types.customFields.byId[fieldId];

		if (field.isComputed && typeof field.startPiece !== 'undefined') {
			const processState: DefaultFlowchartProcessState = {
				displayingContinuePieceId: undefined,
				displayingAddWorkflowPieceId: undefined,
				customFields: { ...this.selected_member.customFields },
				variables: {
					[field.seedEntityVariable]: this.selected_member.id,
				},
				lastComputedPiece: undefined,
				executionStack: [],
				forIterationCounts: {},
				displayingQuestionPieceId: undefined,
				displayingShowPieceId: undefined,
				displayingGroupPieceId: undefined,
				displayingTransferPieceId: undefined,
				createdWorkflowId: undefined,
			};

			try {
				return getMemberComputedFieldValue(this.applicationState, processState, field.startPiece.piece, this.selected_member.id, field);
			} catch (err) {
				return '-';
			}
		}
	}

	getMemberName(memberId: string) {
		const member = this.membersData.byId[memberId];
		const memberType = this.membersData.types.byId[member.type];
		let memberName: VariableValueType;

		const nameField = this.membersData.types.customFields.byId[memberType.nameFieldId];

		if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
			const processState: DefaultFlowchartProcessState = {
				displayingContinuePieceId: undefined,
				displayingAddWorkflowPieceId: undefined,
				customFields: { ...member.customFields },
				variables: {
					[nameField.seedEntityVariable]: member.id,
				},
				lastComputedPiece: undefined,
				executionStack: [],
				forIterationCounts: {},
				displayingQuestionPieceId: undefined,
				displayingShowPieceId: undefined,
				displayingGroupPieceId: undefined,
				displayingTransferPieceId: undefined,
				createdWorkflowId: undefined,
			};

			memberName = getMemberComputedFieldValue(this.applicationState, processState, nameField.startPiece.piece, member.id, nameField);
		} else {
			memberName = member.customFields[memberType.nameFieldId];
		}

		if (Array.isArray(memberName)) {

			if (memberName.length > 0 && Array.isArray(memberName[0])) {
				// Cannot be a multidimensional array
				throw new Error('The value cannot be a multi-dimensional array')
			}

			memberName = memberName as Array<string>;
		}

		memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

		return memberName;
	}

	initiateGroupWorkflow(type_id: string) {
		let typeWorkflowIds: Array<string>, typeWorkflows: Array<IWorkflow>,
			isWorkflowPresent: boolean = false;
		typeWorkflowIds = this.applicationState.users.byId[this.my_id].workflows[type_id];

		if (typeWorkflowIds) {
			typeWorkflows = typeWorkflowIds.map(id => {
				return this.workflowsData.byId[id];
			});

			typeWorkflows.forEach(workflow => {
				if (workflow &&
					workflow.affiliatedEntity === this.selected_affiliated_group.id &&
					!this.isWorkflowTerminal(workflow.status) &&
					!isWorkflowPresent &&
					workflow.user === this.my_id &&
					!workflow.archived) {
					isWorkflowPresent = true;
					this.show_group_popup = false;
					this.resumeWorkflow(workflow.id);
				}
			});
		}

		if (!typeWorkflows || !isWorkflowPresent) {
			this.allowed_statuses = this.workflowsData.types.byId[type_id].statuses.map(statusId => this.workflowsData.types.statuses.byId[statusId]);

			let nonTerminalStatus: Array<IStatus> = this.allowed_statuses.filter((status) => {
				return !status.isTerminal;
			});

			this.new_workflow = {
				id: uuid(),
				type: type_id,
				status: nonTerminalStatus[0].id,
				dueDate: moment(new Date().setDate(new Date().getDate() + nonTerminalStatus[0].dueInDays)).format('YYYY-MM-DD'),
				user: this.my_id,
				affiliatedEntity: this.selected_affiliated_group.id,
			}

			this.ngRedux.dispatch(addWorkflow(this.new_workflow));
			this.executeWorkflow(this.new_workflow.id);
		}
	}

	initiateWorkflow(type_id: string) {
		let typeWorkflowIds: Array<string>, typeWorkflows: Array<IWorkflow>,
			isWorkflowPresent: boolean = false;
		typeWorkflowIds = this.applicationState.users.byId[this.my_id].workflows[type_id];

		if (typeWorkflowIds) {
			typeWorkflows = typeWorkflowIds.map(id => {
				return this.workflowsData.byId[id];
			});

			typeWorkflows.forEach(workflow => {
				if (workflow &&
					workflow.affiliatedEntity === this.selected_member.id &&
					!this.isWorkflowTerminal(workflow.status) &&
					!isWorkflowPresent &&
					workflow.user === this.my_id &&
					!workflow.archived) {
					isWorkflowPresent = true;
					this.show_popup = false;
					this.resumeWorkflow(workflow.id);
				}
			});
		}

		if (!typeWorkflowIds || !isWorkflowPresent) {
			this.allowed_statuses = this.workflowsData.types.byId[type_id].statuses.map(statusId => this.workflowsData.types.statuses.byId[statusId]);

			let nonTerminalStatus: Array<IStatus> = this.allowed_statuses.filter((status) => {
				return !status.isTerminal;
			});

			this.new_workflow = {
				id: uuid(),
				type: type_id,
				status: nonTerminalStatus[0].id,
				dueDate: moment(new Date().setDate(new Date().getDate() + nonTerminalStatus[0].dueInDays)).format('YYYY-MM-DD'),
				user: this.my_id,
				affiliatedEntity: this.selected_member.id,
			}

			this.ngRedux.dispatch(addWorkflow(this.new_workflow));
			this.executeWorkflow(this.new_workflow.id);
		}
	}

	async executeWorkflow(id: string, isEnd?: boolean) {
		const modal = await this.modalController.create({
			component: WorkflowExecutorPage,
			componentProps: {
				workflow_id: id,
				isEnd: isEnd ? true : false
			}
		});

		return await modal.present();
	}

	async alertUser(text: string) {
		const alert = await this.alertController.create({
			message: text,
			buttons: [
				{
					text: 'Okay',
					handler: () => { }
				}
			]
		});

		await alert.present();
	}

	async resumeWorkflow(id: string) {
		const alert = await this.alertController.create({
			header: `${this.translate('Wait')}!`,
			message: this.translate('Seems like there is already a workflow existing... Would you like to resume it?'),
			mode: 'ios',
			buttons: [
				{
					text: this.translate('Cancel'),
					role: 'cancel',
					cssClass: 'alert-danger',
				}, {
					text: this.translate('Okay'),
					cssClass: 'alert-confirm',
					handler: () => {
						this.executeWorkflow(id);
						this.back();
					}
				}
			]
		});

		await alert.present();
	}

	getMemberTypePermission(id: string) {
		if (this.userPermission.members && this.userPermission.members[id]) {
			return this.userPermission.members[id];
		} else {
			return Permissions.WRITE;
		}
	}

	hasChoice(array: any, choice: string) {
		return array.includes(choice);
	}
}

import { PieceType } from '../../pieces/types';

import { ApplicationState } from '../../../types';

import { getPieceValue, executePiece, getEntitiesFunctionaltiy } from '../index';
import { WorkflowProcessState } from '../../../workflows/types';

import { ExecutePieceReturnType, getNextPieceIdForComputedField } from './index';
import { VariableValueType } from '../../../../helpers/common-types';
import { WorkflowTypeCustomField, FieldType, getValidCustomFieldValueForInput } from '../../../custom-fields/types';
import { getAllLocationsUnderUser } from '../../../../helpers/locations';

export function getWorkflowCustomFieldPieceValue(applicationState: ApplicationState, processState: WorkflowProcessState, workflowId: string, workflowTypeCustomField: WorkflowTypeCustomField, pieceId: string): VariableValueType {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];
    const workflowsData = applicationState.workflows;
    const workflow = workflowsData.byId[workflowId]
    const workflowType = workflowsData.types.byId[workflow.type];

    const executingUser = applicationState.users.byId[workflow.user];
    const locationsData = applicationState.structure.locations;

    const functionShortHandTemp = getWorkflowCustomFieldPieceValue.bind({}, applicationState, processState, workflowId);
    const functionShortHand = functionShortHandTemp.bind({}, workflowTypeCustomField);

    switch (piece.type) {


        case PieceType.MY_GROUPS:
            const allMyLocationsForGroups = executingUser.locations.concat(getAllLocationsUnderUser(executingUser.id, applicationState));
            return allMyLocationsForGroups.map(locationId => locationsData.byId[locationId].groups).flat().filter(groupId => groupId in applicationState.groups.byId);

        case PieceType.MY_MEMBERS:
            const allMyLocationsForMembers = executingUser.locations.concat(getAllLocationsUnderUser(executingUser.id, applicationState));
            return allMyLocationsForMembers.map(locationId => locationsData.byId[locationId].members).flat().filter(memberId => memberId in applicationState.members.byId);

        case PieceType.GET_ENTITIES:
            return getEntitiesFunctionaltiy(piece, executingUser.id, applicationState);

        case PieceType.CUSTOM_FIELD:
            if (!piece.customField) {
                throw new Error('A custom field needs to be selected');
            }

            if (piece.customFieldOption) {
                return workflowsData.types.customFieldOptions.byId[piece.customFieldOption].name;
            }

            const customField = workflowsData.types.customFields.byId[piece.customField];

            if (customField.isComputed) {
                let startPiece: string | undefined;

                if (customField.startPiece) {
                    startPiece = customField.startPiece.piece;
                }

                const computedValue = getWorkflowComputedFieldValue(applicationState, processState, startPiece, workflowId, workflowTypeCustomField);

                return computedValue;
            }

            if (workflowType.affiliation === 'group' && customField.affiliation === 'member') {
                const customFieldMemberData = processState.customFields[customField.id];

                if (Array.isArray(customFieldMemberData) || typeof customFieldMemberData !== 'object') {
                    throw new Error('This field must be an object with a different value for each member ID');
                }

                if (!piece.memberVariablePiece) {
                    throw new Error('The piece must have a member variable');
                }

                const memberVariablePiece = piecesData.byId[piece.memberVariablePiece];

                if (memberVariablePiece.type !== PieceType.VARIABLE) {
                    throw new Error('This piece must be a variable piece');
                }

                if (!memberVariablePiece.variable) {
                    throw new Error('The variable piece must point to a variable');
                }

                const customFieldMemberId = processState.variables[memberVariablePiece.variable];

                if (typeof customFieldMemberId !== 'string') {
                    throw new Error('The member ID must always be a string');
                }

                let customFieldValue = customFieldMemberData[customFieldMemberId];

                if (!Array.isArray(customFieldValue) && typeof customFieldValue === 'object') {
                    throw new Error('This field must be not be a collection of data for individual members');
                }

                if (customField.type === FieldType.SINGLE_SELECT) {
                    if (!!customFieldValue) {
                        if (typeof customFieldValue !== 'string') {
                            throw new Error('A single select must have a custom field type');
                        }

                        customFieldValue = workflowsData.types.customFieldOptions.byId[customFieldValue].name;

                    }
                }

                if (customField.type === FieldType.MULTI_SELECT) {
                    if (!!customFieldValue) {
                        if (!Array.isArray(customFieldValue)) {
                            throw new Error('A multi select must have an array');
                        }

                        customFieldValue = customFieldValue.map(customFieldOptionId => workflowsData.types.customFieldOptions.byId[customFieldOptionId].name);

                    }
                }

                return customFieldValue;

            } else {
                let customFieldValue = processState.customFields[customField.id];

                if (!Array.isArray(customFieldValue) && typeof customFieldValue === 'object') {
                    throw new Error('This field must be not be a collection of data for individual members');
                }

                if (customField.type === FieldType.SINGLE_SELECT) {
                    if (!!customFieldValue) {
                        if (typeof customFieldValue !== 'string') {
                            throw new Error('A single select must have a custom field type');
                        }

                        customFieldValue = workflowsData.types.customFieldOptions.byId[customFieldValue].name;

                    }
                }

                if (customField.type === FieldType.MULTI_SELECT) {
                    if (!!customFieldValue) {
                        if (!Array.isArray(customFieldValue)) {
                            throw new Error('A multi select must have an array');
                        }

                        customFieldValue = customFieldValue.map(customFieldOptionId => workflowsData.types.customFieldOptions.byId[customFieldOptionId].name);

                    }
                }

                return customFieldValue;
            }

        case PieceType.SEQUENCE:
            const allPreviousWorkflows = workflowsData.allEntries.slice(0, workflowsData.allEntries.indexOf(workflow.id) + 1);
            const selectedOptions = piece.selectedOptions;

            if (!selectedOptions || selectedOptions.length === 0) {
                return 1;  // There are no options, so all values are the first of thier kind
            }

            let sequenceNumber = 0;  // Initialize the number to zero before checking

            allPreviousWorkflows.forEach(previousWorkflowId => {
                const previousWorkflow = workflowsData.byId[previousWorkflowId];
                let matchesPreviousWorkflow = true;

                selectedOptions.forEach(option => {

                    switch (option) {
                        case 'type':
                            if (previousWorkflow.type !== workflow.type) {
                                matchesPreviousWorkflow = false;
                                return;
                            }
                            break;
                        case 'affiliatedEntity':
                            if (previousWorkflow.affiliatedEntity !== workflow.affiliatedEntity) {
                                matchesPreviousWorkflow = false;
                                return;
                            }
                            break;
                    }
                });

                if (matchesPreviousWorkflow) {
                    sequenceNumber += 1;
                }
            });

            return sequenceNumber;
        default:
            return getPieceValue(applicationState, processState, pieceId, functionShortHand);
    }
}

// If it returns false, stop execution and display what needs to be displayed. Otherwise, feel free to get the next piece and continue executing
function executePieceForWorkflowComputedField(applicationState: ApplicationState, processState: WorkflowProcessState, workflowId: string, workflowTypeCustomField: WorkflowTypeCustomField, pieceId: string): ExecutePieceReturnType {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];

    const getPieceShorthandTemp = getWorkflowCustomFieldPieceValue.bind({}, applicationState, processState, workflowId);
    const getPieceShorthand = getPieceShorthandTemp.bind({}, workflowTypeCustomField);

    switch (piece.type) {

        case PieceType.RETURN:

            if (typeof piece.returnValue === 'undefined') {
                throw new Error('The return piece must have a variable')
            }

            const returnVariablePiece = piecesData.byId[piece.returnValue];
            const returnVariableValue = getPieceShorthand(returnVariablePiece.id);

            return {
                canContinueExecution: false,
                returnValue: returnVariableValue,
            };

        default:
            const canContinueExecution = executePiece(applicationState, processState, pieceId, getPieceShorthand);

            return {
                canContinueExecution,
                returnValue: undefined,
            };
    }
}

export function getWorkflowComputedFieldValue(applicationState: ApplicationState, processState: WorkflowProcessState, startPiece: string | undefined, workflowId: string, customField: WorkflowTypeCustomField) {
    let executionResult: ExecutePieceReturnType;
    const getPieceShorthandTemp = getWorkflowCustomFieldPieceValue.bind({}, applicationState, processState, workflowId);
    const getPieceShorthand = getPieceShorthandTemp.bind({}, customField);

    do {
        const nextPieceId = getNextPieceIdForComputedField(applicationState, processState, startPiece, getPieceShorthand);

        if (typeof nextPieceId === 'undefined') {
            throw new Error('The next piece does not exist');
        }

        executionResult = executePieceForWorkflowComputedField(applicationState, processState, workflowId, customField, nextPieceId);
    } while (executionResult.canContinueExecution);

    const returnValue = executionResult.returnValue;
    return getValidCustomFieldValueForInput(customField, returnValue, applicationState.workflows.types.customFieldOptions);

}
export const UPDATE_TRANSLATION = 'UPDATE_TRANSLATION';
export const UPDATE_MULTIPLE_TRANSLATIONS = 'UPDATE_MULTIPLE_TRANSLATIONS';
export const UPDATE_TRANSLATIONS = 'UPDATE_TRANSLATIONS';
export const CLEAR_TRANSLATIONS_DELTA = 'CLEAR_TRANSLATIONS_DELTA';

export interface LanguageTranslations {
    [word: string]: string,
}

export interface TranslationsState {
    byLanguage: {
        [languageId: string]: LanguageTranslations,
    },
    updatedWords: {
        [languageId: string]: Array<string>,
    }
    currentLanguage: string|undefined,
}

interface UpdateTranslationAction {
    type: typeof UPDATE_TRANSLATION,
    originalWord: string,
    translatedWord: string,
    language: string,
}

interface UpdateMultipleTranslationsAction {
    type: typeof UPDATE_MULTIPLE_TRANSLATIONS,
    translations: Array<{originalWord: string, translatedWord: string}>,
    language: string,
}

interface UpdateTranslationsAction {
    type: typeof UPDATE_TRANSLATIONS,
    data: {
        [languageId: string]: LanguageTranslations
    },
};

interface ClearTranslationsDeltaAction {
    type: typeof CLEAR_TRANSLATIONS_DELTA,
};

export type TranslationActionTypes = UpdateTranslationAction | UpdateMultipleTranslationsAction | UpdateTranslationsAction | ClearTranslationsDeltaAction;
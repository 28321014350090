import { PieceType, AllPieceTypes, PieceState, NestingData, IGetEntitiesPiece } from '../pieces/types';

import { isUUID, dataURLtoFile } from '../../../helpers/utilities';
import { Months } from '../../../helpers/date-utilities';
import { ApplicationState } from '../../types';
import { getValueForComputedField } from '../../custom-fields';
import { FlowchartProcessState, FlowchartState } from '../types';

import { getTrueValueFromVariableValue } from './variables';
import { WorkflowProcessState } from '../../workflows/types';
import { VariableType, VariableState, IVariable } from '../variables/types';
import { getAllLocationsUnderUser, getAllLocationsVisibleToUser, getLocationsAtLevel } from '../../../helpers/locations';

import moment from 'moment';
import store from '../../main';
import { translatePhrase } from '../../../helpers/translation';
import { VariableValueType } from '../../../helpers/common-types';
import { CustomFieldValueType, CustomField, WorkflowTypeCustomField, FieldType } from '../../custom-fields/types';
import { getVisibleMemberIds, getVisibleGroupIds, getVisibleWorkflowIds, getVisibleUserIds } from '../../../helpers/visible-entities';


function formatDateIfWarped(value: string) {
    if (moment(value, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
        return moment(value).format();
    } else if (moment(value, 'YYYY-MM-DD', true).isValid()) {
        return moment(value).format();
    } else {
        return value;
    }
}

function getReadablePiece(pieceId: string | undefined, pieceState: PieceState) {
    if (!pieceId) {
        return undefined;
    }

    if (!(pieceId in pieceState.byId)) {
        return undefined;
    }

    const piece = pieceState.byId[pieceId];

    return piece;
}

function getReadableVariableValue(variable: IVariable, value: VariableValueType, applicationState: ApplicationState) {
    switch (variable.type) {
        case VariableType.USER:
            if (typeof value !== 'string') {
                return;
            }
            return applicationState.users.byId[value];
        case VariableType.MEMBER:
            if (typeof value !== 'string') {
                return;
            }
            return applicationState.members.byId[value];
        case VariableType.GROUP:
            if (typeof value !== 'string') {
                return;
            }
            return applicationState.groups.byId[value];
        case VariableType.LOCATION:
            if (typeof value !== 'string') {
                return;
            }
            return applicationState.structure.locations.byId[value];
        case VariableType.ROLE:
            if (typeof value !== 'string') {
                return;
            }
            return applicationState.structure.roles.byId[value];
        case VariableType.LEVEL:
            if (typeof value !== 'string') {
                return;
            }
            return applicationState.structure.levels.byId[value];
        case VariableType.PROJECT:
            if (typeof value !== 'string') {
                return;
            }
            return applicationState.structure.projects.byId[value];
        case VariableType.WORKFLOW:
            if (typeof value !== 'string') {
                return;
            }
            return applicationState.workflows.byId[value];
        case VariableType.STATUS:
            if (typeof value !== 'string') {
                return;
            }
            return applicationState.workflows.types.statuses.byId[value];
        case VariableType.REPORT:
            if (typeof value !== 'string') {
                return;
            }
            return applicationState.reports.byId[value];

        case VariableType.STATIC_DATA:
            if (typeof value !== 'string') {
                return;
            }
            return applicationState.staticInfo.byId[value];
        case VariableType.DATA_FRAGMENT:
            if (typeof value !== 'string') {
                return;
            }
            return applicationState.staticInfo.fragments.byId[value];
        default:
            return value;
    }
}

export function getReadableProcessState(processState: FlowchartProcessState, applicationState: ApplicationState) {
    const readableLastComputedPiece = processState.lastComputedPiece ? applicationState.flowchart.pieces.byId[processState.lastComputedPiece] : undefined;
    const readableExecutionStack = processState.executionStack.map(pieceId => applicationState.flowchart.pieces.byId[pieceId]);

    const variableData: Array<{
        name: string,
        value: any,
    }> = [];

    for (const variableId in processState.variables) {
        const variable = applicationState.flowchart.variables.byId[variableId];
        variableData.push({
            name: variable.name,
            value: getReadableVariableValue(variable, processState.variables[variableId], applicationState),
        });
    }

    return {
        lastComputedPiece: readableLastComputedPiece,
        executionStack: readableExecutionStack,
        variableData,
    };
}

export function getEntitiesFunctionaltiy(piece: IGetEntitiesPiece, userId: string, applicationState: ApplicationState) {
    const entityForGetEntities = piece.entity;
    const entityTypeForGetEntities = piece.entityType;

    if (!entityForGetEntities) {
        throw new Error('The entity is required');
    }

    switch (entityForGetEntities) {
        case 'LOCATION':
            const visibleLocations = getAllLocationsVisibleToUser(userId, applicationState);
            if (!entityTypeForGetEntities) {
                return visibleLocations;
            } else {
                const level = applicationState.structure.levels.byId[entityTypeForGetEntities];

                if (!level) {
                    throw new Error('Unknown level when getting location entities');
                }

                const locationIdsAtLevel = getLocationsAtLevel(level.id, applicationState).map(location => location.id);
                const visibleLocationsAtLevel = locationIdsAtLevel.filter(locationId => visibleLocations.includes(locationId));

                return visibleLocationsAtLevel;
            }

        case 'USER':
            if (!entityTypeForGetEntities) {
                return getVisibleUserIds(applicationState, true, userId);
            } else {
                const role = applicationState.structure.roles.byId[entityTypeForGetEntities];

                if (!role) {
                    throw new Error('Unknown role when getting location entities');
                }

                const userIds = getVisibleUserIds(applicationState, true, userId).filter(userId => {
                    const user = applicationState.users.byId[userId];
                    return user.roles.includes(role.id)
                });

                return userIds;
            }

        case 'MEMBER':
            if (!entityTypeForGetEntities) {
                return getVisibleMemberIds(applicationState, true, userId);
            } else {
                const memberType = applicationState.members.types.byId[entityTypeForGetEntities];

                if (!memberType) {
                    throw new Error('Unknown member type when getting location entities');
                }

                const memberIds = getVisibleMemberIds(applicationState, true, userId).filter(memberId => {
                    const member = applicationState.members.byId[memberId];
                    return member.type === memberType.id;
                });

                return memberIds;
            }

        case 'GROUP':
            if (!entityTypeForGetEntities) {
                return getVisibleGroupIds(applicationState, true, userId);
            } else {
                const groupType = applicationState.groups.types.byId[entityTypeForGetEntities];

                if (!groupType) {
                    throw new Error('Unknown group type when getting location entities');
                }

                const groupIds = getVisibleGroupIds(applicationState, true, userId).filter(groupId => {
                    const group = applicationState.groups.byId[groupId];
                    return group.type === groupType.id;
                });

                return groupIds;
            }

        case 'WORKFLOW':
            if (!entityTypeForGetEntities) {
                return getVisibleWorkflowIds(applicationState, undefined, undefined, undefined, true, userId);
            } else {
                const workflowType = applicationState.workflows.types.byId[entityTypeForGetEntities];

                if (!workflowType) {
                    throw new Error('Unknown workflow type when getting location entities');
                }

                const workflowIds = getVisibleWorkflowIds(applicationState, undefined, undefined, undefined, true, userId).filter(workflowId => {
                    const workflow = applicationState.workflows.byId[workflowId];
                    return workflow.type === workflowType.id;
                });

                return workflowIds;
            }
        default:
            throw new Error('Unknown entity: ' + entityForGetEntities);
    }
}

function isNumeric(value: VariableValueType) {
    return typeof value === 'number' || (typeof value === 'string' && value.trim() !== '' && !isNaN(Number(value)));
}

export function getPieceValue(applicationState: ApplicationState, processState: FlowchartProcessState, pieceId: string, getPieceValueFromAbove?: (pieceId: string) => VariableValueType): VariableValueType {
    const locationsData = applicationState.structure.locations;
    const variablesData = applicationState.flowchart.variables;
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];

    let leftOperandValue: VariableValueType;
    let rightOperandValue: VariableValueType;
    let operandValue: VariableValueType;
    let variableValue: VariableValueType;

    const functionShortHand = getPieceValueFromAbove ? getPieceValueFromAbove : getPieceValue.bind({}, applicationState, processState);

    const loggedInUser = applicationState.users.byId[applicationState.myData.id];
    let dateSegments: Array<number>;

    switch (piece.type) {
        case PieceType.TODAY:
            return moment().format('YYYY-MM-DD');

        case PieceType.NOW:
            return moment().format();

        case PieceType.TRUE:
            return true;

        case PieceType.HEXAGONAL_TRUE:
            return true;

        case PieceType.FALSE:
            return false;

        case PieceType.HEXAGONAL_FALSE:
            return false;

        case PieceType.LOGGED_IN_USER:
            return applicationState.myData.id;

        case PieceType.VARIABLE:

            if (!piece.variable) {
                throw new Error('The variable piece must point to a variable');
            }

            let variableId = piece.variable;
            let variable = variablesData.byId[variableId];
            variableValue = processState.variables[variable.id];

            variableValue = getTrueValueFromVariableValue(variableValue, variable.type, piece.nesting || [], applicationState);

            if (variable.type === VariableType.NUMBER && (isNaN(Number(variableValue)) || variableValue === 'NaN')) {
                variableValue = undefined;
            }

            return variableValue;

        case PieceType.FORMAT:

            if (!piece.variablePiece) {
                throw new Error('There must be a piece that can be formatted');
            }

            if (!piece.formatModifier) {
                throw new Error('There must be a format modifier');
            }

            const formattingPieceValue = functionShortHand(piece.variablePiece);
            const formattingPieceType = getPieceValueType(piece.variablePiece, applicationState.flowchart.pieces, applicationState.flowchart.variables, applicationState);
            let formattedData: string;

            if (typeof formattingPieceValue === 'undefined') {
                return undefined;
            }

            switch (formattingPieceType) {
                case VariableType.DATE:
                    if (typeof formattingPieceValue !== 'string') {
                        throw new Error('Invalid value for date')
                    }

                    try {
                        formattedData = moment(formattingPieceValue).format(piece.formatModifier);
                    } catch (e) {
                        throw new Error('Could not format date value');
                    }
                    break;
                case VariableType.NUMBER:
                    if (isNaN(Number(formattingPieceValue))) {
                        throw new Error('Invalid value for number')
                    }

                    if (isNaN(Number(piece.formatModifier))) {
                        throw new Error('Invalid format modifier')
                    }

                    try {
                        formattedData = Number(formattingPieceValue).toFixed(Number(piece.formatModifier));
                    } catch (e) {
                        throw new Error('Could not format date value');
                    }
                    break;
                case VariableType.TEXT_LIST:
                    if (!Array.isArray(formattingPieceValue)) {
                        throw new Error('Invalid value for text list');
                    }

                    formattedData = formattingPieceValue.join(piece.formatModifier);
                    break;
                default:
                    throw new Error('Unsupported type for formatting: ' + formattingPieceType)
            }

            return formattedData;

        case PieceType.GET_VALUE:

            if (!piece.variablePiece) {
                throw new Error('There must be a variable piece from which to fetch the custom field');
            }

            if (!piece.customFieldId) {
                throw new Error('The piece must point to a field');
            }

            const fetchingVariable = functionShortHand(piece.variablePiece);

            if (typeof fetchingVariable !== 'string' || !isUUID(fetchingVariable)) {
                throw new Error('The fetching variable must be a UUID for an entity');
            }

            const getVariableType = piece.variablePiece && isUUID(piece.variablePiece) ? getPieceValueType(piece.variablePiece, applicationState.flowchart.pieces, applicationState.flowchart.variables, applicationState) : VariableType.TEXT;

            let customFieldValue: CustomFieldValueType;
            let rawVariableValue: CustomFieldValueType;
            let memberVariablePiece: AllPieceTypes;

            if (typeof getVariableType === 'undefined') {
                throw new Error('The type for the piece cannot be undefined');
            } else if (getVariableType === VariableType.LOCATION) {
                customFieldValue = applicationState.structure.locations.byId[fetchingVariable].customFields[piece.customFieldId];
                rawVariableValue = customFieldValue;

                const customField = applicationState.structure.levels.customFields.byId[piece.customFieldId];

                if (customField.isComputed) {
                    rawVariableValue = getValueForComputedField(customField, fetchingVariable, 'level', applicationState);
                } else if (typeof customFieldValue === 'string' && isUUID(customFieldValue)) {
                    rawVariableValue = applicationState.structure.levels.customFieldOptions.byId[customFieldValue].name;
                } else if (Array.isArray(customFieldValue) && customFieldValue.length > 0 && isUUID(customFieldValue[0])) {
                    rawVariableValue = customFieldValue.map(fieldId => applicationState.structure.levels.customFieldOptions.byId[fieldId].name);
                }

            } else if (getVariableType === VariableType.USER) {
                customFieldValue = applicationState.users.byId[fetchingVariable].customFields[piece.customFieldId];
                rawVariableValue = customFieldValue;

                if (piece.customFieldId in applicationState.users.customFields.byId) {
                    const customField = applicationState.users.customFields.byId[piece.customFieldId];

                    if (customField.isComputed) {
                        rawVariableValue = getValueForComputedField(customField, fetchingVariable, 'user', applicationState);
                    } else if (typeof customFieldValue === 'string' && isUUID(customFieldValue)) {
                        rawVariableValue = applicationState.users.customFieldOptions.byId[customFieldValue].name;
                    } else if (Array.isArray(customFieldValue) && customFieldValue.length > 0 && isUUID(customFieldValue[0])) {
                        rawVariableValue = customFieldValue.map(fieldId => applicationState.users.customFieldOptions.byId[fieldId].name);
                    }
                } else if (piece.customFieldId in applicationState.structure.roles.customFields.byId) {
                    const customField = applicationState.structure.roles.customFields.byId[piece.customFieldId];

                    if (customField.isComputed) {
                        rawVariableValue = getValueForComputedField(customField, fetchingVariable, 'role', applicationState);
                    } else if (typeof customFieldValue === 'string' && isUUID(customFieldValue)) {
                        rawVariableValue = applicationState.structure.roles.customFieldOptions.byId[customFieldValue].name;
                    } else if (Array.isArray(customFieldValue) && customFieldValue.length > 0 && isUUID(customFieldValue[0])) {
                        rawVariableValue = customFieldValue.map(fieldId => applicationState.structure.roles.customFieldOptions.byId[fieldId].name);
                    }
                }
            } else if (getVariableType === VariableType.MEMBER) {
                customFieldValue = applicationState.members.byId[fetchingVariable].customFields[piece.customFieldId];
                rawVariableValue = customFieldValue;

                const customField = applicationState.members.types.customFields.byId[piece.customFieldId];

                if (customField.isComputed) {
                    rawVariableValue = getValueForComputedField(customField, fetchingVariable, 'member', applicationState);
                } else if (typeof customFieldValue === 'string' && isUUID(customFieldValue)) {
                    rawVariableValue = applicationState.members.types.customFieldOptions.byId[customFieldValue].name;
                } else if (Array.isArray(customFieldValue) && customFieldValue.length > 0 && isUUID(customFieldValue[0])) {
                    rawVariableValue = customFieldValue.map(fieldId => applicationState.members.types.customFieldOptions.byId[fieldId].name);
                }

            } else if (getVariableType === VariableType.GROUP) {
                customFieldValue = applicationState.groups.byId[fetchingVariable].customFields[piece.customFieldId];
                rawVariableValue = customFieldValue;

                const customField = applicationState.groups.types.customFields.byId[piece.customFieldId];

                if (customField.isComputed) {
                    rawVariableValue = getValueForComputedField(customField, fetchingVariable, 'member', applicationState);
                } else if (typeof customFieldValue === 'string' && isUUID(customFieldValue)) {
                    rawVariableValue = applicationState.groups.types.customFieldOptions.byId[customFieldValue].name;
                } else if (Array.isArray(customFieldValue) && customFieldValue.length > 0 && isUUID(customFieldValue[0])) {
                    rawVariableValue = customFieldValue.map(fieldId => applicationState.groups.types.customFieldOptions.byId[fieldId].name);
                }

            } else if (getVariableType === VariableType.WORKFLOW) {
                const workflowCustomFieldValue = applicationState.workflows.byId[fetchingVariable].history[applicationState.workflows.byId[fetchingVariable].historyIndex].customFields[piece.customFieldId];

                if (workflowCustomFieldValue !== null && typeof workflowCustomFieldValue === 'object' && !Array.isArray(workflowCustomFieldValue)) {
                    // The value is on a per-member basis

                    if (!piece.memberVariablePiece) {
                        throw new Error('The piece must have a member variable, since the custom field value is an object');
                    }

                    memberVariablePiece = piecesData.byId[piece.memberVariablePiece];

                    if (memberVariablePiece.type !== PieceType.VARIABLE) {
                        throw new Error('This piece must be a variable piece');
                    }

                    if (!memberVariablePiece.variable) {
                        throw new Error('The variable piece must point to a variable');
                    }

                    const getMemberId = processState.variables[memberVariablePiece.variable];

                    if (typeof getMemberId !== 'string') {
                        throw new Error('The member ID must always be a string');
                    }

                    rawVariableValue = workflowCustomFieldValue[getMemberId];
                } else {
                    rawVariableValue = workflowCustomFieldValue as CustomFieldValueType;
                }

                const customField = applicationState.workflows.types.customFields.byId[piece.customFieldId];

                if (customField.isComputed) {
                    rawVariableValue = getValueForComputedField(customField, fetchingVariable, 'workflow', applicationState);
                } else if (typeof rawVariableValue === 'string' && isUUID(rawVariableValue) && rawVariableValue in applicationState.workflows.types.customFieldOptions.byId) {
                    rawVariableValue = applicationState.workflows.types.customFieldOptions.byId[rawVariableValue].name;
                } else if (Array.isArray(rawVariableValue) && rawVariableValue.length > 0 && isUUID(rawVariableValue[0])) {
                    rawVariableValue = rawVariableValue.map(fieldId => applicationState.workflows.types.customFieldOptions.byId[fieldId].name);
                }
            }

            if (typeof rawVariableValue === 'number') {
                variableValue = String(rawVariableValue);
            } else {
                variableValue = rawVariableValue;
            }

            return variableValue;

        case PieceType.MY_GROUPS:
            const allMyLocationsForGroups = isUUID(applicationState.myData.id) ? loggedInUser.locations.concat(getAllLocationsUnderUser(applicationState.myData.id, applicationState)) : locationsData.allEntries;
            return allMyLocationsForGroups.map(locationId => locationsData.byId[locationId].groups).flat().filter(groupId => groupId in applicationState.groups.byId);

        case PieceType.MY_MEMBERS:
            const allMyLocationsForMembers = isUUID(applicationState.myData.id) ? loggedInUser.locations.concat(getAllLocationsUnderUser(applicationState.myData.id, applicationState)) : locationsData.allEntries;
            return allMyLocationsForMembers.map(locationId => locationsData.byId[locationId].members).flat().filter(memberId => memberId in applicationState.members.byId);

        case PieceType.FINANCIAL_YEAR_MONTHS:
            return [Months.MARCH, Months.APRIL, Months.MAY, Months.JUNE, Months.JULY, Months.AUGUST, Months.NOVEMBER, Months.DECEMBER, Months.JANUARY, Months.FEBRUARY, Months.MARCH];

        case PieceType.GET_ENTITIES:
            return getEntitiesFunctionaltiy(piece, applicationState.myData.id, applicationState);

        case PieceType.ADD:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (typeof leftOperandValue === 'boolean' || typeof rightOperandValue === 'boolean') {
                throw new Error('Invalid variable types for addition');
            }

            if (typeof leftOperandValue === 'undefined' && typeof rightOperandValue === 'undefined') {
                return undefined;
            }

            if (typeof leftOperandValue === 'undefined' || (typeof leftOperandValue === 'string' && leftOperandValue.trim() === '')) {
                if (isNumeric(rightOperandValue)) {
                    leftOperandValue = 0;
                } else if (typeof rightOperandValue === 'string') {
                    leftOperandValue = '';
                }
            }

            if (typeof rightOperandValue === 'undefined' || (typeof rightOperandValue === 'string' && rightOperandValue.trim() === '')) {
                if (isNumeric(leftOperandValue)) {
                    rightOperandValue = 0;
                } else if (typeof leftOperandValue === 'string') {
                    rightOperandValue = '';
                }
            }

            if (typeof leftOperandValue === 'string' && (isNumeric(rightOperandValue) || typeof rightOperandValue === 'undefined')) {
                // Check if the variable is a date

                if (moment(leftOperandValue, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                    if (typeof rightOperandValue === 'undefined') {
                        return moment(leftOperandValue).format();
                    } else {
                        rightOperandValue = Number(rightOperandValue);
                        return moment(leftOperandValue).add(rightOperandValue, 'days').format();
                    }
                } else if (moment(leftOperandValue, 'YYYY-MM-DD', true).isValid()) {
                    if (typeof rightOperandValue === 'undefined') {
                        return moment(leftOperandValue).format();
                    } else {
                        rightOperandValue = Number(rightOperandValue);
                        return moment(leftOperandValue).add(rightOperandValue, 'days').format();
                    }
                }
            }

            if (isNumeric(leftOperandValue) && isNumeric(rightOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
                rightOperandValue = Number(rightOperandValue);
                return (leftOperandValue as number) + (rightOperandValue as number);
            } else if (
                (typeof leftOperandValue === 'string' || isNumeric(leftOperandValue)) &&
                (typeof rightOperandValue === 'string' || isNumeric(rightOperandValue))
            ) {
                return (leftOperandValue as string) + (rightOperandValue as string);
            } else if (
                (typeof leftOperandValue === 'string') && Array.isArray(rightOperandValue)
            ) {
                if (rightOperandValue.length > 0 && Array.isArray(rightOperandValue[0])) {
                    throw new Error('The right operand cannot be a table type');
                }

                return [leftOperandValue].concat(rightOperandValue as Array<string>);
            } else if (
                Array.isArray(leftOperandValue) && (typeof rightOperandValue === 'string')
            ) {
                if (leftOperandValue.length > 0 && Array.isArray(leftOperandValue[0])) {
                    throw new Error('The left operand cannot be a table type');
                }

                return (leftOperandValue as Array<string>).concat([rightOperandValue]);
            }

            throw new Error('The types of variables on either side are incompatible')

        case PieceType.SUBTRACT:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (piece.rightOperand && isNumeric(rightOperandValue)) {
                rightOperandValue = Number(rightOperandValue);
            }

            if (leftOperandValue === '') {
                leftOperandValue = 0;
            }

            if (rightOperandValue === '') {
                rightOperandValue = 0;
            }

            if (typeof leftOperandValue === 'string' && (typeof rightOperandValue === 'number' || typeof rightOperandValue === 'undefined')) {
                // Check if the variable is a date

                if (moment(leftOperandValue, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                    if (typeof rightOperandValue === 'undefined') {
                        return moment(leftOperandValue).format();
                    } else {
                        return moment(leftOperandValue).subtract(rightOperandValue, 'days').format();
                    }
                } else if (moment(leftOperandValue, 'YYYY-MM-DD', true).isValid()) {
                    if (typeof rightOperandValue === 'undefined') {
                        return moment(leftOperandValue).format();
                    } else {
                        return moment(leftOperandValue).subtract(rightOperandValue, 'days').format();
                    }
                }
            }

            if (typeof leftOperandValue === 'string' && typeof rightOperandValue === 'string') {
                // Check if the variable is a date
                let isLeftOperandValidDate = false;
                let isRightOperandValidDate = false;

                if (moment(leftOperandValue, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                    isLeftOperandValidDate = true;
                } else if (moment(leftOperandValue, 'YYYY-MM-DD', true).isValid()) {
                    isLeftOperandValidDate = true;
                }

                if (moment(rightOperandValue, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                    isRightOperandValidDate = true;
                } else if (moment(rightOperandValue, 'YYYY-MM-DD', true).isValid()) {
                    isRightOperandValidDate = true;
                }

                if (isLeftOperandValidDate && isRightOperandValidDate) {
                    return moment(leftOperandValue).diff(moment(rightOperandValue), 'days');
                }
            }

            if (typeof leftOperandValue === 'boolean' || typeof leftOperandValue === 'string' || Array.isArray(leftOperandValue) || typeof rightOperandValue === 'boolean' || typeof rightOperandValue === 'string' || Array.isArray(rightOperandValue)) {
                throw new Error('Invalid variable types for subtraction');
            }

            if (typeof leftOperandValue === 'undefined' && typeof rightOperandValue === 'undefined') {
                return undefined;
            }

            if (typeof leftOperandValue === 'undefined') {
                if (typeof rightOperandValue === 'number') {
                    leftOperandValue = 0;
                }
            }

            if (typeof rightOperandValue === 'undefined') {
                if (typeof leftOperandValue === 'number') {
                    rightOperandValue = 0;
                }
            }

            if (typeof leftOperandValue === 'number' && typeof rightOperandValue === 'number') {
                return leftOperandValue - rightOperandValue;
            }

            throw new Error('The types of variables on either side are incompatible');

        case PieceType.GET_TIME_DIFFERENCE:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (typeof leftOperandValue !== 'string' || typeof rightOperandValue !== 'string') {
                return undefined;
            }

            let isLeftOperandValidDate = false;
            let isRightOperandValidDate = false;

            if (moment(leftOperandValue, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                isLeftOperandValidDate = true;
            } else if (moment(leftOperandValue, 'YYYY-MM-DD', true).isValid()) {
                isLeftOperandValidDate = true;
            }

            if (moment(rightOperandValue, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                isRightOperandValidDate = true;
            } else if (moment(rightOperandValue, 'YYYY-MM-DD', true).isValid()) {
                isRightOperandValidDate = true;
            }

            if (!isLeftOperandValidDate || !isRightOperandValidDate) {
                throw new Error('The date format is invalid')
            }

            const leftDate = moment(leftOperandValue);
            const rightDate = moment(rightOperandValue);
            let timeDifference: number;

            switch (piece.format) {
                case 'Years':
                    timeDifference = leftDate.diff(rightDate, 'years');
                    break;
                case 'Months':
                    timeDifference = leftDate.diff(rightDate, 'months');
                    break;
                case 'Weeks':
                    timeDifference = leftDate.diff(rightDate, 'weeks');
                    break;
                case 'Days':
                    timeDifference = leftDate.diff(rightDate, 'days');
                    break;
                case 'Hours':
                    timeDifference = leftDate.diff(rightDate, 'hours');
                    break;
                case 'Minutes':
                    timeDifference = leftDate.diff(rightDate, 'minutes');
                    break;
                case 'Seconds':
                    timeDifference = leftDate.diff(rightDate, 'seconds');
                    break;
                default:
                    timeDifference = leftDate.diff(rightDate, 'days');
            }

            if (!isNaN(timeDifference)) {
                return Math.round(timeDifference);
            } else {
                return undefined;
            }

        case PieceType.MULTIPLY:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (piece.rightOperand && isNumeric(rightOperandValue)) {
                rightOperandValue = Number(rightOperandValue);
            }

            if (leftOperandValue === '') {
                leftOperandValue = 1;
            }

            if (rightOperandValue === '') {
                rightOperandValue = 1;
            }

            if (typeof leftOperandValue === 'boolean' || typeof leftOperandValue === 'string' || Array.isArray(leftOperandValue) || typeof rightOperandValue === 'boolean' || typeof rightOperandValue === 'string' || Array.isArray(rightOperandValue)) {
                throw new Error('Invalid variable types for multiplication');
            }

            if (typeof leftOperandValue === 'undefined' && typeof rightOperandValue === 'undefined') {
                return undefined;
            }

            if (typeof leftOperandValue === 'undefined') {
                if (typeof rightOperandValue === 'number') {
                    leftOperandValue = 1;
                }
            }

            if (typeof rightOperandValue === 'undefined') {
                if (typeof leftOperandValue === 'number') {
                    rightOperandValue = 1;
                }
            }

            if (typeof leftOperandValue === 'number' && typeof rightOperandValue === 'number') {
                return leftOperandValue * rightOperandValue;
            }

            throw new Error('The types of variables on either side are incompatible');

        case PieceType.DIVIDE:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (piece.rightOperand && isNumeric(rightOperandValue)) {
                rightOperandValue = Number(rightOperandValue);
            }

            if (leftOperandValue === '') {
                leftOperandValue = 1;
            }

            if (rightOperandValue === '') {
                rightOperandValue = 1;
            }

            if (typeof leftOperandValue === 'boolean' || typeof leftOperandValue === 'string' || Array.isArray(leftOperandValue) || typeof rightOperandValue === 'boolean' || typeof rightOperandValue === 'string' || Array.isArray(rightOperandValue)) {
                throw new Error('Invalid variable types for division');
            }

            if (typeof leftOperandValue === 'undefined' && typeof rightOperandValue === 'undefined') {
                return undefined;
            }

            if (typeof leftOperandValue === 'undefined') {
                if (typeof rightOperandValue === 'number') {
                    leftOperandValue = 1;
                }
            }

            if (typeof rightOperandValue === 'undefined') {
                if (typeof leftOperandValue === 'number') {
                    rightOperandValue = 1;
                }
            }

            if (rightOperandValue === 0) {
                throw new Error('Divides by zero error');
            }

            if (typeof leftOperandValue === 'number' && typeof rightOperandValue === 'number') {
                return leftOperandValue / rightOperandValue;
            }

            throw new Error('The types of variables on either side are incompatible');

        case PieceType.EXPONENT:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (piece.rightOperand && isNumeric(rightOperandValue)) {
                rightOperandValue = Number(rightOperandValue);
            }

            if (leftOperandValue === '') {
                leftOperandValue = 1;
            }

            if (rightOperandValue === '') {
                rightOperandValue = 1;
            }

            if (typeof leftOperandValue === 'boolean' || typeof leftOperandValue === 'string' || Array.isArray(leftOperandValue) || typeof rightOperandValue === 'boolean' || typeof rightOperandValue === 'string' || Array.isArray(rightOperandValue)) {
                throw new Error('Invalid variable types for division');
            }

            if (typeof leftOperandValue === 'undefined' && typeof rightOperandValue === 'undefined') {
                return undefined;
            }

            if (typeof leftOperandValue === 'undefined') {
                if (typeof rightOperandValue === 'number') {
                    leftOperandValue = 1;
                }
            }

            if (typeof rightOperandValue === 'undefined') {
                if (typeof leftOperandValue === 'number') {
                    rightOperandValue = 1;
                }
            }

            if (typeof leftOperandValue === 'number' && typeof rightOperandValue === 'number') {
                return Math.pow(leftOperandValue, rightOperandValue);
            }

            throw new Error('The types of variables on either side are incompatible');

        case PieceType.LESSER_THAN:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (typeof leftOperandValue === 'undefined' || typeof rightOperandValue === 'undefined') {
                return false;
            }

            if (piece.leftOperand && isUUID(piece.leftOperand) && leftOperandValue === 'NaN') {
                leftOperandValue = undefined;
            }

            if (piece.rightOperand && isUUID(piece.rightOperand) && rightOperandValue === 'NaN') {
                rightOperandValue = undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (piece.rightOperand && isNumeric(rightOperandValue)) {
                rightOperandValue = Number(rightOperandValue);
            }

            if (typeof leftOperandValue === 'boolean' || Array.isArray(leftOperandValue) || typeof rightOperandValue === 'boolean' || Array.isArray(rightOperandValue)) {
                throw new Error('Invalid variable types for comparison');
            }

            if (typeof leftOperandValue === 'number' && typeof rightOperandValue === 'number') {
                return leftOperandValue < rightOperandValue;
            }

            if (typeof leftOperandValue === 'string' && typeof rightOperandValue === 'string') {
                leftOperandValue = formatDateIfWarped(leftOperandValue);
                rightOperandValue = formatDateIfWarped(rightOperandValue);
                return leftOperandValue < rightOperandValue;
            }

            if (typeof leftOperandValue === 'undefined' || typeof rightOperandValue === 'undefined') {
                return false;
            }

            return false;

        case PieceType.LESSER_THAN_OR_EQUAL_TO:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (typeof leftOperandValue === 'undefined' || typeof rightOperandValue === 'undefined') {
                return false;
            }

            if (piece.leftOperand && isUUID(piece.leftOperand) && leftOperandValue === 'NaN') {
                leftOperandValue = undefined;
            }

            if (piece.rightOperand && isUUID(piece.rightOperand) && rightOperandValue === 'NaN') {
                rightOperandValue = undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (piece.rightOperand && isNumeric(rightOperandValue)) {
                rightOperandValue = Number(rightOperandValue);
            }

            if (Array.isArray(leftOperandValue) || Array.isArray(rightOperandValue)) {
                throw new Error('Invalid variable types for comparison');
            }

            if (typeof leftOperandValue === 'number' && typeof rightOperandValue === 'number') {
                return leftOperandValue <= rightOperandValue;
            }

            if (typeof leftOperandValue === 'string' && typeof rightOperandValue === 'string') {
                leftOperandValue = formatDateIfWarped(leftOperandValue);
                rightOperandValue = formatDateIfWarped(rightOperandValue);
                return leftOperandValue <= rightOperandValue;
            }

            if (typeof leftOperandValue === 'boolean' && typeof rightOperandValue === 'boolean') {
                return leftOperandValue === rightOperandValue;
            }

            if (typeof leftOperandValue === 'undefined' || typeof rightOperandValue === 'undefined') {
                return false;
            }

            return false;

        case PieceType.GREATER_THAN:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (typeof leftOperandValue === 'undefined' || typeof rightOperandValue === 'undefined') {
                return false;
            }

            if (piece.leftOperand && isUUID(piece.leftOperand) && leftOperandValue === 'NaN') {
                leftOperandValue = undefined;
            }

            if (piece.rightOperand && isUUID(piece.rightOperand) && rightOperandValue === 'NaN') {
                rightOperandValue = undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (piece.rightOperand && isNumeric(rightOperandValue)) {
                rightOperandValue = Number(rightOperandValue);
            }

            if (typeof leftOperandValue === 'boolean' || Array.isArray(leftOperandValue) || typeof rightOperandValue === 'boolean' || Array.isArray(rightOperandValue)) {
                throw new Error('Invalid variable types for comparison');
            }

            if (typeof leftOperandValue === 'number' && typeof rightOperandValue === 'number') {
                return leftOperandValue > rightOperandValue;
            }

            if (typeof leftOperandValue === 'string' && typeof rightOperandValue === 'string') {
                leftOperandValue = formatDateIfWarped(leftOperandValue);
                rightOperandValue = formatDateIfWarped(rightOperandValue);
                return leftOperandValue > rightOperandValue;
            }

            if (typeof leftOperandValue === 'undefined' || typeof rightOperandValue === 'undefined') {
                return false;
            }

            return false;

        case PieceType.GREATER_THAN_OR_EQUAL_TO:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (piece.id === 'd0151916-6346-4245-9dd5-1b2c08be7f44') {
                // Final report start date comparison for vaxnow list generation

                if (processState.variables['b826d245-3bcc-4470-8f73-21f36a393634'] === 'c3bda44f-1c0d-4c09-b367-0e3b5dd2f3e7') {
                    console.log('Values for workflow ID: c3bda44f-1c0d-4c09-b367-0e3b5dd2f3e7');
                    console.log(leftOperandValue);
                    console.log(rightOperandValue);
                }
            }

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (typeof leftOperandValue === 'undefined' || typeof rightOperandValue === 'undefined') {
                return false;
            }

            if (piece.leftOperand && isUUID(piece.leftOperand) && leftOperandValue === 'NaN') {
                leftOperandValue = undefined;
            }

            if (piece.rightOperand && isUUID(piece.rightOperand) && rightOperandValue === 'NaN') {
                rightOperandValue = undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (piece.rightOperand && isNumeric(rightOperandValue)) {
                rightOperandValue = Number(rightOperandValue);
            }

            if (Array.isArray(leftOperandValue) || Array.isArray(rightOperandValue)) {
                throw new Error('Invalid variable types for comparison');
            }

            if (typeof leftOperandValue === 'number' && typeof rightOperandValue === 'number') {
                return leftOperandValue >= rightOperandValue;
            }

            if (typeof leftOperandValue === 'string' && typeof rightOperandValue === 'string') {
                leftOperandValue = formatDateIfWarped(leftOperandValue);
                rightOperandValue = formatDateIfWarped(rightOperandValue);
                return leftOperandValue >= rightOperandValue;
            }

            if (typeof leftOperandValue === 'boolean' && typeof rightOperandValue === 'boolean') {
                return leftOperandValue === rightOperandValue;
            }

            if (typeof leftOperandValue === 'undefined' || typeof rightOperandValue === 'undefined') {
                return false;
            }

            return false;

        case PieceType.EQUAL_TO:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (typeof leftOperandValue === 'undefined' || typeof rightOperandValue === 'undefined') {
                return false;
            }

            if (piece.leftOperand && isUUID(piece.leftOperand) && leftOperandValue === 'NaN') {
                leftOperandValue = undefined;
            }

            if (piece.rightOperand && isUUID(piece.rightOperand) && rightOperandValue === 'NaN') {
                rightOperandValue = undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (piece.rightOperand && isNumeric(rightOperandValue)) {
                rightOperandValue = Number(rightOperandValue);
            }

            if (Array.isArray(leftOperandValue) || Array.isArray(rightOperandValue)) {
                throw new Error('Invalid variable types for comparison');
            }

            if (typeof leftOperandValue === 'number' && typeof rightOperandValue === 'number') {
                return leftOperandValue === rightOperandValue;
            }

            if (typeof leftOperandValue === 'string' && typeof rightOperandValue === 'string') {
                leftOperandValue = formatDateIfWarped(leftOperandValue);
                rightOperandValue = formatDateIfWarped(rightOperandValue);
                return leftOperandValue === rightOperandValue;
            }

            if (typeof leftOperandValue === 'boolean' && typeof rightOperandValue === 'boolean') {
                return leftOperandValue === rightOperandValue;
            }

            if (typeof leftOperandValue === 'undefined' || typeof rightOperandValue === 'undefined') {
                return false;
            }

            return false;

        case PieceType.NOT_EQUAL_TO:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (typeof leftOperandValue === 'undefined' || typeof rightOperandValue === 'undefined') {
                return true;
            }

            if (piece.leftOperand && isUUID(piece.leftOperand) && leftOperandValue === 'NaN') {
                leftOperandValue = undefined;
            }

            if (piece.rightOperand && isUUID(piece.rightOperand) && rightOperandValue === 'NaN') {
                rightOperandValue = undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (piece.rightOperand && isNumeric(rightOperandValue)) {
                rightOperandValue = Number(rightOperandValue);
            }

            if (Array.isArray(leftOperandValue) || Array.isArray(rightOperandValue)) {
                throw new Error('Invalid variable types for comparison');
            }

            if (typeof leftOperandValue === 'number' && typeof rightOperandValue === 'number') {
                return leftOperandValue !== rightOperandValue;
            }

            if (typeof leftOperandValue === 'string' && typeof rightOperandValue === 'string') {
                leftOperandValue = formatDateIfWarped(leftOperandValue);
                rightOperandValue = formatDateIfWarped(rightOperandValue);
                return leftOperandValue !== rightOperandValue;
            }

            if (typeof leftOperandValue === 'boolean' || typeof rightOperandValue === 'boolean') {
                return leftOperandValue !== rightOperandValue;
            }

            if (typeof leftOperandValue === 'undefined' || typeof rightOperandValue === 'undefined') {
                return false;
            }

            return false;

        case PieceType.IN:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (typeof leftOperandValue === 'undefined') {
                return false;
            }

            if (typeof leftOperandValue !== 'string') {
                throw new Error('The left operand must always be a string value');
            }

            if (typeof rightOperandValue === 'undefined') {
                return false;
            }

            if (typeof rightOperandValue !== 'string' && !Array.isArray(rightOperandValue)) {
                throw new Error('The right operand must either be a string or an array');
            }

            if (Array.isArray(rightOperandValue)) {
                if (rightOperandValue.length === 0) {
                    // Nothing can be in the array if the array is empty
                    return false;
                }

                if (Array.isArray(rightOperandValue[0])) {
                    // In comparison is not done for table values
                    return false;
                }

                rightOperandValue = rightOperandValue as Array<string>;
            }

            return rightOperandValue.includes(leftOperandValue);

        case PieceType.NOT_IN:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (typeof leftOperandValue === 'undefined') {
                return true;
            }

            if (typeof leftOperandValue !== 'string') {
                throw new Error('The left operand must always be a string value');
            }

            if (typeof rightOperandValue === 'undefined') {
                return true;
            }

            if (typeof rightOperandValue !== 'string' && !Array.isArray(rightOperandValue)) {
                throw new Error('The right operand must either be a string or an array');
            }

            if (Array.isArray(rightOperandValue)) {
                if (rightOperandValue.length === 0) {
                    // Nothing can be in the array if the array is empty
                    return true;
                }

                if (Array.isArray(rightOperandValue[0])) {
                    // In comparison is not done for table values
                    return true;
                }

                rightOperandValue = rightOperandValue as Array<string>;
            }

            return !rightOperandValue.includes(leftOperandValue);

        case PieceType.AND:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;

            if (typeof leftOperandValue !== 'boolean' && typeof leftOperandValue !== 'undefined') {
                throw new Error('The left operand value must be a boolean, or empty')
            }

            if (typeof leftOperandValue === 'undefined') {
                leftOperandValue = false;
            }

            if (!leftOperandValue) {
                return false;
            }

            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (typeof rightOperandValue !== 'boolean' && typeof rightOperandValue !== 'undefined') {
                throw new Error('The right operand value must be a boolean, or empty')
            }

            if (typeof rightOperandValue === 'undefined') {
                rightOperandValue = false;
            }

            return rightOperandValue;

        case PieceType.OR:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;

            if (typeof leftOperandValue !== 'boolean' && typeof leftOperandValue !== 'undefined') {
                throw new Error('The left operand value must be a boolean, or empty')
            }

            if (typeof leftOperandValue === 'undefined') {
                leftOperandValue = false;
            }

            if (!!leftOperandValue) {
                return true;
            }

            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (typeof rightOperandValue !== 'boolean' && typeof rightOperandValue !== 'undefined') {
                throw new Error('The right operand value must be a boolean, or empty')
            }

            if (typeof rightOperandValue === 'undefined') {
                rightOperandValue = false;
            }

            return rightOperandValue;

        case PieceType.ADD_MONTHS:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (typeof rightOperandValue === 'undefined') {
                return undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (typeof leftOperandValue === 'boolean' || typeof leftOperandValue === 'string' || Array.isArray(leftOperandValue) || typeof rightOperandValue !== 'string') {
                throw new Error('Invalid variable types for month addition');
            }

            if (typeof rightOperandValue === 'string') {

                if (moment(rightOperandValue, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                    return moment(rightOperandValue).format('YYYY-MM-DD');
                } else if (moment(rightOperandValue, 'YYYY-MM-DD', true).isValid()) {
                    return moment(rightOperandValue).format('YYYY-MM-DD');
                } else {
                    throw new Error('The types of variables on either side are incompatible');
                }
            }

            throw new Error('Function should never reach this point - either return or throw an error before this');

        case PieceType.ADD_YEARS:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (typeof rightOperandValue === 'undefined') {
                return undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (typeof leftOperandValue === 'boolean' || typeof leftOperandValue === 'string' || Array.isArray(leftOperandValue) || typeof rightOperandValue !== 'string') {
                throw new Error('Invalid variable types for month addition');
            }

            if (typeof rightOperandValue === 'string') {

                if (moment(rightOperandValue, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                    return moment(rightOperandValue).format('YYYY-MM-DD');
                } else if (moment(rightOperandValue, 'YYYY-MM-DD', true).isValid()) {
                    return moment(rightOperandValue).format('YYYY-MM-DD');
                } else {
                    throw new Error('The types of variables on either side are incompatible');
                }
            }

            throw new Error('Function should never reach this point - either return or throw an error before this');

        case PieceType.SUBTRACT_MONTHS:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (typeof rightOperandValue === 'undefined') {
                return undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (typeof leftOperandValue === 'boolean' || typeof leftOperandValue === 'string' || Array.isArray(leftOperandValue) || typeof rightOperandValue !== 'string') {
                throw new Error('Invalid variable types for month addition');
            }

            if (typeof rightOperandValue === 'string') {
                if (moment(rightOperandValue, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                    return moment(rightOperandValue).format('YYYY-MM-DD');
                } else if (moment(rightOperandValue, 'YYYY-MM-DD', true).isValid()) {
                    return moment(rightOperandValue).format('YYYY-MM-DD');
                } else {
                    throw new Error('The types of variables on either side are incompatible');
                }
            }

            throw new Error('Function should never reach this point - either return or throw an error before this');

        case PieceType.SUBTRACT_YEARS:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? functionShortHand(piece.leftOperand) : piece.leftOperand;
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (leftOperandValue === null) {
                leftOperandValue = undefined;
            }

            if (rightOperandValue === null) {
                rightOperandValue = undefined;
            }

            if (typeof rightOperandValue === 'undefined') {
                return undefined;
            }

            if (piece.leftOperand && isNumeric(leftOperandValue)) {
                leftOperandValue = Number(leftOperandValue);
            }

            if (typeof leftOperandValue === 'boolean' || typeof leftOperandValue === 'string' || Array.isArray(leftOperandValue) || typeof rightOperandValue !== 'string') {
                throw new Error('Invalid variable types for month addition');
            }

            if (typeof rightOperandValue === 'string') {
                if (moment(rightOperandValue, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                    return moment(rightOperandValue).format('YYYY-MM-DD');
                } else if (moment(rightOperandValue, 'YYYY-MM-DD', true).isValid()) {
                    return moment(rightOperandValue).format('YYYY-MM-DD');
                } else {
                    throw new Error('The types of variables on either side are incompatible');
                }
            }

            throw new Error('Function should never reach this point - either return or throw an error before this');

        case PieceType.PICK_FIRST_N_ELEMENTS:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? Number(functionShortHand(piece.leftOperand)) : Number(piece.leftOperand);
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (isNaN(leftOperandValue)) {
                throw new Error('The left value must be a number')
            }

            if (Array.isArray(rightOperandValue)) {
                if (rightOperandValue.length === 0) {
                    return [];
                } else {
                    return rightOperandValue.slice(0, leftOperandValue);
                }
            } else if (typeof rightOperandValue === 'string') {
                if (rightOperandValue.length === 0) {
                    return [];
                } else {
                    return rightOperandValue.substring(0, leftOperandValue);
                }
            } else {
                throw new Error('The right-side value must be an array or a string');
            }

        case PieceType.PICK_LAST_N_ELEMENTS:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? Number(functionShortHand(piece.leftOperand)) : Number(piece.leftOperand);
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (isNaN(leftOperandValue)) {
                throw new Error('The left value must be a number')
            }

            if (Array.isArray(rightOperandValue)) {
                if (rightOperandValue.length === 0) {
                    return [];
                } else {
                    return rightOperandValue.slice(-leftOperandValue);
                }
            } else if (typeof rightOperandValue === 'string') {
                if (rightOperandValue.length === 0) {
                    return [];
                } else {
                    return rightOperandValue.substring(rightOperandValue.length - leftOperandValue, rightOperandValue.length);
                }
            } else {
                throw new Error('The right-side value must be an array or a string');
            }

        case PieceType.PICK_NTH_ELEMENT:
            leftOperandValue = piece.leftOperand && isUUID(piece.leftOperand) ? Number(functionShortHand(piece.leftOperand)) : Number(piece.leftOperand);
            rightOperandValue = piece.rightOperand && isUUID(piece.rightOperand) ? functionShortHand(piece.rightOperand) : piece.rightOperand;

            if (typeof leftOperandValue === 'undefined' || typeof rightOperandValue === 'undefined') {
                return undefined;
            }

            if (isNaN(leftOperandValue)) {
                throw new Error('The left value must be a number')
            }

            if (Array.isArray(rightOperandValue)) {
                if (rightOperandValue.length < leftOperandValue) {
                    return undefined;
                } else {
                    return rightOperandValue[leftOperandValue - 1];
                }
            } else if (typeof rightOperandValue === 'string') {
                if (rightOperandValue.length < leftOperandValue) {
                    return undefined;
                } else {
                    return rightOperandValue[leftOperandValue - 1];
                }
            } else {
                throw new Error('The right-side value must be an array or a string');
            }

        case PieceType.NOT:
            operandValue = piece.operand && isUUID(piece.operand) ? functionShortHand(piece.operand) : piece.operand;

            if (typeof operandValue !== 'boolean' && typeof operandValue !== 'undefined') {
                throw new Error('The operand value must be a boolean, or empty')
            }

            if (typeof operandValue === 'undefined') {
                operandValue = false;
            }

            return !operandValue;

        case PieceType.GET_DATE:
            operandValue = piece.operand && isUUID(piece.operand) ? functionShortHand(piece.operand) : piece.operand;

            if (typeof operandValue === 'undefined') {
                return undefined;
            }

            if (typeof operandValue !== 'string') {
                throw new Error('The value for this must be a string');
            }

            dateSegments = operandValue.split('-').map(dateSegment => Number(dateSegment));

            if (dateSegments.length !== 3) {
                throw new Error('There must be exactly 3 segments for a date');
            }

            return dateSegments[2];

        case PieceType.GET_DAY:
            operandValue = piece.operand && isUUID(piece.operand) ? functionShortHand(piece.operand) : piece.operand;

            if (typeof operandValue === 'undefined') {
                return undefined;
            }

            if (typeof operandValue !== 'string') {
                throw new Error('The value for this must be a string');
            }

            dateSegments = operandValue.split('-').map(dateSegment => Number(dateSegment));

            if (dateSegments.length !== 3) {
                throw new Error('There must be exactly 3 segments for a date');
            }

            const newDate = new Date(dateSegments[0], dateSegments[1] - 1, dateSegments[2]);

            return newDate.getDay();

        case PieceType.GET_MONTH:
            operandValue = piece.operand && isUUID(piece.operand) ? functionShortHand(piece.operand) : piece.operand;

            if (typeof operandValue === 'undefined') {
                return undefined;
            }

            if (typeof operandValue !== 'string') {
                throw new Error('The value for this must be a string');
            }

            dateSegments = operandValue.split('-').map(dateSegment => Number(dateSegment));

            if (dateSegments.length !== 3) {
                throw new Error('There must be exactly 3 segments for a date');
            }

            return dateSegments[1];

        case PieceType.GET_READABLE_MONTH:
            operandValue = piece.operand && isUUID(piece.operand) ? functionShortHand(piece.operand) : piece.operand;

            if (typeof operandValue === 'undefined') {
                return undefined;
            }

            if (typeof operandValue !== 'string') {
                throw new Error('The value for this must be a string');
            }

            dateSegments = operandValue.split('-').map(dateSegment => Number(dateSegment));

            if (dateSegments.length !== 3) {
                throw new Error('There must be exactly 3 segments for a date');
            }

            switch (dateSegments[1]) {
                case 1: return 'January';
                case 2: return 'February';
                case 3: return 'March';
                case 4: return 'April';
                case 5: return 'May';
                case 6: return 'June';
                case 7: return 'July';
                case 8: return 'August';
                case 9: return 'September';
                case 10: return 'October';
                case 11: return 'November';
                case 12: return 'December';
                default: throw new Error('Unknown month value');
            }

        case PieceType.GET_YEAR:
            operandValue = piece.operand && isUUID(piece.operand) ? functionShortHand(piece.operand) : piece.operand;

            if (typeof operandValue === 'undefined') {
                return undefined;
            }

            if (typeof operandValue !== 'string') {
                throw new Error('The value for this must be a string');
            }

            dateSegments = operandValue.split('-').map(dateSegment => Number(dateSegment));

            if (dateSegments.length !== 3) {
                throw new Error('There must be exactly 3 segments for a date');
            }

            return dateSegments[0];

        case PieceType.PICK_FIRST_ELEMENT:
            operandValue = piece.operand && isUUID(piece.operand) ? functionShortHand(piece.operand) : piece.operand;

            if (typeof operandValue === 'undefined') {
                return undefined;
            }

            if (!Array.isArray(operandValue)) {
                throw new Error('The operand must be an iterable');
            }

            if (operandValue.length === 0) {
                return undefined;
            } else {
                return operandValue[0];
            }

        case PieceType.PICK_LAST_ELEMENT:
            operandValue = piece.operand && isUUID(piece.operand) ? functionShortHand(piece.operand) : piece.operand;

            if (typeof operandValue === 'undefined') {
                return undefined;
            }

            if (!Array.isArray(operandValue)) {
                throw new Error('The operand must be an iterable');
            }

            if (operandValue.length === 0) {
                return undefined;
            } else {
                return operandValue[operandValue.length - 1];
            }

        case PieceType.BOOLEAN_TO_VARIABLE:
            if (!piece.operand) {
                return false;
            }

            operandValue = functionShortHand(piece.operand);

            if (typeof operandValue !== 'boolean') {
                throw new Error('The variable type must be boolean');
            }

            return operandValue;

        case PieceType.VARIABLE_TO_BOOLEAN:
            if (!piece.operand) {
                return false;
            }

            operandValue = functionShortHand(piece.operand);

            if (typeof operandValue === 'undefined') {
                operandValue = false;
            }

            if (operandValue === 'Yes') {
                return true;
            }

            if (operandValue === 'No') {
                return false;
            }

            if (typeof operandValue !== 'boolean') {
                throw new Error('The variable type must be boolean');
            }

            return operandValue;

        case PieceType.IS_DEFINED:
            if (!piece.operand) {
                return false;
            }

            operandValue = functionShortHand(piece.operand);

            const isEmptyStringForDefined = typeof operandValue === 'string' && !operandValue;

            return typeof operandValue !== 'undefined' && operandValue !== null && !isEmptyStringForDefined;

        case PieceType.IS_NOT_DEFINED:
            if (!piece.operand) {
                return false;
            }

            operandValue = functionShortHand(piece.operand);

            const isEmptyStringForUndefined = typeof operandValue === 'string' && !operandValue;

            return !(typeof operandValue !== 'undefined' && operandValue !== null && !isEmptyStringForUndefined);

        case PieceType.LENGTH:
            if (!piece.operand) {
                return undefined;
            }

            operandValue = functionShortHand(piece.operand);

            if (typeof operandValue === 'undefined') {
                return undefined;
            } else if (typeof operandValue === 'string') {
                // eslint-disable-next-line no-useless-escape
                const baseSixtyFourRegex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
                if (operandValue.match(baseSixtyFourRegex)) {
                    const file = dataURLtoFile(operandValue, 'test');
                    return file.size;
                } else {
                    return operandValue.length;
                }
            } else if (Array.isArray(operandValue)) {
                return operandValue.length;
            } else if (typeof operandValue === 'number') {
                return String(operandValue).length;
            } else {
                throw new Error('Length does not apply to this value');
            }

        case PieceType.TRANSLATE:
            if (!piece.operand) {
                return undefined;
            }

            operandValue = isUUID(piece.operand) ? functionShortHand(piece.operand) : piece.operand;

            if (typeof operandValue !== 'string') {
                return undefined;
            } else {
                const trimmedOperand = operandValue.trim();
                const translation = translatePhrase(trimmedOperand);
                return operandValue.replace(trimmedOperand, translation);
            }

        case PieceType.STRUCTURE:

            if (piece.role) {
                return piece.role;
            } else if (piece.level) {
                return piece.level;
            } else if (piece.project) {
                return piece.project;
            } else {
                return undefined;
            }

        case PieceType.STATIC_DATA:
            if (Array.isArray(piece.selectedFragments) && piece.selectedFragments.length > 0) {
                return piece.selectedFragments[piece.selectedFragments.length - 1];
            } else if (piece.staticDataHolder) {
                return piece.staticDataHolder
            } else {
                return undefined;
            }

        case PieceType.GET_AFFILIATION_FROM_WORKFLOW:
            const workflowId = piece.variablePiece && isUUID(piece.variablePiece) ? functionShortHand(piece.variablePiece) : undefined;

            if (typeof workflowId !== 'string') {
                throw new Error('The get affiliation piece should be assigned a workflow value');
            }

            const workflow = applicationState.workflows.byId[workflowId];

            if (typeof workflow === 'undefined') {
                throw new Error('A workflow does not exist for this piece in get affiliation from workflow');
            }

            return workflow.affiliatedEntity;

        default:
            throw new Error('Cannot get value from this piece');
    }
}

export function pushToExecutionStack(processState: FlowchartProcessState, pieceId: string) {
    processState.executionStack = processState.executionStack.concat([pieceId]);
}

export function popExecutionStack(processState: FlowchartProcessState) {
    processState.executionStack = processState.executionStack.slice(0, -1);
}

export function incrementForCount(processState: FlowchartProcessState, forPieceId: string) {
    processState.forIterationCounts = {
        ...processState.forIterationCounts,
        [forPieceId]: processState.forIterationCounts[forPieceId] ? processState.forIterationCounts[forPieceId] + 1 : 1
    }
}

export function resetForCount(processState: FlowchartProcessState, forPieceId: string) {
    delete processState.forIterationCounts[forPieceId];
}

export function updateLastComputedPiece(processState: FlowchartProcessState, lastComputedPiece: string) {
    processState.lastComputedPiece = lastComputedPiece;
}

export function updateCreatedWorkflowId(processState: FlowchartProcessState, createdWorkflowId: string) {
    processState.createdWorkflowId = createdWorkflowId;
}

export function updateVariableValue(processState: FlowchartProcessState, variableId: string, value: VariableValueType) {
    processState.variables = {
        ...processState.variables,
        [variableId]: value,
    };
}

export function updateWorkflowCustomFieldValue(processState: WorkflowProcessState, customFieldId: string, value: CustomFieldValueType) {
    processState.customFields = {
        ...processState.customFields,
        [customFieldId]: value,
    }
}

export function updateWorkflowCustomFieldValueForMember(processState: WorkflowProcessState, customFieldId: string, value: CustomFieldValueType, memberId: string) {
    const customFieldData = processState.customFields[customFieldId];
    let membersData = {};

    if (typeof customFieldData === 'object') {
        membersData = {
            ...customFieldData
        };
    }

    processState.customFields = {
        ...processState.customFields,
        [customFieldId]: {
            ...membersData,
            [memberId]: value,
        },
    }
}

export function getVariableValueType(variable: IVariable, nesting: Array<NestingData>) {

    if (nesting.length === 0) {
        return variable.type;
    } else {
        const lastNestedData = nesting[nesting.length - 1].type;

        return lastNestedData as VariableType;
    }

}

function getOperandType(operand: string, applicationState: ApplicationState): VariableType | undefined {

    if (isUUID(operand)) {
        return getPieceValueType(operand, applicationState.flowchart.pieces, applicationState.flowchart.variables, applicationState);
    }

    if (operand.split('-').length === 3) {
        return VariableType.DATE;
    }

    if (!operand) {
        return undefined;
    }

    if (isNumeric(operand)) {
        return VariableType.NUMBER;
    }

    return VariableType.TEXT;
}

export function getPieceValueType(pieceId: string, piecesState: PieceState, variablesState: VariableState, state?: ApplicationState): VariableType | undefined {
    const piece = piecesState.byId[pieceId];
    const applicationState = state ? state : store.getState();

    let operandType: VariableType | undefined;
    let leftOperandType: VariableType | undefined;
    let rightOperandType: VariableType | undefined;
    let customField: CustomField | WorkflowTypeCustomField | undefined = undefined;

    switch (piece.type) {

        case PieceType.VARIABLE:
            const variableId = piece.variable;

            if (!variableId) {
                return undefined;
            }

            const variable = variablesState.byId[variableId];

            return getVariableValueType(variable, piece.nesting || []);

        case PieceType.CUSTOM_FIELD:
            if (!!piece.customFieldOption) {
                return VariableType.TEXT;
            }

            if (typeof piece.customField === 'undefined') {
                return undefined;
            }

            if (piece.customField in applicationState.structure.levels.customFields.byId) {
                customField = applicationState.structure.levels.customFields.byId[piece.customField];
            } else if (piece.customField in applicationState.structure.roles.customFields.byId) {
                customField = applicationState.structure.roles.customFields.byId[piece.customField];
            } else if (piece.customField in applicationState.users.customFields.byId) {
                customField = applicationState.users.customFields.byId[piece.customField];
            } else if (piece.customField in applicationState.members.types.customFields.byId) {
                customField = applicationState.members.types.customFields.byId[piece.customField];
            } else if (piece.customField in applicationState.groups.types.customFields.byId) {
                customField = applicationState.groups.types.customFields.byId[piece.customField];
            } else if (piece.customField in applicationState.workflows.types.customFields.byId) {
                customField = applicationState.workflows.types.customFields.byId[piece.customField];
            }

            if (typeof customField === 'undefined') {
                return undefined;
            }

            switch (customField.type) {
                case FieldType.NUMBER:
                    return VariableType.NUMBER;
                case FieldType.DATE:
                    return VariableType.DATE;
                case FieldType.MULTI_SELECT:
                    return VariableType.TEXT_LIST;
                default:
                    return VariableType.TEXT;
            }

        case PieceType.GET_VALUE:
            if (typeof piece.customFieldId === 'undefined') {
                return undefined;
            }

            if (piece.customFieldId in applicationState.structure.levels.customFields.byId) {
                customField = applicationState.structure.levels.customFields.byId[piece.customFieldId];
            } else if (piece.customFieldId in applicationState.structure.roles.customFields.byId) {
                customField = applicationState.structure.roles.customFields.byId[piece.customFieldId];
            } else if (piece.customFieldId in applicationState.users.customFields.byId) {
                customField = applicationState.users.customFields.byId[piece.customFieldId];
            } else if (piece.customFieldId in applicationState.members.types.customFields.byId) {
                customField = applicationState.members.types.customFields.byId[piece.customFieldId];
            } else if (piece.customFieldId in applicationState.groups.types.customFields.byId) {
                customField = applicationState.groups.types.customFields.byId[piece.customFieldId];
            } else if (piece.customFieldId in applicationState.workflows.types.customFields.byId) {
                customField = applicationState.workflows.types.customFields.byId[piece.customFieldId];
            }

            if (typeof customField === 'undefined') {
                return undefined;
            }

            switch (customField.type) {
                case FieldType.NUMBER:
                    return VariableType.NUMBER;
                case FieldType.DATE:
                    return VariableType.DATE;
                default:
                    return VariableType.TEXT;
            }

        case PieceType.STATUS:
            return VariableType.TEXT;

        case PieceType.FORMAT:
            return VariableType.TEXT;

        case PieceType.VARIABLE_TO_BOOLEAN:
        case PieceType.BOOLEAN_TO_VARIABLE:
        case PieceType.LESSER_THAN:
        case PieceType.LESSER_THAN_OR_EQUAL_TO:
        case PieceType.GREATER_THAN:
        case PieceType.GREATER_THAN_OR_EQUAL_TO:
        case PieceType.EQUAL_TO:
        case PieceType.NOT_EQUAL_TO:
        case PieceType.IN:
        case PieceType.NOT_IN:
        case PieceType.AND:
        case PieceType.OR:
        case PieceType.NOT:
        case PieceType.TRUE:
        case PieceType.FALSE:
        case PieceType.IS_DEFINED:
        case PieceType.IS_NOT_DEFINED:
            return VariableType.BOOLEAN;

        case PieceType.ADD:
            if (typeof piece.leftOperand === 'undefined' || typeof piece.rightOperand === 'undefined') {
                return undefined;
            }

            leftOperandType = getOperandType(piece.leftOperand, applicationState);
            rightOperandType = getOperandType(piece.rightOperand, applicationState);

            if (typeof leftOperandType === 'undefined' || typeof rightOperandType === 'undefined') {
                return undefined;
            }

            if (leftOperandType === VariableType.DATE && rightOperandType === VariableType.NUMBER) {
                return VariableType.DATE;
            } else if (leftOperandType === VariableType.NUMBER && rightOperandType === VariableType.NUMBER) {
                return VariableType.NUMBER;
            } else if (
                (leftOperandType === VariableType.TEXT && rightOperandType === VariableType.TEXT) ||
                (leftOperandType === VariableType.NUMBER && rightOperandType === VariableType.TEXT) ||
                (leftOperandType === VariableType.TEXT && rightOperandType === VariableType.NUMBER)
            ) {
                return VariableType.TEXT;
            } else if (
                (leftOperandType === VariableType.DATE && rightOperandType === VariableType.TEXT) ||
                (leftOperandType === VariableType.TEXT && rightOperandType === VariableType.DATE)
            ) {
                return VariableType.TEXT;
            } else if (
                (leftOperandType === VariableType.TEXT && rightOperandType === VariableType.TEXT_LIST) ||
                (leftOperandType === VariableType.TEXT_LIST && rightOperandType === VariableType.TEXT)
            ) {
                return VariableType.TEXT_LIST;
            } else {
                return undefined;
            }

        case PieceType.SUBTRACT:
            if (typeof piece.leftOperand === 'undefined' || typeof piece.rightOperand === 'undefined') {
                return undefined;
            }

            leftOperandType = getOperandType(piece.leftOperand, applicationState);
            rightOperandType = getOperandType(piece.rightOperand, applicationState);

            if (typeof leftOperandType === 'undefined' || typeof rightOperandType === 'undefined') {
                return undefined;
            }

            if (leftOperandType === VariableType.DATE && rightOperandType === VariableType.NUMBER) {
                return VariableType.DATE;
            } else if (leftOperandType === VariableType.NUMBER && rightOperandType === VariableType.NUMBER) {
                return VariableType.NUMBER;
            } else if (leftOperandType === VariableType.DATE && rightOperandType === VariableType.DATE) {
                return VariableType.NUMBER;
            } else {
                return undefined;
            }

        case PieceType.GET_TIME_DIFFERENCE:
            return VariableType.NUMBER;

        case PieceType.MULTIPLY:
        case PieceType.DIVIDE:
        case PieceType.EXPONENT:
            if (typeof piece.leftOperand === 'undefined' || typeof piece.rightOperand === 'undefined') {
                return undefined;
            }

            leftOperandType = getOperandType(piece.leftOperand, applicationState);
            rightOperandType = getOperandType(piece.rightOperand, applicationState);

            if (typeof leftOperandType === 'undefined' || typeof rightOperandType === 'undefined') {
                return undefined;
            }

            if (leftOperandType === VariableType.NUMBER && rightOperandType === VariableType.NUMBER) {
                return VariableType.NUMBER;
            } else {
                return undefined;
            }

        case PieceType.ADD_MONTHS:
        case PieceType.ADD_YEARS:
        case PieceType.SUBTRACT_MONTHS:
        case PieceType.SUBTRACT_YEARS:
            if (typeof piece.rightOperand === 'undefined') {
                return undefined;
            } else {
                return VariableType.DATE;
            }

        case PieceType.PICK_FIRST_ELEMENT:
            if (typeof piece.operand === 'undefined') {
                return undefined;
            }

            operandType = getOperandType(piece.operand, applicationState);

            if (typeof operandType === 'undefined') {
                return undefined;
            }

            switch (operandType) {
                case VariableType.TEXT_LIST:
                    return VariableType.TEXT;
                case VariableType.ROLES_LIST:
                    return VariableType.ROLE;
                case VariableType.USERS_LIST:
                    return VariableType.USER;
                case VariableType.LEVELS_LIST:
                    return VariableType.LEVEL;
                case VariableType.GROUPS_LIST:
                    return VariableType.GROUP;
                case VariableType.MEMBERS_LIST:
                    return VariableType.MEMBER;
                case VariableType.PROJECTS_LIST:
                    return VariableType.PROJECT;
                case VariableType.LOCATIONS_LIST:
                    return VariableType.LOCATION;
                case VariableType.WORKFLOWS_LIST:
                    return VariableType.WORKFLOW;
                case VariableType.DATA_FRAGMENTS_LIST:
                    return VariableType.DATA_FRAGMENT;
                default:
                    return undefined;
            }

        case PieceType.PICK_FIRST_N_ELEMENTS:
            if (typeof piece.rightOperand === 'undefined') {
                return undefined;
            }

            rightOperandType = getOperandType(piece.rightOperand, applicationState);

            if (typeof rightOperandType === 'undefined') {
                return undefined;
            }

            return rightOperandType;

        case PieceType.PICK_LAST_ELEMENT:
            if (typeof piece.operand === 'undefined') {
                return undefined;
            }

            operandType = getOperandType(piece.operand, applicationState);

            if (typeof operandType === 'undefined') {
                return undefined;
            }

            switch (operandType) {
                case VariableType.TEXT_LIST:
                    return VariableType.TEXT;
                case VariableType.ROLES_LIST:
                    return VariableType.ROLE;
                case VariableType.USERS_LIST:
                    return VariableType.USER;
                case VariableType.LEVELS_LIST:
                    return VariableType.LEVEL;
                case VariableType.GROUPS_LIST:
                    return VariableType.GROUP;
                case VariableType.MEMBERS_LIST:
                    return VariableType.MEMBER;
                case VariableType.PROJECTS_LIST:
                    return VariableType.PROJECT;
                case VariableType.LOCATIONS_LIST:
                    return VariableType.LOCATION;
                case VariableType.WORKFLOWS_LIST:
                    return VariableType.WORKFLOW;
                case VariableType.DATA_FRAGMENTS_LIST:
                    return VariableType.DATA_FRAGMENT;
                default:
                    return undefined;
            }

        case PieceType.PICK_LAST_N_ELEMENTS:
            if (typeof piece.rightOperand === 'undefined') {
                return undefined;
            }

            rightOperandType = getOperandType(piece.rightOperand, applicationState);

            if (typeof rightOperandType === 'undefined') {
                return undefined;
            }

            return rightOperandType;

        case PieceType.PICK_NTH_ELEMENT:
            if (typeof piece.rightOperand === 'undefined') {
                return undefined;
            }

            operandType = getOperandType(piece.rightOperand, applicationState);

            if (typeof operandType === 'undefined') {
                return undefined;
            }

            switch (operandType) {
                case VariableType.TEXT_LIST:
                    return VariableType.TEXT;
                case VariableType.ROLES_LIST:
                    return VariableType.ROLE;
                case VariableType.USERS_LIST:
                    return VariableType.USER;
                case VariableType.LEVELS_LIST:
                    return VariableType.LEVEL;
                case VariableType.GROUPS_LIST:
                    return VariableType.GROUP;
                case VariableType.MEMBERS_LIST:
                    return VariableType.MEMBER;
                case VariableType.PROJECTS_LIST:
                    return VariableType.PROJECT;
                case VariableType.LOCATIONS_LIST:
                    return VariableType.LOCATION;
                case VariableType.WORKFLOWS_LIST:
                    return VariableType.WORKFLOW;
                case VariableType.DATA_FRAGMENTS_LIST:
                    return VariableType.DATA_FRAGMENT;
                default:
                    return undefined;
            }

        case PieceType.ADD_TO_LIST:
        case PieceType.REMOVE_FROM_LIST:
            if (typeof piece.listVariable === 'undefined') {
                return undefined;
            }

            rightOperandType = getOperandType(piece.listVariable, applicationState);

            if (typeof operandType === 'undefined') {
                return undefined;
            }

            return rightOperandType;

        case PieceType.MY_GROUPS:
            return VariableType.GROUPS_LIST;

        case PieceType.MY_MEMBERS:
            return VariableType.MEMBERS_LIST;

        case PieceType.FINANCIAL_YEAR_MONTHS:
            return VariableType.TEXT_LIST;

        case PieceType.GET_ENTITIES:
            switch (piece.entity) {
                case 'LOCATION':
                    return VariableType.LOCATIONS_LIST;
                case 'USER':
                    return VariableType.USERS_LIST;
                case 'MEMBER':
                    return VariableType.MEMBERS_LIST;
                case 'GROUP':
                    return VariableType.GROUPS_LIST;
                case 'WORKFLOW':
                    return VariableType.WORKFLOWS_LIST;
                default:
                    return undefined;
            }

        case PieceType.TRANSLATE:
            return VariableType.TEXT;

        case PieceType.LENGTH:
            if (typeof piece.operand === 'undefined') {
                return undefined;
            }

            operandType = getOperandType(piece.operand, applicationState);

            if (typeof operandType === 'undefined') {
                return undefined;
            } else if (typeof operandType === 'string' || typeof operandType === 'number' || Array.isArray(operandType)) {
                return VariableType.NUMBER;
            } else {
                throw new Error('Length does not apply to this type of operand');
            }

        case PieceType.STRUCTURE:

            if (piece.role) {
                return VariableType.ROLE;
            } else if (piece.level) {
                return VariableType.LEVEL;
            } else if (piece.project) {
                return VariableType.PROJECT;
            } else {
                return undefined;
            }

        case PieceType.STATIC_DATA:
            if (Array.isArray(piece.selectedFragments) && piece.selectedFragments.length > 0) {
                return VariableType.DATA_FRAGMENT;
            } else if (piece.staticDataHolder) {
                return VariableType.STATIC_DATA;
            } else {
                return undefined;
            }

        case PieceType.GET_DATE:
            return VariableType.NUMBER;

        case PieceType.GET_MONTH:
            return VariableType.NUMBER;

        case PieceType.GET_YEAR:
            return VariableType.NUMBER;

        case PieceType.GET_READABLE_MONTH:
            return VariableType.TEXT;

        case PieceType.TODAY:
            return VariableType.DATE;

        case PieceType.NOW:
            return VariableType.TEXT;

        case PieceType.LOGGED_IN_USER:
            return VariableType.USER;

        case PieceType.GET_AFFILIATION_FROM_WORKFLOW:
            if (!piece.workflowType) {
                return undefined;
            }

            const workflowType = applicationState.workflows.types.byId[piece.workflowType];

            switch (workflowType.affiliation) {
                case 'member':
                    return VariableType.MEMBER;
                case 'group':
                    return VariableType.GROUP;
                default:
                    return undefined;
            }

        case PieceType.GROUPED_ANSWER:
            if (!piece.customField) {
                return undefined;
            }

            const customFieldForGroupedAnswer = applicationState.workflows.types.customFields.byId[piece.customField];

            if (typeof customFieldForGroupedAnswer === 'undefined') {
                return undefined;
            }

            switch (customFieldForGroupedAnswer.type) {
                case FieldType.NUMBER:
                    return VariableType.NUMBER;
                case FieldType.DATE:
                    return VariableType.DATE;
                case FieldType.MULTI_SELECT:
                    return VariableType.TEXT_LIST;
                default:
                    return VariableType.TEXT;
            }


        default:
            throw new Error('Unrecognized piece type');
    }
}

export function updateDisplayingQuestion(processState: FlowchartProcessState, questionId: string) {
    processState.displayingQuestionPieceId = questionId;
}

export function updateDisplayingShowPiece(processState: FlowchartProcessState, showPieceId: string) {
    processState.displayingShowPieceId = showPieceId;
}

export function updateDisplayingGroupPiece(processState: FlowchartProcessState, groupPieceId: string) {
    processState.displayingGroupPieceId = groupPieceId;
}

export function updateDisplayingTransferPiece(processState: FlowchartProcessState, transferPieceId: string) {
    processState.displayingTransferPieceId = transferPieceId;
}

export function updateDisplayingContinuePiece(processState: FlowchartProcessState, continuePieceId: string) {
    processState.displayingContinuePieceId = continuePieceId;
}

export function updateDisplayingAddWorkflowPiece(processState: FlowchartProcessState, addWorkflowPieceId: string) {
    processState.displayingAddWorkflowPieceId = addWorkflowPieceId;
}

export function getNextPieceId(applicationState: ApplicationState, processState: FlowchartProcessState, startPiece: string | undefined, getNextPieceIdForFlowchart: () => string | undefined, getPieceValueFromAbove?: (pieceId: string) => VariableValueType): string | undefined {
    const piecesData = applicationState.flowchart.pieces;

    const pieceValueShortHand = getPieceValueFromAbove ? getPieceValueFromAbove : getPieceValue.bind({}, applicationState, processState);

    if (!processState.lastComputedPiece) {
        if (startPiece) {
            return startPiece;
        } else {
            return undefined;
        }
    }

    const lastComputedPiece = piecesData.byId[processState.lastComputedPiece];

    if (lastComputedPiece.type === PieceType.END || lastComputedPiece.type === PieceType.RETURN) {
        return undefined;
    }

    if ('nextPiece' in lastComputedPiece && !!lastComputedPiece.nextPiece) {

        // Return the next piece only if the last computed piece is not a for or split piece. If it is a for piece, we will need to check whether the next piece is inside or after the for loop. Similarly, for a split piece, we need to see if any of the if conditions match
        if (lastComputedPiece.type !== PieceType.FOR && lastComputedPiece.type !== PieceType.SPLIT && !!lastComputedPiece.nextPiece) {
            return lastComputedPiece.nextPiece;
        }
    }

    // Check if there are any pieces in the stack that can be executed.
    if (processState.executionStack.length > 0) {
        const executionStackPiece = piecesData.byId[processState.executionStack[processState.executionStack.length - 1]];

        switch (executionStackPiece.type) {
            case PieceType.FOR:

                if (!executionStackPiece.iterableVariable) {
                    return getNextPieceIdForFlowchart();
                }

                let iterable = isUUID(executionStackPiece.iterableVariable) ? pieceValueShortHand(executionStackPiece.iterableVariable) : executionStackPiece.iterableVariable;

                if (!Array.isArray(iterable)) {
                    if (typeof iterable === 'undefined') {
                        iterable = [];
                    } else if (!isNaN(Number(iterable))) {
                        iterable = Array(Math.floor(Number(iterable))).fill('0').map((value, index) => String(index));
                    } else {
                        throw new Error('Value given by this field must be an iterable');
                    }
                }

                if (iterable.length === 0) {
                    popExecutionStack(processState);
                    resetForCount(processState, executionStackPiece.id);

                    if (executionStackPiece.nextPiece) {
                        return executionStackPiece.nextPiece;
                    } else {
                        // If there is no next piece for this for loop, run this entire algorithm again.
                        return getNextPieceIdForFlowchart();
                    }
                }

                if (processState.lastComputedPiece === executionStackPiece.id) {
                    if (executionStackPiece.innerPiece) {
                        return executionStackPiece.innerPiece;
                    } else {
                        // If there is no inner piece for this for loop, run this entire algorithm again.
                        return getNextPieceIdForFlowchart();
                    }
                }

                const iterationCount = processState.forIterationCounts[executionStackPiece.id];

                if (iterable.length === iterationCount) {
                    popExecutionStack(processState);
                    resetForCount(processState, executionStackPiece.id);

                    if (executionStackPiece.nextPiece) {
                        return executionStackPiece.nextPiece;
                    } else {
                        // If there is no next piece for this for loop, run this entire algorithm again.
                        return getNextPieceIdForFlowchart();
                    }
                } else {
                    return executionStackPiece.id;
                }

            case PieceType.SPLIT:

                if (processState.lastComputedPiece === executionStackPiece.id) {
                    if (executionStackPiece.ifPieceData) {
                        for (let i = 0; i < executionStackPiece.ifPieceData.length; i += 1) {
                            const ifPiece = executionStackPiece.ifPieceData[i];

                            if (ifPiece.conditionPiece) {

                                if (isUUID(ifPiece.conditionPiece)) {
                                    const conditionValue = pieceValueShortHand(ifPiece.conditionPiece);

                                    if (typeof conditionValue !== 'boolean' && typeof conditionValue !== 'undefined') {
                                        throw new Error('Variable value type must be boolean');
                                    }

                                    if (conditionValue) {
                                        if (ifPiece.nextPiece) {
                                            return ifPiece.nextPiece;
                                        } else {
                                            return undefined;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }

                popExecutionStack(processState);
                resetForCount(processState, executionStackPiece.id);

                if (executionStackPiece.nextPiece) {
                    return executionStackPiece.nextPiece;
                } else {
                    // If there is no next piece for this split piece, run this entire algorithm again.
                    return getNextPieceIdForFlowchart();
                }

            default:
                throw new Error('Cannot handle execution stack entry for this piece');
        }
    }

    return undefined;
}

// If it returns false, stop execution and display what needs to be displayed. Otherwise, feel free to get the next piece and continue executing
export function executePiece(applicationState: ApplicationState, processState: FlowchartProcessState, pieceId: string, getPieceValueFromAbove?: (pieceId: string) => VariableValueType) {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];

    let variableValue: VariableValueType;
    let listValue: VariableValueType;

    const pieceValueShortHand = getPieceValueFromAbove ? getPieceValueFromAbove : getPieceValue.bind({}, applicationState, processState);

    switch (piece.type) {
        case PieceType.FOR:
            const iterationCount = processState.forIterationCounts[piece.id] || 0;

            if (!piece.iterableVariable) {
                throw new Error('The for loop needs to have a variable to iterate over');
            } else if (!piece.loopVariable) {
                throw new Error('The for loop needs to have a variable in which to store the iterated value');
            }

            let iterable = isUUID(piece.iterableVariable) ? pieceValueShortHand(piece.iterableVariable) : piece.iterableVariable;

            if (!Array.isArray(iterable)) {
                if (typeof iterable === 'undefined') {
                    iterable = [];
                } else if (!isNaN(Number(iterable))) {
                    iterable = Array(Math.floor(Number(iterable))).fill('0').map((value, index) => String(index));
                } else {
                    throw new Error('Value given by this field must be an iterable');
                }
            }

            const newValueInLoop = iterable[iterationCount];

            // Push the for piece on the execution stack if it is not already on top.
            if (processState.executionStack.length === 0 || processState.executionStack[processState.executionStack.length - 1] !== pieceId) {
                pushToExecutionStack(processState, pieceId);
            }

            incrementForCount(processState, pieceId);

            updateVariableValue(processState, piece.loopVariable, newValueInLoop);
            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.SPLIT:
            pushToExecutionStack(processState, pieceId);
            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.START:
            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.GET:

            if (!piece.variablePiece) {
                throw new Error('There must be a variable piece from which to fetch the custom field');
            }

            if (!piece.variableToCopy) {
                throw new Error('There must be a variable to copy to in this piece');
            }

            if (!piece.customFieldId) {
                throw new Error('The piece must point to a field');
            }

            const fetchingVariable = pieceValueShortHand(piece.variablePiece);

            if (typeof fetchingVariable !== 'string' || !isUUID(fetchingVariable)) {
                throw new Error('The fetching variable must be a UUID for an entity');
            }

            const getVariableType = piece.variablePiece && isUUID(piece.variablePiece) ? getPieceValueType(piece.variablePiece, applicationState.flowchart.pieces, applicationState.flowchart.variables, applicationState) : VariableType.TEXT;

            let customFieldValue: CustomFieldValueType;
            let rawVariableValue: CustomFieldValueType;
            let memberVariablePiece: AllPieceTypes;

            if (typeof getVariableType === 'undefined') {
                throw new Error('The type for the piece cannot be undefined');
            } else if (getVariableType === VariableType.LOCATION) {
                customFieldValue = applicationState.structure.locations.byId[fetchingVariable].customFields[piece.customFieldId];
                rawVariableValue = customFieldValue;

                if (typeof customFieldValue === 'string' && isUUID(customFieldValue)) {
                    rawVariableValue = applicationState.structure.levels.customFieldOptions.byId[customFieldValue].name;
                } else if (Array.isArray(customFieldValue) && customFieldValue.length > 0 && isUUID(customFieldValue[0])) {
                    rawVariableValue = customFieldValue.map(fieldId => applicationState.structure.levels.customFieldOptions.byId[fieldId].name);
                }

            } else if (getVariableType === VariableType.USER) {
                customFieldValue = applicationState.users.byId[fetchingVariable].customFields[piece.customFieldId];
                rawVariableValue = customFieldValue;

                if (typeof customFieldValue === 'string' && isUUID(customFieldValue)) {
                    rawVariableValue = applicationState.users.customFieldOptions.byId.hasOwnProperty(customFieldValue) ? applicationState.users.customFieldOptions.byId[customFieldValue].name : applicationState.structure.roles.customFieldOptions.byId[customFieldValue].name;
                } else if (Array.isArray(customFieldValue) && customFieldValue.length > 0 && isUUID(customFieldValue[0])) {
                    rawVariableValue = customFieldValue.map(fieldId => applicationState.users.customFieldOptions.byId.hasOwnProperty(fieldId) ? applicationState.users.customFieldOptions.byId[fieldId].name : applicationState.structure.roles.customFieldOptions.byId[fieldId].name);
                }
            } else if (getVariableType === VariableType.MEMBER) {
                customFieldValue = applicationState.members.byId[fetchingVariable].customFields[piece.customFieldId];
                rawVariableValue = customFieldValue;

                if (typeof customFieldValue === 'string' && isUUID(customFieldValue)) {
                    rawVariableValue = applicationState.members.types.customFieldOptions.byId[customFieldValue].name;
                } else if (Array.isArray(customFieldValue) && customFieldValue.length > 0 && isUUID(customFieldValue[0])) {
                    rawVariableValue = customFieldValue.map(fieldId => applicationState.members.types.customFieldOptions.byId[fieldId].name);
                }

            } else if (getVariableType === VariableType.GROUP) {
                customFieldValue = applicationState.groups.byId[fetchingVariable].customFields[piece.customFieldId];
                rawVariableValue = customFieldValue;

                if (typeof customFieldValue === 'string' && isUUID(customFieldValue)) {
                    rawVariableValue = applicationState.groups.types.customFieldOptions.byId[customFieldValue].name;
                } else if (Array.isArray(customFieldValue) && customFieldValue.length > 0 && isUUID(customFieldValue[0])) {
                    rawVariableValue = customFieldValue.map(fieldId => applicationState.groups.types.customFieldOptions.byId[fieldId].name);
                }

            } else if (getVariableType === VariableType.WORKFLOW) {
                const workflowCustomFieldValue = applicationState.workflows.byId[fetchingVariable].history[applicationState.workflows.byId[fetchingVariable].historyIndex].customFields[piece.customFieldId];

                if (typeof workflowCustomFieldValue === 'object' && !Array.isArray(workflowCustomFieldValue)) {
                    // The value is on a per-member basis

                    if (!piece.memberVariablePiece) {
                        throw new Error('The piece must have a member variable, since the custom field value is an object');
                    }

                    memberVariablePiece = piecesData.byId[piece.memberVariablePiece];

                    if (memberVariablePiece.type !== PieceType.VARIABLE) {
                        throw new Error('This piece must be a variable piece');
                    }

                    if (!memberVariablePiece.variable) {
                        throw new Error('The variable piece must point to a variable');
                    }

                    const getMemberId = processState.variables[memberVariablePiece.variable];

                    if (typeof getMemberId !== 'string') {
                        throw new Error('The member ID must always be a string');
                    }

                    rawVariableValue = workflowCustomFieldValue[getMemberId];
                } else {
                    rawVariableValue = workflowCustomFieldValue;
                }

                if (typeof rawVariableValue === 'string' && isUUID(rawVariableValue)) {
                    rawVariableValue = applicationState.workflows.types.customFieldOptions.byId[rawVariableValue].name;
                } else if (Array.isArray(customFieldValue) && customFieldValue.length > 0 && isUUID(customFieldValue[0])) {
                    rawVariableValue = customFieldValue.map(fieldId => applicationState.workflows.types.customFieldOptions.byId[fieldId].name);
                }
            }

            if (typeof rawVariableValue === 'number') {
                variableValue = String(rawVariableValue);
            } else {
                variableValue = rawVariableValue;
            }

            updateVariableValue(processState, piece.variableToCopy, variableValue);
            updateLastComputedPiece(processState, pieceId);

            return true;

        case PieceType.SET_VARIABLE:
            let valueToStore = piece.dataToSet && isUUID(piece.dataToSet) ? pieceValueShortHand(piece.dataToSet) : piece.dataToSet;

            if (!piece.variableToSet) {
                throw new Error('The variable piece must point to a variable');
            }

            const variableToSet = applicationState.flowchart.variables.byId[piece.variableToSet];

            if (!valueToStore) {
                if (variableToSet.type === VariableType.TEXT) {
                    valueToStore = '';
                } else {
                    valueToStore = undefined;
                }
            }

            switch (variableToSet.type) {
                case VariableType.NUMBER:
                    valueToStore = isNaN(Number(valueToStore)) ? undefined : Number(valueToStore);
                    break;
                case VariableType.TEXT:
                    if (typeof valueToStore !== 'undefined') {
                        valueToStore = String(valueToStore);
                    }
                    break;
                case VariableType.TEXT_LIST:
                    if (!Array.isArray(valueToStore)) {
                        if (typeof valueToStore === 'string' && valueToStore.trim().length > 0) {
                            valueToStore = [valueToStore];
                        } else {
                            valueToStore = [];
                        }
                    }
                    break;
                case VariableType.PROJECTS_LIST:
                case VariableType.LEVELS_LIST:
                case VariableType.ROLES_LIST:
                case VariableType.LOCATIONS_LIST:
                case VariableType.USERS_LIST:
                case VariableType.MEMBERS_LIST:
                case VariableType.GROUPS_LIST:
                case VariableType.WORKFLOWS_LIST:
                case VariableType.REPORTS_LIST:
                case VariableType.TABLE_DATA:
                    if (!Array.isArray(valueToStore)) {
                        if (typeof valueToStore === 'string' && isUUID(valueToStore)) {
                            valueToStore = [valueToStore];
                        } else {
                            valueToStore = [];
                        }
                    }
                    break;
                default:
                    break;
            }

            updateVariableValue(processState, piece.variableToSet, valueToStore);
            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.NEW_DATE:
            let yearValue = piece.yearVariablePiece && isUUID(piece.yearVariablePiece) ? pieceValueShortHand(piece.yearVariablePiece) : piece.yearVariablePiece;
            let monthValue = piece.monthVariablePiece && isUUID(piece.monthVariablePiece) ? pieceValueShortHand(piece.monthVariablePiece) : piece.monthVariablePiece;
            let dateValue = piece.dateVariablePiece && isUUID(piece.dateVariablePiece) ? pieceValueShortHand(piece.dateVariablePiece) : piece.dateVariablePiece;

            if (!piece.variablePiece) {
                throw new Error('The variable piece must point to a variable');
            }

            yearValue = Number(yearValue);
            monthValue = Number(monthValue);
            dateValue = Number(dateValue);

            if (isNaN(yearValue)) {
                throw new Error('The year must be a number');
            }

            if (isNaN(monthValue)) {
                throw new Error('The month must be a number');
            }

            if (isNaN(dateValue)) {
                throw new Error('The date must be a number');
            }

            const createdDate = new Date();
            createdDate.setFullYear(yearValue);
            createdDate.setMonth(monthValue - 1);
            createdDate.setDate(dateValue);

            updateVariableValue(processState, piece.variablePiece, moment(createdDate).format('YYYY-MM-DD'));
            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.ADD_TO_LIST:
            let dataToAdd = piece.dataToSet && isUUID(piece.dataToSet) ? pieceValueShortHand(piece.dataToSet) : piece.dataToSet;

            if (typeof dataToAdd === 'number' || typeof dataToAdd === 'boolean') {
                dataToAdd = String(dataToAdd);
            }

            if (typeof piece.listVariable !== 'string' || !isUUID(piece.listVariable)) {
                throw new Error('The list variable must be defined')
            }

            const listVariable = applicationState.flowchart.variables.byId[piece.listVariable];

            if (typeof dataToAdd === 'undefined') {
                if (listVariable.type === VariableType.TEXT_LIST) {
                    dataToAdd = '';
                } else {
                    updateLastComputedPiece(processState, pieceId);
                    return true;
                }
            }

            if (Array.isArray(dataToAdd)) {
                if (listVariable.type === VariableType.TEXT_LIST) {
                    dataToAdd = dataToAdd.join(', ');
                }
            }

            if (typeof dataToAdd !== 'string') {
                throw new Error('Can only add string values');
            }

            listValue = processState.variables[piece.listVariable];

            if (typeof listValue === 'undefined') {
                listValue = [];
            }

            if (!Array.isArray(listValue)) {
                throw new Error('The list value must be an iterable');
            }

            if (Array.isArray(listValue)) {

                if (listValue.length > 0 && Array.isArray(listValue[0])) {
                    // In comparison is not done for table values
                    throw new Error('The list value cannot be a multi-dimensional array')
                }

                listValue = listValue as Array<string>;
            }

            const appendedList = listValue.concat([dataToAdd]);

            updateVariableValue(processState, piece.listVariable, appendedList);
            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.REMOVE_FROM_LIST:
            let dataToRemove = piece.dataToSet && isUUID(piece.dataToSet) ? pieceValueShortHand(piece.dataToSet) : piece.dataToSet;

            if (typeof dataToRemove === 'number') {
                dataToRemove = String(dataToRemove);
            }

            if (typeof dataToRemove !== 'string') {
                throw new Error('Can only add string values');
            }

            const removeListVariable = piece.listVariable;

            if (typeof removeListVariable !== 'string' || !isUUID(removeListVariable)) {
                throw new Error('The list variable must be defined')
            }

            listValue = processState.variables[removeListVariable];

            if (typeof listValue === 'undefined') {
                listValue = [];
            }

            if (!Array.isArray(listValue)) {
                throw new Error('The list value must be an iterable');
            }

            if (Array.isArray(listValue)) {

                if (listValue.length > 0 && Array.isArray(listValue[0])) {
                    // In comparison is not done for table values
                    throw new Error('The list value cannot be a multi-dimensional array')
                }

                listValue = listValue as Array<string>;
            }

            const filteredList = listValue.filter(listElement => listElement !== dataToRemove);

            updateVariableValue(processState, removeListVariable, filteredList);
            updateLastComputedPiece(processState, pieceId);
            return true;

        case PieceType.ADD_TO_TABLE:
            let listToAdd = piece.dataToSet && isUUID(piece.dataToSet) ? pieceValueShortHand(piece.dataToSet) : piece.dataToSet;

            if (typeof listToAdd === 'undefined') {
                listToAdd = [];
            }

            if (!Array.isArray(listToAdd)) {
                throw new Error('The list value must be an iterable');
            }

            if (Array.isArray(listToAdd)) {

                if (listToAdd.length > 0 && Array.isArray(listToAdd[0])) {
                    // In comparison is not done for table values
                    throw new Error('The data to add cannot be a multi-dimensional array')
                }

                listToAdd = listToAdd as Array<string>;
            }

            if (typeof piece.listVariable !== 'string' || !isUUID(piece.listVariable)) {
                throw new Error('The list variable must be defined')
            }

            listValue = processState.variables[piece.listVariable];

            if (typeof listValue === 'undefined') {
                listValue = [];
            }

            if (!Array.isArray(listValue)) {
                throw new Error('The list value must be an iterable');
            }

            if (Array.isArray(listValue)) {

                if (listValue.length > 0 && !Array.isArray(listValue[0])) {
                    // In comparison is not done for table values
                    throw new Error('The list value must be a multi-dimensional array')
                }

                listValue = listValue as Array<Array<string>>;
            }

            const appendedTable = listValue.concat([listToAdd]);

            updateVariableValue(processState, piece.listVariable, appendedTable);
            updateLastComputedPiece(processState, pieceId);
            return true;

        default:
            throw new Error('This piece is not executable');
    }
}
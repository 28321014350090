import { select } from '@angular-redux/store';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import draftToHtml from 'draftjs-to-html';
import { Observable, Subscription } from 'rxjs';
import { VariableValueType } from 'src/shared/helpers/common-types';
import { getAllLocationsVisibleToUser } from 'src/shared/helpers/locations';
import { isUUID } from 'src/shared/helpers/utilities';
import { getWidgetValue } from 'src/shared/store/flowchart/helpers/widget';
import { DefaultFlowchartProcessState } from 'src/shared/store/flowchart/types';
import { ApplicationState } from 'src/shared/store/types';

@Component({
	selector: 'app-rich-text',
	templateUrl: './rich-text.component.html',
	styleUrls: ['./rich-text.component.scss'],
})
export class RichTextComponent implements OnInit, OnDestroy {
    @Input() widgetId: string;
    @select(state => state) applicationStateSource: Observable<ApplicationState>;
    applicationState: ApplicationState;
    applicationStateSubscription: Subscription;
    returnValue: string;

    constructor(private sanitizer: DomSanitizer) { }

    generateWidgetData = (widgetId: string) => {

        let visibleUsers: Array<string> = [],
            visibleMembers: Array<string> = [],
            visibleGroups: Array<string> = [],
            visibleWorkflows: Array<string> = [];

        const widget = this.applicationState.widgets.byId[widgetId];

        if (!widget) {
            return '-';
        }

        if (isUUID(this.applicationState.myData.id)) {
            const visibleLocationIds = getAllLocationsVisibleToUser(this.applicationState.myData.id);
        
            visibleLocationIds.forEach(locationId => {
                const location = this.applicationState.structure.locations.byId[locationId];
                visibleUsers = visibleUsers.concat(location.users);
                visibleMembers = visibleMembers.concat(location.members);
                visibleGroups = visibleGroups.concat(location.groups);
            });

            visibleUsers = Array.from(new Set(visibleUsers)).filter(userId => {
                try {
                    return !this.applicationState.users.byId[userId].archived;
                } catch {
                    return false;
                }
            });

            visibleMembers = Array.from(new Set(visibleMembers)).filter(memberId => {
                try {
                    return !this.applicationState.members.byId[memberId].archived;
                } catch {
                    return false;
                }
            });
            
            visibleGroups = Array.from(new Set(visibleGroups)).filter(groupId => {
                try {
                    !this.applicationState.groups.byId[groupId].archived;
                } catch {
                    return false;
                }
            });

            const reportUser = this.applicationState.users.byId[this.applicationState.myData.id];

            for (const workflowTypeId in reportUser.workflows) {
                visibleWorkflows = visibleWorkflows.concat(reportUser.workflows[workflowTypeId]);
            }

            visibleMembers.forEach(memberId => {
                const member = this.applicationState.members.byId[memberId];

                for (const workflowTypeId in member.workflows) {
                    visibleWorkflows = visibleWorkflows.concat(member.workflows[workflowTypeId]);
                }
            });

            visibleGroups.forEach(groupId => {
                const group = this.applicationState.groups.byId[groupId];

                for (const workflowTypeId in group.workflows) {
                    visibleWorkflows = visibleWorkflows.concat(group.workflows[workflowTypeId]);
                }
            });

            visibleWorkflows = Array.from(new Set(visibleWorkflows)).filter(workflowId => this.applicationState.workflows.byId[workflowId] && !this.applicationState.workflows.byId[workflowId].archived);
        } else {
            visibleUsers = this.applicationState.users.allEntries;
            visibleMembers = this.applicationState.members.allEntries;
            visibleGroups = this.applicationState.groups.allEntries;
            visibleWorkflows = this.applicationState.workflows.allEntries;
        }

        if (
            typeof widget.seedUsersVariable === 'undefined' ||
            typeof widget.seedMembersVariable === 'undefined' ||
            typeof widget.seedGroupsVariable === 'undefined' ||
            typeof widget.seedWorkflowsVariable === 'undefined'
        ) {
            return;
        }
        
        const widgetProcessState: DefaultFlowchartProcessState = {
            executionStack: [],
            forIterationCounts: {},
            customFields: {},
            variables: {
                [widget.seedUsersVariable]: visibleUsers,
                [widget.seedMembersVariable]: visibleMembers,
                [widget.seedGroupsVariable]: visibleGroups,
                [widget.seedWorkflowsVariable]: visibleWorkflows,
            },
            lastComputedPiece: undefined,
            displayingGroupPieceId: undefined,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingTransferPieceId: undefined,
            displayingContinuePieceId: undefined,
            displayingAddWorkflowPieceId: undefined,
            createdWorkflowId: undefined,
        };

        const startPiece = widget.richTextStartPiece ? widget.richTextStartPiece.piece : undefined;
        
        let widgetData: VariableValueType;
        
        try {
            widgetData = getWidgetValue(this.applicationState, widgetProcessState, widgetId, startPiece);
        } catch (e) {
            console.log(e.message);
        }

        if (typeof widgetData !== 'string') {
            throw new Error('The widget data must be a string');
        }

        return widgetData;
    }

	ngOnInit() {
        this.applicationStateSubscription = this.applicationStateSource.subscribe(applicationState => {
            this.applicationState = applicationState;

            try {
                const richTextData = this.generateWidgetData(this.widgetId);

                if (richTextData === '-') {
                    this.returnValue = '<div></div>';
                } else {
                    this.returnValue = draftToHtml(JSON.parse(richTextData));
                }
            } catch (e) {
                this.returnValue = '<div></div>';
            }
            
        });
    }

    transformYourHtml() {
        if (!this.returnValue) {
            return '';
        }

        return this.sanitizer.bypassSecurityTrustHtml(this.returnValue);
	}

    ngOnDestroy() {
        if (this.applicationStateSubscription) {
            this.applicationStateSubscription.unsubscribe();
        }
    }

}

import { isUUID } from './utilities';
import store from '../store/main';
import { ILocation } from '../store/structure/location/types';
import { IProject } from '../store/structure/project/types';
import { ApplicationState } from '../store/types';


export function getAllLocationsUnderUser(userId: string, appState?: ApplicationState) {
    const applicationState = appState ? appState : store.getState();
    const user = applicationState.users.byId[userId];
    const locationState = applicationState.structure.locations;
    const allLocationsUnderUser: Array<string> = [];

    let locationsInThisLevel: Array<string> = [...user.locations];

    while (locationsInThisLevel.length !== 0) {
        const nextLevelLocations = locationsInThisLevel.map(locationId => locationState.byId[locationId].children).flat();
        allLocationsUnderUser.push.apply(allLocationsUnderUser, nextLevelLocations);
        locationsInThisLevel = nextLevelLocations;
    }

    return allLocationsUnderUser;
}

export function getAncestorChainOfUser(userId: string, appState?: ApplicationState) {
    const applicationState = appState ? appState : store.getState();
    const user = applicationState.users.byId[userId];

    const ancestorLocations: Array<string> = [];

    for (const locationId of user.locations) {
        const locationChain = getAncestorChainOfLocation(locationId, applicationState).reverse();

        for (const locationId of locationChain) {
            if (!ancestorLocations.includes(locationId)) {
                ancestorLocations.push(locationId);
            }
        }
    }

    return ancestorLocations;
}

export function getAncestorChainOfLocation(locationId: string, appState?: ApplicationState) {
    const applicationState = appState ? appState : store.getState();
    const locationState = applicationState.structure.locations;

    const ancestorLocations: Array<string> = [];
    let upperLocation: ILocation = locationState.byId[locationId];
    let parentId = upperLocation && upperLocation.parent ? upperLocation.parent : '';

    while (parentId in locationState.byId) {
        ancestorLocations.push(parentId);
        upperLocation = locationState.byId[parentId];
        parentId = upperLocation.parent || '';
    }

    return ancestorLocations;
}

export function getAllLocationsVisibleToUser(userId: string, appState?: ApplicationState) {
    const applicationState = appState ? appState : store.getState();
    const user = applicationState.users.byId[userId];

    const ancestorLocations = getAncestorChainOfUser(userId, applicationState);
    const currentLocations = [...user.locations];
    const childLocations = getAllLocationsUnderUser(userId, applicationState);

    return ancestorLocations.concat(currentLocations).concat(childLocations);
}

export function getLeafLocationsForProject(projectId: string, appState?: ApplicationState) {
    const applicationState = appState ? appState : store.getState();

    const projectData = applicationState.structure.projects.byId[projectId];
    const leafLevelIndex = projectData.children.map(levelId => applicationState.structure.levels.byId[levelId]).filter(level => !level.archived).length - 1;

    let locations = applicationState.structure.locations.byProject[projectId].map(locationId => applicationState.structure.locations.byId[locationId]);
    
    for (let i = 0; i < leafLevelIndex; i += 1) {
        let newLocations: Array<string> = [];
        
        locations.forEach(location => {
            if (location.children && location.children.length > 0) {
                newLocations = newLocations.concat(location.children);
            }
        });
        
        locations = newLocations.map(locationId => applicationState.structure.locations.byId[locationId]);
    }

    return locations.map(location => location.id);
}


export function getAllLeafLocationsOfUser(userId: string, projectId?: string, appState?: ApplicationState) {
    const applicationState = appState ? appState : store.getState();
    
    if (!isUUID(userId)) {
        // Super User. Return all locations in project
        return applicationState.structure.projects.allEntries.map(projectId => getLeafLocationsForProject(projectId, applicationState)).flat();
    }

    const user = applicationState.users.byId[userId];
    const projects = projectId ? [applicationState.structure.projects.byId[projectId]] : user.projects.map(projectId => applicationState.structure.projects.byId[projectId]);

    let allLeafLocationsAcrossProjects: Array<string> = [];

    for (const project of projects) {
        for (const userLevelId of user.levels) {
            if (project.children.includes(userLevelId)) {
                const startIndex = project.children.findIndex(levelId => levelId === userLevelId);
                const leafLevelIndex = project.children.map(levelId => applicationState.structure.levels.byId[levelId]).filter(level => !level.archived).length - 1;

                const locationsAtLevel = getLocationsAtLevel(userLevelId, applicationState);

                let locations = locationsAtLevel.filter(location => user.locations.includes(location.id));
                
                for (let i = startIndex; i < leafLevelIndex; i += 1) {
                    let newLocations: Array<string> = [];
                    
                    locations.forEach(location => {
                        if (location.children && location.children.length > 0) {
                            newLocations = newLocations.concat(location.children);
                        }
                    });
                    
                    locations = newLocations.map(locationId => applicationState.structure.locations.byId[locationId]);
                }

                for (const location of locations) {
                    if (!allLeafLocationsAcrossProjects.includes(location.id)) {
                        allLeafLocationsAcrossProjects.push(location.id)
                    }
                }
            }
        }
        
    }


    return allLeafLocationsAcrossProjects;
}


export function getAllLeafLocationsUnderLocation(locationId: string, appState?: ApplicationState) {
    const applicationState = appState ? appState : store.getState();
    const location = applicationState.structure.locations.byId[locationId];
    let currentLocation = location;
    let project: IProject;
    let startIndex = 0;

    while (currentLocation.parent && currentLocation.parent in applicationState.structure.locations.byId) {
        currentLocation = applicationState.structure.locations.byId[currentLocation.parent];
        startIndex += 1;
    }

    if (!currentLocation.parent || !(currentLocation.parent in applicationState.structure.projects.byId)) {
        throw new Error('Location not linked to project');
    }

    project = applicationState.structure.projects.byId[currentLocation.parent];

    const leafLevelIndex = project.children.map(levelId => applicationState.structure.levels.byId[levelId]).filter(level => !level.archived).length - 1;

    let locations = [location]
    
    for (let i = startIndex; i < leafLevelIndex; i += 1) {
        let newLocations: Array<string> = [];
        
        locations.forEach(location => {
            if (location.children && location.children.length > 0) {
                newLocations = newLocations.concat(location.children);
            }
        });
        
        locations = newLocations.map(locationId => applicationState.structure.locations.byId[locationId]);
    }

    return locations.map(location => location.id);
}

export function getLocationsAtLevel(levelId: string, appState?: ApplicationState) {
    const applicationState = appState ? appState : store.getState();
    const level = applicationState.structure.levels.byId[levelId];

    const index = applicationState.structure.projects.byId[level.project].children.findIndex(levelId => levelId === level.id);
    
    let locations = applicationState.structure.locations.byProject[level.project]
    .filter(locationId => locationId in applicationState.structure.locations.byId)
    .map(locationId => applicationState.structure.locations.byId[locationId]);
    
    for (let i = 0; i < index; i += 1) {
        let newLocations: Array<string> = [];
        
        locations.forEach(location => {
            if (location.children && location.children.length > 0) {
                newLocations = newLocations.concat(location.children);
            }
        });
        
        locations = newLocations
        .filter(locationId => locationId in applicationState.structure.locations.byId)
        .map(locationId => applicationState.structure.locations.byId[locationId]);
    }

    return locations;
}


export function getAllLocationsInTree(locationId: string, appState?: ApplicationState) {
    const applicationState = appState ? appState : store.getState();
    const locationState = applicationState.structure.locations;
    const locationIdsInTree = [locationId];
    
    let locationsInThisLevel: Array<string> = [locationId];

    while (locationsInThisLevel.length !== 0) {
        const nextLevelLocations = locationsInThisLevel
            .filter(locationId => locationId in locationState.byId)
            .map(locationId => locationState.byId[locationId].children)
            .flat();
        locationIdsInTree.push.apply(locationIdsInTree, nextLevelLocations);
        locationsInThisLevel = nextLevelLocations;
    }

    return locationIdsInTree;
}
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ApplicationState } from 'src/shared/store/types';
import { transferWorkflow } from 'src/shared/store/workflows/actions';
import { PieceType } from 'src/shared/store/flowchart/pieces/types';
import { select, NgRedux } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { getReadableDataForCustomField } from 'src/shared/store/custom-fields';
import { DefaultFlowchartProcessState } from 'src/shared/store/flowchart/types';
import { getUserComputedFieldValue } from 'src/shared/store/flowchart/helpers/custom-fields/user';
import { translatePhrase } from 'src/shared/helpers/translation';
import { getAllLocationsVisibleToUser } from 'src/shared/helpers/locations';
import { ToastService, ToastType } from '../../services/toast.service';
import { VariableValueType } from 'src/shared/helpers/common-types';

@Component({
    selector: 'app-transfer',
    templateUrl: './transfer.component.html',
    styleUrls: ['./transfer.component.scss'],
})
export class TransferComponent implements OnInit, OnDestroy {
    @select(state => state) applicationStateSource: Observable<ApplicationState>;
    applicationState: ApplicationState;
    applicationStateSubscription: Subscription;
    @Input() workflowId: string;
    @Input() transferId: string;
    @Output() exitTransfer = new EventEmitter();
    userChoices: Array<{
        value: string,
        role: string,
        locations: string,
    }>;
    selectedUser: any;

    userInput = '';
    errorMessage = '';
    transferred = false;

    constructor(
        private ngRedux: NgRedux<ApplicationState>,
        private toastService: ToastService
    ) { }

    translate(phrase: string) {
        return translatePhrase(phrase.trim());
    }

    getUserLocation(id: string) {
        return this.applicationState.structure.locations.byId[id].name;
    }

    ngOnInit() {
        this.applicationStateSubscription = this.applicationStateSource.subscribe(state => {
            this.applicationState = state;

            const transferPiece = state.flowchart.pieces.byId[this.transferId];

            if (transferPiece.type !== PieceType.TRANSFER_WORKFLOW) {
                throw new Error('The ID should point to a piece of the transfer type');
            }

            if (!transferPiece.roles) {
                throw new Error('The transfer must be attached to one or more roles');
            }

            const roles = transferPiece.roles;

            const currentOwnerLocationHierarchy = getAllLocationsVisibleToUser(state.myData.id);

            const users = state.users.allEntries
                .filter(userId => {
                    const user = state.users.byId[userId];
                    return userId !== state.myData.id && roles.some(roleId => user.roles.includes(roleId)) &&
                        currentOwnerLocationHierarchy.some(hierarchy => user.locations.includes(hierarchy));
                })
                .map(userId => {
                    return {
                        value: userId,
                        role: state.users.byId[userId].roles.filter(roleId => roleId in state.structure.roles.byId).map(roleId => state.structure.roles.byId[roleId].name).join(', '),
                        locations: state.users.byId[userId].locations
                            .filter(locationId => locationId in state.structure.locations.byId)
                            .map(locationId => state.structure.locations.byId[locationId].name).join(', '),
                    };
                });

            if (roles.includes('previous')) {
                state.workflows.byId[this.workflowId].trackingUsers.forEach(userId => {
                    const userData = {
                        value: userId,
                        role: state.users.byId[userId].roles.map(roleId => state.structure.roles.byId[roleId].name).join(', '),
                        locations: state.users.byId[userId].locations
                            .map(locationId => state.structure.locations.byId[locationId].name).join(', '),
                    };

                    if (!users.some(user => user.value === userId)) {
                        users.push(userData);
                    }
                });
            }

            this.userChoices = users;

            if (this.userChoices.length === 1) {
                this.userInput = this.userChoices[0].value;
                this.selectedUser = this.userChoices[0];
            }
        });
    }

    transferWorkflow = (userId: string) => {
        this.ngRedux.dispatch(transferWorkflow(this.workflowId, userId));
        this.toastService.presentToastWithOptions(this.translate('This workflow has been transferred to') +
            ' ' + this.translate(this.getNameForUser(this.selectedUser.value)) + ' ' +
            this.translate('Role') + ':' + this.selectedUser.role + ' ' +
            this.translate('Locations') + ':' + this.selectedUser.locations, ToastType.INFO, 4000);
        this.exitTransferScreen();
    }

    getNameForUser = (userId: string) => {

        const user = this.applicationState.users.byId[userId];
        let userName: VariableValueType;

        const nameField = this.applicationState.users.customFields.byId[this.applicationState.users.nameFieldId];

        if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
            const processState: DefaultFlowchartProcessState = {
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                customFields: { ...user.customFields },
                variables: {
                    [nameField.seedEntityVariable]: user.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
            };

            userName = getUserComputedFieldValue(this.applicationState, processState, nameField.startPiece.piece, user.id, nameField);
        } else {
            userName = user.customFields[this.applicationState.users.nameFieldId];
        }

        if (Array.isArray(userName)) {

            if (userName.length > 0 && Array.isArray(userName[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            userName = userName as Array<string>;
        }

        userName = getReadableDataForCustomField(userName, this.applicationState.users.customFields.byId[this.applicationState.users.nameFieldId], user.id, 'user');

        return userName;
    }

    showErrorMessage = (message: string) => {
        this.errorMessage = message;

        setTimeout(() => {
            this.errorMessage = '';
        }, 5000);
    }

    ngOnDestroy() {
        if (this.applicationStateSubscription) {
            this.applicationStateSubscription.unsubscribe();
        }
    }

    transfer() {
        if (!this.userInput) {
            this.showErrorMessage('Please select a user');
            return;
        }

        this.transferred = true;

        this.transferWorkflow(this.userInput);
    }

    exitTransferScreen() {
        this.exitTransfer.emit();
    }

}

import { ADD_WORKFLOW, UPDATE_WORKFLOW, DELETE_WORKFLOW, SEARCH_WORKFLOW_TABLE, GO_TO_PAGE_WORKFLOW_TABLE, SET_PAGE_SIZE_WORKFLOW_TABLE, SORT_WORKFLOW_TABLE, UPDATE_WORKFLOW_STATUS, UPDATE_WORKFLOW_PROCESS_STATE, IUpdateableWorkflowData, WorkflowProcessState, TRANSFER_WORKFLOW, WORKFLOW_NAVIGATE_FORWARD, WORKFLOW_NAVIGATE_BACK, ADD_TO_WORKFLOW_HISTORY, UPDATE_WORKFLOW_DUE_DATE, FilterWorkflowTableAction, FILTER_WORKFLOW_TABLE, AddWorkflowAction, TransferWorkflowAction, UpdateWorkflowAction, DeleteWorkflowAction, SearchWorkflowTableAction, GoToPageWorkflowTableAction, SetPageSizeWorkflowTableAction, SortWorkflowTableAction, UpdateWorkflowStatusAction, UpdateWorkflowDueDateAction, UpdateWorkflowProcessStateAction, AddToWorkflowHistory, WorkflowNavigateForwardAction, WorkflowNavigateBackAction, RestrictWorkflowNavigationAction, RESTRICT_WORKFLOW_NAVIGATION, UN_ARCHIVE_WORKFLOW, UnArchiveWorkflowAction, IWorkflowScreenInput, AddToScreenInputs, ADD_TO_SCREEN_INPUTS, SetIsFilteringForWorkflowTableAction, SET_IS_FILTERING_FOR_WORKFLOW_TABLE, UpdateWorkflowCustomFieldDataAction, UPDATE_WORKFLOW_CUSTOM_FIELD_DATA, BulkTransferWorkflowsAction, BULK_TRANSFER_WORKFLOWS, UpdateWorkflowComputedFieldDataAction, BulkUpdateWorkflowComputedFieldDataAction, ComputedFieldUpdatePayloadForWorkflow, BULK_UPDATE_WORKFLOW_COMPUTED_FIELD_DATA, RecomputeAllWorkflowsAction, RECOMPUTE_ALL_WORKFLOWS, UPDATE_WORKFLOW_COMPUTED_FIELD_DATA, BULK_RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW, ChangeDeltaForWorkflowComputedFields, RecalculateComputedFieldsForWorkflowAction, RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW, ISetTotalNumberOfWorkflowsFromServerAction, SET_TOTAL_NUMBER_OF_WORKFLOWS_FROM_SERVER, CLEAR_WORKFLOW_ENTRIES, AppendWorkflowsData, APPEND_WORKFLOWS_DATA, IWorkflow } from './types';
import moment from 'moment';
import { SearchIndex } from '../../helpers/common-types';
import { WorkflowTypeCustomFieldDataHolder } from '../custom-fields/types';

export function addWorkflow(payload: IUpdateableWorkflowData): AddWorkflowAction {
    const now = moment.utc().format();

    return {
        type: ADD_WORKFLOW,
        payload: {
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            lastUpdateTimeForFields: {},
            trackingUsers: [payload.user],
            history: [],
            historyIndex: 0,
        },
        currentTime: now,
    }
}

export function transferWorkflow(workflowId: string, user: string): TransferWorkflowAction {
    return {
        type: TRANSFER_WORKFLOW,
        workflowId,
        user,
        currentTime: moment.utc().format(),
    }
}

export function bulkTransferWorkflows(workflowIds: Array<string>, user: string): BulkTransferWorkflowsAction {
    return {
        type: BULK_TRANSFER_WORKFLOWS,
        workflowIds,
        user,
        currentTime: moment.utc().format(),
    }
}

export function updateWorkflow(payload: IUpdateableWorkflowData): UpdateWorkflowAction {
    return {
        type: UPDATE_WORKFLOW,
        payload,
        currentTime: moment.utc().format(),
    }
}

export function deleteWorkflow(id: string): DeleteWorkflowAction {
    return {
        type: DELETE_WORKFLOW,
        id,
        currentTime: moment.utc().format(),
    }
}

export function unArchiveWorkflow(id: string): UnArchiveWorkflowAction {
    return {
        type: UN_ARCHIVE_WORKFLOW,
        id,
        currentTime: moment.utc().format(),
    }
}

export function searchWorkflowTable(searchTerm: string): SearchWorkflowTableAction {
    return {
        type: SEARCH_WORKFLOW_TABLE,
        searchTerm,
    }
}

export function filterWorkflowTable(
    dues: Array<string>,
    projects: Array<string>,
    types: Array<string>,
    statuses: Array<string>,
    users: Array<string>,
    locations: Array<string>,
    otherUsers: Array<string>,
    affiliations: Array<string>,
    customFields: { [customFieldId: string]: Array<string> },
    createdDateRange: Array<string>,
    lastUpdatedDateRange: Array<string>,
    dueDateRange: Array<string>,
    unsynced: boolean,
    archived: boolean,
): FilterWorkflowTableAction {

    return {
        type: FILTER_WORKFLOW_TABLE,
        dues,
        projects,
        types,
        statuses,
        users,
        locations,
        otherUsers,
        affiliations,
        customFields,
        createdDateRange,
        lastUpdatedDateRange,
        dueDateRange,
        unsynced,
        archived,
    }
}

export function setIsFilteringForWorkflowTable(isFiltering: boolean): SetIsFilteringForWorkflowTableAction {
    return {
        type: SET_IS_FILTERING_FOR_WORKFLOW_TABLE,
        isFiltering,
    };
}

export function setTotalNumberOfWorkflows(totalNumberOfWorkflows: number): ISetTotalNumberOfWorkflowsFromServerAction {
    return {
        type: SET_TOTAL_NUMBER_OF_WORKFLOWS_FROM_SERVER,
        totalNumberOfWorkflows,
    }
}

export function goToPageWorkflowTable(pageNumber: number): GoToPageWorkflowTableAction {
    return {
        type: GO_TO_PAGE_WORKFLOW_TABLE,
        pageNumber,
    }
}

export function setPageSizeWorkflowTable(pageSize: number): SetPageSizeWorkflowTableAction {
    return {
        type: SET_PAGE_SIZE_WORKFLOW_TABLE,
        pageSize,
    }
}

export function sortWorkflowTable(column: string, order: 'ASC' | 'DESC'): SortWorkflowTableAction {
    return {
        type: SORT_WORKFLOW_TABLE,
        column,
        order,
    }
}


export function updateStatus(workflowId: string, statusId: string): UpdateWorkflowStatusAction {
    return {
        type: UPDATE_WORKFLOW_STATUS,
        workflowId,
        statusId,
        currentTime: moment.utc().format(),
    }
}

export function updateDueDate(workflowId: string, dueDate: string): UpdateWorkflowDueDateAction {
    return {
        type: UPDATE_WORKFLOW_DUE_DATE,
        workflowId,
        dueDate,
        currentTime: moment.utc().format(),
    }
}

export function updateProcessState(processState: WorkflowProcessState, workflowId: string, userId: string): UpdateWorkflowProcessStateAction {
    const now = moment.utc().format();

    return {
        type: UPDATE_WORKFLOW_PROCESS_STATE,
        workflowId,
        processState: {
            ...processState,
            executingUser: userId,
            executionTime: now,
        },
        updateTime: now,
    }
}

export function addToHistory(processState: WorkflowProcessState, workflowId: string, userId: string): AddToWorkflowHistory {
    const now = moment.utc().format();

    return {
        type: ADD_TO_WORKFLOW_HISTORY,
        processState: {
            ...processState,
            executingUser: userId,
            executionTime: now,
        },
        workflowId,
        updateTime: now,
    }
}

export function addToScreenInputs(workflowId: string, screenInput: IWorkflowScreenInput): AddToScreenInputs {
    return {
        type: ADD_TO_SCREEN_INPUTS,
        workflowId,
        screenInput,
    }
}

export function restrictNavigation(workflowId: string): RestrictWorkflowNavigationAction {
    return {
        type: RESTRICT_WORKFLOW_NAVIGATION,
        workflowId,
        currentTime: moment.utc().format(),
    }
}

export function navigateForward(workflowId: string): WorkflowNavigateForwardAction {
    return {
        type: WORKFLOW_NAVIGATE_FORWARD,
        workflowId,
        currentTime: moment.utc().format(),
    }
}

export function navigateBack(workflowId: string): WorkflowNavigateBackAction {
    return {
        type: WORKFLOW_NAVIGATE_BACK,
        workflowId,
        currentTime: moment.utc().format(),
    }
}

export function updateWorkflowCustomFieldData(changingWorkflowId: string, workflowId: string, customFieldData: WorkflowTypeCustomFieldDataHolder): UpdateWorkflowCustomFieldDataAction {
    return {
        type: UPDATE_WORKFLOW_CUSTOM_FIELD_DATA,
        changingWorkflowId,
        workflowId,
        customFieldData,
        currentTime: moment.utc().format(),
    }
}

export function updateWorkflowComputedFieldData(workflowId: string, customFieldData: WorkflowTypeCustomFieldDataHolder): UpdateWorkflowComputedFieldDataAction {
    return {
        type: UPDATE_WORKFLOW_COMPUTED_FIELD_DATA,
        workflowId,
        customFieldData,
        currentTime: moment.utc().format(),
    }
}

export function bulkUpdateWorkflowComputedFieldData(payload: Array<ComputedFieldUpdatePayloadForWorkflow>): BulkUpdateWorkflowComputedFieldDataAction {
    return {
        type: BULK_UPDATE_WORKFLOW_COMPUTED_FIELD_DATA,
        payload,
        currentTime: moment.utc().format(),
    }
}

export function recomputeAllWorkflows(): RecomputeAllWorkflowsAction {
    return {
        type: RECOMPUTE_ALL_WORKFLOWS,
    };
}

export function appendWorkflows(members: Array<IWorkflow>): AppendWorkflowsData {
    return {
        type: APPEND_WORKFLOWS_DATA,
        data: members,
    }
}

export function recalculateComputedFieldsForWorkflow(
    workflowId: string,
    workflowChanged: boolean,
    usersChanged: boolean,
    affiliationChanged: boolean,
): RecalculateComputedFieldsForWorkflowAction {
    return {
        type: RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW,
        workflowId,
        workflowChanged,
        usersChanged,
        affiliationChanged,
    }
};

export function bulkRecalculateComputedFieldsForWorkflow(payload: Array<ChangeDeltaForWorkflowComputedFields>) {
    return {
        type: BULK_RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW,
        payload,
    }
}

export function clearWorkflowEntries() {
    return {
        type: CLEAR_WORKFLOW_ENTRIES,
    }
}
import { ExportedMemberType, ExportedGroupType, ExportWorkflowTypeData } from "../../helpers/duplicate";
import { NormalizedModel } from "../normalized-model";


export type PackageType = 'Member' | 'Group' | 'Workflow' | 'Data';

interface BasePackage {
    id: string;
    type: PackageType,
    name: string;
    description: string;
    categories: Array<string>;
    imageLinks: Array<string>;

    organization: string;
    author: string;

    createdTime: string;
    lastUpdatedTime: string;
}

export interface MemberPackage extends BasePackage {
    type: 'Member';
    customFieldsCount: number;
    packageData?: ExportedMemberType;
}

export interface GroupPackage extends BasePackage {
    type: 'Group';
    customFieldsCount: number;
    packageData?: ExportedGroupType;
}

export interface WorkflowPackage extends BasePackage {
    type: 'Workflow';
    hasAffiliation: boolean;
    customFieldsCount: number;
    packageData?: ExportWorkflowTypeData;
}

export interface DataPackage extends BasePackage {
    type: 'Data',
    levelsCount: number;
    packageData?: Array<Array<string>>;
}

export type MarketplacePackage = MemberPackage | GroupPackage | WorkflowPackage | DataPackage;

export const ADD_WORKFLOW_PACKAGE = 'ADD_WORKFLOW_PACKAGE';
export const UPDATE_WORKFLOW_PACKAGE_REQUEST = 'UPDATE_WORKFLOW_PACKAGE_REQUEST';
export const UPDATE_WORKFLOW_PACKAGE = 'UPDATE_WORKFLOW_PACKAGE';

export const ADD_DATA_PACKAGE = 'ADD_DATA_PACKAGE';
export const UPDATE_DATA_PACKAGE_REQUEST = 'UPDATE_DATA_PACKAGE_REQUEST';
export const UPDATE_DATA_PACKAGE = 'UPDATE_DATA_PACKAGE';

export const DELETE_PACKAGE = 'DELETE_PACKAGE';

export const UPDATE_PACKAGES_DATA = 'UPDATE_PACKAGES_DATA';

export interface PackageState extends NormalizedModel<MarketplacePackage> {
    hasFetchedPackages: boolean;
}

export type INewWorkflowPackage = Omit<WorkflowPackage, 'id'|'createdTime'|'lastUpdatedTime'>
export type IUpdateableWorkflowPackage = Omit<WorkflowPackage, 'createdTime'|'lastUpdatedTime'>

export type INewDataPackage = Omit<DataPackage, 'id'|'createdTime'|'lastUpdatedTime'>
export type IUpdateableDataPackage = Omit<DataPackage, 'createdTime'|'lastUpdatedTime'>

export interface AddWorkflowPackageAction {
    type: typeof ADD_WORKFLOW_PACKAGE,
    payload: WorkflowPackage,
    currentTime: string,
}

export interface UpdateWorkflowPackageRequestAction {
    type: typeof UPDATE_WORKFLOW_PACKAGE_REQUEST,
    payload: IUpdateableWorkflowPackage,
}

export interface UpdateWorkflowPackageAction {
    type: typeof UPDATE_WORKFLOW_PACKAGE,
    payload: IUpdateableWorkflowPackage,
    currentTime: string,
}

export interface AddDataPackageAction {
    type: typeof ADD_DATA_PACKAGE,
    payload: DataPackage,
    currentTime: string,
}

export interface UpdateDataPackageRequestAction {
    type: typeof UPDATE_DATA_PACKAGE_REQUEST,
    payload: IUpdateableDataPackage,
}

export interface UpdateDataPackageAction {
    type: typeof UPDATE_DATA_PACKAGE,
    payload: IUpdateableDataPackage,
    currentTime: string,
}

export interface DeletePackageAction {
    type: typeof DELETE_PACKAGE,
    id: string,
    currentTime: string,
}

export interface UpdatePackagesData {
    type: typeof UPDATE_PACKAGES_DATA,
    data: Array<MarketplacePackage>,
}

export type PackageActionTypes = AddWorkflowPackageAction | UpdateWorkflowPackageRequestAction | UpdateWorkflowPackageAction | AddDataPackageAction | UpdateDataPackageRequestAction | UpdateDataPackageAction | DeletePackageAction | UpdatePackagesData;
import { SELECT_STATUS, UN_SELECT_STATUS, ADD_STATUS, DELETE_STATUS, UPDATE_STATUS, RE_ORDER_STATUSES, StatusState, UPDATE_WORKFLOW_STATUSES_DATA, SYNCHRONIZE_WORKFLOW_STATUSES_DATA, CLEAR_WORKFLOW_STATUSES_DELTA, IStatus, INSTANTIATE_STATUS } from './types';
import { WorkflowActionTypes } from '../../types';
import { reOrderList } from '../../../../helpers/utilities';
import { updateEntries, synchronizeEntries, clearDelta, addEntity, updateEntity, deleteEntity } from '../../../normalized-model';

export const initialState: StatusState = {  
    byId: {},
    allEntries: [],
    filteredEntries: [],
    
    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),
};

export function statusesReducer(state = initialState, action: WorkflowActionTypes): StatusState {
    switch(action.type) {

        case SELECT_STATUS:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_STATUS:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_STATUSES:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
            };

        case ADD_STATUS:
            return addEntity<StatusState, IStatus>(state, action.payload);

        case INSTANTIATE_STATUS:
            return addEntity<StatusState, IStatus>(state, action.payload);
        
        case UPDATE_STATUS:
            return updateEntity<StatusState, IStatus>(state, action.payload, action.currentTime);

        case DELETE_STATUS:
            return deleteEntity<StatusState, IStatus>(state, action.id, action.currentTime);

        case UPDATE_WORKFLOW_STATUSES_DATA:
            return updateEntries<StatusState, IStatus>(state, action.data);

        case SYNCHRONIZE_WORKFLOW_STATUSES_DATA:
            return synchronizeEntries<StatusState, IStatus>(state, action.data);

        case CLEAR_WORKFLOW_STATUSES_DELTA:
            return clearDelta<StatusState, IStatus>(state);
        

        default:
            return state
    }
}
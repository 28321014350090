import { ReOrderedListsForEntity, PiecePositionState } from '../../../helpers/common-types';
import { CustomFieldState, IUpdateableCustomFieldData, CustomField, IUpdateableFieldChoiceData, FieldChoice } from '../../custom-fields/types';
import { NormalizedModel, Synchronizable } from '../../normalized-model';

export const SELECT_LEVEL = 'SELECT_LEVEL';
export const UN_SELECT_LEVEL = 'UN_SELECT_LEVEL';
export const RE_ORDER_LEVELS = 'RE_ORDER_LEVELS';
export const ADD_LEVEL_REQUEST = 'ADD_LEVEL_REQUEST';
export const ADD_LEVEL = 'ADD_LEVEL';
export const INSTANTIATE_LEVEL = 'INSTANTIATE_LEVEL';
export const DELETE_LEVEL = 'DELETE_LEVEL';
export const UPDATE_LEVEL = 'UPDATE_LEVEL';

export const ADD_ROLE_TO_LEVEL = 'ADD_ROLE_TO_LEVEL';
export const REMOVE_ROLE_FROM_LEVEL = 'REMOVE_ROLE_FROM_LEVEL';

export const SELECT_LEVEL_CUSTOM_FIELD = 'SELECT_LEVEL_CUSTOM_FIELD';
export const UN_SELECT_LEVEL_CUSTOM_FIELD = 'UN_SELECT_LEVEL_CUSTOM_FIELD';
export const ADD_LEVEL_CUSTOM_FIELD = 'ADD_LEVEL_CUSTOM_FIELD';
export const INSTANTIATE_LEVEL_CUSTOM_FIELD = 'INSTANTIATE_LEVEL_CUSTOM_FIELD';
export const DELETE_LEVEL_CUSTOM_FIELD = 'DELETE_LEVEL_CUSTOM_FIELD';
export const UPDATE_LEVEL_CUSTOM_FIELD = 'UPDATE_LEVEL_CUSTOM_FIELD';

export const UPDATE_LEVEL_CUSTOM_FIELD_START_PIECE = 'UPDATE_LEVEL_CUSTOM_FIELD_START_PIECE';
export const SET_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE = 'SET_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE';
export const REMOVE_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE = 'REMOVE_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE';
export const REGISTER_LEVEL_CUSTOM_FIELD_VARIABLE = 'REGISTER_LEVEL_CUSTOM_FIELD_VARIABLE';

export const SELECT_LEVEL_CUSTOM_FIELD_OPTION = 'SELECT_LEVEL_CUSTOM_FIELD_OPTION';
export const UN_SELECT_LEVEL_CUSTOM_FIELD_OPTION = 'UN_SELECT_LEVEL_CUSTOM_FIELD_OPTION';
export const RE_ORDER_LEVEL_CUSTOM_FIELD_OPTION = 'RE_ORDER_LEVEL_CUSTOM_FIELD_OPTION';
export const ADD_LEVEL_CUSTOM_FIELD_OPTION = 'ADD_LEVEL_CUSTOM_FIELD_OPTION';
export const INSTANTIATE_LEVEL_CUSTOM_FIELD_OPTION = 'INSTANTIATE_LEVEL_CUSTOM_FIELD_OPTION';
export const DELETE_LEVEL_CUSTOM_FIELD_OPTION = 'DELETE_LEVEL_CUSTOM_FIELD_OPTION';
export const UPDATE_LEVEL_CUSTOM_FIELD_OPTION = 'UPDATE_LEVEL_CUSTOM_FIELD_OPTION';

export const UPDATE_LEVELS_DATA = 'UPDATE_LEVELS_DATA';
export const UPDATE_LEVEL_CUSTOM_FIELDS_DATA = 'UPDATE_LEVEL_CUSTOM_FIELDS_DATA';
export const UPDATE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA = 'UPDATE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA';
export const SYNCHRONIZE_LEVELS_DATA = 'SYNCHRONIZE_LEVELS_DATA';
export const SYNCHRONIZE_LEVEL_CUSTOM_FIELDS_DATA = 'SYNCHRONIZE_LEVEL_CUSTOM_FIELDS_DATA';
export const SYNCHRONIZE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA = 'SYNCHRONIZE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA';
export const CLEAR_LEVELS_DELTA = 'CLEAR_LEVELS_DELTA';


export interface INewLevelData {
    name: string;
}

export interface IUpdateableLevelData extends INewLevelData {
    id: string,
    name: string;
}

export interface ILevel extends IUpdateableLevelData, Synchronizable {
    children: Array<string>,
    archived?: boolean,
    project: string,
    customFields: Array<string>,
}

export interface LevelState extends NormalizedModel<ILevel>, CustomFieldState {
    selected: string|undefined,

    reOrderedRoles: ReOrderedListsForEntity,
    reOrderedCustomFields: ReOrderedListsForEntity,
}

export interface SelectLevelAction {
    type: typeof SELECT_LEVEL,
    id: string,
}

export interface UnSelectLevelAction {
    type: typeof UN_SELECT_LEVEL,
}

export interface ReOrderLevelsAction {
    type: typeof RE_ORDER_LEVELS,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddLevelRequestAction {
    type: typeof ADD_LEVEL_REQUEST,
    payload: INewLevelData
    parentId: string,
}

export interface AddLevelAction {
    type: typeof ADD_LEVEL,
    payload: ILevel,
    parentId: string,
    currentTime: string,
}

export interface InstantiateLevelAction {
    type: typeof INSTANTIATE_LEVEL,
    payload: ILevel,
    parentId: string,
    currentTime: string,
}

export interface DeleteLevelAction {
    type: typeof DELETE_LEVEL,
    id: string,
    parentId: string,
    currentTime: string,
}

export interface UpdateLevelAction {
    type: typeof UPDATE_LEVEL,
    payload: IUpdateableLevelData,
    currentTime: string,
}

export interface AddRoleToLevelAction {
    type: typeof ADD_ROLE_TO_LEVEL,
    levelId: string,
    roleId: string,
}

export interface RemoveRoleFromLevelAction {
    type: typeof REMOVE_ROLE_FROM_LEVEL,
    levelId: string,
    roleId: string,
}


export interface SelectLevelCustomFieldAction {
    type: typeof SELECT_LEVEL_CUSTOM_FIELD,
    id: string,
}

export interface UnSelectLevelCustomFieldAction {
    type: typeof UN_SELECT_LEVEL_CUSTOM_FIELD,
}

export interface AddLevelCustomFieldAction {
    type: typeof ADD_LEVEL_CUSTOM_FIELD,
    payload: IUpdateableCustomFieldData,
    levelId: string,
    currentTime: string,
}

export interface InstantiateLevelCustomFieldAction {
    type: typeof INSTANTIATE_LEVEL_CUSTOM_FIELD,
    payload: CustomField,
    levelId: string,
    currentTime: string,
}

export interface DeleteLevelCustomFieldAction {
    type: typeof DELETE_LEVEL_CUSTOM_FIELD,
    id: string,
    levelId: string,
    currentTime: string,
}

export interface UpdateLevelCustomFieldAction {
    type: typeof UPDATE_LEVEL_CUSTOM_FIELD,
    payload: IUpdateableCustomFieldData,
    currentTime: string,
}


export interface UpdateLevelCustomFieldStartPieceAction {
    type: typeof UPDATE_LEVEL_CUSTOM_FIELD_START_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
    currentTime: string,
}

export interface SetIsolatedLevelCustomFieldPieceAction {
    type: typeof SET_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
    currentTime: string,
}

export interface RemoveIsolatedLevelCustomFieldPieceAction {
    type: typeof REMOVE_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE,
    pieceId: string,
    customFieldId: string,
    currentTime: string,
}

export interface RegisterLevelCustomFieldVariableAction {
    type: typeof REGISTER_LEVEL_CUSTOM_FIELD_VARIABLE,
    variableId: string,
    customFieldId: string,
    currentTime: string,
}


export interface SelectLevelCustomFieldOptionAction {
    type: typeof SELECT_LEVEL_CUSTOM_FIELD_OPTION,
    id: string,
}

export interface UnSelectLevelCustomFieldOptionAction {
    type: typeof UN_SELECT_LEVEL_CUSTOM_FIELD_OPTION,
}

export interface ReOrderLevelCustomFieldOptionAction {
    type: typeof RE_ORDER_LEVEL_CUSTOM_FIELD_OPTION,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddLevelCustomFieldOptionAction {
    type: typeof ADD_LEVEL_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
    parentId: string,
    currentTime: string,
}

export interface InstantiateLevelCustomFieldOptionAction {
    type: typeof INSTANTIATE_LEVEL_CUSTOM_FIELD_OPTION,
    payload: FieldChoice,
    parentId: string,
    currentTime: string,
}

export interface DeleteLevelCustomFieldOptionAction {
    type: typeof DELETE_LEVEL_CUSTOM_FIELD_OPTION,
    id: string,
    parentId: string,
    currentTime: string,
}

export interface UpdateLevelCustomFieldOptionAction {
    type: typeof UPDATE_LEVEL_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
    currentTime: string,
}

export interface UpdateLevelsData {
    type: typeof UPDATE_LEVELS_DATA,
    data: Array<ILevel>,
}

export interface UpdateLevelCustomFieldsData {
    type: typeof UPDATE_LEVEL_CUSTOM_FIELDS_DATA,
    data: Array<CustomField>,
}

export interface UpdateLevelCustomFieldOptionsData {
    type: typeof UPDATE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA,
    data: Array<FieldChoice>,
}

export interface SynchronizeLevelsData {
    type: typeof SYNCHRONIZE_LEVELS_DATA,
    data: Array<ILevel>,
}

export interface SynchronizeLevelCustomFieldsData {
    type: typeof SYNCHRONIZE_LEVEL_CUSTOM_FIELDS_DATA,
    data: Array<CustomField>,
}

export interface SynchronizeLevelCustomFieldOptionsData {
    type: typeof SYNCHRONIZE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA,
    data: Array<FieldChoice>,
    reOrderedCustomFieldOptions: ReOrderedListsForEntity,
}

export interface ClearLevelsDelta {
    type: typeof CLEAR_LEVELS_DELTA,
}

type CustomFieldActions = SelectLevelCustomFieldAction | UnSelectLevelCustomFieldAction | AddLevelCustomFieldAction | InstantiateLevelCustomFieldAction | DeleteLevelCustomFieldAction | UpdateLevelCustomFieldAction;

type CustomFieldChoiceActions = SelectLevelCustomFieldOptionAction | UnSelectLevelCustomFieldOptionAction | ReOrderLevelCustomFieldOptionAction | AddLevelCustomFieldOptionAction | InstantiateLevelCustomFieldOptionAction | DeleteLevelCustomFieldOptionAction | UpdateLevelCustomFieldOptionAction;

export type LevelActionTypes = SelectLevelAction | UnSelectLevelAction | ReOrderLevelsAction | AddLevelAction | InstantiateLevelAction | AddLevelRequestAction | DeleteLevelAction | UpdateLevelAction | AddRoleToLevelAction | RemoveRoleFromLevelAction | UpdateLevelCustomFieldStartPieceAction | SetIsolatedLevelCustomFieldPieceAction | RemoveIsolatedLevelCustomFieldPieceAction | RegisterLevelCustomFieldVariableAction | CustomFieldActions | CustomFieldChoiceActions | UpdateLevelsData | UpdateLevelCustomFieldsData | UpdateLevelCustomFieldOptionsData | SynchronizeLevelsData | SynchronizeLevelCustomFieldsData | SynchronizeLevelCustomFieldOptionsData | ClearLevelsDelta;
import { takeEvery, put, select, all, takeLatest, delay, call } from 'redux-saga/effects'
import { ADD_GROUP_TYPE, AddGroupTypeAction, AddGroupTypeCustomFieldAction, ADD_GROUP_TYPE_CUSTOM_FIELD, UPDATE_GROUP_TYPE_CUSTOM_FIELD, UpdateGroupTypesData, UPDATE_GROUP_TYPES_DATA } from './types/types';
import { addGroupTypeCustomField, updateGroupTypeCustomFieldStartPiece, registerGroupTypeCustomFieldVariable, removeGroupFromGroupType, addGroupToGroupType, addActionToGroupType, removeActionFromGroupType } from './types/actions';
import { CustomField, CustomFieldDataHolder, FieldType, IUpdateableCustomFieldData } from '../custom-fields/types';
import { addVariable } from '../flowchart/variables/actions';
import { VariableType } from '../flowchart/variables/types';
import { addPiece } from '../flowchart/pieces/actions';
import uuid from 'uuid';
import { IVariablePiece, PieceType } from '../flowchart/pieces/types';
import { ApplicationState } from '../types';
import { addGroupToLocation, removeGroupFromLocation } from '../structure/location/actions';
import { AddGroupAction, DeleteGroupAction, UpdateGroupRequestAction, ADD_GROUP, DELETE_GROUP, UPDATE_GROUP_REQUEST, IGroup, SET_MEMBERS_FOR_GROUP_REQUEST, SetMembersForGroupRequestAction, UnArchiveGroupAction, UN_ARCHIVE_GROUP, ADD_MEMBER_TO_GROUP, REMOVE_MEMBER_FROM_GROUP, UpdateGroupsLocationRequestAction, UPDATE_GROUPS_LOCATION_REQUEST, ChangeDeltaForGroupComputedFields, UPDATE_GROUP_CUSTOM_FIELD_DATA, UpdateGroupCustomFieldDataAction, RECALCULATE_COMPUTED_FIELDS_FOR_GROUP, BULK_RECALCULATE_COMPUTED_FIELDS_FOR_GROUP, RECOMPUTE_ALL_GROUPS, RecalculateComputedFieldsForGroupAction, BulkRecalculateComputedFieldsForGroupAction, ComputedFieldUpdatePayloadForGroup, UPDATE_GROUP, UpdateGroupAction, BULK_ADD_MEMBERS_TO_GROUPS, BULK_REMOVE_MEMBERS_FROM_GROUPS, BulkAddMembersToGroupsAction, BulkRemoveMembersFromGroupsAction, FILTER_GROUP_TABLE, GO_TO_PAGE_GROUP_TABLE, SEARCH_GROUP_TABLE, SET_PAGE_SIZE_GROUP_TABLE, GroupFilters } from './types';
import { updateGroup, setMembersForGroup, updateGroupsLocation, bulkRecalculateComputedFieldsForGroup, recalculateComputedFieldsForGroup, updateGroupComputedFieldData, bulkUpdateGroupComputedFieldData, appendGroups, clearGroupEntries, setTotalNumberOfGroups } from './actions';
import { addGroupToMember, appendMembers, bulkRecalculateComputedFieldsForMember, clearMemberEntries, recalculateComputedFieldsForMember, removeGroupFromMember } from '../members/actions';
import { AddGroupTypeActionAction, DeleteGroupTypeActionAction, ADD_GROUP_TYPE_ACTION, DELETE_GROUP_TYPE_ACTION } from './types/actions/types';
import { addGroupTypeAction } from './types/actions/actions';
import { AddGroupToMemberAction, ChangeDeltaForMemberComputedFields, IMember, RemoveGroupFromMemberAction } from '../members/types';
import { ChangeDeltaForWorkflowComputedFields, IWorkflow } from '../workflows/types';
import { getAllPiecesInPiece } from '../flowchart/helpers/pieces';
import { getValueForComputedField } from '../custom-fields';
import { appendWorkflows, bulkRecalculateComputedFieldsForWorkflow, clearWorkflowEntries } from '../workflows/actions';
import axios, { AxiosResponse } from 'axios';
import { PageDataForOnlineEntities, FilterResponseForOnlineEntities, OnlineGroupQueryData } from '../../helpers/synchronize/types';
import { isUUID } from '../../helpers/utilities';
import { setInfoMessage, clearInfoMessage, setLastRefreshTime } from '../my-data/actions';
import { IUser } from '../users/types';
import { BASE_URL } from '../url';

function* createDefaultCustomFields(action: AddGroupTypeAction) {
    const nameCustomField: IUpdateableCustomFieldData = {
        id: action.payload.nameFieldId,
        name: 'Name',
        type: FieldType.TEXT,
        isComputed: false,
        isEditable: true,
        isDeletable: false,
        isInTable: true,
        seedEntityVariable: uuid.v4(),
    };

    const subTitleCustomField: IUpdateableCustomFieldData = {
        id: action.payload.subTitleFieldId,
        name: 'Sub Title',
        type: FieldType.TEXT,
        isComputed: false,
        isEditable: true,
        isDeletable: false,
        isInTable: true,
        seedEntityVariable: uuid.v4(),
    };

    yield all([
        put(addGroupTypeCustomField(nameCustomField, action.payload.id)),
        put(addGroupTypeCustomField(subTitleCustomField, action.payload.id)),
        put(addGroupTypeAction({
            name: 'Add Group',
            icon: 'plus',
        }, action.payload.id)),
        put(addGroupTypeAction({
            name: 'Edit Group',
            icon: 'pencil',
        }, action.payload.id)),
        put(addGroupTypeAction({
            name: 'Delete Group',
            icon: 'trash',
        }, action.payload.id)),
    ]);
}

export function* watchGroupTypeCreationRequest() {
    yield takeEvery(ADD_GROUP_TYPE, createDefaultCustomFields);
}

function* createDefaultActionsIfMissing(action: UpdateGroupTypesData) {
    let allDefaultActions: Array<any> = [];
    for (const groupType of action.data) {
        if (!groupType.archived && (!groupType.actions || groupType.actions.length === 0)) {
            allDefaultActions = allDefaultActions.concat([
                put(addGroupTypeAction({
                    name: 'Add Group',
                    icon: 'plus',
                }, groupType.id)),
                put(addGroupTypeAction({
                    name: 'Edit Group',
                    icon: 'pencil',
                }, groupType.id)),
                put(addGroupTypeAction({
                    name: 'Delete Group',
                    icon: 'trash',
                }, groupType.id)),
            ]);
        }
    }

    yield all(allDefaultActions);
}

export function* watchGroupTypeInstantiationRequest() {
    yield takeEvery(UPDATE_GROUP_TYPES_DATA, createDefaultActionsIfMissing);
}

function* createSeedFlowchartForGroupTypeCustomField(action: AddGroupTypeCustomFieldAction) {

    const state: ApplicationState = yield select();

    // Creating seed flowcharts are only required for computed fields
    if (action.payload.isComputed) {

        if (!action.payload.seedEntityVariable) {
            throw new Error('Computed fields need to have the seed workflow variable ID defined');
        }


        // Only seed the flochart if it doesn't already exist
        if (!state.flowchart.variables.byId.hasOwnProperty(action.payload.seedEntityVariable)) {

            const startPieceId = uuid.v4();

            yield all([
                put(addVariable({
                    id: action.payload.seedEntityVariable,
                    name: 'Group',
                    type: VariableType.GROUP,
                })),

                put(registerGroupTypeCustomFieldVariable(action.payload.seedEntityVariable, action.payload.id)),

                put(addPiece(startPieceId, PieceType.START)),

                put(updateGroupTypeCustomFieldStartPiece({
                    piece: startPieceId,
                    position: {
                        x: 0,
                        y: 0,
                    }
                }, action.payload.id))
            ]);

        }

        const changeDeltas: Array<ChangeDeltaForGroupComputedFields> = Object.keys(state.groups.byId).map(groupId => {
            return {
                groupId,
                groupChanged: true,
                locationChanged: false,
                membersChanged: false,
                workflowTypesChanged: [],
            }
        })

        yield put(bulkRecalculateComputedFieldsForGroup(changeDeltas));

    }
}

export function* watchGroupTypeCustomFieldChanges() {
    yield takeEvery([ADD_GROUP_TYPE_CUSTOM_FIELD, UPDATE_GROUP_TYPE_CUSTOM_FIELD], createSeedFlowchartForGroupTypeCustomField);
}



function* indexGroupAfterModifyingMember(action: AddGroupToMemberAction | RemoveGroupFromMemberAction) {
    const group: IGroup = yield select(state => state.groups.byId[action.groupId]);
    yield put(recalculateComputedFieldsForMember(action.memberId, false, false, [group.type], []));
    yield put(recalculateComputedFieldsForGroup(action.groupId, false, false, true, []));

    const state: ApplicationState = yield select();

    const linkedWorkflowIds = Object.keys(group.workflows).map(workflowTypeId => group.workflows[workflowTypeId]).flat().filter(workflowId => workflowId in state.workflows.byId);

    const changeDelta: Array<ChangeDeltaForWorkflowComputedFields> = linkedWorkflowIds.map(workflowId => {
        return {
            workflowId,
            workflowChanged: false,
            usersChanged: false,
            affiliationChanged: true,
        };
    });

    yield put(bulkRecalculateComputedFieldsForWorkflow(changeDelta));

}

function* indexGroupsAfterModifyingMembers(action: BulkAddMembersToGroupsAction | BulkRemoveMembersFromGroupsAction) {
    const changeDeltas: Array<ChangeDeltaForGroupComputedFields> = action.links.map(link => {
        return {
            groupId: link.groupId,
            groupChanged: false,
            locationChanged: false,
            membersChanged: true,
            workflowTypesChanged: [],
        };
    });

    yield put(bulkRecalculateComputedFieldsForGroup(changeDeltas));
}

function* provideReverseLinkForNewGroupTypeAction(action: AddGroupTypeActionAction) {
    yield put(addActionToGroupType(action.payload.id, action.parentId));
}

function* removeReverseLinkForGroupTypeAction(action: DeleteGroupTypeActionAction) {
    yield put(removeActionFromGroupType(action.id, action.parentId));
}



function* updateLocationReverseLink(action: UpdateGroupsLocationRequestAction) {
    let allPutActions = [];

    const state: ApplicationState = yield select();

    for (const groupId of action.groupIds) {
        const currentGroup: IGroup = state.groups.byId[groupId];

        if (action.locationId !== currentGroup.location) {
            allPutActions.push(put(removeGroupFromLocation(groupId, currentGroup.location)));
            allPutActions.push(put(addGroupToLocation(groupId, action.locationId)));
        }
    }

    allPutActions.push(put(updateGroupsLocation(action.groupIds, action.locationId)));
    yield all(allPutActions);

    const changeDeltas: Array<ChangeDeltaForGroupComputedFields> = action.groupIds.map(groupId => {
        return {
            groupId,
            groupChanged: true,
            locationChanged: false,
            membersChanged: false,
            workflowTypesChanged: [],
        }
    });

    yield put(bulkRecalculateComputedFieldsForGroup(changeDeltas));

    yield all(allPutActions);
}

function* reIndexGroupAfterCustomFieldUpdate(action: UpdateGroupCustomFieldDataAction) {
    yield delay(1000);

    const workflow: IWorkflow = yield select(state => state.workflows.byId[action.workflowId]);

    yield put(recalculateComputedFieldsForGroup(action.groupId, true, false, false, [workflow.type]));

    const group: IGroup = yield select(state => state.groups.byId[action.groupId]);

    const changeDeltas: Array<ChangeDeltaForMemberComputedFields> = group.members.map(memberId => {
        return {
            memberId,
            memberChanged: false,
            locationChanged: false,
            groupTypesChanged: [group.type],
            workflowTypesChanged: [],
        }
    })

    yield put(bulkRecalculateComputedFieldsForMember(changeDeltas));

    const state: ApplicationState = yield select();

    const linkedWorkflowIds = Object.keys(group.workflows).map(workflowTypeId => group.workflows[workflowTypeId]).flat().filter(workflowId => workflowId in state.workflows.byId);

    const changeDelta: Array<ChangeDeltaForWorkflowComputedFields> = linkedWorkflowIds.map(workflowId => {
        return {
            workflowId,
            workflowChanged: false,
            usersChanged: false,
            affiliationChanged: true,
        };
    });

    yield put(bulkRecalculateComputedFieldsForWorkflow(changeDelta));
}

function* indexAndProvideReverseLinksForNewGroup(action: AddGroupAction) {
    let allPutActions = [];

    allPutActions.push(put(addGroupToLocation(action.payload.id, action.payload.location)));
    allPutActions.push(put(addGroupToGroupType(action.payload.id, action.payload.type)));
    allPutActions.push(put(recalculateComputedFieldsForGroup(action.payload.id, true, false, false, [])));

    const newMemberIds = new Set(action.payload.members);

    for (const newMemberId of newMemberIds) {
        allPutActions.push(put(addGroupToMember(action.payload.id, action.payload.type, newMemberId)));
    }

    yield all(allPutActions);
}

function* provideReverseLinksForExistingGroup(action: UnArchiveGroupAction) {
    const group: IGroup = yield select(state => state.groups.byId[action.id]);
    let allPutActions = [];

    allPutActions.push(put(addGroupToLocation(action.id, group.location)));
    allPutActions.push(put(addGroupToGroupType(action.id, group.type)));

    const newMemberIds = new Set(group.members);

    for (const newMemberId of newMemberIds) {
        allPutActions.push(put(addGroupToMember(action.id, group.type, newMemberId)));
    }

    yield all(allPutActions);
}

function* removeReverseLocationLinksForGroup(action: DeleteGroupAction) {
    const currentGroup: IGroup = yield select(state => state.groups.byId[action.id]);

    let allPutActions = [];

    allPutActions.push(put(removeGroupFromLocation(action.id, currentGroup.location)));
    allPutActions.push(put(removeGroupFromGroupType(action.id, currentGroup.type)));

    const oldMemberIds = new Set(currentGroup.members);

    for (const oldMemberId of oldMemberIds) {
        allPutActions.push(put(removeGroupFromMember(action.id, currentGroup.type, oldMemberId)));
    }

    yield all(allPutActions);
}

function* updateLocationsAndMembers(action: UpdateGroupRequestAction) {

    const currentGroup: IGroup = yield select(state => state.groups.byId[action.payload.id]);

    let allPutActions = [];

    if (action.payload.location !== currentGroup.location) {
        allPutActions.push(put(removeGroupFromLocation(action.payload.id, currentGroup.location)));
        allPutActions.push(put(addGroupToLocation(action.payload.id, action.payload.location)));
    }

    if (action.payload.type !== currentGroup.type) {
        allPutActions.push(put(removeGroupFromGroupType(action.payload.id, currentGroup.type)));
        allPutActions.push(put(addGroupToGroupType(action.payload.id, action.payload.type)));
    }

    const oldMemberIds = new Set(currentGroup.members);
    const newMemberIds = new Set(action.payload.members);

    for (const oldMemberId of oldMemberIds) {
        if (!newMemberIds.has(oldMemberId)) {
            allPutActions.push(put(removeGroupFromMember(action.payload.id, action.payload.type, oldMemberId)));
        }
    }

    for (const newMemberId of newMemberIds) {
        if (!oldMemberIds.has(newMemberId)) {
            allPutActions.push(put(addGroupToMember(action.payload.id, action.payload.type, newMemberId)));
        }
    }

    allPutActions.push(put(updateGroup(action.payload)));

    yield all(allPutActions);

    yield put(recalculateComputedFieldsForGroup(action.payload.id, true, false, false, []));

    const changeDeltas: Array<ChangeDeltaForMemberComputedFields> = action.payload.members
        .filter(memberId => oldMemberIds.has(memberId))
        .map(memberId => {
            return {
                memberId,
                memberChanged: false,
                locationChanged: false,
                groupTypesChanged: [action.payload.type],
                workflowTypesChanged: [],
            }
        });

    yield put(bulkRecalculateComputedFieldsForMember(changeDeltas));

    const state: ApplicationState = yield select();

    const linkedWorkflowIds = Object.keys(currentGroup.workflows).map(workflowTypeId => currentGroup.workflows[workflowTypeId]).flat().filter(workflowId => workflowId in state.workflows.byId);

    const changeDelta: Array<ChangeDeltaForWorkflowComputedFields> = linkedWorkflowIds.map(workflowId => {
        return {
            workflowId,
            workflowChanged: false,
            usersChanged: false,
            affiliationChanged: true,
        };
    });

    yield put(bulkRecalculateComputedFieldsForWorkflow(changeDelta));
}

function* updateMemberReverseLinks(action: SetMembersForGroupRequestAction) {
    const currentGroup: IGroup = yield select(state => state.groups.byId[action.groupId]);
    const currentMemberIds = currentGroup.members;
    const newMemberIds = action.memberTypes === 'all_members' ? action.memberIds : Array.from(new Set(currentGroup.members.concat(action.memberIds)));

    const memberIdsToAdd = newMemberIds.filter(newMemberId => !currentMemberIds.includes(newMemberId));
    const memberIdsToRemove = currentMemberIds.filter(currentMemberId => !newMemberIds.includes(currentMemberId));

    const allPutActions = [];

    for (const memberId of memberIdsToAdd) {
        allPutActions.push(put(addGroupToMember(currentGroup.id, currentGroup.type, memberId)));
    }

    for (const memberId of memberIdsToRemove) {
        allPutActions.push(put(removeGroupFromMember(currentGroup.id, currentGroup.type, memberId)));
    }

    allPutActions.push(put(setMembersForGroup(action.groupId, action.memberTypes, action.memberIds)));

    yield all(allPutActions);
}

function shouldUpdateCustomField(customField: CustomField, changeDelta: ChangeDeltaForGroupComputedFields, variablePiecesInFlowchart: Array<IVariablePiece>) {

    if (!customField.isComputed || !customField.startPiece) {
        return false;
    }

    if (changeDelta.groupChanged) {
        return true;
    }

    if (changeDelta.locationChanged) {
        const usesLocation = variablePiecesInFlowchart.some(variablePiece => {
            const isMemberVariable = variablePiece.variable === customField.seedEntityVariable;

            if (!isMemberVariable) {
                return false;
            }

            const nestingObject = variablePiece.nesting;

            if (!nestingObject) {
                return false;
            }

            if (nestingObject.length === 0) {
                return false;
            }

            return nestingObject[0].type === 'LOCATION';
        });

        return usesLocation;
    }

    if (changeDelta.membersChanged) {
        const usesMembers = variablePiecesInFlowchart.some(variablePiece => {
            const isGroupVariable = variablePiece.variable === customField.seedEntityVariable;

            if (!isGroupVariable) {
                return false;
            }

            const nestingObject = variablePiece.nesting;

            if (!nestingObject) {
                return false;
            }

            if (nestingObject.length === 0) {
                return false;
            }

            return nestingObject[0].type === 'MEMBERS_LIST';
        });

        return usesMembers;
    }

    for (const workflowTypeId of changeDelta.workflowTypesChanged) {
        const usesWorkflowType = variablePiecesInFlowchart.some(variablePiece => {
            const isGroupVariable = variablePiece.variable === customField.seedEntityVariable;

            if (!isGroupVariable) {
                return false;
            }

            const nestingObject = variablePiece.nesting;

            if (!nestingObject) {
                return false;
            }

            if (nestingObject.length === 0) {
                return false;
            }

            return nestingObject[0].type === 'WORKFLOWS_LIST' && nestingObject[0].value === workflowTypeId;
        });

        return usesWorkflowType;
    }

    return false;
}

function* recalculateComputedFieldsDataForGroup(action: RecalculateComputedFieldsForGroupAction) {
    const state: ApplicationState = yield select();
    const group = state.groups.byId[action.groupId];
    const groupType = state.groups.types.byId[group.type];
    const changeDelta: ChangeDeltaForGroupComputedFields = {
        groupId: action.groupId,
        groupChanged: action.groupChanged,
        locationChanged: action.locationChanged,
        membersChanged: action.membersChanged,
        workflowTypesChanged: action.workflowTypesChanged,
    };

    const customFieldIdsToUpdate = groupType.customFields.filter(customFieldId => {
        const customField = state.groups.types.customFields.byId[customFieldId];

        if (!customField.isComputed || !customField.startPiece) {
            return false;
        }

        const piecesInFlowchart = getAllPiecesInPiece(state.flowchart.pieces, customField.startPiece.piece);

        const variablePiecesInFlowchart: Array<IVariablePiece> = piecesInFlowchart.filter(piece => piece.type === PieceType.VARIABLE) as Array<IVariablePiece>;
        return shouldUpdateCustomField(customField, changeDelta, variablePiecesInFlowchart);
    });

    if (customFieldIdsToUpdate.length > 0) {
        const customFieldData: CustomFieldDataHolder = {};

        for (const customFieldId of customFieldIdsToUpdate) {
            const customField = state.groups.types.customFields.byId[customFieldId];
            const fieldValue = getValueForComputedField(customField, action.groupId, 'group', state);

            if (fieldValue !== group.customFields[customFieldId]) {
                customFieldData[customFieldId] = fieldValue;
            }
        }

        if (Object.keys(customFieldData).length > 0) {
            yield put(updateGroupComputedFieldData(action.groupId, customFieldData));
        }

    }

}

function* bulkRecalculateComputedFieldsDataForGroup(action: BulkRecalculateComputedFieldsForGroupAction) {
    const state: ApplicationState = yield select();

    const variablePiecesForFields: {
        [groupTypeId: string]: {
            [customFieldId: string]: Array<IVariablePiece>,
        }
    } = {};

    for (const groupTypeId of Object.keys(state.groups.types.byId)) {
        variablePiecesForFields[groupTypeId] = {};
        const groupType = state.groups.types.byId[groupTypeId];

        for (const customFieldId of groupType.customFields) {
            const customField = state.groups.types.customFields.byId[customFieldId];

            if (!customField.isComputed || !customField.startPiece) {
                continue;
            }

            const piecesInFlowchart = getAllPiecesInPiece(state.flowchart.pieces, customField.startPiece.piece);
            const variablePiecesInFlowchart: Array<IVariablePiece> = piecesInFlowchart.filter(piece => piece.type === PieceType.VARIABLE) as Array<IVariablePiece>;
            variablePiecesForFields[groupTypeId][customFieldId] = variablePiecesInFlowchart;
        }
    }

    const bulkRecalculatePayload: Array<ComputedFieldUpdatePayloadForGroup> = action.payload.map(changeDelta => {
        const group = state.groups.byId[changeDelta.groupId];
        const groupType = state.groups.types.byId[group.type];

        const customFieldIdsToUpdate = groupType.customFields.filter(customFieldId => {
            const customField = state.groups.types.customFields.byId[customFieldId];

            if (!customField.isComputed || !customField.startPiece) {
                return false;
            }

            const variablePiecesInFlowchart = variablePiecesForFields[groupType.id][customFieldId];
            return shouldUpdateCustomField(customField, changeDelta, variablePiecesInFlowchart);
        });

        const customFieldData: CustomFieldDataHolder = {};

        for (const customFieldId of customFieldIdsToUpdate) {
            const customField = state.groups.types.customFields.byId[customFieldId];
            const fieldValue = getValueForComputedField(customField, changeDelta.groupId, 'group', state);

            if (fieldValue !== group.customFields[customFieldId]) {
                customFieldData[customFieldId] = fieldValue;
            }
        }

        return {
            groupId: changeDelta.groupId,
            customFieldData,
        };
    });

    const filteredBulkRecalculatePayload = bulkRecalculatePayload.filter(payload => Object.keys(payload.customFieldData).length > 0);

    if (filteredBulkRecalculatePayload.length > 0) {
        yield put(bulkUpdateGroupComputedFieldData(bulkRecalculatePayload));
    }

}

function* recalculateAllGroupCustomFieldsData() {
    const state: ApplicationState = yield select();

    const changeDeltas: Array<ChangeDeltaForGroupComputedFields> = Object.keys(state.groups.byId).map(groupId => {
        return {
            groupId,
            groupChanged: true,
            locationChanged: false,
            membersChanged: false,
            workflowTypesChanged: [],
        }
    })

    yield put(bulkRecalculateComputedFieldsForGroup(changeDeltas));
}

function fetchGroupPageData(pageSize: number, currentPageNumber: number) {

    const serverUrl = new URL('/online-groups/', BASE_URL);

    serverUrl.searchParams.set('pageSize', String(pageSize));
    serverUrl.searchParams.set('currentPageNumber', String(currentPageNumber));

    return axios.get<PageDataForOnlineEntities>(serverUrl.toString(), {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

function fetchGroupQueryData(filters: GroupFilters, searchTerm: string, pageSize: number) {

    const serverUrl = new URL('/online-group-filters/', BASE_URL);

    const queryData: OnlineGroupQueryData = {
        filters,
        searchTerm,
        pageSize,
    }

    return axios.post(serverUrl.toString(), queryData, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

function* getNewPageDataForGroup() {
    yield delay(200);

    const myId: string = yield select(state => state.myData.id);
    const user: IUser | undefined = isUUID(myId) ? yield select(state => state.users.byId[myId]) : undefined;

    if (user && user.isOnline) {
        const pageSize: number = yield select((state: ApplicationState) => state.groups.pageSize);
        const currentPageNumber: number = yield select((state: ApplicationState) => state.groups.currentPageNumber);

        yield all([
            put(clearMemberEntries()),
            put(clearGroupEntries()),
            put(clearWorkflowEntries()),
        ]);

        const pageResponseData: AxiosResponse<PageDataForOnlineEntities> = yield call(fetchGroupPageData, pageSize, currentPageNumber);

        if (pageResponseData.status === 200) {
            yield all([
                put(appendMembers(pageResponseData.data.members)),
                put(appendGroups(pageResponseData.data.groups)),
                put(appendWorkflows(pageResponseData.data.workflows)),
                put(setLastRefreshTime(pageResponseData.data.syncTime)),
            ]);
        }
    }
}

function* getFilteredDataForGroup() {

    yield delay(1000);

    const myId: string = yield select(state => state.myData.id);
    const user: IUser | undefined = isUUID(myId) ? yield select(state => state.users.byId[myId]) : undefined;

    if (user && user.isOnline) {
        const groupFilters: GroupFilters = yield select((state: ApplicationState) => state.groups.filters);
        const groupSearchTerm: string = yield select((state: ApplicationState) => state.groups.searchTerm);
        const pageSize: number = yield select((state: ApplicationState) => state.groups.pageSize);

        yield all([
            put(clearMemberEntries()),
            put(clearGroupEntries()),
            put(clearWorkflowEntries()),
            put(setInfoMessage('Applying group search/filters')),
        ]);

        const pageResponseData: AxiosResponse<FilterResponseForOnlineEntities> = yield call(fetchGroupQueryData, groupFilters, groupSearchTerm, pageSize);

        if (pageResponseData.status === 200) {
            yield all([
                put(appendMembers(pageResponseData.data.pageData.members)),
                put(appendGroups(pageResponseData.data.pageData.groups)),
                put(appendWorkflows(pageResponseData.data.pageData.workflows)),
                put(setTotalNumberOfGroups(pageResponseData.data.totalNumber)),
                put(setLastRefreshTime(pageResponseData.data.pageData.syncTime)),
                put(clearInfoMessage()),
            ]);
        }
    }
}

export function* watchGroupTypeActionCreationChanges() {
    yield takeEvery(ADD_GROUP_TYPE_ACTION, provideReverseLinkForNewGroupTypeAction);
}

export function* watchGroupTypeActionDeletionChanges() {
    yield takeEvery(DELETE_GROUP_TYPE_ACTION, removeReverseLinkForGroupTypeAction);
}

export function* watchGroupCreationChanges() {
    yield takeEvery(ADD_GROUP, indexAndProvideReverseLinksForNewGroup);
}

export function* watchGroupDeletionChanges() {
    yield takeEvery(DELETE_GROUP, removeReverseLocationLinksForGroup);
}

export function* watchGroupUnarchiveChanges() {
    yield takeEvery(UN_ARCHIVE_GROUP, provideReverseLinksForExistingGroup);
}

export function* watchGroupUpdateChanges() {
    yield takeEvery(UPDATE_GROUP_REQUEST, updateLocationsAndMembers);
}

export function* watchGroupCustomFieldUpdateChanges() {
    yield takeLatest(UPDATE_GROUP_CUSTOM_FIELD_DATA, reIndexGroupAfterCustomFieldUpdate);
}

export function* watchGroupMemberUpdateChanges() {
    yield takeEvery(SET_MEMBERS_FOR_GROUP_REQUEST, updateMemberReverseLinks);
}

export function* watchGroupAddToMemberChanges() {
    yield takeEvery(ADD_MEMBER_TO_GROUP, indexGroupAfterModifyingMember)
}

export function* watchGroupRemoveFromMemberChanges() {
    yield takeEvery(REMOVE_MEMBER_FROM_GROUP, indexGroupAfterModifyingMember)
}

export function* watchBulkGroupAddToMemberChanges() {
    yield takeEvery(BULK_ADD_MEMBERS_TO_GROUPS, indexGroupsAfterModifyingMembers)
}

export function* watchBulkGroupRemoveFromMemberChanges() {
    yield takeEvery(BULK_REMOVE_MEMBERS_FROM_GROUPS, indexGroupsAfterModifyingMembers)
}

export function* watchGroupLocationUpdate() {
    yield takeEvery(UPDATE_GROUPS_LOCATION_REQUEST, updateLocationReverseLink)
}

export function* watchGroupCustomFieldRecalculation() {
    yield takeEvery(RECALCULATE_COMPUTED_FIELDS_FOR_GROUP, recalculateComputedFieldsDataForGroup);
}

export function* watchBulkGroupCustomFieldRecalculation() {
    yield takeEvery(BULK_RECALCULATE_COMPUTED_FIELDS_FOR_GROUP, bulkRecalculateComputedFieldsDataForGroup);
}

export function* watchRecomputeAllGroups() {
    yield takeEvery(RECOMPUTE_ALL_GROUPS, recalculateAllGroupCustomFieldsData);
}

export function* watchGroupPagePageChanges() {
    yield takeLatest([GO_TO_PAGE_GROUP_TABLE, SET_PAGE_SIZE_GROUP_TABLE], getNewPageDataForGroup);
}

export function* watchGroupQueryChangesForOnline() {
    yield takeLatest([SEARCH_GROUP_TABLE, FILTER_GROUP_TABLE], getFilteredDataForGroup)
}
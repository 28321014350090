import { AllPieceTypes, PieceState } from '../../store/flowchart/pieces/types';
import { IVariable, VariableState } from '../../store/flowchart/variables/types';
import { ApplicationState } from '../../store/types';
import { IProject, ProjectState } from '../../store/structure/project/types';
import { ILevel, LevelState } from '../../store/structure/level/types';
import { IRole, RoleState } from '../../store/structure/role/types';
import { ILocation, LocationState } from '../../store/structure/location/types';
import { NormalizedModel, Synchronizable } from '../../store/normalized-model';
import { CustomField, CustomFieldState, FieldChoice, CustomFieldDataType, CustomFieldOptionsDataType, WorkflowTypeCustomField, WorkflowTypeCustomFieldState, WorkflowTypeCustomFieldDataType } from '../../store/custom-fields/types';
import { IUser, UserState } from '../../store/users/types';
import { IMember, MemberState } from '../../store/members/types';
import { IMemberType, MemberTypeState } from '../../store/members/types/types';
import { GroupState, IGroup } from '../../store/groups/types';
import { GroupTypeState, IGroupType } from '../../store/groups/types/types';
import { IWorkflow, WorkflowState } from '../../store/workflows/types';
import { IWorkflowType, WorkflowTypeState } from '../../store/workflows/types/types';
import { IStatus, StatusState } from '../../store/workflows/types/statuses/types';
import { IReport, ReportState } from '../../store/reports/types';
import { IReportType, ReportTypeState } from '../../store/reports/types/types';
import { DataFragmentToPush, DataToPush } from './types';
import { IWidget, WidgetState } from '../../store/widgets/types';
import { IWidgetConfiguration } from '../../store/my-data/widgets/types';
import { ILanguage, LanguageState } from '../../store/internationalization/languages/types';
import { isObjectEmpty, isUUID } from '../utilities';
import { TranslationsState, LanguageTranslations } from '../../store/internationalization/translations/types';
import { IMemberTypeAction, MemberTypeActionState } from '../../store/members/types/actions/types';
import { IGroupTypeAction, GroupTypeActionState } from '../../store/groups/types/actions/types';
import { IStaticDataHolder, StaticDataHolderState } from '../../store/static-info/types';
import { DataFragmentState, IDataFragment } from '../../store/static-info/data-fragment/types';
import { AllEventTypes, EventState } from '../../store/events/types';

function getEmptyDataFragment<T>(): DataFragmentToPush<T> {
    return {
        created: [],
        updated: [],
        deleted: [],
    };
}

interface FetchableById<T> {
    byId: {
        [id: string]: T
    };
}

function populateChangedData<T extends FetchableById<U>, U>(state: T,
                                                            createdIds: Set<string>, updatedIds: Set<string>, deletedIds: Set<string>) {
    const dataFragment: DataFragmentToPush<U> = {
        created: [],
        updated: [],
        deleted: [],
    };

    for (const entityId of Array.from(createdIds)) {
        if (state.byId[entityId]) {
            dataFragment.created.push(state.byId[entityId]);
        }
    }

    for (const entityId of Array.from(updatedIds)) {
        if (!createdIds.has(entityId)) {
            if (state.byId[entityId]) {
                dataFragment.updated.push(state.byId[entityId]);
            }
        }
    }

    for (const entityId of Array.from(deletedIds)) {
        if (state.byId[entityId]) {
            dataFragment.deleted.push(state.byId[entityId]);
        }
    }

    return dataFragment;
}

function populateChangedModel<T extends NormalizedModel<U>, U extends Synchronizable>(state: T) {
    const dataFragment = populateChangedData<T, U>(state, state.createdIds, state.updatedIds, state.deletedIds);
    return dataFragment;
}

function populateChangedCustomField<T extends CustomFieldState>(state: T) {
    const dataFragment = populateChangedData<CustomFieldDataType, CustomField>(state.customFields,
        state.createdCustomFieldIds, state.updatedCustomFieldIds, state.deletedCustomFieldIds);

    return dataFragment;
}

function populateChangedWorkflowTypeCustomField<T extends WorkflowTypeCustomFieldState>(state: T) {
    const dataFragment = populateChangedData<WorkflowTypeCustomFieldDataType, WorkflowTypeCustomField>(
        state.customFields, state.createdCustomFieldIds, state.updatedCustomFieldIds, state.deletedCustomFieldIds);

    return dataFragment;
}

function populateChangedCustomFieldOption<T extends CustomFieldState>(state: T) {
    const dataFragment = populateChangedData<CustomFieldOptionsDataType, FieldChoice>(state.customFieldOptions,
        state.createdCustomFieldOptionIds, state.updatedCustomFieldOptionIds, state.deletedCustomFieldOptionIds);

    return dataFragment;
}

function hasData<T>(dataFragment: DataFragmentToPush<T>) {
    return dataFragment.created.length > 0 || dataFragment.updated.length > 0 || dataFragment.deleted.length > 0;
}

export function encodeTranslations(translationsState: TranslationsState) {
    const encodedTranslations: {
        [languageId: string]: LanguageTranslations
    } = {};

    if (translationsState.byLanguage) {

        for (const languageId of Object.keys(translationsState.byLanguage)) {
            const languageTranslations = translationsState.byLanguage[languageId];
            const updatedTranslations: {
                [phrase: string]: string,
            } = {};
    
            if (languageTranslations) {
                for (const phrase of Object.keys(languageTranslations)) {
                    updatedTranslations[phrase.split('.').join('．')] = languageTranslations[phrase];
                }
            }
    
            encodedTranslations[languageId] = updatedTranslations;
        }

    }

    return encodedTranslations;
}

export function decodeTranslations(translationsState: { [languageId: string]: LanguageTranslations; }) {
    const encodedTranslations: {
        [languageId: string]: LanguageTranslations
    } = {};

    if (translationsState) {

        for (const languageId of Object.keys(translationsState)) {
            if (isUUID(languageId)) {
                const languageTranslations = translationsState[languageId];
                const updatedTranslations: {
                    [phrase: string]: string,
                } = {};
    
                if (languageTranslations) {
        
                    for (const phrase of Object.keys(languageTranslations)) {
                        updatedTranslations[phrase.split('．').join('.')] = languageTranslations[phrase];
                    }
                }
        
                encodedTranslations[languageId] = updatedTranslations;
            }
        }

    }

    return encodedTranslations;
}

function doesLocalModelHaveChanges<T extends NormalizedModel<U>, U extends Synchronizable>(state: T) {
    return state.createdIds.size > 0 || state.updatedIds.size > 0 || state.deletedIds.size > 0;
}

function doesLocalCustomFieldHaveChanges<T extends CustomFieldState>(state: T) {
    return state.createdCustomFieldIds.size > 0 || state.updatedCustomFieldIds.size > 0 || state.deletedCustomFieldIds.size > 0 || 
        state.createdCustomFieldOptionIds.size > 0 || state.updatedCustomFieldOptionIds.size > 0 || state.deletedCustomFieldOptionIds.size > 0;
}

export function hasLocalChanges(applicationState: ApplicationState) {
    if (doesLocalModelHaveChanges<ProjectState, IProject>(applicationState.structure.projects)) {
        return true;
    }

    if (applicationState.structure.projects.areProjectsReordered) {
        return true;
    }

    if (doesLocalModelHaveChanges<LevelState, ILevel>(applicationState.structure.levels)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.structure.projects.reOrderedLevels)) {
        return true;
    }

    if (doesLocalCustomFieldHaveChanges<LevelState>(applicationState.structure.levels)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.structure.levels.reOrderedCustomFields)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.structure.levels.reOrderedCustomFieldOptions)) {
        return true;
    }


    if (doesLocalModelHaveChanges<RoleState, IRole>(applicationState.structure.roles)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.structure.levels.reOrderedRoles)) {
        return true;
    }

    if (doesLocalCustomFieldHaveChanges<RoleState>(applicationState.structure.roles)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.structure.roles.reOrderedCustomFields)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.structure.roles.reOrderedCustomFieldOptions)) {
        return true;
    }

    if (doesLocalModelHaveChanges<LocationState, ILocation>(applicationState.structure.locations)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.structure.locations.reOrderedTopLevelLocations)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.structure.locations.reOrderedChildLocations)) {
        return true;
    }

    if (doesLocalModelHaveChanges<UserState, IUser>(applicationState.users)) {
        return true;
    }

    if (doesLocalCustomFieldHaveChanges<UserState>(applicationState.users)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.users.reOrderedCustomFieldOptions)) {
        return true;
    }


    if (doesLocalModelHaveChanges<MemberState, IMember>(applicationState.members)) {
        return true;
    }

    if (doesLocalModelHaveChanges<MemberTypeState, IMemberType>(applicationState.members.types)) {
        return true;
    }

    if (applicationState.members.types.areMemberTypesReordered) {
        return true;
    }

    if (doesLocalCustomFieldHaveChanges<MemberTypeState>(applicationState.members.types)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.members.types.reOrderedActions)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.members.types.reOrderedCustomFields)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.members.types.reOrderedCustomFieldOptions)) {
        return true;
    }

    if (doesLocalModelHaveChanges<MemberTypeActionState, IMemberTypeAction>(applicationState.members.types.actions)) {
        return true;
    }


    if (doesLocalModelHaveChanges<GroupState, IGroup>(applicationState.groups)) {
        return true;
    }

    if (doesLocalModelHaveChanges<GroupTypeState, IGroupType>(applicationState.groups.types)) {
        return true;
    }

    if (applicationState.groups.types.areGroupTypesReordered) {
        return true;
    }

    if (doesLocalCustomFieldHaveChanges<GroupTypeState>(applicationState.groups.types)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.groups.types.reOrderedActions)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.groups.types.reOrderedCustomFields)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.groups.types.reOrderedCustomFieldOptions)) {
        return true;
    }

    if (doesLocalModelHaveChanges<GroupTypeActionState, IGroupTypeAction>(applicationState.groups.types.actions)) {
        return true;
    }


    if (doesLocalModelHaveChanges<WorkflowState, IWorkflow>(applicationState.workflows)) {
        return true;
    }

    if (doesLocalModelHaveChanges<WorkflowTypeState, IWorkflowType>(applicationState.workflows.types)) {
        return true;
    }

    if (applicationState.workflows.types.areWorkflowTypesReordered) {
        return true;
    }

    if (doesLocalCustomFieldHaveChanges<WorkflowTypeState>(applicationState.workflows.types)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.groups.types.reOrderedCustomFields)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.workflows.types.reOrderedCustomFieldOptions)) {
        return true;
    }

    if (doesLocalModelHaveChanges<StatusState, IStatus>(applicationState.workflows.types.statuses)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.workflows.types.reOrderedStatuses)) {
        return true;
    }


    if (doesLocalModelHaveChanges<ReportState, IReport>(applicationState.reports)) {
        return true;
    }

    if (doesLocalModelHaveChanges<ReportTypeState, IReportType>(applicationState.reports.types)) {
        return true;
    }


    if (doesLocalModelHaveChanges<StaticDataHolderState, IStaticDataHolder>(applicationState.staticInfo)) {
        return true;
    }

    if (doesLocalModelHaveChanges<DataFragmentState, IDataFragment>(applicationState.staticInfo.fragments)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.staticInfo.fragments.reOrderedTopLevelDataFragments)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.staticInfo.fragments.reOrderedChildDataFragments)) {
        return true;
    }


    if (doesLocalModelHaveChanges<WidgetState, IWidget>(applicationState.widgets)) {
        return true;
    }

    if (applicationState.myData.widgets.createdConfigurations.size > 0 ||
        applicationState.myData.widgets.updatedIds.size > 0 ||
        applicationState.myData.widgets.deletedConfigurations.size > 0) {

        return true;
    }


    if (doesLocalModelHaveChanges<PieceState, AllPieceTypes>(applicationState.flowchart.pieces)) {
        return true;
    }

    if (doesLocalModelHaveChanges<VariableState, IVariable>(applicationState.flowchart.variables)) {
        return true;
    }


    if (doesLocalModelHaveChanges<LanguageState, ILanguage>(applicationState.internationalization.languages)) {
        return true;
    }

    if (!isObjectEmpty(applicationState.internationalization.translations.updatedWords)) {
        return true;
    }

    if (applicationState.organization.superUserPassword) {
        return true;
    }

    if (applicationState.organization.hasUpdated) {
        return true;
    }

    if (applicationState.permissions.updatedIds.size > 0) {
        return true;
    }

    return false;
}

export function collectDataToPush(applicationState: ApplicationState) {

    const dataToPush: DataToPush = {
        structure: {
            projects: getEmptyDataFragment<IProject>(),
            levels: getEmptyDataFragment<ILevel>(),
            roles: getEmptyDataFragment<IRole>(),
            locations: getEmptyDataFragment<ILocation>(),

            levelCustomFields: getEmptyDataFragment<CustomField>(),
            levelCustomFieldOptions: getEmptyDataFragment<FieldChoice>(),

            roleCustomFields: getEmptyDataFragment<CustomField>(),
            roleCustomFieldOptions: getEmptyDataFragment<FieldChoice>(),

            reOrderedProjects: [],
            
            reOrderedLevels: {},
            reOrderedLevelCustomFields: {},
            reOrderedLevelCustomFieldOptions: {},

            reOrderedRoles: {},
            reOrderedRoleCustomFields: {},
            reOrderedRoleCustomFieldOptions: {},
            
            reOrderedTopLevelLocations: {},
            reOrderedChildLocations: {},
        },
        users: {
            entries: getEmptyDataFragment<IUser>(),
            customFields: getEmptyDataFragment<CustomField>(),
            customFieldOptions: getEmptyDataFragment<FieldChoice>(),

            reOrderedUserCustomFieldOptions: {},
        },
        members: getEmptyDataFragment<IMember>(),
        memberTypes: {
            entries: getEmptyDataFragment<IMemberType>(),
            customFields: getEmptyDataFragment<CustomField>(),
            customFieldOptions: getEmptyDataFragment<FieldChoice>(),
            reOrderedActions: {},

            reOrderedMemberTypes: [],
            reOrderedMemberCustomFields: {},
            reOrderedMemberCustomFieldOptions: {},
        },
        memberTypeActions: getEmptyDataFragment<IMemberTypeAction>(),
        groups: getEmptyDataFragment<IGroup>(),
        groupTypes: {
            entries: getEmptyDataFragment<IGroupType>(),
            customFields: getEmptyDataFragment<CustomField>(),
            customFieldOptions: getEmptyDataFragment<FieldChoice>(),
            reOrderedActions: {},

            reOrderedGroupTypes: [],
            reOrderedGroupCustomFields: {},
            reOrderedGroupCustomFieldOptions: {},
        },
        groupTypeActions: getEmptyDataFragment<IGroupTypeAction>(),
        workflows: getEmptyDataFragment<IWorkflow>(),
        workflowTypes: {
            entries: getEmptyDataFragment<IWorkflowType>(),
            customFields: getEmptyDataFragment<WorkflowTypeCustomField>(),
            customFieldOptions: getEmptyDataFragment<FieldChoice>(),

            reOrderedWorkflowStatuses: {},
            reOrderedWorkflowTypes: [],
            reOrderedWorkflowCustomFields: {},
            reOrderedWorkflowCustomFieldOptions: {},
        },
        workflowTypeStatuses: getEmptyDataFragment<IStatus>(),
        reports: getEmptyDataFragment<IReport>(),
        reportTypes: getEmptyDataFragment<IReportType>(),
        staticDataHolders: getEmptyDataFragment<IStaticDataHolder>(),
        dataFragments: getEmptyDataFragment<IDataFragment>(),
            
        reOrderedTopLevelDataFragments: {},
        reOrderedChildDataFragments: {},

        widgets: getEmptyDataFragment<IWidget>(),
        widgetConfigurations: getEmptyDataFragment<IWidgetConfiguration>(),
        flowchart: {
            pieces: getEmptyDataFragment<AllPieceTypes>(),
            deletedPieces: [],
            variables: getEmptyDataFragment<IVariable>(),
        },

        events: getEmptyDataFragment<AllEventTypes>(),
        errors: [],

        languages: getEmptyDataFragment<ILanguage>(),
        translations: {},

        permissions: [],

        lastSyncTime: applicationState.myData.lastSyncTime || undefined,
    };

    let hasDataToPush = false;

    dataToPush.structure.projects = populateChangedModel<ProjectState, IProject>(applicationState.structure.projects);

    if (applicationState.structure.projects.areProjectsReordered) {
        dataToPush.structure.reOrderedProjects = applicationState.structure.projects.allEntries.slice();
    }

    hasDataToPush = hasDataToPush || applicationState.structure.projects.areProjectsReordered || hasData<IProject>(dataToPush.structure.projects);


    dataToPush.structure.levels = populateChangedModel<LevelState, ILevel>(applicationState.structure.levels);
    dataToPush.structure.levelCustomFields = populateChangedCustomField<LevelState>(applicationState.structure.levels);
    dataToPush.structure.levelCustomFieldOptions = populateChangedCustomFieldOption<LevelState>(applicationState.structure.levels);

    const areLevelsReordered = !isObjectEmpty(applicationState.structure.projects.reOrderedLevels);

    if (areLevelsReordered) {
        dataToPush.structure.reOrderedLevels = JSON.parse(JSON.stringify(applicationState.structure.projects.reOrderedLevels));
    }

    const areLevelCustomFieldsReordered = !isObjectEmpty(applicationState.structure.levels.reOrderedCustomFields);

    if (areLevelCustomFieldsReordered) {
        dataToPush.structure.reOrderedLevelCustomFields = JSON.parse(JSON.stringify(applicationState.structure.levels.reOrderedCustomFields));
    }

    const areLevelCustomFieldOptionsReordered = !isObjectEmpty(applicationState.structure.levels.reOrderedCustomFieldOptions);

    if (areLevelCustomFieldOptionsReordered) {
        dataToPush.structure.reOrderedLevelCustomFieldOptions = JSON.parse(JSON.stringify(applicationState.structure.levels.reOrderedCustomFieldOptions));
    }

    hasDataToPush = hasDataToPush || areLevelsReordered || areLevelCustomFieldOptionsReordered 
                        || hasData<ILevel>(dataToPush.structure.levels)
                        || hasData<CustomField>(dataToPush.structure.levelCustomFields)
                        || hasData<FieldChoice>(dataToPush.structure.levelCustomFieldOptions);


    dataToPush.structure.roles = populateChangedModel<RoleState, IRole>(applicationState.structure.roles);
    dataToPush.structure.roleCustomFields = populateChangedCustomField<RoleState>(applicationState.structure.roles);
    dataToPush.structure.roleCustomFieldOptions = populateChangedCustomFieldOption<RoleState>(applicationState.structure.roles);

    const areRolesReordered = !isObjectEmpty(applicationState.structure.levels.reOrderedRoles);

    if (areRolesReordered) {
        dataToPush.structure.reOrderedRoles = JSON.parse(JSON.stringify(applicationState.structure.levels.reOrderedRoles));
    }

    const areRoleCustomFieldsReordered = !isObjectEmpty(applicationState.structure.roles.reOrderedCustomFields);

    if (areRoleCustomFieldsReordered) {
        dataToPush.structure.reOrderedRoleCustomFields = JSON.parse(JSON.stringify(applicationState.structure.roles.reOrderedCustomFields));
    }

    const areRoleCustomFieldOptionsReordered = !isObjectEmpty(applicationState.structure.roles.reOrderedCustomFieldOptions);

    if (areRoleCustomFieldOptionsReordered) {
        dataToPush.structure.reOrderedRoleCustomFieldOptions = JSON.parse(JSON.stringify(applicationState.structure.roles.reOrderedCustomFieldOptions));
    }

    hasDataToPush = hasDataToPush || areRolesReordered || areRoleCustomFieldOptionsReordered 
                        || hasData<IRole>(dataToPush.structure.roles)
                        || hasData<CustomField>(dataToPush.structure.roleCustomFields)
                        || hasData<FieldChoice>(dataToPush.structure.roleCustomFieldOptions);

    dataToPush.structure.locations = populateChangedModel<LocationState, ILocation>(applicationState.structure.locations);

    const areTopLevelLocationsReordered = !isObjectEmpty(applicationState.structure.locations.reOrderedTopLevelLocations);

    if (areTopLevelLocationsReordered) {
        dataToPush.structure.reOrderedTopLevelLocations = JSON.parse(JSON.stringify(applicationState.structure.locations.reOrderedTopLevelLocations));
    }

    const areChildLocationsReordered = !isObjectEmpty(applicationState.structure.locations.reOrderedChildLocations);

    if (areChildLocationsReordered) {
        dataToPush.structure.reOrderedChildLocations = JSON.parse(JSON.stringify(applicationState.structure.locations.reOrderedChildLocations));
    }

    hasDataToPush = hasDataToPush || areTopLevelLocationsReordered || areChildLocationsReordered || hasData<ILocation>(dataToPush.structure.locations);


    dataToPush.users.entries = populateChangedModel<UserState, IUser>(applicationState.users);
    dataToPush.users.customFields = populateChangedCustomField<UserState>(applicationState.users);
    dataToPush.users.customFieldOptions = populateChangedCustomFieldOption<UserState>(applicationState.users);

    const areUserCustomFieldOptionsReordered = !isObjectEmpty(applicationState.users.reOrderedCustomFieldOptions);

    if (areUserCustomFieldOptionsReordered) {
        dataToPush.users.reOrderedUserCustomFieldOptions = JSON.parse(JSON.stringify(applicationState.users.reOrderedCustomFieldOptions));
    }

    hasDataToPush = hasDataToPush || areUserCustomFieldOptionsReordered || hasData<IUser>(dataToPush.users.entries)
                        || hasData<CustomField>(dataToPush.users.customFields)
                        || hasData<FieldChoice>(dataToPush.users.customFieldOptions);


    dataToPush.members = populateChangedModel<MemberState, IMember>(applicationState.members);

    dataToPush.memberTypes.entries = populateChangedModel<MemberTypeState, IMemberType>(applicationState.members.types);
    dataToPush.memberTypes.customFields = populateChangedCustomField<MemberTypeState>(applicationState.members.types);
    dataToPush.memberTypes.customFieldOptions = populateChangedCustomFieldOption<MemberTypeState>(applicationState.members.types);

    if (applicationState.members.types.areMemberTypesReordered) {
        dataToPush.memberTypes.reOrderedMemberTypes = applicationState.members.types.allEntries.slice();
    }

    const areMemberTypeActionsReordered = !isObjectEmpty(applicationState.members.types.reOrderedActions);

    if (areMemberTypeActionsReordered) {
        dataToPush.memberTypes.reOrderedActions = JSON.parse(JSON.stringify(applicationState.members.types.reOrderedActions));
    }

    const areMemberTypeCustomFieldsReordered = !isObjectEmpty(applicationState.members.types.reOrderedCustomFields);

    if (areMemberTypeCustomFieldsReordered) {
        dataToPush.memberTypes.reOrderedMemberCustomFields = JSON.parse(JSON.stringify(applicationState.members.types.reOrderedCustomFields));
    }
    
    const areMemberTypeCustomFieldOptionsReordered = !isObjectEmpty(applicationState.members.types.reOrderedCustomFieldOptions);

    if (areMemberTypeCustomFieldOptionsReordered) {
        dataToPush.memberTypes.reOrderedMemberCustomFieldOptions = JSON.parse(JSON.stringify(applicationState.members.types.reOrderedCustomFieldOptions));
    }

    dataToPush.memberTypeActions = populateChangedModel<MemberTypeActionState, IMemberTypeAction>(applicationState.members.types.actions);

    hasDataToPush = hasDataToPush || applicationState.members.types.areMemberTypesReordered || areMemberTypeActionsReordered 
                        || areMemberTypeCustomFieldsReordered || areMemberTypeCustomFieldOptionsReordered
                        || hasData<IMember>(dataToPush.members)
                        || hasData<IMemberType>(dataToPush.memberTypes.entries)
                        || hasData<IMemberTypeAction>(dataToPush.memberTypeActions)
                        || hasData<CustomField>(dataToPush.memberTypes.customFields)
                        || hasData<FieldChoice>(dataToPush.memberTypes.customFieldOptions);


    dataToPush.groups = populateChangedModel<GroupState, IGroup>(applicationState.groups);

    dataToPush.groupTypes.entries = populateChangedModel<GroupTypeState, IGroupType>(applicationState.groups.types);
    dataToPush.groupTypes.customFields = populateChangedCustomField<GroupTypeState>(applicationState.groups.types);
    dataToPush.groupTypes.customFieldOptions = populateChangedCustomFieldOption<GroupTypeState>(applicationState.groups.types);

    if (applicationState.groups.types.areGroupTypesReordered) {
        dataToPush.groupTypes.reOrderedGroupTypes = applicationState.groups.types.allEntries.slice();
    }

    const areGroupTypeActionsReordered = !isObjectEmpty(applicationState.groups.types.reOrderedActions);

    if (areGroupTypeActionsReordered) {
        dataToPush.groupTypes.reOrderedActions = JSON.parse(JSON.stringify(applicationState.groups.types.reOrderedActions));
    }

    const areGroupTypeCustomFieldsReordered = !isObjectEmpty(applicationState.groups.types.reOrderedCustomFields);

    if (areGroupTypeCustomFieldsReordered) {
        dataToPush.groupTypes.reOrderedGroupCustomFields = JSON.parse(JSON.stringify(applicationState.groups.types.reOrderedCustomFields));
    }
    
    const areGroupTypeCustomFieldOptionsReordered = !isObjectEmpty(applicationState.groups.types.reOrderedCustomFieldOptions);

    if (areGroupTypeCustomFieldOptionsReordered) {
        dataToPush.groupTypes.reOrderedGroupCustomFieldOptions = JSON.parse(JSON.stringify(applicationState.groups.types.reOrderedCustomFieldOptions));
    }

    dataToPush.groupTypeActions = populateChangedModel<GroupTypeActionState, IGroupTypeAction>(applicationState.groups.types.actions);

    hasDataToPush = hasDataToPush || applicationState.groups.types.areGroupTypesReordered || areGroupTypeActionsReordered 
                        || areGroupTypeCustomFieldsReordered || areGroupTypeCustomFieldOptionsReordered 
                        || hasData<IGroup>(dataToPush.groups)
                        || hasData<IGroupType>(dataToPush.groupTypes.entries)
                        || hasData<IGroupTypeAction>(dataToPush.groupTypeActions)
                        || hasData<CustomField>(dataToPush.groupTypes.customFields)
                        || hasData<FieldChoice>(dataToPush.groupTypes.customFieldOptions);


    dataToPush.workflows = populateChangedModel<WorkflowState, IWorkflow>(applicationState.workflows);

    dataToPush.workflowTypes.entries = populateChangedModel<WorkflowTypeState, IWorkflowType>(applicationState.workflows.types);
    dataToPush.workflowTypes.customFields = populateChangedWorkflowTypeCustomField<WorkflowTypeState>(applicationState.workflows.types);
    dataToPush.workflowTypes.customFieldOptions = populateChangedCustomFieldOption<WorkflowTypeState>(applicationState.workflows.types);

    if (applicationState.workflows.types.areWorkflowTypesReordered) {
        dataToPush.workflowTypes.reOrderedWorkflowTypes = applicationState.workflows.types.allEntries.slice();
    }

    const areWorkflowTypeCustomFieldsReordered = !isObjectEmpty(applicationState.workflows.types.reOrderedCustomFields);

    if (areWorkflowTypeCustomFieldsReordered) {
        dataToPush.workflowTypes.reOrderedWorkflowCustomFields = JSON.parse(JSON.stringify(applicationState.workflows.types.reOrderedCustomFields));
    }
    
    const areWorkflowTypeCustomFieldOptionsReordered = !isObjectEmpty(applicationState.workflows.types.reOrderedCustomFieldOptions);

    if (areWorkflowTypeCustomFieldOptionsReordered) {
        dataToPush.workflowTypes.reOrderedWorkflowCustomFieldOptions = JSON.parse(JSON.stringify(applicationState.workflows.types.reOrderedCustomFieldOptions));
    }

    dataToPush.workflowTypeStatuses = populateChangedModel<StatusState, IStatus>(applicationState.workflows.types.statuses);
    
    const areWorkflowStatusesReordered = !isObjectEmpty(applicationState.workflows.types.reOrderedStatuses);

    if (areWorkflowStatusesReordered) {
        dataToPush.workflowTypes.reOrderedWorkflowStatuses = JSON.parse(JSON.stringify(applicationState.workflows.types.reOrderedStatuses));
    }

    hasDataToPush = hasDataToPush || applicationState.workflows.types.areWorkflowTypesReordered || areWorkflowTypeCustomFieldsReordered || areWorkflowTypeCustomFieldOptionsReordered || areWorkflowStatusesReordered 
                        || hasData<IWorkflow>(dataToPush.workflows)
                        || hasData<IWorkflowType>(dataToPush.workflowTypes.entries)
                        || hasData<CustomField>(dataToPush.workflowTypes.customFields)
                        || hasData<FieldChoice>(dataToPush.workflowTypes.customFieldOptions)
                        || hasData<IStatus>(dataToPush.workflowTypeStatuses);


    dataToPush.reports = populateChangedModel<ReportState, IReport>(applicationState.reports);
    dataToPush.reportTypes = populateChangedModel<ReportTypeState, IReportType>(applicationState.reports.types);

    hasDataToPush = hasDataToPush || hasData<IReport>(dataToPush.reports)
                        || hasData<IReportType>(dataToPush.reportTypes);
    
    dataToPush.staticDataHolders = populateChangedModel<StaticDataHolderState, IStaticDataHolder>(applicationState.staticInfo);
    dataToPush.dataFragments = populateChangedModel<DataFragmentState, IDataFragment>(applicationState.staticInfo.fragments);

    const areTopLevelDataFragmentsReordered = !isObjectEmpty(applicationState.staticInfo.fragments.reOrderedTopLevelDataFragments);

    if (areTopLevelDataFragmentsReordered) {
        dataToPush.reOrderedTopLevelDataFragments = JSON.parse(JSON.stringify(applicationState.staticInfo.fragments.reOrderedTopLevelDataFragments));
    }

    const areChildDataFragmentsReordered = !isObjectEmpty(applicationState.staticInfo.fragments.reOrderedChildDataFragments);

    if (areChildDataFragmentsReordered) {
        dataToPush.reOrderedChildDataFragments = JSON.parse(JSON.stringify(applicationState.staticInfo.fragments.reOrderedChildDataFragments));
    }

    hasDataToPush = hasDataToPush || areTopLevelDataFragmentsReordered || areChildDataFragmentsReordered || hasData<IStaticDataHolder>(dataToPush.staticDataHolders) || hasData<IDataFragment>(dataToPush.dataFragments);

    dataToPush.widgets = populateChangedModel<WidgetState, IWidget>(applicationState.widgets);

    hasDataToPush = hasDataToPush || hasData<IWidget>(dataToPush.widgets);

    dataToPush.widgetConfigurations = {
        created: Array.from(applicationState.myData.widgets.createdConfigurations.values()).map(widgetId => applicationState.myData.widgets.byId[widgetId]),
        updated: Array.from(applicationState.myData.widgets.updatedIds.values()).map(widgetId => applicationState.myData.widgets.byId[widgetId]),
        deleted: Array.from(applicationState.myData.widgets.deletedConfigurations.values()).map(widgetId => applicationState.myData.widgets.byId[widgetId]),
    };

    hasDataToPush = hasDataToPush || hasData<IWidgetConfiguration>(dataToPush.widgetConfigurations);

    dataToPush.flowchart.pieces = populateChangedModel<PieceState, AllPieceTypes>(applicationState.flowchart.pieces);
    dataToPush.flowchart.pieces.created = dataToPush.flowchart.pieces.created.filter(createdPiece => !!createdPiece);
    dataToPush.flowchart.pieces.updated = dataToPush.flowchart.pieces.updated.filter(updatedPiece => !!updatedPiece);
    dataToPush.flowchart.pieces.deleted = [];
    dataToPush.flowchart.deletedPieces = Array.from(applicationState.flowchart.pieces.deletedIds).filter(deletedId => !applicationState.flowchart.pieces.createdIds.has(deletedId));
    hasDataToPush = hasDataToPush || hasData<AllPieceTypes>(dataToPush.flowchart.pieces) || dataToPush.flowchart.deletedPieces.length > 0;

    dataToPush.flowchart.variables = populateChangedModel<VariableState, IVariable>(applicationState.flowchart.variables);
    hasDataToPush = hasDataToPush || hasData<IVariable>(dataToPush.flowchart.variables);

    dataToPush.events = populateChangedModel<EventState, AllEventTypes>(applicationState.events);
    hasDataToPush = hasDataToPush || hasData<AllEventTypes>(dataToPush.events);

    dataToPush.errors = applicationState.errors.allEntries.map(errorId => applicationState.errors.byId[errorId]);
    hasDataToPush = hasDataToPush || dataToPush.errors.length > 0;

    dataToPush.languages = populateChangedModel<LanguageState, ILanguage>(applicationState.internationalization.languages);

    hasDataToPush = hasDataToPush || hasData<ILanguage>(dataToPush.languages);

    if (!isObjectEmpty(applicationState.internationalization.translations.updatedWords)) {
        dataToPush.translations = encodeTranslations(applicationState.internationalization.translations);
        hasDataToPush = true;
    }

    if (applicationState.organization.hasUpdated) {
        dataToPush.organization = applicationState.organization;
        hasDataToPush = true;
    }

    if (applicationState.organization.superUserPassword) {
        dataToPush.superUserPassword = applicationState.organization.superUserPassword;
        hasDataToPush = true;
    }

    for (const roleId of applicationState.permissions.updatedIds) {
        dataToPush.permissions.push({
            ...applicationState.permissions.rolePermissions[roleId],
            role: roleId,
        });
    }

    hasDataToPush = hasDataToPush || applicationState.permissions.updatedIds.size > 0;

    if (!hasDataToPush) {
        console.log('No data to push');
    }

    return {
        hasData: hasDataToPush,
        data: dataToPush,
    };
}

import { select } from '@angular-redux/store';
import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { StatisticalDetailsPage } from 'src/app/statistical-details/statistical-details.page';
import { translatePhrase } from 'src/shared/helpers/translation';
import { IWidget, WidgetState } from 'src/shared/store/widgets/types';

@Component({
  selector: 'app-aggregated-widget',
  templateUrl: './aggregated-widget.component.html',
  styleUrls: ['./aggregated-widget.component.scss'],
})
export class AggregatedWidgetComponent implements OnInit {
  @Input() widgetId: string;
  
  @select(['widgets']) widgetStateSource: Observable<WidgetState>;
  widgetStateSubscription: Subscription;
  widgetState: WidgetState;

  aggregationValue = 0;

  widget: IWidget;

  constructor(
    public modalController: ModalController,
  ) { }

	translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

  async statisticalDetails() {
      const showData = this.widgetState.cachedWidgetData[this.widgetId];

      const modal = await this.modalController.create({
          component: StatisticalDetailsPage,
          componentProps: {
            header: this.widget.name,
            type: this.widget.type,
            typeId: this.widget.typeId,
            customFields: this.widget.customFields,
            entityIds: showData.entityIds,
            widgetId: this.widgetId
          }
      });
      return await modal.present();
  }

  ngOnInit() {
    this.widgetStateSubscription = this.widgetStateSource.subscribe(widgetState => {
      this.widgetState = widgetState;
      this.widget = widgetState.byId[this.widgetId];
      
      const showData = widgetState.cachedWidgetData[this.widgetId];

      if (!showData) {
        return;
      }

      const dataToAggregate = showData.entries.map(tableRow => tableRow.entries[2]);
      let numericValues: Array<number>;

      switch(this.widget.aggregation) {
          case 'count':
              this.aggregationValue = dataToAggregate.length;
              break;
          case 'sum':
              numericValues = dataToAggregate.map(value => Number(value));
              this.aggregationValue = numericValues.reduce((sum, num) => {
                  if (!isNaN(num)) {
                      return sum + num;
                  } else {
                      return sum;
                  }
              });
              break;
          case 'average':
              numericValues = dataToAggregate.map(value => Number(value));
              this.aggregationValue = numericValues.reduce((sum, num) => {
                  if (!isNaN(num)) {
                      return sum + num;
                  } else {
                      return sum;
                  }
              });
              if (numericValues.length > 0) {
                  this.aggregationValue /= numericValues.length;
              } else {
                  this.aggregationValue = 0;
              }
              break;

          default:
              throw new Error('Incorrect aggregation');
        }
    });
  }

  ngOnDestroy() {
    if (this.widgetStateSubscription) {
      this.widgetStateSubscription.unsubscribe();
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { CountryCode } from '../models/country-code';
import { COUNTRY_CODES } from '../data/country-codes';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
    selector: 'app-country-selector',
    templateUrl: './country-selector.page.html',
    styleUrls: ['./country-selector.page.scss'],
})
export class CountrySelectorPage implements OnInit {
    country_code_options: Array<CountryCode>;
    temp_country_code_options: Array<CountryCode>;
    search_text: string;
    countryCode: string;

    constructor(
        public modalController: ModalController,
        private navParams: NavParams
    ) { }

    ngOnInit() {
        this.countryCode = this.navParams.get('countryCode');
        setTimeout(() => {
            this.country_code_options = COUNTRY_CODES.filter(country => country.name === "India" || country.name === "United States");
            this.temp_country_code_options = COUNTRY_CODES.filter(country => country.name === "India" || country.name === "United States");;
        }, 100);
    }

    filterCountries() {
        this.temp_country_code_options = this.country_code_options.filter((country) => {
            return country.name.toLowerCase().indexOf(this.search_text.toLowerCase()) > -1 ||
                country.dial_code.toLowerCase().indexOf(this.search_text.toLowerCase()) > -1;
        });
    }

    back() {
        this.modalController.dismiss(this.countryCode);
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { AddOrEditMemberPage } from './add-or-edit-member.page';
import { QuestionComponent } from './question/question.component';
import { DateSelectorModule } from '../shared/date-selector.module';

const routes: Routes = [
	{
		path: '',
		component: AddOrEditMemberPage
	}
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
        DateSelectorModule,
		RouterModule.forChild(routes)
	],
	declarations: [AddOrEditMemberPage, QuestionComponent]
})
export class AddOrEditMemberPageModule { }

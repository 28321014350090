import { SELECT_LOCATION, UN_SELECT_LOCATION, RE_ORDER_LOCATIONS, ADD_LOCATION_REQUEST, ADD_LOCATION, DELETE_LOCATION, UPDATE_LOCATION, ADD_USER_TO_LOCATION, REMOVE_USER_FROM_LOCATION, UPDATE_LOCATION_CUSTOM_FIELD_DATA, ADD_MEMBER_TO_LOCATION, REMOVE_MEMBER_FROM_LOCATION, ADD_GROUP_TO_LOCATION, REMOVE_GROUP_FROM_LOCATION, SelectLocationAction, UnSelectLocationAction, ReOrderLocationsAction, AddLocationRequestAction, AddLocationAction, UpdateLocationAction, UpdateLocationCustomFieldDataAction, DeleteLocationAction, AddUserToLocationAction, RemoveUserFromLocationAction, AddMemberToLocationAction, RemoveMemberFromLocationAction, AddGroupToLocationAction, RemoveGroupFromLocationAction, BulkAddMembersToLocationsAction, BULK_ADD_MEMBERS_TO_LOCATIONS, MemberLocationLink, BULK_REMOVE_MEMBERS_FROM_LOCATIONS, BulkRemoveMembersFromLocationsAction, GroupLocationLink, BulkAddGroupsToLocationsAction, BULK_ADD_GROUPS_TO_LOCATIONS, BulkRemoveGroupsFromLocationsAction, BULK_REMOVE_GROUPS_FROM_LOCATIONS } from './types';
import { INewLocationData, IUpdateableLocationData } from './types';
import { v4 as uuidv4 } from 'uuid';
import { CustomFieldDataHolder } from '../../custom-fields/types';
import moment from 'moment';

export function selectLocation(id: string, index: number): SelectLocationAction {
    return {
        type: SELECT_LOCATION,
        id,
        index,
    }
}

export function unSelectLocation(index: number): UnSelectLocationAction {
    return {
        type: UN_SELECT_LOCATION,
        index,
    }
}

export function reOrderLocations(sourceIndex: number, destinationIndex: number, parentId: string): ReOrderLocationsAction {
    return {
        type: RE_ORDER_LOCATIONS,
        sourceIndex,
        destinationIndex,
        parentId,
    }
}

export function addLocationRequest(payload: INewLocationData, parentId: string): AddLocationRequestAction {
    return {
        type: ADD_LOCATION_REQUEST,
        payload,
        parentId,
    }
}

export function addLocation(payload: INewLocationData, parentId: string): AddLocationAction {
    const now = moment.utc().format();

    return {
        type: ADD_LOCATION,
        payload: {
            id: uuidv4(),
            ...payload,
            parent: parentId,
            children: [],
            users: [],
            members: [],
            groups: [],
            createdTime: now,
            lastUpdatedTime: now,
            lastUpdatedCustomFieldTime: now,
            lastUpdateTimeForFields: {},
        },
        parentId,
        currentTime: now,
    }
}

export function updateLocation(payload: IUpdateableLocationData): UpdateLocationAction {
    return {
        type: UPDATE_LOCATION,
        payload,
        currentTime: moment.utc().format(),
    }
}

export function updateLocationCustomFieldData(locationId: string, customFieldData: CustomFieldDataHolder): UpdateLocationCustomFieldDataAction {
    return {
        type: UPDATE_LOCATION_CUSTOM_FIELD_DATA,
        locationId,
        customFieldData,
        currentTime: moment.utc().format(),
    }
}

export function deleteLocation(id: string, parentId: string): DeleteLocationAction {
    return {
        type: DELETE_LOCATION,
        id,
        parentId,
        currentTime: moment.utc().format(),
    }
}

export function addUserToLocation(userId: string, locationId: string): AddUserToLocationAction {
    return {
        type: ADD_USER_TO_LOCATION,
        userId,
        locationId,
    };
}

export function removeUserFromLocation(userId: string, locationId: string): RemoveUserFromLocationAction {
    return {
        type: REMOVE_USER_FROM_LOCATION,
        userId,
        locationId,
    };
}

export function addMemberToLocation(memberId: string, locationId: string): AddMemberToLocationAction {
    return {
        type: ADD_MEMBER_TO_LOCATION,
        memberId,
        locationId,
    };
}

export function removeMemberFromLocation(memberId: string, locationId: string): RemoveMemberFromLocationAction {
    return {
        type: REMOVE_MEMBER_FROM_LOCATION,
        memberId,
        locationId,
    };
}

export function addGroupToLocation(groupId: string, locationId: string): AddGroupToLocationAction {
    return {
        type: ADD_GROUP_TO_LOCATION,
        groupId,
        locationId,
    };
}

export function removeGroupFromLocation(groupId: string, locationId: string): RemoveGroupFromLocationAction {
    return {
        type: REMOVE_GROUP_FROM_LOCATION,
        groupId,
        locationId,
    };
}

export function bulkAddMembersToLocations(links: Array<MemberLocationLink>): BulkAddMembersToLocationsAction {
    return {
        type: BULK_ADD_MEMBERS_TO_LOCATIONS,
        links,
    };
}

export function bulkRemoveMembersFromLocations(links: Array<MemberLocationLink>): BulkRemoveMembersFromLocationsAction {
    return {
        type: BULK_REMOVE_MEMBERS_FROM_LOCATIONS,
        links,
    };
}

export function bulkAddGroupsToLocations(links: Array<GroupLocationLink>): BulkAddGroupsToLocationsAction {
    return {
        type: BULK_ADD_GROUPS_TO_LOCATIONS,
        links,
    };
}

export function bulkRemoveGroupsFromLocations(links: Array<GroupLocationLink>): BulkRemoveGroupsFromLocationsAction {
    return {
        type: BULK_REMOVE_GROUPS_FROM_LOCATIONS,
        links,
    };
}
import moment from 'moment';

export function reOrderList<T>(list: Iterable<T>, startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export function mergeReverseLinkArrays(incomingLinks: Array<string>, existingLinks: Array<string>) {
    const mergedArray: Array<string> = [];

    while (incomingLinks.length > 0 && existingLinks.length > 0) {
        const firstIncomingElement = incomingLinks[0];
        const firstExistingElement = existingLinks[0];

        if (!incomingLinks.includes(firstExistingElement)) {
            mergedArray.push(firstExistingElement);
            existingLinks.shift();
            continue;
        }

        mergedArray.push(firstIncomingElement);

        incomingLinks.shift();
        existingLinks = existingLinks.filter(existingElement => existingElement !== firstIncomingElement);
    }

    for (const remainingLink of incomingLinks.concat(existingLinks)) {
        mergedArray.push(remainingLink);
    }

    return mergedArray;
}

export function getReadableDate(dateString: string) {
    return moment(dateString).format('DD MMM YYYY');
}

export function getCurrentPosition(options?: PositionOptions) {
    return new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
}

export function dataURLtoFile(dataUrl: string, filename: string) {

    let arr = dataUrl.split(','),
        mimes = arr[0].match(/:(.*?);/),
        mime = mimes ? mimes[1] : '',
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export function isUUID(string: string) {
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(string);
}

export function isObjectEmpty(object: any) {
    if (object === null || typeof object === 'undefined') {
        return true;
    }

    return Object.entries(object).length === 0 && object.constructor === Object
}

export function flattenArray<T>(twoDimensionalArray: Array<Array<T>>) {
    const flattenedArray: Array<T> = [];
    for (const internalArray of twoDimensionalArray) {
        flattenedArray.push.apply(flattenedArray, internalArray);
    }

    return flattenedArray;
}

export function copyStringToClipboard(value: string) {
    var el = document.createElement('textarea');
    el.value = value;

    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

export function isMobilePlatform() {
    return typeof window !== 'undefined' && window.location.hostname.startsWith('m.') || (window.location.hostname === 'localhost' && window.location.port === '8100');
}

export function isDesktopPlatform() {
    return typeof window !== 'undefined' && !isMobilePlatform();
}
import { takeEvery, put, select } from 'redux-saga/effects'
import { AddDataFragmentAction, DeleteDataFragmentAction, ADD_DATA_FRAGMENT, DELETE_DATA_FRAGMENT } from './types';
import { ApplicationState } from '../../types';
import { addDataFragmentToStaticDataHolder, removeDataFragmentFromStaticDataHolder } from '../actions';

function* addReverseLinkForParentStaticDataHolder(action: AddDataFragmentAction) {
    const state: ApplicationState = yield select();
    if (action.parentId in state.staticInfo.byId) {
        yield put(addDataFragmentToStaticDataHolder(action.payload.id, action.parentId));
    }
}

function* removeReverseLinkForParentStaticDataHolder(action: DeleteDataFragmentAction) {
    const state: ApplicationState = yield select();
    if (action.parentId in state.staticInfo.byId) {
        yield put(removeDataFragmentFromStaticDataHolder(action.id, action.parentId));
    }
}

export function* watchDataFragmentCreationChanges() {
    yield takeEvery(ADD_DATA_FRAGMENT, addReverseLinkForParentStaticDataHolder);
}

export function* watchDataFragmentDeletionChanges() {
    yield takeEvery(DELETE_DATA_FRAGMENT, removeReverseLinkForParentStaticDataHolder);
}
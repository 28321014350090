import { NormalizedModel, Synchronizable } from '../../../normalized-model';

export const SELECT_MEMBER_TYPE_ACTION = 'SELECT_MEMBER_TYPE_ACTION';
export const UN_SELECT_MEMBER_TYPE_ACTION = 'UN_SELECT_MEMBER_TYPE_ACTION';
export const RE_ORDER_MEMBER_TYPE_ACTIONS = 'RE_ORDER_MEMBER_TYPE_ACTIONS';
export const ADD_MEMBER_TYPE_ACTION = 'ADD_MEMBER_TYPE_ACTION';
export const INSTANTIATE_MEMBER_TYPE_ACTION = 'INSTANTIATE_MEMBER_TYPE_ACTION';
export const DELETE_MEMBER_TYPE_ACTION = 'DELETE_MEMBER_TYPE_ACTION';
export const UPDATE_MEMBER_TYPE_ACTION = 'UPDATE_MEMBER_TYPE_ACTION';

export const UPDATE_MEMBER_TYPE_ACTIONS_DATA = 'UPDATE_MEMBER_TYPE_ACTIONS_DATA';
export const SYNCHRONIZE_MEMBER_TYPE_ACTIONS_DATA = 'SYNCHRONIZE_MEMBER_TYPE_ACTIONS_DATA';
export const CLEAR_MEMBER_TYPE_ACTIONS_DELTA = 'CLEAR_MEMBER_TYPES_DELTA';

export interface INewMemberTypeActionData {
    name: string;
    icon: string,
    workflowType?: string,
}

export interface IUpdateableMemberTypeActionData extends INewMemberTypeActionData {
    id: string,
}

export interface IMemberTypeAction extends IUpdateableMemberTypeActionData, Synchronizable {
    archived?: boolean,
    parentId: string,
}

export interface MemberTypeActionState extends NormalizedModel<IMemberTypeAction> {
    selected: string|undefined,
}


export interface SelectActionAction {
    type: typeof SELECT_MEMBER_TYPE_ACTION,
    id: string,
}

export interface UnSelectActionAction {
    type: typeof UN_SELECT_MEMBER_TYPE_ACTION,
}

export interface ReOrderActionAction {
    type: typeof RE_ORDER_MEMBER_TYPE_ACTIONS,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddMemberTypeActionAction {
    type: typeof ADD_MEMBER_TYPE_ACTION,
    payload: IMemberTypeAction,
    parentId: string,
    currentTime: string,
}

export interface InstantiateMemberTypeActionAction {
    type: typeof INSTANTIATE_MEMBER_TYPE_ACTION,
    payload: IMemberTypeAction,
    parentId: string,
    currentTime: string,
}

export interface DeleteMemberTypeActionAction {
    type: typeof DELETE_MEMBER_TYPE_ACTION,
    id: string,
    parentId: string,
    currentTime: string,
}

export interface UpdateMemberTypeActionAction {
    type: typeof UPDATE_MEMBER_TYPE_ACTION,
    payload: IUpdateableMemberTypeActionData,
    currentTime: string,
}

export interface UpdateMemberTypeActionsData {
    type: typeof UPDATE_MEMBER_TYPE_ACTIONS_DATA,
    data: Array<IMemberTypeAction>,
}

export interface SynchronizeMemberTypeActionsData {
    type: typeof SYNCHRONIZE_MEMBER_TYPE_ACTIONS_DATA,
    data: Array<IMemberTypeAction>,
}

export interface ClearMemberTypeActionsDelta {
    type: typeof CLEAR_MEMBER_TYPE_ACTIONS_DELTA,
}

export type MemberTypeActionActions = SelectActionAction | UnSelectActionAction | ReOrderActionAction | AddMemberTypeActionAction | InstantiateMemberTypeActionAction | DeleteMemberTypeActionAction | UpdateMemberTypeActionAction | UpdateMemberTypeActionsData | SynchronizeMemberTypeActionsData | ClearMemberTypeActionsDelta;
import { Permissions, UPDATE_GENERAL_PERMISSION, UPDATE_MEMBER_PERMISSION, UPDATE_GROUP_PERMISSION, UPDATE_WORKFLOW_PERMISSION, UPDATE_REPORT_PERMISSION, UpdateGeneralPermission, UpdateMemberPermission, UpdateGroupPermission, UpdateWorkflowPermission, UpdateReportPermission, PermissionSet, SetMyPermissions, SET_MY_PERMISSIONS, UpdateLanguagePermission, UPDATE_LANGUAGE_PERMISSION } from './types';

export function setMyPermissions(permissions: PermissionSet): SetMyPermissions {
    return {
        type: SET_MY_PERMISSIONS,
        permissions,
    }
}

export function updateGeneralPermission(id: string, role: string, permission: Permissions): UpdateGeneralPermission {
    return {
        type: UPDATE_GENERAL_PERMISSION,
        id,
        role,
        permission,
    }
}

export function updateMemberPermission(memberTypeId: string, role: string, permission: Permissions): UpdateMemberPermission {
    return {
        type: UPDATE_MEMBER_PERMISSION,
        memberTypeId,
        role,
        permission,
    }
}

export function updateGroupPermission(groupTypeId: string, role: string, permission: Permissions): UpdateGroupPermission {
    return {
        type: UPDATE_GROUP_PERMISSION,
        groupTypeId,
        role,
        permission,
    }
}

export function updateWorkflowPermission(workflowTypeId: string, role: string, permission: Permissions): UpdateWorkflowPermission {
    return {
        type: UPDATE_WORKFLOW_PERMISSION,
        workflowTypeId,
        role,
        permission,
    }
}

export function updateReportPermission(reportTypeId: string, role: string, permission: Permissions): UpdateReportPermission {
    return {
        type: UPDATE_REPORT_PERMISSION,
        reportTypeId,
        role,
        permission,
    }
}

export function updateLanguagePermission(languageId: string, role: string, permission: Permissions): UpdateLanguagePermission {
    return {
        type: UPDATE_LANGUAGE_PERMISSION,
        languageId,
        role,
        permission,
    }
}
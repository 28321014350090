import { ADD_VARIABLE, DELETE_VARIABLE, UPDATE_VARIABLE, VariableState, IVariable, UPDATE_VARIABLES_DATA, SYNCHRONIZE_VARIABLES_DATA, CLEAR_VARIABLES_DELTA, ADD_VARIABLES } from './types';
import { FlowchartActionTypes, UPDATE_FLOWCHART_DATA } from '../types';
import { updateEntries, synchronizeEntries, clearDelta } from '../../normalized-model';

export const initialState: VariableState = {  
    byId: {},
    allEntries: [],
    filteredEntries: [],
    
    createdIds: new Set<string>(),
    updatedIds: new Set<string>(),
    deletedIds: new Set<string>(),
};

export function variablesReducer(state = initialState, action: FlowchartActionTypes): VariableState {
    switch(action.type) {

        case ADD_VARIABLE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...action.payload,
                        createdTime: action.createdTime,
                        lastUpdatedTime: action.createdTime,
                    },
                },
                allEntries: state.allEntries.concat([action.payload.id]),
                createdIds: new Set(Array.from(state.createdIds).concat([action.payload.id])),
            }

        case ADD_VARIABLES:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                },
                allEntries: state.allEntries.slice(),
                createdIds: new Set(Array.from(state.createdIds)),
            }

            for (const variableData of action.payload) {
                state.byId[variableData.id] = {
                    ...variableData,
                    createdTime: action.createdTime,
                    lastUpdatedTime: action.createdTime,
                };

                state.allEntries.push(variableData.id);
                state.createdIds.add(variableData.id);
            }

            return state;
        
        case DELETE_VARIABLE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        archived: true,
                    },
                },
                allEntries: state.allEntries.filter(entry => entry !== action.id),
                deletedIds: new Set(Array.from(state.updatedIds).concat([action.id])),
            }
        
        case UPDATE_VARIABLE:
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.payload.id]: {
                            ...state.byId[action.payload.id],
                            ...action.payload,
                        },
                    },
                    updatedIds: !state.createdIds.has(action.payload.id) ? new Set(Array.from(state.updatedIds).concat([action.payload.id])) : state.updatedIds,
                }

            case UPDATE_VARIABLES_DATA:
                return updateEntries<VariableState, IVariable>(state, action.data);
    
            case SYNCHRONIZE_VARIABLES_DATA:
                return synchronizeEntries<VariableState, IVariable>(state, action.data);
    
            case CLEAR_VARIABLES_DELTA:
                return clearDelta<VariableState, IVariable>(state);

        // Flowchart actions

        case UPDATE_FLOWCHART_DATA:
            return {
                ...action.data.variables,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
            }
        

        default:
            return state
    }
}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService } from '../services/toast.service';

interface Toast {
	text: string;
	time: number | undefined;
	toastType: string | undefined;
}

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
})

export class ToastComponent implements OnInit, OnDestroy {
	public showToast = false;
	public toastMessage: string;
	public toastSubscription: Subscription;
	public toastType: string = "success";
	public icon: string = "assets/new-custom-icons/checkmark.svg";
	public loading: boolean = false;
	public isShowingSubscription: Subscription;
	public isCloseButton: boolean = true;

	constructor(private toast: ToastService) {

	}

	ngOnInit() {

		this.toastSubscription = this.toast.toastDetails.subscribe((toast: Toast) => {

			if (toast === null) {
				this.showToast = false;
			} else {
				this.toastMessage = toast.text;
				this.showToast = true;
			}

			if (toast && toast.toastType) {
				this.toastType = toast.toastType;
			} else {
				this.toastType = "success";
			}

			let time = (toast && toast.time) ? toast.time : 4000;
			this.isCloseButton = true;
			this.loading = false;

			switch (this.toastType) {
				case "error": this.icon = "assets/new-revision/stop-error.svg";
					break;
				case "warning": this.icon = "assets/new-custom-icons/warning.svg";
					break;
				case "info": this.icon = "assets/new-revision/message.svg";
					break;
				case "loading": this.loading = true;
					time = 5000000;
					this.isCloseButton = false;
					break;
				// default success
				default: this.icon = "assets/new-custom-icons/checkmark.svg";
					this.loading = false;
			}
			if (isFinite(time)) {
				setTimeout(() => {
					this.toast.setIsShowingToast(false);
				}, time);
			}


		});

		this.isShowingSubscription = this.toast.isShowingSubscription.subscribe((isShowing => {
			this.showToast = isShowing;
		}))
	}

	ngOnDestroy(): void {
		if (this.toastSubscription) {
			this.toastSubscription.unsubscribe();
		}
		if (this.isShowingSubscription) {
			this.isShowingSubscription.unsubscribe();
		}
	}

	closeToast() {
		this.toast.setIsShowingToast(false);
	}

}

import { IWidgetConfigurationState, UPDATE_WIDGET_SIZE, UPDATE_WIDGET_POSITION, REGISTER_WIDGET_CREATION, UNREGISTER_WIDGET_CREATION, UPDATE_MY_WIDGETS, UPDATE_WIDGET_CONFIGURATIONS_DATA, SYNCHRONIZE_WIDGET_CONFIGURATIONS_DATA, CLEAR_WIDGET_CONFIGURATIONS_DELTA } from './types';
import { MyDataActions } from '../types';
import { GlobalActions } from '../../types';

export const initialState: IWidgetConfigurationState = {
    byId: {},
    updatedIds: new Set(),
    myWidgets: [],
    createdConfigurations: new Set(),
    deletedConfigurations: new Set(),
};

export function widgetsReducer(state = initialState, action: MyDataActions | GlobalActions): IWidgetConfigurationState {
    let newState: IWidgetConfigurationState;

    switch (action.type) {

        case UPDATE_MY_WIDGETS:
            return {
                ...state,
                myWidgets: action.widgetIds,
            }

        case UPDATE_WIDGET_SIZE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        width: action.width,
                        height: action.height,
                    },
                },
                updatedIds: new Set([...state.updatedIds, action.widgetId]),
            };

        case UPDATE_WIDGET_POSITION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        x: action.x,
                        y: action.y,
                    },
                },
                updatedIds: new Set([...state.updatedIds, action.widgetId]),
            };

        case REGISTER_WIDGET_CREATION:

            const createdReverseLinks = new Set([...state.createdConfigurations, action.widgetId]);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        widgetId: action.widgetId,
                    },
                },
                myWidgets: state.myWidgets.concat([action.widgetId]),
                createdConfigurations: createdReverseLinks,
            };

        case UNREGISTER_WIDGET_CREATION:

            const deletedReverseLinks = new Set([...state.deletedConfigurations, action.widgetId]);

            return {
                ...state,
                myWidgets: state.myWidgets.filter(widgetId => widgetId !== action.widgetId),
                deletedConfigurations: deletedReverseLinks,
            };

        case UPDATE_WIDGET_CONFIGURATIONS_DATA:
            newState = {
                ...state,
                byId: {
                    ...state.byId
                }
            };

            for (const widgetConfig of action.data) {
                newState.byId[widgetConfig.widgetId] = widgetConfig;
            }

            return newState;

        case SYNCHRONIZE_WIDGET_CONFIGURATIONS_DATA:
            newState = {
                ...state,
                byId: {
                    ...state.byId
                }
            };

            for (const widgetConfig of action.data) {
                newState.byId[widgetConfig.widgetId] = widgetConfig;
            }

            return newState;

        case CLEAR_WIDGET_CONFIGURATIONS_DELTA:
            return {
                ...state,
                createdConfigurations: new Set(),
                updatedIds: new Set(),
                deletedConfigurations: new Set(),
            }
        default:
            return state;
    }
}
export const UPDATE_GENERAL_PERMISSION = 'UPDATE_GENERAL_PERMISSION';

export const UPDATE_MEMBER_PERMISSION = 'UPDATE_MEMBER_PERMISSION';
export const UPDATE_GROUP_PERMISSION = 'UPDATE_GROUP_PERMISSION';
export const UPDATE_WORKFLOW_PERMISSION = 'UPDATE_WORKFLOW_PERMISSION';
export const UPDATE_REPORT_PERMISSION = 'UPDATE_REPORT_PERMISSION';
export const UPDATE_LANGUAGE_PERMISSION = 'UPDATE_LANGUAGE_PERMISSION';

export const SET_MY_PERMISSIONS = 'SET_MY_PERMISSIONS';

export const UPDATE_PERMISSIONS_DATA = 'UPDATE_PERMISSIONS_DATA';
export const SYNCHRONIZE_PERMISSIONS_DATA = 'SYNCHRONIZE_PERMISSIONS_DATA';
export const CLEAR_PERMISSIONS_DELTA = 'CLEAR_PERMISSIONS_DELTA';

export const UPDATE_PERMISSION_DATA = 'UPDATE_PERMISSION_DATA';

export enum Permissions {
    NONE = 'NONE',
    READ = 'READ',
    WRITE = 'WRITE',
}

export const DEFAULT_GENERAL_PERMISSIONS = {
    Hierarchy: Permissions.READ,
    Locations: Permissions.READ,
    Users: Permissions.READ,
    UserConfiguration: Permissions.NONE,
    MembersConfiguration: Permissions.NONE,
    GroupsConfiguration: Permissions.NONE,
    WorkflowsConfiguration: Permissions.NONE,
    OrganizationProfile: Permissions.READ,
    LanguagesConfiguration: Permissions.NONE,
    ReportsConfiguration: Permissions.NONE,
}

export const DEFAULT_PERMISSIONS: PermissionSet = {
    general: DEFAULT_GENERAL_PERMISSIONS,
    members: {},
    groups: {},
    workflows: {},
    reports: {},
    languages: {},
}

export type PermissionSet = {
    general: {
        Hierarchy: Permissions,
        Locations: Permissions,
        Users: Permissions,
        UserConfiguration: Permissions,
        MembersConfiguration: Permissions,
        GroupsConfiguration: Permissions,
        WorkflowsConfiguration: Permissions,
        OrganizationProfile: Permissions,
        LanguagesConfiguration: Permissions,
        ReportsConfiguration: Permissions,
        [id: string]: Permissions,
    },
    members: {
        [memberTypeId: string]: Permissions,
    },
    groups: {
        [groupTypeId: string]: Permissions,
    },
    workflows: {
        [workflowTypeId: string]: Permissions,
    },
    reports: {
        [reportTypeId: string]: Permissions,
    },
    languages: {
        [languageId: string]: Permissions,
    },
}

export type PermissionSetWithRole = PermissionSet & {role: string};

export interface PermissionState {
    myPermissions: PermissionSet,
    rolePermissions: {
        [id: string]: PermissionSet,
    },
    updatedIds: Set<string>,
}

export interface SetMyPermissions {
    type: typeof SET_MY_PERMISSIONS,
    permissions: PermissionSet,
}

export interface UpdateGeneralPermission {
    type: typeof UPDATE_GENERAL_PERMISSION,
    id: string,
    role: string,
    permission: Permissions,
}


export interface UpdateMemberPermission {
    type: typeof UPDATE_MEMBER_PERMISSION,
    memberTypeId: string,
    role: string,
    permission: Permissions,
}

export interface UpdateGroupPermission {
    type: typeof UPDATE_GROUP_PERMISSION,
    groupTypeId: string,
    role: string,
    permission: Permissions,
}

export interface UpdateWorkflowPermission {
    type: typeof UPDATE_WORKFLOW_PERMISSION,
    workflowTypeId: string,
    role: string,
    permission: Permissions,
}

export interface UpdateReportPermission {
    type: typeof UPDATE_REPORT_PERMISSION,
    reportTypeId: string,
    role: string,
    permission: Permissions,
}

export interface UpdateLanguagePermission {
    type: typeof UPDATE_LANGUAGE_PERMISSION,
    languageId: string,
    role: string,
    permission: Permissions,
}

export interface UpdatePermissionsData {
    type: typeof UPDATE_PERMISSIONS_DATA,
    data: Array<PermissionSetWithRole>,
}

export interface SynchronizePermissionsData {
    type: typeof SYNCHRONIZE_PERMISSIONS_DATA,
    data: Array<PermissionSetWithRole>,
}

export interface ClearPermissionsDelta {
    type: typeof CLEAR_PERMISSIONS_DELTA,
}

export interface IUpdatePermissionData {
    type: typeof UPDATE_PERMISSION_DATA,
    data: PermissionState,
}

export type RolePermissionActionTypes = UpdateGeneralPermission | UpdateMemberPermission | UpdateGroupPermission | UpdateWorkflowPermission | UpdateReportPermission | UpdateLanguagePermission;
export type PermissionActionTypes = SetMyPermissions | UpdateGeneralPermission | UpdateMemberPermission | UpdateGroupPermission | UpdateWorkflowPermission | UpdateReportPermission | UpdateLanguagePermission | UpdatePermissionsData | SynchronizePermissionsData | ClearPermissionsDelta | IUpdatePermissionData;
import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import * as Chartist from 'chartist';
import { translatePhrase } from 'src/shared/helpers/translation';

@Component({
	selector: 'app-donut-chart',
	templateUrl: './donut-chart.component.html',
	styleUrls: ['./donut-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonutChartComponent implements OnInit {
	@Input() chartLabels: Array<string>;
	@Input() chartData: Array<number>;
	calculated_series: Array<{
        value: number,
        className: string
	}> = [];
    total: number = 0;
    colors = ['#42ace5', '#b4f793', '#9647e1', '#f5d47d', '#ffc3ff', '#626262', '#00533a', '#a82ed8', '#e26a45', '#8b002e'];
	
	@ViewChild('donutChartElement') chartElement: ElementRef;

	constructor() { }

    ngOnInit() {
        let i: number;
        for (i = 0; i < this.chartData.length; i += 1) {
            this.calculated_series.push({
                value: this.chartData[i],
                className: 'series-' + i,
            });
        }

        this.total = this.chartData.reduce((a, b) => a + b, 0);
    }
    
	ngAfterViewInit() {	
        
        if (this.calculated_series.length > 0 && this.chartLabels.length > 0) {
            new Chartist.Pie(this.chartElement.nativeElement, {
                labels: this.chartLabels,
                series: this.calculated_series.slice(),
            }, {
                donut: true,
                donutWidth: 25,
                showLabel: false,
                startAngle: 0,
                total: this.calculated_series.reduce((a, b) => a + b.value, 0),
            });
        }	
	}

	translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

	getDonutChartTotal() {
		return this.chartData.reduce((a, b) => a + b, 0);
	}

}

import { openDB, IDBPDatabase } from 'idb';
import { DataToPush } from '../helpers/synchronize/types';
import * as uuid from 'uuid';
import moment from 'moment';
import { ApplicationState } from './types';
import { IRole } from './structure/role/types';
import { migrateDatabase } from './database-migrations';

interface PushedDataLog {
    id: string;
    timestamp: string;
    data: DataToPush;
}

let diceflowDb: IDBPDatabase<PushedDataLog>;

export async function getDICEflowDB() {
    if (!diceflowDb) {
        diceflowDb = await openDB('diceflow', 7, {
            upgrade(db, oldVersion, newVersion) {
                if (!db.objectStoreNames.contains('pushedDataLog')) {
                    db.createObjectStore('pushedDataLog');
                }

                if (!db.objectStoreNames.contains('currentSnapshot')) {
                    db.createObjectStore('currentSnapshot');
                } else if (oldVersion !== newVersion) {
                    db.deleteObjectStore('currentSnapshot');
                    db.createObjectStore('currentSnapshot');
                }
            }
        });
    }

    return diceflowDb;
}

export async function logDataPush(data: DataToPush) {
    const dataLog: PushedDataLog = {
        id: uuid.v4(),
        timestamp: moment().format(),
        data,
    };

    const database = await getDICEflowDB();
    database.add('pushedDataLog', dataLog, dataLog.id);
}

export async function getCurrentSnapshot(myId: string) {
    const database = await getDICEflowDB();
    const snapshot = await database.get('currentSnapshot', myId);

    return snapshot;
}

export async function storeCurrentSnapshot(state: ApplicationState) {
    let myId = state.myData.id;

    if (typeof localStorage === 'undefined') {
        return;
    }

    if (myId === 'SuperUser') {
        const orgAdminData = localStorage.getItem('orgAdminIds')
        if (orgAdminData) {
            const orgAdminIds = JSON.parse(orgAdminData);
            myId = orgAdminIds[state.organization.code];
        }
    }

    if (!myId) {
        return;
    }

    const stateToStore: ApplicationState = {
        ...state,
        myData: {
            ...state.myData,
            isTopBarExpanded: false,
        },
    }

    const database = await getDICEflowDB();
    await database.put('currentSnapshot', stateToStore, myId);
}

export async function clearCurrentSnapshot(myId: string) {
    const database = await getDICEflowDB();
    await database.delete('currentSnapshot', myId);
}
import { put, call, takeLatest, select, delay, all } from 'redux-saga/effects'
import axios, { AxiosResponse } from 'axios';
import { ApplicationState } from './types';

import { UPDATE_PROJECTS_DATA, SYNCHRONIZE_PROJECTS_DATA, CLEAR_PROJECTS_DELTA } from './structure/project/types';
import { UPDATE_LEVELS_DATA, SYNCHRONIZE_LEVELS_DATA, CLEAR_LEVELS_DELTA, SYNCHRONIZE_LEVEL_CUSTOM_FIELDS_DATA, SYNCHRONIZE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA, UPDATE_LEVEL_CUSTOM_FIELDS_DATA, UPDATE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA } from './structure/level/types';
import { CLEAR_USERS_DELTA, UPDATE_USERS_DATA, UPDATE_USER_CUSTOM_FIELDS_DATA, UPDATE_USER_CUSTOM_FIELD_OPTIONS_DATA, SYNCHRONIZE_USERS_DATA, SYNCHRONIZE_USER_CUSTOM_FIELDS_DATA, SYNCHRONIZE_USER_CUSTOM_FIELD_OPTIONS_DATA, IUser } from './users/types';
import { UPDATE_MY_ID, UPDATE_LAST_SYNC_TIME, COMPLETE_OLDER_DATA_FETCH, START_OLDER_DATA_FETCH, COMPLETE_DATA_FETCH, COMPLETE_DATA_PUSH, COMPLETE_PARTIAL_DATA_FETCH, FETCH_DATA_REQUEST, RESUME_PARTIAL_DATA_FETCH } from './my-data/types';
import { UPDATE_MY_WIDGETS, UPDATE_WIDGET_CONFIGURATIONS_DATA, CLEAR_WIDGET_CONFIGURATIONS_DELTA, SYNCHRONIZE_WIDGET_CONFIGURATIONS_DATA } from './my-data/widgets/types';
import { UPDATE_MEMBERS_DATA, CLEAR_MEMBERS_DELTA, SYNCHRONIZE_MEMBERS_DATA, APPEND_MEMBERS_DATA } from './members/types';
import { SYNCHRONIZE_WORKFLOWS_DATA, CLEAR_WORKFLOWS_DELTA, UPDATE_WORKFLOWS_DATA, APPEND_WORKFLOWS_DATA } from './workflows/types';
import { CLEAR_WIDGETS_DELTA, UPDATE_WIDGETS_DATA, IWidget, WidgetData, SYNCHRONIZE_WIDGETS_DATA } from './widgets/types';
import { CLEAR_PERMISSIONS_DELTA, UPDATE_PERMISSIONS_DATA, SYNCHRONIZE_PERMISSIONS_DATA } from './permissions/types';
import { AnyAction } from 'redux';
import { completeDataPush, initiateFetchAppData, markNetworkInterrupt, setIsFetchingForDataUpdate, setIsOnline } from './my-data/actions';
import { START_DATA_PUSH } from './my-data/types';
import { CLEAR_REPORTS_DELTA, UPDATE_REPORTS_DATA, SYNCHRONIZE_REPORTS_DATA } from './reports/types';
import { collectDataToPush, decodeTranslations } from '../helpers/synchronize';
import { DataReceivedAfterSync, DataToPush, RemainingDataReceivedAfterSync, ThresholdData } from '../helpers/synchronize/types';
import { UPDATE_GROUPS_DATA, SYNCHRONIZE_GROUPS_DATA, CLEAR_GROUPS_DELTA, APPEND_GROUPS_DATA } from './groups/types';
import { SYNCHRONIZE_ROLES_DATA, SYNCHRONIZE_ROLE_CUSTOM_FIELDS_DATA, SYNCHRONIZE_ROLE_CUSTOM_FIELD_OPTIONS_DATA, CLEAR_ROLES_DELTA, UPDATE_ROLES_DATA, UPDATE_ROLE_CUSTOM_FIELDS_DATA, UPDATE_ROLE_CUSTOM_FIELD_OPTIONS_DATA, IRole } from './structure/role/types';
import { UPDATE_LOCATIONS_DATA, CLEAR_LOCATIONS_DELTA, SYNCHRONIZE_LOCATIONS_DATA } from './structure/location/types';
import { SYNCHRONIZE_MEMBER_TYPES_DATA, SYNCHRONIZE_MEMBER_TYPE_CUSTOM_FIELDS_DATA, SYNCHRONIZE_MEMBER_TYPE_CUSTOM_FIELD_OPTIONS_DATA, UPDATE_MEMBER_TYPES_DATA, UPDATE_MEMBER_TYPE_CUSTOM_FIELDS_DATA, UPDATE_MEMBER_TYPE_CUSTOM_FIELD_OPTIONS_DATA, CLEAR_MEMBER_TYPES_DELTA } from './members/types/types';
import { UPDATE_GROUP_TYPES_DATA, UPDATE_GROUP_TYPE_CUSTOM_FIELDS_DATA, UPDATE_GROUP_TYPE_CUSTOM_FIELD_OPTIONS_DATA, SYNCHRONIZE_GROUP_TYPES_DATA, SYNCHRONIZE_GROUP_TYPE_CUSTOM_FIELDS_DATA, SYNCHRONIZE_GROUP_TYPE_CUSTOM_FIELD_OPTIONS_DATA, CLEAR_GROUP_TYPES_DELTA } from './groups/types/types';
import { SYNCHRONIZE_WORKFLOW_TYPES_DATA, SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA, SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA, CLEAR_WORKFLOW_TYPES_DELTA, UPDATE_WORKFLOW_TYPES_DATA, UPDATE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA, UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA } from './workflows/types/types';
import { SYNCHRONIZE_WORKFLOW_STATUSES_DATA, CLEAR_WORKFLOW_STATUSES_DELTA, UPDATE_WORKFLOW_STATUSES_DATA } from './workflows/types/statuses/types';
import { UPDATE_PIECES_DATA, SYNCHRONIZE_PIECES_DATA, CLEAR_PIECES_DELTA } from './flowchart/pieces/types';
import { UPDATE_VARIABLES_DATA, SYNCHRONIZE_VARIABLES_DATA, CLEAR_VARIABLES_DELTA } from './flowchart/variables/types';
import { CLEAR_REPORT_TYPES_DELTA, UPDATE_REPORT_TYPES_DATA, SYNCHRONIZE_REPORT_TYPES_DATA } from './reports/types/types';
import { CLEAR_ORGANIZATION_DELTA, UPDATE_ORGANIZATION, WIPE_DATA } from './organization/types';
import { CLEAR_LANGUAGES_DELTA, UPDATE_LANGUAGES_DATA, SYNCHRONIZE_LANGUAGES_DATA } from './internationalization/languages/types';
import { CLEAR_TRANSLATIONS_DELTA, UPDATE_TRANSLATIONS } from './internationalization/translations/types';
import { CLEAR_MEMBER_TYPE_ACTIONS_DELTA, UPDATE_MEMBER_TYPE_ACTIONS_DATA, SYNCHRONIZE_MEMBER_TYPE_ACTIONS_DATA } from './members/types/actions/types';
import { CLEAR_GROUP_TYPE_ACTIONS_DELTA, UPDATE_GROUP_TYPE_ACTIONS_DATA, SYNCHRONIZE_GROUP_TYPE_ACTIONS_DATA } from './groups/types/actions/types';
import { storeCurrentSnapshot, logDataPush } from './database';
import { CLEAR_STATIC_DATA_HOLDERS_DELTA, SYNCHRONIZE_STATIC_DATA_HOLDERS_DATA, UPDATE_STATIC_DATA_HOLDERS_DATA } from './static-info/types';
import { CLEAR_DATA_FRAGMENTS_DELTA, SYNCHRONIZE_DATA_FRAGMENTS_DATA, UPDATE_DATA_FRAGMENTS_DATA } from './static-info/data-fragment/types';
import { CLEAR_EVENTS_DELTA } from './events/types';
import { AddErrorAction, ADD_ERROR, CLEAR_ERRORS, IAppError } from './errors/types';
import { getSearchStringsForGroup, getSearchStringsForMember, getSearchStringsForUser, getSearchStringsForWorkflow } from '../helpers/search';
import { getValueForMessageWidget, getShowDataForWidget } from '../helpers/widgets';
import { updateWidgetCache, updateWidgetMessageCache } from './widgets/actions';
import store from './main';
import { addAppError } from './errors/actions';
import { getVisibleUserIds, getVisibleMemberIds, getVisibleGroupIds, getVisibleWorkflowIds } from '../helpers/visible-entities';
import { isMobilePlatform, isUUID } from '../helpers/utilities';
import { resetSession } from './actions';
import { SearchIndex } from '../helpers/common-types';
import { BASE_URL } from './url';

function fetchAllData() {

    const serverUrl = BASE_URL + '/org-data/';

    return axios.get<DataReceivedAfterSync>(serverUrl, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });

}

function markResponseComplete(
    responseId: string,
) {
    const serverUrl = new URL('/mark-response-complete/', BASE_URL);
    serverUrl.searchParams.set('responseId', responseId);

    return axios.get(serverUrl.toString(), {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

function fetchRemainingData(
    responseId: string,
    lastMemberId: string | undefined,
    lastGroupId: string | undefined,
    lastWorkflowId: string | undefined,
) {

    const serverUrl = new URL('/remaining-data/', BASE_URL);

    serverUrl.searchParams.set('responseId', responseId);

    lastMemberId && serverUrl.searchParams.set('lastMemberId', lastMemberId);
    lastGroupId && serverUrl.searchParams.set('lastGroupId', lastGroupId);
    lastWorkflowId && serverUrl.searchParams.set('lastWorkflowId', lastWorkflowId);

    return axios.get<RemainingDataReceivedAfterSync>(serverUrl.toString(), {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

function fetchInactiveEntitiesData() {

    const serverUrl = BASE_URL + '/inactive-entities/';

    return axios.get(serverUrl, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });

}

function pushAllData(dataToPush: DataToPush) {

    logDataPush(dataToPush);

    const serverUrl = BASE_URL + '/synchronize/';

    return axios.post(serverUrl, dataToPush, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });

}

function clearDeltaActions() {
    const allActions = [];
    allActions.push(put({
        type: CLEAR_PROJECTS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_LEVELS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_ROLES_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_LOCATIONS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_USERS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_MEMBERS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_MEMBER_TYPES_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_MEMBER_TYPE_ACTIONS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_GROUPS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_GROUP_TYPES_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_GROUP_TYPE_ACTIONS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_WORKFLOWS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_WORKFLOW_TYPES_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_WORKFLOW_STATUSES_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_REPORTS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_STATIC_DATA_HOLDERS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_DATA_FRAGMENTS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_REPORT_TYPES_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_WIDGETS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_WIDGET_CONFIGURATIONS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_PIECES_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_EVENTS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_ERRORS,
    }));

    allActions.push(put({
        type: CLEAR_VARIABLES_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_LANGUAGES_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_TRANSLATIONS_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_ORGANIZATION_DELTA,
    }));

    allActions.push(put({
        type: CLEAR_PERMISSIONS_DELTA,
    }));

    return allActions;
}

function* fetchOlderDataFromServer() {

    if (typeof window === 'undefined') {
        return;
    }

    if (!window.navigator.onLine) {
        return;
    }

    try {
        const responseData: AxiosResponse<ThresholdData> = yield call(fetchInactiveEntitiesData);

        if (!responseData) {
            yield put(markNetworkInterrupt());
            return;
        }

        const appData = responseData.data;
        const inactiveMembers = responseData.data.members;
        const inactiveGroups = responseData.data.groups;
        const inactiveWorkflows = responseData.data.workflows;

        yield put({
            type: APPEND_MEMBERS_DATA,
            data: inactiveMembers,
        });

        yield put({
            type: APPEND_GROUPS_DATA,
            data: inactiveGroups,
        });

        yield put({
            type: APPEND_WORKFLOWS_DATA,
            data: inactiveWorkflows,
        });

        const isDataFetchComplete = !appData.responseId;

        if (isDataFetchComplete) {
            yield put({
                type: COMPLETE_OLDER_DATA_FETCH,
            });
        } else {
            const lastIds = getLastIds(appData);

            const activeWorkflowsCount: number = yield select((state: ApplicationState) => state.workflows.activeWorkflowEntries.length);

            yield put({
                type: COMPLETE_PARTIAL_DATA_FETCH,
                responseId: appData.responseId,

                lastMemberId: lastIds.lastMemberId,
                lastGroupId: lastIds.lastGroupId,

                lastWorkflowId: lastIds.lastWorkflowId,

                totalNoOfMembers: appData.totalNoOfMembers,
                totalNoOfGroups: appData.totalNoOfGroups,
                totalNoOfWorkflows: appData.totalNoOfWorkflows + activeWorkflowsCount,
            });

            if (!isDataFetchComplete) {
                yield put({
                    type: RESUME_PARTIAL_DATA_FETCH,
                });
            }
        }

    } catch (e) {
        console.error('Data fetch failed');
        console.error(e);
        let myId = localStorage.getItem('myId');

        if (!myId) {
            myId = 'Unknown user';
        }
        if (e instanceof Error) {
            yield put(addAppError(e.message, 'Error while fetching completed workflows', window.location.href, myId));
        }
    }

}

interface LastIds {
    lastMemberId?: string;
    lastGroupId?: string;
    lastWorkflowId?: string;
}

function getLastIds(appData: DataReceivedAfterSync | RemainingDataReceivedAfterSync): LastIds {
    let lastMemberId = appData.members.length > 0 ? appData.members[appData.members.length - 1].id : undefined;
    let lastGroupId = appData.groups.length > 0 ? appData.groups[appData.groups.length - 1].id : undefined;
    let lastWorkflowId = appData.workflows.length > 0 ? appData.workflows[appData.workflows.length - 1].id : undefined;

    return {
        lastMemberId,
        lastGroupId,
        lastWorkflowId,
    }
}

function* fetchRemainingDataFromServer() {
    let partialResponseId: string | undefined = yield select((state: ApplicationState) => state.myData.partialResponseId);

    const isLoaded: boolean = yield select((state: ApplicationState) => state.myData.isLoaded);
    const isFetchingOlderData: boolean = yield select((state: ApplicationState) => state.myData.isFetchingOlderData);
    const isPushingData: boolean = yield select((state: ApplicationState) => state.myData.isPushingData);

    let lastIds: LastIds = yield select((state: ApplicationState) => {
        return {
            lastMemberId: state.myData.lastMemberId,
            lastGroupId: state.myData.lastGroupId,
            lastWorkflowId: state.myData.lastWorkflowId,
        };
    });

    try {
        const originalResponseId = partialResponseId;

        while (partialResponseId) {

            if (!window.navigator.onLine) {
                yield put(markNetworkInterrupt());
                return;
            }

            const remainingResponseData: AxiosResponse<RemainingDataReceivedAfterSync> = yield call(fetchRemainingData, partialResponseId, lastIds.lastMemberId, lastIds.lastGroupId, lastIds.lastWorkflowId);

            if (!remainingResponseData) {
                yield put(markNetworkInterrupt());
                return;
            }

            const remainingData = remainingResponseData.data;
            partialResponseId = remainingData.responseId;

            if (Array.isArray(remainingData.members)) {
                yield put({
                    type: APPEND_MEMBERS_DATA,
                    data: remainingData.members,
                });
            }

            if (Array.isArray(remainingData.groups)) {
                yield put({
                    type: APPEND_GROUPS_DATA,
                    data: remainingData.groups,
                });
            }

            if (Array.isArray(remainingData.workflows)) {
                yield put({
                    type: APPEND_WORKFLOWS_DATA,
                    data: remainingData.workflows,
                });
            }

            lastIds = getLastIds(remainingData);

            yield put({
                type: COMPLETE_PARTIAL_DATA_FETCH,
                responseId: partialResponseId,
                lastMemberId: lastIds.lastMemberId,
                lastGroupId: lastIds.lastGroupId,
                lastWorkflowId: lastIds.lastWorkflowId,
            });
        }

        if (!isLoaded) {
            yield put({
                type: COMPLETE_DATA_FETCH
            });
        }

        if (isFetchingOlderData) {
            yield put({
                type: COMPLETE_OLDER_DATA_FETCH
            });
        }

        if (isPushingData) {
            yield put({
                type: COMPLETE_DATA_PUSH
            });
        }

        if (originalResponseId) {
            yield call(markResponseComplete, originalResponseId);
        }

        if (typeof window !== 'undefined') {

            const isMobile = isMobilePlatform();

            if (isMobile) {
                console.log('Indexing users, members, groups, workflows, and updating widget cache in the main thread');
                let state: ApplicationState = yield select();

                const widgetCache: WidgetCache = yield call(cacheWidgetAfterSync, state);

                for (const messageWidgetId of Object.keys(widgetCache.messageWidgets)) {
                    const message = widgetCache.messageWidgets[messageWidgetId];
                    yield put(updateWidgetMessageCache(messageWidgetId, message));
                }

                for (const dataWidgetId of Object.keys(widgetCache.dataWidgets)) {
                    const showData = widgetCache.dataWidgets[dataWidgetId];
                    yield put(updateWidgetCache(dataWidgetId, showData));
                }

                state = yield select();

                try {
                    storeCurrentSnapshot(state);
                } catch (e) {
                    console.log('Exceeded limit');
                }
            }
        }
    } catch (e) {
        console.error('Data fetch failed');
        console.error(e);
        let myId = localStorage.getItem('myId');

        if (!myId) {
            myId = 'Unknown user';
        }

        yield put(markNetworkInterrupt());
        if (e instanceof Error) {
            yield put(addAppError(e.message, 'Error while fetching data', window.location.href, myId));
        }

        const stateAfterRemainingDataFetchFail: ApplicationState = yield select();

        try {
            storeCurrentSnapshot(stateAfterRemainingDataFetchFail);
        } catch (e) {
            console.log('Exceeded limit');
        }
    }
}

function* fetchDataFromServer() {

    if (typeof window === 'undefined') {
        return;
    }

    if (!window.navigator.onLine) {
        return;
    }

    try {
        const responseData: AxiosResponse<DataReceivedAfterSync> = yield call(fetchAllData);

        if (!responseData) {
            yield put(markNetworkInterrupt());
            return;
        }

        const appData = responseData.data;
        localStorage.setItem('lastSync', appData.lastSyncTime);

        if (appData.projects.length > 0) {
            yield put({
                type: UPDATE_PROJECTS_DATA,
                data: appData.projects,
            });
        }

        if (appData.levels.length > 0) {
            yield put({
                type: UPDATE_LEVELS_DATA,
                data: appData.levels,
            });
        }

        if (appData.levelCustomFields.length > 0) {
            yield put({
                type: UPDATE_LEVEL_CUSTOM_FIELDS_DATA,
                data: appData.levelCustomFields,
            });
        }

        if (appData.levelCustomFieldOptions.length > 0) {
            yield put({
                type: UPDATE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA,
                data: appData.levelCustomFieldOptions,
            });
        }

        if (appData.roles.length > 0) {
            yield put({
                type: UPDATE_ROLES_DATA,
                data: appData.roles,
            });
        }

        if (appData.roleCustomFields.length > 0) {
            yield put({
                type: UPDATE_ROLE_CUSTOM_FIELDS_DATA,
                data: appData.roleCustomFields,
            });
        }

        if (appData.roleCustomFieldOptions.length > 0) {
            yield put({
                type: UPDATE_ROLE_CUSTOM_FIELD_OPTIONS_DATA,
                data: appData.roleCustomFieldOptions,
            });
        }

        if (Array.isArray(appData.locations)) {
            yield put({
                type: UPDATE_LOCATIONS_DATA,
                data: appData.locations,
            });
        }

        if (Array.isArray(appData.users)) {
            yield put({
                type: UPDATE_USERS_DATA,
                data: appData.users,
            });
        }

        // This needs to be input even if the length is 0, because if there are no custom fields for users, the app needs to auto-create those fields
        yield put({
            type: UPDATE_USER_CUSTOM_FIELDS_DATA,
            data: appData.userCustomFields,
        });

        if (appData.userCustomFieldOptions.length > 0) {
            yield put({
                type: UPDATE_USER_CUSTOM_FIELD_OPTIONS_DATA,
                data: appData.userCustomFieldOptions,
            });
        }

        if (Array.isArray(appData.members)) {
            yield put({
                type: UPDATE_MEMBERS_DATA,
                data: appData.members,
                totalNoOfMembers: appData.totalNoOfMembers,
            });

        }

        if (appData.memberTypes.length > 0) {
            yield put({
                type: UPDATE_MEMBER_TYPES_DATA,
                data: appData.memberTypes,
            });
        }

        if (appData.memberTypeActions && appData.memberTypeActions.length > 0) {
            yield put({
                type: UPDATE_MEMBER_TYPE_ACTIONS_DATA,
                data: appData.memberTypeActions,
            });
        }

        if (appData.memberTypeCustomFields.length > 0) {
            yield put({
                type: UPDATE_MEMBER_TYPE_CUSTOM_FIELDS_DATA,
                data: appData.memberTypeCustomFields,
            });
        }

        if (appData.memberTypeCustomFieldOptions.length > 0) {
            yield put({
                type: UPDATE_MEMBER_TYPE_CUSTOM_FIELD_OPTIONS_DATA,
                data: appData.memberTypeCustomFieldOptions,
            });
        }

        if (Array.isArray(appData.groups)) {
            yield put({
                type: UPDATE_GROUPS_DATA,
                data: appData.groups,
                totalNoOfGroups: appData.totalNoOfGroups,
            });

        }

        if (appData.groupTypes.length > 0) {
            yield put({
                type: UPDATE_GROUP_TYPES_DATA,
                data: appData.groupTypes,
            });
        }

        if (appData.groupTypeActions && appData.groupTypeActions.length > 0) {
            yield put({
                type: UPDATE_GROUP_TYPE_ACTIONS_DATA,
                data: appData.groupTypeActions,
            });
        }

        if (appData.groupTypeCustomFields.length > 0) {
            yield put({
                type: UPDATE_GROUP_TYPE_CUSTOM_FIELDS_DATA,
                data: appData.groupTypeCustomFields,
            });
        }

        if (appData.groupTypeCustomFieldOptions.length > 0) {
            yield put({
                type: UPDATE_GROUP_TYPE_CUSTOM_FIELD_OPTIONS_DATA,
                data: appData.groupTypeCustomFieldOptions,
            });
        }

        if (appData.workflowTypes.length > 0) {
            yield put({
                type: UPDATE_WORKFLOW_TYPES_DATA,
                data: appData.workflowTypes,
            });
        }

        if (appData.workflowTypeStatuses.length > 0) {
            yield put({
                type: UPDATE_WORKFLOW_STATUSES_DATA,
                data: appData.workflowTypeStatuses,
            });
        }

        if (appData.workflowTypeCustomFields.length > 0) {
            yield put({
                type: UPDATE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA,
                data: appData.workflowTypeCustomFields,
            });
        }

        if (appData.workflowTypeCustomFieldOptions.length > 0) {
            yield put({
                type: UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA,
                data: appData.workflowTypeCustomFieldOptions,
            });
        }

        if (Array.isArray(appData.workflows)) {
            yield put({
                type: UPDATE_WORKFLOWS_DATA,
                data: appData.workflows,
                totalNoOfWorkflows: appData.totalNoOfWorkflows,
            });

        }

        if (Array.isArray(appData.reports)) {
            yield put({
                type: UPDATE_REPORTS_DATA,
                data: appData.reports,
            });

        }

        if (Array.isArray(appData.staticDataHolders)) {
            yield put({
                type: UPDATE_STATIC_DATA_HOLDERS_DATA,
                data: appData.staticDataHolders,
            })
        }

        if (Array.isArray(appData.dataFragments)) {
            yield put({
                type: UPDATE_DATA_FRAGMENTS_DATA,
                data: appData.dataFragments,
            });
        }

        if (appData.reportTypes.length > 0) {
            yield put({
                type: UPDATE_REPORT_TYPES_DATA,
                data: appData.reportTypes,
            });
        }

        if (appData.widgets.length > 0) {
            yield put({
                type: UPDATE_WIDGETS_DATA,
                data: appData.widgets,
            });

        }

        if (appData.widgetConfigurations.length > 0) {
            yield put({
                type: UPDATE_WIDGET_CONFIGURATIONS_DATA,
                data: appData.widgetConfigurations,
            });

        }

        if (appData.pieces.length > 0) {
            yield put({
                type: UPDATE_PIECES_DATA,
                data: appData.pieces,
            });
        }

        if (appData.variables.length > 0) {
            yield put({
                type: UPDATE_VARIABLES_DATA,
                data: appData.variables,
            });
        }

        if (appData.permissions.length > 0) {
            yield put({
                type: UPDATE_PERMISSIONS_DATA,
                data: appData.permissions,
            });
        }

        if (appData.languages.length > 0) {
            yield put({
                type: UPDATE_LANGUAGES_DATA,
                data: appData.languages,
            });
        }

        if (typeof appData.translations !== 'undefined') {
            yield put({
                type: UPDATE_TRANSLATIONS,
                data: decodeTranslations(appData.translations),
            });
        }

        if (typeof appData.organization !== 'undefined') {
            yield put({
                type: UPDATE_ORGANIZATION,
                payload: appData.organization,
            });

            yield put({
                type: CLEAR_ORGANIZATION_DELTA,
            });
        }

        yield put({
            type: UPDATE_LAST_SYNC_TIME,
            lastSyncTime: appData.lastSyncTime,
        });

        yield put({
            type: UPDATE_MY_ID,
            id: appData.myId,
        });

        const loggedInUser = appData.users.find(user => user.id === appData.myId);

        if (loggedInUser) {
            yield put(setIsOnline((loggedInUser as IUser).isOnline));
        }

        const allWidgets = appData.widgets as Array<IWidget>;
        const myWidgetIds = allWidgets.filter(widget => widget.creatingUser === appData.myId).map(widget => widget.id);

        yield put({
            type: UPDATE_MY_WIDGETS,
            widgetIds: myWidgetIds,
        });

        const isDataFetchComplete = !appData.responseId;

        if (isDataFetchComplete) {
            yield put({
                type: COMPLETE_DATA_FETCH
            });
        } else {
            const lastIds = getLastIds(appData);

            yield put({
                type: COMPLETE_PARTIAL_DATA_FETCH,
                responseId: appData.responseId,
                lastMemberId: lastIds.lastMemberId,
                lastGroupId: lastIds.lastGroupId,

                totalNoOfMembers: appData.totalNoOfMembers,
                totalNoOfGroups: appData.totalNoOfGroups,
                totalNoOfWorkflows: appData.totalNoOfWorkflows,

                lastWorkflowId: lastIds.lastWorkflowId,
            });
        }

        let state: ApplicationState = yield select();

        try {
            storeCurrentSnapshot(state);
        } catch (e) {
            console.log('Exceeded limit');
        }

        if (isDataFetchComplete && typeof window !== 'undefined') {

            const isMobile = isMobilePlatform();

            if (isMobile) {
                console.log('Indexing users, members, groups, workflows, and updating widget cache in the main thread');
                let state: ApplicationState = yield select();

                const widgetCache: WidgetCache = yield call(cacheWidgetAfterSync, state);

                for (const messageWidgetId of Object.keys(widgetCache.messageWidgets)) {
                    const message = widgetCache.messageWidgets[messageWidgetId];
                    yield put(updateWidgetMessageCache(messageWidgetId, message));
                }

                for (const dataWidgetId of Object.keys(widgetCache.dataWidgets)) {
                    const showData = widgetCache.dataWidgets[dataWidgetId];
                    yield put(updateWidgetCache(dataWidgetId, showData));
                }

                state = yield select();

                try {
                    storeCurrentSnapshot(state);
                } catch (e) {
                    console.log('Exceeded limit');
                }
            }
        }

        if (!isDataFetchComplete) {
            yield put({
                type: RESUME_PARTIAL_DATA_FETCH,
            });
        }

    } catch (e) {
        console.error('Data fetch failed');
        console.error(e);
        let myId = localStorage.getItem('myId');

        if (!myId) {
            myId = 'Unknown user';
        }

        yield put(markNetworkInterrupt());

        if (e instanceof Error) {
            yield put(addAppError(e.message, 'Error while fetching data', window.location.href, myId));
        }

        const stateAfterFetchFail: ApplicationState = yield select();

        try {
            storeCurrentSnapshot(stateAfterFetchFail);
        } catch (e) {
            console.log('Exceeded limit');
        }
    }
}

function* synchronizeChangesToStore(receivedData: DataReceivedAfterSync) {

    // Projects sync actions
    if (receivedData.projects.length > 0 || receivedData.reOrderedProjects.length > 0) {
        yield put({
            type: SYNCHRONIZE_PROJECTS_DATA,
            data: receivedData.projects,
            reOrder: receivedData.reOrderedProjects,
        });

        yield put({
            type: CLEAR_PROJECTS_DELTA,
        });
    }


    // Levels sync actions
    if (receivedData.levels.length > 0) {
        yield put({
            type: SYNCHRONIZE_LEVELS_DATA,
            data: receivedData.levels,
        });
    }

    if (receivedData.levelCustomFields.length > 0) {
        yield put({
            type: SYNCHRONIZE_LEVEL_CUSTOM_FIELDS_DATA,
            data: receivedData.levelCustomFields,
        });
    }

    if (receivedData.levelCustomFieldOptions.length > 0 || Object.keys(receivedData.reOrderedLevelCustomFieldOptions).length > 0) {
        yield put({
            type: SYNCHRONIZE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA,
            data: receivedData.levelCustomFieldOptions,
            reOrderedCustomFieldOptions: receivedData.reOrderedLevelCustomFieldOptions,
        });
    }

    if (receivedData.levels.length > 0 || receivedData.levelCustomFields.length > 0 || receivedData.levelCustomFieldOptions.length > 0) {
        yield put({
            type: CLEAR_LEVELS_DELTA,
        });
    }


    // Roles sync actions
    if (receivedData.roles.length > 0) {
        yield put({
            type: SYNCHRONIZE_ROLES_DATA,
            data: receivedData.roles,
        });
    }

    if (receivedData.roleCustomFields.length > 0) {
        yield put({
            type: SYNCHRONIZE_ROLE_CUSTOM_FIELDS_DATA,
            data: receivedData.roleCustomFields,
        });
    }

    if (receivedData.roleCustomFieldOptions.length > 0 || Object.keys(receivedData.reOrderedRoleCustomFieldOptions).length > 0) {
        yield put({
            type: SYNCHRONIZE_ROLE_CUSTOM_FIELD_OPTIONS_DATA,
            data: receivedData.roleCustomFieldOptions,
            reOrderedCustomFieldOptions: receivedData.reOrderedRoleCustomFieldOptions,
        });
    }

    if (receivedData.roles.length > 0 || receivedData.roleCustomFields.length > 0 || receivedData.roleCustomFieldOptions.length > 0) {
        yield put({
            type: CLEAR_ROLES_DELTA,
        });
    }

    // Location sync actions
    if (receivedData.locations.length > 0) {
        yield put({
            type: SYNCHRONIZE_LOCATIONS_DATA,
            data: receivedData.locations,
            reOrderedTopLevelLocations: receivedData.reOrderedTopLevelLocations,
            reOrderedChildLocations: receivedData.reOrderedChildLocations,
        });
    }

    if (receivedData.locations.length > 0) {
        yield put({
            type: CLEAR_LOCATIONS_DELTA,
        });
    }

    // User sync actions
    if (receivedData.users.length > 0) {
        yield put({
            type: SYNCHRONIZE_USERS_DATA,
            data: receivedData.users,
        });
    }

    if (receivedData.userCustomFields.length > 0) {
        yield put({
            type: SYNCHRONIZE_USER_CUSTOM_FIELDS_DATA,
            data: receivedData.userCustomFields,
        });
    }

    if (receivedData.userCustomFieldOptions.length > 0) {
        yield put({
            type: SYNCHRONIZE_USER_CUSTOM_FIELD_OPTIONS_DATA,
            data: receivedData.userCustomFieldOptions,
        });
    }

    if (receivedData.users.length > 0 || receivedData.userCustomFields.length > 0 || receivedData.userCustomFieldOptions.length > 0) {
        yield put({
            type: CLEAR_USERS_DELTA,
        });
    }

    // Member sync actions
    if (receivedData.members.length > 0) {
        yield put({
            type: SYNCHRONIZE_MEMBERS_DATA,
            data: receivedData.members,
        });
    }

    if (receivedData.memberTypes.length > 0 || receivedData.reOrderedMemberTypes.length > 0) {
        yield put({
            type: SYNCHRONIZE_MEMBER_TYPES_DATA,
            data: receivedData.memberTypes,
            reOrder: receivedData.reOrderedMemberTypes,
        });
    }

    if ((receivedData.memberTypeActions && receivedData.memberTypeActions.length > 0) || Object.keys(receivedData.reOrderedMemberTypeActions).length > 0) {
        yield put({
            type: SYNCHRONIZE_MEMBER_TYPE_ACTIONS_DATA,
            data: receivedData.memberTypeActions,
            reOrderedActions: receivedData.reOrderedMemberTypeActions,
        });
    }

    if (receivedData.memberTypeCustomFields.length > 0) {
        yield put({
            type: SYNCHRONIZE_MEMBER_TYPE_CUSTOM_FIELDS_DATA,
            data: receivedData.memberTypeCustomFields,
        });
    }

    if (receivedData.memberTypeCustomFieldOptions.length > 0 || Object.keys(receivedData.reOrderedMemberTypeCustomFieldOptions).length > 0) {
        yield put({
            type: SYNCHRONIZE_MEMBER_TYPE_CUSTOM_FIELD_OPTIONS_DATA,
            data: receivedData.memberTypeCustomFieldOptions,
            reOrderedCustomFieldOptions: receivedData.reOrderedMemberTypeCustomFieldOptions,
        });
    }

    if (receivedData.members.length > 0) {
        yield put({
            type: CLEAR_MEMBERS_DELTA,
        });
    }

    if (receivedData.memberTypes.length > 0 || receivedData.memberTypeCustomFields.length > 0 || receivedData.memberTypeCustomFieldOptions.length > 0) {
        yield put({
            type: CLEAR_MEMBER_TYPES_DELTA,
        });
    }

    // Group sync actions
    if (receivedData.groups.length > 0) {
        yield put({
            type: SYNCHRONIZE_GROUPS_DATA,
            data: receivedData.groups,
        });
    }

    if (receivedData.groupTypes.length > 0 || receivedData.reOrderedGroupTypes.length > 0) {
        yield put({
            type: SYNCHRONIZE_GROUP_TYPES_DATA,
            data: receivedData.groupTypes,
            reOrder: receivedData.reOrderedGroupTypes,
        });
    }

    if ((receivedData.groupTypeActions && receivedData.groupTypeActions.length > 0) || Object.keys(receivedData.reOrderedGroupTypeActions).length > 0) {
        yield put({
            type: SYNCHRONIZE_GROUP_TYPE_ACTIONS_DATA,
            data: receivedData.groupTypeActions,
            reOrderedActions: receivedData.reOrderedGroupTypeActions,
        });
    }

    if (receivedData.groupTypeCustomFields.length > 0) {
        yield put({
            type: SYNCHRONIZE_GROUP_TYPE_CUSTOM_FIELDS_DATA,
            data: receivedData.groupTypeCustomFields,
        });
    }

    if (receivedData.groupTypeCustomFieldOptions.length > 0 || Object.keys(receivedData.reOrderedGroupTypeCustomFieldOptions).length > 0) {
        yield put({
            type: SYNCHRONIZE_GROUP_TYPE_CUSTOM_FIELD_OPTIONS_DATA,
            data: receivedData.groupTypeCustomFieldOptions,
            reOrderedCustomFieldOptions: receivedData.reOrderedGroupTypeCustomFieldOptions,
        });
    }

    if (receivedData.groups.length > 0) {
        yield put({
            type: CLEAR_GROUPS_DELTA,
        });
    }

    if (receivedData.groupTypes.length > 0 || receivedData.groupTypeCustomFields.length > 0 || receivedData.groupTypeCustomFieldOptions.length > 0) {
        yield put({
            type: CLEAR_GROUP_TYPES_DELTA,
        });
    }

    // Workflow sync actions
    if (receivedData.workflows.length > 0) {
        yield put({
            type: SYNCHRONIZE_WORKFLOWS_DATA,
            data: receivedData.workflows,
        });
    }

    if (receivedData.workflowTypes.length > 0 || receivedData.reOrderedWorkflowTypes.length > 0) {
        yield put({
            type: SYNCHRONIZE_WORKFLOW_TYPES_DATA,
            data: receivedData.workflowTypes,
            reOrder: receivedData.reOrderedWorkflowTypes,
        });
    }

    if (receivedData.workflowTypeStatuses.length > 0 || Object.keys(receivedData.reOrderedWorkflowTypeStatuses).length > 0) {
        yield put({
            type: SYNCHRONIZE_WORKFLOW_STATUSES_DATA,
            data: receivedData.workflowTypeStatuses,
            reOrderedStatuses: receivedData.reOrderedWorkflowTypeStatuses,
        });
    }

    if (receivedData.workflowTypeCustomFields.length > 0) {
        yield put({
            type: SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA,
            data: receivedData.workflowTypeCustomFields,
        });
    }

    if (receivedData.workflowTypeCustomFieldOptions.length > 0 || Object.keys(receivedData.reOrderedWorkflowTypeCustomFieldOptions).length > 0) {
        yield put({
            type: SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA,
            data: receivedData.workflowTypeCustomFieldOptions,
            reOrderedCustomFieldOptions: receivedData.reOrderedWorkflowTypeCustomFieldOptions,
        });
    }

    if (receivedData.workflows.length > 0) {
        yield put({
            type: CLEAR_WORKFLOWS_DELTA,
        });
    }

    if (receivedData.workflowTypes.length > 0 || receivedData.workflowTypeCustomFields.length > 0 || receivedData.workflowTypeCustomFieldOptions.length > 0) {
        yield put({
            type: CLEAR_WORKFLOW_TYPES_DELTA,
        });
    }

    if (receivedData.workflowTypeStatuses.length > 0) {
        yield put({
            type: CLEAR_WORKFLOW_STATUSES_DELTA,
        });
    }

    // Report sync actions
    if (receivedData.reports.length > 0) {
        yield put({
            type: SYNCHRONIZE_REPORTS_DATA,
            data: receivedData.reports,
        });
    }

    if (receivedData.staticDataHolders.length > 0) {
        yield put({
            type: SYNCHRONIZE_STATIC_DATA_HOLDERS_DATA,
            data: receivedData.staticDataHolders,
        })
    }

    if (receivedData.dataFragments.length > 0) {
        yield put({
            type: SYNCHRONIZE_DATA_FRAGMENTS_DATA,
            data: receivedData.dataFragments,
        })
    }

    if (receivedData.reportTypes.length > 0) {
        yield put({
            type: SYNCHRONIZE_REPORT_TYPES_DATA,
            data: receivedData.reportTypes,
        });
    }

    if (receivedData.staticDataHolders.length > 0) {
        yield put({
            type: SYNCHRONIZE_STATIC_DATA_HOLDERS_DATA,
            data: receivedData.staticDataHolders,
        });
    }

    if (receivedData.dataFragments.length > 0) {
        yield put({
            type: SYNCHRONIZE_DATA_FRAGMENTS_DATA,
            data: receivedData.dataFragments,
        });
    }

    if (receivedData.reportTypes.length > 0) {
        yield put({
            type: SYNCHRONIZE_REPORT_TYPES_DATA,
            data: receivedData.reportTypes,
        });
    }

    if (receivedData.widgets.length > 0) {
        yield put({
            type: SYNCHRONIZE_WIDGETS_DATA,
            data: receivedData.widgets,
        });
    }

    if (receivedData.widgetConfigurations.length > 0) {
        yield put({
            type: SYNCHRONIZE_WIDGET_CONFIGURATIONS_DATA,
            data: receivedData.widgetConfigurations,
        });
    }

    if (receivedData.pieces.length > 0) {
        yield put({
            type: SYNCHRONIZE_PIECES_DATA,
            data: receivedData.pieces,
        });

        yield put({
            type: CLEAR_PIECES_DELTA,
        });
    }

    if (receivedData.variables.length > 0) {
        yield put({
            type: SYNCHRONIZE_VARIABLES_DATA,
            data: receivedData.pieces,
        });

        yield put({
            type: CLEAR_VARIABLES_DELTA,
        });
    }

    yield put({
        type: CLEAR_EVENTS_DELTA,
    });

    yield put({
        type: CLEAR_ERRORS,
    });

    if (receivedData.languages.length > 0) {
        yield put({
            type: SYNCHRONIZE_LANGUAGES_DATA,
            data: receivedData.languages,
        });

        yield put({
            type: CLEAR_LANGUAGES_DELTA,
        });
    }

    if (typeof receivedData.translations !== 'undefined') {
        yield put({
            type: UPDATE_TRANSLATIONS,
            data: decodeTranslations(receivedData.translations),
        });
    }

    if (typeof receivedData.organization !== 'undefined') {
        yield put({
            type: UPDATE_ORGANIZATION,
            payload: receivedData.organization,
        });

        yield put({
            type: CLEAR_ORGANIZATION_DELTA,
        });
    }

    if (receivedData.permissions.length > 0) {
        yield put({
            type: SYNCHRONIZE_PERMISSIONS_DATA,
            data: receivedData.permissions,
        });
    }

    yield put({
        type: UPDATE_LAST_SYNC_TIME,
        lastSyncTime: receivedData.lastSyncTime,
    });

    yield put({
        type: UPDATE_MY_ID,
        id: receivedData.myId,
    });



    const isDataFetchComplete = !receivedData.responseId;

    if (isDataFetchComplete) {
        yield put({
            type: COMPLETE_DATA_PUSH,
        });
    } else {
        const lastIds = getLastIds(receivedData);

        yield put({
            type: COMPLETE_PARTIAL_DATA_FETCH,
            responseId: receivedData.responseId,
            lastMemberId: lastIds.lastMemberId,
            lastGroupId: lastIds.lastGroupId,

            totalNoOfMembers: receivedData.totalNoOfMembers,
            totalNoOfGroups: receivedData.totalNoOfGroups,
            totalNoOfWorkflows: receivedData.totalNoOfWorkflows,

            lastWorkflowId: lastIds.lastWorkflowId,
        });
    }

    if (!isDataFetchComplete) {
        yield put({
            type: RESUME_PARTIAL_DATA_FETCH,
        });
    }
}

interface WidgetCache {
    messageWidgets: {
        [widgetId: string]: string,
    },
    dataWidgets: {
        [widgetId: string]: WidgetData,
    },
};

async function cacheWidgetAfterSync(state: ApplicationState) {
    let widgetIdsInRoles: Array<string> = [];

    const myRoles = state.users.byId.hasOwnProperty(state.myData.id) ? state.users.byId[state.myData.id].roles : [];

    for (const roleId of myRoles) {
        const widgetIdsInRole = roleId && roleId in state.widgets.byRole ? state.widgets.byRole[roleId] : [];
        widgetIdsInRoles = widgetIdsInRoles.concat(widgetIdsInRole);
    }

    const newWidgetIds = Array.from(new Set(widgetIdsInRoles.filter(widgetId => !state.myData.widgets.myWidgets.includes(widgetId))));
    const myWidgetIds = Array.from(new Set(state.myData.widgets.myWidgets));

    const allWidgetIds = newWidgetIds.concat(myWidgetIds).filter(widgetId => !state.widgets.byId[widgetId].archived);

    const widgetCache: WidgetCache = {
        messageWidgets: {},
        dataWidgets: {},
    };

    const visibleUserIds = getVisibleUserIds(state, true);
    const visibleMemberIds = getVisibleMemberIds(state, true);
    const visibleGroupIds = getVisibleGroupIds(state, true);
    const visibleWorkflowIds = getVisibleWorkflowIds(state, visibleUserIds, visibleMemberIds, visibleGroupIds, true);

    for (const widgetId of allWidgetIds) {
        const widget = state.widgets.byId[widgetId];

        if (widget.displayType === 'message') {
            const message = getValueForMessageWidget(widgetId, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, state);
            widgetCache.messageWidgets[widgetId] = message ? message : '-';
        } else {
            let runningSlice = getShowDataForWidget(widgetId, state, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds);
            const widgetData = runningSlice.widgetData;

            while (runningSlice.lastEntityId) {
                runningSlice = getShowDataForWidget(widgetId, state, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, runningSlice.lastEntityId);

                widgetData.entries = widgetData.entries.concat(runningSlice.widgetData.entries);
                widgetData.entityIds = widgetData.entityIds.concat(runningSlice.widgetData.entityIds);

                for (let i = 0; i < runningSlice.widgetData.chartLabels.length; i += 1) {
                    const chartLabel = runningSlice.widgetData.chartLabels[i];
                    const chartData = runningSlice.widgetData.chartData[i];

                    const existingLabelIndex = widgetData.chartLabels.indexOf(chartLabel);

                    if (existingLabelIndex === -1) {
                        widgetData.chartLabels.push(chartLabel);
                        widgetData.chartData.push(chartData);
                    } else {
                        widgetData.chartData[existingLabelIndex] += chartData;
                    }
                }
            }

            widgetCache.dataWidgets[widgetId] = widgetData;
        }
    }

    return widgetCache;
}

function* saveDataToSnapshot() {
    yield delay(15000);

    const state: ApplicationState = yield select();

    try {
        storeCurrentSnapshot(state);
    } catch (e) {
        console.log('Exceeded limit');
    }
}

function* storeLatestErrorsLocally(action: AddErrorAction) {

    if (window && window.localStorage) {

        let temp = localStorage.getItem('errors');

        const existingErrorEntries: Array<IAppError> = !temp ? [] : JSON.parse(temp);

        if (existingErrorEntries.length >= 5) {
            existingErrorEntries.splice(0, 1);
        }

        existingErrorEntries.push(action.payload);

        localStorage.setItem('errors', JSON.stringify(existingErrorEntries));
    }
}

function* saveDataToServer(action: AnyAction) {
    const state: ApplicationState = yield select();

    if (!window.navigator.onLine) {
        return;
    }

    try {
        // yield call(pushAllData, state);

        const dataDelta = collectDataToPush(state);
        let receivedData: AxiosResponse<DataReceivedAfterSync> | undefined;

        try {
            receivedData = yield call(pushAllData, dataDelta.data);
        } catch (e) {
            console.error('Error when synchronizing');
            console.error(e);

            yield put(markNetworkInterrupt());

            if (e instanceof Error) {
                yield put(addAppError(e.message, 'Error while synchronizing', window.location.href, state.myData.id));
            }

            const stateAfterSyncFail: ApplicationState = yield select();

            try {
                storeCurrentSnapshot(stateAfterSyncFail);
            } catch (e) {
                console.log('Exceeded limit');
            }
        }

        if (!receivedData) {
            yield put(addAppError('No data received from server', 'Error while synchronizing', window.location.href, state.myData.id));
            return;
        }

        if (state.organization.hasThresholdChanged) {

            const allActions = [
                put(completeDataPush()),
                put(resetSession()),
                put(setIsFetchingForDataUpdate(true, 'Getting fresh data due to threshold(s) change')),
                put(initiateFetchAppData()),
            ]

            yield all(allActions);
            return;
        }

        let hasThresholdsChanged = false;
        let hasRolesChanged = false;
        let hasLocationsChanged = false;

        if (isUUID(state.myData.id)) {
            const user = state.users.byId[state.myData.id];

            hasThresholdsChanged = user.roles.some(roleId => {
                const role = state.structure.roles.byId[roleId];
                const newRole = receivedData?.data.roles.find(newRole => newRole.id === role.id) as IRole | undefined;

                if (!newRole) {
                    return false;
                }

                const hasMemberThresholdChanged = role.thresholdDaysForMembers !== newRole.thresholdDaysForMembers;
                const hasGroupThresholdChanged = role.thresholdDaysForGroups !== newRole.thresholdDaysForGroups;
                const hasWorkflowThresholdChanged = role.thresholdDaysForWorkflows !== newRole.thresholdDaysForWorkflows;

                return hasMemberThresholdChanged || hasGroupThresholdChanged || hasWorkflowThresholdChanged;
            });

            if (!hasThresholdsChanged && receivedData.data.organization) {
                const hasMemberThresholdChanged = state.organization.completedMemberThreshold !== receivedData.data.organization.completedMemberThreshold;
                const hasGroupThresholdChanged = state.organization.completedGroupThreshold !== receivedData.data.organization.completedGroupThreshold;
                const hasWorkflowThresholdChanged = state.organization.completedWorkflowThreshold !== receivedData.data.organization.completedWorkflowThreshold;

                hasThresholdsChanged = hasThresholdsChanged || hasMemberThresholdChanged || hasGroupThresholdChanged || hasWorkflowThresholdChanged;
            }

            const receivedUser = receivedData?.data.users.find(newUser => newUser.id === user.id) as IUser | undefined;

            if (receivedUser) {
                hasRolesChanged = user.roles.some(roleId => !receivedUser.roles.includes(roleId)) || receivedUser.roles.some(roleId => !user.roles.includes(roleId))
                hasLocationsChanged = user.locations.some(roleId => !receivedUser.locations.includes(roleId)) || receivedUser.locations.some(roleId => !user.locations.includes(roleId))
            }
        } else {

            if (receivedData.data.organization) {
                let memberThreshold = state.organization.completedMemberThresholdForSuperUser;

                if (typeof memberThreshold === 'undefined' || memberThreshold === null) {
                    memberThreshold = state.organization.completedMemberThreshold;
                }

                let newMemberThreshold = receivedData.data.organization.completedMemberThresholdForSuperUser;

                if (typeof newMemberThreshold === 'undefined' || newMemberThreshold === null) {
                    newMemberThreshold = receivedData.data.organization.completedMemberThreshold;
                }

                const hasMemberThresholdChanged = newMemberThreshold !== memberThreshold;

                let groupThreshold = state.organization.completedGroupThresholdForSuperUser;

                if (typeof groupThreshold === 'undefined' || groupThreshold === null) {
                    groupThreshold = state.organization.completedGroupThreshold;
                }

                let newGroupThreshold = receivedData.data.organization.completedGroupThresholdForSuperUser;

                if (typeof newGroupThreshold === 'undefined' || newGroupThreshold === null) {
                    newGroupThreshold = receivedData.data.organization.completedGroupThreshold;
                }

                const hasGroupThresholdChanged = newGroupThreshold !== groupThreshold;

                let workflowThreshold = state.organization.completedWorkflowThresholdForSuperUser;

                if (typeof workflowThreshold === 'undefined' || workflowThreshold === null) {
                    workflowThreshold = state.organization.completedWorkflowThreshold;
                }

                let newWorkflowThreshold = receivedData.data.organization.completedWorkflowThresholdForSuperUser;

                if (typeof newWorkflowThreshold === 'undefined' || newWorkflowThreshold === null) {
                    newWorkflowThreshold = receivedData.data.organization.completedWorkflowThreshold;
                }

                const hasWorkflowThresholdChanged = newWorkflowThreshold !== workflowThreshold;

                hasThresholdsChanged = hasMemberThresholdChanged || hasGroupThresholdChanged || hasWorkflowThresholdChanged;
            }

        }

        if (hasThresholdsChanged || hasRolesChanged || hasLocationsChanged) {

            let message = 'Getting fresh data as per new user settings';

            if (hasThresholdsChanged) {
                message = 'Getting fresh data due to threshold(s) change';
            } else if (hasRolesChanged) {
                message = 'Getting fresh data due to role(s) change';
            } else if (hasLocationsChanged) {
                message = 'Getting fresh data due to location(s) change';
            }

            const allActions = [
                put(completeDataPush()),
                put(resetSession()),
                put(setIsFetchingForDataUpdate(true, message)),
                put(initiateFetchAppData()),
            ]

            yield all(allActions);
            return;
        }

        for (const action of synchronizeChangesToStore(receivedData.data)) {
            yield action;
        }

        const allActions = clearDeltaActions();
        yield all(allActions);

        if (typeof window !== 'undefined') {

            yield put(completeDataPush());

            const stateAfterSync: ApplicationState = yield select();

            try {
                storeCurrentSnapshot(stateAfterSync);
            } catch (e) {
                console.log('Exceeded limit');
            }
        }
    } catch (e) {
        console.error('Data push failed');
        console.error(e);

        if (e instanceof Error) {
            yield put(addAppError(e.message, 'Error while pushing data', window.location.href, state.myData.id));
        }
    }
}

function* wipeDataOnServer(action: AnyAction) {

    const serverUrl = BASE_URL + '/wipe-work-data/';

    yield axios.get(serverUrl, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

export function* watchDataFetchRequest() {
    yield takeLatest(FETCH_DATA_REQUEST, fetchDataFromServer);
}

export function* watchChangeDataRequest() {
    yield takeLatest('*', saveDataToSnapshot);
}

export function* watchDataWipeRequest() {
    yield takeLatest(WIPE_DATA, wipeDataOnServer);
}

export function* watchSaveDataRequest() {
    yield takeLatest(START_DATA_PUSH, saveDataToServer);
}

export function* watchOlderDataFetchRequest() {
    yield takeLatest(START_OLDER_DATA_FETCH, fetchOlderDataFromServer);
}

export function* watchResumePartialResponseRequest() {
    yield takeLatest(RESUME_PARTIAL_DATA_FETCH, fetchRemainingDataFromServer);
}

export function* watchErrorAddRequest() {
    yield takeLatest(ADD_ERROR, storeLatestErrorsLocally);
}

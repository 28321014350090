import { Component, OnDestroy, OnInit } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { IUpdateableGroupData } from 'src/shared/store/groups/types';
import { MemberState } from '../../shared/store/members/types';
import { IGroupType } from 'src/shared/store/groups/types/types';
import { GroupState } from 'src/shared/store/groups/types';
import { addGroup, updateGroupRequest } from 'src/shared/store/groups/actions';
import { IUpdateableCustomFieldData } from '../../shared/store/custom-fields/types';
import { translatePhrase } from 'src/shared/helpers/translation';
import { ModalController, NavParams } from '@ionic/angular';
import { NgRedux } from '@angular-redux/store';
import { ApplicationState } from '../../shared/store/types';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { PermissionSet, Permissions } from 'src/shared/store/permissions/types';
import { AddWorkflowPage } from '../add-workflow/add-workflow.page';
import { ToastService } from '../services/toast.service';
import { getLocationsAtLevel } from 'src/shared/helpers/locations';
import { ToastType } from '../services/toast.service';

@Component({
    selector: 'app-add-or-edit-group',
    templateUrl: './add-or-edit-group.page.html',
    styleUrls: ['./add-or-edit-group.page.scss'],
})
export class AddOrEditGroupPage implements OnInit, OnDestroy {
    @select('members') MembersData: Observable<MemberState>;
    @select('groups') GroupsData: Observable<GroupState>;
    @select(state => state) applicationStateSource: Observable<ApplicationState>;
    new_group: IUpdateableGroupData;
    save_state: string = 'inactive';
    current_heading: string = 'Add';
    all_group_types: Array<IGroupType> = [];
    customFields: Array<IUpdateableCustomFieldData> = [];
    calculatedCustomFields: any = [];
    membersData: MemberState;
    groupsSubscription: Subscription;
    groupsData: GroupState;
    userPermission: PermissionSet;

    applicationState: ApplicationState;
    applicationStateSubscription: Subscription;
    selectGroupTypeModal: boolean = false;

    groupLocations: Array<string> = [];

    constructor(
        private ngRedux: NgRedux<ApplicationState>,
        private modalController: ModalController,
        private navParams: NavParams,
        public toastService: ToastService,
    ) { }

    translate(phrase: string) {
        return translatePhrase(phrase.trim());
    }

    isActionWorkflowAvailable(actionId: string) {
        return this.groupsData.types.actions.byId[actionId].workflowType ? true : false;
    }

    getlocationName(id: string) {
        return this.applicationState.structure.locations.byId[id].name;
    }

    updateCustomFields() {
        if (this.new_group.type) {
            let groupType = this.groupsData.types.byId[this.new_group.type];

            if (groupType.actions && groupType.actions[0] &&
                this.groupsData.types.actions.byId[groupType.actions[0]].workflowType &&
                this.current_heading !== 'Edit') {
                this.back();
                this.addNewWorkflowWithType(this.groupsData.types.actions.byId[groupType.actions[0]].workflowType);
            } else {
                if (this.getGroupTypePermission(this.new_group.type) === 'WRITE') {
                    this.selectGroupTypeModal = false;

                    this.customFields = this.groupsData.types.byId[this.new_group.type].customFields.map((fieldId) => {
                        return this.groupsData.types.customFields.byId[fieldId];
                    });

                    let i: number;
                    this.calculatedCustomFields = JSON.parse(JSON.stringify(this.customFields));
                    for (i = 0; i < this.calculatedCustomFields.length; i += 1) {
                        if (this.calculatedCustomFields[i].type === 'SINGLE_SELECT') {
                            let j: number;
                            this.calculatedCustomFields[i].choice_details = [];
                            for (j = 0; j < this.calculatedCustomFields[i].choices.length; j += 1) {
                                this.calculatedCustomFields[i].choice_details.push(this.groupsData.types.byId[this.new_group.type].customFields.map(() => {
                                    return this.groupsData.types.customFieldOptions.byId[this.calculatedCustomFields[i].choices[j]];
                                })[0]);
                            }
                        }
                    }
                } else {
                    this.toastService.presentToastWithOptions("You do not have permission to add group of this type", ToastType.WARNING);
                }
            }
        } else {
            this.toastService.presentToastWithOptions("Please select a group type", ToastType.INFO);
        }
    }


    getGroupTypePermission(id: string) {
        if (this.userPermission.groups && this.userPermission.groups[id]) {
            return this.userPermission.groups[id];
        } else {
            return Permissions.WRITE;
        }
    }

    updateGroupTypeInfo() {

        const currentGroupType = this.groupsData.types.byId[this.new_group.type];

        if (currentGroupType) {
            this.groupLocations = getLocationsAtLevel(currentGroupType.level).map(location => location.id);

            if (this.groupLocations.length > 0) {
                this.new_group.location = this.groupLocations[0];
            }
        }


    }

    ngOnInit() {
        this.applicationStateSubscription = this.applicationStateSource.subscribe((applicationState) => {
            this.applicationState = applicationState;

            this.userPermission = this.applicationState.permissions.myPermissions;
        });

        this.groupsSubscription = this.GroupsData.subscribe((groupsData) => {
            this.groupsData = groupsData;

            if (this.navParams.get('group')) {
                this.new_group = JSON.parse(this.navParams.get('group'));
                this.current_heading = 'Edit';

                this.all_group_types = this.groupsData.types.allEntries.map(typeId => this.groupsData.types.byId[typeId]);

                let userProjectIds = this.applicationState.users.byId[this.applicationState.myData.id].projects;

                this.all_group_types = this.all_group_types.filter((type) => {
                    return userProjectIds.includes(type.project) && this.getGroupTypePermission(type.id) !== Permissions.NONE;
                });

                this.updateCustomFields();
            } else {
                this.new_group = {
                    id: uuid(),
                    type: '',
                    location: '',
                    members: [],
                    representatives: [],
                    customFields: {}
                };

                this.all_group_types = this.groupsData.types.allEntries.map(typeId => this.groupsData.types.byId[typeId]);

                let userProjectIds = this.applicationState.users.byId[this.applicationState.myData.id].projects;

                this.all_group_types = this.all_group_types.filter((type) => {
                    return userProjectIds.includes(type.project) && this.getGroupTypePermission(type.id) !== Permissions.NONE;
                });

                if (this.all_group_types.length > 0) {
                    this.selectGroupTypeModal = true;
                }

                if (this.groupLocations.length > 0) {
                    this.new_group.location = this.groupLocations[0];
                }
            }
        });
    }

    back() {
        this.modalController.dismiss();
    }

    isAllFieldsFilled() {
        let flag = true;

        for (let i = 0; i < this.customFields.length; i += 1) {
            if (!this.new_group.customFields[this.customFields[i].id]) {
                if (!(this.customFields[i].isComputed || !this.customFields[i].isEditable)) {
                    this.toastService.presentToastWithOptions("Missing " + this.customFields[i].name, ToastType.ERROR);
                    flag = false;
                }
                break;
            }
        }

        return flag;
    }

    save() {
        if (this.selectGroupTypeModal) {
            return;
        }

        if (this.current_heading === 'Add') {
            if (this.isAllFieldsFilled()) {
                this.save_state = 'initiated';
                this.ngRedux.dispatch(addGroup(this.new_group));
                this.save_state = 'saved';
                this.back();
            }
        } else {
            this.save_state = 'initiated';
            this.ngRedux.dispatch(updateGroupRequest(this.new_group));
            this.save_state = 'saved';
            this.back();
        }
    }

    ngOnDestroy() {
        if (this.groupsSubscription) {
            this.groupsSubscription.unsubscribe();
        }

        if (this.applicationStateSubscription) {
            this.applicationStateSubscription.unsubscribe();
        }
    }

    async addNewWorkflowWithType(id: string) {
        const modal = await this.modalController.create({
            component: AddWorkflowPage,
            componentProps: {
                workflow_type_id: id
            }
        });

        return await modal.present();
    }

    getQuestionInputData(e: any) {
        this.new_group.customFields[e.customFieldId] = e.data;
    }

}

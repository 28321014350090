import { NormalizedModel, Synchronizable } from '../../../normalized-model';

export const SELECT_GROUP_TYPE_ACTION = 'SELECT_ACTION';
export const UN_SELECT_GROUP_TYPE_ACTION = 'UN_SELECT_ACTION';
export const RE_ORDER_GROUP_TYPE_ACTIONS = 'RE_ORDER_ACTIONS';
export const ADD_GROUP_TYPE_ACTION = 'ADD_ACTION';
export const INSTANTIATE_GROUP_TYPE_ACTION = 'INSTANTIATE_ACTION';
export const DELETE_GROUP_TYPE_ACTION = 'DELETE_ACTION';
export const UPDATE_GROUP_TYPE_ACTION = 'UPDATE_ACTION';

export const UPDATE_GROUP_TYPE_ACTIONS_DATA = 'UPDATE_WORKFLOW_ACTIONS_DATA';
export const SYNCHRONIZE_GROUP_TYPE_ACTIONS_DATA = 'SYNCHRONIZE_WORKFLOW_ACTIONS_DATA';
export const CLEAR_GROUP_TYPE_ACTIONS_DELTA = 'CLEAR_WORKFLOWS_DELTA';

export interface INewGroupTypeActionData {
    name: string;
    icon: string,
    workflowType?: string,
}

export interface IUpdateableGroupTypeActionData extends INewGroupTypeActionData {
    id: string,
}

export interface IGroupTypeAction extends IUpdateableGroupTypeActionData, Synchronizable {
    archived?: boolean,
    parentId: string,
}

export interface GroupTypeActionState extends NormalizedModel<IGroupTypeAction> {
    selected: string|undefined,
}


export interface SelectGroupTypeActionAction {
    type: typeof SELECT_GROUP_TYPE_ACTION,
    id: string,
}

export interface UnSelectGroupTypeActionAction {
    type: typeof UN_SELECT_GROUP_TYPE_ACTION,
}

export interface ReOrderGroupTypeActionAction {
    type: typeof RE_ORDER_GROUP_TYPE_ACTIONS,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddGroupTypeActionAction {
    type: typeof ADD_GROUP_TYPE_ACTION,
    payload: IGroupTypeAction,
    parentId: string,
    currentTime: string,
}

export interface InstantiateGroupTypeActionAction {
    type: typeof INSTANTIATE_GROUP_TYPE_ACTION,
    payload: IGroupTypeAction,
    parentId: string,
    currentTime: string,
}

export interface DeleteGroupTypeActionAction {
    type: typeof DELETE_GROUP_TYPE_ACTION,
    id: string,
    parentId: string,
    currentTime: string,
}

export interface UpdateGroupTypeActionAction {
    type: typeof UPDATE_GROUP_TYPE_ACTION,
    payload: IUpdateableGroupTypeActionData,
    currentTime: string,
}

export interface UpdateGroupTypeActionsData {
    type: typeof UPDATE_GROUP_TYPE_ACTIONS_DATA,
    data: Array<IGroupTypeAction>,
}

export interface SynchronizeGroupTypeActionsData {
    type: typeof SYNCHRONIZE_GROUP_TYPE_ACTIONS_DATA,
    data: Array<IGroupTypeAction>,
}

export interface ClearGroupTypeActionsDelta {
    type: typeof CLEAR_GROUP_TYPE_ACTIONS_DELTA,
}

export type GroupTypeActionActions = SelectGroupTypeActionAction | UnSelectGroupTypeActionAction | ReOrderGroupTypeActionAction | AddGroupTypeActionAction | InstantiateGroupTypeActionAction | DeleteGroupTypeActionAction | UpdateGroupTypeActionAction | UpdateGroupTypeActionsData | SynchronizeGroupTypeActionsData | ClearGroupTypeActionsDelta;
import { StructureActionTypes, UPDATE_STRUCTURE_DATA } from '../types'
import { ADD_ROLE, DELETE_ROLE, UPDATE_ROLE, SELECT_ROLE, UN_SELECT_ROLE, SELECT_ROLE_CUSTOM_FIELD, UN_SELECT_ROLE_CUSTOM_FIELD, ADD_ROLE_CUSTOM_FIELD, DELETE_ROLE_CUSTOM_FIELD, UPDATE_ROLE_CUSTOM_FIELD, SELECT_ROLE_CUSTOM_FIELD_OPTION, UN_SELECT_ROLE_CUSTOM_FIELD_OPTION, RE_ORDER_ROLE_CUSTOM_FIELD_OPTION, ADD_ROLE_CUSTOM_FIELD_OPTION, DELETE_ROLE_CUSTOM_FIELD_OPTION, UPDATE_ROLE_CUSTOM_FIELD_OPTION, RoleState, UPDATE_ROLE_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_ROLE_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_ROLE_CUSTOM_FIELD_PIECE, REGISTER_ROLE_CUSTOM_FIELD_VARIABLE, IRole, UPDATE_ROLES_DATA, UPDATE_ROLE_CUSTOM_FIELDS_DATA, UPDATE_ROLE_CUSTOM_FIELD_OPTIONS_DATA, SYNCHRONIZE_ROLES_DATA, CLEAR_ROLES_DELTA, SYNCHRONIZE_ROLE_CUSTOM_FIELDS_DATA, SYNCHRONIZE_ROLE_CUSTOM_FIELD_OPTIONS_DATA, INSTANTIATE_ROLE, INSTANTIATE_ROLE_CUSTOM_FIELD, INSTANTIATE_ROLE_CUSTOM_FIELD_OPTION } from './types';
import { UN_SELECT_PROJECT, SELECT_PROJECT } from '../project/types';
import { UN_SELECT_LEVEL, SELECT_LEVEL } from '../level/types';
import { selectCustomField, unSelectCustomField, addCustomField, deleteCustomField, updateCustomField, selectCustomFieldOption, unSelectCustomFieldOption, reOrderCustomFieldOptions, addCustomFieldOption, deleteCustomFieldOption, updateCustomFieldOption, setIsolatedPieceForCustomField, removeIsolatedPieceForCustomField, updateStartPieceForCustomField, registerVariableForCustomField, updateCustomFields, updateCustomFieldOptions, clearDeltaForCustomFields, synchronizeCustomFields, synchronizeCustomFieldOptions } from '../../custom-fields';
import { addEntity, updateEntity, deleteEntity, updateEntries, synchronizeEntries, clearDelta, synchronizeCustomFieldReverseLinks, synchronizeCustomFieldOptionReverseLinks } from '../../normalized-model';

const initialState: RoleState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],
    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    customFields: {
        byId: {},
        allFields: [],
    },
    customFieldOptions: {
        byId: {},
        allOptions: [],
    },
    selectedField: undefined,
    selectedOption: undefined,
    createdCustomFieldIds: new Set(),
    updatedCustomFieldIds: new Set(),
    deletedCustomFieldIds: new Set(),
    createdCustomFieldOptionIds: new Set(),
    updatedCustomFieldOptionIds: new Set(),
    deletedCustomFieldOptionIds: new Set(),

    reOrderedCustomFields: {},
    reOrderedCustomFieldOptions: {},
}

export function rolesReducer(state = initialState, action: StructureActionTypes): RoleState {
    let newState: RoleState;
    switch(action.type) {

        // PROJECT ACTIONS:

        case SELECT_PROJECT:
        case UN_SELECT_PROJECT:
            return {
                ...state,
                selected: undefined,
            }
        
        // LEVEL ACTIONS:

        case SELECT_LEVEL:
        case UN_SELECT_LEVEL:
            return {
                ...state,
                selected: undefined,
            }
        
        // ROLE ACTIONS
        
        case SELECT_ROLE:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_ROLE:
            return {
                ...state,
                selected: undefined,
            }

        case ADD_ROLE:
            return addEntity<RoleState, IRole>(state, action.payload);

        case INSTANTIATE_ROLE:
            return addEntity<RoleState, IRole>(state, action.payload);
        
        case UPDATE_ROLE:
            return updateEntity<RoleState, IRole>(state, action.payload, action.currentTime);
        
        case DELETE_ROLE:
            state = deleteEntity<RoleState, IRole>(state, action.id, action.currentTime);
            return state;
        
        case SELECT_ROLE_CUSTOM_FIELD:
            return selectCustomField<RoleState>(state, action.id);
        
        case UN_SELECT_ROLE_CUSTOM_FIELD:
            return unSelectCustomField<RoleState>(state);


        case ADD_ROLE_CUSTOM_FIELD:
                newState = {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.roleId]: {
                            ...state.byId[action.roleId],
                            customFields: state.byId[action.roleId].customFields.concat([action.payload.id]),
                        }
                    },
                    reOrderedCustomFields: {
                        ...state.reOrderedCustomFields,
                        [action.roleId]: state.byId[action.roleId].customFields.concat([action.payload.id]),
                    },
                }
                return addCustomField<RoleState>(newState, action.payload, action.roleId, action.currentTime);


        case INSTANTIATE_ROLE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.roleId]: {
                        ...state.byId[action.roleId],
                        customFields: state.byId[action.roleId].customFields.concat([action.payload.id]),
                    }
                }
            }
            return addCustomField<RoleState>(newState, action.payload, action.roleId, action.currentTime);
            
        case DELETE_ROLE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.roleId]: {
                        ...state.byId[action.roleId],
                        customFields: state.byId[action.roleId].customFields.filter(customFieldId => customFieldId !== action.id),
                    }
                },
                reOrderedCustomFields: {
                    ...state.reOrderedCustomFields,
                    [action.roleId]: state.byId[action.roleId].customFields.filter(customFieldId => customFieldId !== action.id),
                },
            }
            return deleteCustomField<RoleState>(newState, action.id, action.currentTime);
        
        case UPDATE_ROLE_CUSTOM_FIELD:
            return updateCustomField<RoleState>(state, action.payload, action.currentTime);


        case UPDATE_ROLE_CUSTOM_FIELD_START_PIECE:
            return updateStartPieceForCustomField<RoleState>(state, action.customFieldId, action.payload, action.currentTime);
    
        case SET_ISOLATED_ROLE_CUSTOM_FIELD_PIECE:
            return setIsolatedPieceForCustomField<RoleState>(state, action.customFieldId, action.payload, action.currentTime);

        case REMOVE_ISOLATED_ROLE_CUSTOM_FIELD_PIECE:
            return removeIsolatedPieceForCustomField<RoleState>(state, action.customFieldId, action.pieceId, action.currentTime);

        case REGISTER_ROLE_CUSTOM_FIELD_VARIABLE:
            return registerVariableForCustomField<RoleState>(state, action.customFieldId, action.variableId, action.currentTime);
        
        
        case SELECT_ROLE_CUSTOM_FIELD_OPTION:
            return selectCustomFieldOption<RoleState>(state, action.id);
        
        case UN_SELECT_ROLE_CUSTOM_FIELD_OPTION:
            return unSelectCustomFieldOption<RoleState>(state);

        case RE_ORDER_ROLE_CUSTOM_FIELD_OPTION:
            return reOrderCustomFieldOptions<RoleState>(state, action.sourceIndex, action.destinationIndex, action.parentId);

        case ADD_ROLE_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<RoleState>(state, action.payload, action.parentId, action.currentTime);

        case INSTANTIATE_ROLE_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<RoleState>(state, action.payload, action.parentId, action.currentTime);
        
        case DELETE_ROLE_CUSTOM_FIELD_OPTION:
            return deleteCustomFieldOption<RoleState>(state, action.id, action.parentId, action.currentTime);

        case UPDATE_ROLE_CUSTOM_FIELD_OPTION:
            return updateCustomFieldOption<RoleState>(state, action.payload, action.currentTime);

        case UPDATE_ROLES_DATA:
            return updateEntries<RoleState, IRole>(state, action.data);

        case UPDATE_ROLE_CUSTOM_FIELDS_DATA:
            return updateCustomFields<RoleState>(state, action.data);

        case UPDATE_ROLE_CUSTOM_FIELD_OPTIONS_DATA:
            return updateCustomFieldOptions<RoleState>(state, action.data);

        case SYNCHRONIZE_ROLES_DATA:
            return synchronizeEntries<RoleState, IRole>(state, action.data);

        case SYNCHRONIZE_ROLE_CUSTOM_FIELDS_DATA:
            newState = synchronizeCustomFields<RoleState>(state, action.data);
            newState = synchronizeCustomFieldReverseLinks<RoleState>(newState, action.data);
            return newState;

        case SYNCHRONIZE_ROLE_CUSTOM_FIELD_OPTIONS_DATA:
            newState = synchronizeCustomFieldOptions<RoleState>(state, action.data);
            newState = synchronizeCustomFieldOptionReverseLinks<RoleState>(newState, action.reOrderedCustomFieldOptions);
            return newState;

        case CLEAR_ROLES_DELTA:
            newState = clearDelta<RoleState, IRole>(state);
            newState = clearDeltaForCustomFields<RoleState>(newState);
            return newState;

        // Structure actions

        case UPDATE_STRUCTURE_DATA:
            return {
                ...action.data.roles,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
                createdCustomFieldIds: state.createdCustomFieldIds,
                updatedCustomFieldIds: state.updatedCustomFieldIds,
                deletedCustomFieldIds: state.deletedCustomFieldIds,
                createdCustomFieldOptionIds: state.createdCustomFieldOptionIds,
                updatedCustomFieldOptionIds: state.updatedCustomFieldOptionIds,
                deletedCustomFieldOptionIds: state.deletedCustomFieldOptionIds,
            }

        default:
            return state
    }
}
import { ADD_REPORT_TYPE, UPDATE_REPORT_TYPE, DELETE_REPORT_TYPE, IUpdateableReportTypeData, REGISTER_REPORT_TYPE_VARIABLE, UPDATE_REPORT_TYPE_START_PIECE, SET_ISOLATED_REPORT_TYPE_PIECE, REMOVE_ISOLATED_REPORT_TYPE_PIECE, AddReportTypeAction, SELECT_REPORT_TYPE, UN_SELECT_REPORT_TYPE, RE_ORDER_REPORT_TYPES, INewReportTypeData, UpdateReportTypeAction, DeleteReportTypeAction, UpdateReportTypeStartPieceAction, SetIsolatedReportTypePieceAction, RemoveIsolatedReportTypePieceAction, RegisterReportTypeVariableAction, IReportType, InstantiateReportTypeAction, INSTANTIATE_REPORT_TYPE } from './types';
import moment from 'moment';
import uuid from 'uuid';
import { PiecePositionState } from '../../../helpers/common-types';

export function selectReportType(id: string) {
    return {
        type: SELECT_REPORT_TYPE,
        id,
    }
}

export function unSelectReportType() {
    return {
        type: UN_SELECT_REPORT_TYPE,
    }
}

export function reOrderReportTypes(sourceIndex: number, destinationIndex: number) {
    return {
        type: RE_ORDER_REPORT_TYPES,
        sourceIndex,
        destinationIndex,
    }
}

export function addReportType(payload: INewReportTypeData): AddReportTypeAction {

    const now = moment.utc().format();

    return {
        type: ADD_REPORT_TYPE,
        payload: {
            id: uuid.v4(),
            createdTime: now,
            lastUpdatedTime: now,
            variables: [
                payload.seedUserVariable,
                payload.seedStartDateVariable,
                payload.seedEndDateVariable,
            ],
            isolatedPieces: [],
            ...payload,
        },
        currentTime: now,
    }
}

export function instantiateReportType(payload: IReportType): InstantiateReportTypeAction {

    const now = moment.utc().format();

    return {
        type: INSTANTIATE_REPORT_TYPE,
        payload,
        currentTime: now,
    }
}

export function updateReportType(payload: IUpdateableReportTypeData): UpdateReportTypeAction {
    return {
        type: UPDATE_REPORT_TYPE,
        payload,
        currentTime: moment.utc().format(),
    }
}

export function deleteReportType(id: string): DeleteReportTypeAction {
    return {
        type: DELETE_REPORT_TYPE,
        id,
        currentTime: moment.utc().format(),
    }
}

export function updateReportTypeStartPiece(payload: PiecePositionState, reportTypeId: string): UpdateReportTypeStartPieceAction {
    return {
        type: UPDATE_REPORT_TYPE_START_PIECE,
        payload,
        reportTypeId
    }
}

export function setIsolatedReportTypePiece(payload: PiecePositionState, reportTypeId: string): SetIsolatedReportTypePieceAction {
    return {
        type: SET_ISOLATED_REPORT_TYPE_PIECE,
        payload,
        reportTypeId
    }
}

export function removeIsolatedReportTypePiece(pieceId: string, reportTypeId: string): RemoveIsolatedReportTypePieceAction {
    return {
        type: REMOVE_ISOLATED_REPORT_TYPE_PIECE,
        pieceId,
        reportTypeId
    }
}

export function registerReportTypeVariable(variableId: string, reportTypeId: string): RegisterReportTypeVariableAction {
    return {
        type: REGISTER_REPORT_TYPE_VARIABLE,
        variableId,
        reportTypeId,
    }
}
import { SELECT_MEMBER_TYPE_ACTION, UN_SELECT_MEMBER_TYPE_ACTION, ADD_MEMBER_TYPE_ACTION, DELETE_MEMBER_TYPE_ACTION, UPDATE_MEMBER_TYPE_ACTION, RE_ORDER_MEMBER_TYPE_ACTIONS, MemberTypeActionState, UPDATE_MEMBER_TYPE_ACTIONS_DATA, SYNCHRONIZE_MEMBER_TYPE_ACTIONS_DATA, CLEAR_MEMBER_TYPE_ACTIONS_DELTA, IMemberTypeAction, INSTANTIATE_MEMBER_TYPE_ACTION } from './types';
import { reOrderList } from '../../../../helpers/utilities';
import { updateEntries, synchronizeEntries, clearDelta, addEntity, updateEntity, deleteEntity } from '../../../normalized-model';
import { MemberActionTypes } from '../../types';

export const initialState: MemberTypeActionState = {  
    byId: {},
    allEntries: [],
    filteredEntries: [],

    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),
};

export function actionsReducer(state = initialState, action: MemberActionTypes): MemberTypeActionState {
    switch(action.type) {

        case SELECT_MEMBER_TYPE_ACTION:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_MEMBER_TYPE_ACTION:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_MEMBER_TYPE_ACTIONS:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
            };

        case ADD_MEMBER_TYPE_ACTION:
            return addEntity<MemberTypeActionState, IMemberTypeAction>(state, action.payload);

        case INSTANTIATE_MEMBER_TYPE_ACTION:
            return addEntity<MemberTypeActionState, IMemberTypeAction>(state, action.payload);
        
        case UPDATE_MEMBER_TYPE_ACTION:
            return updateEntity<MemberTypeActionState, IMemberTypeAction>(state, action.payload, action.currentTime);

        case DELETE_MEMBER_TYPE_ACTION:
            return deleteEntity<MemberTypeActionState, IMemberTypeAction>(state, action.id, action.currentTime);

        case UPDATE_MEMBER_TYPE_ACTIONS_DATA:
            return updateEntries<MemberTypeActionState, IMemberTypeAction>(state, action.data);

        case SYNCHRONIZE_MEMBER_TYPE_ACTIONS_DATA:
            return synchronizeEntries<MemberTypeActionState, IMemberTypeAction>(state, action.data);

        case CLEAR_MEMBER_TYPE_ACTIONS_DELTA:
            return clearDelta<MemberTypeActionState, IMemberTypeAction>(state);
        

        default:
            return state
    }
}
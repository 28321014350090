import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { translatePhrase } from 'src/shared/helpers/translation';
import * as mapbox from 'mapbox-gl';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HttpClient } from '@angular/common/http';
import { ToastService, ToastType } from '../services/toast.service';
import { LoadingController } from '@ionic/angular';
import { IntroductionService } from '../services/introduction.service';

@Component({
    selector: 'app-location-selector',
    templateUrl: './location-selector.page.html',
    styleUrls: ['./location-selector.page.scss'],
})
export class LocationSelectorPage implements OnInit {
    map: any;
    accessToken = 'pk.eyJ1Ijoia2oxMzUyIiwiYSI6ImNrYnRkam1lMjA4dm0ycm92cjV2ejhtaWUifQ.xYBfm6r_mpvVcNSkkd99Ow';
    corodinates: any;
    searchInput: string = '';
    searchResults: any = [];

    constructor(
        private modalController: ModalController,
        private geolocation: Geolocation,
        private http: HttpClient,
        private toastService: ToastService,
        public loadingController: LoadingController,
        private navParams: NavParams,
        private introductionService: IntroductionService,
    ) { }

    ngOnInit() {
        setTimeout(() => {
            if (this.navParams.get('lat') && this.navParams.get('lng')) {
                this.loadMapBox(this.navParams.get('lat'), this.navParams.get('lng'));
                this.introductionService.setPageUrl('maps');
            } else {
                this.locateMe();
            }
        }, 100);
    }

    back() {
        this.modalController.dismiss({
            coordinates: this.corodinates
        });
    }

    translate(phrase: string) {
        return translatePhrase(phrase.trim());
    }

    loadMapBox(lat, lng) {
        this.searchInput = '';
        this.searchResults = [];

        this.corodinates = {
            lng: lng,
            lat: lat
        };

        var map = new mapbox.Map({
            container: 'map',
            zoom: 16,
            center: [lng, lat],
            style: 'mapbox://styles/mapbox/streets-v11',
            accessToken: this.accessToken,
            preserveDrawingBuffer: true,
        })

        var marker = new mapbox.Marker()
            .setLngLat([lng, lat])
            .addTo(map);


        map.on('click', (e) => {
            // The event object (e) contains information like the
            // coordinates of the point on the map that was clicked.
            this.loadMapBox(e.lngLat.lat, e.lngLat.lng);
        });
    }

    locateMe() {
        this.presentLoading();
        this.geolocation.getCurrentPosition().then((resp) => {
            this.loadingController.dismiss();
            this.loadMapBox(resp.coords.latitude, resp.coords.longitude);
            this.introductionService.setPageUrl('maps');
        }).catch((error) => {
            this.loadingController.dismiss();
            console.log(error);
            this.toastService.presentToastWithOptions('Error getting location', ToastType.ERROR);
            this.introductionService.setPageUrl('maps');
        });
    }

    async getLocationByPOI(searchText: string) {
        return await this.http.get('https://api.mapbox.com/geocoding/v5/mapbox.places/'
            + searchText
            + '.json?country=IN'
            + '&&types=poi&&access_token='
            + this.accessToken)
            .toPromise();
    }

    searchMap() {
        if (this.searchInput) {
            this.getLocationByPOI(this.searchInput).then((data: any) => {
                this.searchResults = data.features;
            }, () => {
                this.toastService.presentToastWithOptions('No Result found', ToastType.INFO);
            });
        } else {
            this.searchResults = [];
        }
    }

    async presentLoading() {
        const loading = await this.loadingController.create({
            message: this.translate('Loading') + '...',
            mode: 'ios'
        });
        await loading.present();
    }

    showMapsIntro() {
        let introduction_pages = JSON.parse(localStorage.introduction_pages);
        if (introduction_pages.includes('maps')) {
            introduction_pages.splice(introduction_pages.indexOf('maps'), 1);
            localStorage.setItem('introduction_pages', JSON.stringify(introduction_pages));
            this.introductionService.setPageUrl('maps');
        }
    }

}

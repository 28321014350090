export const UPDATE_MY_WIDGETS = 'UPDATE_MY_WIDGETS';


export const UPDATE_WIDGET_CONFIGURATIONS_DATA = 'UPDATE_WIDGET_CONFIGURATIONS_DATA';
export const SYNCHRONIZE_WIDGET_CONFIGURATIONS_DATA = 'SYNCHRONIZE_WIDGET_CONFIGURATIONS_DATA';
export const CLEAR_WIDGET_CONFIGURATIONS_DELTA = 'CLEAR_WIDGET_CONFIGURATIONS_DELTA';

export const UPDATE_WIDGET_SIZE = 'UPDATE_WIDGET_SIZE';
export const UPDATE_WIDGET_POSITION = 'UPDATE_WIDGET_POSITION';
export const REGISTER_WIDGET_CREATION = 'REGISTER_WIDGET_CREATION';
export const UNREGISTER_WIDGET_CREATION = 'UNREGISTER_WIDGET_CREATION';

export interface IWidgetConfiguration {
    widgetId: string,
    width?: number,
    height?: number,
    x?: number,
    y?: number,
}

export interface IWidgetConfigurationState {
    byId: {
        [widgetId: string]: IWidgetConfiguration,
    },
    myWidgets: Array<string>,

    updatedIds: Set<string>,
    createdConfigurations: Set<string>,
    deletedConfigurations: Set<string>,
}

export interface IUpdateMyWidgetsAction {
    type: typeof UPDATE_MY_WIDGETS,
    widgetIds: Array<string>,
}

interface UpdateWidgetSizeAction {
    type: typeof UPDATE_WIDGET_SIZE,
    width: number,
    height: number,
    widgetId: string,
}

interface UpdateWidgetPositionAction {
    type: typeof UPDATE_WIDGET_POSITION,
    x: number,
    y: number,
    widgetId: string,
}

interface RegisterWidgetCreationAction {
    type: typeof REGISTER_WIDGET_CREATION,
    widgetId: string,
}

interface UnregisterWidgetCreationAction {
    type: typeof UNREGISTER_WIDGET_CREATION,
    widgetId: string,
}

export interface UpdateWidgetConfigurationsData {
    type: typeof UPDATE_WIDGET_CONFIGURATIONS_DATA,
    data: Array<IWidgetConfiguration>,
}

export interface SynchronizeWidgetConfigurationsData {
    type: typeof SYNCHRONIZE_WIDGET_CONFIGURATIONS_DATA,
    data: Array<IWidgetConfiguration>,
}

export interface ClearWidgetConfigurationsDelta {
    type: typeof CLEAR_WIDGET_CONFIGURATIONS_DELTA,
}

export type WidgetActionTypes = UpdateWidgetSizeAction | IUpdateMyWidgetsAction | UpdateWidgetPositionAction | RegisterWidgetCreationAction | UnregisterWidgetCreationAction | UpdateWidgetConfigurationsData
 | SynchronizeWidgetConfigurationsData
 | ClearWidgetConfigurationsDelta;
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { translatePhrase } from 'src/shared/helpers/translation';
import { MemberState } from 'src/shared/store/members/types';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import * as moment from 'moment';
import { ModalController } from '@ionic/angular';
import { CountrySelectorPage } from '../../country-selector/country-selector.page';

@Component({
	selector: 'app-question',
	templateUrl: './question.component.html',
	styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit {
    @Input() membersData: MemberState;
    @Input() custom_field: any;
    @Input() current_member: any;
    @Output() inputData: any = new EventEmitter();
    isExpanded: boolean = false;
    searchText: string = '';
    questionChoices: Array<any>;
    selectedMultipleChoice: Array<any> = [];
    fileInput: string = '';
    country_code: string = '+91';

	constructor(
        private geolocation: Geolocation,
        public modalController: ModalController,
    ) { }

	ngOnInit() {
        this.questionChoices = JSON.parse(JSON.stringify(this.custom_field.choices));

        if (this.custom_field.type === 'MULTI_SELECT') {
            this.selectedMultipleChoice = this.current_member.customFields[this.custom_field.id] ? this.current_member.customFields[this.custom_field.id] : [];
        }

        if (this.custom_field.type === 'FILE') {
            this.fileInput = this.current_member.customFields[this.custom_field.id] ? this.current_member.customFields[this.custom_field.id] : '';
        }
    }

    searchChoices() {
        if (this.searchText.length > 0) {
            this.questionChoices = this.custom_field.choices.filter((choice: any) => this.getCustomFieldOptionDetails(choice).toLowerCase().includes(this.searchText.toLowerCase()));
        } else {
            this.questionChoices = JSON.parse(JSON.stringify(this.custom_field.choices));
        }
    }

    translate(phrase: string) {
        return translatePhrase(phrase.trim());
    }

    getCustomFieldOptionDetails(id: string) {
		return this.membersData.types.customFieldOptions.byId[id].name;
	}

    emitInputData(customFieldId: string, data: any) {
        this.inputData.emit({
            customFieldId: customFieldId,
            data: data
        });
    }

    updateDate(e: string) {
        if (moment(e.toString(), 'DD-MM-YYYY', true).isValid()) {
            this.emitInputData(this.custom_field.id, moment(e.toString(), 'DD-MM-YYYY').format('YYYY-MM-DD'));
        }
    }

    getLocation(id: string) {
		this.geolocation.getCurrentPosition().then((resp) => {
			this.current_member.customFields[id] = resp.coords.latitude + ' ' + resp.coords.longitude;
            this.emitInputData(id, this.current_member.customFields[id]);
		}).catch(() => {
			alert(this.translate('Error getting location'));
		});
	}

    selectMultipleChoice(id: string, choice: string) {
        if (this.selectedMultipleChoice.includes(choice)) {
            this.selectedMultipleChoice.splice(this.selectedMultipleChoice.indexOf(choice), 1);
        } else {
            this.selectedMultipleChoice.push(choice);
        }

        this.emitInputData(id, this.selectedMultipleChoice);
    }

    updateFile() {
        const fileInputId = 'file-' + this.custom_field.id;
        const fileInput: any = document.getElementById(fileInputId) as HTMLInputElement;

        if (!fileInput) {
            throw new Error('The file field does not exist');
        }

        if (fileInput.files && fileInput.files.length > 0) {
            const fileToUpload = fileInput.files[0];
            var formData = new FormData();
            formData.append('file', fileToUpload);

            const cacheAvailable = 'caches' in self;

            if (cacheAvailable) {
                caches.open('diceflow-files').then((cache) => {
                    let filename = ('/file-f123f-' + fileToUpload.name.toString()).toString();
                    cache.put(filename, new Response(formData)).then(() => {
                        this.fileInput = filename;
                        this.emitInputData(this.custom_field.id, this.fileInput);
                    }, () => {
                        alert("No memory available to store the file locally!");
                    });
                });
            } else {
                alert("No memory available to store the file locally!, We have cleared the cache for you. Please try again");
                caches.delete('diceflow-files');
            }
        } else {
            this.fileInput = '';
            this.emitInputData(this.custom_field.id, this.fileInput);
        }
    }

    getFileName(str: string) {
        if (str) {
            if (str.indexOf('-f123f-') >= 0) {
                return str.split('-f123f-')[1];
            } else {
                return '<a href=' + str + ' target="_blank"> Link <a>';
            }
        } else {
            return 'Please select a file';
        }
    }

    async selectCountryModal(custom_field_id: string) {
		if (this.current_member.customFields[custom_field_id] === undefined) {
			const modal = await this.modalController.create({
				component: CountrySelectorPage,
				componentProps: {
					countryCode: this.country_code
				}
			});

			modal.onDidDismiss().then((data) => {
				this.country_code = data.data;
				this.current_member.customFields[custom_field_id] = this.country_code + ' ';
			});

			return await modal.present();
		} else if (this.current_member.customFields[custom_field_id].toString().split(' ').length < 2) {
			const modal = await this.modalController.create({
				component: CountrySelectorPage,
				componentProps: {
					countryCode: this.country_code
				}
			});

			modal.onDidDismiss().then((data) => {
				this.country_code = data.data;
				this.current_member.customFields[custom_field_id] = this.country_code + ' ';
			});

			return await modal.present();
		}
	}

}

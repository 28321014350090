import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { IWorkflow, WorkflowState } from '../../shared/store/workflows/types';
import { ApplicationState } from '../../shared/store/types';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { IUser, UserState } from '../../shared/store/users/types';
import { MemberState, IMember } from '../../shared/store/members/types';
import { IMemberType } from 'src/shared/store/members/types/types';
import { getReadableDataForCustomField } from 'src/shared/store/custom-fields';
import { CustomFieldValueType, CustomFieldOptionsDataType, CustomField, WorkflowTypeCustomField } from 'src/shared/store/custom-fields/types';
import { IGroup, GroupState } from 'src/shared/store/groups/types';
import { RoleState } from 'src/shared/store/structure/role/types';
import { StatisticalDetailsPage } from '../statistical-details/statistical-details.page';
import { ModalController } from '@ionic/angular';
import { translatePhrase } from 'src/shared/helpers/translation';
import { getShowData } from 'src/shared/helpers/show-data';
import { WidgetData } from 'src/shared/store/widgets/types';

@Component({
    selector: 'app-show-widget',
    templateUrl: './show-widget.component.html',
    styleUrls: ['./show-widget.component.scss'],
})
export class ShowWidgetComponent implements OnInit, OnDestroy {
    @select('roles') rolesDataSource: Observable<RoleState>;
    @select(state => state) applicationStateSource: Observable<ApplicationState>;
    @Input() type: string;
    @Input() typeId: string;
    @Input() customFields: Array<string>;
    @Input() exportingCustomFields?: Array<string>;
    @Input() systemFields?: Array<string>;
    @Input() exportingSystemFields?: Array<string>;
    @Input() entityIds: Array<string>;
    @Input() selectedWidgetType: 'table'|'donut'|'line'|'bar'|'message';
    @Input() aggregation: string;
    @Input() xAxis?: string;
    @Input() yAxis?: string;
    @Input() yAxisAggregation?: 'sum'|'average';
	@Input() header: string;
	@Input() widgetId: string;
    @Input() showEntities: boolean;
    
    applicationState: ApplicationState;
    workflowData: WorkflowState;
    usersData: UserState;
    rolesData: RoleState;
    membersData: MemberState;
	groupsData: GroupState;
    memberTypes: Array<IMemberType>;
    users: Array<IUser> = [];
    chartLabels: Array<string>;
    chartData: Array<number>;
    maxValue: number;
    csvData: any;
    applicationStateSubscription: Subscription;
	aggregationValue: number = 0;
    entityType: string = '';
    isAggregated = false;

    tableHeadings: any;
    tableEntries: any;

    constructor(
        public modalController: ModalController,
    ) {
        if (!this.selectedWidgetType) {
            this.selectedWidgetType = 'table';            
        }
    }

	translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

    ngOnInit() {
        this.applicationStateSubscription = this.applicationStateSource.subscribe((applicationState) => {
			this.applicationState = applicationState;
			this.workflowData = this.applicationState.workflows;
			this.rolesData = this.applicationState.structure.roles;
			this.usersData = this.applicationState.users;
			this.membersData = this.applicationState.members;
            this.groupsData = this.applicationState.groups;

			this.getChartValues();
		});
    }

	getWorkflowCustomFieldValue(workflow: IWorkflow, customField: WorkflowTypeCustomField) {
        const workflowProcessState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
        let customFieldValue = workflowProcessState.customFields[customField.id] as CustomFieldValueType;

        return getReadableDataForCustomField(customFieldValue, customField, workflow.id, 'workflow');
    }

	getCommonCustomFieldValue(entity: IUser|IMember|IGroup, type: 'user'|'member'|'group', customField: CustomField, optionsData: CustomFieldOptionsDataType) {
        let customFieldValue = entity.customFields[customField.id];

        return getReadableDataForCustomField(customFieldValue, customField, entity.id, type);
    }

    async getChartValues() {
        
        let showData: WidgetData;

        if (this.widgetId) {
            showData = {
                headings: [],
                entries: [],
            
                chartLabels: [],
                chartData: [],
            
                csvData: [],

                entityIds: [],
            
                xAxisFieldName: '',
                yAxisFieldName: '',
            };

            const cachedWidgetData = this.applicationState.widgets.cachedWidgetData[this.widgetId];

            if (cachedWidgetData) {
                showData = cachedWidgetData;
            }
        } else {
            showData = getShowData(
                this.applicationState,
                this.entityIds,
                this.type,
                this.customFields,
                this.xAxis,
                this.yAxis,
                this.yAxisAggregation,
                this.typeId,
                this.systemFields,
            );
        }

        this.chartLabels = showData.chartLabels;
        this.chartData = showData.chartData;
        this.maxValue = Math.max(...showData.chartData);
        this.csvData = showData.csvData;
        this.tableHeadings = showData.headings;
        this.tableEntries = showData.entries;

		this.isAggregated = this.aggregation && this.aggregation !== 'none';
        this.aggregationValue = 0;

        if (this.isAggregated) {
            const dataToAggregate = showData.csvData.map(csvRow => csvRow[2]).slice(1);
            let numericValues: Array<number>;

            switch(this.aggregation) {
                case 'count':
                    this.aggregationValue = dataToAggregate.length;
                    break;
                case 'sum':
                    numericValues = dataToAggregate.map(value => Number(value));
                    this.aggregationValue = numericValues.reduce((sum, num) => {
                        return sum + num;
                    });
                    break;
                case 'average':
                    numericValues = dataToAggregate.map(value => Number(value));
                    this.aggregationValue = numericValues.reduce((sum, num) => {
                        return sum + num;
                    });
                    this.aggregationValue /= numericValues.length;
                    break;

                default:
                    throw new Error('Incorrect aggregation');
            }
        }
    }

    async statisticalDetails() {
        const modal = await this.modalController.create({
            component: StatisticalDetailsPage,
			componentProps: {
				header: this.header? this.header : this.entityType,
				type: this.type,
				typeId: this.typeId,
				customFields: this.customFields,
				entityIds: this.entityIds,
				widgetId: this.widgetId
			}
        });
        return await modal.present();
    }

    ngOnDestroy() {
        if (this.applicationStateSubscription) {
            this.applicationStateSubscription.unsubscribe();
        }
    }

}

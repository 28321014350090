import { ReOrderedListsForEntity, PiecePositionState } from '../../../helpers/common-types';
import { CustomFieldState, IUpdateableCustomFieldData, CustomField, IUpdateableFieldChoiceData, FieldChoice } from '../../custom-fields/types';
import { NormalizedModel, Synchronizable } from '../../normalized-model';

export const SELECT_ROLE = 'SELECT_ROLE';
export const UN_SELECT_ROLE = 'UN_SELECT_ROLE';
export const RE_ORDER_ROLES = 'RE_ORDER_ROLES';
export const ADD_ROLE_REQUEST = 'ADD_ROLE_REQUEST';
export const ADD_ROLE = 'ADD_ROLE';
export const INSTANTIATE_ROLE = 'INSTANTIATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';

export const SELECT_ROLE_CUSTOM_FIELD = 'SELECT_ROLE_CUSTOM_FIELD';
export const UN_SELECT_ROLE_CUSTOM_FIELD = 'UN_SELECT_ROLE_CUSTOM_FIELD';
export const ADD_ROLE_CUSTOM_FIELD = 'ADD_ROLE_CUSTOM_FIELD';
export const INSTANTIATE_ROLE_CUSTOM_FIELD = 'INSTANTIATE_ROLE_CUSTOM_FIELD';
export const DELETE_ROLE_CUSTOM_FIELD = 'DELETE_ROLE_CUSTOM_FIELD';
export const UPDATE_ROLE_CUSTOM_FIELD = 'UPDATE_ROLE_CUSTOM_FIELD';

export const UPDATE_ROLE_CUSTOM_FIELD_START_PIECE = 'UPDATE_ROLE_CUSTOM_FIELD_START_PIECE';
export const SET_ISOLATED_ROLE_CUSTOM_FIELD_PIECE = 'SET_ISOLATED_ROLE_CUSTOM_FIELD_PIECE';
export const REMOVE_ISOLATED_ROLE_CUSTOM_FIELD_PIECE = 'REMOVE_ISOLATED_ROLE_CUSTOM_FIELD_PIECE';
export const REGISTER_ROLE_CUSTOM_FIELD_VARIABLE = 'REGISTER_ROLE_CUSTOM_FIELD_VARIABLE';

export const SELECT_ROLE_CUSTOM_FIELD_OPTION = 'SELECT_ROLE_CUSTOM_FIELD_OPTION';
export const UN_SELECT_ROLE_CUSTOM_FIELD_OPTION = 'UN_SELECT_ROLE_CUSTOM_FIELD_OPTION';
export const RE_ORDER_ROLE_CUSTOM_FIELD_OPTION = 'RE_ORDER_ROLE_CUSTOM_FIELD_OPTION';
export const ADD_ROLE_CUSTOM_FIELD_OPTION = 'ADD_ROLE_CUSTOM_FIELD_OPTION';
export const INSTANTIATE_ROLE_CUSTOM_FIELD_OPTION = 'INSTANTIATE_ROLE_CUSTOM_FIELD_OPTION';
export const DELETE_ROLE_CUSTOM_FIELD_OPTION = 'DELETE_ROLE_CUSTOM_FIELD_OPTION';
export const UPDATE_ROLE_CUSTOM_FIELD_OPTION = 'UPDATE_ROLE_CUSTOM_FIELD_OPTION';

export const UPDATE_ROLES_DATA = 'UPDATE_ROLES_DATA';
export const UPDATE_ROLE_CUSTOM_FIELDS_DATA = 'UPDATE_ROLE_CUSTOM_FIELDS_DATA';
export const UPDATE_ROLE_CUSTOM_FIELD_OPTIONS_DATA = 'UPDATE_ROLE_CUSTOM_FIELD_OPTIONS_DATA';
export const SYNCHRONIZE_ROLES_DATA = 'SYNCHRONIZE_ROLES_DATA';
export const SYNCHRONIZE_ROLE_CUSTOM_FIELDS_DATA = 'SYNCHRONIZE_ROLE_CUSTOM_FIELDS_DATA';
export const SYNCHRONIZE_ROLE_CUSTOM_FIELD_OPTIONS_DATA = 'SYNCHRONIZE_ROLE_CUSTOM_FIELD_OPTIONS_DATA';
export const CLEAR_ROLES_DELTA = 'CLEAR_ROLES_DELTA';


export interface INewRoleData {
    name: string;
    thresholdDaysForMembers?: number;
    thresholdDaysForGroups?: number;
    thresholdDaysForWorkflows?: number;
    bulkActions?: boolean;
}

export interface IUpdateableRoleData extends INewRoleData {
    id: string;
}

export interface IRole extends IUpdateableRoleData, Synchronizable {
    archived?: boolean,
    level: string,
    customFields: Array<string>,
}

export interface RoleState extends NormalizedModel<IRole>, CustomFieldState {
    selected: string | undefined,
    reOrderedCustomFields: ReOrderedListsForEntity,
}

export interface SelectRoleAction {
    type: typeof SELECT_ROLE,
    id: string,
}

export interface UnSelectRoleAction {
    type: typeof UN_SELECT_ROLE,
}

export interface ReOrderRolesAction {
    type: typeof RE_ORDER_ROLES,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddRoleRequestAction {
    type: typeof ADD_ROLE_REQUEST,
    payload: INewRoleData,
    parentId: string,
}

export interface AddRoleAction {
    type: typeof ADD_ROLE,
    payload: IRole,
    parentId: string,
    currentTime: string,
}

export interface InstantiateRoleAction {
    type: typeof INSTANTIATE_ROLE,
    payload: IRole,
    parentId: string,
    currentTime: string,
}

export interface DeleteRoleAction {
    type: typeof DELETE_ROLE,
    id: string,
    parentId: string,
    currentTime: string,
}

export interface UpdateRoleAction {
    type: typeof UPDATE_ROLE,
    payload: IUpdateableRoleData,
    currentTime: string,
}


export interface SelectRoleCustomFieldAction {
    type: typeof SELECT_ROLE_CUSTOM_FIELD,
    id: string,
}

export interface UnSelectRoleCustomFieldAction {
    type: typeof UN_SELECT_ROLE_CUSTOM_FIELD,
}

export interface AddRoleCustomFieldAction {
    type: typeof ADD_ROLE_CUSTOM_FIELD,
    payload: IUpdateableCustomFieldData,
    roleId: string,
    currentTime: string,
}

export interface InstantiateRoleCustomFieldAction {
    type: typeof INSTANTIATE_ROLE_CUSTOM_FIELD,
    payload: CustomField,
    roleId: string,
    currentTime: string,
}

export interface DeleteRoleCustomFieldAction {
    type: typeof DELETE_ROLE_CUSTOM_FIELD,
    id: string,
    roleId: string,
    currentTime: string,
}

export interface UpdateRoleCustomFieldAction {
    type: typeof UPDATE_ROLE_CUSTOM_FIELD,
    payload: IUpdateableCustomFieldData,
    currentTime: string,
}


export interface UpdateRoleCustomFieldStartPieceAction {
    type: typeof UPDATE_ROLE_CUSTOM_FIELD_START_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
    currentTime: string,
}

export interface SetIsolatedRoleCustomFieldPieceAction {
    type: typeof SET_ISOLATED_ROLE_CUSTOM_FIELD_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
    currentTime: string,
}

export interface RemoveIsolatedRoleCustomFieldPieceAction {
    type: typeof REMOVE_ISOLATED_ROLE_CUSTOM_FIELD_PIECE,
    pieceId: string,
    customFieldId: string,
    currentTime: string,
}

export interface RegisterRoleCustomFieldVariableAction {
    type: typeof REGISTER_ROLE_CUSTOM_FIELD_VARIABLE,
    variableId: string,
    customFieldId: string,
    currentTime: string,
}


export interface SelectRoleCustomFieldOptionAction {
    type: typeof SELECT_ROLE_CUSTOM_FIELD_OPTION,
    id: string,
}

export interface UnSelectRoleCustomFieldOptionAction {
    type: typeof UN_SELECT_ROLE_CUSTOM_FIELD_OPTION,
}

export interface ReOrderRoleCustomFieldOptionAction {
    type: typeof RE_ORDER_ROLE_CUSTOM_FIELD_OPTION,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddRoleCustomFieldOptionAction {
    type: typeof ADD_ROLE_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
    parentId: string,
    currentTime: string,
}

export interface InstantiateRoleCustomFieldOptionAction {
    type: typeof INSTANTIATE_ROLE_CUSTOM_FIELD_OPTION,
    payload: FieldChoice,
    parentId: string,
    currentTime: string,
}

export interface DeleteRoleCustomFieldOptionAction {
    type: typeof DELETE_ROLE_CUSTOM_FIELD_OPTION,
    id: string,
    parentId: string,
    currentTime: string,
}

export interface UpdateRoleCustomFieldOptionAction {
    type: typeof UPDATE_ROLE_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
    currentTime: string,
}

export interface UpdateRolesData {
    type: typeof UPDATE_ROLES_DATA,
    data: Array<IRole>,
}

export interface UpdateRoleCustomFieldsData {
    type: typeof UPDATE_ROLE_CUSTOM_FIELDS_DATA,
    data: Array<CustomField>,
}

export interface UpdateRoleCustomFieldOptionsData {
    type: typeof UPDATE_ROLE_CUSTOM_FIELD_OPTIONS_DATA,
    data: Array<FieldChoice>,
}

export interface SynchronizeRolesData {
    type: typeof SYNCHRONIZE_ROLES_DATA,
    data: Array<IRole>,
}

export interface SynchronizeRoleCustomFieldsData {
    type: typeof SYNCHRONIZE_ROLE_CUSTOM_FIELDS_DATA,
    data: Array<CustomField>,
}

export interface SynchronizeRoleCustomFieldOptionsData {
    type: typeof SYNCHRONIZE_ROLE_CUSTOM_FIELD_OPTIONS_DATA,
    data: Array<FieldChoice>,
    reOrderedCustomFieldOptions: ReOrderedListsForEntity,
}

export interface ClearRolesDelta {
    type: typeof CLEAR_ROLES_DELTA,
}

type CustomFieldActions = SelectRoleCustomFieldAction | UnSelectRoleCustomFieldAction | AddRoleCustomFieldAction | InstantiateRoleCustomFieldAction | DeleteRoleCustomFieldAction | UpdateRoleCustomFieldAction;

type CustomFieldChoiceActions = SelectRoleCustomFieldOptionAction | UnSelectRoleCustomFieldOptionAction | ReOrderRoleCustomFieldOptionAction | AddRoleCustomFieldOptionAction | InstantiateRoleCustomFieldOptionAction | DeleteRoleCustomFieldOptionAction | UpdateRoleCustomFieldOptionAction;

export type RoleActionTypes = SelectRoleAction | UnSelectRoleAction | ReOrderRolesAction | AddRoleRequestAction | AddRoleAction | InstantiateRoleAction | DeleteRoleAction | UpdateRoleAction | UpdateRoleCustomFieldStartPieceAction | SetIsolatedRoleCustomFieldPieceAction | RemoveIsolatedRoleCustomFieldPieceAction | RegisterRoleCustomFieldVariableAction | CustomFieldActions | CustomFieldChoiceActions | UpdateRolesData | UpdateRoleCustomFieldsData | UpdateRoleCustomFieldOptionsData | SynchronizeRolesData | SynchronizeRoleCustomFieldsData | SynchronizeRoleCustomFieldOptionsData | ClearRolesDelta;
import { select } from '@angular-redux/store';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { translatePhrase } from 'src/shared/helpers/translation';
import { IWidget, WidgetData, WidgetState } from 'src/shared/store/widgets/types';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements OnInit {
  @Input() widgetId: string;
  widget: IWidget;
  showData: WidgetData;
  @select(['widgets']) widgetStateSource: Observable<WidgetState>;
  widgetStateSubscription: Subscription;
  widgetState: WidgetState;

  cachedMessage = '';
  isAggregated = false;

  constructor() { }

	translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

  ngOnInit() {
    this.widgetStateSubscription = this.widgetStateSource.subscribe(widgetState => {
        this.widgetState = widgetState;
        this.widget = widgetState.byId[this.widgetId];
        
        if (!this.widget) {
          return;
        }

        this.showData = widgetState.cachedWidgetData[this.widgetId];

        if (this.widget.displayType === 'message') {
        this.cachedMessage = this.widgetState.cachedWidgetMessages[this.widgetId];
            return;
        }

        this.isAggregated = this.widget.aggregation && this.widget.aggregation !== 'none';

        if (this.isAggregated) {
            return;
        }
    });
  }

  ngOnDestroy() {
    if (this.widgetStateSubscription) {
        this.widgetStateSubscription.unsubscribe();
    }
  }

}

import { NgRedux } from "@angular-redux/store";
import { ErrorHandler, Injectable } from "@angular/core";
import { addAppError } from "src/shared/store/errors/actions";
import store from "src/shared/store/main";
import { ApplicationState } from "src/shared/store/types";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor (private ngRedux: NgRedux<ApplicationState>,) {

    }

    handleError(error) {
        const applicationState = store.getState();
        this.ngRedux.dispatch(addAppError(error.message, 'Mobile global error handler', window.location.href, applicationState.myData.id));
        console.error(error);
    }
  }
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { translatePhrase } from 'src/shared/helpers/translation';

@Component({
    selector: 'app-introduction',
    templateUrl: './introduction.component.html',
    styleUrls: ['./introduction.component.scss'],
})
export class IntroductionComponent implements OnInit {
    @Input() introduction: any;

    @Input() counter: number = 0;

    @Output() clickCounter = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    incrementClick() {
        this.counter += 1;
        this.clickCounter.emit(this.counter);
    }

	translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

}

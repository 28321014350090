import { getWidgetValue } from "../store/flowchart/helpers/widget";
import { PieceType } from "../store/flowchart/pieces/types";
import { DefaultFlowchartProcessState } from "../store/flowchart/types";
import store from "../store/main";
import { ApplicationState } from "../store/types";
import { WidgetDataSlice } from "../store/widgets/types";
import { VariableValueType } from "./common-types";
import { getShowData } from "./show-data";

export function getValueForMessageWidget(widgetId: string, visibleUserIds: Array<string>, visibleMemberIds: Array<string>, visibleGroupIds: Array<string>, visibleWorkflowIds: Array<string>, applicationState?: ApplicationState) {
    const state = applicationState ? applicationState : store.getState()
    const widget = state.widgets.byId[widgetId];

    if (
        typeof widget.seedUsersVariable === 'undefined' ||
        typeof widget.seedMembersVariable === 'undefined' ||
        typeof widget.seedGroupsVariable === 'undefined' ||
        typeof widget.seedWorkflowsVariable === 'undefined'
    ) {
        return;
    }

    const visibleUsers = visibleUserIds;
    const visibleMembers = visibleMemberIds;
    const visibleGroups = visibleGroupIds;
    const visibleWorkflows = visibleWorkflowIds;
    
    const widgetProcessState: DefaultFlowchartProcessState = {
        executionStack: [],
        forIterationCounts: {},
        customFields: {},
        variables: {
            [widget.seedUsersVariable]: visibleUsers,
            [widget.seedMembersVariable]: visibleMembers,
            [widget.seedGroupsVariable]: visibleGroups,
            [widget.seedWorkflowsVariable]: visibleWorkflows,
        },
        lastComputedPiece: undefined,
        displayingGroupPieceId: undefined,
        displayingQuestionPieceId: undefined,
        displayingShowPieceId: undefined,
        displayingTransferPieceId: undefined,
        displayingContinuePieceId: undefined,
        displayingAddWorkflowPieceId: undefined,
        createdWorkflowId: undefined,
    };

    const startPiece = widget.richTextStartPiece ? widget.richTextStartPiece.piece : undefined;
    
    let widgetData: VariableValueType;
    
    try {
        widgetData = getWidgetValue(state, widgetProcessState, widgetId, startPiece);
    } catch (e) {
        widgetData = 'N/A';
    }

    if (typeof widgetData !== 'string') {
        widgetData = 'N/A';
    }

    return widgetData as string;
}

export function getShowDataForWidget(widgetId: string, applicationState: ApplicationState, visibleUserIds: Array<string>, visibleMemberIds: Array<string>, visibleGroupIds: Array<string>, visibleWorkflowIds: Array<string>, lastEntityId?: string) {
    const state = applicationState ? applicationState : store.getState();
    const widget = state.widgets.byId[widgetId];

    let entityIds: Array<string> = [];

    switch (widget.type) {
        case 'User':
            const visibleUsers = visibleUserIds;
            if (widget.typeId) {
                const typeId = widget.typeId;
                entityIds = visibleUsers.filter(userId => state.users.byId[userId].roles.includes(typeId)).slice();
            } else {
                entityIds = visibleUsers.slice();
            }
            break;

        case 'Member':
            const visibleMembers = visibleMemberIds;
            if (widget.typeId) {
                entityIds = visibleMembers.filter(memberId => state.members.byId[memberId].type === widget.typeId);
            } else {
                entityIds = visibleMembers.slice();
            }
            break;

        case 'Group':
            const visibleGroups = visibleGroupIds;
            if (widget.typeId) {
                entityIds = visibleGroups.filter(groupId => state.groups.byId[groupId].type === widget.typeId);
            } else {
                entityIds = visibleGroups.slice();
            }
            break;

        case 'Workflow':
            const visibleWorkflows = visibleWorkflowIds;
            if (widget.typeId) {
                entityIds = visibleWorkflows.filter(workflowId => {
                    const workflow = state.workflows.byId[workflowId];
                    return !workflow.archived && workflow.type === widget.typeId;
                });
            } else {
                entityIds = visibleWorkflows.slice();
            }
            break;
    }

    const PAGE_SIZE = 5000;

    const startIndex = lastEntityId ? entityIds.indexOf(lastEntityId) + 1 : 0;

    const slicedEntityIds = entityIds.slice(startIndex);
    let newLastEntityId: string|undefined;

    const filteredEntityIds: Array<string> = [];

    let hasFlowchart = false;

    if (widget.startPiece && widget.startPiece.piece) {
        const startPiece = state.flowchart.pieces.byId[widget.startPiece.piece];

        if (startPiece.type === PieceType.START && startPiece.nextPiece) {
            hasFlowchart = true;
        }
    }

    for (const entityId of slicedEntityIds) {
        const processState: DefaultFlowchartProcessState = {
            variables: {
                [widget.seedEntityVariable]: entityId,
            },
            customFields: {},
            lastComputedPiece: undefined,
            executionStack: [],
            forIterationCounts: {},
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingGroupPieceId: undefined,
            displayingTransferPieceId: undefined,
            displayingContinuePieceId: undefined,
            displayingAddWorkflowPieceId: undefined,
            createdWorkflowId: undefined,
        }

        let filterResult = true;

        if (hasFlowchart) {
            try {
                filterResult = widget.startPiece ? !!getWidgetValue(state, processState, widget.id, widget.startPiece.piece) : true;
            } catch {
                filterResult = true;
            }
        }

        if (filterResult) {
            filteredEntityIds.push(entityId);
        }

        if (filteredEntityIds.length >= PAGE_SIZE) {
            newLastEntityId = entityId;
            break;
        }
    }
    
    const widgetData = getShowData(
        state,
        filteredEntityIds,
        widget.type,
        widget.customFields,
        undefined,
        undefined,
        undefined,
        widget.typeId,
        widget.systemFields,
    );

    const slicedWidgetData: WidgetDataSlice = {
        widgetData,
        lastEntityId: newLastEntityId,
    }

    return slicedWidgetData;
}
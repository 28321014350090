import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Observable, Subject, Subscription } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { translatePhrase } from 'src/shared/helpers/translation';
import { Router } from '@angular/router';
import { VERSION_NUMBER } from 'src/shared/helpers/version';
import { HttpClient } from '@angular/common/http';
import { clearCache } from "clear-cache"
import { BASE_URL } from 'src/shared/store/url';

export type IVersion = {
    baseVersion: number,
    majorVersion: number,
    minorVersion: number
};

@Injectable({
    providedIn: 'root'
})
export class UpdateService {
    public subject = new Subject<any>();
    isUpdateAvailable: boolean = false;
    latestVersion = VERSION_NUMBER;
    swUpdateSubscription: Subscription;

    constructor(private swUpdate: SwUpdate,
        private router: Router,
        private http: HttpClient,
        public alertController: AlertController
        ) {

        const forceReload = () =>
            navigator.serviceWorker
                .getRegistrations()
                .then((registrations) =>
                    Promise.all(registrations.map((r) => r.unregister())),
                )
                .then(() => window.location.reload());


        if (!this.swUpdate.isEnabled) {
            console.log('No SW');
        } else {
            this.swUpdate.checkForUpdate().then((ev) => {
                console.log(ev);
            }, (err) => {
                console.log(err);
            });
        }
        this.swUpdateSubscription = this.swUpdate.available.subscribe((evt: any) => {
            this.setUpdateFlag(true);
            this.isUpdateAvailable = true;
            this.updateAlert();
        });
    }

    async updateAlert() {
        const alert = await this.alertController.create({
            header: this.translate('Update'),
            mode: 'ios',
            message: this.translate('Update Application'),
            buttons: [{
                text: this.translate('No'),
                cssClass: 'alert-danger',
                role: 'cancel',
            }, {
                text: this.translate('Yes'),
                cssClass: 'alert-confirm',
                handler: async () => {
                    if (localStorage.token) {
                        this.router.navigate(['/tabs/more']);
                    } else {
                        clearCache(true);
                    }
                }
            }]
        });

        await alert.present();
    }

    translate(phrase: string) {
        return translatePhrase(phrase.trim());
    }

    setUpdateFlag(flag: boolean) {
        this.subject.next({ isUpdateAvailable: flag });
    }

    getUpdateFlag(): Observable<any> {
        return this.subject.asObservable();
    }

    getLatestVersion() {
        return this.latestVersion;
    }

    async checkIfUpdateAvailable() {
        if (navigator.onLine) {
            return this.http.get(BASE_URL + '/version/', { responseType: 'text' }).toPromise().then((data: string) => {

                this.latestVersion = data;
                let tempVersionArray = data.split("."),
                    tempLocalVersionArray = VERSION_NUMBER.split(".");

                let tempVersion: IVersion = {
                    baseVersion: Number(tempVersionArray[0]),
                    majorVersion: Number(tempVersionArray[1]),
                    minorVersion: Number(tempVersionArray[2])
                };

                let localVersion: IVersion = {
                    baseVersion: Number(tempLocalVersionArray[0]),
                    majorVersion: Number(tempLocalVersionArray[1]),
                    minorVersion: Number(tempLocalVersionArray[2])
                };

                if (tempVersion.baseVersion > localVersion.baseVersion) {
                    this.isUpdateAvailable = true;
                    return true;
                }

                if (tempVersion.majorVersion > localVersion.majorVersion) {
                    this.isUpdateAvailable = true;
                    return true;
                }

                if (tempVersion.minorVersion > localVersion.minorVersion) {
                    this.isUpdateAvailable = true;
                    return true;
                }

                if (VERSION_NUMBER !== data) {
                    this.isUpdateAvailable = true;
                    return true;
                }

                return false;

            });
        } else {
            return false;
        }
    }
}

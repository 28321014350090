import { WorkflowState, ADD_WORKFLOW, UPDATE_WORKFLOW, DELETE_WORKFLOW, SEARCH_WORKFLOW_TABLE, GO_TO_PAGE_WORKFLOW_TABLE, SET_PAGE_SIZE_WORKFLOW_TABLE, SORT_WORKFLOW_TABLE, UPDATE_WORKFLOW_STATUS, UPDATE_WORKFLOW_PROCESS_STATE, WorkflowActionTypes, UPDATE_WORKFLOW_DATA, TRANSFER_WORKFLOW, WORKFLOW_NAVIGATE_FORWARD, WORKFLOW_NAVIGATE_BACK, ADD_TO_WORKFLOW_HISTORY, UPDATE_WORKFLOW_DUE_DATE, FILTER_WORKFLOW_TABLE, RESTRICT_WORKFLOW_NAVIGATION, IWorkflow, UPDATE_WORKFLOWS_DATA, SYNCHRONIZE_WORKFLOWS_DATA, CLEAR_WORKFLOWS_DELTA, UN_ARCHIVE_WORKFLOW, ADD_TO_SCREEN_INPUTS, WorkflowProcessStep, SET_IS_FILTERING_FOR_WORKFLOW_TABLE, UPDATE_WORKFLOW_CUSTOM_FIELD_DATA, ADD_COMPLETED_WORKFLOWS_DATA, APPEND_WORKFLOWS_DATA, BULK_TRANSFER_WORKFLOWS, UPDATE_WORKFLOW_COMPUTED_FIELD_DATA, BULK_UPDATE_WORKFLOW_COMPUTED_FIELD_DATA, CLEAR_WORKFLOW_ENTRIES, SET_TOTAL_NUMBER_OF_WORKFLOWS_FROM_SERVER } from './types';
import { workflowTypesReducer, initialState as workflowTypesInitialState } from './types/reducer';
import { CustomFieldValueType, CustomFieldDataForIndividualMembers } from '../custom-fields/types';
import { updateEntries, clearDelta, synchronizeEntries, addEntity, updateEntity, deleteEntity, unArchiveEntity, addEntities } from '../normalized-model';
import { isDesktopPlatform, isUUID } from '../../helpers/utilities';

const defaultFlowFilter = isDesktopPlatform() ? ['my', 'other'] : ['my'];

export const initialState: WorkflowState = {
    byId: {},
    allEntries: [],
    activeWorkflowEntries: [],
    filteredEntries: [],

    totalNoOfWorkflows: 0,

    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    types: workflowTypesInitialState,
    pageSize: 25,
    currentPageNumber: 1,

    isFiltering: false,

    markedForIndex: [],

    filters: {
        dues: ['due'],
        projects: [],
        types: [],
        statuses: [],
        users: defaultFlowFilter,
        locations: [],
        otherUsers: [],
        affiliations: [],
        customFields: {},
        createdDateRange: [],
        lastUpdatedDateRange: [],
        dueDateRange: [],
        unsynced: false,
        archived: false,
    },
    sort: {
        column: undefined,
        order: 'ASC',
    },
    searchTerm: '',
    screenInputs: {},
};

type ComputedFieldSeedDataType = {
    [computedFieldId: string]: {
        [variableId: string]: CustomFieldValueType;
    };
};

export function workflowsReducer(state = initialState, action: WorkflowActionTypes): WorkflowState {
    state = {
        ...state,
        types: workflowTypesReducer(state.types, action),
    }

    let newState: WorkflowState;

    switch (action.type) {

        // WORKFLOW ACTIONS
        case ADD_WORKFLOW:
            const workflowType = state.types.byId[action.payload.type];
            const firstHistoryStep: WorkflowProcessStep = {
                lastComputedPiece: undefined,
                executionStack: [],
                variables: {
                    [workflowType.seedEntityVariable]: action.payload.id,
                    [workflowType.seedAffiliationVariable]: action.payload.affiliatedEntity,
                },
                customFields: {},
                executingUser: action.payload.user,
                executionTime: action.currentTime,
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                createdWorkflowId: undefined,
            };
            newState = addEntity<WorkflowState, IWorkflow>(state, action.payload);
            newState.byId[action.payload.id].history.push(firstHistoryStep);
            newState.activeWorkflowEntries = newState.activeWorkflowEntries.concat([action.payload.id]);
            return newState;

        case UPDATE_WORKFLOW:
            state = updateEntity<WorkflowState, IWorkflow>(state, action.payload, action.currentTime);
            const updatedStatusInEntity = state.types.statuses.byId[action.payload.status];

            if (state.activeWorkflowEntries.includes(action.payload.id) && updatedStatusInEntity.isTerminal) {
                state.activeWorkflowEntries = state.activeWorkflowEntries.filter(entryId => entryId !== action.payload.id);
            } else if (!state.activeWorkflowEntries.includes(action.payload.id) && !updatedStatusInEntity.isTerminal) {
                state.activeWorkflowEntries = state.activeWorkflowEntries.concat([action.payload.id]);
            }

            return state;

        case DELETE_WORKFLOW:
            state = deleteEntity<WorkflowState, IWorkflow>(state, action.id, action.currentTime);

            if (state.activeWorkflowEntries.includes(action.id)) {
                state.activeWorkflowEntries = state.activeWorkflowEntries.filter(activeEntryId => activeEntryId !== action.id);
            }

            return state;

        case UN_ARCHIVE_WORKFLOW:
            state = unArchiveEntity<WorkflowState, IWorkflow>(state, action.id, action.currentTime);
            const workflowToReinstate = state.byId[action.id];
            const workflowToReinstateStatus = state.types.statuses.byId[workflowToReinstate.status];

            if (!workflowToReinstateStatus.isTerminal) {
                state.activeWorkflowEntries = state.activeWorkflowEntries.concat([action.id]);
            }

            return state;

        case SEARCH_WORKFLOW_TABLE:
            return {
                ...state,
                searchTerm: action.searchTerm,
                currentPageNumber: 1,
            }

        case FILTER_WORKFLOW_TABLE:
            return {
                ...state,
                filters: {
                    dues: action.dues,
                    projects: action.projects,
                    types: action.types,
                    statuses: action.statuses,
                    users: action.users,
                    locations: action.locations,
                    otherUsers: action.otherUsers,
                    affiliations: action.affiliations,
                    customFields: action.customFields,
                    createdDateRange: action.createdDateRange,
                    lastUpdatedDateRange: action.lastUpdatedDateRange,
                    dueDateRange: action.dueDateRange,
                    unsynced: action.unsynced,
                    archived: action.archived,
                },
                currentPageNumber: 1,
            }

        case SET_IS_FILTERING_FOR_WORKFLOW_TABLE:
            return {
                ...state,
                isFiltering: action.isFiltering,
            };

        case GO_TO_PAGE_WORKFLOW_TABLE:
            return {
                ...state,
                currentPageNumber: action.pageNumber,
            }

        case SET_PAGE_SIZE_WORKFLOW_TABLE:
            return {
                ...state,
                pageSize: action.pageSize,
            }

        case SORT_WORKFLOW_TABLE:
            return {
                ...state,
                sort: {
                    column: action.column,
                    order: action.order
                }
            }

        // WORKFLOW PROCESS ACTIONS

        case UPDATE_WORKFLOW_STATUS:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        status: action.statusId,
                        lastUpdatedTime: action.currentTime,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowId]),
            }

            const updatedStatus = state.types.statuses.byId[action.statusId];

            if (state.activeWorkflowEntries.includes(action.workflowId) && updatedStatus.isTerminal) {
                state.activeWorkflowEntries = state.activeWorkflowEntries.filter(entryId => entryId !== action.workflowId);
            } else if (!state.activeWorkflowEntries.includes(action.workflowId) && !updatedStatus.isTerminal) {
                state.activeWorkflowEntries = state.activeWorkflowEntries.concat([action.workflowId]);
            }

            return state;

        case UPDATE_WORKFLOW_DUE_DATE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        dueDate: action.dueDate,
                        lastUpdatedTime: action.currentTime,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowId]),
            }

        case ADD_TO_WORKFLOW_HISTORY:
            const latestStep = action.processState;

            if (!isUUID(latestStep.executingUser)) {
                latestStep.executingUser = state.byId[action.workflowId].user;
            }

            const newHistory = state.byId[action.workflowId].history.slice(0, state.byId[action.workflowId].historyIndex + 1).concat(latestStep);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        history: newHistory,
                        historyIndex: newHistory.length - 1,
                        lastUpdatedTime: action.updateTime,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowId]),
            }

        case ADD_TO_SCREEN_INPUTS:
            const newScreenInputs = !state.screenInputs[action.workflowId] ? [] : state.screenInputs[action.workflowId].map(screenInputs => {
                return {
                    ...screenInputs,
                };
            });

            newScreenInputs.unshift({
                pieceId: action.screenInput.pieceId,
                workflowIndex: action.screenInput.workflowIndex,
                answers: action.screenInput.answers,
                groupedAnswers: action.screenInput.groupedAnswers,
                choices: action.screenInput.choices,
            });

            return {
                ...state,
                screenInputs: {
                    [action.workflowId]: newScreenInputs,
                },
            };

        case UPDATE_WORKFLOW_CUSTOM_FIELD_DATA:
        case UPDATE_WORKFLOW_COMPUTED_FIELD_DATA:

            const currentProcessState = state.byId[action.workflowId].historyIndex >= state.byId[action.workflowId].history.length ? state.byId[action.workflowId].history[state.byId[action.workflowId].history.length - 1] : state.byId[action.workflowId].history[state.byId[action.workflowId].historyIndex];
            const newCustomFieldStep: WorkflowProcessStep = JSON.parse(JSON.stringify(currentProcessState));
            newCustomFieldStep.customFields = {
                ...newCustomFieldStep.customFields,
                ...action.customFieldData,
            };

            for (const customFieldId of Object.keys(action.customFieldData)) {
                const dataForField = action.customFieldData[customFieldId];
                if (typeof dataForField === 'object' && !Array.isArray(dataForField)) {
                    for (const memberId of Object.keys(dataForField)) {
                        (newCustomFieldStep.customFields[customFieldId] as CustomFieldDataForIndividualMembers)[memberId] = dataForField[memberId];
                    }
                } else {
                    newCustomFieldStep.customFields[customFieldId] = dataForField;
                }
            }

            const newHistoryWithCustomFields = state.byId[action.workflowId].history.slice(0, state.byId[action.workflowId].historyIndex + 1).concat(newCustomFieldStep);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        history: newHistoryWithCustomFields,
                        historyIndex: newHistoryWithCustomFields.length - 1,
                        lastUpdatedTime: action.currentTime,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowId]),
            };

        case BULK_UPDATE_WORKFLOW_COMPUTED_FIELD_DATA:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                },
                updatedIds: new Set([...state.updatedIds, ...action.payload.map(memberData => memberData.workflowId)]),
            }

            for (const workflowData of action.payload) {
                const currentProcessState = state.byId[workflowData.workflowId].historyIndex >= state.byId[workflowData.workflowId].history.length ? state.byId[workflowData.workflowId].history[state.byId[workflowData.workflowId].history.length - 1] : state.byId[workflowData.workflowId].history[state.byId[workflowData.workflowId].historyIndex];
                const newCustomFieldStep: WorkflowProcessStep = JSON.parse(JSON.stringify(currentProcessState));
                newCustomFieldStep.customFields = {
                    ...newCustomFieldStep.customFields,
                    ...workflowData.customFieldData,
                };

                for (const customFieldId of Object.keys(workflowData.customFieldData)) {
                    const dataForField = workflowData.customFieldData[customFieldId];
                    if (typeof dataForField === 'object' && !Array.isArray(dataForField)) {
                        for (const memberId of Object.keys(dataForField)) {
                            (newCustomFieldStep.customFields[customFieldId] as CustomFieldDataForIndividualMembers)[memberId] = dataForField[memberId];
                        }
                    } else {
                        newCustomFieldStep.customFields[customFieldId] = dataForField;
                    }
                }

                const newHistoryWithCustomFields = state.byId[workflowData.workflowId].history.slice(0, state.byId[workflowData.workflowId].historyIndex + 1).concat(newCustomFieldStep);

                state.byId[workflowData.workflowId] = {
                    ...state.byId[workflowData.workflowId],
                    history: newHistoryWithCustomFields,
                    historyIndex: newHistoryWithCustomFields.length - 1,
                    lastUpdatedTime: action.currentTime,
                };

            }

            return state;

        case UPDATE_WORKFLOW_PROCESS_STATE:
            const updatedHistory = state.byId[action.workflowId].history.slice(0, state.byId[action.workflowId].historyIndex);
            let updatedScreenInputs = !state.screenInputs[action.workflowId] ? [] : state.screenInputs[action.workflowId].map(screenInputs => {
                return {
                    ...screenInputs,
                };
            });

            updatedScreenInputs = updatedScreenInputs.filter(screenInput => {
                return screenInput.workflowIndex <= state.byId[action.workflowId].historyIndex;
            });

            updatedHistory[state.byId[action.workflowId].historyIndex] = {
                ...action.processState,
            };

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        history: updatedHistory,
                        lastUpdatedTime: action.updateTime,
                    }
                },
                screenInputs: {
                    ...state.screenInputs,
                    [action.workflowId]: updatedScreenInputs,
                },
                updatedIds: new Set([...state.updatedIds, action.workflowId]),
            }

        case RESTRICT_WORKFLOW_NAVIGATION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        restrictedHistoryIndex: state.byId[action.workflowId].historyIndex,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowId]),
            }

        case WORKFLOW_NAVIGATE_FORWARD:

            if (state.byId[action.workflowId].historyIndex >= state.byId[action.workflowId].history.length) {
                return state;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        historyIndex: state.byId[action.workflowId].historyIndex + 1,
                        lastUpdatedTime: action.currentTime,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowId]),
            }

        case WORKFLOW_NAVIGATE_BACK:

            if (state.byId[action.workflowId].historyIndex < 0) {
                return state;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        historyIndex: state.byId[action.workflowId].historyIndex - 1,
                        lastUpdatedTime: action.currentTime,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowId]),
            }

        case TRANSFER_WORKFLOW:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowId]: {
                        ...state.byId[action.workflowId],
                        user: action.user,
                        lastUpdatedTime: action.currentTime,
                        trackingUsers: state.byId[action.workflowId].trackingUsers.concat([action.user]),
                        restrictedHistoryIndex: state.byId[action.workflowId].historyIndex,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowId]),
            }

        case BULK_TRANSFER_WORKFLOWS:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                },
                updatedIds: new Set([...state.updatedIds, ...action.workflowIds]),
            }

            for (const workflowId of action.workflowIds) {
                newState.byId[workflowId] = {
                    ...state.byId[workflowId],
                    user: action.user,
                    lastUpdatedTime: action.currentTime,
                    trackingUsers: state.byId[workflowId].trackingUsers.concat([action.user]),
                    restrictedHistoryIndex: state.byId[workflowId].historyIndex,
                }
            }

            return newState;

        case UPDATE_WORKFLOWS_DATA:
            state = updateEntries<WorkflowState, IWorkflow>(state, action.data);
            const activeWorkflowIds = state.allEntries.filter(workflowId => {
                const workflow = state.byId[workflowId];
                const status = state.types.statuses.byId[workflow.status];

                return status && !status.isTerminal;
            });

            state.activeWorkflowEntries = activeWorkflowIds;

            state.totalNoOfWorkflows = action.totalNoOfWorkflows;

            return state;

        case SYNCHRONIZE_WORKFLOWS_DATA:
            state = synchronizeEntries<WorkflowState, IWorkflow>(state, action.data);
            const newActiveIds = action.data
                .filter(workflow => {
                    if (state.activeWorkflowEntries.includes(workflow.id)) {
                        // If the workflow is already in the active list, no need to add it
                        return false;
                    }

                    if (workflow.archived) {
                        return false;
                    }

                    const status = state.types.statuses.byId[workflow.status];

                    return status && !status.isTerminal;

                })
                .map(workflow => workflow.id);

            const deletedIds = action.data.filter(workflow => workflow.archived).map(workflow => workflow.id);

            state.activeWorkflowEntries = state.activeWorkflowEntries.concat(newActiveIds).filter(workflowId => !deletedIds.includes(workflowId));
            return state;

        case APPEND_WORKFLOWS_DATA:
            state = synchronizeEntries<WorkflowState, IWorkflow>(state, action.data);
            const newActiveIdsForAppendedWorkflows = action.data
                .filter(workflow => {
                    if (state.activeWorkflowEntries.includes(workflow.id)) {
                        // If the workflow is already in the active list, no need to add it
                        return false;
                    }

                    if (workflow.archived) {
                        return false;
                    }

                    const status = state.types.statuses.byId[workflow.status];

                    return status && !status.isTerminal;

                })
                .map(workflow => workflow.id);

            const deletedIdsForAppendedWorkflows = action.data.filter(workflow => workflow.archived).map(workflow => workflow.id);

            state.activeWorkflowEntries = state.activeWorkflowEntries.concat(newActiveIdsForAppendedWorkflows).filter(workflowId => !deletedIdsForAppendedWorkflows.includes(workflowId));
            return state;

        case ADD_COMPLETED_WORKFLOWS_DATA:
            const unrecordedCompletedFlows = action.data.filter(workflow => !(workflow.id in state.byId));
            state = synchronizeEntries(state, unrecordedCompletedFlows);
            return state;

        case SET_TOTAL_NUMBER_OF_WORKFLOWS_FROM_SERVER:
            return {
                ...state,
                currentPageNumber: 1,
                totalNoOfWorkflows: action.totalNumberOfWorkflows,
            }

        case CLEAR_WORKFLOW_ENTRIES:
            return {
                ...state,
                byId: {},
                allEntries: [],
            };

        case CLEAR_WORKFLOWS_DELTA:
            return clearDelta<WorkflowState, IWorkflow>(state);

        case UPDATE_WORKFLOW_DATA:
            return {
                ...action.data,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
                types: {
                    ...action.data.types,
                    createdIds: state.types.createdIds,
                    updatedIds: state.types.updatedIds,
                    deletedIds: state.types.deletedIds,
                    createdCustomFieldIds: state.types.createdCustomFieldIds,
                    updatedCustomFieldIds: state.types.updatedCustomFieldIds,
                    deletedCustomFieldIds: state.types.deletedCustomFieldIds,
                    createdCustomFieldOptionIds: state.types.createdCustomFieldOptionIds,
                    updatedCustomFieldOptionIds: state.types.updatedCustomFieldOptionIds,
                    deletedCustomFieldOptionIds: state.types.deletedCustomFieldOptionIds,
                    statuses: {
                        ...action.data.types.statuses,
                        createdIds: state.types.statuses.createdIds,
                        updatedIds: state.types.statuses.updatedIds,
                        deletedIds: state.types.statuses.deletedIds,
                    }
                },
                filters: state.filters,
            }

        default:
            return state;
    }
}
import { Injectable } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Injectable({
	providedIn: 'root'
})
export class ShareService {

	constructor(
		private socialSharing: SocialSharing
	) { }

	share(subject: string, message: string, url: string) {
		let navigator: any;
		navigator = window.navigator;

		if (navigator && navigator.share) {
			navigator.share({
				title: subject,
				text: message,
			});
		} else {
			this.socialSharing.share(subject, message, null, url);
		}
	}
}

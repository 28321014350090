import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import { IGroup, GroupState } from 'src/shared/store/groups/types';
import { IWorkflow, IUpdateableWorkflowData, WorkflowState } from '../../shared/store/workflows/types';
import { IWorkflowType } from '../../shared/store/workflows/types/types';
import { IStatus } from '../../shared/store/workflows/types/statuses/types';
import { IMember, MemberState } from '../../shared/store/members/types';
import { IGroupType } from 'src/shared/store/groups/types/types';
import { ToggleGroupMembersPage } from '../toggle-group-members/toggle-group-members.page';
import { MemberProfileDetailsPage } from '../member-profile-details/member-profile-details.page';
import { v4 as uuid } from 'uuid';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { WorkflowExecutorPage } from '../workflow-executor/workflow-executor.page';
import { NgRedux } from '@angular-redux/store';
import { ApplicationState } from '../../shared/store/types';
import { addWorkflow } from '../../shared/store/workflows/actions';
import { getReadableDataForCustomField } from 'src/shared/store/custom-fields';
import { CustomFieldValueType, IUpdateableCustomFieldData } from 'src/shared/store/custom-fields/types';
import { DefaultFlowchartProcessState } from 'src/shared/store/flowchart/types';
import { getMemberComputedFieldValue } from 'src/shared/store/flowchart/helpers/custom-fields/member';
import { updateDueDate } from '../../shared/store/workflows/actions';
import { translatePhrase } from 'src/shared/helpers/translation';
import { PermissionSet, Permissions } from 'src/shared/store/permissions/types';
import { ShareService } from '../services/share.service';
import { AddOrEditGroupPage } from '../add-or-edit-group/add-or-edit-group.page';
import { getGroupComputedFieldValue } from 'src/shared/store/flowchart/helpers/custom-fields/group';
import { ToastService, ToastType } from '../services/toast.service';
import * as moment from 'moment';
import { AddWorkflowPage } from '../add-workflow/add-workflow.page';
import { getVisibleMemberIds } from 'src/shared/helpers/visible-entities';
import isValidCoordinates from 'is-valid-coordinates';
import { completionPercentageOfWorkflow } from 'src/shared/store/flowchart/helpers/progress';
import { VariableValueType } from 'src/shared/helpers/common-types';

@Component({
    selector: 'app-group-details',
    templateUrl: './group-details.page.html',
    styleUrls: ['./group-details.page.scss'],
})
export class GroupDetailsPage implements OnInit, OnDestroy {
    @select(state => state) applicationStateSource: Observable<ApplicationState>;

    isReadOnly: boolean;

    @ViewChild('clipboardInput') clipboardInput: ElementRef;

    my_id: string;
    workflowsData: WorkflowState;
    membersData: MemberState;
    groupsData: GroupState;
    group_details: IGroup;
    selected_member: IMember;
    all_members: Array<IMember> = [];
    all_groups: Array<IGroup> = [];
    all_group_types: Array<IGroupType> = [];
    calculated_members: Array<IMember> = [];
    workflows: Array<IWorkflow> = [];
    workflow_types: Array<IWorkflowType> = [];
    workflow_statuses: Array<IStatus> = [];
    allowed_statuses: Array<IStatus> = [];
    affiliated_workflow_types: Array<IWorkflowType> = [];
    member_affiliated_workflow_types: Array<IWorkflowType> = [];
    selectedMemberFireWorkflowType: IWorkflowType;
    selectedGroupFireWorkflowType: IWorkflowType;
    new_workflow: IUpdateableWorkflowData;
    show_popup: boolean = false;
    applicationState: ApplicationState;
    selected_workflow: IWorkflow;
    applicationStateSubscription: Subscription;
    selected_affiliated_member: IMember;
    member_filter_popup: boolean = false;
    userPermission: PermissionSet;
    customFields: Array<IUpdateableCustomFieldData> = [];
    isEditWorkflowPresent: boolean = false;
    editWorkflowId: string;
    phoneFieldId: string;

    selectedDataToCopy: string = '';

    selectedWorkflowForDueDateChange: {
        id: string,
        preSelectedDate: string
    } = {
            id: null,
            preSelectedDate: null
        }

    constructor(
        private modalController: ModalController,
        private navParams: NavParams,
        private ngRedux: NgRedux<ApplicationState>,
        public alertController: AlertController,
        public shareService: ShareService,
        public toastService: ToastService
    ) { }

    isPhoneNumber(value: any) {
        try {
            if ((value.split(' ')[0] && value.split(' ')[1]) && (value.split(' ')[0].includes('+') && value.split(' ')[1].length >= 10 && value.split(' ')[1].length <= 15)) {
                return true;
            }

            return false;
        } catch {
            return false;
        }
    }

    isLocation(value: any) {
        try {
            if ((value.split(' ')[0] && isValidCoordinates.latitude(Number(value.split(' ')[0]))) && (value.split(' ')[1] && isValidCoordinates.longitude(Number(value.split(' ')[1])))) {
                return true;
            }

            return false;
        } catch {
            return false;
        }
    }

    getCompletionRate(workflowId: string) {
        try {
            return Number(completionPercentageOfWorkflow(workflowId).toFixed(1));
        } catch {
            return 0;
        }
    }

    copyMessage(val: any) {
        this.selectedDataToCopy = val;

        if (this.isLocation(val)) {
            this.selectedDataToCopy = 'https://maps.google.com?q=loc:' + val.split(' ')[0] + '+' + val.split(' ')[1];

            setTimeout(() => {
                window.open('https://maps.google.com?q=loc:' + val.split(' ')[0] + '+' + val.split(' ')[1]);
            }, 300);
        }

        setTimeout(() => {
            this.clipboardInput.nativeElement.select();
            document.execCommand('copy');
            this.clipboardInput.nativeElement.setSelectionRange(0, 0);
            this.toastService.presentToastWithOptions("Copied to clipboard", ToastType.SUCCESS);

            if (this.isPhoneNumber(val)) {
                window.open("tel:" + val, '_blank');
            }
        }, 100);
    }

    copyMultiSelect(data: any, choices: any) {
        this.selectedDataToCopy = '';

        for (let i = 0; i < choices.length; i += 1) {
            if (data.includes(choices[i])) {
                this.selectedDataToCopy += (this.selectedDataToCopy !== '' ? ', ' : '') + this.getGroupCustomFieldOptionDetails(choices[i]).name;
            }
        }

        setTimeout(() => {
            this.clipboardInput.nativeElement.select();
            document.execCommand('copy');
            this.clipboardInput.nativeElement.setSelectionRange(0, 0);
            this.toastService.presentToastWithOptions("Copied to clipboard", ToastType.SUCCESS);
        }, 100);
    }

    getGroupTypePermission(id: string) {
        if (this.userPermission.groups && this.userPermission.groups[id]) {
            return this.userPermission.groups[id];
        } else {
            return Permissions.WRITE;
        }
    }

    toggleFireWorkflowForGroups() {
        let affiliated_workflow_types = this.affiliated_workflow_types;

        affiliated_workflow_types = affiliated_workflow_types.filter((type) => {
            if (type.affiliatedEntity && type.affiliatedEntity !== this.group_details.type) {
                return false;
            }

            return this.getWorkflowPermission(type.id) === 'WRITE';
        });

        if (affiliated_workflow_types.length > 0) {
            this.show_popup = true;
        } else {
            this.toastService.presentToastWithOptions(this.translate('No Workflows to fire'), ToastType.INFO);
        }
    }

    isGroupFireWorkflowAvailable() {
        let affiliated_workflow_types = this.affiliated_workflow_types;

        affiliated_workflow_types = affiliated_workflow_types.filter((type) => {
            if (type.affiliatedEntity && type.affiliatedEntity !== this.group_details.type) {
                return false;
            }

            return this.getWorkflowPermission(type.id) === 'WRITE';
        });

        if (affiliated_workflow_types.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    isMemberFireWorkflowAvailable(memberTypeId: string) {
        let member_affiliated_workflow_types = this.member_affiliated_workflow_types;

        member_affiliated_workflow_types = member_affiliated_workflow_types.filter((type) => {
            if (type.affiliatedEntity && type.affiliatedEntity !== memberTypeId) {
                return false;
            }

            return this.getWorkflowPermission(type.id) === 'WRITE';
        });

        if (member_affiliated_workflow_types.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    toggleFireWorkflowForMembers() {
        let member_affiliated_workflow_types = this.member_affiliated_workflow_types;

        member_affiliated_workflow_types = member_affiliated_workflow_types.filter((type) => {
            if (type.affiliatedEntity && type.affiliatedEntity !== this.selected_affiliated_member.type) {
                return false;
            }

            return this.getWorkflowPermission(type.id) === 'WRITE';
        });

        if (member_affiliated_workflow_types.length > 0) {
            this.member_filter_popup = true;
        } else {
            this.toastService.presentToastWithOptions(this.translate('No Workflows to fire'), ToastType.INFO);
        }
    }

    getFormattedDate(date: any) {
        return moment(date).format('DD MMM YYYY');
    }

    isActionAvailable(typeId: string) {
        let actions = this.getGroupType(typeId).actions;
        if (actions[1] && this.groupsData.types.actions.byId[actions[1]].workflowType && this.getGroupTypePermission(typeId) !== 'NONE') {
            return true;
        } else {
            return false;
        }
    }

    getWorkflowPermission(id: string) {
        if (this.userPermission.workflows && this.userPermission.workflows[id]) {
            return this.userPermission.workflows[id];
        } else {
            return Permissions.WRITE;
        }
    }

    translate(phrase: string) {
        return translatePhrase(phrase.trim());
    }

    getGroupType(type_id: string) {
        let type = this.groupsData.types.byId[type_id];
        return type;
    }

    getWorkflowName(type: string) {
        return this.workflowsData.types.byId[type].name;
    }

    openCallApp(phone: string) {
        var url = "tel:" + phone;
        window.open(url);
    }

    getMemberPhoneNumber(memberId: string) {
        if (this.phoneFieldId) {
            if (this.membersData.byId[memberId].customFields[this.phoneFieldId]) {
                return this.membersData.byId[memberId].customFields[this.phoneFieldId].toString();
            } else {
                return '-';
            }
        } else {
            return '-';
        }
    }

    shareGroupCard() {
        this.shareService.share('Group',
            'Group: ' + this.getGroupName(this.group_details.id) + '\n' +
                'Subtitle: ' + this.getGroupSubtitle(this.group_details.id) + '\n' +
                'Type: ' + this.getGroupType(this.group_details.type) ? this.getGroupType(this.group_details.type).name : '-'
            , null);
    }

    shareWorkflowCard() {
        this.shareService.share('Workflow',
            'Workflow: ' + this.getWorkflowName(this.selected_workflow.type) + '\n' +
            'Affiliated to: ' + this.getAffiliatedEntityName(this.selected_workflow.affiliatedEntity) + '\n' +
            'Status: ' + this.workflowsData.types.statuses.byId[this.selected_workflow.status].name
            + '\n' + 'Due on: ' + moment(this.selected_workflow.dueDate).format('DD-MM-YYYY'), null);
    }

    getGroupCustomFieldOptionDetails(id: string) {
        return this.groupsData.types.customFieldOptions.byId[id];
    }

    updateCustomFields() {
        this.customFields = this.group_details.type ?
            this.groupsData.types.byId[this.group_details.type].customFields.map((fieldId) => {
                return this.groupsData.types.customFields.byId[fieldId];
            }) : [];
    }

    ngOnInit() {
        let group_id: string = this.navParams.get('group_id')

        this.applicationStateSubscription = this.applicationStateSource.subscribe((applicationState) => {
            this.applicationState = applicationState;

            this.my_id = this.applicationState.myData.id;

            const userData = this.applicationState.users.byId[this.my_id];
            this.userPermission = this.applicationState.permissions.myPermissions;

            this.membersData = this.applicationState.members;

            this.groupsData = this.applicationState.groups;

            this.all_groups = this.groupsData.allEntries.map((groupId) => {
                return this.groupsData.byId[groupId];
            });

            this.group_details = this.all_groups.find((group) => {
                return group.id == group_id;
            });

            this.all_group_types = this.groupsData.types.allEntries.map((typeId) => {
                return this.groupsData.types.byId[typeId];
            });

            const visibleMembers = getVisibleMemberIds(this.applicationState);

            this.all_members = visibleMembers.map(memberId => this.membersData.byId[memberId]);

            this.all_members = this.all_members.filter((member) => {
                return this.isMember(member.id);
            });

            let customFields = this.membersData.types.customFields.allFields.map((field) => {
                return this.membersData.types.customFields.byId[field];
            });

            customFields.forEach((field) => {
                if (field.type === 'PHONE') {
                    this.phoneFieldId = field.id;
                    return;
                }
            });

            this.workflowsData = this.applicationState.workflows;

            this.workflows = this.workflowsData.allEntries.map((workflowId) => {
                return this.workflowsData.byId[workflowId];
            });

            this.workflows = this.workflows.filter((workflow) => {
                return workflow.affiliatedEntity === group_id && this.my_id === workflow.user;
            });

            this.workflow_types = this.workflowsData.types.allEntries
                .map((typeId) => {
                    return this.workflowsData.types.byId[typeId];
                })
                .filter(workflowType => {
                    return userData.projects.includes(workflowType.project);
                });

            console.log(this.workflow_types);

            this.workflow_statuses = this.workflowsData.types.statuses.allEntries.map((statusId) => {
                return this.workflowsData.types.statuses.byId[statusId];
            });

            this.affiliated_workflow_types = this.workflow_types.filter((workflow) => {
                return workflow.affiliatedEntity === this.group_details.type;
            });

            this.member_affiliated_workflow_types = this.workflow_types.filter((workflow) => {
                return workflow.affiliation === 'member';
            });

            this.updateCustomFields();
        });
    }

    getComputedFieldValue(fieldId: string) {
        const field = this.groupsData.types.customFields.byId[fieldId];

        if (field.isComputed && typeof field.startPiece !== 'undefined') {
            const processState: DefaultFlowchartProcessState = {
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                customFields: { ...this.group_details.customFields },
                variables: {
                    [field.seedEntityVariable]: this.group_details.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
            };

            try {
                return getGroupComputedFieldValue(this.applicationState, processState, field.startPiece.piece, this.group_details.id, field);
            } catch (err) {
                return '-';
            }
        }
    }

    showMap(latlng: string) {
        var url = "https://maps.google.com/?q=" + latlng.split(' ')[0] + latlng.split(' ')[1];
        window.open(url);
    }

    getGroupPermission() {
        let id = this.navParams.get('group_id');
        this.isReadOnly = this.navParams.get('read_only');
        if (this.userPermission.groups && this.userPermission.groups[this.applicationState.groups.byId[id].type]) {
            return this.userPermission.groups[this.applicationState.groups.byId[id].type];
        } else {
            return Permissions.WRITE;
        }
    }

    getMemberLocation(memberId: string) {
        const member = this.membersData.byId[memberId];
        const memberType = this.membersData.types.byId[member.type];
        let memberLocation: VariableValueType;

        const locationField = this.membersData.types.customFields.byId[memberType.locationFieldId];

        if (locationField.isComputed && typeof locationField.startPiece !== 'undefined') {
            const processState: DefaultFlowchartProcessState = {
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                customFields: { ...member.customFields },
                variables: {
                    [locationField.seedEntityVariable]: member.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
            };

            memberLocation = getMemberComputedFieldValue(this.applicationState, processState, locationField.startPiece.piece, member.id, locationField);
        } else {
            memberLocation = member.customFields[memberType.locationFieldId];
        }

        if (Array.isArray(memberLocation)) {

            if (memberLocation.length > 0 && Array.isArray(memberLocation[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            memberLocation = memberLocation as Array<string>;
        }

        memberLocation = getReadableDataForCustomField(memberLocation, locationField, member.id, 'member');

        return memberLocation;
    }

    shareMemberCard() {
        this.shareService.share('Member',
            'Member: ' + this.getMemberName(this.selected_member.id) + '\n' +
            'Subtitle: ' + this.getMemberSubtitle(this.selected_member.id) + '\n' +
            'Type: ' + this.getMemberType(this.membersData.byId[this.selected_member.id].type)
            , null);
    }

    getMemberSubtitle(memberId: string) {
        try {
            const member = this.membersData.byId[memberId];
            const memberType = this.membersData.types.byId[member.type];
            let memberSubtitle: VariableValueType;
            const subTitleField = this.membersData.types.customFields.byId[memberType.subTitleFieldId];

            if (subTitleField.isComputed && typeof subTitleField.startPiece !== 'undefined') {
                const processState: DefaultFlowchartProcessState = {
                    displayingContinuePieceId: undefined,
                    displayingAddWorkflowPieceId: undefined,
                    customFields: { ...member.customFields },
                    variables: {
                        [subTitleField.seedEntityVariable]: member.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                };

                memberSubtitle = getMemberComputedFieldValue(this.applicationState, processState, subTitleField.startPiece.piece, member.id, subTitleField);
            } else {
                memberSubtitle = member.customFields[memberType.subTitleFieldId];
            }

            if (Array.isArray(memberSubtitle)) {

                if (memberSubtitle.length > 0 && Array.isArray(memberSubtitle[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                memberSubtitle = memberSubtitle as Array<string>;
            }

            memberSubtitle = getReadableDataForCustomField(memberSubtitle, subTitleField, member.id, 'member');

            return memberSubtitle;
        } catch {
            return '-';
        }
    }

    getMemberType(type_id: string) {
        let type = this.membersData.types.byId[type_id].name;
        return type ? type : '-';
    }

    getGroupName(groupId: string) {
        const group = this.groupsData.byId[groupId];
        const groupType = this.groupsData.types.byId[group.type];
        let groupName: CustomFieldValueType;
        const nameField = this.groupsData.types.customFields.byId[groupType.nameFieldId];

        groupName = group.customFields[groupType.nameFieldId];

        groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');
        return groupName;
    }

    getGroupSubtitle(groupId: string) {
        const group = this.groupsData.byId[groupId];
        const groupType = this.groupsData.types.byId[group.type];
        let groupSubtitle: CustomFieldValueType;
        const subTitleField = this.groupsData.types.customFields.byId[groupType.subTitleFieldId];

        groupSubtitle = group.customFields[groupType.subTitleFieldId];

        groupSubtitle = getReadableDataForCustomField(groupSubtitle, subTitleField, group.id, 'group');

        return groupSubtitle;
    }

    async executeGroupWorkflow(workflow: IWorkflow) {
        if (this.workflowsData.byId[workflow.id].user === this.my_id) {
            const modal = await this.modalController.create({
                component: WorkflowExecutorPage,
                componentProps: {
                    workflow_id: workflow.id
                }
            });

            return await modal.present();
        } else {
            this.alertUser('You cannot resume other user\'s workflows');
        }
    }

    async alertUser(text: string) {
        const alert = await this.alertController.create({
            message: text,
            buttons: [
                {
                    text: 'Okay',
                    handler: () => { }
                }
            ]
        });

        await alert.present();
    }

    showCalendar(workflow: IUpdateableWorkflowData, preSelectedDate?: string) {
        this.selectedWorkflowForDueDateChange = {
            id: workflow.id,
            preSelectedDate: preSelectedDate
        };
    }

    getDateInput(tempDateInput: any) {
        if (!moment(tempDateInput, 'DD-MM-YYYY', true).isValid()) {
            this.toastService.presentToastWithOptions("Invalid Date, Format: DD-MM-YYYY", ToastType.ERROR);
        } else {
            if (this.selectedWorkflowForDueDateChange.preSelectedDate !== moment(tempDateInput, 'DD-MM-YYYY').format('YYYY-MM-DD')) {
                this.ngRedux.dispatch(updateDueDate(this.selectedWorkflowForDueDateChange.id, moment(tempDateInput, 'DD-MM-YYYY').format('YYYY-MM-DD')));
                this.toastService.presentToastWithOptions('The due date has been updated!', ToastType.SUCCESS);

                this.selectedWorkflowForDueDateChange = {
                    id: null,
                    preSelectedDate: null
                };
            } else {
                this.selectedWorkflowForDueDateChange = {
                    id: null,
                    preSelectedDate: null
                };
            }

        }
    }

    isWorkflowTerminal(status: string) {
        return this.workflowsData.types.statuses.byId[status].isTerminal;
    }

    getAffiliatedEntityName(affiliatedEntity: string) {
        let group = this.all_groups.find((group) => {
            return affiliatedEntity === group.id;
        });

        if (group) {
            return this.getGroupName(group.id);
        } else {
            return '-';
        }
    }

    getMemberName(memberId: string) {
        const member = this.membersData.byId[memberId];
        const memberType = this.membersData.types.byId[member.type];
        let memberName: VariableValueType;

        const nameField = this.membersData.types.customFields.byId[memberType.nameFieldId];

        if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
            const processState: DefaultFlowchartProcessState = {
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                customFields: { ...member.customFields },
                variables: {
                    [nameField.seedEntityVariable]: member.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
            };

            memberName = getMemberComputedFieldValue(this.applicationState, processState, nameField.startPiece.piece, member.id, nameField);
        } else {
            memberName = member.customFields[memberType.nameFieldId];
        }

        if (Array.isArray(memberName)) {

            if (memberName.length > 0 && Array.isArray(memberName[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            memberName = memberName as Array<string>;
        }

        memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

        return memberName;
    }

    back() {
        this.modalController.dismiss();
    }

    async addNewWorkflowWithType(actionId: string) {
        let selected_affiliated_entity = {
            id: this.group_details.id,
            type: this.group_details.type,
            name: this.getGroupName(this.group_details.id)
        }

        const modal = await this.modalController.create({
            component: AddWorkflowPage,
            componentProps: {
                workflow_type_id: this.groupsData.types.actions.byId[actionId].workflowType,
                selected_affiliated_entity: JSON.stringify(selected_affiliated_entity)
            }
        });

        return await modal.present();
    }


    editSelectedGroupCheckActions() {
        if (this.group_details.type) {
            let groupType = this.groupsData.types.byId[this.group_details.type];

            if (groupType.actions && groupType.actions[1] && this.groupsData.types.actions.byId[groupType.actions[1]].workflowType) {
                this.addNewWorkflowWithType(groupType.actions[1]);
            } else if (this.getGroupPermission() === 'WRITE') {
                this.editSelectedGroup();
            } else {
                this.toastService.presentToastWithOptions("You do not have permission to edit this group", ToastType.INFO);
            }
        }
    }

    async editSelectedGroup() {
        const modal = await this.modalController.create({
            component: AddOrEditGroupPage,
            componentProps: {
                group: JSON.stringify(this.group_details)
            }
        });

        return await modal.present();
    }

    async toggleGroupMembers(groupTypeId: string) {
        const groupType = this.groupsData.types.byId[groupTypeId];

        if (groupType.actions && groupType.actions[1] && this.groupsData.types.actions.byId[groupType.actions[1]].workflowType) {
            this.addNewWorkflowWithType(groupType.actions[1]);
        } else {
            const modal = await this.modalController.create({
                component: ToggleGroupMembersPage,
                componentProps: {
                    group_id: this.group_details.id
                }
            });

            return await modal.present();
        }
    }

    isRepresentative(id: string) {
        if (this.group_details.representatives.includes(id)) {
            return true;
        } else {
            return false;
        }
    }

    isMember(id: string) {
        if (this.group_details.members.includes(id)) {
            return true;
        } else {
            return false;
        }
    }

    async selectedMemberDetails(member: IMember) {
        if (this.isReadOnly) {
            return;
        }

        const modal = await this.modalController.create({
            component: MemberProfileDetailsPage,
            componentProps: {
                selected_member_data: member
            }
        });

        return await modal.present();
    }

    initiateMemberWorkflow(type_id: string) {
        let typeWorkflowIds: Array<string>, typeWorkflows: Array<IWorkflow>,
            isWorkflowPresent: boolean = false;
        typeWorkflowIds = this.applicationState.users.byId[this.my_id].workflows[type_id];

        if (typeWorkflowIds) {
            typeWorkflows = typeWorkflowIds.map(id => {
                return this.workflowsData.byId[id];
            });

            typeWorkflows.forEach(workflow => {
                if (workflow.affiliatedEntity === this.selected_affiliated_member.id && !this.isWorkflowTerminal(workflow.status) && !isWorkflowPresent) {
                    isWorkflowPresent = true;
                    this.member_filter_popup = false;
                    this.resumeWorkflow(workflow.id);
                }
            });
        }

        if (!typeWorkflowIds || !isWorkflowPresent) {
            this.member_filter_popup = false;
            this.allowed_statuses = this.workflowsData.types.byId[type_id].statuses.map(statusId => this.workflowsData.types.statuses.byId[statusId]);

            this.new_workflow = {
                id: uuid(),
                type: type_id,
                status: this.allowed_statuses.filter((status) => {
                    return !status.isTerminal;
                })[0].id,
                dueDate: '',
                user: this.my_id,
                affiliatedEntity: this.selected_affiliated_member.id,
            }

            let dueDays = this.allowed_statuses.filter((status) => {
                return !status.isTerminal;
            })[0].dueInDays;

            this.new_workflow.dueDate = moment(new Date().setDate(new Date().getDate() + dueDays)).format('YYYY-MM-DD');
            this.ngRedux.dispatch(addWorkflow(this.new_workflow));
            this.executeWorkflow(this.new_workflow.id);
        }
    }

    initiateWorkflow(type_id: string) {
        let typeWorkflowIds: Array<string>, typeWorkflows: Array<IWorkflow>,
            isWorkflowPresent: boolean = false;
        typeWorkflowIds = this.applicationState.users.byId[this.my_id].workflows[type_id];

        if (typeWorkflowIds) {
            typeWorkflows = typeWorkflowIds.map(id => {
                return this.workflowsData.byId[id];
            });

            typeWorkflows.forEach(workflow => {
                if (workflow &&
                    workflow.affiliatedEntity === this.group_details.id &&
                    !this.isWorkflowTerminal(workflow.status) &&
                    !isWorkflowPresent) {
                    isWorkflowPresent = true;
                    this.show_popup = false;
                    this.resumeWorkflow(workflow.id);
                }
            });
        }

        if (!typeWorkflows || !isWorkflowPresent) {
            this.allowed_statuses = this.workflowsData.types.byId[type_id].statuses.map(statusId => this.workflowsData.types.statuses.byId[statusId]);

            let nonTerminalStatus: Array<IStatus> = this.allowed_statuses.filter((status) => {
                return !status.isTerminal;
            });

            this.new_workflow = {
                id: uuid(),
                type: type_id,
                status: nonTerminalStatus[0].id,
                dueDate: moment(new Date().setDate(new Date().getDate() + nonTerminalStatus[0].dueInDays)).format('YYYY-MM-DD'),
                user: this.my_id,
                affiliatedEntity: this.group_details.id,
            }

            this.ngRedux.dispatch(addWorkflow(this.new_workflow));
            this.executeWorkflow(this.new_workflow.id);
        }
    }

    async executeWorkflow(id: string, isEnd?: boolean) {
        const modal = await this.modalController.create({
            component: WorkflowExecutorPage,
            componentProps: {
                workflow_id: id,
                isEnd: isEnd ? true : false
            }
        });

        return await modal.present();
    }

    async resumeWorkflow(id: string) {
        const alert = await this.alertController.create({
            header: `${this.translate('Wait')}!`,
            message: this.translate('Seems like there is already a workflow existing... Would you like to resume it?'),
            mode: 'ios',
            buttons: [
                {
                    text: this.translate('Cancel'),
                    role: 'cancel',
                    cssClass: 'alert-danger',
                }, {
                    text: this.translate('Okay'),
                    cssClass: 'alert-confirm',
                    handler: () => {
                        this.executeWorkflow(id);
                        this.back();
                    }
                }
            ]
        });

        await alert.present();
    }

    ngOnDestroy() {
        if (this.applicationStateSubscription) {
            this.applicationStateSubscription.unsubscribe();
        }
    }

    hasChoice(array: any, choice: string) {
        return array.includes(choice);
    }

}

import { ErrorState, ErrorActionTypes, IAppError, ADD_ERROR, CLEAR_ERRORS } from './types';
import { addEntity } from '../normalized-model';

const initialState: ErrorState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],

    createdIds: new Set<string>(),
    updatedIds: new Set<string>(),
    deletedIds: new Set<string>(),
};

export function errorsReducer(state = initialState, action: ErrorActionTypes): ErrorState {

    switch(action.type) {
        case ADD_ERROR:
            return addEntity<ErrorState, IAppError>(state, action.payload);

        case CLEAR_ERRORS:
            return {
                byId: {},
                allEntries: [],
                filteredEntries: [],

                createdIds: new Set(),
                updatedIds: new Set(),
                deletedIds: new Set(),
            };
        
        default:
            return state;
    }
}
import { UPDATE_WIDGET_SIZE, UPDATE_WIDGET_POSITION, REGISTER_WIDGET_CREATION, UNREGISTER_WIDGET_CREATION } from './types';

export function updateWidgetSize(width: number, height: number, widgetId: string) {
    return {
        type: UPDATE_WIDGET_SIZE,
        width,
        height,
        widgetId,
    }
}

export function updateWidgetPosition(x: number, y: number, widgetId: string) {
    return {
        type: UPDATE_WIDGET_POSITION,
        x,
        y,
        widgetId,
    }
}

export function registerWidgetCreation(widgetId: string) {
    return {
        type: REGISTER_WIDGET_CREATION,
        widgetId,
    }
}

export function unregisterWidgetCreation(widgetId: string) {
    return {
        type: UNREGISTER_WIDGET_CREATION,
        widgetId,
    }
}
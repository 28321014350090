import { SELECT_STATIC_DATA_HOLDER, UN_SELECT_STATIC_DATA_HOLDER, RE_ORDER_STATIC_DATA_HOLDERS, ADD_STATIC_DATA_HOLDER_REQUEST, ADD_STATIC_DATA_HOLDER, DELETE_STATIC_DATA_HOLDER, UPDATE_STATIC_DATA_HOLDER, SelectStaticDataHolderAction, UnSelectStaticDataHolderAction, ReOrderStaticDataHoldersAction, AddStaticDataHolderRequestAction, AddStaticDataHolderAction, UpdateStaticDataHolderAction, DeleteStaticDataHolderAction, ADD_DATA_FRAGMENT_TO_STATIC_DATA_HOLDER, REMOVE_DATA_FRAGMENT_FROM_STATIC_DATA_HOLDER, AddDataFragmentToStaticDataHolderAction, RemoveDataFragmentFromStaticDataHolderAction } from './types';
import { INewStaticDataHolderData, IUpdateableStaticDataHolderData } from './types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export function selectStaticDataHolder(id: string): SelectStaticDataHolderAction {
    return {
        type: SELECT_STATIC_DATA_HOLDER,
        id,
    }
}

export function unSelectStaticDataHolder(): UnSelectStaticDataHolderAction {
    return {
        type: UN_SELECT_STATIC_DATA_HOLDER,
    }
}

export function reOrderStaticDataHolders(sourceIndex: number, destinationIndex: number): ReOrderStaticDataHoldersAction {
    return {
        type: RE_ORDER_STATIC_DATA_HOLDERS,
        sourceIndex,
        destinationIndex,
    }
}

export function addStaticDataHolderRequest(payload: INewStaticDataHolderData): AddStaticDataHolderRequestAction {
    return {
        type: ADD_STATIC_DATA_HOLDER_REQUEST,
        payload,
    }
}

export function addStaticDataHolder(payload: INewStaticDataHolderData): AddStaticDataHolderAction {
    const now = moment.utc().format();

    return {
        type: ADD_STATIC_DATA_HOLDER,
        payload: {
            id: uuidv4(),
            ...payload,
            children: [],
            createdTime: now,
            lastUpdatedTime: now,
        },
        currentTime: now,
    }
}

export function updateStaticDataHolder(payload: IUpdateableStaticDataHolderData): UpdateStaticDataHolderAction {
    return {
        type: UPDATE_STATIC_DATA_HOLDER,
        payload,
        currentTime: moment.utc().format(),
    }
}

export function deleteStaticDataHolder(id: string): DeleteStaticDataHolderAction {
    return {
        type: DELETE_STATIC_DATA_HOLDER,
        id,
        currentTime: moment.utc().format(),
    }
}

export function addDataFragmentToStaticDataHolder(dataFragmentId: string, staticDataHolderId: string): AddDataFragmentToStaticDataHolderAction {
    return {
        type: ADD_DATA_FRAGMENT_TO_STATIC_DATA_HOLDER,
        dataFragmentId,
        staticDataHolderId,
    };
}

export function removeDataFragmentFromStaticDataHolder(dataFragmentId: string, staticDataHolderId: string): RemoveDataFragmentFromStaticDataHolderAction {
    return {
        type: REMOVE_DATA_FRAGMENT_FROM_STATIC_DATA_HOLDER,
        dataFragmentId,
        staticDataHolderId,
    };
}
import { NormalizedModel, CustomFieldDeltaTrackable } from '../../normalized-model';
import { SynchronizeUsersData } from '../../users/types';
import { SynchronizeMembersData } from '../../members/types';
import { SynchronizeGroupsData } from '../../groups/types';
import { ReOrderedListsForEntity } from '../../../helpers/common-types';
import { CustomFieldDataHolder } from '../../custom-fields/types';

export const SELECT_LOCATION = 'SELECT_LOCATION';
export const UN_SELECT_LOCATION = 'UN_SELECT_LOCATION';
export const RE_ORDER_LOCATIONS = 'RE_ORDER_LOCATIONS';
export const ADD_LOCATION_REQUEST = 'ADD_LOCATION_REQUEST';
export const ADD_LOCATION = 'ADD_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';

export const UPDATE_LOCATION_CUSTOM_FIELD_DATA = 'UPDATE_LOCATION_CUSTOM_FIELD_DATA';
export const ADD_USER_TO_LOCATION = 'ADD_USER_TO_LOCATION';
export const REMOVE_USER_FROM_LOCATION = 'REMOVE_USER_FROM_LOCATION';

export const ADD_MEMBER_TO_LOCATION = 'ADD_MEMBER_TO_LOCATION';
export const REMOVE_MEMBER_FROM_LOCATION = 'REMOVE_MEMBER_FROM_LOCATION';
export const ADD_GROUP_TO_LOCATION = 'ADD_GROUP_TO_LOCATION';
export const REMOVE_GROUP_FROM_LOCATION = 'REMOVE_GROUP_FROM_LOCATION';

export const BULK_ADD_MEMBERS_TO_LOCATIONS = 'BULK_ADD_MEMBERS_TO_LOCATIONS';
export const BULK_REMOVE_MEMBERS_FROM_LOCATIONS = 'BULK_REMOVE_MEMBERS_FROM_LOCATIONS';
export const BULK_ADD_GROUPS_TO_LOCATIONS = 'BULK_ADD_GROUPS_TO_LOCATIONS';
export const BULK_REMOVE_GROUPS_FROM_LOCATIONS = 'BULK_REMOVE_GROUPS_FROM_LOCATIONS';

export const UPDATE_LOCATIONS_DATA = 'UPDATE_LOCATIONS_DATA';
export const SYNCHRONIZE_LOCATIONS_DATA = 'SYNCHRONIZE_LOCATIONS_DATA';
export const CLEAR_LOCATIONS_DELTA = 'CLEAR_LOCATIONS_DELTA';


export interface INewLocationData {
    name: string;
    customFields: CustomFieldDataHolder;
}

export interface IUpdateableLocationData extends INewLocationData {
    id: string;
}

export interface ILocation extends IUpdateableLocationData, CustomFieldDeltaTrackable {
    children: Array<string>,
    archived?: boolean,
    parent?: string,
    users: Array<string>,
    members: Array<string>,
    groups: Array<string>,
}

export interface LocationState extends NormalizedModel<ILocation> {
    byProject: {
        [key: string]: Array<string>, // Array of top level locations for each project
    },
    selected: Array<string>,

    reOrderedTopLevelLocations: ReOrderedListsForEntity,
    reOrderedChildLocations: ReOrderedListsForEntity,
}

export interface SelectLocationAction {
    type: typeof SELECT_LOCATION,
    id: string,
    index: number,
}

export interface UnSelectLocationAction {
    type: typeof UN_SELECT_LOCATION,
    index: number,
}

export interface ReOrderLocationsAction {
    type: typeof RE_ORDER_LOCATIONS,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddLocationRequestAction {
    type: typeof ADD_LOCATION_REQUEST,
    payload: INewLocationData,
    parentId: string,
}

export interface AddLocationAction {
    type: typeof ADD_LOCATION,
    payload: ILocation,
    parentId: string,
    currentTime: string,
}

export interface DeleteLocationAction {
    type: typeof DELETE_LOCATION,
    id: string,
    parentId: string,
    currentTime: string,
}

export interface UpdateLocationAction {
    type: typeof UPDATE_LOCATION,
    payload: IUpdateableLocationData,
    currentTime: string,
}

export interface UpdateLocationCustomFieldDataAction {
    type: typeof UPDATE_LOCATION_CUSTOM_FIELD_DATA,
    locationId: string,
    customFieldData: CustomFieldDataHolder,
    currentTime: string,
}

export interface AddUserToLocationAction {
    type: typeof ADD_USER_TO_LOCATION,
    userId: string,
    locationId: string,
}

export interface RemoveUserFromLocationAction {
    type: typeof REMOVE_USER_FROM_LOCATION,
    userId: string,
    locationId: string,
}

export interface AddMemberToLocationAction {
    type: typeof ADD_MEMBER_TO_LOCATION,
    memberId: string,
    locationId: string,
}

export interface RemoveMemberFromLocationAction {
    type: typeof REMOVE_MEMBER_FROM_LOCATION,
    memberId: string,
    locationId: string,
}

export interface AddGroupToLocationAction {
    type: typeof ADD_GROUP_TO_LOCATION,
    groupId: string,
    locationId: string,
}

export interface RemoveGroupFromLocationAction {
    type: typeof REMOVE_GROUP_FROM_LOCATION,
    groupId: string,
    locationId: string,
}

export interface MemberLocationLink {
    memberId: string;
    locationId: string;
}

export interface BulkAddMembersToLocationsAction {
    type: typeof BULK_ADD_MEMBERS_TO_LOCATIONS;
    links: Array<MemberLocationLink>
}

export interface BulkRemoveMembersFromLocationsAction {
    type: typeof BULK_REMOVE_MEMBERS_FROM_LOCATIONS;
    links: Array<MemberLocationLink>;
}

export interface GroupLocationLink {
    groupId: string;
    locationId: string;
}

export interface BulkAddGroupsToLocationsAction {
    type: typeof BULK_ADD_GROUPS_TO_LOCATIONS;
    links: Array<GroupLocationLink>;
}

export interface BulkRemoveGroupsFromLocationsAction {
    type: typeof BULK_REMOVE_GROUPS_FROM_LOCATIONS;
    links: Array<GroupLocationLink>;
}

export interface UpdateLocationsData {
    type: typeof UPDATE_LOCATIONS_DATA,
    data: Array<ILocation>,
}

export interface SynchronizeLocationsData {
    type: typeof SYNCHRONIZE_LOCATIONS_DATA,
    data: Array<ILocation>,
    reOrderedTopLevelLocations: ReOrderedListsForEntity,
    reOrderedChildLocations: ReOrderedListsForEntity,
}

export interface ClearLocationsDelta {
    type: typeof CLEAR_LOCATIONS_DELTA,
}

export type LocationActionTypes = SelectLocationAction | UnSelectLocationAction | ReOrderLocationsAction | AddLocationRequestAction | AddLocationAction | DeleteLocationAction | UpdateLocationAction | UpdateLocationCustomFieldDataAction | AddUserToLocationAction | RemoveUserFromLocationAction | AddMemberToLocationAction | RemoveMemberFromLocationAction | AddGroupToLocationAction | RemoveGroupFromLocationAction | BulkAddMembersToLocationsAction | BulkRemoveMembersFromLocationsAction | BulkAddGroupsToLocationsAction | BulkRemoveGroupsFromLocationsAction | UpdateLocationsData | SynchronizeLocationsData | ClearLocationsDelta | SynchronizeUsersData | SynchronizeMembersData | SynchronizeGroupsData;
import { SELECT_LANGUAGE, UN_SELECT_LANGUAGE, ADD_LANGUAGE, DELETE_LANGUAGE, UPDATE_LANGUAGE, RE_ORDER_LANGUAGES, LanguageState, UPDATE_LANGUAGES_DATA, SYNCHRONIZE_LANGUAGES_DATA, CLEAR_LANGUAGES_DELTA, ILanguage } from './types';
import { InternationalizationActionTypes, UPDATE_INTERNATIONALIZATION_DATA } from '../types';
import { reOrderList } from '../../../helpers/utilities';
import { updateEntries, synchronizeEntries, clearDelta, addEntity, updateEntity, deleteEntity } from '../../normalized-model';

const initialState: LanguageState = {  
    byId:{},
    allEntries: [],
    filteredEntries: [],
    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),
};

export function languagesReducer(state = initialState, action: InternationalizationActionTypes): LanguageState {
    switch(action.type) {

        case SELECT_LANGUAGE:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_LANGUAGE:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_LANGUAGES:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
            };

        case ADD_LANGUAGE:
            return addEntity<LanguageState, ILanguage>(state, action.payload);

        case UPDATE_LANGUAGE:
            return updateEntity<LanguageState, ILanguage>(state, action.payload, action.currentTime);

        case DELETE_LANGUAGE:
            return deleteEntity<LanguageState, ILanguage>(state, action.id, action.currentTime);

        case UPDATE_LANGUAGES_DATA:
            return updateEntries<LanguageState, ILanguage>(state, action.data);

        case SYNCHRONIZE_LANGUAGES_DATA:
            return synchronizeEntries<LanguageState, ILanguage>(state, action.data);

        case CLEAR_LANGUAGES_DELTA:
            return clearDelta<LanguageState, ILanguage>(state);

            
        // Internationalization actions
        case UPDATE_INTERNATIONALIZATION_DATA:
            return {
                ...action.data.languages,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
            }
        

        default:
            return state
    }
}
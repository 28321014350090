import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', loadChildren: './login/login.module#LoginPageModule' },
	{ path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },
	{ path: 'near', loadChildren: './near/near.module#NearPageModule' },
	{ path: 'edit-profile', loadChildren: './edit-profile/edit-profile.module#EditProfilePageModule' },
	{ path: 'calendar', loadChildren: './calendar/calendar.module#CalendarPageModule' },
	{ path: 'synclog', loadChildren: './synclog/synclog.module#SynclogPageModule' },
	{ path: 'forgot-password', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordPageModule' },
	{ path: 'release-notes', loadChildren: './release-notes/release-notes.module#ReleaseNotesPageModule' },
  	{ path: 'bug-report', loadChildren: './bug-report/bug-report.module#BugReportPageModule' },
  	{ path: 'fetch-older-data', loadChildren: './fetch-older-data/fetch-older-data.module#FetchOlderDataPageModule' },
  	{ path: 'refresh-data', loadChildren: './refresh-data/refresh-data.module#RefreshDataPageModule' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
    
}

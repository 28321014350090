import { SELECT_STATUS, UN_SELECT_STATUS, RE_ORDER_STATUSES, ADD_STATUS, DELETE_STATUS, UPDATE_STATUS, INewStatusData, IUpdateableStatusData, SelectStatusAction, UnSelectStatusAction, ReOrderStatusAction, AddStatusAction, DeleteStatusAction, UpdateStatusAction, InstantiateStatusAction, INSTANTIATE_STATUS } from './types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export function selectStatus(id: string): SelectStatusAction {
    return {
        type: SELECT_STATUS,
        id,
    }
}

export function unSelectStatus(): UnSelectStatusAction {
    return {
        type: UN_SELECT_STATUS,
    }
}

export function reOrderStatuses(sourceIndex: number, destinationIndex: number, parentId: string): ReOrderStatusAction {
    return {
        type: RE_ORDER_STATUSES,
        sourceIndex,
        destinationIndex,
        parentId,
    }
}

export function addStatus(payload: INewStatusData, parentId: string): AddStatusAction {
    const now = moment.utc().format();

    return {
        type: ADD_STATUS,
        payload: {
            id: uuidv4(),
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            parentId,
        },
        parentId,
        currentTime: now,
    }
}

export function instantiateStatus(payload: IUpdateableStatusData, parentId: string): InstantiateStatusAction {
    const now = moment.utc().format();

    return {
        type: INSTANTIATE_STATUS,
        payload: {
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            parentId,
        },
        parentId,
        currentTime: now,
    }
}

export function deleteStatus(id: string, parentId: string): DeleteStatusAction {
    return {
        type: DELETE_STATUS,
        id,
        parentId,
        currentTime: moment.utc().format(),
    }
}

export function updateStatus(payload: IUpdateableStatusData): UpdateStatusAction {
    return {
        type: UPDATE_STATUS,
        payload,
        currentTime: moment.utc().format(),
    }
}
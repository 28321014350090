import { Permissions, PermissionActionTypes, PermissionSet, PermissionState, UPDATE_GENERAL_PERMISSION, DEFAULT_GENERAL_PERMISSIONS, UPDATE_PERMISSION_DATA, UPDATE_MEMBER_PERMISSION, UPDATE_GROUP_PERMISSION, UPDATE_WORKFLOW_PERMISSION, UPDATE_REPORT_PERMISSION, SET_MY_PERMISSIONS, RolePermissionActionTypes, UPDATE_PERMISSIONS_DATA, SYNCHRONIZE_PERMISSIONS_DATA, CLEAR_PERMISSIONS_DELTA, UPDATE_LANGUAGE_PERMISSION } from './types';

const initialState: PermissionState = {
    myPermissions: {
        general: {
            Hierarchy: Permissions.WRITE,
            Locations: Permissions.WRITE,
            Users: Permissions.WRITE,
            UserConfiguration: Permissions.WRITE,
            Members: Permissions.WRITE,
            MembersConfiguration: Permissions.WRITE,
            GroupsConfiguration: Permissions.WRITE,
            Groups: Permissions.WRITE,
            Workflows: Permissions.WRITE,
            WorkflowsConfiguration: Permissions.WRITE,
            OrganizationProfile: Permissions.WRITE,
            Languages: Permissions.WRITE,
            LanguagesConfiguration: Permissions.WRITE,
            Reports: Permissions.WRITE,
            ReportsConfiguration: Permissions.WRITE,
        },
        members: {},
        groups: {},
        workflows: {},
        reports: {},
        languages: {},
    },
    rolePermissions: {},
    updatedIds: new Set(),
};

function getOldPermissions(state: PermissionState, action: RolePermissionActionTypes) {
    const oldPermissions: PermissionSet = {
        general: state.rolePermissions[action.role] && state.rolePermissions[action.role].general ? state.rolePermissions[action.role].general : DEFAULT_GENERAL_PERMISSIONS,

        members: state.rolePermissions[action.role] && state.rolePermissions[action.role].members ? state.rolePermissions[action.role].members : {},
        groups: state.rolePermissions[action.role] && state.rolePermissions[action.role].groups ? state.rolePermissions[action.role].groups : {},
        workflows: state.rolePermissions[action.role] && state.rolePermissions[action.role].workflows ? state.rolePermissions[action.role].workflows : {},
        reports: state.rolePermissions[action.role] && state.rolePermissions[action.role].reports ? state.rolePermissions[action.role].reports : {},
        languages: state.rolePermissions[action.role] && state.rolePermissions[action.role].languages ? state.rolePermissions[action.role].languages : {},
    };

    return oldPermissions;
}

export function permissionsReducer(state = initialState, action: PermissionActionTypes): PermissionState {
    let oldPermissions: PermissionSet, newPermissions: PermissionSet;
    let newState: PermissionState;

    switch(action.type) {
        case SET_MY_PERMISSIONS:
            return {
                ...state,
                myPermissions: action.permissions,
            }

        case UPDATE_GENERAL_PERMISSION:

            oldPermissions = getOldPermissions(state, action);

            newPermissions = {
                ...oldPermissions,
                general: {
                    ...oldPermissions.general,
                    [action.id]: action.permission,
                }
            }

            return {
                ...state,
                rolePermissions: {
                    ...state.rolePermissions,
                    [action.role]: newPermissions
                },
                updatedIds: new Set([...state.updatedIds, action.role]),
            };

        case UPDATE_MEMBER_PERMISSION:

            oldPermissions = getOldPermissions(state, action);

            newPermissions = {
                ...oldPermissions,
                members: {
                    ...oldPermissions.members,
                    [action.memberTypeId]: action.permission,
                }
            }

            return {
                ...state,
                rolePermissions: {
                    ...state.rolePermissions,
                    [action.role]: newPermissions
                },
                updatedIds: new Set([...state.updatedIds, action.role]),
            };

        case UPDATE_GROUP_PERMISSION:

            oldPermissions = getOldPermissions(state, action);

            newPermissions = {
                ...oldPermissions,
                groups: {
                    ...oldPermissions.groups,
                    [action.groupTypeId]: action.permission,
                }
            }

            return {
                ...state,
                rolePermissions: {
                    ...state.rolePermissions,
                    [action.role]: newPermissions
                },
                updatedIds: new Set([...state.updatedIds, action.role]),
            };

        case UPDATE_WORKFLOW_PERMISSION:

            oldPermissions = getOldPermissions(state, action);

            newPermissions = {
                ...oldPermissions,
                workflows: {
                    ...oldPermissions.workflows,
                    [action.workflowTypeId]: action.permission,
                }
            }

            return {
                ...state,
                rolePermissions: {
                    ...state.rolePermissions,
                    [action.role]: newPermissions
                },
                updatedIds: new Set([...state.updatedIds, action.role]),
            };

        case UPDATE_REPORT_PERMISSION:

            oldPermissions = getOldPermissions(state, action);

            newPermissions = {
                ...oldPermissions,
                reports: {
                    ...oldPermissions.reports,
                    [action.reportTypeId]: action.permission,
                }
            }

            return {
                ...state,
                rolePermissions: {
                    ...state.rolePermissions,
                    [action.role]: newPermissions
                },
                updatedIds: new Set([...state.updatedIds, action.role]),
            };

        case UPDATE_LANGUAGE_PERMISSION:

            oldPermissions = getOldPermissions(state, action);

            newPermissions = {
                ...oldPermissions,
                languages: {
                    ...oldPermissions.languages,
                    [action.languageId]: action.permission,
                }
            }

            return {
                ...state,
                rolePermissions: {
                    ...state.rolePermissions,
                    [action.role]: newPermissions
                },
                updatedIds: new Set([...state.updatedIds, action.role]),
            };

        case UPDATE_PERMISSIONS_DATA:
            newState = {
                ...state,
                rolePermissions: {
                    ...state.rolePermissions,
                }
            };

            for (const rolePermission of action.data) {
                newState.rolePermissions[rolePermission.role] = rolePermission;
            }

            return newState;

        case SYNCHRONIZE_PERMISSIONS_DATA:
            newState = {
                ...state,
                rolePermissions: {
                    ...state.rolePermissions,
                }
            };

            for (const rolePermission of action.data) {
                newState.rolePermissions[rolePermission.role] = rolePermission;
            }

            return newState;

        case CLEAR_PERMISSIONS_DELTA:
            return {
                ...state,
                updatedIds: new Set(),
            };

        case UPDATE_PERMISSION_DATA:
            return {
                ...action.data,
                updatedIds: state.updatedIds,
            }
        default:
            return state;
    }
}
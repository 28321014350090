export const SAMPLE_PROJECT_WORKFLOW_DATA = {
    "workflowType": {
        "id": "7ec0016a-3e1c-44ac-a3bc-688b68119b0d",
        "name": "New Visit Record",
        "affiliation": "member",
        "affiliatedEntity": "dbc873fa-86d7-4b02-923e-bcf7eedadc8a",
        "isCore": true,
        "seedEntityVariable": "fd7aac9b-4c5c-4d85-918a-b50f545529f5",
        "seedAffiliationVariable": "26af5a3e-819f-46dd-8de3-f3aee3f203d3",
        "areMultipleInstancesAllowed": false,
        "startPiece": {
            "piece": "92f22230-c55d-4c51-898c-c95de6deabd8",
            "position": {
                "x": 0,
                "y": 0
            }
        },
        "variables": [
            "fd7aac9b-4c5c-4d85-918a-b50f545529f5",
            "26af5a3e-819f-46dd-8de3-f3aee3f203d3"
        ],
        "statuses": [
            {
                "id": "7b305cc3-403a-4aec-a1e4-d23b85cfefe3",
                "name": "Open",
                "isTerminal": false,
                "dueInDays": 7
            },
            {
                "id": "09626b0f-5e3e-4d09-857e-f052314a0096",
                "name": "In Progress",
                "isTerminal": false,
                "dueInDays": 7
            },
            {
                "id": "0859b911-9ff5-4307-97b6-934e664f12e6",
                "name": "Visit Completed",
                "isTerminal": true,
                "dueInDays": 7
            }
        ],
        "customFields": [
            {
                "id": "8005ac82-4b39-4c54-9ee0-5af9ab037544",
                "name": "Vitals - Systolic BP",
                "type": "NUMBER",
                "isComputed": false,
                "isInTable": false,
                "isEditable": true,
                "isDeletable": true,
                "affiliation": "member",
                "variables": [],
                "options": []
            },
            {
                "id": "5f0a65e1-5d5f-4039-87ee-15fb7893fe88",
                "name": "Vitals - Diastolic BP",
                "type": "NUMBER",
                "isComputed": false,
                "isInTable": false,
                "isEditable": true,
                "isDeletable": true,
                "affiliation": "member",
                "variables": [],
                "options": []
            },
            {
                "id": "a8ac89bd-6b6b-4d4b-ad6e-690acdf78b0c",
                "name": "Current Weight (Kgs)",
                "type": "NUMBER",
                "isComputed": false,
                "isInTable": false,
                "isEditable": true,
                "isDeletable": true,
                "affiliation": "member",
                "variables": [],
                "options": []
            },
            {
                "id": "b7e950ec-40e3-4a55-8985-9b4f909700e2",
                "name": "Visit Date",
                "type": "DATE",
                "isComputed": false,
                "isInTable": false,
                "isEditable": true,
                "isDeletable": true,
                "affiliation": "member",
                "variables": [],
                "options": []
            },
            {
                "id": "2dffa77c-5570-4412-ae64-b28529ab8173",
                "name": "Visible Symptoms",
                "type": "MULTI_SELECT",
                "isComputed": false,
                "isInTable": false,
                "isEditable": true,
                "isDeletable": true,
                "affiliation": "member",
                "variables": [],
                "options": [
                    {
                        "id": "864a63b1-fe6d-4500-b343-2eb93e767fad",
                        "name": "Fever"
                    },
                    {
                        "id": "2cab175f-0347-490a-b782-c6a67bf92904",
                        "name": "Cold - runny nose"
                    },
                    {
                        "id": "f1c84c3a-01dc-43fa-85c6-aea198be2ae2",
                        "name": "Ache - Head/Body"
                    },
                    {
                        "id": "1515e19e-599e-47f7-a54e-014379f204e5",
                        "name": "Weakness"
                    }
                ]
            },
            {
                "id": "2377d5d4-11b6-4bb0-9ed3-72d1048e840f",
                "name": "COVID Vaccine Status",
                "type": "SINGLE_SELECT",
                "isComputed": false,
                "isInTable": false,
                "isEditable": true,
                "isDeletable": true,
                "affiliation": "member",
                "variables": [],
                "options": [
                    {
                        "id": "ddef4752-a357-475d-b1a6-b64d7fbe30d2",
                        "name": "Dose 1 completed"
                    },
                    {
                        "id": "26c1b531-81c6-46e0-b254-d941ab6bcffd",
                        "name": "Dose 2 completed"
                    },
                    {
                        "id": "0e8c10eb-0bf4-4c82-99b1-ad63db4d8339",
                        "name": "Booster completed"
                    },
                    {
                        "id": "06caaf20-a36b-4ede-8c4c-cf26b1484931",
                        "name": "Not vaccinated"
                    },
                    {
                        "id": "0b514020-5e33-495f-b266-dfb0b6bfee77",
                        "name": "Not eligible"
                    }
                ]
            }
        ]
    },
    "pieceState": {
        "byId": {
            "e277bdad-c64b-4d26-b6ad-cba685684045": {
                "id": "e277bdad-c64b-4d26-b6ad-cba685684045",
                "type": "END",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-05T18:43:36Z",
                "status": "0859b911-9ff5-4307-97b6-934e664f12e6"
            },
            "cb601c82-d157-4142-9a10-332f0ce64fdd": {
                "id": "cb601c82-d157-4142-9a10-332f0ce64fdd",
                "type": "HEXAGONAL_TRUE",
                "createdTime": "2022-03-09T14:27:45+05:30",
                "lastUpdatedTime": "2022-03-09T08:57:45Z"
            },
            "f9f7989f-2f61-49fb-bb83-00c59a710d99": {
                "id": "f9f7989f-2f61-49fb-bb83-00c59a710d99",
                "type": "GROUPED_QUESTION",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-09T08:57:51Z",
                "customFieldId": "2377d5d4-11b6-4bb0-9ed3-72d1048e840f",
                "isRequiredPiece": "cb601c82-d157-4142-9a10-332f0ce64fdd"
            },
            "2205534d-c2c2-4da1-8e4c-89d98961e815": {
                "id": "2205534d-c2c2-4da1-8e4c-89d98961e815",
                "type": "HEXAGONAL_TRUE",
                "createdTime": "2022-03-09T14:27:47+05:30",
                "lastUpdatedTime": "2022-03-09T08:57:47Z"
            },
            "18a7badd-0e2b-4bb8-b972-03c2c28f2ace": {
                "id": "18a7badd-0e2b-4bb8-b972-03c2c28f2ace",
                "type": "GROUPED_QUESTION",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-09T08:57:49Z",
                "customFieldId": "2dffa77c-5570-4412-ae64-b28529ab8173",
                "nextPiece": "f9f7989f-2f61-49fb-bb83-00c59a710d99",
                "isRequiredPiece": "2205534d-c2c2-4da1-8e4c-89d98961e815"
            },
            "75b4f9e2-83bf-49d7-9385-595c57aa3372": {
                "id": "75b4f9e2-83bf-49d7-9385-595c57aa3372",
                "type": "SECTION",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-05T18:43:36Z",
                "heading": "Patient Review and Vaccination Status",
                "columns": "1",
                "innerPiece": "18a7badd-0e2b-4bb8-b972-03c2c28f2ace"
            },
            "55fe20d0-64c8-4f6a-9556-03b9d786e2a0": {
                "id": "55fe20d0-64c8-4f6a-9556-03b9d786e2a0",
                "type": "CUSTOM_FIELD",
                "createdTime": "2022-03-09T14:38:13+05:30",
                "lastUpdatedTime": "2022-03-09T09:08:13Z",
                "customField": "8005ac82-4b39-4c54-9ee0-5af9ab037544"
            },
            "23172817-f5e6-4fdc-9540-249afffa4e16": {
                "id": "23172817-f5e6-4fdc-9540-249afffa4e16",
                "type": "ADD",
                "createdTime": "2022-03-09T14:38:13+05:30",
                "lastUpdatedTime": "2022-03-09T09:08:19Z",
                "leftOperand": "Diastolic BP: ",
                "rightOperand": "55fe20d0-64c8-4f6a-9556-03b9d786e2a0"
            },
            "806ad028-6f4d-4619-a066-1aa006085fa5": {
                "id": "806ad028-6f4d-4619-a066-1aa006085fa5",
                "type": "GROUPED_SHOW",
                "createdTime": "2022-03-09T14:38:13+05:30",
                "lastUpdatedTime": "2022-03-09T09:08:13Z",
                "variableToShow": "23172817-f5e6-4fdc-9540-249afffa4e16"
            },
            "b15ddb2b-ed14-4f81-ade2-b176e813dc40": {
                "id": "b15ddb2b-ed14-4f81-ade2-b176e813dc40",
                "type": "CUSTOM_FIELD",
                "createdTime": "2022-03-09T14:37:56+05:30",
                "lastUpdatedTime": "2022-03-09T09:08:11Z",
                "customField": "8005ac82-4b39-4c54-9ee0-5af9ab037544"
            },
            "82a12d60-f4c5-415d-a7a1-f2aefa9e1c54": {
                "id": "82a12d60-f4c5-415d-a7a1-f2aefa9e1c54",
                "type": "ADD",
                "createdTime": "2022-03-09T14:37:56+05:30",
                "lastUpdatedTime": "2022-03-09T09:08:06Z",
                "leftOperand": "Systolic BP: ",
                "rightOperand": "b15ddb2b-ed14-4f81-ade2-b176e813dc40"
            },
            "ca1e180c-6dcd-4ca4-8c00-5c1055dbdbe2": {
                "id": "ca1e180c-6dcd-4ca4-8c00-5c1055dbdbe2",
                "type": "GROUPED_SHOW",
                "createdTime": "2022-03-09T14:37:56+05:30",
                "lastUpdatedTime": "2022-03-09T09:08:15Z",
                "variableToShow": "82a12d60-f4c5-415d-a7a1-f2aefa9e1c54",
                "nextPiece": "806ad028-6f4d-4619-a066-1aa006085fa5"
            },
            "a86da3dd-b1c6-4566-9ab4-422252dd566a": {
                "id": "a86da3dd-b1c6-4566-9ab4-422252dd566a",
                "type": "CUSTOM_FIELD",
                "createdTime": "2022-03-09T14:37:46+05:30",
                "lastUpdatedTime": "2022-03-09T09:07:49Z",
                "customField": "a8ac89bd-6b6b-4d4b-ad6e-690acdf78b0c"
            },
            "bf159d49-5018-4b78-a4c8-52f5dc4f7f52": {
                "id": "bf159d49-5018-4b78-a4c8-52f5dc4f7f52",
                "type": "ADD",
                "createdTime": "2022-03-09T14:37:36+05:30",
                "lastUpdatedTime": "2022-03-09T09:07:55Z",
                "leftOperand": "Weight: ",
                "rightOperand": "a86da3dd-b1c6-4566-9ab4-422252dd566a"
            },
            "61ff11a6-772d-453b-8734-ca15193aef3d": {
                "id": "61ff11a6-772d-453b-8734-ca15193aef3d",
                "type": "GROUPED_SHOW",
                "createdTime": "2022-03-09T14:37:08+05:30",
                "lastUpdatedTime": "2022-03-09T09:07:59Z",
                "variableToShow": "bf159d49-5018-4b78-a4c8-52f5dc4f7f52",
                "nextPiece": "ca1e180c-6dcd-4ca4-8c00-5c1055dbdbe2"
            },
            "85742991-56d3-4da5-95bc-64277eca0c4d": {
                "id": "85742991-56d3-4da5-95bc-64277eca0c4d",
                "type": "SECTION",
                "createdTime": "2022-03-09T14:37:14+05:30",
                "lastUpdatedTime": "2022-03-09T09:10:24Z",
                "innerPiece": "61ff11a6-772d-453b-8734-ca15193aef3d",
                "heading": "Vitals collected during this visit shown below",
                "columns": "1",
                "nextPiece": "75b4f9e2-83bf-49d7-9385-595c57aa3372",
                "archived": true
            },
            "a2b36598-c6e4-43bd-b830-2e3cd2d4f31f": {
                "id": "a2b36598-c6e4-43bd-b830-2e3cd2d4f31f",
                "type": "GROUP",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-09T09:08:27Z",
                "innerPiece": "85742991-56d3-4da5-95bc-64277eca0c4d",
                "heading": "COWIN status",
                "nextPiece": "e277bdad-c64b-4d26-b6ad-cba685684045"
            },
            "7a0574a4-4e74-47e0-a0c0-2d90a1f594ff": {
                "id": "7a0574a4-4e74-47e0-a0c0-2d90a1f594ff",
                "type": "TODAY",
                "createdTime": "2022-03-09T14:33:40+05:30",
                "lastUpdatedTime": "2022-03-09T09:03:40Z"
            },
            "b499dc6a-d1eb-4e23-adec-90570eb14e75": {
                "id": "b499dc6a-d1eb-4e23-adec-90570eb14e75",
                "type": "VARIABLE",
                "createdTime": "2022-03-09T14:33:31+05:30",
                "lastUpdatedTime": "2022-03-09T09:03:33Z",
                "variable": "fd7aac9b-4c5c-4d85-918a-b50f545529f5",
                "nesting": []
            },
            "a51881d2-3427-4492-82da-05436440e82e": {
                "id": "a51881d2-3427-4492-82da-05436440e82e",
                "type": "STORE",
                "createdTime": "2022-03-09T14:33:27+05:30",
                "lastUpdatedTime": "2022-03-09T09:04:01Z",
                "dataToStore": "7a0574a4-4e74-47e0-a0c0-2d90a1f594ff",
                "variablePiece": "b499dc6a-d1eb-4e23-adec-90570eb14e75",
                "entityType": "7ec0016a-3e1c-44ac-a3bc-688b68119b0d",
                "customFieldId": "b7e950ec-40e3-4a55-8985-9b4f909700e2",
                "nextPiece": "a2b36598-c6e4-43bd-b830-2e3cd2d4f31f"
            },
            "e5f17666-977a-4766-bea2-37763bb72ea3": {
                "id": "e5f17666-977a-4766-bea2-37763bb72ea3",
                "type": "HEXAGONAL_TRUE",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-05T18:43:36Z"
            },
            "9817ac4d-9363-4582-9755-6dc904012204": {
                "id": "9817ac4d-9363-4582-9755-6dc904012204",
                "type": "GROUPED_QUESTION",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-05T18:49:49Z",
                "isRequiredPiece": "e5f17666-977a-4766-bea2-37763bb72ea3",
                "customFieldId": "5f0a65e1-5d5f-4039-87ee-15fb7893fe88"
            },
            "ea3454d8-9265-4c41-bfdf-c54fcdc6a417": {
                "id": "ea3454d8-9265-4c41-bfdf-c54fcdc6a417",
                "type": "HEXAGONAL_TRUE",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-05T18:43:36Z"
            },
            "6a05b829-a7ba-40dc-bbf5-03336e5f26a0": {
                "id": "6a05b829-a7ba-40dc-bbf5-03336e5f26a0",
                "type": "GROUPED_QUESTION",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-05T18:49:45Z",
                "nextPiece": "9817ac4d-9363-4582-9755-6dc904012204",
                "isRequiredPiece": "ea3454d8-9265-4c41-bfdf-c54fcdc6a417",
                "customFieldId": "8005ac82-4b39-4c54-9ee0-5af9ab037544"
            },
            "e774febb-0e31-4baf-86e4-39d60603cf12": {
                "id": "e774febb-0e31-4baf-86e4-39d60603cf12",
                "type": "SECTION",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-05T18:43:36Z",
                "columns": "1",
                "innerPiece": "6a05b829-a7ba-40dc-bbf5-03336e5f26a0",
                "heading": "BP details"
            },
            "982c1b5b-b750-4adc-b735-75f6264b2a33": {
                "id": "982c1b5b-b750-4adc-b735-75f6264b2a33",
                "type": "GROUPED_ANSWER",
                "createdTime": "2022-03-09T14:35:12+05:30",
                "lastUpdatedTime": "2022-03-09T09:05:30Z",
                "customField": "a8ac89bd-6b6b-4d4b-ad6e-690acdf78b0c"
            },
            "06a69c48-0702-42f1-bc86-85b663d855f5": {
                "id": "06a69c48-0702-42f1-bc86-85b663d855f5",
                "type": "GREATER_THAN",
                "createdTime": "2022-03-09T14:35:24+05:30",
                "lastUpdatedTime": "2022-03-09T09:05:39Z",
                "leftOperand": "982c1b5b-b750-4adc-b735-75f6264b2a33",
                "rightOperand": "250"
            },
            "a8f8cd54-0bb0-4bab-954d-8b9c75d5b574": {
                "id": "a8f8cd54-0bb0-4bab-954d-8b9c75d5b574",
                "type": "ERROR",
                "createdTime": "2022-03-09T14:35:43+05:30",
                "lastUpdatedTime": "2022-03-09T09:05:51Z",
                "error": "Weight cannot be more than 250 Kgs"
            },
            "61f21957-0486-41d6-b56a-22dea01a639b": {
                "id": "61f21957-0486-41d6-b56a-22dea01a639b",
                "type": "SPLIT",
                "createdTime": "2022-03-09T14:35:16+05:30",
                "lastUpdatedTime": "2022-03-09T09:05:45Z",
                "ifPieceData": [
                    {
                        "conditionPiece": "06a69c48-0702-42f1-bc86-85b663d855f5",
                        "nextPiece": "a8f8cd54-0bb0-4bab-954d-8b9c75d5b574"
                    }
                ]
            },
            "cac1f177-38d5-4c60-8e19-a5b2e319538e": {
                "id": "cac1f177-38d5-4c60-8e19-a5b2e319538e",
                "type": "HEXAGONAL_TRUE",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-05T18:43:36Z"
            },
            "2c2fc238-f1dc-4306-9a99-4263d7381dc1": {
                "id": "2c2fc238-f1dc-4306-9a99-4263d7381dc1",
                "type": "GROUPED_QUESTION",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-09T09:06:32Z",
                "customFieldId": "a8ac89bd-6b6b-4d4b-ad6e-690acdf78b0c",
                "isRequiredPiece": "cac1f177-38d5-4c60-8e19-a5b2e319538e",
                "innerPiece": "61f21957-0486-41d6-b56a-22dea01a639b",
                "question": "Please enter Weight of the Member - weight more than 250 kgs not allowed"
            },
            "77041d64-1ca6-432c-aaeb-2873d3ec4f78": {
                "id": "77041d64-1ca6-432c-aaeb-2873d3ec4f78",
                "type": "SECTION",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-05T18:43:36Z",
                "nextPiece": "e774febb-0e31-4baf-86e4-39d60603cf12",
                "columns": "1",
                "innerPiece": "2c2fc238-f1dc-4306-9a99-4263d7381dc1",
                "heading": "Weight"
            },
            "4b7c094a-cc2b-4d0b-b738-02294b04c730": {
                "id": "4b7c094a-cc2b-4d0b-b738-02294b04c730",
                "type": "GROUP",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-09T09:04:04Z",
                "innerPiece": "77041d64-1ca6-432c-aaeb-2873d3ec4f78",
                "heading": "Collect Vitals",
                "nextPiece": "a51881d2-3427-4492-82da-05436440e82e"
            },
            "a0b1e88b-3583-4280-89f0-01d73d23ea9f": {
                "id": "a0b1e88b-3583-4280-89f0-01d73d23ea9f",
                "type": "UPDATE_STATUS",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-05T18:43:36Z",
                "status": "09626b0f-5e3e-4d09-857e-f052314a0096",
                "nextPiece": "4b7c094a-cc2b-4d0b-b738-02294b04c730"
            },
            "92f22230-c55d-4c51-898c-c95de6deabd8": {
                "id": "92f22230-c55d-4c51-898c-c95de6deabd8",
                "type": "START",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-05T18:43:36Z",
                "nextPiece": "a0b1e88b-3583-4280-89f0-01d73d23ea9f"
            }
        },
        "allEntries": [
            "e277bdad-c64b-4d26-b6ad-cba685684045",
            "cb601c82-d157-4142-9a10-332f0ce64fdd",
            "f9f7989f-2f61-49fb-bb83-00c59a710d99",
            "2205534d-c2c2-4da1-8e4c-89d98961e815",
            "18a7badd-0e2b-4bb8-b972-03c2c28f2ace",
            "75b4f9e2-83bf-49d7-9385-595c57aa3372",
            "55fe20d0-64c8-4f6a-9556-03b9d786e2a0",
            "23172817-f5e6-4fdc-9540-249afffa4e16",
            "806ad028-6f4d-4619-a066-1aa006085fa5",
            "b15ddb2b-ed14-4f81-ade2-b176e813dc40",
            "82a12d60-f4c5-415d-a7a1-f2aefa9e1c54",
            "ca1e180c-6dcd-4ca4-8c00-5c1055dbdbe2",
            "a86da3dd-b1c6-4566-9ab4-422252dd566a",
            "bf159d49-5018-4b78-a4c8-52f5dc4f7f52",
            "61ff11a6-772d-453b-8734-ca15193aef3d",
            "85742991-56d3-4da5-95bc-64277eca0c4d",
            "a2b36598-c6e4-43bd-b830-2e3cd2d4f31f",
            "7a0574a4-4e74-47e0-a0c0-2d90a1f594ff",
            "b499dc6a-d1eb-4e23-adec-90570eb14e75",
            "a51881d2-3427-4492-82da-05436440e82e",
            "e5f17666-977a-4766-bea2-37763bb72ea3",
            "9817ac4d-9363-4582-9755-6dc904012204",
            "ea3454d8-9265-4c41-bfdf-c54fcdc6a417",
            "6a05b829-a7ba-40dc-bbf5-03336e5f26a0",
            "e774febb-0e31-4baf-86e4-39d60603cf12",
            "982c1b5b-b750-4adc-b735-75f6264b2a33",
            "06a69c48-0702-42f1-bc86-85b663d855f5",
            "a8f8cd54-0bb0-4bab-954d-8b9c75d5b574",
            "61f21957-0486-41d6-b56a-22dea01a639b",
            "cac1f177-38d5-4c60-8e19-a5b2e319538e",
            "2c2fc238-f1dc-4306-9a99-4263d7381dc1",
            "77041d64-1ca6-432c-aaeb-2873d3ec4f78",
            "4b7c094a-cc2b-4d0b-b738-02294b04c730",
            "a0b1e88b-3583-4280-89f0-01d73d23ea9f",
            "92f22230-c55d-4c51-898c-c95de6deabd8"
        ],
        "filteredEntries": [],
        "createdIds": {},
        "updatedIds": {},
        "deletedIds": {}
    },
    "variableState": {
        "byId": {
            "fd7aac9b-4c5c-4d85-918a-b50f545529f5": {
                "id": "fd7aac9b-4c5c-4d85-918a-b50f545529f5",
                "name": "Workflow",
                "type": "WORKFLOW",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-05T18:43:36Z"
            },
            "26af5a3e-819f-46dd-8de3-f3aee3f203d3": {
                "id": "26af5a3e-819f-46dd-8de3-f3aee3f203d3",
                "name": "Member",
                "type": "MEMBER",
                "createdTime": "2022-03-06T00:13:36+05:30",
                "lastUpdatedTime": "2022-03-05T18:43:36Z"
            }
        },
        "allEntries": [
            "fd7aac9b-4c5c-4d85-918a-b50f545529f5",
            "26af5a3e-819f-46dd-8de3-f3aee3f203d3"
        ],
        "filteredEntries": [],
        "createdIds": {},
        "updatedIds": {},
        "deletedIds": {}
    },
    "affiliation": {
        "id": "dbc873fa-86d7-4b02-923e-bcf7eedadc8a",
        "name": "SAMPLE Patient",
        "nameFieldId": "6bcf410d-1f0f-4302-80ec-d92db2e664c0",
        "subTitleFieldId": "9429a573-bf14-48d4-b4dd-3bb6e9ed66ac",
        "locationFieldId": "62e7b2a3-969f-4a2c-8529-fc6cfcf75894",
        "customFields": [
            {
                "id": "6bcf410d-1f0f-4302-80ec-d92db2e664c0",
                "name": "Name",
                "type": "TEXT",
                "isComputed": false,
                "isInTable": true,
                "isEditable": true,
                "isDeletable": false,
                "seedEntityVariable": "d1a4987b-da50-446a-8beb-f9bad1749153",
                "variables": [],
                "options": []
            },
            {
                "id": "9429a573-bf14-48d4-b4dd-3bb6e9ed66ac",
                "name": "Sub Title",
                "type": "TEXT",
                "isComputed": false,
                "isInTable": true,
                "isEditable": true,
                "isDeletable": false,
                "seedEntityVariable": "b3c0e3e0-f2e3-42b7-8c2e-76b8e80c0756",
                "variables": [],
                "options": []
            },
            {
                "id": "62e7b2a3-969f-4a2c-8529-fc6cfcf75894",
                "name": "Last seen",
                "type": "LOCATION",
                "isComputed": false,
                "isInTable": false,
                "isEditable": false,
                "isDeletable": false,
                "seedEntityVariable": "7ade043e-3356-4399-9d65-15a62fda23f5",
                "variables": [],
                "options": []
            },
            {
                "id": "b0924942-ef4d-4e24-99bf-041948f63384",
                "name": "DoB",
                "type": "DATE",
                "isComputed": false,
                "isInTable": false,
                "isEditable": true,
                "isDeletable": true,
                "seedEntityVariable": "f0bf52c4-ae77-4441-8c2d-62f9dab67281",
                "variables": [],
                "options": []
            },
            {
                "id": "fb7e374b-286b-4e44-a72c-8bfc0adf5250",
                "name": "Pre-existing Conditions",
                "type": "MULTI_SELECT",
                "isComputed": false,
                "isInTable": false,
                "isEditable": true,
                "isDeletable": true,
                "seedEntityVariable": "8fc3699f-4526-4a5e-8e8f-7cda6c80d329",
                "variables": [],
                "options": [
                    {
                        "id": "30eb152f-3d57-401b-acb0-1b21c9c68493",
                        "name": "Anaemia"
                    },
                    {
                        "id": "e945a8ef-5b1b-4f4a-a2b1-2876a59dc8ef",
                        "name": "Diabetes"
                    },
                    {
                        "id": "286e02ff-990e-4dd4-bc17-1ae9ccbf8d4e",
                        "name": "Hypertension"
                    },
                    {
                        "id": "da8ae9c6-d462-4d9a-aca2-f21a79c2e3e9",
                        "name": "Disability"
                    }
                ]
            },
            {
                "id": "d9db9828-f6da-48b7-97e9-f671208e3c9f",
                "name": "Gender",
                "type": "SINGLE_SELECT",
                "isComputed": false,
                "isInTable": false,
                "isEditable": true,
                "isDeletable": true,
                "seedEntityVariable": "cf0f7b13-b154-4d8c-b928-85a387816f51",
                "variables": [],
                "options": [
                    {
                        "id": "c1042a72-5d11-49d5-91d6-b08c4eb8bd28",
                        "name": "Female"
                    },
                    {
                        "id": "9457d503-7137-43cb-a4d6-0acde64ebb91",
                        "name": "Male"
                    },
                    {
                        "id": "1851f746-bcd2-493b-847a-6a66913d4a3a",
                        "name": "TG"
                    }
                ]
            }
        ],
        "actions": [
            {
                "id": "cee5ac34-0703-43cc-869d-77a5a0125b5c",
                "name": "Add Member",
                "icon": "plus"
            },
            {
                "id": "44fc7ab3-b0ca-426b-9da3-896c4c32dcab",
                "name": "Edit Member",
                "icon": "pencil"
            },
            {
                "id": "a1666582-6f24-4c74-bb00-c3c4286cfa97",
                "name": "Delete Member",
                "icon": "trash"
            }
        ]
    }
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { WorkflowExecutorPage } from './workflow-executor.page';
import { TransferComponent } from './transfer/transfer.component';
import { QuestionComponent } from './question/question.component';
import { ContinueComponent } from './continue/continue.component';
import { ChooseComponent } from './choose/choose.component';
import { GroupComponent } from './group/group.component';
import { SharedModule } from '../shared/shared.module';
import { DateSelectorModule } from '../shared/date-selector.module';
import { SafeHtmlPipe } from './../safe-html.pipe';

const routes: Routes = [
  {
    path: '',
    component: WorkflowExecutorPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    DateSelectorModule,
    RouterModule.forChild(routes)
  ],
  declarations: [WorkflowExecutorPage, TransferComponent, QuestionComponent, ContinueComponent, ChooseComponent, GroupComponent, SafeHtmlPipe]
})
export class WorkflowExecutorPageModule {}

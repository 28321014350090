import { Component, OnInit, OnChanges, Input, SimpleChanges, SecurityContext, Output, EventEmitter, OnDestroy } from '@angular/core';
import { select } from '@angular-redux/store';
import { CustomFieldValueType } from 'src/shared/store/custom-fields/types';
import { WorkflowProcessState, IWorkflow, WorkflowState } from 'src/shared/store/workflows/types';
import { ShowWidgetProps } from '../workflow-executor.page';
import { PieceType, PieceState } from 'src/shared/store/flowchart/pieces/types';
import { getWorkflowPieceValue } from 'src/shared/store/flowchart/helpers/workflow';
import { Observable, Subscription } from 'rxjs';
import { ApplicationState } from 'src/shared/store/types';
import { isUUID } from 'src/shared/helpers/utilities';
import { DefaultFlowchartProcessState } from 'src/shared/store/flowchart/types';
import { getMemberComputedFieldValue } from 'src/shared/store/flowchart/helpers/custom-fields/member';
import { getReadableDataForCustomField } from 'src/shared/store/custom-fields';
import { translatePhrase } from 'src/shared/helpers/translation';
import { isWebUri } from 'valid-url';
import * as isValidCoordinates from 'is-valid-coordinates';
import draftToHtml from 'draftjs-to-html';
import { DomSanitizer } from '@angular/platform-browser';
import { first, debounceTime } from 'rxjs/operators';
import { VariableValueType } from 'src/shared/helpers/common-types';

export interface QuestionProps {
	workflowId: string;
	customFieldId: string;
	questionId: string;
	userInput: CustomFieldValueType;
	errorMessage?: string;
	overWrittenVariable?: string;
	overWrittenValue?: string;

	isExpanded?: boolean;

	validateAnswer: (questionId: string, answer: CustomFieldValueType, processState: WorkflowProcessState) => string;
	onInputChange: (value: CustomFieldValueType) => void;
	onExpandToggle: () => void;
}

export interface ChoosePieceProps {
	workflowId: string;
	questionId: string;
	userInput: string|Array<string>;
	errorMessage?: string;
	overWrittenVariable?: string;
	overWrittenValue?: string;

	isSectioned?: boolean;

	choiceInputs: {
		[questionId: string]: string|Array<string>,
	};

	validateAnswer: (questionId: string, answer: string|Array<string>, processState: WorkflowProcessState) => string;
	onInputChange: (value: string|Array<string>) => void;
}

type DisplayData = {
	type: 'question',
	pieceId: string,
	data: QuestionProps,
} | {
	type: 'choose',
	pieceId: string,
	data: ChoosePieceProps,
} | {
	type: 'show',
	pieceId: string,
	data: string | ShowWidgetProps,
} | {
	type: 'section',
	pieceId: string,
	heading: string,
	data: Array<DisplayData>,
};

@Component({
	selector: 'app-group',
	templateUrl: './group.component.html',
	styleUrls: ['./group.component.scss'],
})
export class GroupComponent implements OnInit, OnChanges, OnDestroy {
	@select(state => state) applicationStateSource: Observable<ApplicationState>;
	applicationState: ApplicationState;
	applicationStateFirstSubscription: Subscription;
	applicationStateDebouncedSubscription: Subscription;

	workflowData: WorkflowState;
	piecesData: PieceState;

    public choiceInputsIsExpanded : {
        [questionId: string]: boolean
    }

	@Input() workflowId: string;
	@Input() groupPieceId: string;
	@Input() expandedInputs: {
		[customFieldId: string]: boolean,
	};
    @Input() listExpandedInputs: {
        [listId: string]: {
            [customFieldId: string]: boolean
        }
    };
	@Input() userInputs: {
		[customFieldId: string]: CustomFieldValueType,
	};
    @Input() listUserInputs: {
        [listId: string]: {
            [customFieldId: string]: CustomFieldValueType
        }
    };
	@Input() userInputsForChoice: {
		[customFieldId: string]: string|Array<string>,
	};
	@Input() errorMessages: {
		[questionId: string]: string,
	};
	@Input() errorMessagesForChoice: {
		[questionId: string]: string,
	};

	@Input() overWrittenVariable?: string;
	@Input() overWrittenValue?: string;

	@Input() getShowDataFromPieceId: (showPieceId: string, workflowProcessState: WorkflowProcessState) => ShowWidgetProps | string;

	@Input() validateAnswer: (questionId: string, answer: CustomFieldValueType, processState: WorkflowProcessState) => string;
	@Input() validateChoice: (questionId: string, answer: string, processState: WorkflowProcessState) => string;
	@Input() updateUserInput: (customFieldId: string, value: CustomFieldValueType) => void;
	@Input() expandToggle: (customFieldId: string) => void;
    @Input() updateUserInputForChoice: (customFieldId: string, value: string) => void;
    
    @Output() emitValidChoiceForId = new EventEmitter();

    @Input() validatedChoicesForId?: any = {};

	public groupedPiecesData: Array<DisplayData>;

	constructor(
		private sanitizer: DomSanitizer
	) {
        this.choiceInputsIsExpanded = {};
    }

	translate(phrase: string) {
        if (typeof phrase === 'string') {
            return translatePhrase(phrase.trim());
        } else {
            return '';
        }
    }
    
    checkIfArray(data: any) {
        return Array.isArray(data);
    }

    getFragmentDataToTable(data) {
        if (data.length > 0) {
            let tableHeadings = [], 
            tableEntries = [];

            tableHeadings = [{
                name: 'Sl. no'
            }, {
                name: data[0]
            }];

            for (let i = 1; i < data.length; i += 1) {
                let entries = [];
                entries.push(i);
                entries.push(data[i]);
                tableEntries.push({
                    entries: entries
                });
            }

            return {
                tableHeadings,
                tableEntries
            }
        } else {
            return {
                tableHeadings: [],
                tableEntries: []
            }
        }
    }

	transformYourHtml(htmlTextWithStyle: any) {
        return this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle));
	}

    toggleInputExpansionForChoose(questionId: string) {
        if (this.choiceInputsIsExpanded[questionId]) {
            this.choiceInputsIsExpanded[questionId] = !this.choiceInputsIsExpanded[questionId];
        } else {
            this.choiceInputsIsExpanded[questionId] = true;
        }
    }

	getMemberName(memberId: string) {
		const member = this.applicationState.members.byId[memberId];
		const memberType = this.applicationState.members.types.byId[member.type];
		let memberName: VariableValueType;

		const nameField = this.applicationState.members.types.customFields.byId[memberType.nameFieldId];

		if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
			const processState: DefaultFlowchartProcessState = {
				displayingContinuePieceId: undefined,
				displayingAddWorkflowPieceId: undefined,
				customFields: { ...member.customFields },
				variables: {
					[nameField.seedEntityVariable]: member.id,
				},
				lastComputedPiece: undefined,
				executionStack: [],
				forIterationCounts: {},
				displayingQuestionPieceId: undefined,
				displayingShowPieceId: undefined,
				displayingGroupPieceId: undefined,
				displayingTransferPieceId: undefined,
				createdWorkflowId: undefined,
			};

			memberName = getMemberComputedFieldValue(this.applicationState, processState, nameField.startPiece.piece, member.id, nameField);
		} else {
			memberName = member.customFields[memberType.nameFieldId];
		}

		if (Array.isArray(memberName)) {

			if (memberName.length > 0 && Array.isArray(memberName[0])) {
				// Cannot be a multidimensional array
				throw new Error('The value cannot be a multi-dimensional array')
			}

			memberName = memberName as Array<string>;
		}

		memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

		return memberName;
	}

	getShowMarkupForRichText(value: string) {
		let stringifiedHtml: string;
        try {
            stringifiedHtml = draftToHtml(JSON.parse(value));
        } catch (e) {
            stringifiedHtml = '<p>Incorrect HTML</p>';
		}

        return stringifiedHtml;
    }

	getShowMarkupForLink(link: string) {
		if (!isWebUri(link)) {
            return undefined;
        }

        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
        const audioExtensions = ['.mp3'];
        const videoExtensions = ['.mp4'];
        const downloadableExtensions = ['.pdf', '.css'];

        for (const extension of imageExtensions) {
            if (link.endsWith(extension)) {
                return '<a target="_blank" href="' + link + '"><img src="' + link + '" style="width: 100%; display: block;" alt="Image to show"></a>';
            }
        }

        for (const extension of audioExtensions) {
            if (link.endsWith(extension)) {
                return '<audio controls style="width: 250px; margin: 0 auto; display: block;"><source src="' + link + '"></audio>';
            }
        }

        for (const extension of videoExtensions) {
            if (link.endsWith(extension)) {
                return '<video controls  style="width: 100%; display: block;"><source src="' + link + '"></video>';
            }
        }

		if (
            link.startsWith('https://youtube.com') ||
            link.startsWith('https://www.youtube.com')
        ) {
            try {
                const youtubeUrl = new URL(link);

                let embedLink = '';

                if (link.includes('embed')) {
                    embedLink = link;
                } else if (youtubeUrl.searchParams.get('v')) {
                    embedLink = 'https://www.youtube.com/embed/' + youtubeUrl.searchParams.get('v');
                }

                return `<section style="margin: 20px auto;">
                    <iframe 
                        width="560" 
                        height="315" 
						style="width: 100%;"
                        src="${embedLink}"
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
    
                    </iframe>
                </section>`
            } catch {
                return '<div>Could not load YouTube video</div>';
            }
		}

        for (const extension of downloadableExtensions) {
            if (link.endsWith(extension)) {
                return '<a style="display: block; color: var(--contrast-color); width: 100%;" download href="' + link + '" target="_blank">Download</a>';
            }
        }

        return '<a style="display: block; color: var(--contrast-color);" href="' + link + '" target="_blank">Link</a>';
	}

	getMemberSubtitle(memberId: string) {
		try {
            const member = this.applicationState.members.byId[memberId];
    		const memberType = this.applicationState.members.types.byId[member.type];
    		let memberSubtitle: VariableValueType;
    		const subTitleField = this.applicationState.members.types.customFields.byId[memberType.subTitleFieldId];

    		if (subTitleField.isComputed && typeof subTitleField.startPiece !== 'undefined') {
    			const processState: DefaultFlowchartProcessState = {
					displayingContinuePieceId: undefined,
					displayingAddWorkflowPieceId: undefined,
    				customFields: { ...member.customFields },
    				variables: {
    					[subTitleField.seedEntityVariable]: member.id,
    				},
    				lastComputedPiece: undefined,
    				executionStack: [],
    				forIterationCounts: {},
    				displayingQuestionPieceId: undefined,
    				displayingShowPieceId: undefined,
    				displayingGroupPieceId: undefined,
    				displayingTransferPieceId: undefined,
    				createdWorkflowId: undefined,
    			};

    			memberSubtitle = getMemberComputedFieldValue(this.applicationState, processState, subTitleField.startPiece.piece, member.id, subTitleField);
    		} else {
    			memberSubtitle = member.customFields[memberType.subTitleFieldId];
    		}

    		if (Array.isArray(memberSubtitle)) {

    			if (memberSubtitle.length > 0 && Array.isArray(memberSubtitle[0])) {
    				// Cannot be a multidimensional array
    				throw new Error('The value cannot be a multi-dimensional array')
    			}

    			memberSubtitle = memberSubtitle as Array<string>;
    		}

    		memberSubtitle = getReadableDataForCustomField(memberSubtitle, subTitleField, member.id, 'member');

    		return memberSubtitle;
        } catch {
            return '-';
        }
	}

	getAllGroupedPieces = (groupPieceId: string) => {
		const groupedPieces: Array<{
			id: string,
			type: 'question' | 'choose' | 'show' | 'section',
		}> = [];

		const groupPiece = this.piecesData.byId[groupPieceId];

		if (groupPiece.type !== PieceType.GROUP && groupPiece.type !== PieceType.GROUP_FOR_LIST && groupPiece.type !== PieceType.SECTION) {
			throw new Error('The id must be a group ID');
		}

		if (!groupPiece.innerPiece) {
			throw new Error('The group piece must have an inner piece');
		}

		let pieceIdToConsider = groupPiece.innerPiece;

		do {
			const pieceToConsider = this.piecesData.byId[pieceIdToConsider];

			if (pieceToConsider.type === PieceType.GROUPED_QUESTION) {
				groupedPieces.push({
					id: pieceIdToConsider,
					type: 'question',
				});
				pieceIdToConsider = pieceToConsider.nextPiece || '';
			} else if (pieceToConsider.type === PieceType.GROUPED_SHOW) {
				groupedPieces.push({
					id: pieceIdToConsider,
					type: 'show',
				});
				pieceIdToConsider = pieceToConsider.nextPiece || '';
			} else if (pieceToConsider.type === PieceType.GROUPED_CHOOSE) {
				groupedPieces.push({
					id: pieceIdToConsider,
					type: 'choose',
				});
				pieceIdToConsider = pieceToConsider.nextPiece || '';
			} else if (pieceToConsider.type === PieceType.SECTION) {
				groupedPieces.push({
					id: pieceIdToConsider,
					type: 'section',
				});
				pieceIdToConsider = pieceToConsider.nextPiece || '';
			} else {
				throw new Error('This piece can only be a grouped question, a grouped show, or a section');
			}

		} while (pieceIdToConsider);

		return groupedPieces;
	}

	getDisplayDataForCollection = (collectionId: string): Array<DisplayData> => {

		if (!this.workflowData) {
			return [];
		}

		const workflow = this.workflowData.byId[this.workflowId];
		const workflowProcessState = this.getWorkflowProcessState(workflow);

		const groupPiece = this.piecesData.byId[collectionId];
		const piecesInGroup = this.getAllGroupedPieces(collectionId);

		if (groupPiece.type !== PieceType.GROUP && groupPiece.type !== PieceType.GROUP_FOR_LIST && groupPiece.type !== PieceType.SECTION) {
			throw new Error('The piece must be a group or section type');
		}

		const piecesData: Array<DisplayData> = piecesInGroup.map(groupedPiece => {
			if (groupedPiece.type === 'question') {
				const questionPiece = this.piecesData.byId[groupedPiece.id];

				if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
					throw new Error('The piece must be a question type');
				}

				if (!questionPiece.customFieldId) {
					throw new Error('The question must have a valid custom field');
				}

				const questionData: QuestionProps = {
					workflowId: this.workflowId,
					questionId: groupedPiece.id,
					customFieldId: questionPiece.customFieldId,
					userInput: this.userInputs[questionPiece.customFieldId],
					errorMessage: this.errorMessages[groupedPiece.id],
					validateAnswer: this.validateAnswer,
                    onInputChange: this.updateUserInput.bind(this, questionPiece.customFieldId),
                    onExpandToggle: this.expandToggle.bind(this, questionPiece.customFieldId),
                    overWrittenVariable: this.overWrittenVariable,
                    overWrittenValue: this.overWrittenValue,
                    isExpanded: this.expandedInputs[questionPiece.customFieldId],
				};

				return {
					type: 'question',
					pieceId: groupedPiece.id,
					data: questionData
				};
			} else if (groupedPiece.type === 'choose') {
				const choosePiece = this.piecesData.byId[groupedPiece.id];

				if (choosePiece.type !== PieceType.CHOOSE && choosePiece.type !== PieceType.GROUPED_CHOOSE) {
					throw new Error('The piece must be a choose type');
				}

				if (!this.userInputsForChoice || !this.errorMessagesForChoice || !this.updateUserInputForChoice || !this.validateChoice) {
					throw new Error('These must be defined for a choose piece type');
				}

				const chooseData: ChoosePieceProps = {
					workflowId: this.workflowId,
					questionId: groupedPiece.id,
					userInput: this.userInputsForChoice[groupedPiece.id],
					errorMessage: this.errorMessagesForChoice[groupedPiece.id],
					validateAnswer: this.validateChoice,
					onInputChange: this.updateUserInputForChoice.bind(this, groupedPiece.id),
                    choiceInputs: this.userInputsForChoice,
                    overWrittenVariable: this.overWrittenVariable,
                    overWrittenValue: this.overWrittenValue,
				};

				return {
					type: 'choose',
					pieceId: groupedPiece.id,
					data: chooseData
				};
			} else if (groupedPiece.type === 'show') {
				const showData = this.getShowDataFromPieceId(groupedPiece.id, workflowProcessState);
                let displayPiece: any = this.applicationState.flowchart.pieces.byId[groupedPiece.id];
                let piece: any = this.applicationState.flowchart.pieces.byId[displayPiece.variableToShow];
                let fieldType: string = '';
                if (piece && piece.customField) {
                    fieldType = this.applicationState.workflows.types.customFields.byId[piece.customField].type;
                }

                if (fieldType === "BOOLEAN" || fieldType === "NUMBER") {
                    return {
                        type: 'show',
						pieceId: groupedPiece.id,
						data: showData,
                        customFieldType: fieldType
                    }
                } else if (typeof showData === 'string') {
					if (isWebUri(showData)) {
						return {
							type: 'show',
							pieceId: groupedPiece.id,
							data: this.getShowMarkupForLink(showData),
                            customFieldType: fieldType
						};
					} else if ( (showData.split(' ')[0] && showData.split(' ')[1]) && (showData.split(' ')[0].includes('+') && showData.split(' ')[1].length >= 10 && showData.split(' ')[1].length <= 15) ) {
                        return {
							type: 'show',
							pieceId: groupedPiece.id,
							data: this.getShowMarkupForRichText(showData.toString()) === '<p>Incorrect HTML</p>'? showData : this.getShowMarkupForRichText(showData.toString()),
                            customFieldType: 'PHONE'
						};
                    } else if ( (showData.split(' ')[0] && isValidCoordinates.latitude(Number(showData.split(' ')[0]))) &&
                    (showData.split(' ')[1] && isValidCoordinates.longitude(Number(showData.split(' ')[1]))) ) {
                        return {
							type: 'show',
							pieceId: groupedPiece.id,
							data: this.getShowMarkupForRichText(showData.toString()) === '<p>Incorrect HTML</p>'? showData : this.getShowMarkupForRichText(showData.toString()),
                            customFieldType: 'LOCATION'
						};
                    } else {
                        return {
							type: 'show',
							pieceId: groupedPiece.id,
							data: this.getShowMarkupForRichText(showData.toString()) === '<p>Incorrect HTML</p>'? showData : this.getShowMarkupForRichText(showData.toString()),
                            customFieldType: fieldType
						};
					}
				} else {
                    return {
						type: 'show',
						pieceId: groupedPiece.id,
						data: showData,
                        customFieldType: fieldType
					};
				}

			} else if (groupedPiece.type === 'section') {
				const sectionPiece = this.piecesData.byId[groupedPiece.id];

				if (sectionPiece.type !== PieceType.SECTION) {
					throw new Error('The piece must be a choose type');
				}

				const headingProcessState = this.getWorkflowProcessState(workflow);
				const sectionHeading = sectionPiece.heading ? isUUID(sectionPiece.heading) ?
					getWorkflowPieceValue(this.applicationState, headingProcessState, this.workflowId, sectionPiece.heading) :
					sectionPiece.heading : 'Section';

				if (typeof sectionHeading !== 'string') {
					throw new Error('The section heading must be a string');
				}

				const sectionData = this.getDisplayDataForCollection(groupedPiece.id);

				return {
					type: 'section',
					pieceId: groupedPiece.id,
					heading: sectionHeading,
					data: sectionData,
				};
			} else {
				throw new Error('The piece should be one of these types');
			}
		});

		return piecesData;
	}

	getWorkflowProcessState = (workflow: IWorkflow) => {
		const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
			customFields: workflow.history[workflow.historyIndex].customFields,
			lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
			executionStack: workflow.history[workflow.historyIndex].executionStack,
			forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
			variables: workflow.history[workflow.historyIndex].variables,
			displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
			displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
			displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
			displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
			displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
			displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
			createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
		}));

		if (this.overWrittenVariable) {
			processState.variables[this.overWrittenVariable] = this.overWrittenValue;
		}

		return processState;
	}

    callNumber(number: string) {
        window.open('tel:' + number);
    }

    showMap(latlng: string) {
        var url = "https://maps.google.com/?q=" + latlng.split(' ')[0] + ',' + latlng.split(' ')[1];
        window.open(url);
	}
	
	updateGroup(applicationState: ApplicationState) {
		this.applicationState = applicationState;
		this.workflowData = applicationState.workflows;
		this.piecesData = applicationState.flowchart.pieces;

        this.groupedPiecesData = this.getDisplayDataForCollection(this.groupPieceId);
    }
    
    getValidChoiceForId(data) {
        this.emitValidChoiceForId.emit(data);
    }

	ngOnInit() {
		this.applicationStateFirstSubscription = this.applicationStateSource.pipe(first()).subscribe(applicationState => this.updateGroup(applicationState));
		this.applicationStateDebouncedSubscription = this.applicationStateSource.pipe(debounceTime(500)).subscribe(applicationState => this.updateGroup(applicationState));
        
	}

	ngOnChanges(simpleChanges: SimpleChanges) {
		if (simpleChanges.userInputs &&
			JSON.stringify(simpleChanges.userInputs.currentValue) !== JSON.stringify(simpleChanges.userInputs.previousValue)) {
			this.userInputs = JSON.parse(JSON.stringify(simpleChanges.userInputs.currentValue));
			this.groupedPiecesData = this.getDisplayDataForCollection(this.groupPieceId);
		}
		
		if (simpleChanges.expandedInputs &&
			JSON.stringify(simpleChanges.expandedInputs.currentValue) !== JSON.stringify(simpleChanges.expandedInputs.previousValue)) {
			this.expandedInputs = JSON.parse(JSON.stringify(simpleChanges.expandedInputs.currentValue));
			this.groupedPiecesData = this.getDisplayDataForCollection(this.groupPieceId);
		}

		if (simpleChanges.listUserInputs &&
			JSON.stringify(simpleChanges.listUserInputs.currentValue) !== JSON.stringify(simpleChanges.listUserInputs.previousValue)) {
			this.listUserInputs = JSON.parse(JSON.stringify(simpleChanges.listUserInputs.currentValue));
			this.groupedPiecesData = this.getDisplayDataForCollection(this.groupPieceId);
		}

		if (simpleChanges.listExpandedInputs &&
			JSON.stringify(simpleChanges.listExpandedInputs.currentValue) !== JSON.stringify(simpleChanges.listExpandedInputs.previousValue)) {
			this.listExpandedInputs = JSON.parse(JSON.stringify(simpleChanges.listExpandedInputs.currentValue));
			this.groupedPiecesData = this.getDisplayDataForCollection(this.groupPieceId);
		}

		if (simpleChanges.userInputsForChoice &&
			JSON.stringify(simpleChanges.userInputsForChoice.currentValue) !== JSON.stringify(simpleChanges.userInputsForChoice.previousValue)) {
			this.userInputsForChoice = JSON.parse(JSON.stringify(simpleChanges.userInputsForChoice.currentValue));
			this.groupedPiecesData = this.getDisplayDataForCollection(this.groupPieceId);
		}
		
		if (simpleChanges.choiceInputsIsExpanded &&
			JSON.stringify(simpleChanges.choiceInputsIsExpanded.currentValue) !== JSON.stringify(simpleChanges.choiceInputsIsExpanded.previousValue)) {
			this.choiceInputsIsExpanded = JSON.parse(JSON.stringify(simpleChanges.choiceInputsIsExpanded.currentValue));
			this.groupedPiecesData = this.getDisplayDataForCollection(this.groupPieceId);
		}

		if (simpleChanges.overWrittenVariable &&
			simpleChanges.overWrittenVariable.currentValue !== simpleChanges.overWrittenVariable.previousValue) {
			this.overWrittenVariable = simpleChanges.overWrittenVariable.currentValue;
		}

		if (simpleChanges.overWrittenValue &&
			simpleChanges.overWrittenValue.currentValue !== simpleChanges.overWrittenValue.previousValue) {
			this.overWrittenValue = simpleChanges.overWrittenValue.currentValue;
		}

		if (simpleChanges.groupPieceId && this.workflowData &&
			simpleChanges.groupPieceId.currentValue !== simpleChanges.groupPieceId.previousValue) {
			this.groupedPiecesData = this.getDisplayDataForCollection(this.groupPieceId);
		}        
	}

	ngOnDestroy() {
        if (this.applicationStateFirstSubscription) {
            this.applicationStateFirstSubscription.unsubscribe();
        }

        if (this.applicationStateDebouncedSubscription) {
            this.applicationStateDebouncedSubscription.unsubscribe();
        }
	}

}

import { addEntity, updateEntity, deleteEntity, updateEntries } from "../normalized-model";
import { ADD_WORKFLOW_PACKAGE, UPDATE_WORKFLOW_PACKAGE, DELETE_PACKAGE, UPDATE_PACKAGES_DATA, PackageActionTypes, PackageState, MarketplacePackage, ADD_DATA_PACKAGE, UPDATE_DATA_PACKAGE } from "./types";

const initialState: PackageState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],

    hasFetchedPackages: false,

    createdIds: new Set<string>(),
    updatedIds: new Set<string>(),
    deletedIds: new Set<string>(),
};

export function packagesReducer(state = initialState, action: PackageActionTypes): PackageState {

    switch(action.type) {
        case ADD_WORKFLOW_PACKAGE:
            return addEntity<PackageState, MarketplacePackage>(state, action.payload);
        
        case UPDATE_WORKFLOW_PACKAGE:
            return updateEntity<PackageState, MarketplacePackage>(state, action.payload, action.currentTime);
        
        case ADD_DATA_PACKAGE:
            return addEntity<PackageState, MarketplacePackage>(state, action.payload);
        
        case UPDATE_DATA_PACKAGE:
            return updateEntity<PackageState, MarketplacePackage>(state, action.payload, action.currentTime);

        case DELETE_PACKAGE:
            return deleteEntity<PackageState, MarketplacePackage>(state, action.id, action.currentTime);

        case UPDATE_PACKAGES_DATA:
            if (!state.hasFetchedPackages) {
                state = updateEntries<PackageState, MarketplacePackage>(state, action.data);
                state.hasFetchedPackages = true;
            }
            return state;
        
        default:
            return state;
    }
}
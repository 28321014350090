import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import * as Chartist from 'chartist';
import { translatePhrase } from 'src/shared/helpers/translation';

@Component({
	selector: 'app-line-chart',
	templateUrl: './line-chart.component.html',
	styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit {
	@Input() chartLabels: Array<string>;
	@Input() chartData: Array<number>;
	@Input() maxValue: number;
	
	@ViewChild('chartElement') chartElement: ElementRef;

    constructor() { }
    
    translate(phrase: string) {
		return translatePhrase(phrase.trim());
    }
    
    scrollRight() {
        this.chartElement.nativeElement.scrollLeft += 50;
    }

    ngOnInit() { }

	ngAfterViewInit() {
        let chartWidth = this.chartLabels.length * 100;

        if (this.chartLabels.length > 0 && this.chartData.length > 0) {
            new Chartist.Line(this.chartElement.nativeElement, {
                labels: this.chartLabels,
                series: [
                    this.chartData,
                ]
            }, {
                width: `${chartWidth}px`,
                high: this.maxValue + 1,
                low: 0,
                chartPadding: {
                    right: 15,
                    left: -10,
                },
            });
        }		
	}

}

import { Phone, PiecePositionState, SearchIndex } from '../../helpers/common-types';
import { NormalizedModel, CustomFieldDeltaTrackable } from '../normalized-model';
import { SynchronizeWorkflowsData } from '../workflows/types';
import { CustomFieldDataHolder, CustomFieldState, IUpdateableCustomFieldData, CustomField, IUpdateableFieldChoiceData, FieldChoice } from '../custom-fields/types';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const UN_ARCHIVE_USER = 'UN_ARCHIVE_USER';

export const RECALCULATE_COMPUTED_FIELDS_FOR_USER = 'RECALCULATE_COMPUTED_FIELDS_FOR_USER';
export const BULK_RECALCULATE_COMPUTED_FIELDS_FOR_USER = 'BULK_RECALCULATE_COMPUTED_FIELDS_FOR_USER';
export const RECOMPUTE_ALL_USERS = 'RECOMPUTE_ALL_USERS';

export const UPDATE_USER_WORKFLOWS = 'UPDATE_USER_WORKFLOWS';
export const UPDATE_USER_CUSTOM_FIELD_DATA = 'UPDATE_USER_CUSTOM_FIELD_DATA';
export const UPDATE_USER_COMPUTED_FIELD_DATA = 'UPDATE_USER_COMPUTED_FIELD_DATA';
export const BULK_UPDATE_USER_COMPUTED_FIELD_DATA = 'BULK_UPDATE_USER_COMPUTED_FIELD_DATA';

export const SEARCH_USER_TABLE = 'SEARCH_USER_TABLE';
export const FILTER_USER_TABLE = 'FILTER_USER_TABLE';
export const SET_IS_FILTERING_FOR_USER_TABLE = 'SET_IS_FILTERING_FOR_USER_TABLE';
export const GO_TO_PAGE_USER_TABLE = 'GO_TO_PAGE_USER_TABLE';
export const SET_PAGE_SIZE_USER_TABLE = 'SET_PAGE_SIZE_USER_TABLE';
export const SORT_USER_TABLE = 'SORT_USER_TABLE';

export const SELECT_USER_CUSTOM_FIELD = 'SELECT_USER_CUSTOM_FIELD';
export const UN_SELECT_USER_CUSTOM_FIELD = 'UN_SELECT_USER_CUSTOM_FIELD';
export const ADD_USER_CUSTOM_FIELD = 'ADD_USER_CUSTOM_FIELD';
export const INSTANTIATE_USER_CUSTOM_FIELD = 'INSTANTIATE_USER_CUSTOM_FIELD';
export const DELETE_USER_CUSTOM_FIELD = 'DELETE_USER_CUSTOM_FIELD';
export const UPDATE_USER_CUSTOM_FIELD = 'UPDATE_USER_CUSTOM_FIELD';

export const UPDATE_USER_CUSTOM_FIELD_START_PIECE = 'UPDATE_USER_CUSTOM_FIELD_START_PIECE';
export const SET_ISOLATED_USER_CUSTOM_FIELD_PIECE = 'SET_ISOLATED_USER_CUSTOM_FIELD_PIECE';
export const REMOVE_ISOLATED_USER_CUSTOM_FIELD_PIECE = 'REMOVE_ISOLATED_USER_CUSTOM_FIELD_PIECE';
export const REGISTER_USER_CUSTOM_FIELD_VARIABLE = 'REGISTER_USER_CUSTOM_FIELD_VARIABLE';

export const SET_IS_SHOWING_ADD_FORM_FOR_USERS = 'SET_IS_SHOWING_ADD_FORM_FOR_USERS';
export const SET_IS_SHOWING_MODIFY_FORM_FOR_USERS = 'SET_IS_SHOWING_MODIFY_FORM_FOR_USERS';
export const SET_IS_SHOWING_FILTER_FORM_FOR_USERS = 'SET_IS_SHOWING_FILTER_FORM_FOR_USERS';
export const SET_IS_SHOWING_CSV_FORM_FOR_USERS = 'SET_IS_SHOWING_CSV_FORM_FOR_USERS';

export const SELECT_USER_CUSTOM_FIELD_OPTION = 'SELECT_USER_CUSTOM_FIELD_OPTION';
export const UN_SELECT_USER_CUSTOM_FIELD_OPTION = 'UN_SELECT_USER_CUSTOM_FIELD_OPTION';
export const RE_ORDER_USER_CUSTOM_FIELD_OPTION = 'RE_ORDER_USER_CUSTOM_FIELD_OPTION';
export const ADD_USER_CUSTOM_FIELD_OPTION = 'ADD_USER_CUSTOM_FIELD_OPTION';
export const INSTANTIATE_USER_CUSTOM_FIELD_OPTION = 'INSTANTIATE_USER_CUSTOM_FIELD_OPTION';
export const DELETE_USER_CUSTOM_FIELD_OPTION = 'DELETE_USER_CUSTOM_FIELD_OPTION';
export const UPDATE_USER_CUSTOM_FIELD_OPTION = 'UPDATE_USER_CUSTOM_FIELD_OPTION';

export const UPDATE_USERS_DATA = 'UPDATE_USERS_DATA';
export const UPDATE_USER_CUSTOM_FIELDS_DATA = 'UPDATE_USER_CUSTOM_FIELDS_DATA';
export const UPDATE_USER_CUSTOM_FIELD_OPTIONS_DATA = 'UPDATE_USER_CUSTOM_FIELD_OPTIONS_DATA';
export const SYNCHRONIZE_USERS_DATA = 'SYNCHRONIZE_USERS_DATA';
export const SYNCHRONIZE_USER_CUSTOM_FIELDS_DATA = 'SYNCHRONIZE_USER_CUSTOM_FIELDS_DATA';
export const SYNCHRONIZE_USER_CUSTOM_FIELD_OPTIONS_DATA = 'SYNCHRONIZE_USER_CUSTOM_FIELD_OPTIONS_DATA';
export const CLEAR_USERS_DELTA = 'CLEAR_USERS_DELTA';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export interface INewUserData {

    projects: Array<string>,
    levels: Array<string>,
    roles: Array<string>,
    locations: Array<string>,

    isAppUser: boolean,
    isOnline: boolean,
    isBetaTester: boolean,

    phone: Phone,
    language: string,

    password: string,

    customFields: CustomFieldDataHolder
}

export interface IUpdateableUserData extends INewUserData {
    id: string,
}

export interface IUser extends IUpdateableUserData, CustomFieldDeltaTrackable {
    archived?: boolean,
    createdTime: string,
    lastLoginTime?: string,
    lastPullTime?: string,
    lastPushTime?: string,

    workflows: {
        [workflowTypeId: string]: Array<string>,
    },
}

export interface UserState extends NormalizedModel<IUser>, CustomFieldState {

    isFiltering: boolean,

    pageSize: number,
    currentPageNumber: number,
    filters: {
        projects: Array<string>,
        levels: Array<string>,
        roles: Array<string>,
        locations: Array<string>,
        unsynced: boolean,
        archived: boolean,
        areLowerLevelsIncluded: boolean,
        customFields: {
            [fieldId: string]: Array<string>,
        }
    },
    sort: {
        column: string | undefined,
        order: 'ASC' | 'DESC',
    },
    searchTerm: string | undefined,

    markedForIndex: Array<string>,

    nameFieldId: string;
    subTitleFieldId: string;
    locationFieldId: string;


    isShowingAddForm?: boolean;
    isShowingModifyForm?: boolean;
    isShowingFilterForm?: boolean;
    isShowingCSVForm?: boolean;
}

export interface AddUserRequestAction {
    type: typeof ADD_USER_REQUEST,
    payload: IUser,
}

export interface AddUserAction {
    type: typeof ADD_USER,
    payload: IUser,
    currentTime: string,
}

export interface UpdateUserRequestAction {
    type: typeof UPDATE_USER_REQUEST,
    payload: IUpdateableUserData,
}

export interface UpdateUserAction {
    type: typeof UPDATE_USER,
    payload: IUpdateableUserData,
    currentTime: string,
}

export interface DeleteUserAction {
    type: typeof DELETE_USER,
    id: string,
    currentTime: string,
}

export interface UnArchiveUserAction {
    type: typeof UN_ARCHIVE_USER,
    id: string,
    currentTime: string,
}

export interface ChangeDeltaForUserComputedFields {
    userId: string,
    userChanged: boolean,
    locationsChanged: boolean,
    workflowTypesChanged: Array<string>,
}

export interface RecalculateComputedFieldsForUserAction extends ChangeDeltaForUserComputedFields {
    type: typeof RECALCULATE_COMPUTED_FIELDS_FOR_USER,
}

export interface BulkRecalculateComputedFieldsForUserAction {
    type: typeof BULK_RECALCULATE_COMPUTED_FIELDS_FOR_USER,
    payload: Array<ChangeDeltaForUserComputedFields>
}

export interface UpdateUserWorkflowAction {
    type: typeof UPDATE_USER_WORKFLOWS,
    userId: string,
    workflowTypeId: string,
    workflowId: string,
}

export interface UpdateUserCustomFieldDataAction {
    type: typeof UPDATE_USER_CUSTOM_FIELD_DATA,
    workflowId: string,
    userId: string,
    customFieldData: CustomFieldDataHolder,
    currentTime: string,
}

export interface UpdateUserComputedFieldDataAction {
    type: typeof UPDATE_USER_COMPUTED_FIELD_DATA,
    userId: string,
    customFieldData: CustomFieldDataHolder,
    currentTime: string,
}

export interface ComputedFieldUpdatePayloadForUser {
    userId: string;
    customFieldData: CustomFieldDataHolder;
}

export interface BulkUpdateUserComputedFieldDataAction {
    type: typeof BULK_UPDATE_USER_COMPUTED_FIELD_DATA,
    payload: Array<ComputedFieldUpdatePayloadForUser>,
    currentTime: string,
}

export interface RecomputeAllUsersAction {
    type: typeof RECOMPUTE_ALL_USERS,
}


export interface SearchUserTableAction {
    type: typeof SEARCH_USER_TABLE,
    searchTerm: string,
}

export interface FilterUserTableAction {
    type: typeof FILTER_USER_TABLE,
    projects: Array<string>,
    levels: Array<string>,
    roles: Array<string>,
    locations: Array<string>,
    unsynced: boolean,
    areLowerLevelsIncluded: boolean,
    archived: boolean,
    customFields: {
        [customFieldId: string]: Array<string>,
    },
}

export interface SetIsFilteringForUserTableAction {
    type: typeof SET_IS_FILTERING_FOR_USER_TABLE;
    isFiltering: boolean;
}

export interface GoToPageUserTableAction {
    type: typeof GO_TO_PAGE_USER_TABLE,
    pageNumber: number,
}

export interface SetPageSizeUserTableAction {
    type: typeof SET_PAGE_SIZE_USER_TABLE,
    pageSize: number,
}

export interface SortUserTableAction {
    type: typeof SORT_USER_TABLE,
    column: string,
    order: 'ASC' | 'DESC'
}


export interface SelectUserCustomFieldAction {
    type: typeof SELECT_USER_CUSTOM_FIELD,
    id: string,
}

export interface UnSelectUserCustomFieldAction {
    type: typeof UN_SELECT_USER_CUSTOM_FIELD,
}

export interface AddUserCustomFieldAction {
    type: typeof ADD_USER_CUSTOM_FIELD,
    payload: IUpdateableCustomFieldData,
    currentTime: string,
}

export interface InstantiateUserCustomFieldAction {
    type: typeof INSTANTIATE_USER_CUSTOM_FIELD,
    payload: CustomField,
    currentTime: string,
}

export interface DeleteUserCustomFieldAction {
    type: typeof DELETE_USER_CUSTOM_FIELD,
    id: string,
    currentTime: string,
}

export interface UpdateUserCustomFieldAction {
    type: typeof UPDATE_USER_CUSTOM_FIELD,
    payload: IUpdateableCustomFieldData,
    currentTime: string,
}


export interface UpdateUserCustomFieldStartPieceAction {
    type: typeof UPDATE_USER_CUSTOM_FIELD_START_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
    currentTime: string,
}

export interface SetIsolatedUserCustomFieldPieceAction {
    type: typeof SET_ISOLATED_USER_CUSTOM_FIELD_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
    currentTime: string,
}

export interface RemoveIsolatedUserCustomFieldPieceAction {
    type: typeof REMOVE_ISOLATED_USER_CUSTOM_FIELD_PIECE,
    pieceId: string,
    customFieldId: string,
    currentTime: string,
}

export interface RegisterUserCustomFieldVariableAction {
    type: typeof REGISTER_USER_CUSTOM_FIELD_VARIABLE,
    variableId: string,
    customFieldId: string,
    currentTime: string,
}

export interface SetIsShowingAddFormForUsersAction {
    type: typeof SET_IS_SHOWING_ADD_FORM_FOR_USERS;
    showValue: boolean;
}
export interface SetIsShowingModifyFormForUsersAction {
    type: typeof SET_IS_SHOWING_MODIFY_FORM_FOR_USERS;
    showValue: boolean;
}
export interface SetIsShowingFilterFormForUsersAction {
    type: typeof SET_IS_SHOWING_FILTER_FORM_FOR_USERS;
    showValue: boolean;
}
export interface SetIsShowingCSVFormForUsersAction {
    type: typeof SET_IS_SHOWING_CSV_FORM_FOR_USERS;
    showValue: boolean;
}


export interface SelectUserCustomFieldOptionAction {
    type: typeof SELECT_USER_CUSTOM_FIELD_OPTION,
    id: string,
}

export interface UnSelectUserCustomFieldOptionAction {
    type: typeof UN_SELECT_USER_CUSTOM_FIELD_OPTION,
}

export interface ReOrderUserCustomFieldOptionAction {
    type: typeof RE_ORDER_USER_CUSTOM_FIELD_OPTION,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddUserCustomFieldOptionAction {
    type: typeof ADD_USER_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
    parentId: string,
    currentTime: string,
}

export interface InstantiateUserCustomFieldOptionAction {
    type: typeof INSTANTIATE_USER_CUSTOM_FIELD_OPTION,
    payload: FieldChoice,
    parentId: string,
    currentTime: string,
}

export interface DeleteUserCustomFieldOptionAction {
    type: typeof DELETE_USER_CUSTOM_FIELD_OPTION,
    id: string,
    parentId: string,
    currentTime: string,
}

export interface UpdateUserCustomFieldOptionAction {
    type: typeof UPDATE_USER_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
    currentTime: string,
}

export interface UpdateUserData {
    type: typeof UPDATE_USER_DATA,
    data: UserState,
}

export interface UpdateUsersData {
    type: typeof UPDATE_USERS_DATA,
    data: Array<IUser>,
}

export interface UpdateUserCustomFieldsData {
    type: typeof UPDATE_USER_CUSTOM_FIELDS_DATA,
    data: Array<CustomField>,
}

export interface UpdateUserCustomFieldOptionsData {
    type: typeof UPDATE_USER_CUSTOM_FIELD_OPTIONS_DATA,
    data: Array<FieldChoice>,
}

export interface SynchronizeUsersData {
    type: typeof SYNCHRONIZE_USERS_DATA,
    data: Array<IUser>,
}

export interface SynchronizeUserCustomFieldsData {
    type: typeof SYNCHRONIZE_USER_CUSTOM_FIELDS_DATA,
    data: Array<CustomField>,
}

export interface SynchronizeUserCustomFieldOptionsData {
    type: typeof SYNCHRONIZE_USER_CUSTOM_FIELD_OPTIONS_DATA,
    data: Array<FieldChoice>,
}

export interface ClearUsersDelta {
    type: typeof CLEAR_USERS_DELTA,
}

type UserActions = AddUserRequestAction | AddUserAction | UpdateUserRequestAction | UpdateUserAction | DeleteUserAction | UnArchiveUserAction | UpdateUserWorkflowAction | UpdateUserCustomFieldDataAction | RecalculateComputedFieldsForUserAction | BulkRecalculateComputedFieldsForUserAction | UpdateUserComputedFieldDataAction | BulkUpdateUserComputedFieldDataAction | RecomputeAllUsersAction;

type UserTableActions = SearchUserTableAction | FilterUserTableAction | SetIsFilteringForUserTableAction | GoToPageUserTableAction | SetPageSizeUserTableAction | SortUserTableAction | SetIsShowingAddFormForUsersAction | SetIsShowingModifyFormForUsersAction | SetIsShowingFilterFormForUsersAction | SetIsShowingCSVFormForUsersAction;

type CustomFieldActions = SelectUserCustomFieldAction | UnSelectUserCustomFieldAction | AddUserCustomFieldAction | InstantiateUserCustomFieldAction | DeleteUserCustomFieldAction | UpdateUserCustomFieldAction;

type CustomFieldChoiceActions = SelectUserCustomFieldOptionAction | UnSelectUserCustomFieldOptionAction | ReOrderUserCustomFieldOptionAction | AddUserCustomFieldOptionAction | InstantiateUserCustomFieldOptionAction | DeleteUserCustomFieldOptionAction | UpdateUserCustomFieldOptionAction;

export type UserActionTypes = UserActions | UserTableActions | UpdateUserCustomFieldStartPieceAction | SetIsolatedUserCustomFieldPieceAction | RemoveIsolatedUserCustomFieldPieceAction | RegisterUserCustomFieldVariableAction | CustomFieldActions | CustomFieldChoiceActions | UpdateUserData | UpdateUsersData | UpdateUserCustomFieldsData | UpdateUserCustomFieldOptionsData | SynchronizeUsersData | SynchronizeUserCustomFieldsData | SynchronizeUserCustomFieldOptionsData | ClearUsersDelta | SynchronizeWorkflowsData;
import { NormalizedModel, Synchronizable } from '../normalized-model';

export const ADD_EVENT = 'ADD_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

export const UPDATE_EVENTS_DATA = 'UPDATE_EVENTS_DATA';
export const SYNCHRONIZE_EVENTS_DATA = 'SYNCHRONIZE_EVENTS_DATA';
export const CLEAR_EVENTS_DELTA = 'CLEAR_EVENTS_DELTA';

export enum EventType {
    SEARCH = 'SEARCH',
    FILTER = 'FILTER',
    LOGIN = 'LOGIN',
}

interface BaseEvent extends Synchronizable {
    user: string;
}

export interface SearchEvent extends BaseEvent {
    type: EventType.SEARCH,
    payload: {},
};

export interface FilterEvent extends BaseEvent {
    type: EventType.FILTER,
    payload: {},
};

export interface LoginEvent extends BaseEvent {
    type: EventType.LOGIN,
};

export type AllEventTypes = SearchEvent | FilterEvent | LoginEvent;

export interface EventState extends NormalizedModel<AllEventTypes> {
}

export interface AddEventAction {
    type: typeof ADD_EVENT,
    payload: AllEventTypes,
}


export interface UpdateEventsData {
    type: typeof UPDATE_EVENTS_DATA,
    data: Array<AllEventTypes>,
}

export interface SynchronizeEventsData {
    type: typeof SYNCHRONIZE_EVENTS_DATA,
    data: Array<AllEventTypes>,
}

export interface ClearEventsDelta {
    type: typeof CLEAR_EVENTS_DELTA,
}

export type EventActionTypes = AddEventAction | UpdateEventsData | SynchronizeEventsData | ClearEventsDelta;
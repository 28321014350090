import { EventState, EventActionTypes, ADD_EVENT, AllEventTypes, UPDATE_EVENTS_DATA, SYNCHRONIZE_EVENTS_DATA, CLEAR_EVENTS_DELTA } from './types';
import { addEntity, updateEntries, clearDelta, synchronizeEntries } from '../normalized-model';

const initialState: EventState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],

    createdIds: new Set<string>(),
    updatedIds: new Set<string>(),
    deletedIds: new Set<string>(),
};

export function eventsReducer(state = initialState, action: EventActionTypes): EventState {

    switch(action.type) {
        case ADD_EVENT:
            return addEntity<EventState, AllEventTypes>(state, action.payload);

        case UPDATE_EVENTS_DATA:
            return updateEntries<EventState, AllEventTypes>(state, action.data);

        case SYNCHRONIZE_EVENTS_DATA:
            return synchronizeEntries<EventState, AllEventTypes>(state, action.data);

        case CLEAR_EVENTS_DELTA:
            return clearDelta<EventState, AllEventTypes>(state);
        
        default:
            return state;
    }
}
import { OrganizationState, OrganizationActionTypes, UPDATE_ORGANIZATION, CLEAR_ORGANIZATION_DELTA, UPDATE_SUPER_USER_PASSWORD } from './types';

const initialState: OrganizationState = {
    name: 'Organization Name',
    code: 'ORG_CODE',
    category: 'NGO',
    categories: ['NGO'],
    size: '1 to 10',
    sizes: ['1 to 10'],
    image: '',
    language: 'English',
    phoneCountryCode: '+91',
    phoneNumber: '9999999999',
    address: '',
    latitude: '0',
    longitude: '0',
    primary_color: '',
    primary_gradient: '',
    contrast_color: '',

    sessionTimeoutInMinutes: 24 * 60,
    hasUpdated: false,
    hasThresholdChanged: false,
    superUserPassword: '',
}

export function organizationReducer(state = initialState, action: OrganizationActionTypes) {
    switch (action.type) {
        case UPDATE_ORGANIZATION:

            let memberThreshold = state.completedMemberThresholdForSuperUser;

            if (typeof memberThreshold === 'undefined' || memberThreshold === null) {
                memberThreshold = state.completedMemberThreshold;
            }

            let newMemberThreshold = action.payload.completedMemberThresholdForSuperUser;

            if (typeof newMemberThreshold === 'undefined' || newMemberThreshold === null) {
                newMemberThreshold = action.payload.completedMemberThreshold;
            }

            const hasMemberThresholdChanged = newMemberThreshold !== memberThreshold;

            let groupThreshold = state.completedGroupThresholdForSuperUser;

            if (typeof groupThreshold === 'undefined' || groupThreshold === null) {
                groupThreshold = state.completedGroupThreshold;
            }

            let newGroupThreshold = action.payload.completedGroupThresholdForSuperUser;

            if (typeof newGroupThreshold === 'undefined' || newGroupThreshold === null) {
                newGroupThreshold = action.payload.completedGroupThreshold;
            }

            const hasGroupThresholdChanged = newGroupThreshold !== groupThreshold;

            let workflowThreshold = state.completedWorkflowThresholdForSuperUser;

            if (typeof workflowThreshold === 'undefined' || workflowThreshold === null) {
                workflowThreshold = state.completedWorkflowThreshold;
            }

            let newWorkflowThreshold = action.payload.completedWorkflowThresholdForSuperUser;

            if (typeof newWorkflowThreshold === 'undefined' || newWorkflowThreshold === null) {
                newWorkflowThreshold = action.payload.completedWorkflowThreshold;
            }

            const hasWorkflowThresholdChanged = newWorkflowThreshold !== workflowThreshold;

            return {
                ...state,
                ...action.payload,
                hasUpdated: true,
                hasThresholdChanged: hasMemberThresholdChanged || hasGroupThresholdChanged || hasWorkflowThresholdChanged,
            }

        case CLEAR_ORGANIZATION_DELTA:
            return {
                ...state,
                superUserPassword: '',
                hasUpdated: false,
                hasThresholdChanged: false,
            }

        case UPDATE_SUPER_USER_PASSWORD:
            return {
                ...state,
                superUserPassword: action.password,
            };

        default:
            return state
    }
}
import { VariableType } from '../../variables/types';

import { isUUID } from '../../../../helpers/utilities';
import { IMember, MemberState } from '../../../members/types';
import { ApplicationState } from '../../../types';
import { IGroup } from '../../../groups/types';
import { IUser } from '../../../users/types';
import { IWorkflow } from '../../../workflows/types';

import { NestingData } from '../../pieces/types';
import { ILocation } from '../../../structure/location/types';
import { IProject } from '../../../structure/project/types';
import { ILevel } from '../../../structure/level/types';
import { IRole } from '../../../structure/role/types';
import { IStatus } from '../../../workflows/types/statuses/types';
import { IStaticDataHolder } from '../../../static-info/types';
import { IDataFragment } from '../../../static-info/data-fragment/types';
import { VariableValueType } from '../../../../helpers/common-types';
import { MemberTypeState } from '../../../members/types/types';
import { GroupTypeState } from '../../../groups/types/types';
import { WorkflowTypeState } from '../../../workflows/types/types';

function getTypeForProperty(variableType: VariableType, propertyData: NestingData | string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch (variableType) {

        case VariableType.PROJECT:
            switch (property) {
                case 'name':
                    return VariableType.TEXT;
                case 'locations':
                    return VariableType.LOCATIONS_LIST;
                default:
                    throw new Error('Unknown property on location');
            }

        case VariableType.LEVEL:
            switch (property) {
                case 'name':
                    return VariableType.TEXT;
                default:
                    throw new Error('Unknown property on location');
            }

        case VariableType.ROLE:
            switch (property) {
                case 'name':
                    return VariableType.TEXT;
                case 'threshold_days_for_members':
                    return VariableType.NUMBER;
                case 'threshold_days_for_groups':
                    return VariableType.NUMBER;
                case 'threshold_days_for_workflows':
                    return VariableType.NUMBER;
                case 'can_transfer':
                    return VariableType.BOOLEAN;
                case 'bulk_actions':
                    return VariableType.BOOLEAN;
                default:
                    throw new Error('Unknown property on location');
            }

        case VariableType.LOCATION:
            switch (property) {
                case 'name':
                    return VariableType.TEXT;
                case 'parent':
                    return VariableType.LOCATION;
                case 'children':
                    return VariableType.LOCATIONS_LIST;
                case 'users':
                    return VariableType.USERS_LIST;
                case 'members':
                    return VariableType.MEMBERS_LIST;
                case 'groups':
                    return VariableType.GROUPS_LIST;
                default:
                    throw new Error('Unknown property on location');
            }

        case VariableType.USER:
            switch (property) {
                case 'projects':
                    return VariableType.PROJECTS_LIST;
                case 'levels':
                    return VariableType.LEVELS_LIST;
                case 'roles':
                    return VariableType.ROLES_LIST;
                case 'locations':
                    return VariableType.LOCATIONS_LIST;
                case 'created_time':
                    return VariableType.DATE;
                case 'last_updated_time':
                    return VariableType.DATE;
                case 'last_login_time':
                    return VariableType.DATE;
                case 'last_pull_time':
                    return VariableType.DATE;
                case 'last_push_time':
                    return VariableType.DATE;
                default:
                    throw new Error('Unknown property on user');
            }

        case VariableType.MEMBER:
            switch (property) {
                case 'location':
                    return VariableType.LOCATION;
                case 'type':
                    return VariableType.TEXT;
                case 'created_time':
                    return VariableType.DATE;
                case 'last_updated_time':
                    return VariableType.DATE;
                default:
                    throw new Error('Unknown property on member');
            }

        case VariableType.GROUP:
            switch (property) {
                case 'location':
                    return VariableType.LOCATION;
                case 'type':
                    return VariableType.TEXT;
                case 'created_time':
                    return VariableType.DATE;
                case 'last_updated_time':
                    return VariableType.DATE;
                case 'all_members':
                    return VariableType.MEMBERS_LIST;
                case 'representatives':
                    return VariableType.MEMBERS_LIST;
                default:
                    throw new Error('Unknown property on group');
            }

        case VariableType.WORKFLOW:
            switch (property) {
                case 'user':
                    return VariableType.USER;
                case 'type':
                    return VariableType.TEXT;
                case 'tracking_users':
                    return VariableType.USERS_LIST;
                case 'triggering_workflow':
                    return VariableType.WORKFLOW;
                case 'created_time':
                    return VariableType.DATE;
                case 'last_updated_time':
                    return VariableType.DATE;
                case 'status':
                    return VariableType.STATUS;
                case 'due_date':
                    return VariableType.DATE;
                case 'member':
                    return VariableType.MEMBER;
                case 'group':
                    return VariableType.GROUP;
                default:
                    throw new Error('Unknown property on group');
            }

        case VariableType.STATUS:
            switch (property) {
                case 'name':
                    return VariableType.TEXT;
                case 'is_terminal':
                    return VariableType.BOOLEAN;
                case 'due_in_days':
                    return VariableType.NUMBER;
                default:
                    throw new Error('Unknown property on status');
            }

        case VariableType.STATIC_DATA:
            switch (property) {
                case 'name':
                    return VariableType.TEXT;
                case 'project':
                    return VariableType.PROJECT;
                case 'children':
                    return VariableType.DATA_FRAGMENTS_LIST;
                default:
                    throw new Error('Unknown property on static holder');
            }

        case VariableType.DATA_FRAGMENT:
            switch (property) {
                case 'name':
                    return VariableType.TEXT;
                case 'value':
                    return VariableType.TEXT;
                case 'children':
                    return VariableType.DATA_FRAGMENTS_LIST;
                default:
                    throw new Error('Unknown property on static holder');
            }
        default:
            throw new Error('There are no properties for the given type');
    }
}

function getProjectProperty(project: IProject, propertyData: NestingData | string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch (property) {
        case 'name':
            return project.name;
        case 'locations':
            return project.locations;
        default:
            throw new Error('Unknown property on location');
    }
}

function getLevelProperty(level: ILevel, propertyData: NestingData | string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch (property) {
        case 'name':
            return level.name;
        default:
            throw new Error('Unknown property on location');
    }
}

function getRoleProperty(role: IRole, propertyData: NestingData | string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch (property) {
        case 'name':
            return role.name;
        case 'threshold_days_for_members':
            return role.thresholdDaysForMembers;
        case 'threshold_days_for_groups':
            return role.thresholdDaysForGroups;
        case 'threshold_days_for_workflows':
            return role.thresholdDaysForWorkflows;
        case 'can_transfer':
            return role.bulkActions;
        case 'bulk_actions':
            return role.bulkActions;
        default:
            throw new Error('Unknown property on location');
    }
}

function getLocationProperty(location: ILocation, propertyData: NestingData | string, applicationState: ApplicationState) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch (property) {
        case 'name':
            return location.name;
        case 'parent':
            if (typeof location.parent === 'undefined') {
                throw new Error('This location does not have a parent');
            }

            return location.parent && location.parent in applicationState.structure.locations.byId ? location.parent : undefined;
        case 'users':
            return Array.from(new Set(location.users)).filter(userId => {
                const user = applicationState.users.byId[userId];
                return user && !user.archived;
            });
        case 'members':
            // return applicationState.members.allEntries.filter(memberId => {
            //     const member = applicationState.members.byId[memberId];
            //     return member.location === location.id;
            // });
            return Array.from(new Set(location.members)).filter(memberId => {
                const member = applicationState.members.byId[memberId];
                return member && !member.archived;
            });
        case 'groups':
            // return applicationState.groups.allEntries.filter(groupId => {
            //     const group = applicationState.groups.byId[groupId];
            //     return group.location === location.id;
            // });
            return Array.from(new Set(location.groups)).filter(groupId => {
                const group = applicationState.groups.byId[groupId];
                return group && !group.archived;
            });
        case 'children':
            return location.children
        default:
            throw new Error('Unknown property on location');
    }
}

function getUserProperty(user: IUser, propertyData: NestingData | string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch (property) {
        case 'projects':
            return user.projects;
        case 'levels':
            return user.levels;
        case 'roles':
            return user.roles;
        case 'locations':
            return user.locations;
        case 'created_time':
            return user.createdTime;
        case 'last_updated_time':
            return user.lastUpdatedTime;
        case 'last_login_time':
            return user.lastLoginTime;
        case 'last_push_time':
            return user.lastPushTime;
        case 'last_pull_time':
            return user.lastPullTime;
        default:
            throw new Error('Unknown property on user');
    }
}

function getMemberProperty(member: IMember, propertyData: NestingData | string, memberTypeState: MemberTypeState) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch (property) {
        case 'location':
            return member.location;
        case 'type':
            const memberType = memberTypeState.byId[member.type];
            return memberType ? memberType.name : '-';
        case 'created_time':
            return member.createdTime;
        case 'last_updated_time':
            return member.lastUpdatedTime;
        default:
            throw new Error('Unknown property on member');
    }
}

function getGroupProperty(group: IGroup, propertyData: NestingData | string, membersData: MemberState, groupTypeState: GroupTypeState) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch (property) {
        case 'location':
            return group.location;
        case 'type':
            const groupType = groupTypeState.byId[group.type];
            return groupType ? groupType.name : '-';
        case 'created_time':
            return group.createdTime;
        case 'last_updated_time':
            return group.lastUpdatedTime;
        case 'representatives':
            return group.representatives.filter(memberId => {
                const member = membersData.byId[memberId];
                return member && !member.archived;
            });
        case 'all_members':
            return group.members.filter(memberId => {
                const member = membersData.byId[memberId];
                return member && !member.archived;
            });
        default:
            throw new Error('Unknown property on group');
    }
}

function getWorkflowProperty(workflow: IWorkflow, propertyData: NestingData | string, workflowTypeState: WorkflowTypeState) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch (property) {
        case 'user':
            return workflow.user;
        case 'type':
            const workflowType = workflowTypeState.byId[workflow.type];
            return workflowType ? workflowType.name : '-';
        case 'tracking_users':
            return workflow.trackingUsers;
        case 'triggering_workflow':
            return workflow.triggeringWorkflow;
        case 'status':
            return workflow.status;
        case 'created_time':
            return workflow.createdTime;
        case 'last_updated_time':
            return workflow.lastUpdatedTime;
        case 'due_date':
            return workflow.dueDate;
        case 'member':
            return workflow.affiliatedEntity;
        case 'group':
            return workflow.affiliatedEntity;
        default:
            throw new Error('Unknown property on workflow');
    }
}

function getStatusProperty(status: IStatus, propertyData: NestingData | string) {

    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch (property) {
        case 'name':
            return status.name;
        case 'is_terminal':
            return status.isTerminal;
        case 'due_in_days':
            return status.dueInDays;
        default:
            throw new Error('Unknown property on status');
    }
}

function getStaticDataProperty(staticData: IStaticDataHolder, propertyData: NestingData | string) {
    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch (property) {
        case 'name':
            return staticData.name;
        case 'project':
            return staticData.project;
        case 'children':
            return staticData.children;
        default:
            throw new Error('Unknown property on static data');
    }
}

function getDataFragmentProperty(dataFragment: IDataFragment, propertyData: NestingData | string) {
    const property = typeof propertyData === 'string' ? propertyData : propertyData.value;

    switch (property) {
        case 'name':
            return dataFragment.name;
        case 'children':
            return dataFragment.children;
        default:
            throw new Error('Unknown property on static data');
    }
}

export function getAllPropertiesOfType(type: VariableType, applicationState: ApplicationState) {

    switch (type) {
        case VariableType.PROJECT:
            return [{
                name: 'name',
                type: getTypeForProperty(VariableType.PROJECT, 'name'),
            }, {
                name: 'locations',
                type: getTypeForProperty(VariableType.PROJECT, 'locations'),
            }];
        case VariableType.LEVEL:
            return [{
                name: 'name',
                type: getTypeForProperty(VariableType.LEVEL, 'name'),
            }];
        case VariableType.ROLE:
            return [{
                name: 'name',
                type: getTypeForProperty(VariableType.ROLE, 'name'),
            }, {
                name: 'threshold_days_for_members',
                type: getTypeForProperty(VariableType.ROLE, 'threshold_days_for_members'),
            }, {
                name: 'threshold_days_for_groups',
                type: getTypeForProperty(VariableType.ROLE, 'threshold_days_for_groups'),
            }, {
                name: 'threshold_days_for_workflows',
                type: getTypeForProperty(VariableType.ROLE, 'threshold_days_for_workflows'),
            }, {
                name: 'bulk_actions',
                type: getTypeForProperty(VariableType.ROLE, 'bulk_actions'),
            }];
        case VariableType.LOCATION:
            return [{
                name: 'name',
                type: getTypeForProperty(VariableType.LOCATION, 'name'),
            }, {
                name: 'parent',
                type: getTypeForProperty(VariableType.LOCATION, 'parent'),
            }, {
                name: 'children',
                type: getTypeForProperty(VariableType.LOCATION, 'children'),
            }, {
                name: 'users',
                type: getTypeForProperty(VariableType.LOCATION, 'users'),
            }, {
                name: 'members',
                type: getTypeForProperty(VariableType.LOCATION, 'members'),
            }, {
                name: 'groups',
                type: getTypeForProperty(VariableType.LOCATION, 'groups'),
            }];

        case VariableType.USER:
            return [{
                name: 'projects',
                type: getTypeForProperty(VariableType.USER, 'projects'),
            }, {
                name: 'levels',
                type: getTypeForProperty(VariableType.USER, 'levels'),
            }, {
                name: 'roles',
                type: getTypeForProperty(VariableType.USER, 'roles'),
            }, {
                name: 'locations',
                type: getTypeForProperty(VariableType.USER, 'locations'),
            }, {
                name: 'created_time',
                type: getTypeForProperty(VariableType.USER, 'created_time'),
            }, {
                name: 'last_updated_time',
                type: getTypeForProperty(VariableType.USER, 'last_updated_time'),
            }, {
                name: 'last_login_time',
                type: getTypeForProperty(VariableType.USER, 'last_login_time'),
            }, {
                name: 'last_push_time',
                type: getTypeForProperty(VariableType.USER, 'last_push_time'),
            }, {
                name: 'last_pull_time',
                type: getTypeForProperty(VariableType.USER, 'last_pull_time'),
            }];

        case VariableType.MEMBER:
            return [{
                name: 'location',
                type: getTypeForProperty(VariableType.MEMBER, 'location'),
            }, {
                name: 'type',
                type: getTypeForProperty(VariableType.MEMBER, 'type'),
            }, {
                name: 'created_time',
                type: getTypeForProperty(VariableType.MEMBER, 'created_time'),
            }, {
                name: 'last_updated_time',
                type: getTypeForProperty(VariableType.MEMBER, 'last_updated_time'),
            }];
        case VariableType.GROUP:
            return [{
                name: 'location',
                type: getTypeForProperty(VariableType.GROUP, 'location'),
            }, {
                name: 'type',
                type: getTypeForProperty(VariableType.GROUP, 'type'),
            }, {
                name: 'created_time',
                type: getTypeForProperty(VariableType.GROUP, 'created_time'),
            }, {
                name: 'last_updated_time',
                type: getTypeForProperty(VariableType.GROUP, 'last_updated_time'),
            }, {
                name: 'representatives',
                type: getTypeForProperty(VariableType.GROUP, 'representatives'),
            }, {
                name: 'all_members',
                type: getTypeForProperty(VariableType.GROUP, 'all_members'),
            }];
        case VariableType.WORKFLOW:
            return [{
                name: 'user',
                type: getTypeForProperty(VariableType.WORKFLOW, 'user'),
            }, {
                name: 'type',
                type: getTypeForProperty(VariableType.WORKFLOW, 'type'),
            }, {
                name: 'tracking_users',
                type: getTypeForProperty(VariableType.WORKFLOW, 'tracking_users'),
            }, {
                name: 'triggering_workflow',
                type: getTypeForProperty(VariableType.WORKFLOW, 'triggering_workflow'),
            }, {
                name: 'created_time',
                type: getTypeForProperty(VariableType.WORKFLOW, 'created_time'),
            }, {
                name: 'last_updated_time',
                type: getTypeForProperty(VariableType.WORKFLOW, 'last_updated_time'),
            }, {
                name: 'status',
                type: getTypeForProperty(VariableType.WORKFLOW, 'status'),
            }, {
                name: 'due_date',
                type: getTypeForProperty(VariableType.WORKFLOW, 'due_date'),
            }];
        case VariableType.STATUS:
            return [{
                name: 'name',
                type: getTypeForProperty(VariableType.STATUS, 'name'),
            }, {
                name: 'is_terminal',
                type: getTypeForProperty(VariableType.STATUS, 'is_terminal'),
            }, {
                name: 'due_in_days',
                type: getTypeForProperty(VariableType.STATUS, 'due_in_days'),
            }];
        case VariableType.STATIC_DATA:
            return [{
                name: 'name',
                type: getTypeForProperty(VariableType.STATIC_DATA, 'name'),
            }, {
                name: 'project',
                type: getTypeForProperty(VariableType.STATIC_DATA, 'project'),
            }, {
                name: 'children',
                type: getTypeForProperty(VariableType.STATIC_DATA, 'children'),
            }];
        case VariableType.DATA_FRAGMENT:
            return [{
                name: 'name',
                type: getTypeForProperty(VariableType.DATA_FRAGMENT, 'name'),
            }, {
                name: 'value',
                type: getTypeForProperty(VariableType.DATA_FRAGMENT, 'value'),
            }, {
                name: 'children',
                type: getTypeForProperty(VariableType.DATA_FRAGMENT, 'children'),
            }];
        default:
            throw new Error('The given type has no properties');
    }
}


// The value of a given variable may be a simple date, or string, or whatever - but sometimes it is an ID for another entity, with many levels of nesting. This function will give you the true final value for a variable
export function getTrueValueFromVariableValue(variableValue: VariableValueType, variableType: VariableType, nesting: Array<NestingData>, applicationState: ApplicationState): VariableValueType {
    const structureData = applicationState.structure;
    const usersData = applicationState.users;
    const membersData = applicationState.members;
    const groupsData = applicationState.groups;
    const workflowsData = applicationState.workflows;
    const staticData = applicationState.staticInfo;
    let expandedVariableValue: VariableValueType;
    let newVariableType: VariableType;

    // If there are nested values, expand them
    if (typeof variableValue === 'string' && isUUID(variableValue) && nesting.length > 0) {

        switch (variableType) {
            case VariableType.PROJECT:
                expandedVariableValue = getProjectProperty(structureData.projects.byId[variableValue], nesting[0]);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.LEVEL:
                expandedVariableValue = getLevelProperty(structureData.levels.byId[variableValue], nesting[0]);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.ROLE:
                expandedVariableValue = getRoleProperty(structureData.roles.byId[variableValue], nesting[0]);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.LOCATION:
                expandedVariableValue = getLocationProperty(structureData.locations.byId[variableValue], nesting[0], applicationState);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.USER:

                if (nesting.length === 1) {

                    const propertyData = nesting[0];
                    const user = usersData.byId[variableValue];

                    if (isUUID(propertyData.value)) {
                        switch (propertyData.type) {
                            case 'WORKFLOWS_LIST':
                                let validWorkflows: Array<string> = [];

                                if (user.workflows[propertyData.value]) {
                                    validWorkflows = user.workflows[propertyData.value].filter(groupId => {
                                        const workflow = workflowsData.byId[groupId];

                                        if (!workflow) {
                                            return false;
                                        }

                                        return true;
                                    });
                                }

                                return validWorkflows;
                            default:
                                throw new Error('Unknown type on member');
                        }
                    } else {
                        expandedVariableValue = getUserProperty(user, nesting[0]);
                        return expandedVariableValue;
                    }
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.MEMBER:

                if (nesting.length === 1) {

                    const propertyData = nesting[0];
                    const member = membersData.byId[variableValue];

                    if (isUUID(propertyData.value)) {
                        switch (propertyData.type) {
                            case 'GROUPS_LIST':
                                let validGroups: Array<string> = [];

                                if (member.groups[propertyData.value]) {
                                    // validGroups = groupsData.allEntries.filter(groupId => {
                                    //     const group = groupsData.byId[groupId];

                                    //     if (!group) {
                                    //         return false;
                                    //     }

                                    //     if (group.type !== propertyData.value) {
                                    //         return false;
                                    //     }

                                    //     if (!group.members.includes(member.id)) {
                                    //         return false;
                                    //     }

                                    //     return true;
                                    // });

                                    validGroups = member.groups[propertyData.value].filter(groupId => groupId in groupsData.byId);
                                }

                                return validGroups;
                            case 'WORKFLOWS_LIST':
                                let validWorkflows: Array<string> = [];

                                if (member.workflows[propertyData.value]) {
                                    // validWorkflows = workflowsData.allEntries.filter(workflowId => {
                                    //     const workflow = workflowsData.byId[workflowId];

                                    //     if (!workflow) {
                                    //         return false;
                                    //     }

                                    //     if (workflow.type !== propertyData.value) {
                                    //         return false;
                                    //     }

                                    //     if (workflow.affiliatedEntity !== member.id) {
                                    //         return false;
                                    //     }

                                    //     return true;
                                    // });


                                    validWorkflows = member.workflows[propertyData.value].filter(workflowId => workflowId in workflowsData.byId);
                                }

                                return validWorkflows;
                            default:
                                throw new Error('Unknown type on member');
                        }
                    } else {
                        expandedVariableValue = getMemberProperty(member, nesting[0], membersData.types);
                        return expandedVariableValue;
                    }

                } else {
                    expandedVariableValue = getMemberProperty(membersData.byId[variableValue], nesting[0], membersData.types);
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.GROUP:

                if (nesting.length === 1) {

                    const propertyData = nesting[0];
                    const group = groupsData.byId[variableValue];

                    if (isUUID(propertyData.value)) {
                        switch (propertyData.type) {
                            case 'WORKFLOWS_LIST':
                                let validWorkflows: Array<string> = [];

                                if (group.workflows[propertyData.value]) {
                                    // validWorkflows = workflowsData.allEntries.filter(workflowId => {
                                    //     const workflow = workflowsData.byId[workflowId];

                                    //     if (!workflow) {
                                    //         return false;
                                    //     }

                                    //     if (workflow.type !== propertyData.value) {
                                    //         return false;
                                    //     }

                                    //     if (workflow.affiliatedEntity !== group.id) {
                                    //         return false;
                                    //     }

                                    //     return true;
                                    // });

                                    validWorkflows = group.workflows[propertyData.value].filter(workflowId => workflowId in workflowsData.byId);
                                }

                                return validWorkflows;
                            default:
                                throw new Error('Unknown type on group');
                        }
                    } else {
                        expandedVariableValue = getGroupProperty(group, nesting[0], membersData, groupsData.types);
                        return expandedVariableValue;
                    }

                } else {
                    expandedVariableValue = getGroupProperty(groupsData.byId[variableValue], nesting[0], membersData, groupsData.types);
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.WORKFLOW:
                expandedVariableValue = getWorkflowProperty(workflowsData.byId[variableValue], nesting[0], workflowsData.types);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.STATUS:
                expandedVariableValue = getStatusProperty(workflowsData.types.statuses.byId[variableValue], nesting[0]);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.STATIC_DATA:
                expandedVariableValue = getStaticDataProperty(staticData.byId[variableValue], nesting[0]);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }

            case VariableType.DATA_FRAGMENT:
                expandedVariableValue = getDataFragmentProperty(staticData.fragments.byId[variableValue], nesting[0]);

                if (nesting.length === 1) {
                    return expandedVariableValue;
                } else {
                    newVariableType = getTypeForProperty(variableType, nesting[0]);
                    return getTrueValueFromVariableValue(expandedVariableValue, newVariableType, nesting.slice(1), applicationState)
                }


            default:
                throw new Error('ID of unknown type');
        }

    }

    if (typeof variableValue === 'string' && !isNaN(Number(variableValue)) && variableType === VariableType.NUMBER) {
        return Number(variableValue);
    }

    return variableValue;

}
import { takeEvery, put, select, all, delay, takeLatest, call } from 'redux-saga/effects'
import { ADD_MEMBER_TYPE, AddMemberTypeAction, AddMemberTypeCustomFieldAction, UpdateMemberTypeCustomFieldAction, ADD_MEMBER_TYPE_CUSTOM_FIELD, UPDATE_MEMBER_TYPE_CUSTOM_FIELD, UPDATE_MEMBER_TYPES_DATA, UpdateMemberTypesData, MemberToTypeLink } from './types/types';
import { addMemberTypeCustomField, updateMemberTypeCustomFieldStartPiece, registerMemberTypeCustomFieldVariable, addMemberToMemberType, removeMemberFromMemberType, updateMemberTypeManagementForAdd, addMemberTypeManagementCustomFieldMapForAdd, updateMemberTypeManagementForUpdate, addMemberTypeManagementCustomFieldMapForUpdate, addActionToMemberType, removeActionFromMemberType, bulkAddMembersToMemberTypes, bulkRemoveMembersFromMemberTypes } from './types/actions';
import { CustomField, CustomFieldDataHolder, FieldType, IUpdateableCustomFieldData, IUpdateableWorkflowTypeCustomFieldData } from '../custom-fields/types';
import { addVariable } from '../flowchart/variables/actions';
import { VariableType } from '../flowchart/variables/types';
import { addPiece } from '../flowchart/pieces/actions';
import uuid from 'uuid';
import { PieceType, IStorePiece, IEndPiece, IVariablePiece, IAddMemberPiece, IPickFirstElementPiece, ICustomFieldPiece, IGroupPiece, IGroupedQuestionPiece, IGetValuePiece } from '../flowchart/pieces/types';
import { addFullPiece, setNextPiece } from '../flowchart/pieces/actions';
import { ApplicationState } from '../types';
import { AddMemberAction, DeleteMemberAction, UnArchiveMemberAction, UpdateMemberRequestAction, ADD_MEMBER, DELETE_MEMBER, UN_ARCHIVE_MEMBER, UPDATE_MEMBER_REQUEST, IMember, UPDATE_MEMBER_CUSTOM_FIELD_DATA, UpdateMemberCustomFieldDataAction, BulkAddMembersAction, BulkUpdateMembersRequestAction, MemberState, BULK_ADD_MEMBERS, BULK_UPDATE_MEMBERS_REQUEST, ADD_GROUP_TO_MEMBER, REMOVE_GROUP_FROM_MEMBER, UpdateMembersLocationRequestAction, UPDATE_MEMBERS_LOCATION_REQUEST, RecalculateComputedFieldsForMemberAction, RECALCULATE_COMPUTED_FIELDS_FOR_MEMBER, ChangeDeltaForMemberComputedFields, BULK_RECALCULATE_COMPUTED_FIELDS_FOR_MEMBER, BulkRecalculateComputedFieldsForMemberAction, ComputedFieldUpdatePayloadForMember, RECOMPUTE_ALL_MEMBERS, GO_TO_PAGE_MEMBER_TABLE, SET_PAGE_SIZE_MEMBER_TABLE, MemberFilters, SEARCH_MEMBER_TABLE, FILTER_MEMBER_TABLE } from './types';
import { addMemberToLocation, bulkAddMembersToLocations, bulkRemoveMembersFromLocations, removeMemberFromLocation } from '../structure/location/actions';
import { appendMembers, bulkRecalculateComputedFieldsForMember, bulkUpdateMemberComputedFieldData, bulkUpdateMembers, clearMemberEntries, goToPageMemberTable, recalculateComputedFieldsForMember, updateMember, updateMemberComputedFieldData, updateMembersLocation, setTotalNumberOfMembers } from './actions';
import { addMemberToGroup, appendGroups, bulkAddMembersToGroups, bulkRecalculateComputedFieldsForGroup, bulkRemoveMembersFromGroups, clearGroupEntries, recalculateComputedFieldsForGroup, removeMemberFromGroup } from '../groups/actions';
import { addWorkflowTypeCustomField, registerWorkflowTypeVariable } from '../workflows/types/actions';
import moment from 'moment';
import { AddMemberTypeActionAction, DeleteMemberTypeActionAction, ADD_MEMBER_TYPE_ACTION, DELETE_MEMBER_TYPE_ACTION } from './types/actions/types';
import { addMemberTypeAction } from './types/actions/actions';
import { AddMemberToGroupAction, ChangeDeltaForGroupComputedFields, GroupMemberLink, IGroup, RemoveMemberFromGroupAction } from '../groups/types';
import { MemberLocationLink } from '../structure/location/types';
import { getAllPiecesInPiece } from '../flowchart/helpers/pieces';
import { getValueForComputedField } from '../custom-fields';
import { ChangeDeltaForWorkflowComputedFields, IWorkflow } from '../workflows/types';
import { appendWorkflows, bulkRecalculateComputedFieldsForWorkflow, clearWorkflowEntries } from '../workflows/actions';
import { BASE_URL } from '../url';
import axios, { AxiosResponse } from 'axios';
import { FilterResponseForOnlineEntities, OnlineMemberQueryData, PageDataForOnlineEntities } from '../../helpers/synchronize/types';
import { IUser } from '../users/types';
import { isUUID } from '../../helpers/utilities';
import { clearInfoMessage, setInfoMessage, setLastRefreshTime } from '../my-data/actions';

function* createDefaultCustomFieldsAndManagementFlow(action: AddMemberTypeAction) {

    const nameCustomField: IUpdateableCustomFieldData = {
        id: action.payload.nameFieldId,
        name: 'Name',
        type: FieldType.TEXT,
        isComputed: false,
        isEditable: true,
        isDeletable: false,
        isInTable: true,
        seedEntityVariable: uuid.v4(),
    };

    const subTitleCustomField: IUpdateableCustomFieldData = {
        id: action.payload.subTitleFieldId,
        name: 'Sub Title',
        type: FieldType.TEXT,
        isComputed: false,
        isEditable: true,
        isDeletable: false,
        isInTable: true,
        seedEntityVariable: uuid.v4(),
    };

    const locationCustomField: IUpdateableCustomFieldData = {
        id: action.payload.locationFieldId,
        name: 'Last seen',
        type: FieldType.LOCATION,
        isComputed: false,
        isEditable: false,
        isDeletable: false,
        isInTable: false,
        seedEntityVariable: uuid.v4(),
    };

    yield all([
        put(addMemberTypeCustomField(nameCustomField, action.payload.id)),
        put(addMemberTypeCustomField(subTitleCustomField, action.payload.id)),
        put(addMemberTypeCustomField(locationCustomField, action.payload.id)),

        put(addMemberTypeAction({
            name: 'Add Member',
            icon: 'plus',
        }, action.payload.id)),

        put(addMemberTypeAction({
            name: 'Edit Member',
            icon: 'pencil',
        }, action.payload.id)),

        put(addMemberTypeAction({
            name: 'Delete Member',
            icon: 'trash',
        }, action.payload.id)),
    ]);

}

export function* watchMemberTypeCreationRequest() {
    yield takeEvery(ADD_MEMBER_TYPE, createDefaultCustomFieldsAndManagementFlow);
}

function* createDefaultActionsIfMissing(action: UpdateMemberTypesData) {
    let allDefaultActions: Array<any> = [];
    for (const memberType of action.data) {
        if (!memberType.archived && (!memberType.actions || memberType.actions.length === 0)) {
            allDefaultActions = allDefaultActions.concat([
                put(addMemberTypeAction({
                    name: 'Add Member',
                    icon: 'plus',
                }, memberType.id)),
                put(addMemberTypeAction({
                    name: 'Edit Member',
                    icon: 'pencil',
                }, memberType.id)),
                put(addMemberTypeAction({
                    name: 'Delete Member',
                    icon: 'trash',
                }, memberType.id))
            ]);
        }
    }

    yield all(allDefaultActions);
}

export function* watchMemberTypeInstantiationRequest() {
    yield takeEvery(UPDATE_MEMBER_TYPES_DATA, createDefaultActionsIfMissing);
}

function* createCustomFieldForManagedMemberTypeFlow(action: AddMemberTypeCustomFieldAction) {
    const state: ApplicationState = yield select();
    const memberType = state.members.types.byId[action.memberTypeId];
    let allPutActions = [];

    if (typeof memberType.addManagement !== 'undefined') {
        const managedAddWorkflowType = state.workflows.types.byId[memberType.addManagement.workflowTypeId];
        const newAddCustomFieldId = uuid.v4();

        const addWorkflowTypeCustomFieldData: IUpdateableWorkflowTypeCustomFieldData = {
            ...action.payload,
            id: newAddCustomFieldId,
            seedEntityVariable: uuid.v4(),
            affiliation: 'member',
        };

        allPutActions.push(put(addWorkflowTypeCustomField(addWorkflowTypeCustomFieldData, memberType.addManagement.workflowTypeId)));
        allPutActions.push(put(addMemberTypeManagementCustomFieldMapForAdd(action.memberTypeId, action.payload.id, newAddCustomFieldId)));

        const now = moment().format('YYYY-MM-DD');

        const questionPieceData: IGroupedQuestionPiece = {
            id: uuid.v4(),
            type: PieceType.GROUPED_QUESTION,
            customFieldId: newAddCustomFieldId,
            createdTime: now,
            lastUpdatedTime: now,
        };

        // This is a custom field piece used when storing the data in the member
        const customFieldPieceData: ICustomFieldPiece = {
            id: uuid.v4(),
            type: PieceType.CUSTOM_FIELD,
            customField: newAddCustomFieldId,
            createdTime: now,
            lastUpdatedTime: now,
        }

        // This is the variable piece used when storing the data in the member. Note that the variable value for this piece is yet to be filled in.
        const memberVariablePieceData: IVariablePiece = {
            id: uuid.v4(),
            type: PieceType.VARIABLE,
            createdTime: now,
            lastUpdatedTime: now,
        }

        // Use the custom field piece, as well as the member piece when creating the store piece
        const storePieceData: IStorePiece = {
            id: uuid.v4(),
            type: PieceType.STORE,
            entityType: memberType.id,
            customFieldId: action.payload.id,
            dataToStore: customFieldPieceData.id,
            variablePiece: memberVariablePieceData.id,
            createdTime: now,
            lastUpdatedTime: now,
        };

        if (memberType.addManagement.lastQuestionPiece === '' || memberType.addManagement.lastStorePiece === '') {
            // This is the first custom field that is being added. 

            const terminalStatusId = managedAddWorkflowType.statuses.find(statusId => state.workflows.types.statuses.byId[statusId].isTerminal);

            // We are creating an end piece
            const endPieceData: IEndPiece = {
                id: uuid.v4(),
                type: PieceType.END,
                status: terminalStatusId,
                createdTime: now,
                lastUpdatedTime: now,
            };
            allPutActions.push(put(addFullPiece(endPieceData)));

            // The 'New Member' Variable is added. This is used when adding a member
            const newMemberVariableId = uuid.v4();
            allPutActions.push(put(addVariable({
                id: newMemberVariableId,
                name: 'New Member',
                type: VariableType.MEMBER,
            })));
            allPutActions.push(put(registerWorkflowTypeVariable(newMemberVariableId, managedAddWorkflowType.id)));

            // Now that the variable has been created, fill it into the variable piece used in "store"
            memberVariablePieceData.variable = newMemberVariableId;

            // We are getting a list of locations from the workflow's user...
            const locationsPieceData: IVariablePiece = {
                id: uuid.v4(),
                type: PieceType.VARIABLE,
                variable: managedAddWorkflowType.seedEntityVariable,
                nesting: [{
                    value: 'user',
                }, {
                    value: 'locations',
                }],
                createdTime: now,
                lastUpdatedTime: now,
            }

            allPutActions.push(put(addFullPiece(locationsPieceData)));

            // ...and picking the first of those locations (for use when adding a member)
            const firstLocationPieceData: IPickFirstElementPiece = {
                id: uuid.v4(),
                type: PieceType.PICK_FIRST_ELEMENT,
                operand: locationsPieceData.id,
                createdTime: now,
                lastUpdatedTime: now,
            }

            allPutActions.push(put(addFullPiece(firstLocationPieceData)));

            // The add member piece uses the newly created variable, and the first location of the user
            const addMemberPieceData: IAddMemberPiece = {
                id: uuid.v4(),
                type: PieceType.ADD_MEMBER,

                nextPiece: storePieceData.id,
                variable: newMemberVariableId,
                entityType: memberType.id,
                locationPiece: firstLocationPieceData.id,

                createdTime: now,
                lastUpdatedTime: now,
            }

            allPutActions.push(put(addFullPiece(addMemberPieceData)));
            storePieceData.nextPiece = endPieceData.id;

            // Create a new group piece that stores all the questions
            const groupPieceData: IGroupPiece = {
                id: uuid.v4(),
                type: PieceType.GROUP,
                nextPiece: addMemberPieceData.id,
                innerPiece: questionPieceData.id,
                createdTime: now,
                lastUpdatedTime: now,
            }

            // The new group piece is the first piece in the flowchart
            allPutActions.push(put(addFullPiece(groupPieceData)));
            allPutActions.push(put(setNextPiece(managedAddWorkflowType.startPiece.piece, groupPieceData.id)));
        } else {
            const previousLastQuestionPiece = state.flowchart.pieces.byId[memberType.addManagement.lastQuestionPiece];

            allPutActions.push(put(setNextPiece(memberType.addManagement.lastQuestionPiece, questionPieceData.id)));

            if ('nextPiece' in previousLastQuestionPiece) {
                questionPieceData.nextPiece = previousLastQuestionPiece.nextPiece;
            }

            const previousLastStorePiece = state.flowchart.pieces.byId[memberType.addManagement.lastStorePiece];

            if ('variablePiece' in previousLastStorePiece && typeof previousLastStorePiece.variablePiece !== 'undefined') {
                const previousLastStoreVariablePiece = state.flowchart.pieces.byId[previousLastStorePiece.variablePiece];
                if ('variable' in previousLastStoreVariablePiece) {
                    // Get the variable from the last store piece's variable piece
                    memberVariablePieceData.variable = previousLastStoreVariablePiece.variable;
                }
            }

            allPutActions.push(put(setNextPiece(memberType.addManagement.lastStorePiece, storePieceData.id)));

            if ('nextPiece' in previousLastStorePiece) {
                storePieceData.nextPiece = previousLastStorePiece.nextPiece;
            }
        }

        allPutActions.push(put(addFullPiece(questionPieceData)));
        allPutActions.push(put(addFullPiece(customFieldPieceData)));
        allPutActions.push(put(addFullPiece(memberVariablePieceData)));
        allPutActions.push(put(addFullPiece(storePieceData)));

        allPutActions.push(put(updateMemberTypeManagementForAdd(action.memberTypeId, managedAddWorkflowType.id, questionPieceData.id, storePieceData.id)));

    }

    if (typeof memberType.updateManagement !== 'undefined') {
        const managedUpdateWorkflowType = state.workflows.types.byId[memberType.updateManagement.workflowTypeId];
        const newUpdateCustomFieldId = uuid.v4();

        const updateWorkflowTypeCustomFieldData: IUpdateableWorkflowTypeCustomFieldData = {
            ...action.payload,
            id: newUpdateCustomFieldId,
            seedEntityVariable: uuid.v4(),
            affiliation: 'member',
        };

        allPutActions.push(put(addWorkflowTypeCustomField(updateWorkflowTypeCustomFieldData, memberType.updateManagement.workflowTypeId)));
        allPutActions.push(put(addMemberTypeManagementCustomFieldMapForUpdate(action.memberTypeId, action.payload.id, newUpdateCustomFieldId)));

        const now = moment().format('YYYY-MM-DD');

        // This is the variable piece used when getting the default data from the member.
        const memberVariablePieceDataForGet: IVariablePiece = {
            id: uuid.v4(),
            type: PieceType.VARIABLE,
            variable: managedUpdateWorkflowType.seedAffiliationVariable,
            createdTime: now,
            lastUpdatedTime: now,
        }

        // This is the variable piece used when storing the data in the member.
        const getValuePieceData: IGetValuePiece = {
            id: uuid.v4(),
            type: PieceType.GET_VALUE,
            variablePiece: memberVariablePieceDataForGet.id,
            entityType: memberType.id,
            customFieldId: action.payload.id,
            createdTime: now,
            lastUpdatedTime: now,
        }

        const questionPieceData: IGroupedQuestionPiece = {
            id: uuid.v4(),
            type: PieceType.GROUPED_QUESTION,
            customFieldId: newUpdateCustomFieldId,
            default: getValuePieceData.id,
            createdTime: now,
            lastUpdatedTime: now,
        };

        // This is a custom field piece used when storing the data in the member
        const customFieldPieceData: ICustomFieldPiece = {
            id: uuid.v4(),
            type: PieceType.CUSTOM_FIELD,
            customField: newUpdateCustomFieldId,
            createdTime: now,
            lastUpdatedTime: now,
        }

        // This is the variable piece used when storing the data in the member.
        const memberVariablePieceDataForStore: IVariablePiece = {
            id: uuid.v4(),
            type: PieceType.VARIABLE,
            variable: managedUpdateWorkflowType.seedAffiliationVariable,
            createdTime: now,
            lastUpdatedTime: now,
        }

        // Use the custom field piece, as well as the member piece when creating the store piece
        const storePieceData: IStorePiece = {
            id: uuid.v4(),
            type: PieceType.STORE,
            entityType: memberType.id,
            customFieldId: action.payload.id,
            dataToStore: customFieldPieceData.id,
            variablePiece: memberVariablePieceDataForStore.id,
            createdTime: now,
            lastUpdatedTime: now,
        };

        if (memberType.updateManagement.lastQuestionPiece === '' || memberType.updateManagement.lastStorePiece === '') {
            // This is the first custom field that is being added. 

            const terminalStatusId = managedUpdateWorkflowType.statuses.find(statusId => state.workflows.types.statuses.byId[statusId].isTerminal);

            // We are creating an end piece
            const endPieceData: IEndPiece = {
                id: uuid.v4(),
                type: PieceType.END,
                status: terminalStatusId,
                createdTime: now,
                lastUpdatedTime: now,
            };
            allPutActions.push(put(addFullPiece(endPieceData)));

            storePieceData.nextPiece = endPieceData.id;

            // Create a new group piece that stores all the questions
            const groupPieceData: IGroupPiece = {
                id: uuid.v4(),
                type: PieceType.GROUP,
                nextPiece: storePieceData.id,
                innerPiece: questionPieceData.id,
                createdTime: now,
                lastUpdatedTime: now,
            }

            // The new group piece is the first piece in the flowchart
            allPutActions.push(put(addFullPiece(groupPieceData)));
            allPutActions.push(put(setNextPiece(managedUpdateWorkflowType.startPiece.piece, groupPieceData.id)));
        } else {
            const previousLastQuestionPiece = state.flowchart.pieces.byId[memberType.updateManagement.lastQuestionPiece];

            allPutActions.push(put(setNextPiece(memberType.updateManagement.lastQuestionPiece, questionPieceData.id)));

            if ('nextPiece' in previousLastQuestionPiece) {
                questionPieceData.nextPiece = previousLastQuestionPiece.nextPiece;
            }

            const previousLastStorePiece = state.flowchart.pieces.byId[memberType.updateManagement.lastStorePiece];

            if ('variablePiece' in previousLastStorePiece && typeof previousLastStorePiece.variablePiece !== 'undefined') {
                const previousLastStoreVariablePiece = state.flowchart.pieces.byId[previousLastStorePiece.variablePiece];
                if ('variable' in previousLastStoreVariablePiece) {
                    // Get the variable from the last store piece's variable piece
                    memberVariablePieceDataForStore.variable = previousLastStoreVariablePiece.variable;
                }
            }

            allPutActions.push(put(setNextPiece(memberType.updateManagement.lastStorePiece, storePieceData.id)));

            if ('nextPiece' in previousLastStorePiece) {
                storePieceData.nextPiece = previousLastStorePiece.nextPiece;
            }
        }

        allPutActions.push(put(addFullPiece(memberVariablePieceDataForGet)));
        allPutActions.push(put(addFullPiece(getValuePieceData)));
        allPutActions.push(put(addFullPiece(questionPieceData)));
        allPutActions.push(put(addFullPiece(customFieldPieceData)));
        allPutActions.push(put(addFullPiece(memberVariablePieceDataForStore)));
        allPutActions.push(put(addFullPiece(storePieceData)));

        allPutActions.push(put(updateMemberTypeManagementForUpdate(action.memberTypeId, managedUpdateWorkflowType.id, questionPieceData.id, storePieceData.id)));

    }

    if (allPutActions.length > 0) {
        yield all(allPutActions);
    }
}

function* createSeedFlowchartForMemberTypeCustomField(action: AddMemberTypeCustomFieldAction | UpdateMemberTypeCustomFieldAction) {

    const state: ApplicationState = yield select();

    // Creating seed flowcharts are only required for computed fields
    if (action.payload.isComputed) {

        if (!action.payload.seedEntityVariable) {
            throw new Error('Computed fields need to have the seed workflow variable ID defined');
        }

        // Only seed the flochart if it doesn't already exist
        if (!state.flowchart.variables.byId.hasOwnProperty(action.payload.seedEntityVariable)) {

            const startPieceId = uuid.v4();

            yield all([
                put(addVariable({
                    id: action.payload.seedEntityVariable,
                    name: 'Member',
                    type: VariableType.MEMBER,
                })),

                put(registerMemberTypeCustomFieldVariable(action.payload.seedEntityVariable, action.payload.id)),

                put(addPiece(startPieceId, PieceType.START)),

                put(updateMemberTypeCustomFieldStartPiece({
                    piece: startPieceId,
                    position: {
                        x: 0,
                        y: 0,
                    }
                }, action.payload.id)),
            ]);

        }

        const changeDeltas: Array<ChangeDeltaForMemberComputedFields> = Object.keys(state.members.byId).map(memberId => {
            return {
                memberId,
                memberChanged: true,
                locationChanged: false,
                groupTypesChanged: [],
                workflowTypesChanged: [],
            }
        })

        yield put(bulkRecalculateComputedFieldsForMember(changeDeltas));

    }
}

function* indexAndProvideReverseLinksForNewMember(action: AddMemberAction) {
    let allPutActions = [];

    allPutActions.push(put(addMemberToLocation(action.payload.id, action.payload.location)));
    allPutActions.push(put(addMemberToMemberType(action.payload.id, action.payload.type)));
    allPutActions.push(put(recalculateComputedFieldsForMember(action.payload.id, true, false, [], [])));

    const groupTypeIds = new Set(Object.keys(action.payload.groups));

    for (const groupTypeId of groupTypeIds) {
        const newGroupIds = new Set(action.payload.groups[groupTypeId]);

        for (const newGroupId of newGroupIds) {
            allPutActions.push(put(addMemberToGroup(newGroupId, action.payload.id)));
        }
    }

    yield all(allPutActions);
}

function* indexMemberAfterModifyingGroup(action: AddMemberToGroupAction | RemoveMemberFromGroupAction) {
    const group: IGroup = yield select(state => state.groups.byId[action.groupId]);
    const member: IMember = yield select(state => state.members.byId[action.memberId]);

    yield put(recalculateComputedFieldsForMember(action.memberId, false, false, [group.type], []));
    yield put(recalculateComputedFieldsForGroup(action.groupId, false, false, true, []));

    const state: ApplicationState = yield select();

    const linkedWorkflowIds = Object.keys(member.workflows).map(workflowTypeId => member.workflows[workflowTypeId]).flat().filter(workflowId => workflowId in state.workflows.byId);

    const changeDelta: Array<ChangeDeltaForWorkflowComputedFields> = linkedWorkflowIds.map(workflowId => {
        return {
            workflowId,
            workflowChanged: false,
            usersChanged: false,
            affiliationChanged: true,
        };
    });

    yield put(bulkRecalculateComputedFieldsForWorkflow(changeDelta));
}

function* provideReverseLinksForNewMembers(action: BulkAddMembersAction) {
    const allPutActions = [];

    const memberLocationLinks: Array<MemberLocationLink> = [];
    const memberToTypeLinks: Array<MemberToTypeLink> = [];
    const groupMemberLinks: Array<GroupMemberLink> = [];

    for (const newMemberData of action.payload) {
        memberLocationLinks.push({
            memberId: newMemberData.id,
            locationId: newMemberData.location,
        });

        memberToTypeLinks.push({
            memberId: newMemberData.id,
            memberTypeId: newMemberData.type,
        });

        for (const groupTypeId of Object.keys(newMemberData.groups)) {
            for (const groupId of newMemberData.groups[groupTypeId]) {
                groupMemberLinks.push({
                    memberId: newMemberData.id,
                    groupId,
                });
            }
        }
    }

    const memberIds = action.payload.map(updatedMemberData => updatedMemberData.id);

    const changeDeltas: Array<ChangeDeltaForMemberComputedFields> = memberIds.map(memberId => {
        return {
            memberId,
            memberChanged: true,
            locationChanged: false,
            groupTypesChanged: [],
            workflowTypesChanged: [],
        }
    });

    allPutActions.push(put(bulkAddMembersToLocations(memberLocationLinks)));
    allPutActions.push(put(bulkAddMembersToGroups(groupMemberLinks)));

    allPutActions.push(put(bulkRecalculateComputedFieldsForMember(changeDeltas)));

    yield all(allPutActions);
}

function* provideReverseLinkForNewMemberTypeAction(action: AddMemberTypeActionAction) {
    yield put(addActionToMemberType(action.payload.id, action.parentId));
}

function* removeReverseLinkForMemberTypeAction(action: DeleteMemberTypeActionAction) {
    yield put(removeActionFromMemberType(action.id, action.parentId));
}

function* provideReverseLinksForExistingMember(action: UnArchiveMemberAction) {
    const member: IMember = yield select(state => state.members.byId[action.id]);
    let allPutActions = [];

    allPutActions.push(put(addMemberToLocation(action.id, member.location)));
    allPutActions.push(put(addMemberToMemberType(action.id, member.type)));

    const groupTypeIds = new Set(Object.keys(member.groups));

    for (const groupTypeId of groupTypeIds) {
        const newGroupIds = new Set(member.groups[groupTypeId]);

        for (const newGroupId of newGroupIds) {
            allPutActions.push(put(addMemberToGroup(newGroupId, member.id)));
        }
    }

    yield all(allPutActions);
}

function* removeReverseLinksForMember(action: DeleteMemberAction) {
    const currentMember: IMember = yield select(state => state.members.byId[action.id]);
    let allPutActions = [];

    allPutActions.push(put(removeMemberFromLocation(action.id, currentMember.location)));
    allPutActions.push(put(removeMemberFromMemberType(action.id, currentMember.type)));

    const groupTypeIds = new Set(Object.keys(currentMember.groups));

    for (const groupTypeId of groupTypeIds) {
        const oldGroupIds = new Set(currentMember.groups[groupTypeId]);

        for (const oldGroupId of oldGroupIds) {
            allPutActions.push(put(removeMemberFromGroup(oldGroupId, action.id)));
        }
    }

    yield all(allPutActions);
}

function* reIndexMemberAfterCustomFieldUpdate(action: UpdateMemberCustomFieldDataAction) {
    yield delay(1000);

    const workflow: IWorkflow = yield select(state => state.workflows.byId[action.workflowId]);

    yield put(recalculateComputedFieldsForMember(action.memberId, true, false, [], [workflow.type]));

    const member: IMember = yield select(state => state.members.byId[action.memberId]);
    const allGroupIds = Object.keys(member.groups).map(groupId => member.groups[groupId]).flat();

    const changeDeltas: Array<ChangeDeltaForGroupComputedFields> = allGroupIds.map(groupId => {
        return {
            groupId,
            groupChanged: false,
            locationChanged: false,
            membersChanged: true,
            workflowTypesChanged: [],
        }
    })

    yield put(bulkRecalculateComputedFieldsForGroup(changeDeltas));

    const state: ApplicationState = yield select();

    const linkedWorkflowIds = Object.keys(member.workflows).map(workflowTypeId => member.workflows[workflowTypeId]).flat().filter(workflowId => workflowId in state.workflows.byId);

    const changeDelta: Array<ChangeDeltaForWorkflowComputedFields> = linkedWorkflowIds.map(workflowId => {
        return {
            workflowId,
            workflowChanged: false,
            usersChanged: false,
            affiliationChanged: true,
        };
    });

    yield put(bulkRecalculateComputedFieldsForWorkflow(changeDelta));
}

function* updateLocationReverseLink(action: UpdateMembersLocationRequestAction) {
    let allPutActions = [];

    const state: ApplicationState = yield select();

    for (const memberId of action.memberIds) {
        const currentMember: IMember = state.members.byId[memberId];

        if (action.locationId !== currentMember.location) {
            allPutActions.push(put(removeMemberFromLocation(memberId, currentMember.location)));
            allPutActions.push(put(addMemberToLocation(memberId, action.locationId)));
        }
    }

    allPutActions.push(put(updateMembersLocation(action.memberIds, action.locationId)));
    yield all(allPutActions);

    const changeDeltas: Array<ChangeDeltaForMemberComputedFields> = action.memberIds.map(memberId => {
        return {
            memberId,
            memberChanged: true,
            locationChanged: false,
            groupTypesChanged: [],
            workflowTypesChanged: [],
        }
    });

    yield put(bulkRecalculateComputedFieldsForMember(changeDeltas));

}

function shouldUpdateCustomField(customField: CustomField, changeDelta: ChangeDeltaForMemberComputedFields, variablePiecesInFlowchart: Array<IVariablePiece>) {

    if (!customField.isComputed || !customField.startPiece) {
        return false;
    }

    if (changeDelta.memberChanged) {
        return true;
    }

    if (changeDelta.locationChanged) {
        const usesLocation = variablePiecesInFlowchart.some(variablePiece => {
            const isMemberVariable = variablePiece.variable === customField.seedEntityVariable;

            if (!isMemberVariable) {
                return false;
            }

            const nestingObject = variablePiece.nesting;

            if (!nestingObject) {
                return false;
            }

            if (nestingObject.length === 0) {
                return false;
            }

            return nestingObject[0].type === 'LOCATION';
        });

        return usesLocation;
    }

    for (const groupTypeId of changeDelta.groupTypesChanged) {
        const usesGroupType = variablePiecesInFlowchart.some(variablePiece => {
            const isMemberVariable = variablePiece.variable === customField.seedEntityVariable;

            if (!isMemberVariable) {
                return false;
            }

            const nestingObject = variablePiece.nesting;

            if (!nestingObject) {
                return false;
            }

            if (nestingObject.length === 0) {
                return false;
            }

            return nestingObject[0].type === 'GROUPS_LIST' && nestingObject[0].value === groupTypeId;
        });

        return usesGroupType;
    }

    for (const workflowTypeId of changeDelta.workflowTypesChanged) {
        const usesWorkflowType = variablePiecesInFlowchart.some(variablePiece => {
            const isMemberVariable = variablePiece.variable === customField.seedEntityVariable;

            if (!isMemberVariable) {
                return false;
            }

            const nestingObject = variablePiece.nesting;

            if (!nestingObject) {
                return false;
            }

            if (nestingObject.length === 0) {
                return false;
            }

            return nestingObject[0].type === 'WORKFLOWS_LIST' && nestingObject[0].value === workflowTypeId;
        });

        return usesWorkflowType;
    }

    return false;
}

function* recalculateComputedFieldsDataForMember(action: RecalculateComputedFieldsForMemberAction) {
    const state: ApplicationState = yield select();
    const member = state.members.byId[action.memberId];
    const memberType = state.members.types.byId[member.type];
    const changeDelta: ChangeDeltaForMemberComputedFields = {
        memberId: action.memberId,
        memberChanged: action.memberChanged,
        locationChanged: action.locationChanged,
        groupTypesChanged: action.groupTypesChanged,
        workflowTypesChanged: action.workflowTypesChanged,
    };

    const customFieldIdsToUpdate = memberType.customFields.filter(customFieldId => {
        const customField = state.members.types.customFields.byId[customFieldId];

        if (!customField.isComputed || !customField.startPiece) {
            return false;
        }

        const piecesInFlowchart = getAllPiecesInPiece(state.flowchart.pieces, customField.startPiece.piece);

        const variablePiecesInFlowchart: Array<IVariablePiece> = piecesInFlowchart.filter(piece => piece.type === PieceType.VARIABLE) as Array<IVariablePiece>;
        return shouldUpdateCustomField(customField, changeDelta, variablePiecesInFlowchart);
    });

    if (customFieldIdsToUpdate.length > 0) {
        const customFieldData: CustomFieldDataHolder = {};

        for (const customFieldId of customFieldIdsToUpdate) {
            const customField = state.members.types.customFields.byId[customFieldId];
            const fieldValue = getValueForComputedField(customField, action.memberId, 'member', state);

            if (fieldValue !== member.customFields[customFieldId]) {
                customFieldData[customFieldId] = fieldValue;
            }
        }

        if (Object.keys(customFieldData).length > 0) {
            yield put(updateMemberComputedFieldData(action.memberId, customFieldData));
        }

    }

}

function* bulkRecalculateComputedFieldsDataForMember(action: BulkRecalculateComputedFieldsForMemberAction) {
    const state: ApplicationState = yield select();

    const variablePiecesForFields: {
        [memberTypeId: string]: {
            [customFieldId: string]: Array<IVariablePiece>,
        }
    } = {};

    for (const memberTypeId of Object.keys(state.members.types.byId)) {
        variablePiecesForFields[memberTypeId] = {};
        const memberType = state.members.types.byId[memberTypeId];

        for (const customFieldId of memberType.customFields) {
            const customField = state.members.types.customFields.byId[customFieldId];

            if (!customField.isComputed || !customField.startPiece) {
                continue;
            }

            const piecesInFlowchart = getAllPiecesInPiece(state.flowchart.pieces, customField.startPiece.piece);
            const variablePiecesInFlowchart: Array<IVariablePiece> = piecesInFlowchart.filter(piece => piece.type === PieceType.VARIABLE) as Array<IVariablePiece>;
            variablePiecesForFields[memberTypeId][customFieldId] = variablePiecesInFlowchart;
        }
    }

    const bulkRecalculatePayload: Array<ComputedFieldUpdatePayloadForMember> = action.payload.map(changeDelta => {
        const member = state.members.byId[changeDelta.memberId];
        const memberType = state.members.types.byId[member.type];

        const customFieldIdsToUpdate = memberType.customFields.filter(customFieldId => {
            const customField = state.members.types.customFields.byId[customFieldId];

            if (!customField.isComputed || !customField.startPiece) {
                return false;
            }

            const variablePiecesInFlowchart = variablePiecesForFields[memberType.id][customFieldId];
            return shouldUpdateCustomField(customField, changeDelta, variablePiecesInFlowchart);
        });

        const customFieldData: CustomFieldDataHolder = {};

        for (const customFieldId of customFieldIdsToUpdate) {
            const customField = state.members.types.customFields.byId[customFieldId];
            const fieldValue = getValueForComputedField(customField, changeDelta.memberId, 'member', state);

            if (fieldValue !== member.customFields[customFieldId]) {
                customFieldData[customFieldId] = fieldValue;
            }
        }

        return {
            memberId: changeDelta.memberId,
            customFieldData,
        };
    });

    const filteredBulkRecalculatePayload = bulkRecalculatePayload.filter(payload => Object.keys(payload.customFieldData).length > 0);

    if (filteredBulkRecalculatePayload.length > 0) {
        yield put(bulkUpdateMemberComputedFieldData(bulkRecalculatePayload));
    }

}

function* recalculateAllMemberCustomFieldsData() {
    const state: ApplicationState = yield select();

    const changeDeltas: Array<ChangeDeltaForMemberComputedFields> = Object.keys(state.members.byId).map(memberId => {
        return {
            memberId,
            memberChanged: true,
            locationChanged: false,
            groupTypesChanged: [],
            workflowTypesChanged: [],
        }
    })

    yield put(bulkRecalculateComputedFieldsForMember(changeDeltas));
}

function* updateReverseLinksAndIndex(action: UpdateMemberRequestAction) {
    const currentMember: IMember = yield select(state => state.members.byId[action.payload.id]);

    let allPutActions = [];

    if (action.payload.location !== currentMember.location) {
        allPutActions.push(put(removeMemberFromLocation(action.payload.id, currentMember.location)));
        allPutActions.push(put(addMemberToLocation(action.payload.id, action.payload.location)));
    }

    if (action.payload.type !== currentMember.type) {
        allPutActions.push(put(removeMemberFromMemberType(action.payload.id, currentMember.type)));
        allPutActions.push(put(addMemberToMemberType(action.payload.id, action.payload.type)));
    }

    const groupTypeIds = new Set([...Object.keys(currentMember.groups), ...Object.keys(action.payload.groups)]);
    const persistingGroupIds: Set<string> = new Set();

    for (const groupTypeId of groupTypeIds) {
        const oldGroupIds = new Set(currentMember.groups[groupTypeId]);
        const newGroupIds = new Set(action.payload.groups[groupTypeId]);

        for (const oldGroupId of oldGroupIds) {
            if (!newGroupIds.has(oldGroupId)) {
                allPutActions.push(put(removeMemberFromGroup(oldGroupId, action.payload.id)));
            }
        }

        for (const newGroupId of newGroupIds) {
            if (!oldGroupIds.has(newGroupId)) {
                allPutActions.push(put(addMemberToGroup(newGroupId, action.payload.id)));
            } else {
                persistingGroupIds.add(newGroupId);
            }
        }
    }

    allPutActions.push(put(updateMember(action.payload)));

    yield all(allPutActions);

    const changeDeltas: Array<ChangeDeltaForGroupComputedFields> = Array.from(persistingGroupIds)
        .map(groupId => {
            return {
                groupId,
                groupChanged: false,
                locationChanged: false,
                membersChanged: true,
                workflowTypesChanged: [],
            }
        });

    yield put(bulkRecalculateComputedFieldsForGroup(changeDeltas));

    const state: ApplicationState = yield select();

    const linkedWorkflowIds = Object.keys(currentMember.workflows).map(workflowTypeId => currentMember.workflows[workflowTypeId]).flat().filter(workflowId => workflowId in state.workflows.byId);

    const changeDelta: Array<ChangeDeltaForWorkflowComputedFields> = linkedWorkflowIds.map(workflowId => {
        return {
            workflowId,
            workflowChanged: false,
            usersChanged: false,
            affiliationChanged: true,
        };
    });

    yield put(bulkRecalculateComputedFieldsForWorkflow(changeDelta));
}

function* bulkUpdateReverseLinks(action: BulkUpdateMembersRequestAction) {

    const allPutActions: Array<any> = [];

    const membersData: MemberState = yield select(state => state.members);

    const addMemberLocationLinks: Array<MemberLocationLink> = [];
    const removeMemberLocationLinks: Array<MemberLocationLink> = [];

    const addMemberToTypeLinks: Array<MemberToTypeLink> = [];
    const removeMemberFromTypeLinks: Array<MemberToTypeLink> = [];

    const addGroupMemberLinks: Array<GroupMemberLink> = [];
    const removeGroupMemberLinks: Array<GroupMemberLink> = [];

    const persistingGroupIds: Set<string> = new Set();

    for (const updateMemberData of action.payload) {
        const currentMember = membersData.byId[updateMemberData.id];

        if (updateMemberData.location !== currentMember.location) {
            removeMemberLocationLinks.push({
                memberId: updateMemberData.id,
                locationId: currentMember.location,
            });

            addMemberLocationLinks.push({
                memberId: updateMemberData.id,
                locationId: updateMemberData.location,
            });
        }

        if (updateMemberData.type !== currentMember.type) {
            removeMemberFromTypeLinks.push({
                memberId: updateMemberData.id,
                memberTypeId: currentMember.type,
            });

            addMemberToTypeLinks.push({
                memberId: updateMemberData.id,
                memberTypeId: updateMemberData.type,
            });
        }

        const groupTypeIds = new Set([...Object.keys(currentMember.groups), ...Object.keys(updateMemberData.groups)]);

        for (const groupTypeId of groupTypeIds) {
            const oldGroupIds = new Set(currentMember.groups[groupTypeId]);
            const newGroupIds = new Set(updateMemberData.groups[groupTypeId]);

            for (const oldGroupId of oldGroupIds) {
                if (!newGroupIds.has(oldGroupId)) {
                    removeGroupMemberLinks.push({
                        groupId: oldGroupId,
                        memberId: updateMemberData.id
                    });
                }
            }

            for (const newGroupId of newGroupIds) {
                if (!oldGroupIds.has(newGroupId)) {
                    addGroupMemberLinks.push({
                        groupId: newGroupId,
                        memberId: updateMemberData.id,
                    });
                } else {
                    persistingGroupIds.add(newGroupId);
                }
            }
        }

    }

    const memberIds = action.payload.map(updatedMemberData => updatedMemberData.id);

    const changeDeltas: Array<ChangeDeltaForMemberComputedFields> = memberIds.map(memberId => {
        return {
            memberId,
            memberChanged: true,
            locationChanged: false,
            groupTypesChanged: [],
            workflowTypesChanged: [],
        }
    });

    allPutActions.push(put(bulkAddMembersToLocations(addMemberLocationLinks)));
    allPutActions.push(put(bulkRemoveMembersFromLocations(removeMemberLocationLinks)));

    allPutActions.push(put(bulkAddMembersToMemberTypes(addMemberToTypeLinks)));
    allPutActions.push(put(bulkRemoveMembersFromMemberTypes(removeMemberFromTypeLinks)));

    allPutActions.push(put(bulkAddMembersToGroups(addGroupMemberLinks)));
    allPutActions.push(put(bulkRemoveMembersFromGroups(removeGroupMemberLinks)));

    allPutActions.push(put(bulkUpdateMembers(action.payload)));

    allPutActions.push(put(bulkRecalculateComputedFieldsForMember(changeDeltas)));

    const groupIds = action.payload.map(memberData => Object.keys(memberData.groups).map(groupTypeId => memberData.groups[groupTypeId]).flat()).flat();

    const changeDeltasForGroups: Array<ChangeDeltaForGroupComputedFields> = groupIds.map(groupId => {
        return {
            groupId,
            groupChanged: false,
            locationChanged: false,
            membersChanged: true,
            workflowTypesChanged: [],
        }
    });

    yield put(bulkRecalculateComputedFieldsForGroup(changeDeltasForGroups));

    yield all(allPutActions);
}

function fetchMemberPageData(pageSize: number, currentPageNumber: number) {

    const serverUrl = new URL('/online-members/', BASE_URL);

    serverUrl.searchParams.set('pageSize', String(pageSize));
    serverUrl.searchParams.set('currentPageNumber', String(currentPageNumber));

    return axios.get<PageDataForOnlineEntities>(serverUrl.toString(), {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

function fetchMemberQueryData(filters: MemberFilters, searchTerm: string, pageSize: number) {

    const serverUrl = new URL('/online-member-filters/', BASE_URL);

    const queryData: OnlineMemberQueryData = {
        filters,
        searchTerm,
        pageSize,
    }

    return axios.post(serverUrl.toString(), queryData, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

function* getNewPageDataForMember() {
    yield delay(200);

    const myId: string = yield select(state => state.myData.id);
    const user: IUser | undefined = isUUID(myId) ? yield select(state => state.users.byId[myId]) : undefined;

    if (user && user.isOnline) {
        const pageSize: number = yield select((state: ApplicationState) => state.members.pageSize);
        const currentPageNumber: number = yield select((state: ApplicationState) => state.members.currentPageNumber);

        yield all([
            put(clearMemberEntries()),
            put(clearGroupEntries()),
            put(clearWorkflowEntries()),
        ]);

        const pageResponseData: AxiosResponse<PageDataForOnlineEntities> = yield call(fetchMemberPageData, pageSize, currentPageNumber);

        if (pageResponseData.status === 200) {
            yield all([
                put(appendMembers(pageResponseData.data.members)),
                put(appendGroups(pageResponseData.data.groups)),
                put(appendWorkflows(pageResponseData.data.workflows)),
                put(setLastRefreshTime(pageResponseData.data.syncTime)),
            ]);
        }
    }
}

function* getFilteredDataForMember() {

    yield delay(1000);

    const myId: string = yield select(state => state.myData.id);
    const user: IUser | undefined = isUUID(myId) ? yield select(state => state.users.byId[myId]) : undefined;

    if (user && user.isOnline) {
        const memberFilters: MemberFilters = yield select((state: ApplicationState) => state.members.filters);
        const memberSearchTerm: string = yield select((state: ApplicationState) => state.members.searchTerm);
        const pageSize: number = yield select((state: ApplicationState) => state.members.pageSize);

        yield all([
            put(clearMemberEntries()),
            put(clearGroupEntries()),
            put(clearWorkflowEntries()),
            put(setInfoMessage('Applying member search/filters')),
        ]);

        const pageResponseData: AxiosResponse<FilterResponseForOnlineEntities> = yield call(fetchMemberQueryData, memberFilters, memberSearchTerm, pageSize);

        if (pageResponseData.status === 200) {
            yield all([
                put(appendMembers(pageResponseData.data.pageData.members)),
                put(appendGroups(pageResponseData.data.pageData.groups)),
                put(appendWorkflows(pageResponseData.data.pageData.workflows)),
                put(setTotalNumberOfMembers(pageResponseData.data.totalNumber)),
                put(setLastRefreshTime(pageResponseData.data.pageData.syncTime)),
                put(clearInfoMessage()),
            ]);
        }
    }
}

export function* watchMemberTypeCustomFieldCreation() {
    yield takeEvery(ADD_MEMBER_TYPE_CUSTOM_FIELD, createCustomFieldForManagedMemberTypeFlow);
}

export function* watchMemberTypeCustomFieldChanges() {
    yield takeEvery([ADD_MEMBER_TYPE_CUSTOM_FIELD, UPDATE_MEMBER_TYPE_CUSTOM_FIELD], createSeedFlowchartForMemberTypeCustomField);
}

export function* watchMemberTypeActionCreationChanges() {
    yield takeEvery(ADD_MEMBER_TYPE_ACTION, provideReverseLinkForNewMemberTypeAction);
}

export function* watchMemberTypeActionDeletionChanges() {
    yield takeEvery(DELETE_MEMBER_TYPE_ACTION, removeReverseLinkForMemberTypeAction);
}

export function* watchMemberCreationChanges() {
    yield takeEvery(ADD_MEMBER, indexAndProvideReverseLinksForNewMember);
}

export function* watchBulkMemberCreationChanges() {
    yield takeEvery(BULK_ADD_MEMBERS, provideReverseLinksForNewMembers);
}

export function* watchMemberDeletionChanges() {
    yield takeEvery(DELETE_MEMBER, removeReverseLinksForMember);
}

export function* watchMemberUnarchiveChanges() {
    yield takeEvery(UN_ARCHIVE_MEMBER, provideReverseLinksForExistingMember);
}

export function* watchMemberUpdateChanges() {
    yield takeEvery(UPDATE_MEMBER_REQUEST, updateReverseLinksAndIndex);
}

export function* watchBulkMemberUpdateChanges() {
    yield takeEvery(BULK_UPDATE_MEMBERS_REQUEST, bulkUpdateReverseLinks);
}

export function* watchMemberCustomFieldUpdateChanges() {
    yield takeLatest(UPDATE_MEMBER_CUSTOM_FIELD_DATA, reIndexMemberAfterCustomFieldUpdate);
}

export function* watchMemberAddToGroupChanges() {
    yield takeEvery(ADD_GROUP_TO_MEMBER, indexMemberAfterModifyingGroup)
}

export function* watchMemberRemoveFromGroupChanges() {
    yield takeEvery(REMOVE_GROUP_FROM_MEMBER, indexMemberAfterModifyingGroup)
}

export function* watchMemberLocationUpdate() {
    yield takeEvery(UPDATE_MEMBERS_LOCATION_REQUEST, updateLocationReverseLink)
}

export function* watchMemberCustomFieldRecalculation() {
    yield takeEvery(RECALCULATE_COMPUTED_FIELDS_FOR_MEMBER, recalculateComputedFieldsDataForMember);
}

export function* watchBulkMemberCustomFieldRecalculation() {
    yield takeEvery(BULK_RECALCULATE_COMPUTED_FIELDS_FOR_MEMBER, bulkRecalculateComputedFieldsDataForMember);
}

export function* watchRecomputeAllMembers() {
    yield takeEvery(RECOMPUTE_ALL_MEMBERS, recalculateAllMemberCustomFieldsData);
}

export function* watchMemberPagePageChanges() {
    yield takeLatest([GO_TO_PAGE_MEMBER_TABLE, SET_PAGE_SIZE_MEMBER_TABLE], getNewPageDataForMember);
}

export function* watchMemberQueryChangesForOnline() {
    yield takeLatest([SEARCH_MEMBER_TABLE, FILTER_MEMBER_TABLE], getFilteredDataForMember)
}
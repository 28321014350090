import { takeEvery, put, select } from 'redux-saga/effects'
import { AddLocationAction, DeleteLocationAction, ADD_LOCATION, DELETE_LOCATION, UPDATE_LOCATION, UpdateLocationAction, ILocation } from './types';
import { ApplicationState } from '../../types';
import { addLocationToProject, removeLocationFromProject } from '../project/actions';
import { bulkRecalculateComputedFieldsForMember } from '../../members/actions';
import { ChangeDeltaForMemberComputedFields } from '../../members/types';
import { bulkRecalculateComputedFieldsForGroup } from '../../groups/actions';
import { ChangeDeltaForGroupComputedFields } from '../../groups/types';
import { bulkRecalculateComputedFieldsForUser } from '../../users/actions';
import { ChangeDeltaForUserComputedFields } from '../../users/types';

function* addReverseLinkForParentProject(action: AddLocationAction) {
    const state: ApplicationState = yield select();
    if (action.parentId in state.structure.projects.byId) {
        yield put(addLocationToProject(action.payload.id, action.parentId));
    }
}

function* removeReverseLinkForParentProject(action: DeleteLocationAction) {
    const state: ApplicationState = yield select();
    if (action.parentId in state.structure.projects.byId) {
        yield put(removeLocationFromProject(action.id, action.parentId));
    }
}

function* recomputeComputedFieldValues(action: AddLocationAction | UpdateLocationAction) {
    const location: ILocation = yield select(state => state.structure.locations.byId[action.payload.id]);

    const userChangeDelta: Array<ChangeDeltaForUserComputedFields> = location.users.map(userId => {
        return {
            userId,
            userChanged: false,
            locationsChanged: true,
            workflowTypesChanged: [],
        }
    })

    yield put(bulkRecalculateComputedFieldsForUser(userChangeDelta));

    const memberChangeDelta: Array<ChangeDeltaForMemberComputedFields> = location.members.map(memberId => {
        return {
            memberId,
            memberChanged: false,
            locationChanged: true,
            groupTypesChanged: [],
            workflowTypesChanged: [],
        }
    })

    yield put(bulkRecalculateComputedFieldsForMember(memberChangeDelta));

    const groupChangeDelta: Array<ChangeDeltaForGroupComputedFields> = location.groups.map(groupId => {
        return {
            groupId,
            groupChanged: false,
            locationChanged: true,
            membersChanged: false,
            workflowTypesChanged: [],
        }
    })

    yield put(bulkRecalculateComputedFieldsForGroup(groupChangeDelta));
}

export function* watchLocationCreationChanges() {
    yield takeEvery(ADD_LOCATION, addReverseLinkForParentProject);
}

export function* watchLocationUpsertChanges() {
    yield takeEvery([ADD_LOCATION, UPDATE_LOCATION], recomputeComputedFieldValues);
}

export function* watchLocationDeletionChanges() {
    yield takeEvery(DELETE_LOCATION, removeReverseLinkForParentProject);
}
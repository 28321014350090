import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import { ApplicationState } from 'src/shared/store/types';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { MemberState, IMember } from 'src/shared/store/members/types';
import { getReadableDataForCustomField } from 'src/shared/store/custom-fields';
import { CustomFieldValueType, IUpdateableCustomFieldData } from 'src/shared/store/custom-fields/types';
import { DefaultFlowchartProcessState } from 'src/shared/store/flowchart/types';
import { getMemberComputedFieldValue } from 'src/shared/store/flowchart/helpers/custom-fields/member';
import { getUserComputedFieldValue } from 'src/shared/store/flowchart/helpers/custom-fields/user';
import { MemberProfileDetailsPage } from '../member-profile-details/member-profile-details.page';
import { UserState, IUser } from 'src/shared/store/users/types';
import { IGroup, GroupState } from 'src/shared/store/groups/types';
import { GroupDetailsPage } from '../group-details/group-details.page';
import { WorkflowState, IUpdateableWorkflowData, IWorkflow } from 'src/shared/store/workflows/types';
import { IMemberType } from 'src/shared/store/members/types/types';
import { IGroupType } from 'src/shared/store/groups/types/types';
import { IWorkflowType } from 'src/shared/store/workflows/types/types';
import { IStatus } from 'src/shared/store/workflows/types/statuses/types';
import { WorkflowExecutorPage } from '../workflow-executor/workflow-executor.page';
import * as moment from 'moment';
import { v4 as uuid } from 'uuid';
import { addWorkflow } from 'src/shared/store/workflows/actions';
import { NgRedux } from '@angular-redux/store';
import { AlertController } from '@ionic/angular';
import { ShareService } from '../services/share.service';
import { translatePhrase } from 'src/shared/helpers/translation';
import { ToastService, ToastType } from '../services/toast.service';
import { updateDueDate } from 'src/shared/store/workflows/actions';
import { PermissionSet } from 'src/shared/store/permissions/types';
import { completionPercentageOfWorkflow } from 'src/shared/store/flowchart/helpers/progress';
import { VariableValueType } from 'src/shared/helpers/common-types';

const pageSize = 15;

@Component({
    selector: 'app-statistical-details',
    templateUrl: './statistical-details.page.html',
    styleUrls: ['./statistical-details.page.scss'],
})
export class StatisticalDetailsPage implements OnInit, OnDestroy {
    @select(state => state) applicationStateSource: Observable<ApplicationState>;
    applicationState: ApplicationState;
    type: string;
    typeId: string;
    customFields: Array<string>;
    entityIds: Array<string>;
    membersData: MemberState;
    groupsData: GroupState;
    usersData: UserState;
    workflowsData: WorkflowState;
    showing_member_list: Array<IMember> = [];
    member_list: Array<IMember> = [];
    memberType: IMemberType;
    memberTypes: Array<IMemberType> = [];
    selectedMemberFireWorkflowType: IWorkflowType;
    groupType: IGroupType;
    all_group_types: Array<IGroupType> = [];
    user_list: Array<IUser> = [];
    showing_user_list: Array<IUser> = [];
    all_workflows: Array<IWorkflow> = [];
    showing_workflows: Array<IWorkflow> = [];
    all_groups: Array<IGroup> = [];
    showing_groups: Array<IGroup> = [];
    workflow_statuses: Array<IStatus> = [];
    selected_member: IMember;
    selected_group: IGroup;
    selected_affiliated_group: IGroup;
    selected_workflow: IWorkflow;
    all_customFields: Array<IUpdateableCustomFieldData> = [];
    applicationStateSubscription: Subscription;
    member_filter_popup: boolean = false;
    group_filter_popup: boolean = false;
    member_filter_param: string;
    group_filter_param: string;
    affiliated_workflow_types: Array<IWorkflowType> = [];
    new_workflow: IUpdateableWorkflowData;
    allowed_statuses: Array<IStatus> = [];
    my_id: string;
    selected_affiliated_member: IMember;
    header: string;
    reOrder: boolean = false;
    widgetId: string;
    phoneFieldId: string;
    selected_user: IUser;
    userPermission: PermissionSet;
    searchTerm: string = '';
    filteredGroups: Array<IGroup>;

    selectedWorkflowForDueDateChange: {
        id: string,
        preSelectedDate: string
    } = {
            id: null,
            preSelectedDate: null
        }

    constructor(
        public modalController: ModalController,
        public navParams: NavParams,
        private navCtrl: NavController,
        private ngRedux: NgRedux<ApplicationState>,
        public alertController: AlertController,
        public shareService: ShareService,
        public toastService: ToastService
    ) { }

    toggleFireWorkflowForMembers() {
        let affiliated_workflow_types = this.affiliated_workflow_types;

        affiliated_workflow_types = affiliated_workflow_types.filter((type) => {
            if (type.affiliatedEntity && type.affiliatedEntity !== this.selected_affiliated_member.type) {
                return false;
            }

            return this.getWorkflowPermission(type.id) === 'WRITE';
        });

        if (affiliated_workflow_types.length > 0) {
            this.member_filter_popup = !this.member_filter_popup;
        } else {
            this.toastService.presentToastWithOptions(this.translate('No Workflows to fire'), ToastType.INFO);
        }
    }


    getFormattedDate(date: any) {
        return moment(date).format('DD MMM YYYY');
    }

    getWorkflowSubtitle(workflow: IWorkflow) {

        const type = this.applicationState.workflows.types.byId[workflow.type];

        let details = '-';
        let readableValue = '-';

        if (!workflow.affiliatedEntity) {
            try {
                const allCustomFields = type.customFields.map(customFieldId => this.applicationState.workflows.types.customFields.byId[customFieldId]).filter((field) => {
                    return field.isInTable;
                });
                const workflowProcessState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
                const customFieldValue = workflowProcessState.customFields[allCustomFields[0].id];
                readableValue = getReadableDataForCustomField(customFieldValue as CustomFieldValueType, allCustomFields[0], workflow.id, 'workflow');
            } catch {
                readableValue = '-';
            }
        }

        if (type.subTitleFieldId) {
            const subTitleField = this.applicationState.workflows.types.customFields.byId[type.subTitleFieldId];
            const workflowProcessState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
            const detailsValue = workflowProcessState.customFields[type.subTitleFieldId];

            // Only allow fields that are not member-affiliated in group workflows
            if (Array.isArray(detailsValue) || typeof detailsValue !== 'object') {
                details = getReadableDataForCustomField(detailsValue, subTitleField, workflow.id, 'workflow');

                details = (details === '-' || details === undefined ? workflow.affiliatedEntity ? this.getAffiliatedEntityName(workflow.affiliatedEntity) : readableValue : details);
            }
        } else if (workflow.affiliatedEntity) {
            details = this.getAffiliatedEntityName(workflow.affiliatedEntity);
        }

        return details;
    }


    toggleFireWorkflowForGroups() {
        let affiliated_workflow_types = this.affiliated_workflow_types;

        affiliated_workflow_types = affiliated_workflow_types.filter((type) => {
            if (type.affiliatedEntity && type.affiliatedEntity !== this.selected_affiliated_group.type) {
                return false;
            }

            return this.getWorkflowPermission(type.id) === 'WRITE';
        });

        if (affiliated_workflow_types.length > 0) {
            this.group_filter_popup = !this.group_filter_popup;
        } else {
            this.toastService.presentToastWithOptions(this.translate('No Workflows to fire'), ToastType.INFO);
        }
    }

    translate(phrase: string) {
        return translatePhrase(phrase.trim());
    }

    getWorkflowPermission(id: string) {
        if (this.userPermission.workflows[id]) {
            return this.userPermission.workflows[id];
        } else {
            return this.userPermission.general.Workflows;
        }
    }

    ngOnInit() {
        this.header = this.navParams.get('header');
        this.type = this.navParams.get('type');
        this.typeId = this.navParams.get('typeId');
        this.customFields = this.navParams.get('customFields');
        this.entityIds = this.navParams.get('entityIds');
        this.widgetId = this.navParams.get('widgetId');

        this.applicationStateSubscription = this.applicationStateSource.subscribe((applicationState) => {
            this.applicationState = applicationState;

            this.my_id = this.applicationState.myData.id;
            this.usersData = this.applicationState.users;
            this.membersData = this.applicationState.members;
            this.groupsData = this.applicationState.groups;
            this.workflowsData = this.applicationState.workflows;
            this.userPermission = this.applicationState.permissions.myPermissions;

            this.membersData.types.customFields.allFields.forEach((field) => {
                this.membersData.types.customFields.byId[field].type.trim() === 'PHONE' ? this.phoneFieldId = field : null;
            });

            if (this.type === 'Member') {
                if (localStorage[this.widgetId + '-' + this.my_id]) {
                    let entities: Array<string> = JSON.parse(localStorage[this.widgetId + '-' + this.my_id]);

                    for (let i = 0; i < entities.length; i += 1) {
                        if (!this.entityIds.includes(entities[i])) {
                            entities.splice(entities.indexOf(entities[i]), 1);
                        }
                    }

                    for (let j = 0; j < this.entityIds.length; j += 1) {
                        if (!entities.includes(this.entityIds[j])) {
                            entities.push(this.entityIds[j]);
                        }
                    }

                    let temp_all_members = entities.map(memberId => this.membersData.byId[memberId]);

                    this.member_list = temp_all_members.filter(member => member !== undefined);
                } else {
                    this.member_list = this.entityIds.map(memberId => this.membersData.byId[memberId]);
                }

                this.showing_member_list = this.member_list.slice(0, pageSize);

                this.memberTypes = this.membersData.types.allEntries.map(typeId => this.membersData.types.byId[typeId]);

                this.memberType = this.membersData.types.byId[this.typeId];

                this.all_customFields = this.customFields.map(fieldId => {
                    return this.membersData.types.customFields.byId[fieldId];
                });

                this.workflowsData = this.applicationState.workflows;

                let workflow_types = this.workflowsData.types.allEntries.map((typeId) => {
                    return this.workflowsData.types.byId[typeId];
                });

                this.affiliated_workflow_types = workflow_types.filter((workflow) => {
                    return workflow.affiliation === 'member';
                });
            }

            if (this.type === 'User') {

                if (localStorage[this.widgetId + '-' + this.my_id]) {
                    let entities: Array<string> = JSON.parse(localStorage[this.widgetId + '-' + this.my_id]);

                    for (let i = 0; i < entities.length; i += 1) {
                        if (!this.entityIds.includes(entities[i])) {
                            entities.splice(entities.indexOf(entities[i]), 1);
                        }
                    }

                    for (let j = 0; j < this.entityIds.length; j += 1) {
                        if (!entities.includes(this.entityIds[j])) {
                            entities.push(this.entityIds[j]);
                        }
                    }

                    let temp_all_users = entities.map(userId => this.usersData.byId[userId]);

                    this.user_list = temp_all_users.filter(user => user !== undefined);
                } else {
                    this.user_list = this.entityIds.map(userId => this.usersData.byId[userId]);
                }

                this.showing_user_list = this.user_list.slice(0, pageSize);

                this.all_customFields = this.customFields.map(fieldId => this.usersData.customFields.byId[fieldId]);
            }

            if (this.type === 'Group') {
                if (localStorage[this.widgetId + '-' + this.my_id]) {
                    let entities: Array<string> = JSON.parse(localStorage[this.widgetId + '-' + this.my_id]);

                    for (let i = 0; i < entities.length; i += 1) {
                        if (!this.entityIds.includes(entities[i])) {
                            entities.splice(entities.indexOf(entities[i]), 1);
                        }
                    }

                    for (let j = 0; j < this.entityIds.length; j += 1) {
                        if (!entities.includes(this.entityIds[j])) {
                            entities.push(this.entityIds[j]);
                        }
                    }

                    let temp_all_groups = entities.map(groupId => this.groupsData.byId[groupId]);

                    this.all_groups = temp_all_groups.filter(group => group !== undefined);
                } else {
                    this.all_groups = this.entityIds.map(groupId => this.groupsData.byId[groupId]);
                }

                this.showing_groups = this.all_groups.slice(0, pageSize);

                this.all_group_types = this.groupsData.types.allEntries.map(typeId => this.groupsData.types.byId[typeId]);

                this.groupType = this.groupsData.types.byId[this.typeId];

                this.all_customFields = this.customFields.map(fieldId => this.groupsData.types.customFields.byId[fieldId]);
            }

            if (this.type === 'Workflow') {
                this.member_list = this.membersData.allEntries.map(memberId => this.membersData.byId[memberId]);

                this.all_groups = this.groupsData.allEntries.map(groupId => {
                    return this.groupsData.byId[groupId];
                });

                if (localStorage[this.widgetId + '-' + this.my_id]) {
                    let entities: Array<string> = JSON.parse(localStorage[this.widgetId + '-' + this.my_id]);

                    for (let i = 0; i < entities.length; i += 1) {
                        if (!this.entityIds.includes(entities[i])) {
                            entities.splice(entities.indexOf(entities[i]), 1);
                        }
                    }

                    for (let j = 0; j < this.entityIds.length; j += 1) {
                        if (!entities.includes(this.entityIds[j])) {
                            entities.push(this.entityIds[j]);
                        }
                    }

                    let temp_all_workflows = entities.map(workflowId => {
                        return this.workflowsData.byId[workflowId];
                    });

                    this.all_workflows = temp_all_workflows.filter(flow => flow !== undefined);
                } else {
                    this.all_workflows = this.entityIds.map(workflowId => this.workflowsData.byId[workflowId]);
                }

                this.showing_workflows = this.all_workflows.slice(0, pageSize);

                this.workflow_statuses = this.workflowsData.types.statuses.allEntries.map((statusId) => {
                    return this.workflowsData.types.statuses.byId[statusId];
                });

                this.all_customFields = this.customFields.map(fieldId => {
                    return this.workflowsData.types.customFields.byId[fieldId];
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.applicationStateSubscription) {
            this.applicationStateSubscription.unsubscribe();
        }
    }

    filterWidget() {

        const trimSearchTerm = this.searchTerm.toLowerCase().trim();

        this.showing_groups = this.all_groups.filter(group => {
            return this.getGroupName(group.id).toLowerCase().trim().includes(trimSearchTerm) ||
                this.getGroupSubtitle(group.id).toLowerCase().trim().includes(trimSearchTerm) ||
                this.getGroupTypeName(group.type).toLowerCase().trim().includes(trimSearchTerm);
        });

        this.showing_groups.slice(0,pageSize);

        this.showing_member_list = this.member_list.filter(member => {
            return this.getMemberName(member.id).toLocaleLowerCase().trim().includes(trimSearchTerm) ||
                this.getMemberSubtitle(member.id).toLowerCase().trim().includes(trimSearchTerm) ||
                this.getMemberTypeName(member.type).toLowerCase().trim().includes(trimSearchTerm);
        });

        this.showing_member_list.slice(0,pageSize);

        this.showing_user_list = this.user_list.filter(user => {
            return this.getUserName(user.id).toLowerCase().trim().includes(trimSearchTerm) ||
                this.getUserRoles(user.id).toLowerCase().trim().includes(trimSearchTerm);
        });

        this.showing_user_list.slice(0,pageSize);

        this.showing_workflows = this.all_workflows.filter(workflow => {
            return this.getWorkflowTypeName(workflow.type).toLowerCase().trim().includes(trimSearchTerm) ||
                this.getWorkflowSubtitle(workflow).toLowerCase().trim().includes(trimSearchTerm) ||
                this.getWorkflowStatus(workflow.status).toLowerCase().trim().includes(trimSearchTerm) ||
                moment(workflow.dueDate).format('DD MMM YYYY').toLowerCase().trim().includes(trimSearchTerm);
        });

        this.showing_workflows.slice(0,pageSize);

    }

    showMoreCards(event: any, type: 'user' | 'member' | 'group' | 'workflow') {

        switch (type) {
            case 'user':
                if (this.showing_user_list.length >= this.user_list.length) {
                    return;
                }

                this.showing_user_list = this.user_list.slice(0, this.showing_user_list.length + pageSize);
                break;
            case 'member':
                if (this.showing_member_list.length >= this.member_list.length) {
                    return;
                }

                this.showing_member_list = this.member_list.slice(0, this.showing_member_list.length + pageSize);
                break;
            case 'group':
                if (this.showing_groups.length >= this.all_groups.length) {
                    return;
                }

                this.showing_groups = this.all_groups.slice(0, this.showing_groups.length + pageSize);
                break;
            case 'workflow':
                if (this.showing_workflows.length >= this.all_workflows.length) {
                    return;
                }

                this.showing_workflows = this.all_workflows.slice(0, this.showing_workflows.length + pageSize);
                break;
            default:
                break;
        }

        event.target.complete();
    }

    getWorkflowStatus(statusId: string) {
        return this.applicationState.workflows.types.statuses.byId[statusId] ? this.applicationState.workflows.types.statuses.byId[statusId].name : '-';
    }

    getWorkflowTypeName(typeId: string) {
        return this.applicationState.workflows.types.byId[typeId] ? this.applicationState.workflows.types.byId[typeId].name : '-';
    }

    reOrderEntities(ev: any, partialList: Array<any>, list: Array<any>) {
        const itemMove = partialList.splice(ev.detail.from, 1)[0];
        partialList.splice(ev.detail.to, 0, itemMove);

        list.splice(ev.detail.from, 1)[0];
        list.splice(ev.detail.to, 0, itemMove);

        localStorage[this.widgetId + '-' + this.my_id] = JSON.stringify(list.map(listDetails => listDetails.id));
        ev.detail.complete();
    }

	getMemberTypeName(type_id: string) {
		let type = this.membersData.types.byId[type_id].name;
		return type;
	}

    getMemberPhoneNumber(memberId: string) {
        if (this.phoneFieldId) {
            if (this.membersData.byId[memberId].customFields[this.phoneFieldId]) {
                return this.membersData.byId[memberId].customFields[this.phoneFieldId].toString();
            } else {
                return '-';
            }
        } else {
            return '-';
        }
    }

    openCallApp(phone: string) {
        var url = "tel:" + phone;
        window.open(url);
    }

	shareMemberCard() {
		this.shareService.share('Member',
			'Member: ' + this.getMemberName(this.selected_member.id) + '\n' +
			'Subtitle: ' + this.getMemberSubtitle(this.selected_member.id) + '\n' +
			'Type: ' + this.getMemberTypeName(this.membersData.byId[this.selected_member.id].type)
			, null);
	}

	shareUserCard() {
		this.shareService.share('User',
			'User: ' + this.getUserName(this.selected_user.id) + '\n' +
			'Role: ' + this.getUserRoles(this.selected_user.id)
			, null);
	}

    getUserRoles(id: string) {
        return this.applicationState.users.byId[id].roles.map(roleId => this.applicationState.structure.roles.byId[roleId].name).join(', ');
    }

	shareGroupCard() {
		this.shareService.share('Group',
		'Group: ' + this.getGroupName(this.selected_group.id) + '\n' +
		'Subtitle: ' + this.getGroupSubtitle(this.selected_group.id) + '\n' +
		'Type: ' + this.getGroupTypeName(this.selected_group.type)
		, null);
	}

    shareWorkflowCard() {
        this.shareService.share('Workflow',
            'Workflow: ' + this.getWorkflowTypeName(this.selected_workflow.type) + '\n' +
            'Affiliated to: ' + this.getAffiliatedEntityName(this.selected_workflow.affiliatedEntity) + '\n' +
            'Status: ' + this.workflowsData.types.statuses.byId[this.selected_workflow.status].name
            + '\n' + 'Due on: ' + moment(this.selected_workflow.dueDate).format('DD-MM-YYYY'), null);
    }

	getGroupTypeName(type_id: string) {
		let type = this.groupsData.types.byId[type_id].name;
		return type;
	}

    getGroupSubtitle(groupId: string) {
        const group = this.groupsData.byId[groupId];
        const groupType = this.groupsData.types.byId[group.type];
        let groupSubtitle: CustomFieldValueType;
        const subTitleField = this.groupsData.types.customFields.byId[groupType.subTitleFieldId];

        groupSubtitle = group.customFields[groupType.subTitleFieldId];

        groupSubtitle = getReadableDataForCustomField(groupSubtitle, subTitleField, group.id, 'group');

        return groupSubtitle;
    }

    showMap(latlng: string) {
        var url = "https://maps.google.com/?q=" + latlng.split(' ')[0] + latlng.split(' ')[1];
        window.open(url);
    }

    getMemberLocation(memberId: string) {
        const member = this.membersData.byId[memberId];
        const memberType = this.membersData.types.byId[member.type];
        let memberLocation: VariableValueType;

        const locationField = this.membersData.types.customFields.byId[memberType.locationFieldId];

        if (locationField.isComputed && typeof locationField.startPiece !== 'undefined') {
            const processState: DefaultFlowchartProcessState = {
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                customFields: { ...member.customFields },
                variables: {
                    [locationField.seedEntityVariable]: member.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
            };

            memberLocation = getMemberComputedFieldValue(this.applicationState, processState, locationField.startPiece.piece, member.id, locationField);
        } else {
            memberLocation = member.customFields[memberType.locationFieldId];
        }

        if (Array.isArray(memberLocation)) {

            if (memberLocation.length > 0 && Array.isArray(memberLocation[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            memberLocation = memberLocation as Array<string>;
        }

        memberLocation = getReadableDataForCustomField(memberLocation, locationField, member.id, 'member');

        return memberLocation;
    }

    getMemberSubtitle(memberId: string) {
        try {
            const member = this.membersData.byId[memberId];
            const memberType = this.membersData.types.byId[member.type];
            let memberSubtitle: VariableValueType;
            const subTitleField = this.membersData.types.customFields.byId[memberType.subTitleFieldId];

            if (subTitleField.isComputed && typeof subTitleField.startPiece !== 'undefined') {
                const processState: DefaultFlowchartProcessState = {
                    displayingContinuePieceId: undefined,
                    displayingAddWorkflowPieceId: undefined,
                    customFields: { ...member.customFields },
                    variables: {
                        [subTitleField.seedEntityVariable]: member.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                };

                memberSubtitle = getMemberComputedFieldValue(this.applicationState, processState, subTitleField.startPiece.piece, member.id, subTitleField);
            } else {
                memberSubtitle = member.customFields[memberType.subTitleFieldId];
            }

            if (Array.isArray(memberSubtitle)) {

                if (memberSubtitle.length > 0 && Array.isArray(memberSubtitle[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                memberSubtitle = memberSubtitle as Array<string>;
            }

            memberSubtitle = getReadableDataForCustomField(memberSubtitle, subTitleField, member.id, 'member');

            return memberSubtitle;
        } catch {
            return '-';
        }
    }


    showAffiliatedWorkflows(group: IGroup) {
        this.selected_affiliated_group = group;

        let workflow_types = this.workflowsData.types.allEntries.map((typeId) => {
            return this.workflowsData.types.byId[typeId];
        });

        this.affiliated_workflow_types = workflow_types.filter((workflow) => {
            return workflow.affiliatedEntity === group.type;
        });
    }


    async resumeWorkflow(workflow: IWorkflow) {
        const alert = await this.alertController.create({
            message: 'Seems like there is already a workflow existing... Would you like to resume it?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => { }
                }, {
                    text: 'Okay',
                    handler: () => {
                        this.member_filter_popup = false;
                        this.executeWorkflow(workflow);
                    }
                }
            ]
        });

        await alert.present();
    }

    async executeWorkflow(workflow: IWorkflow, isEnd?: boolean) {
        const modal = await this.modalController.create({
            component: WorkflowExecutorPage,
            componentProps: {
                workflow_id: workflow.id,
                isEnd: isEnd ? true : false
            }
        });

        return await modal.present();
    }

    async selectedGroupDetails(group: IGroup) {
        const modal = await this.modalController.create({
            component: GroupDetailsPage,
            componentProps: {
                group_id: group.id
            }
        });

        return await modal.present();
    }

    editSelectedMember(member: IMember) {
        this.back();
        this.navCtrl.navigateRoot(['/add-or-edit-member', { member: JSON.stringify(member) }]);
    }

    getWorkflowCustomFieldOptionDetails(id: string) {
        return this.workflowsData.types.customFieldOptions.byId[id].name;
    }

    getGroupCustomFieldOptionDetails(id: string) {
        return this.groupsData.types.customFieldOptions.byId[id].name;
    }

    getMemberCustomFieldOptionDetails(id: string) {
        return this.membersData.types.customFieldOptions.byId[id].name;
    }

    getUserCustomFieldOptionDetails(id: string) {
        return this.usersData.customFieldOptions.byId[id].name;
    }

    async selectedMemberDetails(member: IMember) {
        const modal = await this.modalController.create({
            component: MemberProfileDetailsPage,
            componentProps: {
                selected_member_data: member
            }
        });

        return await modal.present();
    }

    getAffiliatedEntityName(affiliatedEntity: string) {
        let member = this.member_list.find((member) => {
            return affiliatedEntity === member.id;
        });

        let group = this.all_groups.find((group) => {
            return affiliatedEntity === group.id;
        });

        if (member) {
            return this.getMemberName(member.id);
        } else if (group) {
            return this.getGroupName(group.id);
        } else {
            return '-';
        }
    }

    initiateMemberWorkflow(type_id: string) {
        let typeWorkflowIds: Array<string>, typeWorkflows: Array<IWorkflow>,
            isWorkflowPresent: boolean = false;
        typeWorkflowIds = this.applicationState.users.byId[this.my_id].workflows[type_id];
        if (typeWorkflowIds) {

            typeWorkflows = typeWorkflowIds.map(id => {
                return this.workflowsData.byId[id];
            });

            typeWorkflows.forEach(workflow => {
                if (workflow.affiliatedEntity === this.selected_affiliated_member.id && !this.isWorkflowTerminal(workflow.status) && !isWorkflowPresent && workflow.user === this.my_id && !workflow.archived) {
                    isWorkflowPresent = true;
                    this.member_filter_popup = false;
                    this.resumeWorkflow(workflow);
                }
            });
        }

        if (!typeWorkflowIds || !isWorkflowPresent) {
            this.member_filter_popup = false;
            this.allowed_statuses = this.workflowsData.types.byId[type_id].statuses.map(statusId => this.workflowsData.types.statuses.byId[statusId]);

            this.new_workflow = {
                id: uuid(),
                type: type_id,
                status: this.allowed_statuses.filter((status) => {
                    return !status.isTerminal;
                })[0].id,
                dueDate: '',
                user: this.my_id,
                affiliatedEntity: this.selected_affiliated_member.id,
            }

            let dueDays = this.allowed_statuses.filter((status) => {
                return !status.isTerminal;
            })[0].dueInDays;

            this.new_workflow.dueDate = moment(new Date().setDate(new Date().getDate() + dueDays)).format('YYYY-MM-DD');
            this.ngRedux.dispatch(addWorkflow(this.new_workflow));
            this.executeNewWorkflow();
        }
    }

    initiateGroupWorkflow(type_id: string) {
        let typeWorkflowIds: Array<string>, typeWorkflows: Array<IWorkflow>,
            isWorkflowPresent: boolean = false;
        typeWorkflowIds = this.applicationState.users.byId[this.my_id].workflows[type_id];

        if (typeWorkflowIds) {
            typeWorkflows = typeWorkflowIds.map(id => {
                return this.workflowsData.byId[id];
            });

            typeWorkflows.forEach(workflow => {
                if (workflow.affiliatedEntity === this.selected_affiliated_group.id && !this.isWorkflowTerminal(workflow.status) && !isWorkflowPresent && workflow.user === this.my_id && !workflow.archived) {
                    isWorkflowPresent = true;
                    this.member_filter_popup = false;
                    this.resumeWorkflow(workflow);
                }
            });
        }

        if (!typeWorkflowIds || !isWorkflowPresent) {
            this.group_filter_popup = false;
            this.allowed_statuses = this.workflowsData.types.byId[type_id].statuses.map(statusId => this.workflowsData.types.statuses.byId[statusId]);

            this.new_workflow = {
                id: uuid(),
                type: type_id,
                status: this.allowed_statuses.filter((status) => {
                    return !status.isTerminal;
                })[0].id,
                dueDate: '',
                user: this.my_id,
                affiliatedEntity: this.selected_affiliated_group.id,
            }

            let dueDays = this.allowed_statuses.filter((status) => {
                return !status.isTerminal;
            })[0].dueInDays;

            this.new_workflow.dueDate = moment(new Date().setDate(new Date().getDate() + dueDays)).format('YYYY-MM-DD');
            this.ngRedux.dispatch(addWorkflow(this.new_workflow));
            this.executeNewWorkflow();
        }
    }

    getCompletionRate(workflowId: string) {
        try {
            return Number(completionPercentageOfWorkflow(workflowId).toFixed(0));
        } catch {
            return 0;
        }
    }

    showCalendar(workflow: IUpdateableWorkflowData, preSelectedDate?: string) {
        this.selectedWorkflowForDueDateChange = {
            id: workflow.id,
            preSelectedDate: preSelectedDate
        };
    }

    getDateInput(tempDateInput: any) {
        if (!moment(tempDateInput, 'DD-MM-YYYY', true).isValid()) {
            this.toastService.presentToastWithOptions("Invalid Date, Format: DD-MM-YYYY", ToastType.ERROR);
        } else {
            if (this.selectedWorkflowForDueDateChange.preSelectedDate !== moment(tempDateInput, 'DD-MM-YYYY').format('YYYY-MM-DD')) {
                this.ngRedux.dispatch(updateDueDate(this.selectedWorkflowForDueDateChange.id, moment(tempDateInput, 'DD-MM-YYYY').format('YYYY-MM-DD')));
                this.toastService.presentToastWithOptions('The due date has been updated!', ToastType.INFO);

                this.selectedWorkflowForDueDateChange = {
                    id: null,
                    preSelectedDate: null
                };
            } else {
                this.selectedWorkflowForDueDateChange = {
                    id: null,
                    preSelectedDate: null
                };
            }

        }
    }

    async executeNewWorkflow() {
        const modal = await this.modalController.create({
            component: WorkflowExecutorPage,
            componentProps: {
                workflow_id: this.new_workflow.id
            }
        });

        return await modal.present();
    }


    getGroupName(groupId: string) {
        const group = this.groupsData.byId[groupId];
        const groupType = this.groupsData.types.byId[group.type];
        let groupName: CustomFieldValueType;
        const nameField = this.groupsData.types.customFields.byId[groupType.nameFieldId];

        groupName = group.customFields[groupType.nameFieldId];

        groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');

        return groupName;
    }

    editSelectedGroup(group: IGroup) {
        this.back();
        this.navCtrl.navigateRoot(['/add-or-edit-group', { group: JSON.stringify(group) }]);
    }

    getUserName(userId: string) {
        const user = this.usersData.byId[userId];
        let userName: VariableValueType;

        const nameField = this.usersData.customFields.byId[this.usersData.nameFieldId];

        if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
            const processState: DefaultFlowchartProcessState = {
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                customFields: { ...user.customFields },
                variables: {
                    [nameField.seedEntityVariable]: user.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
            };

            userName = getUserComputedFieldValue(this.applicationState, processState, nameField.startPiece.piece, user.id, nameField);
        } else {
            userName = user.customFields[this.usersData.nameFieldId];
        }

        if (Array.isArray(userName)) {

            if (userName.length > 0 && Array.isArray(userName[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            userName = userName as Array<string>;
        }

        userName = getReadableDataForCustomField(userName, this.usersData.customFields.byId[this.usersData.nameFieldId], user.id, 'user');

        return userName;
    }

    getMemberName(memberId: string) {
        const member = this.membersData.byId[memberId];
        const memberType = this.membersData.types.byId[member.type];
        let memberName: VariableValueType;

        const nameField = this.membersData.types.customFields.byId[memberType.nameFieldId];

        if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
            const processState: DefaultFlowchartProcessState = {
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                customFields: { ...member.customFields },
                variables: {
                    [nameField.seedEntityVariable]: member.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
            };

            memberName = getMemberComputedFieldValue(this.applicationState, processState, nameField.startPiece.piece, member.id, nameField);
        } else {
            memberName = member.customFields[memberType.nameFieldId];
        }

        if (Array.isArray(memberName)) {

            if (memberName.length > 0 && Array.isArray(memberName[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            memberName = memberName as Array<string>;
        }

        memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

        return memberName;
    }

    isWorkflowTerminal(status: string) {
        return this.workflowsData.types.statuses.byId[status].isTerminal;
    }

    back() {
        if (this.applicationStateSubscription) {
            this.applicationStateSubscription.unsubscribe();
        }

        this.modalController.dismiss();
    }

}

import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import { WorkflowProcessState, WorkflowState, IWorkflow } from 'src/shared/store/workflows/types';
import { isUUID } from 'src/shared/helpers/utilities';
import { getWorkflowPieceValue } from 'src/shared/store/flowchart/helpers/workflow';
import { PieceType, IChoosePiece, IGroupedChoosePiece } from 'src/shared/store/flowchart/pieces/types';
import { DefaultFlowchartProcessState } from 'src/shared/store/flowchart/types';
import { ApplicationState } from '../../../shared/store/types';

import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { getReadableDataForCustomField } from 'src/shared/store/custom-fields';
import { CustomFieldValueType, CustomField, CustomFieldOptionsDataType, WorkflowTypeCustomField } from 'src/shared/store/custom-fields/types';
import { getWorkflowQuestionValidationValue } from 'src/shared/store/flowchart/helpers/question';
import { IVariable, VariableType } from 'src/shared/store/flowchart/variables/types';
import { IUser } from 'src/shared/store/users/types';
import { IMember } from 'src/shared/store/members/types';
import { IGroup } from 'src/shared/store/groups/types';
import { getPieceValueType } from 'src/shared/store/flowchart/helpers';
import { translatePhrase } from 'src/shared/helpers/translation';
import { getMemberComputedFieldValue } from 'src/shared/store/flowchart/helpers/custom-fields/member';
import moment from 'moment';
import { first, debounceTime } from 'rxjs/operators';
import { VariableValueType } from 'src/shared/helpers/common-types';

@Component({
  selector: 'app-choose',
  templateUrl: './choose.component.html',
  styleUrls: ['./choose.component.scss'],
})
export class ChooseComponent implements OnInit, OnChanges, OnDestroy {
    @select(state => state) applicationStateSource: Observable<ApplicationState>;
    applicationState: ApplicationState;
    applicationStateFirstSubscription: Subscription;
    applicationStateDebouncedSubscription: Subscription;

    workflowData: WorkflowState;
    searchText: string;

    isDisabled: boolean;
    isRequired: boolean;

    @Input() workflowId: string;
    @Input() questionId: string;
    @Input() userInput: string|Array<string>;
    @Input() errorMessage: string;
    @Input() overWrittenVariable: string;
    @Input() overWrittenValue: string;
    @Input() isExpanded: boolean;

    @Input() questionInputs: {
		[customFieldId: string]: CustomFieldValueType,
	};

    @Input() choiceInputs: {
        [questionId: string]: string|Array<string>,
    };

    @Input() validateAnswer: (questionId: string, answer: string|Array<string>, processState: WorkflowProcessState) => string;

    @Output() inputChange = new EventEmitter<string|Array<string>>();

    @Output() expandToggle = new EventEmitter();

    @Output() emitValidChoiceForId = new EventEmitter();

    @Input() validatedChoicesForId?: any = {};

    questionPiece: IChoosePiece | IGroupedChoosePiece;
    questionText = '';
    choicesVariable: IVariable;
    isMultiSelect = false;

    choices: Array<{
        name: string,
        value: string
    }>;

    tempChoices: Array<{
        name: string,
        value: string
    }>;
    
    constructor() {
    }

    searchChoices() {
        if (this.searchText.length > 0) {
            this.tempChoices = this.choices.filter(choice => choice.name.toLowerCase().includes(this.searchText.toLowerCase()));
        } else {
            this.tempChoices = JSON.parse(JSON.stringify(this.choices));
        }
    }

	translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

    toggleShowMore() {
        this.expandToggle.emit(this.questionId);
    }

    getWorkflowProcessState = (workflow: IWorkflow) => {
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
            displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        if (this.overWrittenVariable) {
            processState.variables[this.overWrittenVariable] = this.overWrittenValue;
        }

        for (const questionId in this.choiceInputs) {
            if (this.choiceInputs.hasOwnProperty(questionId)) {
                const choosePiece = this.applicationState.flowchart.pieces.byId[questionId];

                if (choosePiece.type !== PieceType.CHOOSE && choosePiece.type !== PieceType.GROUPED_CHOOSE) {
                    throw new Error('The piece must be a choose piece');
                }

                if (typeof choosePiece.choiceVariable === 'undefined') {
                    throw new Error('The piece must point to a choice variable');
                }

                processState.variables[choosePiece.choiceVariable] = this.choiceInputs[questionId];
            }
        }

        return processState;
    }

    getQuestion() {

        if (!this.workflowData || !this.questionPiece) {
            return '';
        }

        const workflow = this.workflowData.byId[this.workflowId];
        const processState = this.getWorkflowProcessState(workflow);

        const expandedQuestion = this.questionPiece.question && isUUID(this.questionPiece.question) ?
        getWorkflowPieceValue(this.applicationState, processState, workflow.id, this.questionPiece.question) : this.questionPiece.question;

        if (typeof expandedQuestion !== 'string') {
            throw new Error('A question value needs to be a string');
        }

        return expandedQuestion;
    }

    checkIfQuestionDisabled = () => {

        if (!this.workflowData || !this.questionPiece) {
            return false;
        }

        const workflow = this.workflowData.byId[this.workflowId];
        const answer = this.userInput;

        // This is either the previous value, or the value from the parent
        let isDisabled = this.isDisabled;

        const allAnswers = {
            ...this.questionInputs,
        };

        const disabledWorkflowProcessState = this.getWorkflowProcessState(workflow);
        isDisabled = this.questionPiece.isDisabledPiece ?
        !!getWorkflowQuestionValidationValue(this.applicationState, disabledWorkflowProcessState, workflow.id,
            this.questionId, answer, allAnswers, this.questionPiece.isDisabledPiece) : false;

        return isDisabled;
    }

    checkIfQuestionRequired = () => {

        if (!this.workflowData || !this.questionPiece) {
            return false;
        }

        const workflow = this.workflowData.byId[this.workflowId];
        const answer = this.userInput;

        const allAnswers = {
            ...this.questionInputs,
        };

        const requiredWorkflowProcessState = this.getWorkflowProcessState(workflow);
        const isRequired = this.questionPiece.isRequiredPiece ?
        !!getWorkflowQuestionValidationValue(this.applicationState, requiredWorkflowProcessState, workflow.id,
            this.questionId, answer, allAnswers, this.questionPiece.isRequiredPiece) : false;

        return isRequired;
    }

	getWorkflowCustomFieldValue(workflow: IWorkflow, customField: WorkflowTypeCustomField) {
        let customFieldValue = workflow.history[workflow.historyIndex].customFields[customField.id] as CustomFieldValueType;

        return getReadableDataForCustomField(customFieldValue, customField, workflow.id, 'workflow');
    }

	getCommonCustomFieldValue(entity: IUser|IMember|IGroup, type: 'user'|'member'|'group', customField: CustomField, optionsData: CustomFieldOptionsDataType) {
        let customFieldValue = entity.customFields[customField.id];

        return getReadableDataForCustomField(customFieldValue, customField, entity.id, type);
    }

    getDefaultValue = () => {
        if (!this.workflowData || !this.questionPiece) {
            return false;
        }

        let lastStoredInput: string|Array<string> = '';
        const workflow = this.workflowData.byId[this.workflowId];

        if (this.workflowData.screenInputs[this.workflowId] && workflow.historyIndex < workflow.history.length - 1) {
            const workflowScreenInputs = this.workflowData.screenInputs[workflow.id].find(screenInputs => workflow.historyIndex === screenInputs.workflowIndex);

            if (!!workflowScreenInputs) {
                lastStoredInput = workflowScreenInputs.choices[this.questionPiece.id];
            }
        }

        if (typeof this.userInput === 'undefined') {
            if (lastStoredInput) {
                this.inputChange.emit(lastStoredInput);
            } else {
                const defaultWorkflowProcessState = this.getWorkflowProcessState(workflow);

                let defaultValue = this.questionPiece.default && isUUID(this.questionPiece.default) ?
                    getWorkflowPieceValue(this.applicationState, defaultWorkflowProcessState, workflow.id,
                        this.questionPiece.default) : this.questionPiece.default;

                if (Array.isArray(defaultValue)) {

                    if (defaultValue.length > 0 && Array.isArray(defaultValue[0])) {
                        // Cannot be a multidimensional array
                        throw new Error('The value cannot be a multi-dimensional array');
                    }

                    defaultValue = defaultValue as Array<string>;
                } else if (typeof defaultValue !== 'string' && typeof defaultValue !== 'undefined') {
                    throw new Error('The value cannot be anything but a string');
                }

                if (typeof defaultValue !== 'undefined') {
                    this.inputChange.emit(defaultValue);
                }
            }
        }
    }


    getGroupSubtitle(groupId: string) {
        const group = this.applicationState.groups.byId[groupId];
        const groupType = this.applicationState.groups.types.byId[group.type];
        let groupSubtitle: CustomFieldValueType;
		const subTitleField = this.applicationState.groups.types.customFields.byId[groupType.subTitleFieldId];

		groupSubtitle = group.customFields[groupType.subTitleFieldId];

		groupSubtitle = getReadableDataForCustomField(groupSubtitle, subTitleField, group.id, 'group');

        return groupSubtitle;
    }

    isChoiceSelected = (choiceId: string) => {
        if (Array.isArray(this.userInput)) {
            return this.userInput.includes(choiceId);
        }

        return choiceId === this.userInput;
    }

    getValidChoices() {
        if (this.validatedChoicesForId[this.overWrittenValue ?  this.overWrittenValue + '-' + this.questionId : this.questionId]) {
            return this.validatedChoicesForId[this.overWrittenValue ?  this.overWrittenValue + '-' + this.questionId : this.questionId];
        }

        if (!this.workflowData || !this.questionPiece) {
            return [];
        }

        const workflow = this.workflowData.byId[this.workflowId];
        const processState = this.getWorkflowProcessState(workflow);

        const choicePiece = this.applicationState.flowchart.pieces.byId[this.questionId];

        if (choicePiece.type !== PieceType.CHOOSE && choicePiece.type !== PieceType.GROUPED_CHOOSE) {
            throw new Error('The ID should point to a piece of the question type');
        }

        if (!choicePiece.choiceVariable) {
            throw new Error('The question must point to a valid choice variable');
        }

        if (!choicePiece.variablePiece) {
            throw new Error('The question must point to a valid choice list variable');
        }

        const choicesVariablePieceId = choicePiece.variablePiece;

        this.checkIsMultiSelect();

        let choicesValue = getWorkflowPieceValue(this.applicationState, JSON.parse(JSON.stringify(processState)),
        this.workflowId, choicesVariablePieceId);

        if (typeof choicesValue === 'undefined') {
            choicesValue = [];
        }

        if (!Array.isArray(choicesValue)) {
            throw new Error('The choices list must point to an array of values');
        }

        let allowedChoices = choicesValue;

        if (Array.isArray(allowedChoices)) {

            if (allowedChoices.length > 0 && Array.isArray(allowedChoices[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array');
            }

            allowedChoices = allowedChoices as Array<string>;
        }
        const chooseListType = getPieceValueType(choicesVariablePieceId, this.applicationState.flowchart.pieces,
            this.applicationState.flowchart.variables);

        switch(chooseListType) {
            case VariableType.PROJECTS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.applicationState.structure.projects.byId && !this.applicationState.structure.projects.byId[choiceId].archived);
                break;

            case VariableType.LEVELS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.applicationState.structure.levels.byId && !this.applicationState.structure.levels.byId[choiceId].archived);
                break;

            case VariableType.ROLES_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.applicationState.structure.roles.byId && !this.applicationState.structure.roles.byId[choiceId].archived);
                break;

            case VariableType.LOCATIONS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.applicationState.structure.locations.byId && !this.applicationState.structure.locations.byId[choiceId].archived);
                break;

            case VariableType.USERS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.applicationState.users.byId && !this.applicationState.users.byId[choiceId].archived);
                break;

            case VariableType.MEMBERS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.applicationState.members.byId && !this.applicationState.members.byId[choiceId].archived);
                break;

            case VariableType.GROUPS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.applicationState.groups.byId && !this.applicationState.groups.byId[choiceId].archived);
                break;

            case VariableType.WORKFLOWS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.applicationState.workflows.byId && !this.applicationState.workflows.byId[choiceId].archived);
                break;

            case VariableType.DATA_FRAGMENTS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.applicationState.staticInfo.fragments.byId && !this.applicationState.staticInfo.fragments.byId[choiceId].archived);
                break;

            default:
                break;
        }

        allowedChoices = allowedChoices.filter(choice => {
            const errorMessage = this.validateAnswer(this.questionId, choice, processState);

            return !errorMessage;
        });
        
        let finalChoices = allowedChoices.map(choiceId => {
            switch (chooseListType) {
                case VariableType.PROJECTS_LIST:
                    return {
                        name: this.applicationState.structure.projects.byId[choiceId].name,
                        value: choiceId,
                    };

                case VariableType.LEVELS_LIST:
                    return {
                        name: this.applicationState.structure.levels.byId[choiceId].name,
                        value: choiceId,
                    };

                case VariableType.ROLES_LIST:
                    return {
                        name: this.applicationState.structure.roles.byId[choiceId].name,
                        value: choiceId,
                    };

                case VariableType.LOCATIONS_LIST:
                    return {
                        name: this.applicationState.structure.locations.byId[choiceId].name,
						parent: this.applicationState.structure.locations.byId[this.applicationState.structure.locations.byId[choiceId].parent].name,
                        value: choiceId,
                    };

                case VariableType.USERS_LIST:
                    const user = this.applicationState.users.byId[choiceId];
                    const nameFieldForUser = this.applicationState.users.customFields.byId[this.applicationState.users.nameFieldId];
                    let nameOfUser = this.getCommonCustomFieldValue(user, 'user', nameFieldForUser,
                    this.applicationState.users.customFieldOptions);

                    nameOfUser = getReadableDataForCustomField(nameOfUser, this.applicationState.users.customFields.byId[this.applicationState.users.nameFieldId], user.id, 'user');

                    return {
                        name: nameOfUser,
                        value: choiceId,
                    };

                case VariableType.MEMBERS_LIST:
                    const member = this.applicationState.members.byId[choiceId];
                    const memberType = this.applicationState.members.types.byId[member.type];
                    const nameFieldForMember = this.applicationState.members.types.customFields.byId[memberType.nameFieldId];
                    let nameOfMember = this.getCommonCustomFieldValue(member, 'member', nameFieldForMember,
                    this.applicationState.members.types.customFieldOptions);

                    nameOfMember = getReadableDataForCustomField(nameOfMember, nameFieldForMember, member.id, 'member');

                    return {
                        name: nameOfMember,
						subTitle: this.getMemberSubtitle(member.id),
                        value: choiceId,
                    };

                case VariableType.GROUPS_LIST:
                    const group = this.applicationState.groups.byId[choiceId];
                    const groupType = this.applicationState.groups.types.byId[group.type];
                    const nameFieldForGroup = this.applicationState.groups.types.customFields.byId[groupType.nameFieldId];
                    let nameOfGroup = this.getCommonCustomFieldValue(group, 'group', nameFieldForGroup,
                    this.applicationState.groups.types.customFieldOptions);

                    nameOfGroup = getReadableDataForCustomField(nameOfGroup, nameFieldForGroup, group.id, 'group');

                    
                    return {
                        name: nameOfGroup,
                        subTitle: this.getGroupSubtitle(group.id),
                        value: choiceId,
                    };

                case VariableType.WORKFLOWS_LIST:
                    const workflow = this.applicationState.workflows.byId[choiceId];
                    const workflowType = this.applicationState.workflows.types.byId[workflow.type];
                    let subTitleOfWorkflow = '';

                    if (workflowType.affiliation === 'member') {
                        const member = this.applicationState.members.byId[workflow.affiliatedEntity];
                        const memberType = this.applicationState.members.types.byId[member.type];
                        const nameField = this.applicationState.members.types.customFields.byId[memberType.nameFieldId];
                        const nameValue = this.getCommonCustomFieldValue(member, 'member', nameField, this.applicationState.members.types.customFieldOptions);
                        const subTitleField = this.applicationState.members.types.customFields.byId[memberType.subTitleFieldId];
                        const subTitleValue = this.getCommonCustomFieldValue(member, 'member', subTitleField, this.applicationState.members.types.customFieldOptions);

                        subTitleOfWorkflow = `${nameValue} (${subTitleValue}) at ${moment(workflow.createdTime).format('DD MMM YYYY')})`;
                    } else if (workflowType.affiliation === 'group') {
                        const group = this.applicationState.groups.byId[workflow.affiliatedEntity];
                        const groupType = this.applicationState.groups.types.byId[group.type];
                        const nameField = this.applicationState.groups.types.customFields.byId[groupType.nameFieldId];
                        const nameValue = this.getCommonCustomFieldValue(group, 'group', nameField, this.applicationState.groups.types.customFieldOptions);
                        const subTitleField = this.applicationState.groups.types.customFields.byId[groupType.subTitleFieldId];
                        const subTitleValue = this.getCommonCustomFieldValue(group, 'group', subTitleField, this.applicationState.groups.types.customFieldOptions);

                        subTitleOfWorkflow = `${nameValue} \n
                                              (${subTitleValue}) \n
                                              at ${moment(workflow.createdTime).format('DD MMM YYYY')}`;
                    }

                    return {
                        name: workflowType.name,
                        subTitle: subTitleOfWorkflow,
                        value: choiceId,
                    };

                case VariableType.DATA_FRAGMENTS_LIST:
                    return {
                        name: this.applicationState.staticInfo.fragments.byId[choiceId].name,
                        value: choiceId,
                    };

                case VariableType.TEXT_LIST:
                    return {
                        name: choiceId,
                        value: choiceId,
                    };

                default:
                    throw new Error('Unknown kind of list');
            }
        });

        if (!this.validatedChoicesForId[this.overWrittenValue ?  this.overWrittenValue + '-' + this.questionId : this.questionId]) {
            this.validatedChoicesForId[this.overWrittenValue ?  this.overWrittenValue + '-' + this.questionId : this.questionId] = finalChoices;
            this.emitValidChoiceForId.emit(this.validatedChoicesForId);
        }
        
        return finalChoices;
    }

    checkIsMultiSelect = () => {
        let isMultiSelect = false;

        const choicePiece = this.applicationState.flowchart.pieces.byId[this.questionId];

        if (choicePiece.type !== PieceType.CHOOSE && choicePiece.type !== PieceType.GROUPED_CHOOSE) {
            throw new Error('The ID should point to a piece of the question type');
        }

        if (!choicePiece.choiceVariable) {
            throw new Error('The question must point to a valid choice variable');
        }

        if (!choicePiece.variablePiece) {
            throw new Error('The question must point to a valid choice list variable');
        }

        const variable = this.applicationState.flowchart.variables.byId[choicePiece.choiceVariable];
    
        switch (variable.type) {
            case VariableType.PROJECTS_LIST:
            case VariableType.LEVELS_LIST:
            case VariableType.ROLES_LIST:
            case VariableType.LOCATIONS_LIST:
            case VariableType.USERS_LIST:
            case VariableType.MEMBERS_LIST:
            case VariableType.GROUPS_LIST:
            case VariableType.WORKFLOWS_LIST:
            case VariableType.DATA_FRAGMENTS_LIST:
            case VariableType.TEXT_LIST:
                isMultiSelect = true;
                break;
            default:
                isMultiSelect = false;
        }

        this.isMultiSelect = isMultiSelect;
    }

    selectChoice = (value: string) => {
        const selectedValue = value;
        let newValue: string|Array<string>;

        this.checkIsMultiSelect();

        if (this.isMultiSelect) {
            const existingValue = Array.isArray(this.userInput) ? this.userInput : [];

            if (existingValue.includes(selectedValue)) {
                newValue = existingValue.filter(selectedChoice => selectedChoice !== selectedValue);
            } else {
                newValue = [...existingValue, selectedValue];
            }
        } else {
            newValue = selectedValue;
        }

        this.inputChange.emit(newValue);
    }

    renderChoiceData() {
        if (!this.applicationState) {
            return;
        }

        const piece = this.applicationState.flowchart.pieces.byId[this.questionId];

        if (piece.type !== PieceType.CHOOSE && piece.type !== PieceType.GROUPED_CHOOSE) {
            throw new Error('The question ID must have a question type');
        }

        this.questionPiece = piece;
        this.choicesVariable = this.applicationState.flowchart.variables.byId[this.questionPiece.variablePiece];

        this.questionText = this.getQuestion();
        this.isDisabled = this.checkIfQuestionDisabled();
        this.isRequired = this.checkIfQuestionRequired();
        this.choices = this.getValidChoices();
        this.tempChoices = JSON.parse(JSON.stringify(this.choices));

        this.getDefaultValue();
    }

    ngOnChanges(simpleChanges: SimpleChanges) {

        if (simpleChanges.choiceInputs) {
            let currentValue: {
                [questionId: string]: string|Array<string>,
            } = {};

            let previousValue: {
                [questionId: string]: string|Array<string>,
            } = {};

            // Deep copy the current and previous values
            if (simpleChanges.choiceInputs.currentValue) {
                currentValue = JSON.parse(JSON.stringify(simpleChanges.choiceInputs.currentValue))
            }

            if (simpleChanges.choiceInputs.previousValue) {
                previousValue = JSON.parse(JSON.stringify(simpleChanges.choiceInputs.previousValue))
            }

            this.userInput = currentValue[this.questionId];

            // Delete the answer for the current question
            if (currentValue[this.questionId]) {
                delete currentValue[this.questionId];
            }

            if (previousValue[this.questionId]) {
                delete previousValue[this.questionId];
            }

            // Check if the values for any of the other questions have changed
            if (JSON.stringify(currentValue) !== JSON.stringify(previousValue)) {
                // If yes, re-render the choices again
                this.validatedChoicesForId = {};
                this.renderChoiceData();
            }

        }

        if (simpleChanges.userInput &&
            simpleChanges.userInput.currentValue !== simpleChanges.userInput.previousValue) {
            this.userInput = simpleChanges.userInput.currentValue;
        }

        if (simpleChanges.isDisabled &&
            simpleChanges.isDisabled.currentValue !== simpleChanges.isDisabled.previousValue) {
                this.isDisabled = simpleChanges.isDisabled.currentValue;
        }

        if (simpleChanges.isExpanded &&
            simpleChanges.isExpanded.currentValue !== simpleChanges.isExpanded.previousValue) {
            this.isExpanded = simpleChanges.isExpanded.currentValue;
        }
    }

    updateChoice(applicationState: ApplicationState) {
        this.applicationState = applicationState;
        this.workflowData = applicationState.workflows;

        this.renderChoiceData();
    }

    ngOnInit() {

        this.applicationStateFirstSubscription = this.applicationStateSource.pipe(first()).subscribe(applicationState => this.updateChoice(applicationState));
        this.applicationStateDebouncedSubscription = this.applicationStateSource.pipe(debounceTime(500)).subscribe(applicationState => this.updateChoice(applicationState));
    }

	ngOnDestroy() {
        if (this.applicationStateFirstSubscription) {
            this.applicationStateFirstSubscription.unsubscribe();
        }

        if (this.applicationStateDebouncedSubscription) {
            this.applicationStateDebouncedSubscription.unsubscribe();
        }
	}

	getMemberSubtitle(memberId: string) {
        const member = this.applicationState.members.byId[memberId];
        const memberType = this.applicationState.members.types.byId[member.type];
        let memberSubtitle: VariableValueType;
        const subTitleField = this.applicationState.members.types.customFields.byId[memberType.subTitleFieldId];

        if (subTitleField.isComputed && typeof subTitleField.startPiece !== 'undefined') {
            const processState: DefaultFlowchartProcessState = {
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                customFields: { ...member.customFields },
                variables: {
                    [subTitleField.seedEntityVariable]: member.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
            };

            memberSubtitle = getMemberComputedFieldValue(this.applicationState, processState, subTitleField.startPiece.piece, member.id, subTitleField);
        } else {
            memberSubtitle = member.customFields[memberType.subTitleFieldId];
        }

        if (Array.isArray(memberSubtitle)) {

            if (memberSubtitle.length > 0 && Array.isArray(memberSubtitle[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            memberSubtitle = memberSubtitle as Array<string>;
        }

        memberSubtitle = getReadableDataForCustomField(memberSubtitle, subTitleField, member.id, 'member');

        return memberSubtitle;
    }

}

import { WidgetState, ADD_WIDGET, UPDATE_WIDGET, DELETE_WIDGET, UPDATE_WIDGET_DATA, WidgetActionTypes, REGISTER_WIDGET_VARIABLE, UPDATE_WIDGET_START_PIECE, SET_ISOLATED_WIDGET_PIECE, REMOVE_ISOLATED_WIDGET_PIECE, IWidget, UPDATE_WIDGETS_DATA, SYNCHRONIZE_WIDGETS_DATA, CLEAR_WIDGETS_DELTA, REGISTER_WIDGET_RICH_TEXT_VARIABLE, REMOVE_ISOLATED_WIDGET_RICH_TEXT_PIECE, SET_ISOLATED_WIDGET_RICH_TEXT_PIECE, UPDATE_WIDGET_RICH_TEXT_START_PIECE, UPDATE_WIDGET_CACHE, UPDATE_WIDGET_MESSAGE_CACHE, CLEAR_WIDGET_CACHE, MARK_PARTIAL_WIDGET_CACHE, UNMARK_PARTIAL_WIDGET_CACHE } from './types';
import { updateEntries, synchronizeEntries, clearDelta } from '../normalized-model';

export const initialState: WidgetState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    byRole: {},
    cachedWidgetData: {},
    cachedWidgetMessages: {},
    partiallyCachedWidgetIds: [],
};

export function widgetsReducer(state = initialState, action: WidgetActionTypes): WidgetState {

    let newRolesLinks: {
        [roleId: string]: Array<string>,
    } = {};

    let newState: WidgetState;

    switch (action.type) {
        case ADD_WIDGET:
            newRolesLinks = {
                ...state.byRole
            };

            for (let role of action.payload.roles) {
                newRolesLinks = {
                    ...newRolesLinks,
                    [role]: newRolesLinks[role] ? newRolesLinks[role].concat(action.payload.id) : [action.payload.id],
                };
            }

            let seedVariables: Array<string> = [];

            if (action.payload.seedUsersVariable) {
                seedVariables.push(action.payload.seedUsersVariable);
            }

            if (action.payload.seedMembersVariable) {
                seedVariables.push(action.payload.seedMembersVariable);
            }

            if (action.payload.seedGroupsVariable) {
                seedVariables.push(action.payload.seedGroupsVariable);
            }

            if (action.payload.seedWorkflowsVariable) {
                seedVariables.push(action.payload.seedWorkflowsVariable);
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...action.payload,
                        
                        variables: [action.payload.seedEntityVariable],
                        richTextVariables: seedVariables,

                        isolatedPieces: [],
                        richTextIsolatedPieces: [],
                    },
                },
                byRole: newRolesLinks,
                allEntries: state.allEntries.concat([action.payload.id]),
                createdIds: new Set([...state.createdIds, action.payload.id]),
            };
        
        case UPDATE_WIDGET:
            newRolesLinks = {
                ...state.byRole
            };

            if (state.byId[action.payload.id].roles) {
                for (let role of state.byId[action.payload.id].roles) {
                    newRolesLinks = {
                        ...newRolesLinks,
                        [role]: newRolesLinks[role].filter(widgetId => widgetId !== action.payload.id),
                    };
                }
            }

            for (let role of action.payload.roles) {
                newRolesLinks = {
                    ...newRolesLinks,
                    [role]: newRolesLinks[role] ? newRolesLinks[role].concat(action.payload.id) : [action.payload.id],
                };
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    },
                },
                byRole: newRolesLinks,
                updatedIds: new Set([...state.updatedIds, action.payload.id]),
            };

        case DELETE_WIDGET:
            newRolesLinks = {
                ...state.byRole
            };

            for (let role of state.byId[action.id].roles) {
                newRolesLinks = {
                    ...newRolesLinks,
                    [role]: newRolesLinks[role].filter(widgetId => widgetId !== action.id),
                };
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.id]: {
                        ...state.byId[action.id],
                        archived: true,
                    },
                },
                byRole: newRolesLinks,
                allEntries: state.allEntries.filter(userId => userId !== action.id),
                deletedIds: new Set([...state.deletedIds, action.id]),
            };
        
        case CLEAR_WIDGET_CACHE:
            if (action.widgetId) {
                const newCachedWidgetData = {
                    ...state.cachedWidgetData,
                };

                delete newCachedWidgetData[action.widgetId];

                const newCachedWidgetMessage = {
                    ...state.cachedWidgetMessages,
                }

                delete newCachedWidgetMessage[action.widgetId];

                return {
                    ...state,
                    cachedWidgetData: newCachedWidgetData,
                    cachedWidgetMessages: newCachedWidgetMessage,
                }
            } else {
                return {
                    ...state,
                    cachedWidgetData: {},
                    cachedWidgetMessages: {},
                };
            }
        
        case MARK_PARTIAL_WIDGET_CACHE:
            return {
                ...state,
                partiallyCachedWidgetIds: state.partiallyCachedWidgetIds ? state.partiallyCachedWidgetIds.concat([action.widgetId]) : [action.widgetId],
            }
        
        case UNMARK_PARTIAL_WIDGET_CACHE:
            return {
                ...state,
                partiallyCachedWidgetIds: state.partiallyCachedWidgetIds && action.widgetId ? state.partiallyCachedWidgetIds.filter(widgetId => widgetId !== action.widgetId) : [],
            }
        
        case UPDATE_WIDGET_CACHE:
            return {
                ...state,
                cachedWidgetData: {
                    ...state.cachedWidgetData,
                    [action.widgetId]: action.widgetData,
                },
            };
        
        case UPDATE_WIDGET_MESSAGE_CACHE:
            return {
                ...state,
                cachedWidgetMessages: {
                    ...state.cachedWidgetMessages,
                    [action.widgetId]: action.message,
                }
            }

        case UPDATE_WIDGET_DATA:
            return {
                ...action.data,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
            };

        case UPDATE_WIDGET_START_PIECE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        startPiece: action.payload,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.widgetId]),
            }

        case SET_ISOLATED_WIDGET_PIECE:
            const newWidgetIsolatedPieces = state.byId[action.widgetId].isolatedPieces.slice(0);
            const customFieldIsolatedPieceIndex = newWidgetIsolatedPieces.findIndex(isolatedPieceData => isolatedPieceData.piece === action.payload.piece);
        
            if (customFieldIsolatedPieceIndex < 0) {
                newWidgetIsolatedPieces.push(action.payload);
            } else {
                newWidgetIsolatedPieces[customFieldIsolatedPieceIndex] = action.payload;
            }
        
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        isolatedPieces: newWidgetIsolatedPieces,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.widgetId]),
            }

        case REMOVE_ISOLATED_WIDGET_PIECE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        isolatedPieces: state.byId[action.widgetId].isolatedPieces.filter(pieceData => pieceData.piece !== action.pieceId),
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.widgetId]),
            }


        case REGISTER_WIDGET_VARIABLE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        variables: state.byId[action.widgetId].variables.concat([action.variableId]),
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.widgetId]),
            }

        case UPDATE_WIDGET_RICH_TEXT_START_PIECE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        richTextStartPiece: action.payload,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.widgetId]),
            }

        case SET_ISOLATED_WIDGET_RICH_TEXT_PIECE:
            const newWidgetRichTextIsolatedPieces = state.byId[action.widgetId].isolatedPieces ? state.byId[action.widgetId].isolatedPieces.slice(0) : [];
            const richTextIsolatedPieceIndex = newWidgetRichTextIsolatedPieces.findIndex(isolatedPieceData => isolatedPieceData.piece === action.payload.piece);
        
            if (richTextIsolatedPieceIndex < 0) {
                newWidgetRichTextIsolatedPieces.push(action.payload);
            } else {
                newWidgetRichTextIsolatedPieces[richTextIsolatedPieceIndex] = action.payload;
            }
        
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        richTextIsolatedPieces: newWidgetRichTextIsolatedPieces,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.widgetId]),
            }

        case REMOVE_ISOLATED_WIDGET_RICH_TEXT_PIECE:
            let existingRichTextIsolatedPieces = state.byId[action.widgetId].richTextIsolatedPieces;

            if (!existingRichTextIsolatedPieces) {
                existingRichTextIsolatedPieces = [];
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        richTextIsolatedPieces: existingRichTextIsolatedPieces.filter(pieceData => pieceData.piece !== action.pieceId),
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.widgetId]),
            }


        case REGISTER_WIDGET_RICH_TEXT_VARIABLE:
            let existingRichTextVariables = state.byId[action.widgetId].richTextVariables;

            if (!existingRichTextVariables) {
                existingRichTextVariables = [];
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.widgetId]: {
                        ...state.byId[action.widgetId],
                        richTextVariables: existingRichTextVariables.concat([action.variableId]),
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.widgetId]),
            }

        case UPDATE_WIDGETS_DATA:
            newState = updateEntries<WidgetState, IWidget>(state, action.data);
            for (const widget of action.data) {
                for (const roleId of widget.roles) {
                    if (!newState.byRole[roleId]) {
                        newState.byRole[roleId] = [];
                    }

                    newState.byRole[roleId].push(widget.id);
                }
            }
            return newState;

        case SYNCHRONIZE_WIDGETS_DATA:
            newState = synchronizeEntries<WidgetState, IWidget>(state, action.data);
            newState.byRole = {};
            for (const widgetId of newState.allEntries) {
                const widget = newState.byId[widgetId];
                for (const roleId of widget.roles) {
                    if (!newState.byRole[roleId]) {
                        newState.byRole[roleId] = [];
                    }

                    newState.byRole[roleId].push(widget.id);
                }
            }
            return newState;

        case CLEAR_WIDGETS_DELTA:
            return clearDelta<WidgetState, IWidget>(state);
        
        default:
            return state;
    }
}
import { ApplicationState } from '../../types';
import { FlowchartProcessState, DefaultFlowchartProcessState } from '../types';

import { getNextPieceId, executePiece, getPieceValue } from './index';
import { PieceType, AllPieceTypes } from '../pieces/types';
import { VariableType } from '../variables/types';
import { isUUID } from '../../../helpers/utilities';
import { VariableValueType } from '../../../helpers/common-types';
import { CustomFieldOptionsDataType, CustomFieldValueType } from '../../custom-fields/types';

export function getWidgetPieceValue(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, widgetId: string, pieceId: string) : VariableValueType  {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];
    const widget = applicationState.widgets.byId[widgetId];

    const functionShortHand = getWidgetPieceValue.bind({}, applicationState, processState, widgetId);

    switch(piece.type) {
        case PieceType.CUSTOM_FIELD:
            let customFieldOptionsData: CustomFieldOptionsDataType;
            let widgetEntity: VariableType = VariableType.WORKFLOW;

            switch (widget.type) {
                case 'User':
                    widgetEntity = VariableType.USER;
                    customFieldOptionsData = {
                        byId: {
                            ...applicationState.structure.roles.customFieldOptions.byId,
                            ...applicationState.users.customFieldOptions.byId,
                        },
                        allOptions: applicationState.structure.roles.customFieldOptions.allOptions.concat(applicationState.users.customFieldOptions.allOptions),
                    };
                    break;
                case 'Member':
                    widgetEntity = VariableType.MEMBER;
                    customFieldOptionsData = applicationState.members.types.customFieldOptions;
                    break;
                case 'Group':
                    widgetEntity = VariableType.GROUP;
                    customFieldOptionsData = applicationState.groups.types.customFieldOptions;
                    break;
                case 'Workflow':
                    widgetEntity = VariableType.WORKFLOW;
                    customFieldOptionsData = applicationState.workflows.types.customFieldOptions;
                    break;
                default:
                    throw new Error('Unknown widget entity type');
            }
            if (!piece.customField) {
                throw new Error('A custom field needs to be selected');
            }

            if (piece.customFieldOption) {
                return customFieldOptionsData.byId[piece.customFieldOption].name;
            }

            let customFieldValue: CustomFieldValueType;
            let rawVariableValue: CustomFieldValueType;
            let variableValue: CustomFieldValueType;
            let memberVariablePiece: AllPieceTypes;
            const entityId = processState.variables[widget.seedEntityVariable];

            if (typeof entityId !== 'string' || !isUUID(entityId)) {
                throw new Error('The entity must be a UUID')
            }

            if (typeof widgetEntity === 'undefined') {
                throw new Error('The type for the piece cannot be undefined');
            } else if (widgetEntity === VariableType.USER) {
                customFieldValue = applicationState.users.byId[entityId].customFields[piece.customField];
                rawVariableValue = customFieldValue;

                if (typeof customFieldValue === 'string' && isUUID(customFieldValue)) {
                    rawVariableValue = applicationState.users.customFieldOptions.byId.hasOwnProperty(customFieldValue) ? applicationState.users.customFieldOptions.byId[customFieldValue].name : applicationState.structure.roles.customFieldOptions.byId[customFieldValue].name;
                }

                if (Array.isArray(customFieldValue)) {
                    rawVariableValue = customFieldValue.map(customFieldValue => applicationState.users.customFieldOptions.byId.hasOwnProperty(customFieldValue) ? applicationState.users.customFieldOptions.byId[customFieldValue].name : applicationState.structure.roles.customFieldOptions.byId[customFieldValue].name);
                }
            } else if (widgetEntity === VariableType.MEMBER) {
                customFieldValue = applicationState.members.byId[entityId].customFields[piece.customField];
                rawVariableValue = customFieldValue;
                
                if (typeof customFieldValue === 'string' && isUUID(customFieldValue)) {
                    rawVariableValue = applicationState.members.types.customFieldOptions.byId[customFieldValue].name;
                }

                if (Array.isArray(customFieldValue)) {
                    rawVariableValue = customFieldValue.map(customFieldValue => applicationState.members.types.customFieldOptions.byId[customFieldValue].name);
                }
            } else if (widgetEntity === VariableType.GROUP) {
                customFieldValue = applicationState.groups.byId[entityId].customFields[piece.customField];
                rawVariableValue = customFieldValue;
                
                if (typeof customFieldValue === 'string' && isUUID(customFieldValue)) {
                    rawVariableValue = applicationState.groups.types.customFieldOptions.byId[customFieldValue].name;
                }

                if (Array.isArray(customFieldValue)) {
                    rawVariableValue = customFieldValue.map(customFieldValue => applicationState.groups.types.customFieldOptions.byId[customFieldValue].name);
                }
            
            } else if (widgetEntity === VariableType.WORKFLOW) {
                const workflowCustomFieldValue = applicationState.workflows.byId[entityId].history[applicationState.workflows.byId[entityId].historyIndex].customFields[piece.customField];

                if (typeof workflowCustomFieldValue === 'object' && !Array.isArray(workflowCustomFieldValue)) {
                    // The value is on a per-member basis

                    if (!piece.memberVariablePiece) {
                        throw new Error('The piece must have a member variable, since the custom field value is an object');
                    }

                    memberVariablePiece = piecesData.byId[piece.memberVariablePiece];

                    if (memberVariablePiece.type !== PieceType.VARIABLE) {
                        throw new Error('This piece must be a variable piece');
                    }

                    if (!memberVariablePiece.variable) {
                        throw new Error('The variable piece must point to a variable');
                    }

                    const getMemberId = processState.variables[memberVariablePiece.variable];

                    if (typeof getMemberId !== 'string') {
                        throw new Error('The member ID must always be a string');
                    }

                    rawVariableValue = workflowCustomFieldValue[getMemberId];
                } else {
                    rawVariableValue = workflowCustomFieldValue;
                }
                
                if (typeof rawVariableValue === 'string' && isUUID(rawVariableValue)) {
                    rawVariableValue = applicationState.workflows.types.customFieldOptions.byId[rawVariableValue].name;
                }

                if (Array.isArray(rawVariableValue)) {
                    rawVariableValue = rawVariableValue.map(customFieldValue => applicationState.users.customFieldOptions.byId[customFieldValue].name);
                }
            }

            if (typeof rawVariableValue === 'number') {
                if (isNaN(Number(rawVariableValue))) {
                    variableValue = undefined;
                } else {
                    variableValue = String(rawVariableValue);
                }
            } else {
                variableValue = rawVariableValue;
            }

            return variableValue;

        case PieceType.STATUS:
            if (widget.type !== 'Workflow') {
                return '';
            }

            const workflowId = processState.variables[widget.seedEntityVariable];

            if (typeof workflowId !== 'string' || !isUUID(workflowId)) {
                throw new Error('The workflow ID must be a UUID');
            }

            const workflow = applicationState.workflows.byId[workflowId];
            return piece.statusId ? applicationState.workflows.types.statuses.byId[piece.statusId].name : applicationState.workflows.types.statuses.byId[workflow.status].name;

        default:
            return getPieceValue(applicationState, processState, pieceId, functionShortHand);
    }
}


export function getNextPieceIdForWidget(applicationState: ApplicationState, processState: FlowchartProcessState, startPiece: string|undefined, getPieceValueFromAbove?: (pieceId: string) => VariableValueType): string|undefined {
    
    const getNextPieceIdShortHand = getNextPieceIdForWidget.bind({}, applicationState, processState, startPiece, getPieceValueFromAbove);

    return getNextPieceId(applicationState, processState, startPiece, getNextPieceIdShortHand, getPieceValueFromAbove);
}

export type ExecutePieceReturnType = {
    canContinueExecution: boolean,
    returnValue: VariableValueType,
};

// If it returns false, stop execution and display what needs to be displayed. Otherwise, feel free to get the next piece and continue executing
function executePieceForWidget(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, widgetId: string, pieceId: string): ExecutePieceReturnType {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];

    const pieceValueShorthand = getWidgetPieceValue.bind({}, applicationState, processState, widgetId);

    switch(piece.type) {

        case PieceType.RETURN:

            if (typeof piece.returnValue === 'undefined') {
                throw new Error('The return piece must have a variable')
            }
    
            const returnVariablePiece = piecesData.byId[piece.returnValue];
            const returnVariableValue = getWidgetPieceValue(applicationState, processState, widgetId, returnVariablePiece.id);

            return {
                canContinueExecution: false,
                returnValue: returnVariableValue,
            };

        case PieceType.RETURN_RICH_TEXT:

            if (typeof piece.returnValue === 'undefined') {
                throw new Error('The return piece must have rich text data')
            }

            return {
                canContinueExecution: false,
                returnValue: piece.returnValue,
            };

        default:
            const canContinueExecution = executePiece(applicationState, processState, pieceId, pieceValueShorthand);

            return {
                canContinueExecution,
                returnValue: undefined,
            };
    }
}

export function getWidgetValue(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, widgetId: string, startPiece: string|undefined) {
    let executionResult: ExecutePieceReturnType;
    const pieceValueShorthand = getWidgetPieceValue.bind({}, applicationState, processState, widgetId);

    do {
        const nextPieceId = getNextPieceIdForWidget(applicationState, processState, startPiece, pieceValueShorthand);

        if (typeof nextPieceId === 'undefined') {
            return true;
        }

        executionResult = executePieceForWidget(applicationState, processState, widgetId, nextPieceId);
    } while (executionResult.canContinueExecution);

    return executionResult.returnValue;
}


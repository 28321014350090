import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { translatePhrase } from 'src/shared/helpers/translation';
import { ToastService, ToastType } from '../services/toast.service';

@Component({
    selector: 'app-date-selector',
    templateUrl: './date-selector.component.html',
    styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent implements OnInit {
    currentDate: number;
    currentMonth: string;
    currentYear: number;
    selectedDate: number;
    selectedMonth: string;
    selectedYear: number;
    selectedDay: Date;
    dayMap: Array<string> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    monthMap: Array<string> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    calendarActiveButton: 'date' | 'month' | 'year' = 'date';
    preceedingDays: Array<number> = [];
    succeedingDays: Array<number> = [];
    selectedMonthDays: Array<number> = [];
    yearMap: Array<string | number> = [];

    @Input() showCalendar: boolean = false;
    @Input() preSelectedDate: any;
    @Output() dateChange = new EventEmitter();
    @Input() maxDate: Date;
    @Input() minDate: Date;
    @Input() clearDate: number;

    @Output() toggleCalender = new EventEmitter();
    @Input() isExpanded: boolean;
    @Input() toggleFromOutside: boolean = false;
    @Input() disableClear: boolean = false;

    constructor(
        public toastService: ToastService
    ) { }

    translate(phrase: string) {
        return translatePhrase(phrase.toString().trim());
    }

    ngOnInit() {
        this.yearMap = [];
        let startYear = new Date().getFullYear() - 100;

        for (let i = 0; i < 105; i += 1) {
            this.yearMap.push(startYear + i);
        }
        this.yearMap = this.yearMap.reverse();
        this.resetCalendar();
    }

    selectToday() {
        this.selectedMonth = this.monthMap[(new Date()).getMonth()];
        this.selectedYear = (new Date()).getFullYear();
        this.selectedDate = new Date().getDate();

        this.currentYear = (new Date()).getFullYear();
        this.currentDate = new Date().getDate();
        this.currentMonth = this.monthMap[(new Date()).getMonth()];

        this.renderCalendar();
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.clearDate && simpleChanges.clearDate.previousValue !== simpleChanges.clearDate.currentValue) {
            this.clearCalender();
        }

        if (simpleChanges.preSelectedDate && !simpleChanges.preSelectedDate.currentValue && !this.toggleFromOutside) {
            this.clearCalender();
        }
    }

    resetCalendar() {
        if (this.preSelectedDate) {
            this.selectedMonth = this.monthMap[moment(this.preSelectedDate).month()];
            this.selectedYear = moment(this.preSelectedDate).year();
            this.selectedDate = moment(this.preSelectedDate).date();

            this.currentYear = (new Date()).getFullYear();
            this.currentDate = new Date().getDate();
            this.currentMonth = this.monthMap[(new Date()).getMonth()];

            this.renderCalendar();
        } else {
            this.currentYear = (new Date()).getFullYear();
            this.currentDate = new Date().getDate();
            this.currentMonth = this.monthMap[(new Date()).getMonth()];
        }
    }

    renderCalendar() {
        // Generate dates for the calendar
        let i = 1,
            no_of_preceeding_days,
            no_of_days_in_selected_month,
            no_of_succeeding_days,
            last_date_of_previous_month,
            first_date_of_selected_month,
            last_date_of_selected_month;

        first_date_of_selected_month = new Date(this.selectedYear, this.monthMap.indexOf(this.selectedMonth), 1);

        // Add one month, and subtract one day to the selected month and year
        last_date_of_selected_month = new Date(this.selectedYear, this.monthMap.indexOf(this.selectedMonth) + 1, 0);
        last_date_of_previous_month = new Date(this.selectedYear, this.monthMap.indexOf(this.selectedMonth), 0);

        no_of_preceeding_days = first_date_of_selected_month.getDay();
        no_of_days_in_selected_month = last_date_of_selected_month.getDate();
        no_of_succeeding_days = 6 - last_date_of_selected_month.getDay();

        this.preceedingDays = [];
        this.selectedMonthDays = [];
        this.succeedingDays = [];

        for (i = no_of_preceeding_days - 1; i >= 0; i -= 1) {
            this.preceedingDays.push(last_date_of_previous_month.getDate() - i);
        }

        for (i = 1; i <= no_of_days_in_selected_month; i += 1) {
            this.selectedMonthDays.push(i);
        }

        for (i = 1; i <= no_of_succeeding_days; i += 1) {
            this.succeedingDays.push(i);
        }

        if (this.selectedMonthDays.length <= 0) {
            this.selectToday();
        }
    }

    getMonthNumberFromMap(month: string) {
        let index = this.monthMap.findIndex((mapmonth => mapmonth === month));
        return (index + 1).toString().padStart(2, '0');
    }

    saveCalendar() {
        this.checkMinMaxDateChange();

        if (this.selectedDate && this.selectedMonth && this.selectedYear) {
            this.dateChange.emit(this.selectedDate.toString().padStart(2, '0') + '-' + this.getMonthNumberFromMap(this.selectedMonth) + '-' + this.selectedYear);
        } else {
            this.dateChange.emit();
        }
    }

    changeMonth(isIncrement: boolean) {
        let monthIndex = this.monthMap.findIndex(month => this.selectedMonth === month);
        const selectedDate = new Date(this.selectedYear, monthIndex, this.selectedDate);

        if (isIncrement) {
            if ((this.maxDate && (selectedDate.getMonth() >= this.maxDate.getMonth() && selectedDate.getFullYear() >= this.maxDate.getFullYear()))) {
                return;
            };
            const nextMonthIndex = (monthIndex + 1) % 12;
            this.selectedMonth = this.monthMap[nextMonthIndex];
            if (nextMonthIndex === 0) {
                this.selectedYear += 1;
            }
        } else {
            if (this.minDate && (selectedDate.getMonth() <= this.minDate.getMonth() && (this.maxDate && selectedDate.getFullYear() <= this.maxDate.getFullYear()))) {
                return;
            };
            const prevMonthIndex = (monthIndex - 1 + 12) % 12;
            this.selectedMonth = this.monthMap[prevMonthIndex];
            if (prevMonthIndex === 11) {
                this.selectedYear -= 1;
            }
        }

        this.renderCalendar();
        if (!this.showCalendar) {
            this.saveCalendar();
        }
    }

    changeYear(isIncrement: boolean) {
        if (isIncrement) {
            if ((this.maxDate && this.maxDate.getFullYear() === this.selectedYear)) {
                return;
            }
            this.selectedYear += 1;
        } else {
            if (this.minDate && this.minDate.getFullYear() === this.selectedYear) {
                return;
            }
            this.selectedYear -= 1;
        }
        this.renderCalendar();
        if (!this.showCalendar) {
            this.saveCalendar();
        }
    }

    clearCalender() {
        this.selectedDate = undefined;
        this.selectedMonth = undefined;
        this.selectedYear = undefined;

        this.saveCalendar();
        if (this.isExpanded) {
            this.toggleCalender.emit();
        }
    }

    getMonthIndex(selectedMonth: string) {
        return this.monthMap.findIndex(month => selectedMonth === month);
    }

    checkMinMaxDateChange() {
        if (this.minDate && this.selectedDate < this.minDate.getDate() && this.minDate.getMonth() === this.getMonthIndex(this.selectedMonth)) {
            this.toastService.presentToastWithOptions(translatePhrase("Please set the date greater than ") + this.minDate.getDate(), ToastType.INFO);
            this.selectedDate = this.minDate.getDate();
        }

        if (this.maxDate && this.selectedDate > this.maxDate.getDate() && this.maxDate.getMonth() === this.getMonthIndex(this.selectedMonth)) {
            this.toastService.presentToastWithOptions(translatePhrase("Please set the date less than ") + this.maxDate.getDate(), ToastType.INFO);
            this.selectedDate = this.maxDate.getDate();
        }

        if (this.minDate && this.selectedYear < this.minDate.getFullYear()) {
            this.toastService.presentToastWithOptions(translatePhrase("Please set the year greater than ") + this.minDate.getFullYear(), ToastType.INFO);
            this.selectedYear = this.minDate.getFullYear();
        }

        if (this.maxDate && this.selectedYear > this.maxDate.getFullYear()) {
            this.toastService.presentToastWithOptions(translatePhrase("Please set the year less than ") + this.maxDate.getFullYear(), ToastType.INFO);
            this.selectedYear = this.maxDate.getFullYear();
        }
    }

    selectDate(date: number) {
        this.selectedDate = date;
        this.saveCalendar();
    }

    selectMonth(month: string) {
        this.selectedMonth = month;
        this.saveCalendar();
        this.calendarActiveButton = 'year'
    }

    selectYear(year: number) {
        this.selectedYear = year;
        this.saveCalendar();
        this.calendarActiveButton = 'year';
    }

    expandCalendar() {
        this.toggleCalender.emit();
        if (!this.selectedDate && !this.selectedYear && !this.selectedMonth) {
            this.selectToday();
            this.saveCalendar();
        }
    }

    closeCalendar() {
        if (this.isExpanded) {
            this.toggleCalender.emit();
        }
    }

    switchActiveSegment(activeButton: 'date' | 'month' | 'year') {
        this.showCalendar = true;
        if (!this.selectedDate && !this.selectedYear && !this.selectedMonth) {
            this.selectToday();
            this.saveCalendar();
        }
        this.calendarActiveButton = activeButton;
        if (!this.isExpanded && this.toggleFromOutside) {
            this.toggleCalender.emit();
        }
    }
}

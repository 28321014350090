import { ADD_VARIABLE, DELETE_VARIABLE, UPDATE_VARIABLE, IUpdateableVariableData, AddVariableAction, DeleteVariableAction, UpdateVariableAction, AddVariablesAction, ADD_VARIABLES } from './types';
import moment from 'moment';

export function addVariable(payload: IUpdateableVariableData): AddVariableAction {
    return {
        type: ADD_VARIABLE,
        payload,
        createdTime: moment().format(),
    }
}

export function addVariables(payload: Array<IUpdateableVariableData>): AddVariablesAction {
    return {
        type: ADD_VARIABLES,
        payload,
        createdTime: moment().format(),
    }
}

export function deleteVariable(id: string, parentId: string): DeleteVariableAction {
    return {
        type: DELETE_VARIABLE,
        id,
        parentId,
    }
}

export function updateVariable(payload: IUpdateableVariableData): UpdateVariableAction {
    return {
        type: UPDATE_VARIABLE,
        payload,
    }
}
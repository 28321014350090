import { PieceType } from '../../pieces/types';

import { ApplicationState } from '../../../types';

import { getPieceValue, executePiece } from '../index';

import { ExecutePieceReturnType, getNextPieceIdForComputedField } from './index';
import { DefaultFlowchartProcessState } from '../../types';
import { VariableValueType } from '../../../../helpers/common-types';
import { CustomField, FieldType, getValidCustomFieldValueForInput } from '../../../custom-fields/types';

export function getLevelCustomFieldPieceValue(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, levelId: string, customField: CustomField, pieceId: string): VariableValueType {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];
    const levelsData = applicationState.structure.levels;

    const functionShortHand = getLevelCustomFieldPieceValue.bind({}, applicationState, processState, levelId, customField);

    switch (piece.type) {
        case PieceType.CUSTOM_FIELD:
            if (!piece.customField) {
                throw new Error('A custom field needs to be selected');
            }

            if (piece.customFieldOption) {
                return levelsData.customFieldOptions.byId[piece.customFieldOption].name;
            }

            const customField = levelsData.customFields.byId[piece.customField];

            if (customField.isComputed) {
                let startPiece: string | undefined;

                if (customField.startPiece) {
                    startPiece = customField.startPiece.piece;
                }

                if (typeof startPiece === 'undefined') {
                    return undefined;
                }

                const computedValue = getLevelCustomFieldPieceValue(applicationState, processState, levelId, customField, startPiece);

                return computedValue;
            }

            let customFieldValue = processState.customFields[customField.id];

            if (customField.type === FieldType.SINGLE_SELECT) {
                if (!!customFieldValue) {
                    if (typeof customFieldValue !== 'string') {
                        throw new Error('A single select must have a custom field type');
                    }

                    customFieldValue = levelsData.customFieldOptions.byId[customFieldValue].name;

                }
            }

            if (customField.type === FieldType.MULTI_SELECT) {
                if (!!customFieldValue) {
                    if (!Array.isArray(customFieldValue)) {
                        throw new Error('A multi select must have an array');
                    }

                    customFieldValue = customFieldValue.map(customFieldOptionId => levelsData.customFieldOptions.byId[customFieldOptionId].name);

                }
            }

            return customFieldValue;

        default:
            return getPieceValue(applicationState, processState, pieceId, functionShortHand);
    }
}

// If it returns false, stop execution and display what needs to be displayed. Otherwise, feel free to get the next piece and continue executing
function executePieceForLevelComputedField(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, pieceId: string, levelId: string, customField: CustomField): ExecutePieceReturnType {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];

    const getPieceShorthand = getLevelCustomFieldPieceValue.bind({}, applicationState, processState, levelId, customField);

    switch (piece.type) {

        case PieceType.RETURN:

            if (typeof piece.returnValue === 'undefined') {
                throw new Error('The return piece must have a variable')
            }

            const returnVariablePiece = piecesData.byId[piece.returnValue];
            const returnVariableValue = getPieceShorthand(returnVariablePiece.id);

            return {
                canContinueExecution: false,
                returnValue: returnVariableValue,
            };

        default:
            const canContinueExecution = executePiece(applicationState, processState, pieceId, getPieceShorthand);

            return {
                canContinueExecution,
                returnValue: undefined,
            };
    }
}

export function getLevelComputedFieldValue(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, startPiece: string | undefined, levelId: string, customField: CustomField) {
    let executionResult: ExecutePieceReturnType;
    const getPieceShorthand = getLevelCustomFieldPieceValue.bind({}, applicationState, processState, levelId, customField);

    do {
        const nextPieceId = getNextPieceIdForComputedField(applicationState, processState, startPiece, getPieceShorthand);

        if (typeof nextPieceId === 'undefined') {
            throw new Error('The next piece does not exist');
        }

        executionResult = executePieceForLevelComputedField(applicationState, processState, nextPieceId, levelId, customField);
    } while (executionResult.canContinueExecution);

    const returnValue = executionResult.returnValue;
    return getValidCustomFieldValueForInput(customField, returnValue, applicationState.structure.levels.customFieldOptions);
}
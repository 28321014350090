import { ReOrderedListsForEntity } from '../helpers/common-types';
import { isObjectEmpty, mergeReverseLinkArrays } from '../helpers/utilities';
import { CustomFieldDataHolder, CustomFieldState, CustomField } from './custom-fields/types';

export interface Identifiable {
    id: string,
};

export interface Synchronizable extends Identifiable {
    createdTime: string,
    lastUpdatedTime: string,
    archived?: boolean,
}

export interface CustomFieldHolder {
    customFields: CustomFieldDataHolder,
}

export interface CustomFieldDeltaTrackable extends Synchronizable, CustomFieldHolder {
    lastUpdatedCustomFieldTime: string,
    lastUpdateTimeForFields: {
        [customFieldId: string]: string,
    }
}

export interface NormalizedModel<T extends Synchronizable> {
    byId: {
        [key: string]: T
    },
    allEntries: Array<string>,
    filteredEntries: Array<string>,

    createdIds: Set<string>,
    updatedIds: Set<string>,
    deletedIds: Set<string>,
}

export function addEntity<T extends NormalizedModel<U>, U extends Synchronizable>(state: T, payload: U): T {
    return {
        ...state,
        byId: {
            ...state.byId,
            [payload.id]: {
                ...payload,
            },
        },
        allEntries: state.allEntries.concat([payload.id]),
        createdIds: new Set([...state.createdIds, payload.id]),
    }
}

export function addEntities<T extends NormalizedModel<U>, U extends Synchronizable>(state: T, payload: Array<U>): T {
    const newIds = payload.map(entity => entity.id);

    state = {
        ...state,
        byId: {
            ...state.byId,
        },
        allEntries: state.allEntries.concat(newIds),
        createdIds: new Set([...state.createdIds, ...newIds]),
    };

    for (const entity of payload) {
        state.byId[entity.id] = entity;
    }

    return state;
}

export function updateEntity<T extends NormalizedModel<U>, U extends Synchronizable>(state: T, payload: Partial<U> & Identifiable, currentTime: string): T {

    return {
        ...state,
        byId: {
            ...state.byId,
            [payload.id]: {
                ...state.byId[payload.id],
                ...payload,
                lastUpdatedTime: currentTime,
            },
        },
        updatedIds: new Set([...state.updatedIds, payload.id]),
    }
}

export function updateEntities<T extends NormalizedModel<U>, U extends Synchronizable>(state: T, payload: Array<Partial<U> & Identifiable>, currentTime: string): T {
    const updatedIds = payload.map(entity => entity.id);

    state = {
        ...state,
        byId: {
            ...state.byId,
        },
        updatedIds: new Set([...state.updatedIds, ...updatedIds]),
    };

    for (const entity of payload) {
        state.byId[entity.id] = {
            ...state.byId[entity.id],
            ...entity,
            lastUpdatedTime: currentTime,
        };
    }

    return state;
}

export function updateEntityWithCustomFields<T extends NormalizedModel<U>, U extends CustomFieldDeltaTrackable>(state: T, payload: Partial<U> & Identifiable, currentTime: string): T {
    const entity = state.byId[payload.id];

    const customFieldsDelta = {
        lastUpdatedCustomFieldTime: entity.lastUpdatedCustomFieldTime,
        lastUpdateTimeForFields: {
            ...entity.lastUpdateTimeForFields,
        },
    };

    for (const customFieldId in payload.customFields) {
        if (payload.customFields.hasOwnProperty(customFieldId)) {
            const customFieldValue = payload.customFields[customFieldId];
            const oldValue = entity.customFields[customFieldId];

            if (customFieldValue !== oldValue) {
                customFieldsDelta.lastUpdatedCustomFieldTime = currentTime;
                customFieldsDelta.lastUpdateTimeForFields[customFieldId] = currentTime;
            }
        }
    }

    return {
        ...state,
        byId: {
            ...state.byId,
            [payload.id]: {
                ...state.byId[payload.id],
                ...payload,
                lastUpdatedTime: currentTime,
                lastUpdatedCustomFieldTime: customFieldsDelta.lastUpdatedCustomFieldTime,
                lastUpdateTimeForFields: customFieldsDelta.lastUpdateTimeForFields,
            },
        },
        updatedIds: new Set([...state.updatedIds, payload.id]),
    }
}

export function bulkUpdateEntityWithCustomFields<T extends NormalizedModel<U>, U extends CustomFieldDeltaTrackable>(state: T, payload: Array<Partial<U> & Identifiable>, currentTime: string): T {
    state = {
        ...state,
        byId: {
            ...state.byId,
        }
    };

    const updatedIds = payload.map(entity => entity.id);

    for (const updateData of payload) {
        const entity = state.byId[updateData.id];

        const customFieldsDelta = {
            lastUpdatedCustomFieldTime: entity.lastUpdatedCustomFieldTime,
            lastUpdateTimeForFields: {
                ...entity.lastUpdateTimeForFields,
            },
        };

        for (const customFieldId in updateData.customFields) {
            if (updateData.customFields.hasOwnProperty(customFieldId)) {
                const customFieldValue = updateData.customFields[customFieldId];
                const oldValue = entity.customFields[customFieldId];

                if (customFieldValue !== oldValue) {
                    customFieldsDelta.lastUpdatedCustomFieldTime = currentTime;
                    customFieldsDelta.lastUpdateTimeForFields[customFieldId] = currentTime;
                }
            }
        }

        state.byId[updateData.id] = {
            ...state.byId[updateData.id],
            ...updateData,
            lastUpdatedTime: currentTime,
            lastUpdatedCustomFieldTime: customFieldsDelta.lastUpdatedCustomFieldTime,
            lastUpdateTimeForFields: customFieldsDelta.lastUpdateTimeForFields,
        };
    }

    state.updatedIds = new Set([...state.updatedIds, ...updatedIds]);

    return state;
}

export function updateCustomFieldsForEntity<T extends NormalizedModel<U>, U extends CustomFieldDeltaTrackable>(state: T, entityId: string, payload: CustomFieldDataHolder, currentTime: string): T {
    const entity = state.byId[entityId];

    const customFieldsDelta = {
        lastUpdatedCustomFieldTime: entity.lastUpdatedCustomFieldTime,
        lastUpdateTimeForFields: {
            ...entity.lastUpdateTimeForFields,
        },
    };

    for (const customFieldId in payload) {
        if (payload.hasOwnProperty(customFieldId)) {
            const customFieldValue = payload[customFieldId];
            const oldValue = entity.customFields[customFieldId];

            if (customFieldValue !== oldValue) {
                customFieldsDelta.lastUpdatedCustomFieldTime = currentTime;
                customFieldsDelta.lastUpdateTimeForFields[customFieldId] = currentTime;
            }
        }
    }

    return {
        ...state,
        byId: {
            ...state.byId,
            [entityId]: {
                ...state.byId[entityId],
                customFields: {
                    ...payload,
                },
                lastUpdatedCustomFieldTime: customFieldsDelta.lastUpdatedCustomFieldTime,
                lastUpdateTimeForFields: customFieldsDelta.lastUpdateTimeForFields,
            },
        },
        updatedIds: new Set([...state.updatedIds, entityId]),
    }
}

export function deleteEntity<T extends NormalizedModel<U>, U extends Synchronizable>(state: T, id: string, currentTime: string): T {

    return {
        ...state,
        byId: {
            ...state.byId,
            [id]: {
                ...state.byId[id],
                lastUpdatedTime: currentTime,
                archived: true,
            },
        },
        allEntries: state.allEntries.filter(entityId => entityId !== id),
        deletedIds: new Set([...state.deletedIds, id]),
    }
}

export function unArchiveEntity<T extends NormalizedModel<U>, U extends Synchronizable>(state: T, id: string, currentTime: string): T {

    return {
        ...state,
        byId: {
            ...state.byId,
            [id]: {
                ...state.byId[id],
                lastUpdatedTime: currentTime,
                archived: false,
            },
        },
        allEntries: state.allEntries.concat([id]),
        updatedIds: new Set([...state.updatedIds, id]),
    }
}

export function updateEntries<T extends NormalizedModel<U>, U extends Synchronizable>(state: T, entries: Array<U>): T {
    const newState = {
        ...state,
        byId: {},
        allEntries: [],
        filteredEntries: [],
    };

    for (const entry of entries) {
        if (!(entry.id in newState.byId) && !entry.archived) {
            newState.allEntries.push(entry.id);
            newState.filteredEntries.push(entry.id);
        }
        newState.byId[entry.id] = entry;
    }

    return newState;
}

export function synchronizeEntries<T extends NormalizedModel<U>, U extends Synchronizable>(state: T, entries: Array<U>): T {
    const newState = {
        ...state,
        byId: {
            ...state.byId,
        },
        allEntries: state.allEntries.slice(),
    };

    for (const entry of entries) {
        if (entry.id in newState.byId) {
            // This entity needs to be updated
            newState.byId[entry.id] = entry;

            if (entry.archived) {
                newState.allEntries = newState.allEntries.filter(entryId => entry.id !== entryId);
            } else if (!newState.allEntries.includes(entry.id)) {
                newState.allEntries.push(entry.id);
            }
        } else {
            // This entity needs to be created
            newState.byId[entry.id] = entry;

            if (!entry.archived) {
                newState.allEntries.push(entry.id);
            }
        }
    }

    return newState;
}

export function synchronizeReverseLinks<T extends NormalizedModel<U>, U extends Synchronizable, V extends Synchronizable>(state: T, entries: Array<V>, reverseLinkPropertyName: string, linkPropertyName: string, groupReverseLinkPropertyName?: string): T {
    const newState = {
        ...state,
        byId: {
            ...state.byId,
        },
    };

    if (groupReverseLinkPropertyName) {

        const reverseLinks: {
            [reverseEntityId: string]: {
                [groupId: string]: Set<string>
            }
        } = {};

        for (const entity of entries) {
            const reverseEntityId: string = (entity as any)[reverseLinkPropertyName];
            const entityGroupId: string = (entity as any)[groupReverseLinkPropertyName];

            if (!(reverseEntityId in reverseLinks)) {
                reverseLinks[reverseEntityId] = {};
            }

            if (!(entityGroupId in reverseLinks[reverseEntityId])) {
                reverseLinks[reverseEntityId][entityGroupId] = new Set();
            }

            reverseLinks[reverseEntityId][entityGroupId].add(entity.id);
        }

        for (const reverseEntityId in reverseLinks) {
            const reverseEntity = {
                ...newState.byId[reverseEntityId]
            };

            if (!isObjectEmpty(reverseEntity)) {
                let reverseLinkValue: {
                    [entityGroupId: string]: Array<string>
                } = (reverseEntity as any)[linkPropertyName];

                if (!reverseLinkValue) {
                    reverseLinkValue = {};
                }

                for (const groupId in reverseLinks[reverseEntityId]) {
                    if (!reverseLinkValue[groupId]) {
                        reverseLinkValue[groupId] = [];
                    }

                    for (const entityId of reverseLinks[reverseEntityId][groupId]) {
                        if (!reverseLinkValue[groupId].includes(entityId)) {
                            reverseLinkValue[groupId].push(entityId);
                        }
                    }
                }

                (reverseEntity as any)[linkPropertyName] = reverseLinkValue;
                newState.byId[reverseEntityId] = reverseEntity;
            }
        }
    } else {

        const reverseLinks: {
            [reverseEntityId: string]: Set<string>
        } = {};

        for (const entity of entries) {
            let reverseEntityId: string = (entity as any)[reverseLinkPropertyName];
            if (reverseEntityId in reverseLinks) {
                reverseLinks[reverseEntityId].add(entity.id);
            } else {
                reverseLinks[reverseEntityId] = new Set([entity.id]);
            }
        }

        for (const reverseEntityId in reverseLinks) {
            const reverseEntity = {
                ...newState.byId[reverseEntityId]
            };

            if (!isObjectEmpty(reverseEntity)) {
                let reverseLinkValue: Array<string> = (reverseEntity as any)[linkPropertyName];

                if (!reverseLinkValue) {
                    reverseLinkValue = [];
                }

                for (const entityId of reverseLinks[reverseEntityId]) {
                    if (!reverseLinkValue.includes(entityId)) {
                        reverseLinkValue.push(entityId);
                    }
                }

                (reverseEntity as any)[linkPropertyName] = reverseLinkValue;

                newState.byId[reverseEntityId] = reverseEntity;
            }
        }
    }

    return newState;
}

export function synchronizeCustomFieldReverseLinks<T extends CustomFieldState & NormalizedModel<Synchronizable & { customFields: Array<string> }>>(state: T, entries: Array<CustomField>): T {
    const newState: T = {
        ...state,
        byId: {
            ...state.byId,
        }
    };

    const reverseLinks: {
        [reverseEntityId: string]: Set<string>
    } = {};

    for (const entity of entries) {
        let reverseEntityId = entity.parentId;
        if (reverseEntityId) {
            if (reverseEntityId in reverseLinks) {
                reverseLinks[reverseEntityId].add(entity.id);
            } else {
                reverseLinks[reverseEntityId] = new Set([entity.id])
            }
        }
    }

    for (const reverseEntityId in reverseLinks) {
        const reverseEntity = {
            ...newState.byId[reverseEntityId]
        };

        if (!isObjectEmpty(reverseEntity)) {
            let reverseLinkValue = reverseEntity.customFields;

            if (!reverseLinkValue) {
                reverseLinkValue = [];
            }

            for (const entityId of reverseLinks[reverseEntityId]) {
                if (!reverseLinkValue.includes(entityId)) {
                    reverseLinkValue.push(entityId);
                }
            }

            reverseEntity.customFields = reverseLinkValue;

            newState.byId[reverseEntityId] = reverseEntity;
        }
    }

    return newState;
}

export function synchronizeCustomFieldOptionReverseLinks<T extends CustomFieldState>(state: T, reOrderedCustomFieldOptions: ReOrderedListsForEntity): T {
    const newState: T = {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
            }
        },
        reOrderedCustomFieldOptions: {
            ...state.reOrderedCustomFieldOptions,
        },
    };

    for (const customFieldId in reOrderedCustomFieldOptions) {
        let newCustomFieldOptionIds = reOrderedCustomFieldOptions[customFieldId];

        if (customFieldId in state.reOrderedCustomFieldOptions) {
            newCustomFieldOptionIds = mergeReverseLinkArrays(newCustomFieldOptionIds, state.reOrderedCustomFieldOptions[customFieldId]);
            newState.reOrderedCustomFieldOptions[customFieldId] = newCustomFieldOptionIds;
        }

        const customField = state.customFields.byId[customFieldId];

        if (JSON.stringify(customField.choices) !== JSON.stringify(newCustomFieldOptionIds)) {
            newState.customFields.byId[customFieldId] = {
                ...state.customFields.byId[customFieldId],
                choices: newCustomFieldOptionIds,
            };
        }
    }

    return newState;
}

export function synchronizeEntriesForCustomModels<T extends NormalizedModel<U>, U extends CustomFieldDeltaTrackable>(state: T, entries: Array<U>): T {
    const newState = {
        ...state,
        byId: {
            ...state.byId,
        },
        allEntries: state.allEntries.slice(),
    };

    for (const entry of entries) {
        if (entry.id in state.byId) {
            // This field needs to be updated.
            newState.byId[entry.id] = entry;

            const existingEntry = state.byId[entry.id];
            const existingCustomFieldData = state.byId[entry.id].customFields;

            for (const customFieldId in existingCustomFieldData) {
                if (
                    typeof entry.lastUpdateTimeForFields[customFieldId] !== 'undefined' &&
                    (typeof existingEntry.lastUpdateTimeForFields[customFieldId] === 'undefined' ||
                        entry.lastUpdateTimeForFields[customFieldId] > existingEntry.lastUpdateTimeForFields[customFieldId])
                ) {
                    existingEntry.customFields[customFieldId] = existingCustomFieldData[customFieldId];
                }
            }

            if (existingEntry.lastUpdatedCustomFieldTime < entry.lastUpdatedCustomFieldTime) {
                existingEntry.lastUpdatedCustomFieldTime = entry.lastUpdatedCustomFieldTime;
            }

            if (entry.archived) {
                newState.allEntries = newState.allEntries.filter(entryId => entryId !== entry.id);
            } else if (!newState.allEntries.includes(entry.id)) {
                newState.allEntries.push(entry.id);
            }

        } else {
            if (!(entry.id in newState.byId)) {
                newState.byId[entry.id] = entry;

                if (!entry.archived) {
                    newState.allEntries.push(entry.id);
                }
            }
        }
    }

    return newState;
}

export function clearDelta<T extends NormalizedModel<U>, U extends Synchronizable>(state: T): T {
    return {
        ...state,
        createdIds: new Set(),
        updatedIds: new Set(),
        deletedIds: new Set(),
    }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Credentials } from '../models/login';
import { BASE_URL } from 'src/shared/store/url';
import { VERSION_NUMBER } from 'src/shared/helpers/version';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient,
    ) { }

    async isAppUpToDate() {
        const response = await this.http.get(BASE_URL + '/version/', { responseType: 'text' }).toPromise();
        return response === VERSION_NUMBER;
    }

    authenticate(credentials: Credentials) {
        const body = `org_code=${encodeURIComponent(credentials.org_code)}&username=${encodeURIComponent(credentials.username)}&password=${encodeURIComponent(credentials.password)}`;
        const headers = new HttpHeaders({
            Accept: 'text/html, application/xhtml+xml, */*',
            'Content-Type': 'application/x-www-form-urlencoded',
        });
        const httpOptions = {
            headers,
            responseType: 'text' as 'json',
        };
        return this.http.post(BASE_URL + '/api-auth/', body, httpOptions).toPromise();
    }

    resetPassword(credentials: any) {
        const body = `org_code=${encodeURIComponent(credentials.org_code)}&username=${encodeURIComponent(credentials.username)}`;
        const headers = new HttpHeaders({
            Accept: 'text/html, application/xhtml+xml, */*',
            'Content-Type': 'application/x-www-form-urlencoded',
        });
        const httpOptions = {
            headers,
            responseType: 'text' as 'json',
        };
        return this.http.post(BASE_URL + '/password-reset/', body, httpOptions).toPromise();

    }
}

import { TranslationsState, UPDATE_TRANSLATION, UPDATE_TRANSLATIONS, CLEAR_TRANSLATIONS_DELTA, UPDATE_MULTIPLE_TRANSLATIONS } from './types'
import { ADD_LANGUAGE } from '../languages/types';
import { InternationalizationActionTypes, UPDATE_INTERNATIONALIZATION_DATA } from '../types';

const initialState: TranslationsState = {  
    byLanguage: {},
    updatedWords: {},
    currentLanguage: undefined,
};

export function translationsReducer(state = initialState, action: InternationalizationActionTypes): TranslationsState {
    let changedWords: Array<string>;

    switch(action.type) {

        // Translation actions

        case UPDATE_TRANSLATION:
            changedWords = action.language in state.updatedWords ? state.updatedWords[action.language] : [];
            changedWords = Array.from((new Set(changedWords)).add(action.originalWord));

            return {
                ...state,
                byLanguage: {
                    ...state.byLanguage,
                    [action.language]: {
                        ...state.byLanguage[action.language],
                        [action.originalWord]: action.translatedWord
                    }
                },
                updatedWords: {
                    [action.language]: changedWords,
                }
            };

        case UPDATE_MULTIPLE_TRANSLATIONS:
            changedWords = action.language in state.updatedWords ? state.updatedWords[action.language] : [];

            const languageTranslations = {
                ...state.byLanguage[action.language]
            };

            for (const translation of action.translations) {
                changedWords.push(translation.originalWord);
                languageTranslations[translation.originalWord] = translation.translatedWord;
            }

            changedWords = Array.from(new Set(changedWords));

            return {
                ...state,
                byLanguage: {
                    ...state.byLanguage,
                    [action.language]: languageTranslations
                },
                updatedWords: {
                    [action.language]: changedWords,
                }
            };

        case UPDATE_TRANSLATIONS:
            return {
                ...state,
                byLanguage: {
                    ...state.byLanguage,
                    ...action.data,
                },
            }

        case CLEAR_TRANSLATIONS_DELTA:
            return {
                ...state,
                updatedWords: {},
            }

        // Language actions

        case ADD_LANGUAGE:
            return {
                ...state,
                byLanguage: {
                    ...state.byLanguage,
                    [action.payload.id]: {}
                }
            }

            
        // Internationalization actions
        case UPDATE_INTERNATIONALIZATION_DATA:
            return {
                ...action.data.translations,
            }

        default:
            return state;
    }
}
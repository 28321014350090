import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { NgReduxModule, NgRedux } from '@angular-redux/store';
import store from '../shared/store/main';
import { ApplicationState } from '../shared/store/types';

// Modules Import
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { IonicSelectableModule } from 'ionic-selectable';
import { IntroductionModule } from './shared/introduction.module';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';

// Native Imports
import { IonicStorageModule } from '@ionic/storage';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './global-error-handler';

// Modal Modules Import
import { CountrySelectorPageModule } from './country-selector/country-selector.module';
import { AddOrEditMemberPageModule } from './add-or-edit-member/add-or-edit-member.module';
import { MemberProfileDetailsPageModule } from './member-profile-details/member-profile-details.module';
import { GroupDetailsPageModule } from './group-details/group-details.module';
import { ToggleGroupMembersPageModule } from './toggle-group-members/toggle-group-members.module';
import { WorkflowExecutorPageModule } from './workflow-executor/workflow-executor.module';
import { StatisticalDetailsPageModule } from './statistical-details/statistical-details.module';
import { AddOrEditGroupPageModule } from './add-or-edit-group/add-or-edit-group.module';
import { AddWorkflowPageModule } from './add-workflow/add-workflow.module';
import { CalendarPageModule } from './calendar/calendar.module';
import { LocationSelectorPageModule } from './location-selector/location-selector.module';

// Services Import
import { AuthService } from './services/auth.service';
import { IntroductionService } from './services/introduction.service';
import { RefreshService } from './services/refresh.service';
import { ShareService } from './services/share.service';
import { ToastService } from './services/toast.service';
import { UpdateService } from './services/update.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ToastModule } from './toast/toast.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        IonicSelectableModule,
        HttpClientModule,
        BackButtonDisableModule.forRoot({
            preserveScrollPosition: true
        }),
        CountrySelectorPageModule,
        AddOrEditMemberPageModule,
        MemberProfileDetailsPageModule,
        GroupDetailsPageModule,
        ToggleGroupMembersPageModule,
        WorkflowExecutorPageModule,
        NgReduxModule,
        IntroductionModule,
        StatisticalDetailsPageModule,
        AddOrEditGroupPageModule,
        AddWorkflowPageModule,
        CalendarPageModule,
        LocationSelectorPageModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        ToastModule,
    ],
    providers: [
        UpdateService,
        RefreshService,
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        Geolocation,
        SocialSharing,
        ShareService,
        AuthService,
        IntroductionService,
        ToastService
    ],
    bootstrap: [
        AppComponent,
    ]
})
export class AppModule {

    constructor(ngRedux: NgRedux<ApplicationState>) {
        ngRedux.provideStore(store);
    }
}

import { NormalizedModel, Synchronizable } from '../normalized-model';
import { ReportTypeActionTypes, ReportTypeState } from './types/types';

export const ADD_REPORT_REQUEST = 'ADD_REPORT_REQUEST';
export const ADD_REPORT = 'ADD_REPORT';
export const DELETE_REPORT = 'DELETE_REPORT';
export const UPDATE_REPORT = 'UPDATE_REPORT';

export const START_REPORT_DATA_GENERATION = 'START_REPORT_DATA_GENERATION';
export const STORE_DATA_FOR_REPORT = 'STORE_DATA_FOR_REPORT';

export const SEARCH_REPORT_TABLE = 'SEARCH_REPORT_TABLE';
export const FILTER_REPORT_TABLE = 'FILTER_REPORT_TABLE';
export const GO_TO_PAGE_REPORT_TABLE = 'GO_TO_PAGE_REPORT_TABLE';
export const SET_PAGE_SIZE_REPORT_TABLE = 'SET_PAGE_SIZE_REPORT_TABLE';
export const SORT_REPORT_TABLE = 'SORT_REPORT_TABLE';

export const UPDATE_REPORTS_DATA = 'UPDATE_REPORTS_DATA';
export const SYNCHRONIZE_REPORTS_DATA = 'SYNCHRONIZE_REPORTS_DATA';
export const CLEAR_REPORTS_DELTA = 'CLEAR_REPORTS_DELTA';

export const UPDATE_REPORT_DATA = 'UPDATE_REPORT_DATA';


export interface INewReportData {
    name: string;
    type: string;
    startDate: string|undefined;
    endDate: string|undefined;
    user: string;
}

export interface IUpdateableReportData extends INewReportData {
    id: string,
}

export interface IReport extends IUpdateableReportData, Synchronizable {
    archived?: boolean,
    generatedTime: string|undefined;
    data?: Array<Array<string>>,
}

export interface ReportFilters {
    projects: Array<string>,
    types: Array<string>,
    users: Array<string>,
    createdDateRange: Array<string>,
    lastUpdatedDateRange: Array<string>,
    generatedDateRange: Array<string>,
    archived: boolean,
    unsynced: boolean,
}

export interface ReportState extends NormalizedModel<IReport> {

    types: ReportTypeState,

    pageSize: number,
    isGeneratingReport: boolean,
    currentPageNumber: number,
    filters: ReportFilters,
    sort: {
        column: string|undefined,
        order: 'ASC'|'DESC',
    },
    searchTerm: string|undefined,
}

export interface AddReportRequestAction {
    type: typeof ADD_REPORT_REQUEST,
    payload: INewReportData,
}

export interface AddReportAction {
    type: typeof ADD_REPORT,
    payload: IReport,
    currentTime: string,
}

export interface DeleteReportAction {
    type: typeof DELETE_REPORT,
    id: string,
    currentTime: string,
}

export interface UpdateReportAction {
    type: typeof UPDATE_REPORT,
    payload: IUpdateableReportData,
    currentTime: string,
}

export interface StartReportDataGenerationAction {
    type: typeof START_REPORT_DATA_GENERATION,
}

export interface StoreDataForReportAction {
    type: typeof STORE_DATA_FOR_REPORT,
    data: Array<Array<string>>,
    reportId: string,
    currentTime: string,
}

export interface IUpdateReportData {
    type: typeof UPDATE_REPORT_DATA,
    data: ReportState,
    currentTime: string,
}


export interface SearchReportTableAction {
    type: typeof SEARCH_REPORT_TABLE,
    searchTerm: string,
}

export interface FilterReportTableAction {
    type: typeof FILTER_REPORT_TABLE,
    projects: Array<string>,
    types: Array<string>,
    users: Array<string>,
    createdDateRange: Array<string>,
    lastUpdatedDateRange: Array<string>,
    generatedDateRange: Array<string>,
    archived: boolean,
    unsynced: boolean,
}

export interface GoToPageReportTableAction {
    type: typeof GO_TO_PAGE_REPORT_TABLE,
    pageNumber: number,
}

export interface SetPageSizeReportTableAction {
    type: typeof SET_PAGE_SIZE_REPORT_TABLE,
    pageSize: number,
}

export interface SortReportTableAction {
    type: typeof SORT_REPORT_TABLE,
    column: string,
    order: 'ASC'|'DESC'
}

export interface UpdateReportsData {
    type: typeof UPDATE_REPORTS_DATA,
    data: Array<IReport>,
}

export interface SynchronizeReportsData {
    type: typeof SYNCHRONIZE_REPORTS_DATA,
    data: Array<IReport>,
}

export interface ClearReportsDelta {
    type: typeof CLEAR_REPORTS_DELTA,
}

type ReportTableActions = SearchReportTableAction | FilterReportTableAction | GoToPageReportTableAction | SetPageSizeReportTableAction | SortReportTableAction;

export type ReportActionTypes = AddReportRequestAction | AddReportAction | DeleteReportAction | UpdateReportAction | StartReportDataGenerationAction | StoreDataForReportAction | IUpdateReportData | ReportTableActions | UpdateReportsData | SynchronizeReportsData | ClearReportsDelta | ReportTypeActionTypes;
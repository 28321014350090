import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import * as Chartist from 'chartist';
import { translatePhrase } from 'src/shared/helpers/translation';

@Component({
	selector: 'app-bar-chart',
	templateUrl: './bar-chart.component.html',
	styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
	@Input() chartLabels: Array<string>;
	@Input() chartData: Array<number>;
	@Input() maxValue: number;
	
	@ViewChild('chartElement') chartElement: ElementRef;
	
    constructor() { }

    translate(phrase: string) {
		return translatePhrase(phrase.trim());
    }
    
    scrollRight() {
        this.chartElement.nativeElement.scrollLeft += 50;
    }

	ngAfterViewInit() {
		if (this.chartData.length > 0 && this.chartLabels.length > 0) {
            let chartWidth = this.chartLabels.length * 100;
			try {
				new Chartist.Bar(this.chartElement.nativeElement, {
					labels: this.chartLabels,
					series: [
						this.chartData
					]
				}, {
                    width: `${chartWidth}px`,
                    high: this.maxValue + 1,
					low: 0,
					chartPadding: {
						right: 15,
						left: -10,
                    },
                    plugins: [
                        this.ctBarLabels()
                    ]
				}).on('draw', (data) => {                    
                    if (data.type === 'bar') {
                        data.element._node.style.strokeWidth = `50px`;
                    }
                  });
			} catch(err) {
				console.error(err);
			}
		}
    }

	ngOnInit() {
				
    }
    
    ctBarLabels() {
        return function ctBarLabels(chart) {
            if (chart instanceof Chartist.Bar) {
                chart.on('draw', function (data) {
                var barHorizontalCenter, barVerticalCenter, label, value;

                if (data.type === "bar") {
                    barHorizontalCenter = data.x1 + (data.element.width() * .5);
                    barVerticalCenter = data.y1 + (data.element.height() * -1) - 10;
                    value = data.element.attr('ct:value');
                    if (value !== '0') {
                    label = new Chartist.Svg('text');
                    label.text(value);
                    label.addClass("ct-barlabel");
                    label.attr({
                        x: barHorizontalCenter,
                        y: barVerticalCenter,
                        'text-anchor': 'middle'
                    });
                    return data.group.append(label);
                    }
                }
                });
            }
        };
    };

}
import { UserState, UserActionTypes, ADD_USER, UPDATE_USER, DELETE_USER, SEARCH_USER_TABLE, GO_TO_PAGE_USER_TABLE, SET_PAGE_SIZE_USER_TABLE, SORT_USER_TABLE, SELECT_USER_CUSTOM_FIELD, UN_SELECT_USER_CUSTOM_FIELD, ADD_USER_CUSTOM_FIELD, DELETE_USER_CUSTOM_FIELD, UPDATE_USER_CUSTOM_FIELD, SELECT_USER_CUSTOM_FIELD_OPTION, UN_SELECT_USER_CUSTOM_FIELD_OPTION, RE_ORDER_USER_CUSTOM_FIELD_OPTION, ADD_USER_CUSTOM_FIELD_OPTION, DELETE_USER_CUSTOM_FIELD_OPTION, UPDATE_USER_CUSTOM_FIELD_OPTION, UPDATE_USER_DATA, UPDATE_USER_WORKFLOWS, UPDATE_USER_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_USER_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_USER_CUSTOM_FIELD_PIECE, REGISTER_USER_CUSTOM_FIELD_VARIABLE, UPDATE_USER_CUSTOM_FIELD_DATA, IUser, FILTER_USER_TABLE, UPDATE_USERS_DATA, UPDATE_USER_CUSTOM_FIELDS_DATA, UPDATE_USER_CUSTOM_FIELD_OPTIONS_DATA, SYNCHRONIZE_USERS_DATA, SYNCHRONIZE_USER_CUSTOM_FIELDS_DATA, SYNCHRONIZE_USER_CUSTOM_FIELD_OPTIONS_DATA, CLEAR_USERS_DELTA, UN_ARCHIVE_USER, INSTANTIATE_USER_CUSTOM_FIELD, INSTANTIATE_USER_CUSTOM_FIELD_OPTION, SET_IS_FILTERING_FOR_USER_TABLE, SET_IS_SHOWING_ADD_FORM_FOR_USERS, SET_IS_SHOWING_CSV_FORM_FOR_USERS, SET_IS_SHOWING_FILTER_FORM_FOR_USERS, SET_IS_SHOWING_MODIFY_FORM_FOR_USERS, UPDATE_USER_COMPUTED_FIELD_DATA, BULK_UPDATE_USER_COMPUTED_FIELD_DATA } from './types';
import { selectCustomField, unSelectCustomField, addCustomField, deleteCustomField, updateCustomField, selectCustomFieldOption, unSelectCustomFieldOption, reOrderCustomFieldOptions, addCustomFieldOption, deleteCustomFieldOption, updateCustomFieldOption, updateStartPieceForCustomField, setIsolatedPieceForCustomField, removeIsolatedPieceForCustomField, registerVariableForCustomField, updateCustomFields, updateCustomFieldOptions, synchronizeCustomFields, synchronizeCustomFieldOptions, clearDeltaForCustomFields } from '../custom-fields';
import uuid from 'uuid';
import { addEntity, deleteEntity, updateEntityWithCustomFields, updateCustomFieldsForEntity, updateEntries, synchronizeEntriesForCustomModels, clearDelta, unArchiveEntity, synchronizeReverseLinks } from '../normalized-model';
import moment from 'moment';
import { IWorkflow, SYNCHRONIZE_WORKFLOWS_DATA } from '../workflows/types';
import { FieldType } from '../custom-fields/types';

const initialState: UserState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],

    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    pageSize: 25,
    currentPageNumber: 1,

    isFiltering: false,

    markedForIndex: [],

    filters: {
        projects: [],
        levels: [],
        roles: [],
        locations: [],
        unsynced: false,
        areLowerLevelsIncluded: true,
        archived: false,
        customFields: {}
    },
    sort: {
        column: undefined,
        order: 'ASC',
    },
    searchTerm: undefined,

    customFields: {
        byId: {},
        allFields: []
    },
    customFieldOptions: {
        byId: {},
        allOptions: []
    },
    selectedField: undefined,
    selectedOption: undefined,
    createdCustomFieldIds: new Set(),
    updatedCustomFieldIds: new Set(),
    deletedCustomFieldIds: new Set(),
    createdCustomFieldOptionIds: new Set(),
    updatedCustomFieldOptionIds: new Set(),
    deletedCustomFieldOptionIds: new Set(),

    reOrderedCustomFieldOptions: {},

    nameFieldId: '',
    subTitleFieldId: '',
    locationFieldId: '',

    isShowingAddForm: false,
    isShowingModifyForm: false,
    isShowingFilterForm: false,
    isShowingCSVForm: false,
};

function createDefaultCustomFields(state: UserState): UserState {

    const nameId = uuid.v4();
    const subTitleId = uuid.v4();
    const locationId = uuid.v4();
    const now = moment.utc().format();

    let newState = {
        ...state,
        customFields: {
            byId: {
                [nameId]: {
                    id: nameId,
                    name: 'Name',
                    parentId: undefined,
                    type: FieldType.TEXT,
                    isComputed: false,
                    isInTable: true,
                    isEditable: true,
                    isDeletable: false,
                    seedEntityVariable: uuid.v4(),
                    choices: [],

                    variables: [],
                    isolatedPieces: [],

                    createdTime: now,
                    lastUpdatedTime: now,
                },
                [subTitleId]: {
                    id: subTitleId,
                    name: 'Sub Title',
                    parentId: undefined,
                    type: FieldType.TEXT,
                    isComputed: false,
                    isInTable: false,
                    isEditable: true,
                    isDeletable: false,
                    seedEntityVariable: uuid.v4(),
                    choices: [],

                    variables: [],
                    isolatedPieces: [],

                    createdTime: now,
                    lastUpdatedTime: now,
                },
                [locationId]: {
                    id: locationId,
                    name: 'Last seen',
                    parentId: undefined,
                    type: FieldType.LOCATION,
                    isComputed: false,
                    isInTable: false,
                    isEditable: false,
                    isDeletable: false,
                    seedEntityVariable: uuid.v4(),
                    choices: [],

                    variables: [],
                    isolatedPieces: [],

                    createdTime: now,
                    lastUpdatedTime: now,
                }
            },
            allFields: [nameId, subTitleId, locationId]
        },
        nameFieldId: nameId,
        subTitleFieldId: subTitleId,
        locationFieldId: locationId,
        createdCustomFieldIds: new Set([nameId, subTitleId, locationId]),
    };

    return newState;
}

export function usersReducer(state = initialState, action: UserActionTypes): UserState {
    let newState: UserState;

    switch (action.type) {

        case ADD_USER:
            return addEntity<UserState, IUser>(state, action.payload);

        case UPDATE_USER:
            return updateEntityWithCustomFields<UserState, IUser>(state, action.payload, action.currentTime);

        case DELETE_USER:
            return deleteEntity<UserState, IUser>(state, action.id, action.currentTime);

        case UN_ARCHIVE_USER:
            return unArchiveEntity<UserState, IUser>(state, action.id, action.currentTime);

        case UPDATE_USER_WORKFLOWS:
            const doesWorkflowTypeExist = action.workflowTypeId in state.byId[action.userId].workflows;
            let connectedWorkflows: Array<string>;

            if (doesWorkflowTypeExist) {
                connectedWorkflows = state.byId[action.userId].workflows[action.workflowTypeId];
            } else {
                connectedWorkflows = [];
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.userId]: {
                        ...state.byId[action.userId],
                        workflows: {
                            ...state.byId[action.userId].workflows,
                            [action.workflowTypeId]: connectedWorkflows.concat([action.workflowId]),
                        }
                    }
                }
            }

        case UPDATE_USER_CUSTOM_FIELD_DATA:
        case UPDATE_USER_COMPUTED_FIELD_DATA:
            return updateCustomFieldsForEntity<UserState, IUser>(state, action.userId, action.customFieldData, action.currentTime);

        case BULK_UPDATE_USER_COMPUTED_FIELD_DATA:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                },
                updatedIds: new Set([...state.updatedIds, ...action.payload.map(userData => userData.userId)]),
            }

            for (const userData of action.payload) {
                state.byId[userData.userId] = {
                    ...state.byId[userData.userId],
                    customFields: {
                        ...state.byId[userData.userId].customFields,
                        ...userData.customFieldData,
                    },
                };
            }

            return state;

        case SEARCH_USER_TABLE:
            return {
                ...state,
                searchTerm: action.searchTerm,
                currentPageNumber: 1,
            }

        case FILTER_USER_TABLE:
            return {
                ...state,
                filters: {
                    projects: action.projects,
                    levels: action.levels,
                    roles: action.roles,
                    locations: action.locations,
                    unsynced: action.unsynced,
                    areLowerLevelsIncluded: action.areLowerLevelsIncluded,
                    archived: action.archived,
                    customFields: action.customFields,
                },
                currentPageNumber: 1,
            }

        case SET_IS_FILTERING_FOR_USER_TABLE:
            return {
                ...state,
                isFiltering: action.isFiltering,
            };

        case GO_TO_PAGE_USER_TABLE:
            return {
                ...state,
                currentPageNumber: action.pageNumber,
            }

        case SET_PAGE_SIZE_USER_TABLE:
            return {
                ...state,
                pageSize: action.pageSize,
            }

        case SORT_USER_TABLE:
            return {
                ...state,
                sort: {
                    column: action.column,
                    order: action.order
                }
            }

        case SELECT_USER_CUSTOM_FIELD:
            return selectCustomField<UserState>(state, action.id);

        case UN_SELECT_USER_CUSTOM_FIELD:
            return unSelectCustomField<UserState>(state);

        case ADD_USER_CUSTOM_FIELD:
            return addCustomField<UserState>(state, action.payload, undefined, action.currentTime);

        case INSTANTIATE_USER_CUSTOM_FIELD:
            return addCustomField<UserState>(state, action.payload, undefined, action.currentTime);

        case DELETE_USER_CUSTOM_FIELD:
            return deleteCustomField<UserState>(state, action.id, action.currentTime);

        case UPDATE_USER_CUSTOM_FIELD:
            return updateCustomField<UserState>(state, action.payload, action.currentTime);


        case UPDATE_USER_CUSTOM_FIELD_START_PIECE:
            return updateStartPieceForCustomField<UserState>(state, action.customFieldId, action.payload, action.currentTime);

        case SET_ISOLATED_USER_CUSTOM_FIELD_PIECE:
            return setIsolatedPieceForCustomField<UserState>(state, action.customFieldId, action.payload, action.currentTime);

        case REMOVE_ISOLATED_USER_CUSTOM_FIELD_PIECE:
            return removeIsolatedPieceForCustomField<UserState>(state, action.customFieldId, action.pieceId, action.currentTime);

        case REGISTER_USER_CUSTOM_FIELD_VARIABLE:
            return registerVariableForCustomField<UserState>(state, action.customFieldId, action.variableId, action.currentTime);


        case SELECT_USER_CUSTOM_FIELD_OPTION:
            return selectCustomFieldOption<UserState>(state, action.id);

        case UN_SELECT_USER_CUSTOM_FIELD_OPTION:
            return unSelectCustomFieldOption<UserState>(state);

        case RE_ORDER_USER_CUSTOM_FIELD_OPTION:
            return reOrderCustomFieldOptions<UserState>(state, action.sourceIndex, action.destinationIndex, action.parentId);

        case ADD_USER_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<UserState>(state, action.payload, action.parentId, action.currentTime);

        case INSTANTIATE_USER_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<UserState>(state, action.payload, action.parentId, action.currentTime);

        case DELETE_USER_CUSTOM_FIELD_OPTION:
            return deleteCustomFieldOption<UserState>(state, action.id, action.parentId, action.currentTime);

        case UPDATE_USER_CUSTOM_FIELD_OPTION:
            return updateCustomFieldOption<UserState>(state, action.payload, action.currentTime);

        case UPDATE_USERS_DATA:
            return updateEntries<UserState, IUser>(state, action.data);

        case UPDATE_USER_CUSTOM_FIELDS_DATA:
            newState = updateCustomFields<UserState>(state, action.data);
            if (action.data.length >= 3) {
                newState.nameFieldId = action.data[0].id;
                newState.subTitleFieldId = action.data[1].id;
                newState.locationFieldId = action.data[2].id;
            } else {
                newState = createDefaultCustomFields(newState);
            }
            return newState;

        case UPDATE_USER_CUSTOM_FIELD_OPTIONS_DATA:
            return updateCustomFieldOptions<UserState>(state, action.data);

        case SET_IS_SHOWING_ADD_FORM_FOR_USERS:
            return {
                ...state,
                isShowingAddForm: action.showValue,
            }

        case SET_IS_SHOWING_MODIFY_FORM_FOR_USERS:
            return {
                ...state,
                isShowingModifyForm: action.showValue,
            }

        case SET_IS_SHOWING_FILTER_FORM_FOR_USERS:
            return {
                ...state,
                isShowingFilterForm: action.showValue,
            }

        case SET_IS_SHOWING_CSV_FORM_FOR_USERS:
            return {
                ...state,
                isShowingCSVForm: action.showValue,
            }

        case SYNCHRONIZE_USERS_DATA:
            return synchronizeEntriesForCustomModels<UserState, IUser>(state, action.data);

        case SYNCHRONIZE_WORKFLOWS_DATA:
            const workflowsAffiliatedWithUsers = action.data.filter(workflow => workflow.user in state.byId);
            state = synchronizeReverseLinks<UserState, IUser, IWorkflow>(state, workflowsAffiliatedWithUsers, 'affiliatedEntity', 'workflows', 'type');
            return state;

        case SYNCHRONIZE_USER_CUSTOM_FIELDS_DATA:
            return synchronizeCustomFields<UserState>(state, action.data);

        case SYNCHRONIZE_USER_CUSTOM_FIELD_OPTIONS_DATA:
            return synchronizeCustomFieldOptions<UserState>(state, action.data);

        case CLEAR_USERS_DELTA:
            newState = clearDelta<UserState, IUser>(state);
            newState = clearDeltaForCustomFields<UserState>(newState);
            return newState;

        case UPDATE_USER_DATA:
            return {
                ...action.data,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
                filters: state.filters,
                createdCustomFieldIds: state.createdCustomFieldIds,
                updatedCustomFieldIds: state.updatedCustomFieldIds,
                deletedCustomFieldIds: state.deletedCustomFieldIds,
                createdCustomFieldOptionIds: state.createdCustomFieldOptionIds,
                updatedCustomFieldOptionIds: state.updatedCustomFieldOptionIds,
                deletedCustomFieldOptionIds: state.deletedCustomFieldOptionIds,
            }

        default:
            return state;
    }
}
import { ADD_ERROR, AddErrorAction } from './types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export function addAppError(message: string, context: string, location: string, userId: string): AddErrorAction {
    const now = moment.utc().format();

    return {
        type: ADD_ERROR,
        payload: {
            id: uuidv4(),
            message,
            context,
            userId,
            location,
            createdTime: now,
            lastUpdatedTime: now,
        },
    }
}
import moment from "moment";
import { ReOrderedListsForEntity, PiecePositionState, VariableValueType } from "../../helpers/common-types";


export enum FieldType {
    NUMBER = 'NUMBER',
    TEXT = 'TEXT',
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
    FILE = 'FILE',
    DATE = 'DATE',
    BOOLEAN = 'BOOLEAN',

    PHONE = 'PHONE',
    LOCATION = 'LOCATION',
}

export type CustomFieldValueType = string|Array<string>|undefined|boolean|number;

export type CustomFieldDataHolder = {
    [fieldId: string]: CustomFieldValueType
}

export interface INewFieldChoiceData {
    name: string,
}

export interface IUpdateableFieldChoiceData extends INewFieldChoiceData {
    id: string,
}

export interface FieldChoice extends IUpdateableFieldChoiceData {
    archived?: boolean,
    parentId: string,

    createdTime: string,
    lastUpdatedTime: string,
}

export interface INewCustomFieldData {
    name: string,
    type: FieldType,
    isComputed: boolean,
    isInTable: boolean,
    isEditable: boolean,
    isDeletable: boolean,
    seedEntityVariable: string,
}

export interface IUpdateableCustomFieldData extends INewCustomFieldData {
    id: string,
}

export interface CustomField extends IUpdateableCustomFieldData {
    archived?: boolean,
    parentId: string|undefined,
    choices: Array<string>,

    startPiece?: PiecePositionState,
    variables: Array<string>,
    isolatedPieces: Array<PiecePositionState>,

    createdTime: string,
    lastUpdatedTime: string,
}

export type CustomFieldDataForIndividualMembers = {
    [memberId: string]: CustomFieldValueType
};

export type WorkflowTypeCustomFieldDataHolder = {
    [fieldId: string]: CustomFieldValueType|CustomFieldDataForIndividualMembers
}

export interface INewWorkflowTypeCustomFieldData extends INewCustomFieldData {
    affiliation: 'member'|'group',

    seedAffiliationVariable?: string,
}

export interface IUpdateableWorkflowTypeCustomFieldData extends INewWorkflowTypeCustomFieldData {
    id: string,
}

export interface WorkflowTypeCustomField extends IUpdateableWorkflowTypeCustomFieldData {
    archived?: boolean,
    parentId: string|undefined,
    startPiece?: PiecePositionState,
    variables: Array<string>,
    isolatedPieces: Array<PiecePositionState>,
    choices: Array<string>,
    createdTime: string,
    lastUpdatedTime: string,
}

export type WorkflowTypeCustomFieldDataType = {
    byId: {
        [id: string]: WorkflowTypeCustomField
    },
    allFields: Array<string>,
}

export interface WorkflowTypeCustomFieldState extends CustomFieldState {
    customFields: WorkflowTypeCustomFieldDataType,
}

export type CustomFieldDataType = {
    byId: {
        [id: string]: CustomField
    },
    allFields: Array<string>,
}

export type CustomFieldOptionsDataType = {
    byId: {
        [id: string]: FieldChoice
    },
    allOptions: Array<string>,
}

export interface CustomFieldState {
    selectedField: string|undefined,
    selectedOption: string|undefined,
    customFields: CustomFieldDataType,
    customFieldOptions: CustomFieldOptionsDataType,

    createdCustomFieldIds: Set<string>,
    updatedCustomFieldIds: Set<string>,
    deletedCustomFieldIds: Set<string>,
    createdCustomFieldOptionIds: Set<string>,
    updatedCustomFieldOptionIds: Set<string>,
    deletedCustomFieldOptionIds: Set<string>,

    reOrderedCustomFieldOptions: ReOrderedListsForEntity,
}



export function getValidCustomFieldValueForInput(customField: CustomField, dataInput: VariableValueType, customFieldOptions: CustomFieldOptionsDataType) {
    let customFieldValue: CustomFieldValueType;

    if (dataInput === '-') {
        return undefined;
    }

    switch (customField.type) {
        case FieldType.SINGLE_SELECT:
            if (typeof dataInput === 'string') {
                const selectedOption = customField.choices.find(optionId => {
                    const option = customFieldOptions.byId[optionId];

                     return option.name === dataInput;
                });

                if (typeof selectedOption !== 'undefined') {
                    customFieldValue = customFieldOptions.byId[selectedOption].name;
                } else {
                    throw new Error(`${customField.name}: Value not recognized`);
                }
            }
            break;
        
        case FieldType.MULTI_SELECT:
            if (Array.isArray(dataInput)) {

                const options = customField.choices.map(optionId => customFieldOptions.byId[optionId]);
                const selectedOptionIds: Array<string> = [];

                for (const value of dataInput) {
                    const option = options.find(option => option.name === value);
                    if (typeof option === 'undefined') {
                        throw new Error(`${customField.name}: Value not recognized`);
                    }

                    selectedOptionIds.push(option.name);
                }

                customFieldValue = selectedOptionIds;
            }
            break;
        
        case FieldType.BOOLEAN:
            if (typeof dataInput === 'boolean') {
                customFieldValue = dataInput;
            }
            break;
        
        case FieldType.LOCATION:
            if (typeof dataInput === 'string') {
                const coOrdinates = dataInput.split(' ').map(word => word.trim());

                if (coOrdinates.length === 2 && !(isNaN(Number(coOrdinates[0])) || isNaN(Number(coOrdinates[1])))) {
                    customFieldValue = `${coOrdinates[0]} ${coOrdinates[1]}`;
                }
            }

            break;
        
        case FieldType.PHONE:
            if (typeof dataInput === 'string') {
                const phoneNumber = dataInput.split(' ').map(word => word.trim());

                if (phoneNumber.length === 2 && !isNaN(Number(phoneNumber[1]))) {
                    const prefix = phoneNumber[0].startsWith('+') ? '' : '+';
                    customFieldValue = `${prefix + phoneNumber[0]} ${phoneNumber[1]}`;
                }
            }
            break;

        case FieldType.NUMBER:
            if (!isNaN(Number(dataInput))) {
                customFieldValue = Number(dataInput);
            }
            break;

        case FieldType.DATE:
            if (typeof dataInput === 'string') {
                if (moment(dataInput, 'YYYY-MM-DD', true).isValid()) {
                    customFieldValue = dataInput;
                } else if(moment(dataInput, 'DD MMM YYYY', true).isValid()) {
                    customFieldValue = moment(dataInput, 'DD MMM YYYY').format('YYYY-MM-DD');
                } else if(moment(dataInput, 'D MMM YYYY', true).isValid()) {
                    customFieldValue = moment(dataInput, 'D MMM YYYY').format('YYYY-MM-DD');
                } else if (dataInput === 'Invalid date') {
                    customFieldValue = undefined;
                }
            }
            break;
        
        case FieldType.TEXT:
            if (typeof dataInput === 'string' || typeof dataInput === 'number') {
                customFieldValue = String(dataInput);
            }
            break;
    }

    return customFieldValue;
}
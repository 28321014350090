import moment from 'moment';
import { getReadableDataForCustomField } from '../store/custom-fields';
import { CustomField, WorkflowTypeCustomField, CustomFieldOptionsDataType, CustomFieldValueType, FieldType } from '../store/custom-fields/types';
import { IGroup } from '../store/groups/types';
import { IMember } from '../store/members/types';
import { LevelState } from '../store/structure/level/types';
import { LocationState } from '../store/structure/location/types';
import { ProjectState } from '../store/structure/project/types';
import { RoleState } from '../store/structure/role/types';
import { ApplicationState } from '../store/types';
import { IUser } from '../store/users/types';
import { WidgetData } from '../store/widgets/types';
import { IWorkflow } from '../store/workflows/types';
import { TableHeading, TableRow } from './common-types';
import { getAncestorChainOfLocation } from './locations';
import { isUUID } from './utilities';


function getCommonCustomFieldValue(entity: IUser | IMember | IGroup, type: 'user' | 'member' | 'group', customField: CustomField, optionsData: CustomFieldOptionsDataType, state: ApplicationState) {
    let customFieldValue = entity.customFields[customField.id];

    return getReadableDataForCustomField(customFieldValue, customField, entity.id, type, state);
}

function getWorkflowCustomFieldValue(workflow: IWorkflow, customField: WorkflowTypeCustomField, state: ApplicationState) {
    const currentProcessState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
    let customFieldValue = currentProcessState.customFields[customField.id] as CustomFieldValueType;

    return getReadableDataForCustomField(customFieldValue, customField, workflow.id, 'workflow', state);
}

function getReadableProject(projectId: string, projectsData: ProjectState) {
    if (!(projectId in projectsData.byId)) {
        return undefined;
    }

    return projectsData.byId[projectId].name;
}

function getReadableLevel(levelId: string, levelsData: LevelState) {
    if (!(levelId in levelsData.byId)) {
        return undefined;
    }

    return levelsData.byId[levelId].name;
}

function getReadableRole(roleId: string, rolesData: RoleState) {
    if (!(roleId in rolesData.byId)) {
        return undefined;
    }

    return rolesData.byId[roleId].name;
}

function getReadableLocation(locationId: string, locationState: LocationState) {
    const locationChain = getAncestorChainOfLocation(locationId).slice().reverse().concat([locationId]);
    return locationChain.map(locationId => {
        if (!(locationId in locationState.byId)) {
            return undefined;
        }

        return locationState.byId[locationId].name
    }).filter(location => !!location).join(' > ');
}

export interface ChartData {
    chartLabels: Array<string>;
    chartData: Array<number>;
}

export function getChartData(
    applicationState: ApplicationState,
    entityIds: Array<string>,
    type: string,
    customFields: Array<string>,
    xAxis: string | undefined,
    yAxis: string | undefined,
    yAxisAggregation: 'sum' | 'average' | undefined,
    typeId?: string,
    systemFields?: Array<string>,
): ChartData {
    const usersData = applicationState.users;
    const rolesData = applicationState.structure.roles;
    const membersData = applicationState.members;
    const groupsData = applicationState.groups;
    const workflowsData = applicationState.workflows;

    let chartLabels: Array<string> = [];
    let chartData: Array<number> = [];
    const chartCounts: { [key: string]: number } = {};
    const chartValues: { [key: string]: Array<number> } = {};

    let xAxisValue: string, yAxisValue: string;

    let tableFields: Array<string>

    if (xAxis && yAxis) {
        tableFields = [xAxis, yAxis];
    } else if (xAxis) {
        tableFields = [xAxis];
    } else {
        const selectedSystemFields = systemFields ? systemFields : [];
        tableFields = selectedSystemFields.concat(customFields);
    }

    let xAxisFieldName = '';
    let yAxisFieldName = '';

    switch (type) {
        case 'User':
            entityIds.forEach((userId => {
                const user = usersData.byId[userId];
                let userName = user.customFields[usersData.nameFieldId];

                userName = getReadableDataForCustomField(userName, usersData.customFields.byId[usersData.nameFieldId], user.id, 'user', applicationState);

                if (xAxis && yAxis) {
                    const xAxisField = usersData.customFields.byId.hasOwnProperty(xAxis) ? usersData.customFields.byId[xAxis] : rolesData.customFields.byId[xAxis];
                    const yAxisField = usersData.customFields.byId.hasOwnProperty(yAxis) ? usersData.customFields.byId[yAxis] : rolesData.customFields.byId[yAxis];

                    xAxisFieldName = xAxisField.name;
                    yAxisFieldName = yAxisField.name;

                    const xAxisFieldOptions = usersData.customFields.byId.hasOwnProperty(xAxis) ? usersData.customFieldOptions : rolesData.customFieldOptions;
                    const yAxisFieldOptions = usersData.customFields.byId.hasOwnProperty(yAxis) ? usersData.customFieldOptions : rolesData.customFieldOptions;

                    xAxisValue = getCommonCustomFieldValue(user, 'user', xAxisField, xAxisFieldOptions, applicationState);
                    yAxisValue = getCommonCustomFieldValue(user, 'user', yAxisField, yAxisFieldOptions, applicationState);

                    if (!isNaN(Number(yAxisValue))) {

                        (Array.isArray(xAxisValue) ? xAxisValue : [xAxisValue]).forEach(value => {
                            if (value in chartValues) {
                                chartValues[value].push(Number(yAxisValue));
                            } else {
                                chartValues[value] = [Number(yAxisValue)];
                            }
                        });

                    }
                } else if (xAxis || tableFields.length === 1) {
                    const fieldId = xAxis ? xAxis : tableFields[0];
                    let readableValue: string;

                    if (!isUUID(fieldId)) {
                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(user.createdTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableCreated;
                                break;
                            case 'last_updated':
                                const readableLastUpdated = moment(user.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableLastUpdated;
                                break;

                            case 'projects':
                                const readableProjects = user.projects.map(projectId => getReadableProject(projectId, applicationState.structure.projects)).filter(project => !!project).join(', ');
                                readableValue = readableProjects;
                                break;
                            case 'levels':
                                const readableLevels = user.levels.map(levelId => getReadableLevel(levelId, applicationState.structure.levels)).filter(level => !!level).join(', ');
                                readableValue = readableLevels;
                                break;
                            case 'roles':
                                const readableRoles = user.roles.map(roleId => getReadableRole(roleId, applicationState.structure.roles)).filter(role => !!role).join(', ');
                                readableValue = readableRoles;
                                break;
                            case 'locations':
                                const readableLocations = user.locations.map(locationId => getReadableLocation(locationId, applicationState.structure.locations)).filter(location => !!location).join(', ');
                                readableValue = readableLocations;
                                break;
                            default:
                                throw new Error('Unknown system field');
                        }
                    } else {
                        const field = usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFields.byId[fieldId] : rolesData.customFields.byId[fieldId];
                        const fieldOptions = usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFieldOptions : rolesData.customFieldOptions;
                        readableValue = getCommonCustomFieldValue(user, 'user', field, fieldOptions, applicationState);
                    }


                    (Array.isArray(readableValue) ? readableValue : [readableValue]).forEach(value => {
                        if (value in chartCounts) {
                            chartCounts[value] += 1;
                        } else {
                            chartCounts[value] = 1;
                        }
                    });
                }
            }));

            if (xAxis && yAxis) {
                for (let property in chartValues) {
                    if (chartValues.hasOwnProperty(property)) {
                        chartLabels.push(property);

                        switch (yAxisAggregation) {
                            case 'sum':
                                const sum = chartValues[property].reduce((prev, current) => prev + current, 0);
                                chartData.push(sum);
                                break;
                            case 'average':
                                const average = chartValues[property].reduce((prev, current) => prev + current, 0) / chartValues[property].length;
                                chartData.push(average);
                                break;
                            default:
                                chartData.push(chartValues[property].length);
                        }
                    }
                }
            } else if (xAxis || tableFields.length === 1) {
                const fieldId = xAxis ? xAxis : tableFields[0];

                if (isUUID(fieldId)) {
                    const field = usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFields.byId[fieldId] : rolesData.customFields.byId[fieldId];
                    const fieldOptions = usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFieldOptions : rolesData.customFieldOptions;

                    if (field.type === FieldType.SINGLE_SELECT || field.type === FieldType.MULTI_SELECT) {
                        const choiceNames = field.choices
                            .filter(choiceId => choiceId in fieldOptions.byId && !fieldOptions.byId[choiceId].archived)
                            .map(choiceId => fieldOptions.byId[choiceId].name);

                        for (const choiceName of choiceNames) {
                            chartLabels.push(choiceName);
                            chartData.push(choiceName in chartCounts ? chartCounts[choiceName] : 0);
                        }

                        for (let property in chartCounts) {
                            if (chartCounts.hasOwnProperty(property) && !choiceNames.includes(property)) {
                                chartLabels.push(property);
                                chartData.push(chartCounts[property]);
                            }
                        }
                        break;
                    }
                }

                for (let property in chartCounts) {
                    if (chartCounts.hasOwnProperty(property)) {
                        chartLabels.push(property);
                        chartData.push(chartCounts[property]);
                    }
                }
            }

            break;

        case 'Member':
            entityIds.forEach((memberId => {
                const member = membersData.byId[memberId];
                const memberType = membersData.types.byId[member.type];

                if (xAxis && yAxis) {
                    const xAxisField = membersData.types.customFields.byId[xAxis];
                    const yAxisField = membersData.types.customFields.byId[yAxis];

                    xAxisFieldName = xAxisField.name;
                    yAxisFieldName = yAxisField.name;

                    xAxisValue = getCommonCustomFieldValue(member, 'member', xAxisField, membersData.types.customFieldOptions, applicationState);
                    yAxisValue = getCommonCustomFieldValue(member, 'member', yAxisField, membersData.types.customFieldOptions, applicationState);

                    if (!isNaN(Number(yAxisValue))) {

                        (Array.isArray(xAxisValue) ? xAxisValue : [xAxisValue]).forEach(value => {
                            if (value in chartValues) {
                                chartValues[value].push(Number(yAxisValue));
                            } else {
                                chartValues[value] = [Number(yAxisValue)];
                            }
                        });

                    }
                } else if (xAxis || tableFields.length === 1) {
                    const fieldId = xAxis ? xAxis : tableFields[0];
                    let readableValue: string;

                    if (!isUUID(fieldId)) {
                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(member.createdTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableCreated;
                                break;

                            case 'last_updated':
                                const readableLastUpdated = moment(member.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableLastUpdated;
                                break;

                            case 'location':
                                const readableLocation = getReadableLocation(member.location, applicationState.structure.locations);
                                readableValue = readableLocation;
                                break;

                            case 'name':
                                const readableName = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[memberType.nameFieldId], membersData.types.customFieldOptions, applicationState);
                                readableValue = readableName;
                                break;

                            case 'subtitle':
                                const readableSubTitle = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[memberType.subTitleFieldId], membersData.types.customFieldOptions, applicationState);
                                readableValue = readableSubTitle;
                                break;

                            case 'last_seen':
                                const readableLastSeen = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[memberType.locationFieldId], membersData.types.customFieldOptions, applicationState);
                                readableValue = readableLastSeen;
                                break;
                            default:
                                throw new Error('Unknown system field');
                        }
                    } else {
                        readableValue = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[fieldId], membersData.types.customFieldOptions, applicationState);
                    }

                    (Array.isArray(readableValue) ? readableValue : [readableValue]).forEach(value => {
                        if (value in chartCounts) {
                            chartCounts[value] += 1;
                        } else {
                            chartCounts[value] = 1;
                        }
                    });
                }
            }));

            if (xAxis && yAxis) {
                for (let property in chartValues) {
                    if (chartValues.hasOwnProperty(property)) {
                        chartLabels.push(property);

                        switch (yAxisAggregation) {
                            case 'sum':
                                const sum = chartValues[property].reduce((prev, current) => prev + current, 0);
                                chartData.push(sum);
                                break;
                            case 'average':
                                const average = chartValues[property].reduce((prev, current) => prev + current, 0) / chartValues[property].length;
                                chartData.push(average);
                                break;
                            default:
                                chartData.push(chartValues[property].length);
                        }
                    }
                }
            } else if (xAxis || tableFields.length === 1) {
                const fieldId = xAxis ? xAxis : tableFields[0];

                if (isUUID(fieldId)) {
                    const field = membersData.types.customFields.byId[fieldId];
                    if (field.type === FieldType.SINGLE_SELECT || field.type === FieldType.MULTI_SELECT) {
                        const choiceNames = field.choices
                            .filter(choiceId => choiceId in membersData.types.customFieldOptions.byId && !membersData.types.customFieldOptions.byId[choiceId].archived)
                            .map(choiceId => membersData.types.customFieldOptions.byId[choiceId].name);

                        for (const choiceName of choiceNames) {
                            chartLabels.push(choiceName);
                            chartData.push(choiceName in chartCounts ? chartCounts[choiceName] : 0);
                        }

                        for (let property in chartCounts) {
                            if (chartCounts.hasOwnProperty(property) && !choiceNames.includes(property)) {
                                chartLabels.push(property);
                                chartData.push(chartCounts[property]);
                            }
                        }
                        break;
                    }
                }

                for (let property in chartCounts) {
                    if (chartCounts.hasOwnProperty(property)) {
                        chartLabels.push(property);
                        chartData.push(chartCounts[property]);
                    }
                }
            }

            break;

        case 'Group':

            entityIds.forEach(((memberId, index) => {
                const group = groupsData.byId[memberId];
                const groupType = groupsData.types.byId[group.type];

                if (xAxis && yAxis) {
                    const xAxisField = groupsData.types.customFields.byId[xAxis];
                    const yAxisField = groupsData.types.customFields.byId[yAxis];

                    xAxisFieldName = xAxisField.name;
                    yAxisFieldName = yAxisField.name;

                    xAxisValue = getCommonCustomFieldValue(group, 'group', xAxisField, groupsData.types.customFieldOptions, applicationState);
                    yAxisValue = getCommonCustomFieldValue(group, 'group', yAxisField, groupsData.types.customFieldOptions, applicationState);

                    if (!isNaN(Number(yAxisValue))) {

                        (Array.isArray(xAxisValue) ? xAxisValue : [xAxisValue]).forEach(value => {
                            if (value in chartValues) {
                                chartValues[value].push(Number(yAxisValue));
                            } else {
                                chartValues[value] = [Number(yAxisValue)];
                            }
                        });

                    }
                } else if (xAxis || tableFields.length === 1) {
                    const fieldId = xAxis ? xAxis : tableFields[0];
                    let readableValue: string;

                    if (!isUUID(fieldId)) {
                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(group.createdTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableCreated;
                                break;

                            case 'last_updated':
                                const readableLastUpdated = moment(group.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableLastUpdated;
                                break;

                            case 'location':
                                const readableLocation = getReadableLocation(group.location, applicationState.structure.locations);
                                readableValue = readableLocation;
                                break;

                            case 'name':
                                const readableName = getCommonCustomFieldValue(group, 'group', groupsData.types.customFields.byId[groupType.nameFieldId], groupsData.types.customFieldOptions, applicationState);
                                readableValue = readableName;
                                break;

                            case 'subtitle':
                                const readableSubTitle = getCommonCustomFieldValue(group, 'group', groupsData.types.customFields.byId[groupType.subTitleFieldId], groupsData.types.customFieldOptions, applicationState);
                                readableValue = readableSubTitle;
                                break;
                            default:
                                throw new Error('Unknown system field');
                        }
                    } else {
                        readableValue = getCommonCustomFieldValue(group, 'group', groupsData.types.customFields.byId[fieldId], groupsData.types.customFieldOptions, applicationState);
                    }

                    (Array.isArray(readableValue) ? readableValue : [readableValue]).forEach(value => {
                        if (value in chartCounts) {
                            chartCounts[value] += 1;
                        } else {
                            chartCounts[value] = 1;
                        }
                    });
                }
            }));

            if (xAxis && yAxis) {
                for (let property in chartValues) {
                    if (chartValues.hasOwnProperty(property)) {
                        chartLabels.push(property);

                        switch (yAxisAggregation) {
                            case 'sum':
                                const sum = chartValues[property].reduce((prev, current) => prev + current, 0);
                                chartData.push(sum);
                                break;
                            case 'average':
                                const average = chartValues[property].reduce((prev, current) => prev + current, 0) / chartValues[property].length;
                                chartData.push(average);
                                break;
                            default:
                                chartData.push(chartValues[property].length);
                        }
                    }
                }
            } else if (xAxis || tableFields.length === 1) {
                const fieldId = xAxis ? xAxis : tableFields[0];

                if (isUUID(fieldId)) {
                    const field = groupsData.types.customFields.byId[fieldId];
                    if (field.type === FieldType.SINGLE_SELECT || field.type === FieldType.MULTI_SELECT) {
                        const choiceNames = field.choices
                            .filter(choiceId => choiceId in groupsData.types.customFields.byId && !groupsData.types.customFields.byId[choiceId].archived)
                            .map(choiceId => groupsData.types.customFields.byId[choiceId].name);

                        for (const choiceName of choiceNames) {
                            chartLabels.push(choiceName);
                            chartData.push(choiceName in chartCounts ? chartCounts[choiceName] : 0);
                        }

                        for (let property in chartCounts) {
                            if (chartCounts.hasOwnProperty(property) && !choiceNames.includes(property)) {
                                chartLabels.push(property);
                                chartData.push(chartCounts[property]);
                            }
                        }
                        break;
                    }
                }

                for (let property in chartCounts) {
                    if (chartCounts.hasOwnProperty(property)) {
                        chartLabels.push(property);
                        chartData.push(chartCounts[property]);
                    }
                }
            }

            break;

        case 'Workflow':

            entityIds.forEach(((workflowId, index) => {
                const workflow = workflowsData.byId[workflowId];
                const workflowType = workflowsData.types.byId[workflow.type];

                if (xAxis && yAxis) {
                    const xAxisField = workflowsData.types.customFields.byId[xAxis];
                    const yAxisField = workflowsData.types.customFields.byId[yAxis];

                    xAxisFieldName = xAxisField.name;
                    yAxisFieldName = yAxisField.name;

                    xAxisValue = getWorkflowCustomFieldValue(workflow, xAxisField, applicationState);
                    yAxisValue = getWorkflowCustomFieldValue(workflow, yAxisField, applicationState);

                    if (!isNaN(Number(yAxisValue))) {

                        (Array.isArray(xAxisValue) ? xAxisValue : [xAxisValue]).forEach(value => {
                            if (value in chartValues) {
                                chartValues[value].push(Number(yAxisValue));
                            } else {
                                chartValues[value] = [Number(yAxisValue)];
                            }
                        });

                    }
                } else if (xAxis || tableFields.length === 1) {
                    const fieldId = xAxis ? xAxis : tableFields[0];
                    let readableValue: string;

                    if (!isUUID(fieldId)) {
                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(workflow.createdTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableCreated;
                                break;

                            case 'last_updated':
                                const readableLastUpdated = moment(workflow.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableLastUpdated;
                                break;

                            case 'type':
                                readableValue = workflowType.name;
                                break;

                            case 'subtitle':
                                if (workflowType.subTitleFieldId) {
                                    const subtitleField = workflowsData.types.customFields.byId[workflowType.subTitleFieldId];
                                    const readableSubTitle = getWorkflowCustomFieldValue(workflow, subtitleField, applicationState);
                                    readableValue = readableSubTitle;
                                } else {
                                    // Code for pushing affiliation in subtitle
                                    if (workflowType.affiliation === 'none') {
                                        readableValue = '-';
                                    } else if (workflowType.affiliation === 'member') {
                                        const member = membersData.byId[workflow.affiliatedEntity];
                                        const memberType = membersData.types.byId[member.type];

                                        const nameField = membersData.types.customFields.byId[memberType.nameFieldId];
                                        const nameFieldValue = getCommonCustomFieldValue(member, 'member', nameField, membersData.types.customFieldOptions, applicationState);

                                        const subtitleField = membersData.types.customFields.byId[memberType.subTitleFieldId];
                                        const subtitleFieldValue = getCommonCustomFieldValue(member, 'member', subtitleField, membersData.types.customFieldOptions, applicationState);

                                        readableValue = `${nameFieldValue} (${subtitleFieldValue})`;
                                    } else if (workflowType.affiliation === 'group') {
                                        const group = groupsData.byId[workflow.affiliatedEntity];
                                        const groupType = groupsData.types.byId[group.type];

                                        const nameField = groupsData.types.customFields.byId[groupType.nameFieldId];
                                        const nameFieldValue = getCommonCustomFieldValue(group, 'group', nameField, groupsData.types.customFieldOptions, applicationState);

                                        const subtitleField = groupsData.types.customFields.byId[groupType.subTitleFieldId];
                                        const subtitleFieldValue = getCommonCustomFieldValue(group, 'group', subtitleField, membersData.types.customFieldOptions, applicationState);

                                        readableValue = `${nameFieldValue} (${subtitleFieldValue})`;
                                    } else {
                                        readableValue = '-';
                                    }
                                }
                                break;

                            case 'status':
                                try {
                                    const readableStatus = workflowsData.types.statuses.byId[workflow.status].name;
                                    readableValue = readableStatus;
                                } catch (e) {
                                    readableValue = '-';
                                    console.log(JSON.stringify(workflow));
                                }
                                break;

                            case 'is_terminal':
                                try {
                                    const readableIsTerminal = workflowsData.types.statuses.byId[workflow.status].isTerminal ? 'Yes' : 'No';
                                    readableValue = readableIsTerminal;
                                } catch (e) {
                                    readableValue = '-';
                                    console.log(JSON.stringify(workflow));
                                }
                                break;

                            case 'due_date':
                                const readableDueDate = moment(workflow.dueDate).format('MMM DD, YYYY');
                                readableValue = readableDueDate;
                                break;

                            case 'user':
                                const user = usersData.byId[workflow.user];

                                const nameField = usersData.customFields.byId[usersData.nameFieldId];
                                const nameFieldValue = getCommonCustomFieldValue(user, 'user', nameField, usersData.customFieldOptions, applicationState);

                                const subtitleField = usersData.customFields.byId[usersData.subTitleFieldId];
                                const subtitleFieldValue = getCommonCustomFieldValue(user, 'user', subtitleField, usersData.customFieldOptions, applicationState);

                                readableValue = `${nameFieldValue} (${subtitleFieldValue})`;
                                break;

                            case 'location':
                                if (workflowType.affiliation === 'none') {
                                    const user = usersData.byId[workflow.user];
                                    const locationNames = user.locations.map(locationId => {
                                        const location = applicationState.structure.locations.byId[locationId];
                                        return location.name;
                                    });
                                    readableValue = locationNames.join(', ');
                                } else if (workflowType.affiliation === 'member') {
                                    const member = membersData.byId[workflow.affiliatedEntity];
                                    const locationName = applicationState.structure.locations.byId[member.location].name;
                                    readableValue = locationName;
                                } else if (workflowType.affiliation === 'group') {
                                    const group = groupsData.byId[workflow.affiliatedEntity];
                                    const locationName = applicationState.structure.locations.byId[group.location].name;
                                    readableValue = locationName;
                                } else {
                                    readableValue = '-';
                                }
                                break;

                            case 'affiliation':

                                if (workflowType.affiliation === 'none') {
                                    readableValue = '-';
                                } else if (workflowType.affiliation === 'member') {
                                    const member = membersData.byId[workflow.affiliatedEntity];
                                    const memberType = membersData.types.byId[member.type];

                                    const nameField = membersData.types.customFields.byId[memberType.nameFieldId];
                                    const nameFieldValue = getCommonCustomFieldValue(member, 'member', nameField, membersData.types.customFieldOptions, applicationState);

                                    const subtitleField = membersData.types.customFields.byId[memberType.subTitleFieldId];
                                    const subtitleFieldValue = getCommonCustomFieldValue(member, 'member', subtitleField, membersData.types.customFieldOptions, applicationState);

                                    readableValue = `${nameFieldValue} (${subtitleFieldValue})`;
                                } else if (workflowType.affiliation === 'group') {
                                    const group = groupsData.byId[workflow.affiliatedEntity];
                                    const groupType = groupsData.types.byId[group.type];

                                    const nameField = groupsData.types.customFields.byId[groupType.nameFieldId];
                                    const nameFieldValue = getCommonCustomFieldValue(group, 'group', nameField, groupsData.types.customFieldOptions, applicationState);

                                    const subtitleField = groupsData.types.customFields.byId[groupType.subTitleFieldId];
                                    const subtitleFieldValue = getCommonCustomFieldValue(group, 'group', subtitleField, membersData.types.customFieldOptions, applicationState);

                                    readableValue = `${nameFieldValue} (${subtitleFieldValue})`;
                                } else {
                                    readableValue = '-';
                                }
                                break;

                            default:
                                throw new Error('Unknown system field');
                        }
                    } else {
                        readableValue = getWorkflowCustomFieldValue(workflow, workflowsData.types.customFields.byId[fieldId], applicationState);
                    }

                    (Array.isArray(readableValue) ? readableValue : [readableValue]).forEach(value => {
                        if (value in chartCounts) {
                            chartCounts[value] += 1;
                        } else {
                            chartCounts[value] = 1;
                        }
                    });
                }
            }));

            if (xAxis && yAxis) {
                for (let property in chartValues) {
                    if (chartValues.hasOwnProperty(property)) {
                        chartLabels.push(property);

                        switch (yAxisAggregation) {
                            case 'sum':
                                const sum = chartValues[property].reduce((prev, current) => prev + current, 0);
                                chartData.push(sum);
                                break;
                            case 'average':
                                const average = chartValues[property].reduce((prev, current) => prev + current, 0) / chartValues[property].length;
                                chartData.push(average);
                                break;
                            default:
                                chartData.push(chartValues[property].length);
                        }
                    }
                }
            } else if (xAxis || tableFields.length === 1) {
                const fieldId = xAxis ? xAxis : tableFields[0];

                if (isUUID(fieldId)) {
                    const field = workflowsData.types.customFields.byId[fieldId];
                    if (field.type === FieldType.SINGLE_SELECT || field.type === FieldType.MULTI_SELECT) {
                        const choiceNames = field.choices
                            .filter(choiceId => choiceId in groupsData.types.customFields.byId && !groupsData.types.customFields.byId[choiceId].archived)
                            .map(choiceId => groupsData.types.customFields.byId[choiceId].name);

                        for (const choiceName of choiceNames) {
                            chartLabels.push(choiceName);
                            chartData.push(choiceName in chartCounts ? chartCounts[choiceName] : 0);
                        }

                        for (let property in chartCounts) {
                            if (chartCounts.hasOwnProperty(property) && !choiceNames.includes(property)) {
                                chartLabels.push(property);
                                chartData.push(chartCounts[property]);
                            }
                        }
                        break;
                    }
                } else if (fieldId === 'status' && typeId) {
                    const workflowType = workflowsData.types.byId[typeId];
                    for (const statusId of workflowType.statuses) {
                        const status = workflowsData.types.statuses.byId[statusId];
                        chartLabels.push(status.name);
                        chartData.push(status.name in chartCounts ? chartCounts[status.name] : 0);
                    }
                    break;
                }

                for (let property in chartCounts) {
                    if (chartCounts.hasOwnProperty(property)) {
                        chartLabels.push(property);
                        chartData.push(chartCounts[property]);
                    }
                }
            }

            break;

        default:
            throw new Error('Unknown type');
    }

    return {
        chartLabels,
        chartData,
    }
}

export function getShowData(
    applicationState: ApplicationState,
    entityIds: Array<string>,
    type: string,
    customFields: Array<string>,
    xAxis: string | undefined,
    yAxis: string | undefined,
    yAxisAggregation: 'sum' | 'average' | undefined,
    typeId?: string,
    systemFields?: Array<string>
) {

    const usersData = applicationState.users;
    const rolesData = applicationState.structure.roles;
    const membersData = applicationState.members;
    const groupsData = applicationState.groups;
    const workflowsData = applicationState.workflows;

    const headings: Array<TableHeading> = [{
        name: 'Sl. no',
        isSortable: false,
        isSticky: false,
        width: 90,
    }, {
        name: 'Name',
        isSortable: false,
        isSticky: false,
        width: 120,
    }];

    let entries: Array<TableRow> = [];

    let chartLabels: Array<string> = [];
    let chartData: Array<number> = [];
    const chartCounts: { [key: string]: number } = {};
    const chartValues: { [key: string]: Array<number> } = {};

    let xAxisValue: string, yAxisValue: string;

    let tableFields: Array<string>

    if (xAxis && yAxis) {
        tableFields = [xAxis, yAxis];
    } else if (xAxis) {
        tableFields = [xAxis];
    } else {
        const selectedSystemFields = systemFields ? systemFields : [];
        tableFields = selectedSystemFields.concat(customFields);
    }

    let xAxisFieldName = '';
    let yAxisFieldName = '';

    switch (type) {
        case 'User':
            tableFields.forEach(fieldId => {

                let fieldHeading: TableHeading;

                if (!isUUID(fieldId)) {
                    switch (fieldId) {
                        case 'created':
                            fieldHeading = {
                                name: 'Created',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'last_updated':
                            fieldHeading = {
                                name: 'Last updated',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'locations':
                            fieldHeading = {
                                name: 'Locations',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'projects':
                            fieldHeading = {
                                name: 'Projects',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'levels':
                            fieldHeading = {
                                name: 'Levels',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'roles':
                            fieldHeading = {
                                name: 'Roles',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        default:
                            throw new Error('Unknown system field');
                    }

                } else {
                    if (fieldId !== usersData.nameFieldId) {
                        fieldHeading = {
                            name: usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFields.byId[fieldId].name : rolesData.customFields.byId[fieldId].name,
                            isSortable: false,
                            width: 150,
                        };

                        headings.push(fieldHeading);
                    }
                }

            });

            entries = entityIds.map(((userId, index) => {
                const user = usersData.byId[userId];
                let userName = user.customFields[usersData.nameFieldId];

                userName = getReadableDataForCustomField(userName, usersData.customFields.byId[usersData.nameFieldId], user.id, 'user', applicationState);

                const cells: Array<number | string> = [index + 1, userName];

                tableFields.forEach(fieldId => {
                    if (!isUUID(fieldId)) {
                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(user.createdTime).format('MMM DD, YYYY hh:mmA');
                                cells.push(readableCreated);
                                break;
                            case 'last_updated':
                                const readableLastUpdated = moment(user.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                cells.push(readableLastUpdated);
                                break;
                            case 'projects':
                                const readableProjects = user.projects.map(projectId => getReadableProject(projectId, applicationState.structure.projects)).filter(project => !!project).join(', ');
                                cells.push(readableProjects);
                                break;
                            case 'levels':
                                const readableLevels = user.levels.map(levelId => getReadableLevel(levelId, applicationState.structure.levels)).filter(level => !!level).join(', ');
                                cells.push(readableLevels);
                                break;
                            case 'roles':
                                const readableRoles = user.roles.map(roleId => getReadableRole(roleId, applicationState.structure.roles)).filter(role => !!role).join(', ');
                                cells.push(readableRoles);
                                break;
                            case 'locations':
                                const readableLocations = user.locations.map(locationId => getReadableLocation(locationId, applicationState.structure.locations)).filter(location => !!location).join(', ');
                                cells.push(readableLocations);
                                break;
                            default:
                                throw new Error('Unknown system field');
                        }
                    } else {
                        if (fieldId !== usersData.nameFieldId) {
                            const field = usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFields.byId[fieldId] : rolesData.customFields.byId[fieldId];
                            const fieldOptions = usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFieldOptions : rolesData.customFieldOptions;
                            const readableValue = getCommonCustomFieldValue(user, 'user', field, fieldOptions, applicationState);

                            cells.push(readableValue);
                        }
                    }
                });

                if (xAxis && yAxis) {
                    const xAxisField = usersData.customFields.byId.hasOwnProperty(xAxis) ? usersData.customFields.byId[xAxis] : rolesData.customFields.byId[xAxis];
                    const yAxisField = usersData.customFields.byId.hasOwnProperty(yAxis) ? usersData.customFields.byId[yAxis] : rolesData.customFields.byId[yAxis];

                    xAxisFieldName = xAxisField.name;
                    yAxisFieldName = yAxisField.name;

                    const xAxisFieldOptions = usersData.customFields.byId.hasOwnProperty(xAxis) ? usersData.customFieldOptions : rolesData.customFieldOptions;
                    const yAxisFieldOptions = usersData.customFields.byId.hasOwnProperty(yAxis) ? usersData.customFieldOptions : rolesData.customFieldOptions;

                    xAxisValue = getCommonCustomFieldValue(user, 'user', xAxisField, xAxisFieldOptions, applicationState);
                    yAxisValue = getCommonCustomFieldValue(user, 'user', yAxisField, yAxisFieldOptions, applicationState);

                    if (!isNaN(Number(yAxisValue))) {

                        (Array.isArray(xAxisValue) ? xAxisValue : [xAxisValue]).forEach(value => {
                            if (value in chartValues) {
                                chartValues[value].push(Number(yAxisValue));
                            } else {
                                chartValues[value] = [Number(yAxisValue)];
                            }
                        });

                    }
                } else if (xAxis || tableFields.length === 1) {
                    const fieldId = xAxis ? xAxis : tableFields[0];
                    let readableValue: string | Array<string> = '-';

                    if (!isUUID(fieldId)) {
                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(user.createdTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableCreated;
                                break;
                            case 'last_updated':
                                const readableLastUpdated = moment(user.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableLastUpdated;
                                break;

                            case 'projects':
                                const readableProjects = user.projects.map(projectId => getReadableProject(projectId, applicationState.structure.projects)).filter(project => !!project).join(', ');
                                readableValue = readableProjects;
                                break;
                            case 'levels':
                                const readableLevels = user.levels.map(levelId => getReadableLevel(levelId, applicationState.structure.levels)).filter(level => !!level).join(', ');
                                readableValue = readableLevels;
                                break;
                            case 'roles':
                                const readableRoles = user.roles.map(roleId => getReadableRole(roleId, applicationState.structure.roles)).filter(role => !!role).join(', ');
                                readableValue = readableRoles;
                                break;
                            case 'locations':
                                const readableLocations = user.locations.map(locationId => getReadableLocation(locationId, applicationState.structure.locations)).filter(location => !!location).join(', ');
                                readableValue = readableLocations;
                                break;
                            default:
                                throw new Error('Unknown system field');
                        }
                    } else {
                        const field = usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFields.byId[fieldId] : rolesData.customFields.byId[fieldId];
                        const fieldOptions = usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFieldOptions : rolesData.customFieldOptions;

                        if (field.type === FieldType.MULTI_SELECT) {
                            const selectedOptions = user.customFields[field.id];

                            if (Array.isArray(selectedOptions)) {
                                readableValue = selectedOptions.map(optionId => fieldOptions.byId[optionId].name);
                            }
                        } else {
                            readableValue = getCommonCustomFieldValue(user, 'user', field, fieldOptions, applicationState);
                        }
                    }


                    (Array.isArray(readableValue) ? readableValue : [readableValue]).forEach(value => {
                        if (value in chartCounts) {
                            chartCounts[value] += 1;
                        } else {
                            chartCounts[value] = 1;
                        }
                    });
                }

                return {
                    id: user.id,
                    entries: cells,
                }
            }));

            if (xAxis && yAxis) {
                for (let property in chartValues) {
                    if (chartValues.hasOwnProperty(property)) {
                        chartLabels.push(property);

                        switch (yAxisAggregation) {
                            case 'sum':
                                const sum = chartValues[property].reduce((prev, current) => prev + current, 0);
                                chartData.push(sum);
                                break;
                            case 'average':
                                const average = chartValues[property].reduce((prev, current) => prev + current, 0) / chartValues[property].length;
                                chartData.push(average);
                                break;
                            default:
                                chartData.push(chartValues[property].length);
                        }
                    }
                }
            } else if (xAxis || tableFields.length === 1) {
                const fieldId = xAxis ? xAxis : tableFields[0];

                if (isUUID(fieldId)) {
                    const field = usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFields.byId[fieldId] : rolesData.customFields.byId[fieldId];
                    const fieldOptions = usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFieldOptions : rolesData.customFieldOptions;

                    if (field.type === FieldType.SINGLE_SELECT || field.type === FieldType.MULTI_SELECT) {
                        const choiceNames = field.choices
                            .filter(choiceId => choiceId in fieldOptions.byId && !fieldOptions.byId[choiceId].archived)
                            .map(choiceId => fieldOptions.byId[choiceId].name);

                        for (const choiceName of choiceNames) {
                            chartLabels.push(choiceName);
                            chartData.push(choiceName in chartCounts ? chartCounts[choiceName] : 0);
                        }

                        for (let property in chartCounts) {
                            if (chartCounts.hasOwnProperty(property) && !choiceNames.includes(property)) {
                                chartLabels.push(property);
                                chartData.push(chartCounts[property]);
                            }
                        }
                        break;
                    }
                }

                for (let property in chartCounts) {
                    if (chartCounts.hasOwnProperty(property)) {
                        chartLabels.push(property);
                        chartData.push(chartCounts[property]);
                    }
                }
            }

            break;

        case 'Member':
            tableFields.forEach(fieldId => {
                let fieldHeading: TableHeading;

                if (!isUUID(fieldId)) {
                    switch (fieldId) {
                        case 'created':
                            fieldHeading = {
                                name: 'Created',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'last_updated':
                            fieldHeading = {
                                name: 'Last Updated',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'location':
                            fieldHeading = {
                                name: 'Location',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'name':
                            fieldHeading = {
                                name: 'Name',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'subtitle':
                            fieldHeading = {
                                name: 'Sub title',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'last_seen':
                            fieldHeading = {
                                name: 'Last seen',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        default:
                            throw new Error('Unknown system field');
                    }

                } else {
                    if (!membersData.types.allEntries.map(memberTypeId => membersData.types.byId[memberTypeId].nameFieldId).includes(fieldId)) {
                        fieldHeading = {
                            name: membersData.types.customFields.byId[fieldId].name,
                            isSortable: false,
                            width: 150,
                        }

                        headings.push(fieldHeading);
                    }
                }
            });

            entries = entityIds.map(((memberId, index) => {
                const member = membersData.byId[memberId];
                const memberType = membersData.types.byId[member.type];
                let memberName = member.customFields[memberType.nameFieldId];

                const nameField = membersData.types.customFields.byId[memberType.nameFieldId];

                memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member', applicationState);

                const cells: Array<number | string> = [index + 1, memberName];

                tableFields.forEach(fieldId => {
                    if (!isUUID(fieldId)) {
                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(member.createdTime).format('MMM DD, YYYY hh:mmA');
                                cells.push(readableCreated);
                                break;

                            case 'last_updated':
                                const readableLastUpdated = moment(member.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                cells.push(readableLastUpdated);
                                break;

                            case 'location':
                                const readableLocation = getReadableLocation(member.location, applicationState.structure.locations);
                                cells.push(readableLocation);
                                break;

                            case 'name':
                                const readableName = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[memberType.nameFieldId], membersData.types.customFieldOptions, applicationState);
                                cells.push(readableName);
                                break;

                            case 'subtitle':
                                const readableSubTitle = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[memberType.subTitleFieldId], membersData.types.customFieldOptions, applicationState);
                                cells.push(readableSubTitle);
                                break;

                            case 'last_seen':
                                const readableLastSeen = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[memberType.locationFieldId], membersData.types.customFieldOptions, applicationState);
                                cells.push(readableLastSeen);
                                break;
                            default:
                                throw new Error('Unknown system field');
                        }
                    } else {
                        if (!membersData.types.allEntries.map(memberTypeId => membersData.types.byId[memberTypeId].nameFieldId).includes(fieldId)) {
                            const readableValue = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[fieldId], membersData.types.customFieldOptions, applicationState);
                            cells.push(readableValue);
                        }
                    }
                });

                if (xAxis && yAxis) {
                    const xAxisField = membersData.types.customFields.byId[xAxis];
                    const yAxisField = membersData.types.customFields.byId[yAxis];

                    xAxisFieldName = xAxisField.name;
                    yAxisFieldName = yAxisField.name;

                    xAxisValue = getCommonCustomFieldValue(member, 'member', xAxisField, membersData.types.customFieldOptions, applicationState);
                    yAxisValue = getCommonCustomFieldValue(member, 'member', yAxisField, membersData.types.customFieldOptions, applicationState);

                    if (!isNaN(Number(yAxisValue))) {

                        (Array.isArray(xAxisValue) ? xAxisValue : [xAxisValue]).forEach(value => {
                            if (value in chartValues) {
                                chartValues[value].push(Number(yAxisValue));
                            } else {
                                chartValues[value] = [Number(yAxisValue)];
                            }
                        });

                    }
                } else if (xAxis || tableFields.length === 1) {
                    const fieldId = xAxis ? xAxis : tableFields[0];
                    let readableValue: string | Array<string> = '-';

                    if (!isUUID(fieldId)) {
                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(member.createdTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableCreated;
                                break;

                            case 'last_updated':
                                const readableLastUpdated = moment(member.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableLastUpdated;
                                break;

                            case 'location':
                                const readableLocation = getReadableLocation(member.location, applicationState.structure.locations);
                                readableValue = readableLocation;
                                break;

                            case 'name':
                                const readableName = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[memberType.nameFieldId], membersData.types.customFieldOptions, applicationState);
                                readableValue = readableName;
                                break;

                            case 'subtitle':
                                const readableSubTitle = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[memberType.subTitleFieldId], membersData.types.customFieldOptions, applicationState);
                                readableValue = readableSubTitle;
                                break;

                            case 'last_seen':
                                const readableLastSeen = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[memberType.locationFieldId], membersData.types.customFieldOptions, applicationState);
                                readableValue = readableLastSeen;
                                break;
                            default:
                                throw new Error('Unknown system field');
                        }
                    } else {
                        const field = membersData.types.customFields.byId[fieldId];
                        const fieldOptions = membersData.types.customFieldOptions;

                        if (field.type === FieldType.MULTI_SELECT) {
                            const selectedOptions = member.customFields[field.id];

                            if (Array.isArray(selectedOptions)) {
                                readableValue = selectedOptions.map(optionId => fieldOptions.byId[optionId].name);
                            }
                        } else {
                            readableValue = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[fieldId], membersData.types.customFieldOptions, applicationState);
                        }
                    }

                    (Array.isArray(readableValue) ? readableValue : [readableValue]).forEach(value => {
                        if (value in chartCounts) {
                            chartCounts[value] += 1;
                        } else {
                            chartCounts[value] = 1;
                        }
                    });
                }

                return {
                    id: member.id,
                    entries: cells,
                }
            }));

            if (xAxis && yAxis) {
                for (let property in chartValues) {
                    if (chartValues.hasOwnProperty(property)) {
                        chartLabels.push(property);

                        switch (yAxisAggregation) {
                            case 'sum':
                                const sum = chartValues[property].reduce((prev, current) => prev + current, 0);
                                chartData.push(sum);
                                break;
                            case 'average':
                                const average = chartValues[property].reduce((prev, current) => prev + current, 0) / chartValues[property].length;
                                chartData.push(average);
                                break;
                            default:
                                chartData.push(chartValues[property].length);
                        }
                    }
                }
            } else if (xAxis || tableFields.length === 1) {
                const fieldId = xAxis ? xAxis : tableFields[0];

                if (isUUID(fieldId)) {
                    const field = membersData.types.customFields.byId[fieldId];
                    if (field.type === FieldType.SINGLE_SELECT || field.type === FieldType.MULTI_SELECT) {
                        const choiceNames = field.choices
                            .filter(choiceId => choiceId in membersData.types.customFieldOptions.byId && !membersData.types.customFieldOptions.byId[choiceId].archived)
                            .map(choiceId => membersData.types.customFieldOptions.byId[choiceId].name);

                        for (const choiceName of choiceNames) {
                            chartLabels.push(choiceName);
                            chartData.push(choiceName in chartCounts ? chartCounts[choiceName] : 0);
                        }

                        for (let property in chartCounts) {
                            if (chartCounts.hasOwnProperty(property) && !choiceNames.includes(property)) {
                                chartLabels.push(property);
                                chartData.push(chartCounts[property]);
                            }
                        }
                        break;
                    }
                }

                for (let property in chartCounts) {
                    if (chartCounts.hasOwnProperty(property)) {
                        chartLabels.push(property);
                        chartData.push(chartCounts[property]);
                    }
                }
            }

            break;

        case 'Group':
            tableFields.forEach(fieldId => {
                let fieldHeading: TableHeading;

                if (!isUUID(fieldId)) {
                    switch (fieldId) {
                        case 'created':
                            fieldHeading = {
                                name: 'Created',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'last_updated':
                            fieldHeading = {
                                name: 'Last Updated',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'location':
                            fieldHeading = {
                                name: 'Location',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'name':
                            fieldHeading = {
                                name: 'Name',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;

                        case 'subtitle':
                            fieldHeading = {
                                name: 'Sub title',
                                isSortable: false,
                                width: 150,
                            };
                            headings.push(fieldHeading);
                            break;
                        default:
                            throw new Error('Unknown system field');
                    }

                } else {
                    if (!groupsData.types.allEntries.map(groupTypeId => groupsData.types.byId[groupTypeId].nameFieldId).includes(fieldId)) {
                        fieldHeading = {
                            name: groupsData.types.customFields.byId[fieldId].name,
                            isSortable: false,
                            width: 150,
                        }

                        headings.push(fieldHeading);
                    }
                }
            });

            entries = entityIds.map(((memberId, index) => {
                const group = groupsData.byId[memberId];
                const groupType = groupsData.types.byId[group.type];
                let groupName = group.customFields[groupType.nameFieldId];

                const nameField = groupsData.types.customFields.byId[groupType.nameFieldId];

                groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group', applicationState);

                const cells: Array<number | string> = [index + 1, groupName];

                tableFields.forEach(fieldId => {
                    if (!isUUID(fieldId)) {
                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(group.createdTime).format('MMM DD, YYYY hh:mmA');
                                cells.push(readableCreated);
                                break;

                            case 'last_updated':
                                const readableLastUpdated = moment(group.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                cells.push(readableLastUpdated);
                                break;

                            case 'location':
                                const readableLocation = getReadableLocation(group.location, applicationState.structure.locations);
                                cells.push(readableLocation);
                                break;

                            case 'name':
                                const readableName = getCommonCustomFieldValue(group, 'group', groupsData.types.customFields.byId[groupType.nameFieldId], groupsData.types.customFieldOptions, applicationState);
                                cells.push(readableName);
                                break;

                            case 'subtitle':
                                const readableSubTitle = getCommonCustomFieldValue(group, 'group', groupsData.types.customFields.byId[groupType.subTitleFieldId], groupsData.types.customFieldOptions, applicationState);
                                cells.push(readableSubTitle);
                                break;
                            default:
                                throw new Error('Unknown system field');
                        }
                    } else {
                        if (!groupsData.types.allEntries.map(groupTypeId => groupsData.types.byId[groupTypeId].nameFieldId).includes(fieldId)) {
                            const readableValue = getCommonCustomFieldValue(group, 'group', groupsData.types.customFields.byId[fieldId], groupsData.types.customFieldOptions, applicationState);
                            cells.push(readableValue);
                        }
                    }
                });

                if (xAxis && yAxis) {
                    const xAxisField = groupsData.types.customFields.byId[xAxis];
                    const yAxisField = groupsData.types.customFields.byId[yAxis];

                    xAxisFieldName = xAxisField.name;
                    yAxisFieldName = yAxisField.name;

                    xAxisValue = getCommonCustomFieldValue(group, 'group', xAxisField, groupsData.types.customFieldOptions, applicationState);
                    yAxisValue = getCommonCustomFieldValue(group, 'group', yAxisField, groupsData.types.customFieldOptions, applicationState);

                    if (!isNaN(Number(yAxisValue))) {

                        (Array.isArray(xAxisValue) ? xAxisValue : [xAxisValue]).forEach(value => {
                            if (value in chartValues) {
                                chartValues[value].push(Number(yAxisValue));
                            } else {
                                chartValues[value] = [Number(yAxisValue)];
                            }
                        });

                    }
                } else if (xAxis || tableFields.length === 1) {
                    const fieldId = xAxis ? xAxis : tableFields[0];
                    let readableValue: string | Array<string> = '-';

                    if (!isUUID(fieldId)) {
                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(group.createdTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableCreated;
                                break;

                            case 'last_updated':
                                const readableLastUpdated = moment(group.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableLastUpdated;
                                break;

                            case 'location':
                                const readableLocation = getReadableLocation(group.location, applicationState.structure.locations);
                                readableValue = readableLocation;
                                break;

                            case 'name':
                                const readableName = getCommonCustomFieldValue(group, 'group', groupsData.types.customFields.byId[groupType.nameFieldId], groupsData.types.customFieldOptions, applicationState);
                                readableValue = readableName;
                                break;

                            case 'subtitle':
                                const readableSubTitle = getCommonCustomFieldValue(group, 'group', groupsData.types.customFields.byId[groupType.subTitleFieldId], groupsData.types.customFieldOptions, applicationState);
                                readableValue = readableSubTitle;
                                break;
                            default:
                                throw new Error('Unknown system field');
                        }
                    } else {
                        const field = groupsData.types.customFields.byId[fieldId];
                        const fieldOptions = groupsData.types.customFieldOptions;

                        if (field.type === FieldType.MULTI_SELECT) {
                            const selectedOptions = group.customFields[field.id];

                            if (Array.isArray(selectedOptions)) {
                                readableValue = selectedOptions.map(optionId => fieldOptions.byId[optionId].name);
                            }
                        } else {
                            readableValue = getCommonCustomFieldValue(group, 'group', groupsData.types.customFields.byId[fieldId], groupsData.types.customFieldOptions, applicationState);
                        }
                    }

                    (Array.isArray(readableValue) ? readableValue : [readableValue]).forEach(value => {
                        if (value in chartCounts) {
                            chartCounts[value] += 1;
                        } else {
                            chartCounts[value] = 1;
                        }
                    });
                }

                return {
                    id: group.id,
                    entries: cells,
                }
            }));

            if (xAxis && yAxis) {
                for (let property in chartValues) {
                    if (chartValues.hasOwnProperty(property)) {
                        chartLabels.push(property);

                        switch (yAxisAggregation) {
                            case 'sum':
                                const sum = chartValues[property].reduce((prev, current) => prev + current, 0);
                                chartData.push(sum);
                                break;
                            case 'average':
                                const average = chartValues[property].reduce((prev, current) => prev + current, 0) / chartValues[property].length;
                                chartData.push(average);
                                break;
                            default:
                                chartData.push(chartValues[property].length);
                        }
                    }
                }
            } else if (xAxis || tableFields.length === 1) {
                const fieldId = xAxis ? xAxis : tableFields[0];

                if (isUUID(fieldId)) {
                    const field = groupsData.types.customFields.byId[fieldId];
                    if (field.type === FieldType.SINGLE_SELECT || field.type === FieldType.MULTI_SELECT) {
                        const choiceNames = field.choices
                            .filter(choiceId => choiceId in groupsData.types.customFields.byId && !groupsData.types.customFields.byId[choiceId].archived)
                            .map(choiceId => groupsData.types.customFields.byId[choiceId].name);

                        for (const choiceName of choiceNames) {
                            chartLabels.push(choiceName);
                            chartData.push(choiceName in chartCounts ? chartCounts[choiceName] : 0);
                        }

                        for (let property in chartCounts) {
                            if (chartCounts.hasOwnProperty(property) && !choiceNames.includes(property)) {
                                chartLabels.push(property);
                                chartData.push(chartCounts[property]);
                            }
                        }
                        break;
                    }
                }

                for (let property in chartCounts) {
                    if (chartCounts.hasOwnProperty(property)) {
                        chartLabels.push(property);
                        chartData.push(chartCounts[property]);
                    }
                }
            }

            break;

        case 'Workflow':
            headings.pop();

            tableFields.forEach(fieldId => {
                let fieldHeading: TableHeading;

                if (!isUUID(fieldId)) {
                    switch (fieldId) {
                        case 'created':
                            fieldHeading = {
                                name: 'Created',
                                isSortable: false,
                                width: 150,
                            };
                            break;

                        case 'last_updated':
                            fieldHeading = {
                                name: 'Last Updated',
                                isSortable: false,
                                width: 150,
                            };
                            break;

                        case 'type':
                            fieldHeading = {
                                name: 'Type',
                                isSortable: false,
                                width: 150,
                            };
                            break;

                        case 'subtitle':
                            fieldHeading = {
                                name: 'Subtitle',
                                isSortable: false,
                                width: 150,
                            };
                            break;

                        case 'status':
                            fieldHeading = {
                                name: 'Status',
                                isSortable: false,
                                width: 150,
                            };
                            break;

                        case 'is_terminal':
                            fieldHeading = {
                                name: 'Is Terminal',
                                isSortable: false,
                                width: 150,
                            };
                            break;

                        case 'due_date':
                            fieldHeading = {
                                name: 'Due date',
                                isSortable: false,
                                width: 150,
                            };
                            break;

                        case 'user':
                            fieldHeading = {
                                name: 'User',
                                isSortable: false,
                                width: 150,
                            };
                            break;

                        case 'location':
                            fieldHeading = {
                                name: 'Location',
                                isSortable: false,
                                width: 150,
                            };
                            break;

                        case 'affiliation':
                            fieldHeading = {
                                name: 'Member/Group',
                                isSortable: false,
                                width: 150,
                            };
                            break;
                        default:
                            throw new Error('Unknown system field');
                    }

                    headings.push(fieldHeading);

                } else {
                    fieldHeading = {
                        name: workflowsData.types.customFields.byId[fieldId].name,
                        isSortable: false,
                        width: 150,
                    }
                    headings.push(fieldHeading);
                }

            });

            entries = entityIds.map(((workflowId, index) => {
                const workflow = workflowsData.byId[workflowId];
                const workflowType = workflowsData.types.byId[workflow.type];
                const cells: Array<number | string> = [index + 1];

                tableFields.forEach(fieldId => {

                    if (!isUUID(fieldId)) {
                        try {
                            switch (fieldId) {
                                case 'created':
                                    const readableCreated = moment(workflow.createdTime).format('MMM DD, YYYY hh:mmA');
                                    cells.push(readableCreated);
                                    break;

                                case 'last_updated':
                                    const readableLastUpdated = moment(workflow.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                    cells.push(readableLastUpdated);
                                    break;

                                case 'type':
                                    cells.push(workflowType.name);
                                    break;

                                case 'subtitle':
                                    if (workflowType.subTitleFieldId) {
                                        const subtitleField = workflowsData.types.customFields.byId[workflowType.subTitleFieldId];
                                        const readableSubTitle = getWorkflowCustomFieldValue(workflow, subtitleField, applicationState);
                                        cells.push(readableSubTitle);
                                    } else {
                                        // Code for pushing affiliation in subtitle
                                        if (workflowType.affiliation === 'none') {
                                            cells.push('-');
                                        } else if (workflowType.affiliation === 'member') {
                                            const member = membersData.byId[workflow.affiliatedEntity];
                                            const memberType = membersData.types.byId[member.type];

                                            const nameField = membersData.types.customFields.byId[memberType.nameFieldId];
                                            const nameFieldValue = getCommonCustomFieldValue(member, 'member', nameField, membersData.types.customFieldOptions, applicationState);

                                            const subtitleField = membersData.types.customFields.byId[memberType.subTitleFieldId];
                                            const subtitleFieldValue = getCommonCustomFieldValue(member, 'member', subtitleField, membersData.types.customFieldOptions, applicationState);

                                            cells.push(`${nameFieldValue} (${subtitleFieldValue})`);
                                        } else if (workflowType.affiliation === 'group') {
                                            const group = groupsData.byId[workflow.affiliatedEntity];
                                            const groupType = groupsData.types.byId[group.type];

                                            const nameField = groupsData.types.customFields.byId[groupType.nameFieldId];
                                            const nameFieldValue = getCommonCustomFieldValue(group, 'group', nameField, groupsData.types.customFieldOptions, applicationState);

                                            const subtitleField = groupsData.types.customFields.byId[groupType.subTitleFieldId];
                                            const subtitleFieldValue = getCommonCustomFieldValue(group, 'group', subtitleField, membersData.types.customFieldOptions, applicationState);

                                            cells.push(`${nameFieldValue} (${subtitleFieldValue})`);
                                        }
                                    }
                                    break;

                                case 'status':
                                    try {
                                        const readableStatus = workflowsData.types.statuses.byId[workflow.status].name;
                                        cells.push(readableStatus);
                                    } catch (e) {
                                        cells.push('-');
                                        console.log(JSON.stringify(workflow));
                                    }
                                    break;

                                case 'is_terminal':
                                    try {
                                        const readableIsTerminal = workflowsData.types.statuses.byId[workflow.status].isTerminal ? 'Yes' : 'No';
                                        cells.push(readableIsTerminal);
                                    } catch (e) {
                                        cells.push('-');
                                        console.log(JSON.stringify(workflow));
                                    }
                                    break;

                                case 'due_date':
                                    const readableDueDate = moment(workflow.dueDate).format('MMM DD, YYYY');
                                    cells.push(readableDueDate);
                                    break;

                                case 'user':
                                    const user = usersData.byId[workflow.user];

                                    const nameField = usersData.customFields.byId[usersData.nameFieldId];
                                    const nameFieldValue = getCommonCustomFieldValue(user, 'user', nameField, usersData.customFieldOptions, applicationState);

                                    const subtitleField = usersData.customFields.byId[usersData.subTitleFieldId];
                                    const subtitleFieldValue = getCommonCustomFieldValue(user, 'user', subtitleField, usersData.customFieldOptions, applicationState);

                                    cells.push(`${nameFieldValue} (${subtitleFieldValue})`);
                                    break;

                                case 'location':
                                    if (workflowType.affiliation === 'none') {
                                        const user = usersData.byId[workflow.user];
                                        const locationNames = user.locations.map(locationId => {
                                            const location = applicationState.structure.locations.byId[locationId];
                                            return location.name;
                                        });
                                        cells.push(locationNames.join(', '));
                                    } else if (workflowType.affiliation === 'member') {
                                        const member = membersData.byId[workflow.affiliatedEntity];
                                        const locationName = applicationState.structure.locations.byId[member.location].name;
                                        cells.push(locationName);
                                    } else if (workflowType.affiliation === 'group') {
                                        const group = groupsData.byId[workflow.affiliatedEntity];
                                        const locationName = applicationState.structure.locations.byId[group.location].name;
                                        cells.push(locationName);
                                    }
                                    break;

                                case 'affiliation':

                                    if (workflowType.affiliation === 'none') {
                                        cells.push('-');
                                    } else if (workflowType.affiliation === 'member') {
                                        const member = membersData.byId[workflow.affiliatedEntity];
                                        const memberType = membersData.types.byId[member.type];

                                        const nameField = membersData.types.customFields.byId[memberType.nameFieldId];
                                        const nameFieldValue = getCommonCustomFieldValue(member, 'member', nameField, membersData.types.customFieldOptions, applicationState);

                                        const subtitleField = membersData.types.customFields.byId[memberType.subTitleFieldId];
                                        const subtitleFieldValue = getCommonCustomFieldValue(member, 'member', subtitleField, membersData.types.customFieldOptions, applicationState);

                                        cells.push(`${nameFieldValue} (${subtitleFieldValue})`);
                                    } else if (workflowType.affiliation === 'group') {
                                        const group = groupsData.byId[workflow.affiliatedEntity];
                                        const groupType = groupsData.types.byId[group.type];

                                        const nameField = groupsData.types.customFields.byId[groupType.nameFieldId];
                                        const nameFieldValue = getCommonCustomFieldValue(group, 'group', nameField, groupsData.types.customFieldOptions, applicationState);

                                        const subtitleField = groupsData.types.customFields.byId[groupType.subTitleFieldId];
                                        const subtitleFieldValue = getCommonCustomFieldValue(group, 'group', subtitleField, membersData.types.customFieldOptions, applicationState);

                                        cells.push(`${nameFieldValue} (${subtitleFieldValue})`);
                                    }
                                    break;
                                default:
                                    throw new Error('Unknown system field');
                            }
                        } catch (e) {
                            cells.push('-');
                        }
                    } else {
                        const readableValue = getWorkflowCustomFieldValue(workflow, workflowsData.types.customFields.byId[fieldId], applicationState);
                        cells.push(readableValue);
                    }
                });

                if (xAxis && yAxis) {
                    const xAxisField = workflowsData.types.customFields.byId[xAxis];
                    const yAxisField = workflowsData.types.customFields.byId[yAxis];

                    xAxisFieldName = xAxisField.name;
                    yAxisFieldName = yAxisField.name;

                    xAxisValue = getWorkflowCustomFieldValue(workflow, xAxisField, applicationState);
                    yAxisValue = getWorkflowCustomFieldValue(workflow, yAxisField, applicationState);

                    if (!isNaN(Number(yAxisValue))) {

                        (Array.isArray(xAxisValue) ? xAxisValue : [xAxisValue]).forEach(value => {
                            if (value in chartValues) {
                                chartValues[value].push(Number(yAxisValue));
                            } else {
                                chartValues[value] = [Number(yAxisValue)];
                            }
                        });

                    }
                } else if (xAxis || tableFields.length === 1) {
                    const fieldId = xAxis ? xAxis : tableFields[0];
                    let readableValue: string | Array<string> = '-';

                    if (!isUUID(fieldId)) {
                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(workflow.createdTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableCreated;
                                break;

                            case 'last_updated':
                                const readableLastUpdated = moment(workflow.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                readableValue = readableLastUpdated;
                                break;

                            case 'type':
                                readableValue = workflowType.name;
                                break;

                            case 'subtitle':
                                if (workflowType.subTitleFieldId) {
                                    const subtitleField = workflowsData.types.customFields.byId[workflowType.subTitleFieldId];
                                    const readableSubTitle = getWorkflowCustomFieldValue(workflow, subtitleField, applicationState);
                                    readableValue = readableSubTitle;
                                } else {
                                    // Code for pushing affiliation in subtitle
                                    if (workflowType.affiliation === 'none') {
                                        readableValue = '-';
                                    } else if (workflowType.affiliation === 'member') {
                                        const member = membersData.byId[workflow.affiliatedEntity];
                                        const memberType = membersData.types.byId[member.type];

                                        const nameField = membersData.types.customFields.byId[memberType.nameFieldId];
                                        const nameFieldValue = getCommonCustomFieldValue(member, 'member', nameField, membersData.types.customFieldOptions, applicationState);

                                        const subtitleField = membersData.types.customFields.byId[memberType.subTitleFieldId];
                                        const subtitleFieldValue = getCommonCustomFieldValue(member, 'member', subtitleField, membersData.types.customFieldOptions, applicationState);

                                        readableValue = `${nameFieldValue} (${subtitleFieldValue})`;
                                    } else if (workflowType.affiliation === 'group') {
                                        const group = groupsData.byId[workflow.affiliatedEntity];
                                        const groupType = groupsData.types.byId[group.type];

                                        const nameField = groupsData.types.customFields.byId[groupType.nameFieldId];
                                        const nameFieldValue = getCommonCustomFieldValue(group, 'group', nameField, groupsData.types.customFieldOptions, applicationState);

                                        const subtitleField = groupsData.types.customFields.byId[groupType.subTitleFieldId];
                                        const subtitleFieldValue = getCommonCustomFieldValue(group, 'group', subtitleField, membersData.types.customFieldOptions, applicationState);

                                        readableValue = `${nameFieldValue} (${subtitleFieldValue})`;
                                    } else {
                                        readableValue = '-';
                                    }
                                }
                                break;

                            case 'status':
                                try {
                                    const readableStatus = workflowsData.types.statuses.byId[workflow.status].name;
                                    readableValue = readableStatus;
                                } catch (e) {
                                    readableValue = '-';
                                    console.log(JSON.stringify(workflow));
                                }
                                break;

                            case 'is_terminal':
                                try {
                                    const readableIsTerminal = workflowsData.types.statuses.byId[workflow.status].isTerminal ? 'Yes' : 'No';
                                    readableValue = readableIsTerminal;
                                } catch (e) {
                                    readableValue = '-';
                                    console.log(JSON.stringify(workflow));
                                }
                                break;

                            case 'due_date':
                                const readableDueDate = moment(workflow.dueDate).format('MMM DD, YYYY');
                                readableValue = readableDueDate;
                                break;

                            case 'user':
                                const user = usersData.byId[workflow.user];

                                const nameField = usersData.customFields.byId[usersData.nameFieldId];
                                const nameFieldValue = getCommonCustomFieldValue(user, 'user', nameField, usersData.customFieldOptions, applicationState);

                                const subtitleField = usersData.customFields.byId[usersData.subTitleFieldId];
                                const subtitleFieldValue = getCommonCustomFieldValue(user, 'user', subtitleField, usersData.customFieldOptions, applicationState);

                                readableValue = `${nameFieldValue} (${subtitleFieldValue})`;
                                break;

                            case 'location':
                                if (workflowType.affiliation === 'none') {
                                    const user = usersData.byId[workflow.user];
                                    const locationNames = user.locations.map(locationId => {
                                        const location = applicationState.structure.locations.byId[locationId];
                                        return location.name;
                                    });
                                    readableValue = locationNames.join(', ');
                                } else if (workflowType.affiliation === 'member') {
                                    const member = membersData.byId[workflow.affiliatedEntity];
                                    const locationName = applicationState.structure.locations.byId[member.location].name;
                                    readableValue = locationName;
                                } else if (workflowType.affiliation === 'group') {
                                    const group = groupsData.byId[workflow.affiliatedEntity];
                                    const locationName = applicationState.structure.locations.byId[group.location].name;
                                    readableValue = locationName;
                                } else {
                                    readableValue = '-';
                                }
                                break;

                            case 'affiliation':

                                if (workflowType.affiliation === 'none') {
                                    readableValue = '-';
                                } else if (workflowType.affiliation === 'member') {
                                    const member = membersData.byId[workflow.affiliatedEntity];
                                    const memberType = membersData.types.byId[member.type];

                                    const nameField = membersData.types.customFields.byId[memberType.nameFieldId];
                                    const nameFieldValue = getCommonCustomFieldValue(member, 'member', nameField, membersData.types.customFieldOptions, applicationState);

                                    const subtitleField = membersData.types.customFields.byId[memberType.subTitleFieldId];
                                    const subtitleFieldValue = getCommonCustomFieldValue(member, 'member', subtitleField, membersData.types.customFieldOptions, applicationState);

                                    readableValue = `${nameFieldValue} (${subtitleFieldValue})`;
                                } else if (workflowType.affiliation === 'group') {
                                    const group = groupsData.byId[workflow.affiliatedEntity];
                                    const groupType = groupsData.types.byId[group.type];

                                    const nameField = groupsData.types.customFields.byId[groupType.nameFieldId];
                                    const nameFieldValue = getCommonCustomFieldValue(group, 'group', nameField, groupsData.types.customFieldOptions, applicationState);

                                    const subtitleField = groupsData.types.customFields.byId[groupType.subTitleFieldId];
                                    const subtitleFieldValue = getCommonCustomFieldValue(group, 'group', subtitleField, membersData.types.customFieldOptions, applicationState);

                                    readableValue = `${nameFieldValue} (${subtitleFieldValue})`;
                                } else {
                                    readableValue = '-';
                                }
                                break;

                            default:
                                throw new Error('Unknown system field');
                        }
                    } else {
                        const field = workflowsData.types.customFields.byId[fieldId];
                        const fieldOptions = workflowsData.types.customFieldOptions;

                        if (field.type === FieldType.MULTI_SELECT) {
                            const currentProcessState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
                            const selectedOptions = currentProcessState.customFields[field.id];

                            if (Array.isArray(selectedOptions)) {
                                readableValue = selectedOptions.map(optionId => fieldOptions.byId[optionId].name);
                            }
                        } else {
                            readableValue = getWorkflowCustomFieldValue(workflow, workflowsData.types.customFields.byId[fieldId], applicationState);
                        }
                    }

                    (Array.isArray(readableValue) ? readableValue : [readableValue]).forEach(value => {
                        if (value in chartCounts) {
                            chartCounts[value] += 1;
                        } else {
                            chartCounts[value] = 1;
                        }
                    });
                }

                return {
                    id: workflow.id,
                    entries: cells,
                }
            }));

            if (xAxis && yAxis) {
                for (let property in chartValues) {
                    if (chartValues.hasOwnProperty(property)) {
                        chartLabels.push(property);

                        switch (yAxisAggregation) {
                            case 'sum':
                                const sum = chartValues[property].reduce((prev, current) => prev + current, 0);
                                chartData.push(sum);
                                break;
                            case 'average':
                                const average = chartValues[property].reduce((prev, current) => prev + current, 0) / chartValues[property].length;
                                chartData.push(average);
                                break;
                            default:
                                chartData.push(chartValues[property].length);
                        }
                    }
                }
            } else if (xAxis || tableFields.length === 1) {
                const fieldId = xAxis ? xAxis : tableFields[0];

                if (isUUID(fieldId)) {
                    const field = workflowsData.types.customFields.byId[fieldId];
                    if (field.type === FieldType.SINGLE_SELECT || field.type === FieldType.MULTI_SELECT) {
                        const choiceNames = field.choices
                            .filter(choiceId => choiceId in workflowsData.types.customFields.byId && !workflowsData.types.customFields.byId[choiceId].archived)
                            .map(choiceId => workflowsData.types.customFields.byId[choiceId].name);

                        for (const choiceName of choiceNames) {
                            chartLabels.push(choiceName);
                            chartData.push(choiceName in chartCounts ? chartCounts[choiceName] : 0);
                        }

                        for (let property in chartCounts) {
                            if (chartCounts.hasOwnProperty(property) && !choiceNames.includes(property)) {
                                chartLabels.push(property);
                                chartData.push(chartCounts[property]);
                            }
                        }
                        break;
                    }
                } else if (fieldId === 'status' && typeId) {
                    const workflowType = workflowsData.types.byId[typeId];
                    for (const statusId of workflowType.statuses) {
                        const status = workflowsData.types.statuses.byId[statusId];
                        chartLabels.push(status.name);
                        chartData.push(status.name in chartCounts ? chartCounts[status.name] : 0);
                    }
                    break;
                }

                for (let property in chartCounts) {
                    if (chartCounts.hasOwnProperty(property)) {
                        chartLabels.push(property);
                        chartData.push(chartCounts[property]);
                    }
                }
            }

            break;

        default:
            throw new Error('Unknown type');
    }

    const returnData: WidgetData = {
        headings,
        entries,

        chartLabels,
        chartData,

        csvData: [],

        entityIds,

        xAxisFieldName,
        yAxisFieldName,
    };

    return returnData;
}


export function getExportData(
    applicationState: ApplicationState,
    entityIds: Array<string>,
    type: string,
    customFields: Array<string>,
    xAxis: string | undefined,
    yAxis: string | undefined,
    systemFields?: Array<string>,
    exportingSystemFields?: Array<string>,
    exportingCustomFields?: Array<string>
) {

    const usersData = applicationState.users;
    const rolesData = applicationState.structure.roles;
    const membersData = applicationState.members;
    const groupsData = applicationState.groups;
    const workflowsData = applicationState.workflows;

    const csvHeadings: Array<string> = ['Sl. no', 'Name'];
    let csvData: Array<Array<string>> = [];

    let tableFields: Array<string>;
    let customFieldsToExport: Array<string>;

    if (xAxis && yAxis) {
        tableFields = [xAxis, yAxis];
    } else if (xAxis) {
        tableFields = [xAxis];
    } else {
        const selectedSystemFields = systemFields ? systemFields : [];
        tableFields = selectedSystemFields.concat(customFields);
    }

    if (exportingCustomFields) {
        const selectedExportingSystemFields = exportingSystemFields ? exportingSystemFields : [];
        customFieldsToExport = selectedExportingSystemFields.concat(exportingCustomFields);
    } else {
        customFieldsToExport = tableFields;
    }

    const csvFields = customFieldsToExport;

    switch (type) {
        case 'User':

            csvFields.forEach(fieldId => {
                if (!isUUID(fieldId)) {
                    switch (fieldId) {
                        case 'created':
                            csvHeadings.push('Created');
                            break;

                        case 'last_updated':
                            csvHeadings.push('Last updated');
                            break;

                        case 'locations':
                            csvHeadings.push('Locations');
                            break;

                        case 'projects':
                            csvHeadings.push('Projects');
                            break;

                        case 'levels':
                            csvHeadings.push('Levels');
                            break;

                        case 'roles':
                            csvHeadings.push('Roles');
                            break;
                        default:
                            throw new Error('Unknown system field');
                    }

                } else if (fieldId !== usersData.nameFieldId) {
                    const field = usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFields.byId[fieldId] : rolesData.customFields.byId[fieldId];
                    csvHeadings.push(field.name);
                }
            })

            csvData = entityIds.map(((userId, index) => {
                const user = usersData.byId[userId];
                let userName = user.customFields[usersData.nameFieldId];

                userName = getReadableDataForCustomField(userName, usersData.customFields.byId[usersData.nameFieldId], user.id, 'user', applicationState);

                const csvRow: Array<string> = [String(index + 1), userName];

                csvFields.forEach(fieldId => {
                    if (!isUUID(fieldId)) {

                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(user.createdTime).format('MMM DD, YYYY hh:mmA');
                                csvRow.push(readableCreated);
                                break;
                            case 'last_updated':
                                const readableLastUpdated = moment(user.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                csvRow.push(readableLastUpdated);
                                break;
                            case 'projects':
                                const readableProjects = user.projects.map(projectId => getReadableProject(projectId, applicationState.structure.projects)).filter(project => !!project).join(', ');
                                csvRow.push(readableProjects);
                                break;
                            case 'levels':
                                const readableLevels = user.levels.map(levelId => getReadableLevel(levelId, applicationState.structure.levels)).filter(level => !!level).join(', ');
                                csvRow.push(readableLevels);
                                break;
                            case 'roles':
                                const readableRoles = user.roles.map(roleId => getReadableRole(roleId, applicationState.structure.roles)).filter(role => !!role).join(', ');
                                csvRow.push(readableRoles);
                                break;
                            case 'locations':
                                const readableLocations = user.locations.map(locationId => getReadableLocation(locationId, applicationState.structure.locations)).filter(location => !!location).join(', ');
                                csvRow.push(readableLocations);
                                break;
                            default:
                                throw new Error('Unknown system field');
                        }
                    } else if (fieldId !== usersData.nameFieldId) {
                        const field = usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFields.byId[fieldId] : rolesData.customFields.byId[fieldId];
                        const fieldOptions = usersData.customFields.byId.hasOwnProperty(fieldId) ? usersData.customFieldOptions : rolesData.customFieldOptions;
                        const readableValue = getCommonCustomFieldValue(user, 'user', field, fieldOptions, applicationState);

                        csvRow.push(readableValue);
                    }
                });

                return csvRow;
            }));

            break;

        case 'Member':

            csvFields.forEach(fieldId => {
                if (!isUUID(fieldId)) {
                    switch (fieldId) {
                        case 'created':
                            csvHeadings.push('Created');
                            break;

                        case 'last_updated':
                            csvHeadings.push('Last updated');
                            break;

                        case 'location':
                            csvHeadings.push('Location');
                            break;

                        case 'name':
                            csvHeadings.push('Name');
                            break;

                        case 'subtitle':
                            csvHeadings.push('Subtitle');
                            break;

                        case 'last_seen':
                            csvHeadings.push('Last seen');
                            break;

                        default:
                            throw new Error('Unknown system field');
                    }

                } else if (!membersData.types.allEntries.map(memberTypeId => membersData.types.byId[memberTypeId].nameFieldId).includes(fieldId)) {
                    const heading = membersData.types.customFields.byId[fieldId].name;
                    csvHeadings.push(heading);
                }
            });

            csvData = entityIds.map(((memberId, index) => {
                const member = membersData.byId[memberId];
                const memberType = membersData.types.byId[member.type];

                let memberName = member.customFields[memberType.nameFieldId];
                const nameField = membersData.types.customFields.byId[memberType.nameFieldId];
                memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member', applicationState);

                const csvRow: Array<string> = [String(index + 1), memberName];

                csvFields.forEach(fieldId => {
                    if (!isUUID(fieldId)) {

                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(member.createdTime).format('MMM DD, YYYY hh:mmA');
                                csvRow.push(readableCreated);
                                break;

                            case 'last_updated':
                                const readableLastUpdated = moment(member.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                csvRow.push(readableLastUpdated);
                                break;

                            case 'location':
                                const readableLocation = getReadableLocation(member.location, applicationState.structure.locations);
                                csvRow.push(readableLocation);
                                break;

                            case 'name':
                                const readableName = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[memberType.nameFieldId], membersData.types.customFieldOptions, applicationState);
                                csvRow.push(readableName);
                                break;

                            case 'subtitle':
                                const readableSubTitle = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[memberType.subTitleFieldId], membersData.types.customFieldOptions, applicationState);
                                csvRow.push(readableSubTitle);
                                break;

                            case 'last_seen':
                                const readableLastSeen = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[memberType.locationFieldId], membersData.types.customFieldOptions, applicationState);
                                csvRow.push(readableLastSeen);
                                break;
                            default:
                                throw new Error('Unknown system field');
                        }
                    } else {
                        if (!membersData.types.allEntries.map(memberTypeId => membersData.types.byId[memberTypeId].nameFieldId).includes(fieldId)) {
                            const readableValue = getCommonCustomFieldValue(member, 'member', membersData.types.customFields.byId[fieldId], membersData.types.customFieldOptions, applicationState);

                            csvRow.push(readableValue);
                        }
                    }
                });

                return csvRow;
            }));

            break;

        case 'Group':

            csvFields.forEach(fieldId => {

                if (!isUUID(fieldId)) {
                    let readableHeading = '';

                    switch (fieldId) {
                        case 'created':
                            readableHeading = 'Created';
                            break;

                        case 'last_updated':
                            readableHeading = 'Last Updated';
                            break;

                        case 'location':
                            readableHeading = 'Location';
                            break;

                        case 'name':
                            readableHeading = 'Name';
                            break;

                        case 'subtitle':
                            readableHeading = 'Sub title';
                            break;
                        default:
                            throw new Error('Unknown system field');
                    }

                    csvHeadings.push(readableHeading);

                } else if (!groupsData.types.allEntries.map(groupTypeId => groupsData.types.byId[groupTypeId].nameFieldId).includes(fieldId)) {
                    const heading = groupsData.types.customFields.byId[fieldId].name;
                    csvHeadings.push(heading);
                }
            })

            csvData = entityIds.map(((memberId, index) => {
                const group = groupsData.byId[memberId];
                const groupType = groupsData.types.byId[group.type];
                let groupName = group.customFields[groupType.nameFieldId];

                const nameField = groupsData.types.customFields.byId[groupType.nameFieldId];

                groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group', applicationState);

                const csvRow: Array<string> = [String(index + 1), groupName];

                csvFields.forEach(fieldId => {
                    if (!isUUID(fieldId)) {
                        const lastRowIndex = csvData.length - 1;

                        switch (fieldId) {
                            case 'created':
                                const readableCreated = moment(group.createdTime).format('MMM DD, YYYY hh:mmA');
                                csvRow.push(readableCreated);
                                break;

                            case 'last_updated':
                                const readableLastUpdated = moment(group.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                csvRow.push(readableLastUpdated);
                                break;

                            case 'location':
                                const readableLocation = getReadableLocation(group.location, applicationState.structure.locations);
                                csvRow.push(readableLocation);
                                break;

                            case 'name':
                                const readableName = getCommonCustomFieldValue(group, 'group', groupsData.types.customFields.byId[groupType.nameFieldId], groupsData.types.customFieldOptions, applicationState);
                                csvRow.push(readableName);
                                break;

                            case 'subtitle':
                                const readableSubTitle = getCommonCustomFieldValue(group, 'group', groupsData.types.customFields.byId[groupType.subTitleFieldId], groupsData.types.customFieldOptions, applicationState);
                                csvRow.push(readableSubTitle);
                                break;

                            default:
                                throw new Error('Unknown system field');
                        }
                    } else {
                        if (!groupsData.types.allEntries.map(groupTypeId => groupsData.types.byId[groupTypeId].nameFieldId).includes(fieldId)) {
                            const readableValue = getCommonCustomFieldValue(group, 'group', groupsData.types.customFields.byId[fieldId], groupsData.types.customFieldOptions, applicationState);
                            csvRow.push(readableValue);
                        }
                    }
                });

                return csvRow;
            }));
            break;

        case 'Workflow':
            csvHeadings.pop();

            csvFields.forEach(fieldId => {

                if (!isUUID(fieldId)) {
                    let readableHeading = '';

                    switch (fieldId) {
                        case 'created':
                            readableHeading = 'Created';
                            break;

                        case 'last_updated':
                            readableHeading = 'Last Updated';
                            break;

                        case 'type':
                            readableHeading = 'Type';
                            break;

                        case 'subtitle':
                            readableHeading = 'Subtitle';
                            break;

                        case 'status':
                            readableHeading = 'Status';
                            break;

                        case 'is_terminal':
                            readableHeading = 'Is Terminal';
                            break;

                        case 'due_date':
                            readableHeading = 'Due date';
                            break;

                        case 'user':
                            readableHeading = 'User';
                            break;

                        case 'location':
                            readableHeading = 'Location';
                            break;

                        case 'affiliation':
                            readableHeading = 'Member/Group';
                            break;
                        default:
                            throw new Error('Unknown system field');
                    }

                    csvHeadings.push(readableHeading);

                } else {
                    const heading = workflowsData.types.customFields.byId[fieldId].name;
                    csvHeadings.push(heading);
                }
            });

            csvData = entityIds.map(((workflowId, index) => {
                const workflow = workflowsData.byId[workflowId];
                const workflowType = workflowsData.types.byId[workflow.type];

                const csvRow: Array<string> = [String(index + 1)];

                csvFields.forEach(fieldId => {
                    if (!isUUID(fieldId)) {
                        try {

                            switch (fieldId) {
                                case 'created':
                                    const readableCreated = moment(workflow.createdTime).format('MMM DD, YYYY hh:mmA');
                                    csvRow.push(readableCreated);
                                    break;

                                case 'last_updated':
                                    const readableLastUpdated = moment(workflow.lastUpdatedTime).format('MMM DD, YYYY hh:mmA');
                                    csvRow.push(readableLastUpdated);
                                    break;

                                case 'type':
                                    csvRow.push(workflowType.name);
                                    break;

                                case 'subtitle':
                                    if (workflowType.subTitleFieldId) {
                                        const subtitleField = workflowsData.types.customFields.byId[workflowType.subTitleFieldId];
                                        const readableSubTitle = getWorkflowCustomFieldValue(workflow, subtitleField, applicationState);
                                        csvRow.push(readableSubTitle);
                                    } else {
                                        // Code for pushing affiliation in subtitle
                                        if (workflowType.affiliation === 'none') {
                                            csvRow.push('-');
                                        } else if (workflowType.affiliation === 'member') {
                                            const member = membersData.byId[workflow.affiliatedEntity];
                                            const memberType = membersData.types.byId[member.type];

                                            const nameField = membersData.types.customFields.byId[memberType.nameFieldId];
                                            const nameFieldValue = getCommonCustomFieldValue(member, 'member', nameField, membersData.types.customFieldOptions, applicationState);

                                            const subtitleField = membersData.types.customFields.byId[memberType.subTitleFieldId];
                                            const subtitleFieldValue = getCommonCustomFieldValue(member, 'member', subtitleField, membersData.types.customFieldOptions, applicationState);

                                            csvRow.push(`${nameFieldValue} (${subtitleFieldValue})`);
                                        } else if (workflowType.affiliation === 'group') {
                                            const group = groupsData.byId[workflow.affiliatedEntity];
                                            const groupType = groupsData.types.byId[group.type];

                                            const nameField = groupsData.types.customFields.byId[groupType.nameFieldId];
                                            const nameFieldValue = getCommonCustomFieldValue(group, 'group', nameField, groupsData.types.customFieldOptions, applicationState);

                                            const subtitleField = groupsData.types.customFields.byId[groupType.subTitleFieldId];
                                            const subtitleFieldValue = getCommonCustomFieldValue(group, 'group', subtitleField, membersData.types.customFieldOptions, applicationState);

                                            csvRow.push(`${nameFieldValue} (${subtitleFieldValue})`);
                                        }
                                    }
                                    break;

                                case 'status':
                                    try {
                                        const readableStatus = workflowsData.types.statuses.byId[workflow.status].name;
                                        csvRow.push(readableStatus);
                                    } catch (e) {
                                        csvRow.push('-');
                                        console.log(JSON.stringify(workflow));
                                    }
                                    break;

                                case 'is_terminal':
                                    try {
                                        const readableIsTerminal = workflowsData.types.statuses.byId[workflow.status].isTerminal ? 'Yes' : 'No';
                                        csvRow.push(readableIsTerminal);
                                    } catch (e) {
                                        csvRow.push('-');
                                        console.log(JSON.stringify(workflow));
                                    }
                                    break;

                                case 'due_date':
                                    const readableDueDate = moment(workflow.dueDate).format('MMM DD, YYYY');
                                    csvRow.push(readableDueDate);
                                    break;

                                case 'user':
                                    const user = usersData.byId[workflow.user];

                                    const nameField = usersData.customFields.byId[usersData.nameFieldId];
                                    const nameFieldValue = getCommonCustomFieldValue(user, 'user', nameField, usersData.customFieldOptions, applicationState);

                                    const subtitleField = usersData.customFields.byId[usersData.subTitleFieldId];
                                    const subtitleFieldValue = getCommonCustomFieldValue(user, 'user', subtitleField, usersData.customFieldOptions, applicationState);

                                    csvRow.push(`${nameFieldValue} (${subtitleFieldValue})`);
                                    break;

                                case 'location':
                                    if (workflowType.affiliation === 'none') {
                                        const user = usersData.byId[workflow.user];
                                        const locationNames = user.locations.map(locationId => {
                                            const location = applicationState.structure.locations.byId[locationId];
                                            return location.name;
                                        });
                                        csvRow.push(locationNames.join(', '));
                                    } else if (workflowType.affiliation === 'member') {
                                        const member = membersData.byId[workflow.affiliatedEntity];
                                        const locationName = applicationState.structure.locations.byId[member.location].name;
                                        csvRow.push(locationName);
                                    } else if (workflowType.affiliation === 'group') {
                                        const group = groupsData.byId[workflow.affiliatedEntity];
                                        const locationName = applicationState.structure.locations.byId[group.location].name;
                                        csvRow.push(locationName);
                                    }
                                    break;

                                case 'affiliation':

                                    if (workflowType.affiliation === 'none') {
                                        csvRow.push('-');
                                    } else if (workflowType.affiliation === 'member') {
                                        const member = membersData.byId[workflow.affiliatedEntity];
                                        const memberType = membersData.types.byId[member.type];

                                        const nameField = membersData.types.customFields.byId[memberType.nameFieldId];
                                        const nameFieldValue = getCommonCustomFieldValue(member, 'member', nameField, membersData.types.customFieldOptions, applicationState);

                                        const subtitleField = membersData.types.customFields.byId[memberType.subTitleFieldId];
                                        const subtitleFieldValue = getCommonCustomFieldValue(member, 'member', subtitleField, membersData.types.customFieldOptions, applicationState);

                                        csvRow.push(`${nameFieldValue} (${subtitleFieldValue})`);
                                    } else if (workflowType.affiliation === 'group') {
                                        const group = groupsData.byId[workflow.affiliatedEntity];
                                        const groupType = groupsData.types.byId[group.type];

                                        const nameField = groupsData.types.customFields.byId[groupType.nameFieldId];
                                        const nameFieldValue = getCommonCustomFieldValue(group, 'group', nameField, groupsData.types.customFieldOptions, applicationState);

                                        const subtitleField = groupsData.types.customFields.byId[groupType.subTitleFieldId];
                                        const subtitleFieldValue = getCommonCustomFieldValue(group, 'group', subtitleField, membersData.types.customFieldOptions, applicationState);

                                        csvRow.push(`${nameFieldValue} (${subtitleFieldValue})`);
                                    }
                                    break;

                                default:
                                    throw new Error('Unknown system field');
                            }
                        } catch (e) {
                            csvRow.push('-');
                        }
                    } else {
                        const readableValue = getWorkflowCustomFieldValue(workflow, workflowsData.types.customFields.byId[fieldId], applicationState);
                        csvRow.push(readableValue);
                    }
                });

                return csvRow;
            }));

            break;

        default:
            throw new Error('Unknown type');
    }

    return [csvHeadings].concat(csvData);
}
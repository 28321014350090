import { NormalizedModel, Synchronizable } from '../normalized-model';

export const ADD_ERROR = 'ADD_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export interface IAppError extends Synchronizable {
    message: string;
    context: string;
    location: string;
    userId: string;
}

export interface ErrorState extends NormalizedModel<IAppError> {
}

export interface AddErrorAction {
    type: typeof ADD_ERROR,
    payload: IAppError,
}

export interface ClearErrorsAction {
    type: typeof CLEAR_ERRORS,
}

export type ErrorActionTypes = AddErrorAction | ClearErrorsAction;
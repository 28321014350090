import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { translatePhrase } from 'src/shared/helpers/translation';

@Component({
	selector: 'app-table-chart',
	templateUrl: './table-chart.component.html',
	styleUrls: ['./table-chart.component.scss'],
})
export class TableChartComponent implements OnInit, OnChanges {
	show_filter: boolean = false;
	searchText: string = '';
    @Input() tableHeadings: any;
    @Input() tableEntries: any;
    tempTableEntries: any;

    @ViewChild('customTable') customTable: ElementRef;

	constructor() { }

	ngOnInit() {
        this.tempTableEntries = this.tableEntries;
    }
    
    scrollRight() {
        this.customTable.nativeElement.scrollLeft += 50;
    }

	ngOnChanges(simpleChanges: SimpleChanges) {
		if (simpleChanges.tableEntries && JSON.stringify(simpleChanges.tableEntries.currentValue) !== JSON.stringify(simpleChanges.tableEntries.previousValue)) {
            this.tableEntries = simpleChanges.tableEntries.currentValue;
            this.tempTableEntries = simpleChanges.tableEntries.currentValue;
        }
        
        if (simpleChanges.tableHeadings && JSON.stringify(simpleChanges.tableHeadings.currentValue) !== JSON.stringify(simpleChanges.tableHeadings.previousValue)) {
			this.tableHeadings = simpleChanges.tableHeadings.currentValue;
		}
	}

	translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

	searchCSV() {
		if (this.searchText.length > 0) {
			this.tempTableEntries = this.tableEntries.filter((row: any) => {
				return row.entries.some(value => value.toString().toLowerCase().includes(this.searchText.toLowerCase()));
			});
		} else {
			this.tempTableEntries = this.tableEntries;
		}
	}

	getHighlight(word: string) {
		const startIndex = word.toString().toLowerCase().indexOf(this.searchText.toLowerCase());
		if (startIndex !== -1) {
			const matchingString = word.toString().substr(startIndex, this.searchText.length);
			return word.toString().replace(matchingString, "<strong>" + matchingString + "</strong>");
		} else {
			return word;
        }
	}

}

import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { startDataPush } from 'src/shared/store/my-data/actions';
import { ApplicationState } from 'src/shared/store/types';
import { Observable, Subscription } from 'rxjs';
import { select } from '@angular-redux/store';
import { AlertController } from '@ionic/angular';
import { collectDataToPush, hasLocalChanges } from 'src/shared/helpers/synchronize/index';
import { DataToPush } from 'src/shared/helpers/synchronize/types';
import { translatePhrase } from 'src/shared/helpers/translation';
import { clearCurrentSnapshot } from 'src/shared/store/database';
import store from 'src/shared/store/main';
import { clearCache } from "clear-cache";

@Injectable({
	providedIn: 'root'
})
export class RefreshService {
	@select(['myData', 'isPushingData']) isPushingDataSource: Observable<boolean>;
    @select(state => state) applicationStateSource: Observable<ApplicationState>;
	refreshSubscription: Subscription;
    applicationStateSubscription: Subscription;
    applicationState: ApplicationState;
    changedData: {
		hasData: boolean,
		data: DataToPush
	};

	constructor(
		private ngRedux: NgRedux<ApplicationState>,
        public alertController: AlertController
	) { }

    translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

	pushData() {
		this.ngRedux.dispatch(startDataPush());
	}

    pushDataToReload() {
		this.ngRedux.dispatch(startDataPush());
		this.refreshSubscription = this.isPushingDataSource.subscribe(isPushingData => {
			if (!isPushingData) {
				!!this.refreshSubscription && !this.refreshSubscription.closed && this.refreshSubscription.unsubscribe();
				clearCache(true);
			}
		});
	}

    pullToReload() {
        this.applicationStateSubscription = this.applicationStateSource.subscribe((applicationState) => {
            this.applicationState = applicationState;
			this.changedData = collectDataToPush(this.applicationState);
            if (this.changedData.hasData) {
				if (window.navigator.onLine) {
					this.presentAlert();
				} else {
					this.presentNoInternetAlert();
				}
            } else {
				if (window.navigator.onLine) {
					clearCache(true);
				} else {
					this.presentNoInternetAlert();
				}
            }
		});
	}

	async presentNoInternetAlert() {
		const alert = await this.alertController.create({
			header: this.translate('Cannot Reload') + '!',
            mode: 'ios',
			message: this.translate('No internet connection!'),
			buttons: [{
                text: this.translate('Okay'),
                cssClass: 'alert-confirm',
                handler: () => { }
            }]
		});

		await alert.present();
	}

	async setReloadDashboard(isSet: boolean) {
		localStorage.setItem('reloadDashboard', isSet.toString());
	}

	async getReloadDashboard() {
		return (localStorage.getItem('reloadDashboard') === 'true');
	}

    async presentAlert() {
		const alert = await this.alertController.create({
			header: this.translate('Wait') + '!',
            mode: 'ios',
			message: this.translate('The changes you have made will not be saved'),
			buttons: [{
                text: this.translate('Cancel'),
                cssClass: 'alert-danger',
                role: 'cancel',
                handler: () => {
                    this.applicationStateSubscription.unsubscribe();
                }
            }, {
                text: this.translate('Reload'),
                cssClass: 'alert-confirm',
                handler: async () => {
					const myId = localStorage.getItem('myId');
					await clearCurrentSnapshot(myId);
        			clearCache(true);
                }
            }]
		});

		await alert.present();
	}
}

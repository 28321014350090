import { takeEvery, takeLatest, put, delay, select } from 'redux-saga/effects'
import { UPDATE_MY_ID, IUpdateMyIDAction, SET_TOAST_MESSAGE, CHANGE_SUPER_USER_PASSWORD, IChangeSuperUserPassword, ISetToastMessageAction, UPDATE_MY_PERMISSIONS, SET_UP_SAMPLE_PROJECT } from './types';
import { clearToastMessage } from './actions';
import { isUUID } from '../../helpers/utilities';
import { Permissions } from '../permissions/types';
import { setMyPermissions } from '../permissions/actions';
import axios from 'axios';
import { getPermissionsForUser } from '../../helpers/permissions';
import { ApplicationState } from '../types';
import { BASE_URL } from '../url';
import { setupSampleProject } from './sample-project';

function* updateUserPermissions(action: IUpdateMyIDAction) {
    if (isUUID(action.id)) {
        const state: ApplicationState = yield select();
        const finalPermissionSet = getPermissionsForUser(action.id, state);

        yield put(setMyPermissions(finalPermissionSet));
    } else {
        // User is a super user
        yield put(setMyPermissions({
            general: {
                Hierarchy: Permissions.WRITE,
                Locations: Permissions.WRITE,
                Users: Permissions.WRITE,
                UserConfiguration: Permissions.WRITE,
                Members: Permissions.WRITE,
                MembersConfiguration: Permissions.WRITE,
                GroupsConfiguration: Permissions.WRITE,
                Groups: Permissions.WRITE,
                WorkflowsConfiguration: Permissions.WRITE,
                Workflows: Permissions.WRITE,
                OrganizationProfile: Permissions.WRITE,
                Languages: Permissions.WRITE,
                LanguagesConfiguration: Permissions.WRITE,
                Reports: Permissions.WRITE,
                ReportsConfiguration: Permissions.WRITE,
            },
            members: {},
            groups: {},
            workflows: {},
            reports: {},
            languages: {},
        }))
    }
}

function* updateMyPermissions() {
    const applicationState: ApplicationState = yield select();
    if (isUUID(applicationState.myData.id)) {
        const finalPermissionSet = getPermissionsForUser(applicationState.myData.id, applicationState);
        yield put(setMyPermissions(finalPermissionSet));
    } else {
        // User is a super user
        yield put(setMyPermissions({
            general: {
                Hierarchy: Permissions.WRITE,
                Locations: Permissions.WRITE,
                Users: Permissions.WRITE,
                UserConfiguration: Permissions.WRITE,
                Members: Permissions.WRITE,
                MembersConfiguration: Permissions.WRITE,
                GroupsConfiguration: Permissions.WRITE,
                Groups: Permissions.WRITE,
                WorkflowsConfiguration: Permissions.WRITE,
                Workflows: Permissions.WRITE,
                OrganizationProfile: Permissions.WRITE,
                Languages: Permissions.WRITE,
                LanguagesConfiguration: Permissions.WRITE,
                Reports: Permissions.WRITE,
                ReportsConfiguration: Permissions.WRITE,
            },
            members: {},
            groups: {},
            workflows: {},
            reports: {},
            languages: {},
        }))
    }
}

function* clearToastAfterDelay(action: ISetToastMessageAction) {
    yield delay(5000);

    if (!action.persistMessage) {
        yield put(clearToastMessage());
    }
}

function makeResetCall(newPassword: string) {

    const serverUrl = BASE_URL + '/super-user-change-password/';

    return axios.post(serverUrl, {
        password: newPassword,
    }, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });

}

function* updateSuperUserPassword(action: IChangeSuperUserPassword) {

    if (action.password) {
        yield makeResetCall(action.password);
    }
}

export function* watchChangeSuperUserPassword() {
    yield takeLatest(CHANGE_SUPER_USER_PASSWORD, updateSuperUserPassword);
}

export function* watchIDUpdate() {
    yield takeEvery(UPDATE_MY_ID, updateUserPermissions);
}

export function* watchPermissionUpdate() {
    yield takeEvery(UPDATE_MY_PERMISSIONS, updateMyPermissions);
}

export function* watchToastMessage() {
    yield takeLatest(SET_TOAST_MESSAGE, clearToastAfterDelay);
}

export function* watchProjectSetup() {
    yield takeLatest(SET_UP_SAMPLE_PROJECT, setupSampleProject);
}
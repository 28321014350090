import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { StatisticalDetailsPage } from './statistical-details.page';
import { ListCardModule } from '../shared/list-card.module';
import { DateSelectorModule } from '../shared/date-selector.module';

const routes: Routes = [
  {
    path: '',
    component: StatisticalDetailsPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
	ListCardModule,
    DateSelectorModule,
    RouterModule.forChild(routes)
  ],
  declarations: [StatisticalDetailsPage]
})
export class StatisticalDetailsPageModule {}

import { OrganizationState } from '../organization/types';
import { START_DATA_PUSH, COMPLETE_DATA_PUSH, CHANGE_SUPER_USER_PASSWORD, SET_TOAST_MESSAGE, CLEAR_TOAST_MESSAGE, ISetToastMessageAction, IClearToastMessageAction, IChangeSuperUserPassword, IFreezeBackgroundAction, FREEZE_BACKGROUND, IUnFreezeBackgroundAction, UN_FREEZE_BACKGROUND, IUpdateMyPermissionsAction, UPDATE_MY_PERMISSIONS, SetIsTopBarExpandedAction, SET_IS_TOP_BAR_EXPANDED, START_OLDER_DATA_FETCH, COMPLETE_OLDER_DATA_FETCH, SELECT_NUDGE, SelectNudgeAction, NudgeType, ResumePartialDataFetchAction, RESUME_PARTIAL_DATA_FETCH, FetchDataRquestAction, FETCH_DATA_REQUEST, MARK_NETWORK_INTERRUPT, SetIsEditingOrgProfileAction, SET_IS_EDITING_ORG_PROFILE, SetEditingOrgStateAction, SET_EDITING_ORG_STATE, SET_IS_FETCHING_FOR_DATA_UPDATE, SetIsFetchingForDataUpdateAction, SetIsLoggingOutAction, SET_IS_LOGGING_OUT, CLEAR_INFO_MESSAGE, IClearInfoMessageAction, ISetInfoMessageAction, SET_INFO_MESSAGE, SetIsOnlineAction, SET_IS_ONLINE, SetupSampleProjectAction, SET_UP_SAMPLE_PROJECT, SetLastRefreshTimeAction, SET_LAST_REFRESH_TIME } from './types';

export function startDataPush() {
    return {
        type: START_DATA_PUSH,
    }
}

export function completeDataPush() {
    return {
        type: COMPLETE_DATA_PUSH,
    }
}

export function markNetworkInterrupt() {
    return {
        type: MARK_NETWORK_INTERRUPT,
    }
}

export function startOlderDataFetch() {
    return {
        type: START_OLDER_DATA_FETCH,
    }
}

export function completeOlderDataFetch() {
    return {
        type: COMPLETE_OLDER_DATA_FETCH,
    }
}

export function changeSuperUserPassword(password: string): IChangeSuperUserPassword {
    return {
        type: CHANGE_SUPER_USER_PASSWORD,
        password,
    }
}

export function selectNudge(id?: NudgeType): SelectNudgeAction {
    return {
        type: SELECT_NUDGE,
        nudgeId: id,
    };
}

export function setToastMessage(message: string, persistMessage = false): ISetToastMessageAction {
    return {
        type: SET_TOAST_MESSAGE,
        message,
        persistMessage,
    };
}

export function updatePermissions(): IUpdateMyPermissionsAction {
    return {
        type: UPDATE_MY_PERMISSIONS,
    };
}

export function clearToastMessage(): IClearToastMessageAction {
    return {
        type: CLEAR_TOAST_MESSAGE,
    };
}

export function setInfoMessage(message: string, persistMessage = false): ISetInfoMessageAction {
    return {
        type: SET_INFO_MESSAGE,
        message,
        persistMessage,
    };
}

export function clearInfoMessage(): IClearInfoMessageAction {
    return {
        type: CLEAR_INFO_MESSAGE,
    };
}

export function freezeBackground(): IFreezeBackgroundAction {
    return {
        type: FREEZE_BACKGROUND,
    };
}

export function unFreezeBackground(): IUnFreezeBackgroundAction {
    return {
        type: UN_FREEZE_BACKGROUND,
    };
}

export function setIsTopBarExpanded(isExpanded: boolean): SetIsTopBarExpandedAction {
    return {
        type: SET_IS_TOP_BAR_EXPANDED,
        isExpanded,
    };
}

export function setIsFetchingForDataUpdate(isFetchingForDataUpdate: boolean, message: string): SetIsFetchingForDataUpdateAction {
    return {
        type: SET_IS_FETCHING_FOR_DATA_UPDATE,
        isFetchingForDataUpdate,
        message,
    };
}

export function initiateFetchAppData(): FetchDataRquestAction {
    return {
        type: FETCH_DATA_REQUEST,
    }
}

export function resumePartialDataFetch(): ResumePartialDataFetchAction {
    return {
        type: RESUME_PARTIAL_DATA_FETCH,
    }
}

export function setIsEditingOrgProfile(isEditing: boolean): SetIsEditingOrgProfileAction {
    return {
        type: SET_IS_EDITING_ORG_PROFILE,
        isEditing,
    };
}

export function setIsLoggingOut(isLoggingOut: boolean): SetIsLoggingOutAction {
    return {
        type: SET_IS_LOGGING_OUT,
        isLoggingOut,
    };
}

export function setEditingOrgState(orgState: OrganizationState): SetEditingOrgStateAction {
    return {
        type: SET_EDITING_ORG_STATE,
        organizationState: orgState,
    };
}

export function setIsOnline(isOnline: boolean): SetIsOnlineAction {
    return {
        type: SET_IS_ONLINE,
        isOnline,
    };
}

export function setLastRefreshTime(lastRefreshTime: string): SetLastRefreshTimeAction {
    return {
        type: SET_LAST_REFRESH_TIME,
        lastRefreshTime,
    }
}

export function setupSampleProject(): SetupSampleProjectAction {
    return {
        type: SET_UP_SAMPLE_PROJECT,
    }
}
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class IntroductionService {
    changePageUrl: any;
    page_url: string = '';
    god: Observer<string>;
    constructor() {
        this.changePageUrl = Observable.create((observer: Observer<string>) => {
            this.god = observer;
            this.god.next(this.page_url);
        });
    }

    async getPageUrl() {
        return await this.changePageUrl;
    }

    setPageUrl(data: string) {
        this.god.next(data);
    }
}

import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import { WorkflowProcessState, WorkflowState, IWorkflow } from 'src/shared/store/workflows/types';
import { isUUID } from 'src/shared/helpers/utilities';
import { getWorkflowPieceValue } from 'src/shared/store/flowchart/helpers/workflow';
import { IQuestionPiece, IGroupedQuestionPiece, PieceType } from 'src/shared/store/flowchart/pieces/types';
import { ApplicationState } from 'src/shared/store/types';
import { ToastService, ToastType } from '../../services/toast.service';
import { CountrySelectorPage } from '../../country-selector/country-selector.page';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { CustomFieldValueType, FieldType, WorkflowTypeCustomField } from 'src/shared/store/custom-fields/types';
import { getWorkflowQuestionValidationValue } from 'src/shared/store/flowchart/helpers/question';
import { translatePhrase } from 'src/shared/helpers/translation';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { CalendarPage } from '../../calendar/calendar.page';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { first, debounceTime } from 'rxjs/operators';
import { LocationSelectorPage } from '../../location-selector/location-selector.page';

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit, OnChanges, OnDestroy {
    @select(state => state) applicationStateSource: Observable<ApplicationState>;
    applicationState: ApplicationState;
    applicationStateFirstSubscription: Subscription;
    applicationStateDebouncedSubscription: Subscription;
    searchText: string;
    workflowData: WorkflowState;

    today = new Date();

    isQuestionDisabled = false;
    isQuestionRequired = false;

    showDate: boolean = true;

    @Input() workflowId: string;
    @Input() questionId: string;
    @Input() userInput: CustomFieldValueType;
    @Input() questionInputs: {
        [customFieldId: string]: CustomFieldValueType,
    };
    @Input() listQuestionInputs: {
        [listId: string]: {
            [customFieldId: string]: CustomFieldValueType
        }
    };
    @Input() errorMessage: string;
    @Input() overWrittenVariable: string;
    @Input() overWrittenValue: string;
    @Input() isExpanded: boolean;

    @Input() validateAnswer: (questionId: string, answer: CustomFieldValueType, processState: WorkflowProcessState) => string;

    @Output() inputChange = new EventEmitter<CustomFieldValueType>();
    @Output() expandToggle = new EventEmitter();

    customFieldValue: CustomFieldValueType;

    phoneCountryCode: string;
    phoneNumber: string;

    latitude: string;
    longitude: string;

    questionPiece: IQuestionPiece | IGroupedQuestionPiece;
    questionText = '';
    customField: WorkflowTypeCustomField;

    showFileInput: boolean = false;

    textInput: string;
    dateInput: string;
    fileInput: string = '';
    tempDateInput: string;
    choices: Array<{
        name: string,
        value: string
    }> = [];

    constructor(private geolocation: Geolocation, private modalController: ModalController,
        public toastService: ToastService) {
        this.textInput = typeof this.userInput === 'string' ? this.userInput : '';
        this.dateInput = typeof this.userInput === 'string' ? this.userInput : '';
        this.fileInput = typeof this.userInput === 'string' ? this.userInput : '';

        if (moment(this.dateInput, 'YYYY-MM-DD', true).isValid()) {
            this.tempDateInput = moment(this.dateInput).format('DD-MM-YYYY');
        }
    }

    toggleShowMore() {
        this.expandToggle.emit(this.customField.id);
    }

    getFormattedDate(date: any, format: string) {
        return date ? moment(date).format(format) : '';
    }

    translate(phrase: string) {
        return translatePhrase(phrase.trim());
    }

    async locate() {
        if (navigator.onLine) {
            const modal = await this.modalController.create({
                component: LocationSelectorPage,
                componentProps: {
                    lat: this.latitude,
                    lng: this.longitude
                }
            });

            modal.onDidDismiss().then((locationData: any) => {
                if (locationData && locationData.data) {
                    let location = locationData.data.coordinates;
                    this.latitude = location.lat.toString();
                    this.longitude = location.lng.toString();
                    this.updateLocation();
                }
            });

            return await modal.present();
        } else {
            this.toastService.presentToastWithOptions("Seems like  you're offline", ToastType.INFO);
            this.geolocation.getCurrentPosition().then((resp) => {
                this.latitude = resp.coords.latitude.toString();
                this.longitude = resp.coords.longitude.toString();
                this.updateLocation();
            }).catch((error) => {
                console.log(error);
                this.toastService.presentToastWithOptions('Error getting location', ToastType.ERROR);
            });
        }
    }

    searchChoices() {
        if (this.searchText.length > 0) {
            this.choices = this.getValidChoices().filter(choice => choice.name.toLowerCase().includes(this.searchText.toLowerCase()));
        } else {
            this.choices = this.getValidChoices();
        }
    }

    getTrimmedString(data: string) {
        return data.trim();
    }

    getWorkflowProcessState = (workflow: IWorkflow) => {
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
            displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        if (this.overWrittenVariable) {
            processState.variables[this.overWrittenVariable] = this.overWrittenValue;
        }

        return processState;
    }

    getQuestion = () => {

        if (!this.workflowData || !this.questionPiece || !this.customField) {
            return '';
        }

        const workflow = this.workflowData.byId[this.workflowId];
        const processState = this.getWorkflowProcessState(workflow);

        let expandedQuestion = this.questionPiece.question && isUUID(this.questionPiece.question) ?
            getWorkflowPieceValue(this.applicationState, processState, workflow.id, this.questionPiece.question) : this.questionPiece.question;

        if (expandedQuestion === '' || typeof expandedQuestion === 'undefined') {
            expandedQuestion = this.customField.name;
        }

        if (typeof expandedQuestion !== 'string') {
            throw new Error('A question value needs to be a string');
        }

        if (expandedQuestion === '') {
            expandedQuestion = this.customField.name;
        }

        return expandedQuestion;
    }

    checkIfQuestionDisabled = () => {

        if (!this.workflowData || !this.questionPiece || !this.customField) {
            return false;
        }

        const workflow = this.workflowData.byId[this.workflowId];
        let answer = this.userInput;

        if (this.customField.type === FieldType.SINGLE_SELECT && typeof answer === 'string') {
            answer = this.workflowData.types.customFieldOptions.byId[answer].name;
        } else if (this.customField.type === FieldType.MULTI_SELECT && Array.isArray(answer)) {
            answer = answer.map(optionId => this.workflowData.types.customFieldOptions.byId[optionId].name);
        } else if (this.customField.type === FieldType.NUMBER && typeof answer !== 'undefined' && !isNaN(Number(answer))) {
            answer = Number(answer);
        } else if (this.customField.type === FieldType.BOOLEAN) {
            answer = answer === 'Yes';
        }

        const allAnswers = typeof this.listQuestionInputs === 'undefined' ? { ...this.questionInputs } : { ...this.listQuestionInputs };

        const disabledWorkflowProcessState = this.getWorkflowProcessState(workflow);
        const isDisabled = this.questionPiece.isDisabledPiece ?
            !!getWorkflowQuestionValidationValue(this.applicationState, disabledWorkflowProcessState, workflow.id,
                this.questionId, answer, allAnswers, this.questionPiece.isDisabledPiece) : false;

        return isDisabled;
    }

    checkIfQuestionRequired = () => {

        if (!this.workflowData || !this.questionPiece || !this.customField) {
            return false;
        }

        const workflow = this.workflowData.byId[this.workflowId];
        let answer = this.userInput;

        if (this.customField.type === FieldType.SINGLE_SELECT && typeof answer === 'string') {
            answer = this.workflowData.types.customFieldOptions.byId[answer].name;
        } else if (this.customField.type === FieldType.MULTI_SELECT && Array.isArray(answer)) {
            answer = answer.map(optionId => this.workflowData.types.customFieldOptions.byId[optionId].name);
        } else if (this.customField.type === FieldType.NUMBER && typeof answer !== 'undefined' && !isNaN(Number(answer))) {
            answer = Number(answer);
        } else if (this.customField.type === FieldType.BOOLEAN) {
            answer = answer === 'Yes';
        }

        const allAnswers = typeof this.listQuestionInputs === 'undefined' ? { ...this.questionInputs } : { ...this.listQuestionInputs };

        const requiredWorkflowProcessState = this.getWorkflowProcessState(workflow);
        const isRequired = this.questionPiece.isRequiredPiece ?
            !!getWorkflowQuestionValidationValue(this.applicationState, requiredWorkflowProcessState, workflow.id,
                this.questionId, answer, allAnswers, this.questionPiece.isRequiredPiece) : false;

        return isRequired;
    }

    getDefaultValue() {
        if (!this.workflowData || !this.questionPiece || !this.customField) {
            return false;
        }

        let lastStoredInput: CustomFieldValueType = '';
        const workflow = this.workflowData.byId[this.workflowId];

        if (this.workflowData.screenInputs[this.workflowId] && workflow.historyIndex < workflow.history.length - 1) {
            const workflowScreenInputs = this.workflowData.screenInputs[workflow.id].find(screenInputs => workflow.historyIndex === screenInputs.workflowIndex);

            if (!!workflowScreenInputs) {
                if (this.overWrittenValue) {
                    lastStoredInput = workflowScreenInputs.groupedAnswers[this.overWrittenValue][this.questionPiece.customFieldId];
                } else {
                    lastStoredInput = workflowScreenInputs.answers[this.questionPiece.customFieldId];
                }
            }
        }

        if (typeof this.userInput === 'undefined') {
            if (lastStoredInput) {
                this.inputChange.emit(lastStoredInput);
            } else {
                const defaultWorkflowProcessState = this.getWorkflowProcessState(workflow);

                let defaultValue = this.questionPiece.default && isUUID(this.questionPiece.default) ?
                    getWorkflowPieceValue(this.applicationState, defaultWorkflowProcessState, workflow.id,
                        this.questionPiece.default) : this.questionPiece.default;

                if (Array.isArray(defaultValue)) {

                    if (defaultValue.length > 0 && Array.isArray(defaultValue[0])) {
                        // Cannot be a multidimensional array
                        throw new Error('The value cannot be a multi-dimensional array')
                    }

                    defaultValue = defaultValue as Array<string>;
                }

                if (typeof defaultValue !== 'undefined') {
                    if (this.customField.type === FieldType.SINGLE_SELECT) {
                        defaultValue = this.customField.choices.find(optionId => {
                            const option = this.workflowData.types.customFieldOptions.byId[optionId];

                            return option.name === defaultValue;
                        });
                    } else if (this.customField.type === FieldType.MULTI_SELECT) {
                        defaultValue = this.customField.choices.filter(optionId => {
                            const option = this.workflowData.types.customFieldOptions.byId[optionId];

                            if (!Array.isArray(defaultValue)) {
                                throw new Error('The default value must be an array of strings');
                            }

                            if (Array.isArray(defaultValue)) {

                                if (defaultValue.length > 0 && Array.isArray(defaultValue[0])) {
                                    // Cannot be a multidimensional array
                                    throw new Error('The value cannot be a multi-dimensional array')
                                }

                                defaultValue = defaultValue as Array<string>;
                            }

                            return defaultValue.includes(option.name);
                        });
                    } else if (this.customField.type === FieldType.BOOLEAN) {
                        if (defaultValue === true) {
                            defaultValue = 'Yes';
                        }

                        if (defaultValue === false) {
                            defaultValue = 'No';
                        }
                    } else if (this.customField.type === FieldType.DATE) {
                        if (typeof defaultValue === 'string') {
                            defaultValue = moment(defaultValue).format('YYYY-MM-DD');
                        }
                    }

                    this.inputChange.emit(defaultValue);
                }
            }
        }
    }

    getValidChoices = () => {

        if (!this.workflowData || !this.questionPiece) {
            return [];
        }

        const workflow = this.workflowData.byId[this.workflowId];
        const processState = this.getWorkflowProcessState(workflow);

        let allowedChoices: Array<string> = [];

        if (this.customField.type === FieldType.SINGLE_SELECT) {
            allowedChoices = this.customField.choices.filter(optionId => {
                const errorMessage = this.validateAnswer(this.questionId,
                    this.workflowData.types.customFieldOptions.byId[optionId].name, processState);

                return !errorMessage;
            });
        } else if (this.customField.type === FieldType.MULTI_SELECT) {
            allowedChoices = this.customField.choices.filter(optionId => {
                const errorMessage = this.validateAnswer(this.questionId,
                    [this.workflowData.types.customFieldOptions.byId[optionId].name], processState);

                return !errorMessage;
            });
        }

        return allowedChoices.map(choiceId => {
            return {
                name: this.workflowData.types.customFieldOptions.byId[choiceId].name,
                value: choiceId,
            };
        });
    }

    selectChoice = (value: string) => {
        if (this.userInput !== value) {
            this.inputChange.emit(value);
        } else {
            this.inputChange.emit(undefined);
        }
    }

    updatePhone = (event?: KeyboardEvent) => {
        if (this.customField.type === FieldType.PHONE && event && event.key) {
            const invalidNumberKeys = ["+", "-", "e", "E"];

            if (invalidNumberKeys.includes(event.key)) {
                event.preventDefault();
                return;
            }
        }
        this.inputChange.emit(this.phoneCountryCode + ' ' + this.phoneNumber);
    }

    async selectCountryModal() {
        const modal = await this.modalController.create({
            component: CountrySelectorPage,
            componentProps: {
                countryCode: this.phoneCountryCode
            }
        });

        modal.onDidDismiss().then((data) => {
            this.phoneCountryCode = data.data;
            this.updatePhone();
        });

        return await modal.present();
    }

    updateLocation = (event?: Event) => {
        const invalidNumberKeys = ["+", "-", "e", "E"];

        if (
            event &&
            (invalidNumberKeys.some(character => this.latitude.includes(character))) ||
            (invalidNumberKeys.some(character => this.longitude.includes(character)))
        ) {
            event.preventDefault();
            return;
        }

        this.inputChange.emit(this.latitude + ' ' + this.longitude);
    }

    isMultipleChoiceSelected = (value: string) => {
        return Array.isArray(this.userInput) && this.userInput.length > 0 && this.userInput.includes(value)
    }

    selectMultipleChoice = (value: string) => {
        const newValue = Array.isArray(this.userInput) ? this.userInput : [];

        if (newValue.includes(value)) {
            newValue.splice(newValue.indexOf(value), 1);
        } else {
            newValue.push(value);
        }

        this.inputChange.emit(newValue);
    }

    async showCalendar(isDate: boolean, isTime: boolean, preSelectedDate?: string) {
        const modal = await this.modalController.create({
            component: CalendarPage,
            componentProps: {
                isDate: isDate,
                isTime: isTime,
                preSelectedDate: preSelectedDate ? preSelectedDate : new Date()
            }
        });

        modal.onDidDismiss().then((data) => {
            if (data.data) {
                this.dateInput = data.data.newDate;

                if (moment(this.dateInput, 'YYYY-MM-DD', true).isValid()) {
                    this.tempDateInput = moment(this.dateInput).format('DD-MM-YYYY');
                }
                this.updateDate();
            }
        });

        return await modal.present();
    }

    validateNumber = (event: KeyboardEvent) => {
        const invalidNumberKeys = ["+", "-", "e", "E"];

        if (invalidNumberKeys.includes(event.key)) {
            event.preventDefault();
            return;
        }
    }

    updateValue = () => {
        this.inputChange.emit(this.textInput);
    }

    updateNumericValue = () => {
        this.inputChange.emit(String(this.textInput))
    }



    setDefaultDate() {
        this.dateInput = moment().format('YYYY-MM-DD');
        this.inputChange.emit(this.dateInput);
    }

    getFileName() {
        let key = this.workflowId + this.questionId;

        if (this.overWrittenValue) {
            key += this.overWrittenValue;
        }

        if (!localStorage.getItem('filenames')) {
            localStorage.setItem('filenames', JSON.stringify({}));
        }

        const fileNames = JSON.parse(localStorage.getItem('filenames'));

        if (key in fileNames) {
            return fileNames[key];
        } else {
            return 'Please select a file';
        }
    }

    storeFileName(fileName: string) {
        let key = this.workflowId + this.questionId;

        if (this.overWrittenValue) {
            key += this.overWrittenValue;
        }

        const fileNames = JSON.parse(localStorage.getItem('filenames'));
        fileNames[key] = fileName;
        localStorage.setItem('filenames', JSON.stringify(fileNames));
    }

    removeFileName() {
        let key = this.workflowId + this.questionId;

        if (this.overWrittenValue) {
            key += this.overWrittenValue;
        }

        const fileNames = JSON.parse(localStorage.getItem('filenames'));
        delete (fileNames[key]);
        localStorage.setItem('filenames', JSON.stringify(fileNames));
    }

    handleFileInputChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file: File = event.target.files[0];
            this.storeFileName(file.name);

            const reader = new FileReader();
            reader.onload = async e => {
                const rawFileData = String(reader.result);
                this.inputChange.emit(rawFileData);
            };

            reader.readAsDataURL(file);
        } else {
            this.removeFileName();
            this.inputChange.emit(undefined);
        }
    }

    getDateInput(e: any) {
        this.tempDateInput = e;
        console.log(e);
        this.updateDate();
    }

    checkIfInvalid() {
        if (!moment(this.tempDateInput, 'DD-MM-YYYY', true).isValid()) {
            this.toastService.presentToastWithOptions("Invalid Date, Format: DD-MM-YYYY", ToastType.ERROR);
            this.errorMessage = 'Invalid Date';

            setTimeout(() => this.errorMessage = '', 2000);
        } else {
            this.updateDate();
        }
    }

    updateDate = () => {
        if (moment(this.tempDateInput, 'DD-MM-YYYY', true).isValid()) {
            this.dateInput = moment(this.tempDateInput, 'DD-MM-YYYY').format('YYYY-MM-DD');
            this.inputChange.emit(this.dateInput);
        } else {
            this.toastService.presentToastWithOptions("Invalid Date, Format: DD-MM-YYYY", ToastType.ERROR);
            this.errorMessage = 'Invalid Date';
            setTimeout(() => this.errorMessage = '', 2000);

            this.inputChange.emit(null)
        }
    }

    renderQuestionData() {
        if (typeof this.applicationState === 'undefined') {
            return;
        }

        const piece = this.applicationState.flowchart.pieces.byId[this.questionId];

        if (piece.type !== PieceType.QUESTION && piece.type !== PieceType.GROUPED_QUESTION) {
            throw new Error('The question ID must have a question type');
        }

        this.questionPiece = piece;
        this.customField = this.workflowData.types.customFields.byId[this.questionPiece.customFieldId];

        this.questionText = this.getQuestion();

        this.isQuestionDisabled = this.checkIfQuestionDisabled();
        this.isQuestionRequired = this.checkIfQuestionRequired();

        this.choices = this.getValidChoices();

        this.getDefaultValue();

        let customFieldValue = this.userInput;

        if (typeof customFieldValue === 'undefined') {
            customFieldValue = '';
        }

        if (this.customField.type === FieldType.PHONE) {

            if (typeof customFieldValue !== 'string') {
                throw new Error('A phone field should have a string value');
            }

            this.phoneCountryCode = '+91';
            this.phoneNumber = '';

            if (customFieldValue.split(' ').length > 1) {
                this.phoneCountryCode = customFieldValue.split(' ')[0];
                this.phoneNumber = customFieldValue.split(' ')[1];
            }

        }

        if (this.customField.type === FieldType.LOCATION) {

            if (typeof customFieldValue !== 'string') {
                throw new Error('A location field should have a string value');
            }

            this.latitude = '';
            this.longitude = '';

            if (customFieldValue.split(' ').length > 1) {
                this.latitude = customFieldValue.split(' ')[0];
                this.longitude = customFieldValue.split(' ')[1];
            }

        }
    }

    updateQuestion(applicationState: ApplicationState) {
        this.applicationState = applicationState;
        this.workflowData = applicationState.workflows;
        this.renderQuestionData();
    }

    ngOnInit() {
        this.applicationStateFirstSubscription = this.applicationStateSource.pipe(first()).subscribe(applicationState => this.updateQuestion(applicationState));
        this.applicationStateDebouncedSubscription = this.applicationStateSource.pipe(debounceTime(500)).subscribe(applicationState => this.updateQuestion(applicationState));
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.questionId &&
            simpleChanges.questionId.currentValue !== simpleChanges.questionId.previousValue) {
            this.renderQuestionData();
        }

        if (simpleChanges.questionInputs) {
            let currentValue: {
                [questionId: string]: string | Array<string>,
            } = {};

            let previousValue: {
                [questionId: string]: string | Array<string>,
            } = {};

            // Deep copy the current and previous values
            if (simpleChanges.questionInputs.currentValue) {
                currentValue = JSON.parse(JSON.stringify(simpleChanges.questionInputs.currentValue))
            }

            if (simpleChanges.questionInputs.previousValue) {
                previousValue = JSON.parse(JSON.stringify(simpleChanges.questionInputs.previousValue))
            }

            // Delete the answer for the current question
            if (currentValue[this.questionId]) {
                delete currentValue[this.questionId];
            }

            if (previousValue[this.questionId]) {
                delete previousValue[this.questionId];
            }

            // Check if the values for any of the other questions have changed
            if (JSON.stringify(currentValue) !== JSON.stringify(previousValue)) {
                // If yes, re-render the question again
                this.renderQuestionData();
            }

        }

        if (simpleChanges.userInput &&
            simpleChanges.userInput.currentValue !== simpleChanges.userInput.previousValue) {
            this.userInput = simpleChanges.userInput.currentValue;
            this.textInput = typeof simpleChanges.userInput.currentValue === 'string' ? simpleChanges.userInput.currentValue : '';
            this.dateInput = typeof simpleChanges.userInput.currentValue === 'string' ? simpleChanges.userInput.currentValue : '';
            this.fileInput = typeof simpleChanges.userInput.currentValue === 'string' ? simpleChanges.userInput.currentValue : '';

            if (moment(this.dateInput, 'YYYY-MM-DD').isValid()) {
                this.tempDateInput = moment(this.dateInput).format('DD-MM-YYYY');
            } else {
                this.tempDateInput = '';
                this.dateInput = '';
                this.showDate = false;

                setTimeout(() => {
                    this.showDate = true;
                }, 1);
            }
        }

        if (simpleChanges.errorMessage &&
            simpleChanges.errorMessage.currentValue !== simpleChanges.errorMessage.previousValue) {
            this.errorMessage = simpleChanges.errorMessage.currentValue;
        }

        if (simpleChanges.overWrittenVariable &&
            simpleChanges.overWrittenVariable.currentValue !== simpleChanges.overWrittenVariable.previousValue) {
            this.overWrittenVariable = simpleChanges.overWrittenVariable.currentValue;
        }

        if (simpleChanges.overWrittenValue &&
            simpleChanges.overWrittenValue.currentValue !== simpleChanges.overWrittenValue.previousValue) {
            this.overWrittenValue = simpleChanges.overWrittenValue.currentValue;
        }

        if (simpleChanges.isExpanded &&
            simpleChanges.isExpanded.currentValue !== simpleChanges.isExpanded.previousValue) {
            this.isExpanded = simpleChanges.isExpanded.currentValue;
        }
    }

    ngOnDestroy() {
        if (this.applicationStateFirstSubscription) {
            this.applicationStateFirstSubscription.unsubscribe();
        }

        if (this.applicationStateDebouncedSubscription) {
            this.applicationStateDebouncedSubscription.unsubscribe();
        }
    }

    callNumber(phone: string) {
        window.open('tel:' + phone);
    }

    navigate() {
        var url = "https://maps.google.com/?q=" + this.latitude + '&' + this.longitude;
        window.open(url);
    }

}

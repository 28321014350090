import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { DateSelectorComponent } from '../date-selector/date-selector.component';

@NgModule({
	declarations: [
		DateSelectorComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		IonicModule
	],
	exports: [
		DateSelectorComponent
	]
})
export class DateSelectorModule { }

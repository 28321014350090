import store from "../../main";
import { ApplicationState } from "../../types";
import { WorkflowProcessState } from "../../workflows/types";
import { AllPieceTypes, PieceType } from "../pieces/types";

function getNextPieceId(pieceId: string, executionStack: Array<string>, state: ApplicationState, backTrack = true) {
    let stepPiece = state.flowchart.pieces.byId[pieceId];

    if (stepPiece.type === PieceType.FOR && stepPiece.innerPiece) {
        if (stepPiece.innerPiece) {
            executionStack.push(stepPiece.id);
            return stepPiece.innerPiece;
        }
    }

    if ('nextPiece' in stepPiece && stepPiece.nextPiece) {
        return stepPiece.nextPiece;
    }

    if (backTrack) {
        while (executionStack.length > 0) {
            const stackPieceId = executionStack.pop();

            if (stackPieceId) {
                const stackPiece = state.flowchart.pieces.byId[stackPieceId];
                if ('nextPiece' in stackPiece) {
                    return stackPiece.nextPiece;
                }
            }
        }
    }

    return undefined;
}

function completionPercentageFromPieceId(
    startPieceId: string,
    processState: WorkflowProcessState,
    executionStack: Array<string>,
    state: ApplicationState
) {
    let totalSteps = 0;
    let remainingSteps = 0;

    let currentPieceId = processState.lastComputedPiece;

    if (!currentPieceId) {
        return 0.0;
    }

    if (processState.displayingQuestionPieceId) {
        currentPieceId = processState.displayingQuestionPieceId;
    } else if (processState.displayingShowPieceId) {
        currentPieceId = processState.displayingShowPieceId;
    } else if (processState.displayingGroupPieceId) {
        currentPieceId = processState.displayingGroupPieceId;
    } else if (processState.displayingTransferPieceId) {
        currentPieceId = processState.displayingTransferPieceId;
    } else if (processState.displayingContinuePieceId) {
        currentPieceId = processState.displayingContinuePieceId;
    } else if (processState.displayingAddWorkflowPieceId) {
        currentPieceId = processState.displayingAddWorkflowPieceId;
    } else if (processState.createdWorkflowId) {
        currentPieceId = processState.createdWorkflowId;
    }

    let stepPiece: AllPieceTypes | undefined = state.flowchart.pieces.byId[startPieceId];
    let hasCompletedStep = true;

    while (stepPiece) {

        if (stepPiece.id === currentPieceId) {
            hasCompletedStep = false;
        }

        if (hasCompletedStep) {
            if (processState.executionStack.includes(stepPiece.id)) {
                hasCompletedStep = false;
            }
        }

        if (
            (stepPiece.type === PieceType.ADD_WORKFLOW) ||
            (stepPiece.type === PieceType.CONTINUE) ||
            (stepPiece.type === PieceType.GROUP) ||
            (stepPiece.type === PieceType.QUESTION) ||
            (stepPiece.type === PieceType.CHOOSE) ||
            (stepPiece.type === PieceType.SHOW) ||
            (stepPiece.type === PieceType.TRANSFER_WORKFLOW) ||
            (stepPiece.type === PieceType.SPLIT)
        ) {
            totalSteps += 1;

            if (!hasCompletedStep) {
                remainingSteps += 1;
            }
        }

        const nextPieceId = getNextPieceId(stepPiece.id, executionStack, state);

        if (nextPieceId) {
            stepPiece = state.flowchart.pieces.byId[nextPieceId];
        } else {
            stepPiece = undefined;
        }
    }

    return ((totalSteps - remainingSteps) * 100.0) / totalSteps;
}

export function completionPercentageOfWorkflow(workflowId: string, applicationState?: ApplicationState) {
    const state: ApplicationState = applicationState ? applicationState : store.getState();
    let totalSteps = 0;
    let remainingSteps = 0;

    const workflow = state.workflows.byId[workflowId];
    const workflowType = state.workflows.types.byId[workflow.type];
    const workflowStatus = state.workflows.types.statuses.byId[workflow.status];

    if (workflowStatus.isTerminal) {
        return 100.0;
    }

    const processState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
    const executionStack: Array<string> = [];

    const startPieceId = workflowType.startPiece.piece;

    let completionPercentage: number;

    completionPercentage = completionPercentageFromPieceId(
        startPieceId,
        processState,
        executionStack,
        state
    );

    if (!workflowStatus.isTerminal && completionPercentage === 100) {
        const betaStartPieceId = workflowType.betaStartPiece.piece;

        completionPercentage = completionPercentageFromPieceId(
            betaStartPieceId,
            processState,
            executionStack,
            state
        );

        if (isNaN(completionPercentage)) {
            completionPercentage = 100;
        }
    }

    return completionPercentage;
}
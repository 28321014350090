import { MemberTypeState, SELECT_MEMBER_TYPE, UN_SELECT_MEMBER_TYPE, RE_ORDER_MEMBER_TYPES, ADD_MEMBER_TYPE, UPDATE_MEMBER_TYPE, DELETE_MEMBER_TYPE, SELECT_MEMBER_TYPE_CUSTOM_FIELD, UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD, ADD_MEMBER_TYPE_CUSTOM_FIELD, DELETE_MEMBER_TYPE_CUSTOM_FIELD, UPDATE_MEMBER_TYPE_CUSTOM_FIELD, SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION, UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION, RE_ORDER_MEMBER_TYPE_CUSTOM_FIELD_OPTION, ADD_MEMBER_TYPE_CUSTOM_FIELD_OPTION, DELETE_MEMBER_TYPE_CUSTOM_FIELD_OPTION, UPDATE_MEMBER_TYPE_CUSTOM_FIELD_OPTION, UPDATE_MEMBER_TYPE_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE, REGISTER_MEMBER_TYPE_CUSTOM_FIELD_VARIABLE, ADD_MEMBER_TO_MEMBER_TYPE, REMOVE_MEMBER_FROM_MEMBER_TYPE, ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_ADD, UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_ADD, DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_ADD, UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE, DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE, ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_UPDATE, IMemberType, UPDATE_MEMBER_TYPES_DATA, UPDATE_MEMBER_TYPE_CUSTOM_FIELDS_DATA, UPDATE_MEMBER_TYPE_CUSTOM_FIELD_OPTIONS_DATA, SYNCHRONIZE_MEMBER_TYPES_DATA, SYNCHRONIZE_MEMBER_TYPE_CUSTOM_FIELDS_DATA, SYNCHRONIZE_MEMBER_TYPE_CUSTOM_FIELD_OPTIONS_DATA, CLEAR_MEMBER_TYPES_DELTA, INSTANTIATE_MEMBER_TYPE, INSTANTIATE_MEMBER_TYPE_CUSTOM_FIELD, INSTANTIATE_MEMBER_TYPE_CUSTOM_FIELD_OPTION, ADD_ACTION_TO_MEMBER_TYPE, REMOVE_ACTION_FROM_MEMBER_TYPE, BULK_ADD_MEMBERS_TO_MEMBER_TYPES, BULK_REMOVE_MEMBERS_FROM_MEMBER_TYPES } from './types';
import { selectCustomField, unSelectCustomField, addCustomField, deleteCustomField, updateCustomField, selectCustomFieldOption, unSelectCustomFieldOption, reOrderCustomFieldOptions, addCustomFieldOption, deleteCustomFieldOption, updateCustomFieldOption, updateStartPieceForCustomField, setIsolatedPieceForCustomField, removeIsolatedPieceForCustomField, registerVariableForCustomField, updateCustomFields, updateCustomFieldOptions, synchronizeCustomFields, synchronizeCustomFieldOptions, clearDeltaForCustomFields } from '../../custom-fields';
import { MemberActionTypes } from '../types';
import { mergeReverseLinkArrays, reOrderList } from '../../../helpers/utilities';
import { addEntity, deleteEntity, updateEntity, updateEntries, synchronizeEntries, clearDelta, synchronizeCustomFieldReverseLinks, synchronizeCustomFieldOptionReverseLinks } from '../../normalized-model';
import { actionsReducer, initialState as memberTypeActionsInitialState } from './actions/reducer';
import { ADD_MEMBER_TYPE_ACTION, DELETE_MEMBER_TYPE_ACTION, INSTANTIATE_MEMBER_TYPE_ACTION, RE_ORDER_MEMBER_TYPE_ACTIONS } from './actions/types';

export const initialState: MemberTypeState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],
    selected: undefined,

    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    reverseLinks: {},

    actions: memberTypeActionsInitialState,

    customFields: {
        byId: {},
        allFields: [],
    },
    customFieldOptions: {
        byId: {},
        allOptions: [],
    },
    selectedField: undefined,
    selectedOption: undefined,
    createdCustomFieldIds: new Set(),
    updatedCustomFieldIds: new Set(),
    deletedCustomFieldIds: new Set(),
    createdCustomFieldOptionIds: new Set(),
    updatedCustomFieldOptionIds: new Set(),
    deletedCustomFieldOptionIds: new Set(),

    areMemberTypesReordered: false,
    reOrderedActions: {},
    reOrderedCustomFields: {},
    reOrderedCustomFieldOptions: {},
};

export function memberTypesReducer(state = initialState, action: MemberActionTypes): MemberTypeState {
    state = {
        ...state,
        actions: actionsReducer(state.actions, action),
    };

    let newState: MemberTypeState;
    let memberTypeManagement: undefined | {
        workflowTypeId: string,
        lastQuestionPiece: string,
        lastStorePiece: string,
        customFieldMap: {
            [memberTypeCustomFieldId: string]: string,  // ID of the custom field in the managed workflow
        },
    };

    switch(action.type) {

        // Member type actions
        
        case SELECT_MEMBER_TYPE:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_MEMBER_TYPE:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_MEMBER_TYPES:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
                areMemberTypesReordered: true,
            };

        case ADD_MEMBER_TYPE:
            return addEntity<MemberTypeState, IMemberType>(state, action.payload);

        case INSTANTIATE_MEMBER_TYPE:
            return addEntity<MemberTypeState, IMemberType>(state, action.payload);
        
        case DELETE_MEMBER_TYPE:
            return deleteEntity<MemberTypeState, IMemberType>(state, action.id, action.currentTime);
        
        case UPDATE_MEMBER_TYPE:
            return updateEntity<MemberTypeState, IMemberType>(state, action.payload, action.currentTime);
        
        case ADD_ACTION_TO_MEMBER_TYPE:
            const newActions = state.byId[action.memberTypeId].actions ? state.byId[action.memberTypeId].actions.concat(action.actionId) : [action.actionId];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        actions: newActions,
                    },
                },
            }
        
        case REMOVE_ACTION_FROM_MEMBER_TYPE:
            const remainingActions = state.byId[action.memberTypeId].actions ? state.byId[action.memberTypeId].actions.filter(memberId => memberId !== action.actionId) : [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        actions: remainingActions,
                    },
                },
            }
        
        case ADD_MEMBER_TO_MEMBER_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        members: state.byId[action.memberTypeId].members.concat(action.memberId),
                    },
                },
            }
        
        case REMOVE_MEMBER_FROM_MEMBER_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        members: state.byId[action.memberTypeId].members.filter(memberId => memberId !== action.memberId),
                    },
                },
            }
        
        case BULK_ADD_MEMBERS_TO_MEMBER_TYPES:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                }
            };

            for (const linkData of action.links) {
                state.byId[linkData.memberTypeId] = {
                    ...state.byId[linkData.memberTypeId],
                    members: state.byId[linkData.memberTypeId].members.concat(linkData.memberId),
                }
            }

            return state;
        
        case BULK_REMOVE_MEMBERS_FROM_MEMBER_TYPES:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                }
            };

            for (const linkData of action.links) {
                state.byId[linkData.memberTypeId] = {
                    ...state.byId[linkData.memberTypeId],
                    members: state.byId[linkData.memberTypeId].members.filter(memberId => memberId !== linkData.memberId),
                }
            }

            return state;
        
        case SELECT_MEMBER_TYPE_CUSTOM_FIELD:
            return selectCustomField<MemberTypeState>(state, action.id);
        
        case UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD:
            return unSelectCustomField<MemberTypeState>(state);

        case ADD_MEMBER_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        customFields: state.byId[action.memberTypeId].customFields.concat([action.payload.id]),
                    }
                },
                reOrderedCustomFields: {
                    ...state.reOrderedCustomFields,
                    [action.memberTypeId]: state.byId[action.memberTypeId].customFields.concat([action.payload.id]),
                },
            }
            newState = addCustomField<MemberTypeState>(newState, action.payload, action.memberTypeId, action.currentTime);
            return newState;


        case INSTANTIATE_MEMBER_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        customFields: state.byId[action.memberTypeId].customFields.concat([action.payload.id]),
                    }
                },
                reOrderedCustomFields: {
                    ...state.reOrderedCustomFields,
                    [action.memberTypeId]: state.byId[action.memberTypeId].customFields.concat([action.payload.id]),
                },
            }
            return addCustomField<MemberTypeState>(newState, action.payload, action.memberTypeId, action.currentTime);
        
        case DELETE_MEMBER_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        customFields: state.byId[action.memberTypeId].customFields.filter(customFieldId => customFieldId !== action.id),
                    }
                },
                reOrderedCustomFields: {
                    ...state.reOrderedCustomFields,
                    [action.memberTypeId]: state.byId[action.memberTypeId].customFields.filter(customFieldId => customFieldId !== action.id),
                },
            }
            return deleteCustomField<MemberTypeState>(newState, action.id, action.currentTime);
        
        case UPDATE_MEMBER_TYPE_CUSTOM_FIELD:
            return updateCustomField<MemberTypeState>(state, action.payload, action.currentTime);


        case UPDATE_MEMBER_TYPE_CUSTOM_FIELD_START_PIECE:
            return updateStartPieceForCustomField<MemberTypeState>(state, action.customFieldId, action.payload, action.currentTime);
    
        case SET_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE:
            return setIsolatedPieceForCustomField<MemberTypeState>(state, action.customFieldId, action.payload, action.currentTime);

        case REMOVE_ISOLATED_MEMBER_TYPE_CUSTOM_FIELD_PIECE:
            return removeIsolatedPieceForCustomField<MemberTypeState>(state, action.customFieldId, action.pieceId, action.currentTime);

        case REGISTER_MEMBER_TYPE_CUSTOM_FIELD_VARIABLE:
            return registerVariableForCustomField<MemberTypeState>(state, action.customFieldId, action.variableId, action.currentTime);
        
        
        case SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION:
            return selectCustomFieldOption<MemberTypeState>(state, action.id);
        
        case UN_SELECT_MEMBER_TYPE_CUSTOM_FIELD_OPTION:
            return unSelectCustomFieldOption<MemberTypeState>(state);

        case RE_ORDER_MEMBER_TYPE_CUSTOM_FIELD_OPTION:
            return reOrderCustomFieldOptions<MemberTypeState>(state, action.sourceIndex, action.destinationIndex, action.parentId);

        case ADD_MEMBER_TYPE_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<MemberTypeState>(state, action.payload, action.parentId, action.currentTime);

        case INSTANTIATE_MEMBER_TYPE_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<MemberTypeState>(state, action.payload, action.parentId, action.currentTime);
        
        case DELETE_MEMBER_TYPE_CUSTOM_FIELD_OPTION:
            return deleteCustomFieldOption<MemberTypeState>(state, action.id, action.parentId, action.currentTime);

        case UPDATE_MEMBER_TYPE_CUSTOM_FIELD_OPTION:
            return updateCustomFieldOption<MemberTypeState>(state, action.payload, action.currentTime);

        case ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_ADD:
            memberTypeManagement = state.byId[action.memberTypeId].addManagement;

            if (typeof memberTypeManagement === 'undefined') {
                return state;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        addManagement: {
                            ...memberTypeManagement,
                            customFieldMap: {
                                ...memberTypeManagement.customFieldMap,
                                [action.memberTypeCustomFieldId]: action.workflowTypeCustomFieldId,
                            }
                        },
                    },
                },
            }

        case UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_ADD:
            memberTypeManagement = state.byId[action.memberTypeId].addManagement;


            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        addManagement: {
                            ...memberTypeManagement,
                            workflowTypeId: action.workflowTypeId,
                            lastQuestionPiece: action.lastQuestionPieceId,
                            lastStorePiece: action.lastStorePieceId,
                            customFieldMap: {
                                ...(memberTypeManagement ? memberTypeManagement.customFieldMap : {})
                            }
                        },
                    },
                },
            }

        case DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_ADD:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        addManagement: undefined,
                    },
                },
            }

        case ADD_MEMBER_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP_FOR_UPDATE:
            memberTypeManagement = state.byId[action.memberTypeId].updateManagement;

            if (typeof memberTypeManagement === 'undefined') {
                return state;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        updateManagement: {
                            ...memberTypeManagement,
                            customFieldMap: {
                                ...memberTypeManagement.customFieldMap,
                                [action.memberTypeCustomFieldId]: action.workflowTypeCustomFieldId,
                            }
                        },
                    },
                },
            }

        case UPDATE_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE:
            memberTypeManagement = state.byId[action.memberTypeId].updateManagement;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        updateManagement: {
                            ...memberTypeManagement,
                            workflowTypeId: action.workflowTypeId,
                            lastQuestionPiece: action.lastQuestionPieceId,
                            lastStorePiece: action.lastStorePieceId,
                            customFieldMap: {
                                ...(memberTypeManagement ? memberTypeManagement.customFieldMap : {})
                            }
                        },
                    },
                },
            }

        case DISCARD_MEMBER_TYPE_MANAGEMENT_FOR_UPDATE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberTypeId]: {
                        ...state.byId[action.memberTypeId],
                        updateManagement: undefined,
                    },
                },
            }

        case UPDATE_MEMBER_TYPES_DATA:
            return updateEntries<MemberTypeState, IMemberType>(state, action.data);

        case UPDATE_MEMBER_TYPE_CUSTOM_FIELDS_DATA:
            return updateCustomFields<MemberTypeState>(state, action.data);

        case UPDATE_MEMBER_TYPE_CUSTOM_FIELD_OPTIONS_DATA:
            return updateCustomFieldOptions<MemberTypeState>(state, action.data);

        case SYNCHRONIZE_MEMBER_TYPES_DATA:
            state = synchronizeEntries<MemberTypeState, IMemberType>(state, action.data);
            if (action.reOrder.length > 0) {
                state.allEntries = mergeReverseLinkArrays(action.reOrder, state.allEntries);
            }
            return state;

        case SYNCHRONIZE_MEMBER_TYPE_CUSTOM_FIELDS_DATA:
            newState = synchronizeCustomFields<MemberTypeState>(state, action.data);
            newState = synchronizeCustomFieldReverseLinks<MemberTypeState>(newState, action.data);
            return newState;

        case SYNCHRONIZE_MEMBER_TYPE_CUSTOM_FIELD_OPTIONS_DATA:
            newState = synchronizeCustomFieldOptions<MemberTypeState>(state, action.data);
            newState = synchronizeCustomFieldOptionReverseLinks<MemberTypeState>(newState, action.reOrderedCustomFieldOptions);
            return newState;

        case CLEAR_MEMBER_TYPES_DELTA:
            newState = clearDelta<MemberTypeState, IMemberType>(state);
            newState.areMemberTypesReordered = false;
            newState.reOrderedActions = {};
            newState.reOrderedCustomFields = {};
            newState = clearDeltaForCustomFields<MemberTypeState>(newState);
            return newState;


        // Action ACTIONS

        case ADD_MEMBER_TYPE_ACTION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.parentId]: {
                        ...state.byId[action.parentId],
                        actions: state.byId[action.parentId].actions.concat(action.payload.id),
                    }
                },
                reOrderedActions: {
                    ...state.reOrderedActions,
                    [action.parentId]: state.byId[action.parentId].actions.concat(action.payload.id),
                }
            }
        
        case INSTANTIATE_MEMBER_TYPE_ACTION:
            return {
                ...state,
                reOrderedActions: {
                    ...state.reOrderedActions,
                    [action.parentId]: state.byId[action.parentId].actions.concat(action.payload.id),
                },
            }

        case DELETE_MEMBER_TYPE_ACTION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.parentId]: {
                        ...state.byId[action.parentId],
                        actions: state.byId[action.parentId].actions.filter(actionId => actionId === action.id),
                    }
                },
                reOrderedActions: {
                    ...state.reOrderedActions,
                    [action.parentId]: state.byId[action.parentId].actions.filter(actionId => actionId === action.id),
                }
            }
    
        case RE_ORDER_MEMBER_TYPE_ACTIONS:
            const reOrderedList = reOrderList(state.byId[action.parentId].actions, action.sourceIndex, action.destinationIndex);
            
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.parentId]: {
                        ...state.byId[action.parentId],
                        actions: reOrderedList,
                    }
                },
                reOrderedActions: {
                    ...state.reOrderedActions,
                    [action.parentId]: reOrderedList,
                }
            }
        
        
        default:
            return state;
    }
}
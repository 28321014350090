import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ListCardComponent } from '../list-card/list-card.component';

@NgModule({
	declarations: [
		ListCardComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		IonicModule
	],
	exports: [
		ListCardComponent,
	]
})
export class ListCardModule { }

import { StructureActionTypes, UPDATE_STRUCTURE_DATA } from '../types'
import { SELECT_PROJECT, UN_SELECT_PROJECT, ADD_PROJECT, DELETE_PROJECT, UPDATE_PROJECT, RE_ORDER_PROJECTS, ADD_LEVEL_TO_PROJECT, REMOVE_LEVEL_FROM_PROJECT, ADD_LOCATION_TO_PROJECT, REMOVE_LOCATION_FROM_PROJECT, ProjectState, IProject, UPDATE_PROJECTS_DATA, CLEAR_PROJECTS_DELTA, SYNCHRONIZE_PROJECTS_DATA, INSTANTIATE_LEVELS_FOR_PROJECT } from './types';
import { ILevel, RE_ORDER_LEVELS, SYNCHRONIZE_LEVELS_DATA } from '../level/types';
import { mergeReverseLinkArrays, reOrderList } from '../../../helpers/utilities';
import { addEntity, updateEntity, deleteEntity, updateEntries, clearDelta, synchronizeEntries, synchronizeReverseLinks } from '../../normalized-model';
import { SYNCHRONIZE_LOCATIONS_DATA } from '../location/types';

const initialState: ProjectState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],

    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    areProjectsReordered: false,
    reOrderedLevels: {},
};

export function projectsReducer(state = initialState, action: StructureActionTypes) {
    let newState: ProjectState;

    switch (action.type) {

        case SELECT_PROJECT:
            return {
                ...state,
                selected: action.id,
            }

        case UN_SELECT_PROJECT:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_PROJECTS:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
                areProjectsReordered: true,
            };

        case ADD_PROJECT:
            return addEntity<ProjectState, IProject>(state, action.payload);

        case UPDATE_PROJECT:
            return updateEntity<ProjectState, IProject>(state, action.payload, action.currentTime);

        case DELETE_PROJECT:
            state = deleteEntity<ProjectState, IProject>(state, action.id, action.currentTime);

            return {
                ...state,
                selected: undefined,
            };

        case ADD_LEVEL_TO_PROJECT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.projectId]: {
                        ...state.byId[action.projectId],
                        children: state.byId[action.projectId].children.concat([action.levelId]),
                    }
                },
                reOrderedLevels: {
                    ...state.reOrderedLevels,
                    [action.projectId]: state.byId[action.projectId].children.concat([action.levelId]),
                },
            }

        case REMOVE_LEVEL_FROM_PROJECT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.projectId]: {
                        ...state.byId[action.projectId],
                        children: state.byId[action.projectId].children.filter(levelId => levelId !== action.levelId),
                    }
                },
                reOrderedLevels: {
                    ...state.reOrderedLevels,
                    [action.projectId]: state.byId[action.projectId].children.filter(levelId => levelId !== action.levelId),
                },
            }

        case ADD_LOCATION_TO_PROJECT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.projectId]: {
                        ...state.byId[action.projectId],
                        locations: state.byId[action.projectId].locations.concat([action.locationId]),
                    }
                }
            }

        case REMOVE_LOCATION_FROM_PROJECT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.projectId]: {
                        ...state.byId[action.projectId],
                        locations: state.byId[action.projectId].locations.filter(locationId => locationId !== action.locationId),
                    }
                }
            }


        // LEVEL ACTIONS

        case RE_ORDER_LEVELS:
            const reOrderedList = reOrderList(state.byId[action.parentId].children, action.sourceIndex, action.destinationIndex);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.parentId]: {
                        ...state.byId[action.parentId],
                        children: reOrderedList,
                    }
                },
                reOrderedLevels: {
                    ...state.reOrderedLevels,
                    [action.parentId]: reOrderedList,
                },
            };

        case INSTANTIATE_LEVELS_FOR_PROJECT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.projectId]: {
                        ...state.byId[action.projectId],
                        children: state.byId[action.projectId].children.concat(action.levelIds),
                    }
                }
            }

        case UPDATE_PROJECTS_DATA:
            return updateEntries<ProjectState, IProject>(state, action.data);

        case SYNCHRONIZE_PROJECTS_DATA:
            state = synchronizeEntries<ProjectState, IProject>(state, action.data);
            if (action.reOrder.length > 0) {
                state.allEntries = mergeReverseLinkArrays(action.reOrder, state.allEntries);
            }
            return state;

        case SYNCHRONIZE_LEVELS_DATA:
            return synchronizeReverseLinks<ProjectState, IProject, ILevel>(state, action.data, 'project', 'children');

        case SYNCHRONIZE_LOCATIONS_DATA:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                },
            };

            for (const projectId in action.reOrderedTopLevelLocations) {
                newState.byId[projectId].locations = action.reOrderedTopLevelLocations[projectId];
            }

            for (const location of action.data) {
                if (location.parent && location.parent in newState.byId) {
                    if (!(location.id in newState.byId[location.parent].locations)) {
                        const project = newState.byId[location.parent];
                        project.locations.push(location.id);
                    }
                }
            }

            return newState;

        case CLEAR_PROJECTS_DELTA:
            newState = clearDelta<ProjectState, IProject>(state);
            newState.areProjectsReordered = false;
            newState.reOrderedLevels = {};
            return newState;

        // Structure actions

        case UPDATE_STRUCTURE_DATA:
            return {
                ...action.data.projects,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
            }


        default:
            return state
    }
}
import { ADD_REPORT_REQUEST, ADD_REPORT, DELETE_REPORT, UPDATE_REPORT, SEARCH_REPORT_TABLE, GO_TO_PAGE_REPORT_TABLE, SET_PAGE_SIZE_REPORT_TABLE, SORT_REPORT_TABLE, AddReportRequestAction, AddReportAction, UpdateReportAction, DeleteReportAction, StoreDataForReportAction, STORE_DATA_FOR_REPORT, FilterReportTableAction, FILTER_REPORT_TABLE, StartReportDataGenerationAction, START_REPORT_DATA_GENERATION } from './types';
import { INewReportData, IUpdateableReportData } from './types';
import moment from 'moment';

export function addReportRequest(payload: INewReportData) : AddReportRequestAction {
    return {
        type: ADD_REPORT_REQUEST,
        payload,
    }
}

export function addReport(payload: IUpdateableReportData) : AddReportAction {
    const now = moment.utc().format();

    return {
        type: ADD_REPORT,
        payload: {
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            generatedTime: undefined,
        },
        currentTime: now,
    }
}

export function updateReport(payload: IUpdateableReportData) : UpdateReportAction {
    return {
        type: UPDATE_REPORT,
        payload,
        currentTime: moment.utc().format(),
    }
}

export function StartReportDataGeneration(): StartReportDataGenerationAction {
    return {
        type: START_REPORT_DATA_GENERATION,
    }
}

export function storeDataForReport(reportId: string, data: Array<Array<string>>) : StoreDataForReportAction {
    return {
        type: STORE_DATA_FOR_REPORT,
        reportId,
        data,
        currentTime: moment.utc().format(),
    }
}

export function deleteReport(id: string): DeleteReportAction {
    return {
        type: DELETE_REPORT,
        id,
        currentTime: moment.utc().format(),
    }
}


export function searchReportTable(searchTerm: string) {
    return {
        type: SEARCH_REPORT_TABLE,
        searchTerm,
    }
}

export function filterReportTable(
    projects: Array<string>, 
    types: Array<string>, 
    users: Array<string>,
    createdDateRange: Array<string>,
    lastUpdatedDateRange: Array<string>,
    generatedDateRange: Array<string>,
    archived: boolean,
    unsynced: boolean,
): FilterReportTableAction {
    return {
        type: FILTER_REPORT_TABLE,
        projects,
        types,
        users,
        createdDateRange,
        lastUpdatedDateRange,
        generatedDateRange,
        archived,
        unsynced,
    }
}

export function goToPageReportTable(pageNumber: number) {
    return {
        type: GO_TO_PAGE_REPORT_TABLE,
        pageNumber,
    }
}

export function setPageSizeReportTable(pageSize: number) {
    return {
        type: SET_PAGE_SIZE_REPORT_TABLE,
        pageSize,
    }
}

export function sortReportTable(column: string, order: 'ASC'|'DESC') {
    return {
        type: SORT_REPORT_TABLE,
        column,
        order,
    }
}
import { GroupState, ADD_GROUP, UPDATE_GROUP, DELETE_GROUP, SEARCH_GROUP_TABLE, GO_TO_PAGE_GROUP_TABLE, SET_PAGE_SIZE_GROUP_TABLE, SORT_GROUP_TABLE, ADD_WORKFLOW_TO_GROUP, UPDATE_GROUP_CUSTOM_FIELD_DATA, SET_MEMBERS_FOR_GROUP, ADD_MEMBER_TO_GROUP, REMOVE_MEMBER_FROM_GROUP, GroupActionTypes, UPDATE_GROUP_DATA, FILTER_GROUP_TABLE, IGroup, UPDATE_GROUPS_DATA, SYNCHRONIZE_GROUPS_DATA, CLEAR_GROUPS_DELTA, UN_ARCHIVE_GROUP, REMOVE_WORKFLOW_FROM_GROUP, BULK_ADD_MEMBERS_TO_GROUPS, BULK_REMOVE_MEMBERS_FROM_GROUPS, SET_IS_FILTERING_FOR_GROUP_TABLE, APPEND_GROUPS_DATA, SET_IS_SHOWING_ADD_FORM_FOR_GROUPS, SET_IS_SHOWING_CSV_FORM_FOR_GROUPS, SET_IS_SHOWING_FILTER_FORM_FOR_GROUPS, SET_IS_SHOWING_MODIFY_FORM_FOR_GROUPS, UPDATE_GROUPS_LOCATION, UPDATE_GROUP_COMPUTED_FIELD_DATA, BULK_UPDATE_GROUP_COMPUTED_FIELD_DATA, CLEAR_GROUP_ENTRIES, SET_TOTAL_NUMBER_OF_GROUPS_FROM_SERVER } from './types';
import { groupTypesReducer, initialState as groupTypesInitialState } from './types/reducer';
import { addEntity, updateEntityWithCustomFields, deleteEntity, updateEntries, synchronizeEntriesForCustomModels, clearDelta, unArchiveEntity, synchronizeReverseLinks, synchronizeEntries } from '../normalized-model';
import { GroupTypeState, IGroupType } from './types/types';
import { IWorkflow, SYNCHRONIZE_WORKFLOWS_DATA } from '../workflows/types';

const initialState: GroupState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],

    totalNoOfGroups: 0,

    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    types: groupTypesInitialState,
    pageSize: 25,
    currentPageNumber: 1,

    isFiltering: false,

    markedForIndex: [],

    filters: {
        projects: [],
        types: [],
        locations: [],
        customFields: {},
        createdDateRange: [],
        lastUpdatedDateRange: [],
        unsynced: false,
        archived: false,
    },
    sort: {
        column: undefined,
        order: 'ASC',
    },
    searchTerm: '',

    isShowingAddForm: false,
    isShowingModifyForm: false,
    isShowingFilterForm: false,
    isShowingCSVForm: false,
};

export function groupsReducer(state = initialState, action: GroupActionTypes): GroupState {
    state = {
        ...state,
        types: groupTypesReducer(state.types, action),
    };

    let connectedWorkflows: Array<string> = [];
    let connectedMembers: Array<string> = [];

    switch (action.type) {
        case ADD_GROUP:
            return addEntity<GroupState, IGroup>(state, action.payload);

        case UPDATE_GROUP:
            return updateEntityWithCustomFields<GroupState, IGroup>(state, action.payload, action.currentTime);

        case DELETE_GROUP:
            return deleteEntity<GroupState, IGroup>(state, action.id, action.currentTime);

        case UN_ARCHIVE_GROUP:
            return unArchiveEntity<GroupState, IGroup>(state, action.id, action.currentTime);

        case ADD_WORKFLOW_TO_GROUP:
            connectedWorkflows = state.byId[action.groupId].workflows[action.workflowTypeId] || [];
            connectedWorkflows = Array.from((new Set(connectedWorkflows)).add(action.workflowId));

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupId]: {
                        ...state.byId[action.groupId],
                        workflows: {
                            ...state.byId[action.groupId].workflows,
                            [action.workflowTypeId]: connectedWorkflows,
                        }
                    }
                },
            }

        case REMOVE_WORKFLOW_FROM_GROUP:
            connectedWorkflows = state.byId[action.groupId].workflows[action.workflowTypeId] || [];
            connectedWorkflows = connectedWorkflows.filter(workflowId => workflowId !== action.workflowId);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupId]: {
                        ...state.byId[action.groupId],
                        workflows: {
                            ...state.byId[action.groupId].workflows,
                            [action.workflowTypeId]: connectedWorkflows,
                        },
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.groupId]),
            }

        case ADD_MEMBER_TO_GROUP:
            connectedMembers = state.byId[action.groupId].members || [];
            connectedMembers = Array.from((new Set(connectedMembers)).add(action.memberId));

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupId]: {
                        ...state.byId[action.groupId],
                        members: connectedMembers,
                    },
                },
                updatedIds: new Set([...state.updatedIds, action.groupId]),
            }

        case REMOVE_MEMBER_FROM_GROUP:
            connectedMembers = state.byId[action.groupId].members || [];
            connectedMembers = connectedMembers.filter(memberId => memberId !== action.memberId);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupId]: {
                        ...state.byId[action.groupId],
                        members: connectedMembers,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.groupId]),
            }

        case BULK_ADD_MEMBERS_TO_GROUPS:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                }
            };

            const updatedGroupIdsForAdd: Set<string> = new Set();

            for (const link of action.links) {
                connectedMembers = state.byId[link.groupId].members || [];
                connectedMembers = Array.from((new Set(connectedMembers)).add(link.memberId));

                state.byId[link.groupId] = {
                    ...state.byId[link.groupId],
                    members: connectedMembers,
                };

                updatedGroupIdsForAdd.add(link.groupId);
            }

            state.updatedIds = new Set([...state.updatedIds, ...updatedGroupIdsForAdd]);

            return state;

        case BULK_REMOVE_MEMBERS_FROM_GROUPS:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                }
            };

            const updatedGroupIdsForRemove: Set<string> = new Set();

            for (const link of action.links) {
                connectedMembers = state.byId[link.groupId].members || [];
                connectedMembers = connectedMembers.filter(memberId => memberId !== link.memberId);

                state.byId[link.groupId] = {
                    ...state.byId[link.groupId],
                    members: connectedMembers,
                };

                updatedGroupIdsForRemove.add(link.groupId)
            }

            state.updatedIds = new Set([...state.updatedIds, ...updatedGroupIdsForRemove]);

            return state;

        case SET_MEMBERS_FOR_GROUP:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupId]: {
                        ...state.byId[action.groupId],
                        representatives: action.memberTypes === 'representatives' ? action.memberIds : state.byId[action.groupId].representatives,
                        members: action.memberTypes === 'all_members' ? action.memberIds : Array.from(new Set(state.byId[action.groupId].members.concat(action.memberIds))),
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.groupId]),
            }


        case UPDATE_GROUP_CUSTOM_FIELD_DATA:
        case UPDATE_GROUP_COMPUTED_FIELD_DATA:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupId]: {
                        ...state.byId[action.groupId],
                        customFields: {
                            ...state.byId[action.groupId].customFields,
                            ...action.customFieldData,
                        }
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.groupId]),

            }

        case BULK_UPDATE_GROUP_COMPUTED_FIELD_DATA:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                },
                updatedIds: new Set([...state.updatedIds, ...action.payload.map(groupData => groupData.groupId)]),
            }

            for (const groupData of action.payload) {
                state.byId[groupData.groupId] = {
                    ...state.byId[groupData.groupId],
                    customFields: {
                        ...state.byId[groupData.groupId].customFields,
                        ...groupData.customFieldData,
                    },
                };
            }

            return state;

        case UPDATE_GROUPS_LOCATION:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                },
                updatedIds: new Set([...state.updatedIds, ...action.groupIds]),
            }

            for (const groupId of action.groupIds) {
                state.byId[groupId] = {
                    ...state.byId[groupId],
                    location: action.locationId,
                }
            }

            return state;


        case SEARCH_GROUP_TABLE:
            return {
                ...state,
                searchTerm: action.searchTerm,
                currentPageNumber: 1,
            }

        case FILTER_GROUP_TABLE:
            return {
                ...state,
                filters: {
                    projects: action.projects,
                    types: action.types,
                    locations: action.locations,
                    customFields: action.customFields,
                    createdDateRange: action.createdDateRange,
                    lastUpdatedDateRange: action.lastUpdatedDateRange,
                    unsynced: action.unsynced,
                    archived: action.archived,
                },
                currentPageNumber: 1,
            }

        case SET_IS_FILTERING_FOR_GROUP_TABLE:
            return {
                ...state,
                isFiltering: action.isFiltering,
            };

        case GO_TO_PAGE_GROUP_TABLE:
            return {
                ...state,
                currentPageNumber: action.pageNumber,
            }

        case SET_PAGE_SIZE_GROUP_TABLE:
            return {
                ...state,
                pageSize: action.pageSize,
            }

        case SORT_GROUP_TABLE:
            return {
                ...state,
                sort: {
                    column: action.column,
                    order: action.order
                }
            }

        case UPDATE_GROUPS_DATA:
            state = updateEntries<GroupState, IGroup>(state, action.data);
            state.totalNoOfGroups = action.totalNoOfGroups;
            return state;

        case SET_IS_SHOWING_ADD_FORM_FOR_GROUPS:
            return {
                ...state,
                isShowingAddForm: action.showValue,
            }

        case SET_IS_SHOWING_MODIFY_FORM_FOR_GROUPS:
            return {
                ...state,
                isShowingModifyForm: action.showValue,
            }

        case SET_IS_SHOWING_FILTER_FORM_FOR_GROUPS:
            return {
                ...state,
                isShowingFilterForm: action.showValue,
            }

        case SET_IS_SHOWING_CSV_FORM_FOR_GROUPS:
            return {
                ...state,
                isShowingCSVForm: action.showValue,
            }

        case SYNCHRONIZE_GROUPS_DATA:
            state = synchronizeEntriesForCustomModels<GroupState, IGroup>(state, action.data);
            state.types = synchronizeReverseLinks<GroupTypeState, IGroupType, IGroup>(state.types, action.data, 'type', 'groups');
            return state;

        case SYNCHRONIZE_WORKFLOWS_DATA:
            const workflowsAffiliatedWithGroups = action.data.filter(workflow => workflow.affiliatedEntity in state.byId);
            state = synchronizeReverseLinks<GroupState, IGroup, IWorkflow>(state, workflowsAffiliatedWithGroups, 'affiliatedEntity', 'workflows', 'type');
            return state;

        case APPEND_GROUPS_DATA:
            const unrecordedInactiveGroups = action.data.filter(group => !(group.id in state.byId));
            state = synchronizeEntries(state, unrecordedInactiveGroups);
            return state;

        case SET_TOTAL_NUMBER_OF_GROUPS_FROM_SERVER:
            return {
                ...state,
                currentPageNumber: 1,
                totalNoOfGroups: action.totalNumberOfGroups,
            }

        case CLEAR_GROUP_ENTRIES:
            return {
                ...state,
                byId: {},
                allEntries: [],
            };

        case CLEAR_GROUPS_DELTA:
            return clearDelta<GroupState, IGroup>(state);

        case UPDATE_GROUP_DATA:
            return {
                ...action.data,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
                filters: state.filters,
                types: {
                    ...action.data.types,
                    createdIds: state.types.createdIds,
                    updatedIds: state.types.updatedIds,
                    deletedIds: state.types.deletedIds,
                    createdCustomFieldIds: state.types.createdCustomFieldIds,
                    updatedCustomFieldIds: state.types.updatedCustomFieldIds,
                    deletedCustomFieldIds: state.types.deletedCustomFieldIds,
                    createdCustomFieldOptionIds: state.types.createdCustomFieldOptionIds,
                    updatedCustomFieldOptionIds: state.types.updatedCustomFieldOptionIds,
                    deletedCustomFieldOptionIds: state.types.deletedCustomFieldOptionIds,
                },
            }

        default:
            return state;
    }
}
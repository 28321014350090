import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { translatePhrase } from 'src/shared/helpers/translation';

export enum ToastType {
	SUCCESS = "success",
	ERROR = "error",
	INFO = "info",
	WARNING = "warning",
	Loading = "loading"
}

interface Toast {
	text: string;
	time: number | undefined;
	toastType: ToastType | undefined;
}



@Injectable({
	providedIn: 'root'
})

export class ToastService {

	constructor() {

	}

	toastDetails: BehaviorSubject<Toast> = new BehaviorSubject<Toast>(null);
	isShowingSubscription: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);


	translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

	public async setIsShowingToast(isShowingToast: boolean) {
		this.isShowingSubscription.next(isShowingToast);
	}

	public async presentToastWithOptions(message: string, toastType?: ToastType, time?: number) {

		const toast: Toast = {
			text: this.translate(message),
			time: time,
			toastType: toastType,
		}
		this.toastDetails.next(toast);

		return;
	}
}

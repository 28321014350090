import { AllPieceTypes, PieceType, IfData, PieceState } from '../pieces/types';
import { isUUID } from '../../../helpers/utilities';
import uuid from 'uuid';


function applyFunctionToPiece(pieceData: AllPieceTypes, functionToApply: (pieceId: string) => string) {

    if ('nextPiece' in pieceData) {
        if (typeof pieceData.nextPiece === 'string') {
            pieceData.nextPiece = functionToApply(pieceData.nextPiece);
        }
    }

    if ('innerPiece' in pieceData) {
        if (typeof pieceData.innerPiece === 'string') {
            pieceData.innerPiece = functionToApply(pieceData.innerPiece);
        }
    }

    if (pieceData.type === PieceType.SPLIT) {
        const newIfData: Array<IfData> | undefined = JSON.parse(JSON.stringify(pieceData.ifPieceData));

        if (typeof newIfData !== 'undefined') {
            for (let ifDatum of newIfData) {
                if (typeof ifDatum.conditionPiece === 'string') {
                    ifDatum.conditionPiece = functionToApply(ifDatum.conditionPiece);
                }

                if (typeof ifDatum.nextPiece === 'string') {
                    ifDatum.nextPiece = functionToApply(ifDatum.nextPiece);
                }
            }

            pieceData.ifPieceData = newIfData;
        }
    }

    if ('iterableVariable' in pieceData) {
        if (typeof pieceData.iterableVariable === 'string' && isUUID(pieceData.iterableVariable)) {
            pieceData.iterableVariable = functionToApply(pieceData.iterableVariable);
        }
    }

    if ('operand' in pieceData) {
        if (typeof pieceData.operand === 'string' && isUUID(pieceData.operand)) {
            pieceData.operand = functionToApply(pieceData.operand);
        }
    }

    if ('leftOperand' in pieceData) {
        if (typeof pieceData.leftOperand === 'string' && isUUID(pieceData.leftOperand)) {
            pieceData.leftOperand = functionToApply(pieceData.leftOperand);
        }
    }

    if ('rightOperand' in pieceData) {
        if (typeof pieceData.rightOperand === 'string' && isUUID(pieceData.rightOperand)) {
            pieceData.rightOperand = functionToApply(pieceData.rightOperand);
        }
    }

    if ('heading' in pieceData) {
        if (typeof pieceData.heading === 'string' && isUUID(pieceData.heading)) {
            pieceData.heading = functionToApply(pieceData.heading);
        }
    }

    if ('question' in pieceData) {
        if (typeof pieceData.question === 'string' && isUUID(pieceData.question)) {
            pieceData.question = functionToApply(pieceData.question);
        }
    }

    if ('isRequiredPiece' in pieceData) {
        if (typeof pieceData.isRequiredPiece === 'string' && isUUID(pieceData.isRequiredPiece)) {
            pieceData.isRequiredPiece = functionToApply(pieceData.isRequiredPiece);
        }
    }

    if ('isDisabledPiece' in pieceData) {
        if (typeof pieceData.isDisabledPiece === 'string' && isUUID(pieceData.isDisabledPiece)) {
            pieceData.isDisabledPiece = functionToApply(pieceData.isDisabledPiece);
        }
    }

    if ('default' in pieceData) {
        if (typeof pieceData.default === 'string' && isUUID(pieceData.default)) {
            pieceData.default = functionToApply(pieceData.default);
        }
    }

    if ('yearVariablePiece' in pieceData) {
        if (typeof pieceData.yearVariablePiece === 'string' && isUUID(pieceData.yearVariablePiece)) {
            pieceData.yearVariablePiece = functionToApply(pieceData.yearVariablePiece);
        }
    }

    if ('monthVariablePiece' in pieceData) {
        if (typeof pieceData.monthVariablePiece === 'string' && isUUID(pieceData.monthVariablePiece)) {
            pieceData.monthVariablePiece = functionToApply(pieceData.monthVariablePiece);
        }
    }

    if ('dateVariablePiece' in pieceData) {
        if (typeof pieceData.dateVariablePiece === 'string' && isUUID(pieceData.dateVariablePiece)) {
            pieceData.dateVariablePiece = functionToApply(pieceData.dateVariablePiece);
        }
    }

    if ('memberVariablePiece' in pieceData) {
        if (typeof pieceData.memberVariablePiece === 'string' && isUUID(pieceData.memberVariablePiece)) {
            pieceData.memberVariablePiece = functionToApply(pieceData.memberVariablePiece);
        }
    }

    if ('dataToStore' in pieceData) {
        if (typeof pieceData.dataToStore === 'string' && isUUID(pieceData.dataToStore)) {
            pieceData.dataToStore = functionToApply(pieceData.dataToStore);
        }
    }

    if ('dataToSet' in pieceData) {
        if (typeof pieceData.dataToSet === 'string' && isUUID(pieceData.dataToSet)) {
            pieceData.dataToSet = functionToApply(pieceData.dataToSet);
        }
    }

    if ('returnValue' in pieceData) {
        if (typeof pieceData.returnValue === 'string' && isUUID(pieceData.returnValue)) {
            pieceData.returnValue = functionToApply(pieceData.returnValue);
        }
    }

    if ('variableToShow' in pieceData) {
        if (typeof pieceData.variableToShow === 'string' && isUUID(pieceData.variableToShow)) {
            pieceData.variableToShow = functionToApply(pieceData.variableToShow);
        }
    }

    if ('variablePiece' in pieceData) {
        if (typeof pieceData.variablePiece === 'string' && isUUID(pieceData.variablePiece)) {
            pieceData.variablePiece = functionToApply(pieceData.variablePiece);
        }
    }

    if ('workflowDueDateVariable' in pieceData) {
        if (typeof pieceData.workflowDueDateVariable === 'string' && isUUID(pieceData.workflowDueDateVariable)) {
            pieceData.workflowDueDateVariable = functionToApply(pieceData.workflowDueDateVariable);
        }
    }

    if ('affiliationVariable' in pieceData) {
        if (typeof pieceData.affiliationVariable === 'string' && isUUID(pieceData.affiliationVariable)) {
            pieceData.affiliationVariable = functionToApply(pieceData.affiliationVariable);
        }
    }

    if ('dueDate' in pieceData) {
        if (typeof pieceData.dueDate === 'string' && isUUID(pieceData.dueDate)) {
            pieceData.dueDate = functionToApply(pieceData.dueDate);
        }
    }

    if ('locationPiece' in pieceData) {
        if (typeof pieceData.locationPiece === 'string' && isUUID(pieceData.locationPiece)) {
            pieceData.locationPiece = functionToApply(pieceData.locationPiece);
        }
    }

    if ('message' in pieceData) {
        if (typeof pieceData.message === 'string' && isUUID(pieceData.message)) {
            pieceData.message = functionToApply(pieceData.message);
        }
    }

    if ('name' in pieceData) {
        if (typeof pieceData.name === 'string' && isUUID(pieceData.name)) {
            pieceData.name = functionToApply(pieceData.name);
        }
    }

    if ('user' in pieceData) {
        if (typeof pieceData.user === 'string' && isUUID(pieceData.user)) {
            pieceData.user = functionToApply(pieceData.user);
        }
    }

    if ('startDate' in pieceData) {
        if (typeof pieceData.startDate === 'string' && isUUID(pieceData.startDate)) {
            pieceData.startDate = functionToApply(pieceData.startDate);
        }
    }

    if ('endDate' in pieceData) {
        if (typeof pieceData.endDate === 'string' && isUUID(pieceData.endDate)) {
            pieceData.endDate = functionToApply(pieceData.endDate);
        }
    }

    return pieceData;
}

export function duplicatePiece(piecesData: PieceState, addFullPiece: (pieceData: AllPieceTypes) => void, pieceId: string) {
    const pieceToCopy = piecesData.byId[pieceId];
    const newId = uuid.v4();

    const duplicatePieceShortHand = duplicatePiece.bind({}, piecesData, addFullPiece);

    const newPieceData: AllPieceTypes = {
        ...pieceToCopy,
        id: newId,
    };

    applyFunctionToPiece(newPieceData, duplicatePieceShortHand);
    addFullPiece(newPieceData);

    return newId;
}

export function getAllPiecesInPiece(piecesData: PieceState, pieceId: string) {

    let allPiecesInPiece: Array<AllPieceTypes> = [];
    const pieceData = piecesData.byId[pieceId];

    allPiecesInPiece.push(pieceData);

    const functionToApply = getAllPiecesInPiece.bind({}, piecesData);

    if ('nextPiece' in pieceData) {
        if (typeof pieceData.nextPiece === 'string') {
            const innerPieces = functionToApply(pieceData.nextPiece);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('innerPiece' in pieceData) {
        if (typeof pieceData.innerPiece === 'string') {
            const innerPieces = functionToApply(pieceData.innerPiece);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if (pieceData.type === PieceType.SPLIT) {
        const newIfData: Array<IfData> | undefined = JSON.parse(JSON.stringify(pieceData.ifPieceData));

        if (typeof newIfData !== 'undefined') {
            for (let ifDatum of newIfData) {
                if (typeof ifDatum.conditionPiece === 'string') {
                    const innerPieces = functionToApply(ifDatum.conditionPiece);
                    allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
                }

                if (typeof ifDatum.nextPiece === 'string') {
                    const innerPieces = functionToApply(ifDatum.nextPiece);
                    allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
                }
            }

            pieceData.ifPieceData = newIfData;
        }
    }

    if ('iterableVariable' in pieceData) {
        if (typeof pieceData.iterableVariable === 'string' && isUUID(pieceData.iterableVariable)) {
            const innerPieces = functionToApply(pieceData.iterableVariable);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('operand' in pieceData) {
        if (typeof pieceData.operand === 'string' && isUUID(pieceData.operand)) {
            const innerPieces = functionToApply(pieceData.operand);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('leftOperand' in pieceData) {
        if (typeof pieceData.leftOperand === 'string' && isUUID(pieceData.leftOperand)) {
            const innerPieces = functionToApply(pieceData.leftOperand);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('rightOperand' in pieceData) {
        if (typeof pieceData.rightOperand === 'string' && isUUID(pieceData.rightOperand)) {
            const innerPieces = functionToApply(pieceData.rightOperand);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('heading' in pieceData) {
        if (typeof pieceData.heading === 'string' && isUUID(pieceData.heading)) {
            const innerPieces = functionToApply(pieceData.heading);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('question' in pieceData) {
        if (typeof pieceData.question === 'string' && isUUID(pieceData.question)) {
            const innerPieces = functionToApply(pieceData.question);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('isRequiredPiece' in pieceData) {
        if (typeof pieceData.isRequiredPiece === 'string' && isUUID(pieceData.isRequiredPiece)) {
            const innerPieces = functionToApply(pieceData.isRequiredPiece);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('isDisabledPiece' in pieceData) {
        if (typeof pieceData.isDisabledPiece === 'string' && isUUID(pieceData.isDisabledPiece)) {
            const innerPieces = functionToApply(pieceData.isDisabledPiece);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('default' in pieceData) {
        if (typeof pieceData.default === 'string' && isUUID(pieceData.default)) {
            const innerPieces = functionToApply(pieceData.default);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('yearVariablePiece' in pieceData) {
        if (typeof pieceData.yearVariablePiece === 'string' && isUUID(pieceData.yearVariablePiece)) {
            const innerPieces = functionToApply(pieceData.yearVariablePiece);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('monthVariablePiece' in pieceData) {
        if (typeof pieceData.monthVariablePiece === 'string' && isUUID(pieceData.monthVariablePiece)) {
            const innerPieces = functionToApply(pieceData.monthVariablePiece);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('dateVariablePiece' in pieceData) {
        if (typeof pieceData.dateVariablePiece === 'string' && isUUID(pieceData.dateVariablePiece)) {
            const innerPieces = functionToApply(pieceData.dateVariablePiece);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('memberVariablePiece' in pieceData) {
        if (typeof pieceData.memberVariablePiece === 'string' && isUUID(pieceData.memberVariablePiece)) {
            const innerPieces = functionToApply(pieceData.memberVariablePiece);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('dataToStore' in pieceData) {
        if (typeof pieceData.dataToStore === 'string' && isUUID(pieceData.dataToStore)) {
            const innerPieces = functionToApply(pieceData.dataToStore);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('dataToSet' in pieceData) {
        if (typeof pieceData.dataToSet === 'string' && isUUID(pieceData.dataToSet)) {
            const innerPieces = functionToApply(pieceData.dataToSet);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('returnValue' in pieceData) {
        if (typeof pieceData.returnValue === 'string' && isUUID(pieceData.returnValue)) {
            const innerPieces = functionToApply(pieceData.returnValue);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('variableToShow' in pieceData) {
        if (typeof pieceData.variableToShow === 'string' && isUUID(pieceData.variableToShow)) {
            const innerPieces = functionToApply(pieceData.variableToShow);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('variablePiece' in pieceData) {
        if (typeof pieceData.variablePiece === 'string' && isUUID(pieceData.variablePiece)) {
            const innerPieces = functionToApply(pieceData.variablePiece);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('workflowDueDateVariable' in pieceData) {
        if (typeof pieceData.workflowDueDateVariable === 'string' && isUUID(pieceData.workflowDueDateVariable)) {
            const innerPieces = functionToApply(pieceData.workflowDueDateVariable);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('affiliationVariable' in pieceData) {
        if (typeof pieceData.affiliationVariable === 'string' && isUUID(pieceData.affiliationVariable)) {
            const innerPieces = functionToApply(pieceData.affiliationVariable);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('dueDate' in pieceData) {
        if (typeof pieceData.dueDate === 'string' && isUUID(pieceData.dueDate)) {
            const innerPieces = functionToApply(pieceData.dueDate);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('locationPiece' in pieceData) {
        if (typeof pieceData.locationPiece === 'string' && isUUID(pieceData.locationPiece)) {
            const innerPieces = functionToApply(pieceData.locationPiece);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('message' in pieceData) {
        if (typeof pieceData.message === 'string' && isUUID(pieceData.message)) {
            const innerPieces = functionToApply(pieceData.message);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('name' in pieceData) {
        if (typeof pieceData.name === 'string' && isUUID(pieceData.name)) {
            const innerPieces = functionToApply(pieceData.name);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('user' in pieceData) {
        if (typeof pieceData.user === 'string' && isUUID(pieceData.user)) {
            const innerPieces = functionToApply(pieceData.user);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('startDate' in pieceData) {
        if (typeof pieceData.startDate === 'string' && isUUID(pieceData.startDate)) {
            const innerPieces = functionToApply(pieceData.startDate);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    if ('endDate' in pieceData) {
        if (typeof pieceData.endDate === 'string' && isUUID(pieceData.endDate)) {
            const innerPieces = functionToApply(pieceData.endDate);
            allPiecesInPiece = allPiecesInPiece.concat(innerPieces);
        }
    }

    return allPiecesInPiece;
}

export function pastePiece(piecesData: PieceState, addFullPiece: (pieceData: AllPieceTypes) => void, pieceId: string) {
    const pieceToCopy = piecesData.byId[pieceId];
    const newId = uuid.v4();

    const pastePieceShortHand = pastePiece.bind({}, piecesData, addFullPiece);

    const newPieceData: AllPieceTypes = {
        ...pieceToCopy,
        id: newId,
    };

    // Copy-specific logic

    switch (newPieceData.type) {
        case PieceType.QUESTION:
        case PieceType.GROUPED_QUESTION:
            delete newPieceData.customFieldId;
            delete newPieceData.memberVariablePiece;
            break;
        case PieceType.GROUPED_ANSWER:
            delete newPieceData.customField;
            delete newPieceData.memberVariablePiece;
            break;
        case PieceType.END:
            delete newPieceData.status;
            break;
        case PieceType.STATUS:
            delete newPieceData.statusId;
            break;
        case PieceType.UPDATE_STATUS:
            delete newPieceData.status;
            delete newPieceData.workflowType;
            break;
        case PieceType.CUSTOM_FIELD:
            delete newPieceData.customField;
            delete newPieceData.customFieldOption
            delete newPieceData.memberVariablePiece;
            break;
        default:
            break;
    }

    applyFunctionToPiece(newPieceData, pastePieceShortHand);
    addFullPiece(newPieceData);

    return newId;
}
import { SELECT_GROUP_TYPE_ACTION, UN_SELECT_GROUP_TYPE_ACTION, ADD_GROUP_TYPE_ACTION, DELETE_GROUP_TYPE_ACTION, UPDATE_GROUP_TYPE_ACTION, RE_ORDER_GROUP_TYPE_ACTIONS, GroupTypeActionState, UPDATE_GROUP_TYPE_ACTIONS_DATA, SYNCHRONIZE_GROUP_TYPE_ACTIONS_DATA, CLEAR_GROUP_TYPE_ACTIONS_DELTA, IGroupTypeAction, INSTANTIATE_GROUP_TYPE_ACTION } from './types';
import { reOrderList } from '../../../../helpers/utilities';
import { updateEntries, synchronizeEntries, clearDelta, addEntity, updateEntity, deleteEntity } from '../../../normalized-model';
import { GroupActionTypes } from '../../types';

export const initialState: GroupTypeActionState = {  
    byId: {},
    allEntries: [],
    filteredEntries: [],

    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),
};

export function actionsReducer(state = initialState, action: GroupActionTypes): GroupTypeActionState {
    switch(action.type) {

        case SELECT_GROUP_TYPE_ACTION:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_GROUP_TYPE_ACTION:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_GROUP_TYPE_ACTIONS:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
            };

        case ADD_GROUP_TYPE_ACTION:
            return addEntity<GroupTypeActionState, IGroupTypeAction>(state, action.payload);

        case INSTANTIATE_GROUP_TYPE_ACTION:
            return addEntity<GroupTypeActionState, IGroupTypeAction>(state, action.payload);
        
        case UPDATE_GROUP_TYPE_ACTION:
            return updateEntity<GroupTypeActionState, IGroupTypeAction>(state, action.payload, action.currentTime);

        case DELETE_GROUP_TYPE_ACTION:
            return deleteEntity<GroupTypeActionState, IGroupTypeAction>(state, action.id, action.currentTime);

        case UPDATE_GROUP_TYPE_ACTIONS_DATA:
            return updateEntries<GroupTypeActionState, IGroupTypeAction>(state, action.data);

        case SYNCHRONIZE_GROUP_TYPE_ACTIONS_DATA:
            return synchronizeEntries<GroupTypeActionState, IGroupTypeAction>(state, action.data);

        case CLEAR_GROUP_TYPE_ACTIONS_DELTA:
            return clearDelta<GroupTypeActionState, IGroupTypeAction>(state);
        

        default:
            return state
    }
}
import { SELECT_MEMBER_TYPE_ACTION, UN_SELECT_MEMBER_TYPE_ACTION, RE_ORDER_MEMBER_TYPE_ACTIONS, ADD_MEMBER_TYPE_ACTION, DELETE_MEMBER_TYPE_ACTION, UPDATE_MEMBER_TYPE_ACTION, INewMemberTypeActionData, IUpdateableMemberTypeActionData, SelectActionAction, UnSelectActionAction, ReOrderActionAction, AddMemberTypeActionAction, DeleteMemberTypeActionAction, UpdateMemberTypeActionAction, InstantiateMemberTypeActionAction, INSTANTIATE_MEMBER_TYPE_ACTION } from './types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export function selectMemberTypeAction(id: string): SelectActionAction {
    return {
        type: SELECT_MEMBER_TYPE_ACTION,
        id,
    }
}

export function unSelectMemberTypeAction(): UnSelectActionAction {
    return {
        type: UN_SELECT_MEMBER_TYPE_ACTION,
    }
}

export function reOrderMemberTypeActions(sourceIndex: number, destinationIndex: number, parentId: string): ReOrderActionAction {
    return {
        type: RE_ORDER_MEMBER_TYPE_ACTIONS,
        sourceIndex,
        destinationIndex,
        parentId,
    }
}

export function addMemberTypeAction(payload: INewMemberTypeActionData, parentId: string): AddMemberTypeActionAction {
    const now = moment.utc().format();

    return {
        type: ADD_MEMBER_TYPE_ACTION,
        payload: {
            id: uuidv4(),
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            parentId,
        },
        parentId,
        currentTime: now,
    }
}

export function instantiateMemberTypeAction(payload: IUpdateableMemberTypeActionData, parentId: string): InstantiateMemberTypeActionAction {
    const now = moment.utc().format();

    return {
        type: INSTANTIATE_MEMBER_TYPE_ACTION,
        payload: {
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            parentId,
        },
        parentId,
        currentTime: now,
    }
}

export function deleteMemberTypeAction(id: string, parentId: string): DeleteMemberTypeActionAction {
    return {
        type: DELETE_MEMBER_TYPE_ACTION,
        id,
        parentId,
        currentTime: moment.utc().format(),
    }
}

export function updateMemberTypeAction(payload: IUpdateableMemberTypeActionData): UpdateMemberTypeActionAction {
    return {
        type: UPDATE_MEMBER_TYPE_ACTION,
        payload,
        currentTime: moment.utc().format(),
    }
}
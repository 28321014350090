import { PiecePositionState, ReOrderedListsForEntity } from '../../../helpers/common-types';
import { WorkflowTypeCustomFieldState, IUpdateableWorkflowTypeCustomFieldData, CustomField, IUpdateableFieldChoiceData, FieldChoice } from '../../custom-fields/types';
import { NormalizedModel, Synchronizable } from '../../normalized-model';
import { StatusState } from './statuses/types';

export const SELECT_WORKFLOW_TYPE = 'SELECT_WORKFLOW_TYPE';
export const UN_SELECT_WORKFLOW_TYPE = 'UN_SELECT_WORKFLOW_TYPE';
export const RE_ORDER_WORKFLOW_TYPES = 'RE_ORDER_WORKFLOW_TYPES';
export const ADD_WORKFLOW_TYPE_REQUEST = 'ADD_WORKFLOW_TYPE_REQUEST';
export const ADD_WORKFLOW_TYPE = 'ADD_WORKFLOW_TYPE';
export const INSTANTIATE_WORKFLOW_TYPE = 'INSTANTIATE_WORKFLOW_TYPE';
export const DELETE_WORKFLOW_TYPE = 'DELETE_WORKFLOW_TYPE';
export const UPDATE_WORKFLOW_TYPE = 'UPDATE_WORKFLOW_TYPE';
export const UPDATE_WORKFLOW_TYPE_RICH_TEXT = 'UPDATE_WORKFLOW_TYPE_RICH_TEXT';

export const SET_WORKFLOW_TYPE_SEARCH_STRING = 'SET_WORKFLOW_TYPE_SEARCH_STRING';

export const ADD_WORKFLOW_TO_WORKFLOW_TYPE = 'ADD_WORKFLOW_TO_WORKFLOW_TYPE';
export const REMOVE_WORKFLOW_FROM_WORKFLOW_TYPE = 'REMOVE_WORKFLOW_FROM_WORKFLOW_TYPE';
export const ADD_STATUS_TO_WORKFLOW_TYPE = 'ADD_STATUS_TO_WORKFLOW_TYPE';
export const REMOVE_STATUS_FROM_WORKFLOW_TYPE = 'REMOVE_STATUS_FROM_WORKFLOW_TYPE';

export const UPDATE_WORKFLOW_TYPE_START_PIECE = 'UPDATE_WORKFLOW_TYPE_START_PIECE';
export const SET_ISOLATED_WORKFLOW_TYPE_PIECE = 'SET_ISOLATED_WORKFLOW_TYPE_PIECE';
export const REMOVE_ISOLATED_WORKFLOW_TYPE_PIECE = 'REMOVE_ISOLATED_WORKFLOW_TYPE_PIECE';
export const REGISTER_WORKFLOW_TYPE_VARIABLE = 'REGISTER_WORKFLOW_TYPE_VARIABLE';

export const UPDATE_WORKFLOW_TYPE_BETA_START_PIECE = 'UPDATE_WORKFLOW_TYPE_BETA_START_PIECE';
export const SET_BETA_ISOLATED_WORKFLOW_TYPE_PIECE = 'SET_BETA_ISOLATED_WORKFLOW_TYPE_PIECE';
export const REMOVE_BETA_ISOLATED_WORKFLOW_TYPE_PIECE = 'REMOVE_BETA_ISOLATED_WORKFLOW_TYPE_PIECE';

export const PUBLISH_FLOWCHART_TO_LIVE = 'PUBLISH_FLOWCHART_TO_LIVE';

export const SELECT_WORKFLOW_TYPE_CUSTOM_FIELD = 'SELECT_WORKFLOW_TYPE_CUSTOM_FIELD';
export const UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD = 'UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD';
export const ADD_WORKFLOW_TYPE_CUSTOM_FIELD = 'ADD_WORKFLOW_TYPE_CUSTOM_FIELD';
export const INSTANTIATE_WORKFLOW_TYPE_CUSTOM_FIELD = 'INSTANTIATE_WORKFLOW_TYPE_CUSTOM_FIELD';
export const DELETE_WORKFLOW_TYPE_CUSTOM_FIELD = 'DELETE_WORKFLOW_TYPE_CUSTOM_FIELD';
export const UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD = 'UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD';

export const UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_START_PIECE = 'UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_START_PIECE';
export const SET_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE = 'SET_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE';
export const REMOVE_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE = 'REMOVE_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE';
export const REGISTER_WORKFLOW_TYPE_CUSTOM_FIELD_VARIABLE = 'REGISTER_WORKFLOW_TYPE_CUSTOM_FIELD_VARIABLE';

export const SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION = 'SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION';
export const UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION = 'UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION';
export const RE_ORDER_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION = 'RE_ORDER_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION';
export const ADD_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION = 'ADD_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION';
export const INSTANTIATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION = 'INSTANTIATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION';
export const DELETE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION = 'DELETE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION';
export const UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION = 'UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION';

export const UPDATE_WORKFLOW_TYPES_DATA = 'UPDATE_WORKFLOW_TYPES_DATA';
export const UPDATE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA = 'UPDATE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA';
export const UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA = 'UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA';
export const SYNCHRONIZE_WORKFLOW_TYPES_DATA = 'SYNCHRONIZE_WORKFLOW_TYPES_DATA';
export const SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA = 'SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA';
export const SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA = 'SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA';
export const CLEAR_WORKFLOW_TYPES_DELTA = 'CLEAR_WORKFLOW_TYPES_DELTA';

export interface INewWorkflowTypeData {
    name: string;
    affiliatedEntity: string,
    affiliation: 'member' | 'group' | 'none',
    isCore: boolean,
    areMultipleInstancesAllowed: boolean,
    isManaged?: boolean,
    project: string,
    startPiece: PiecePositionState,
    betaStartPiece: PiecePositionState,

    seedEntityVariable: string,
    seedAffiliationVariable: string,
}

export interface IUpdateableWorkflowTypeData extends INewWorkflowTypeData {
    id: string;
    subTitleFieldId?: string,

    richTextDescription?: string,
}

export interface PreviousVersion extends PiecePositionState {
    deprecationTime: string,
}

export interface IWorkflowType extends IUpdateableWorkflowTypeData, Synchronizable {
    archived?: boolean,

    isolatedPieces: Array<PiecePositionState>,
    betaIsolatedPieces: Array<PiecePositionState>,

    previousVersions: Array<PreviousVersion>,

    workflows: Array<string>,
    statuses: Array<string>,
    pieces: Array<string>,
    variables: Array<string>,
    customFields: Array<string>,
}


export interface WorkflowTypeState extends NormalizedModel<IWorkflowType>, WorkflowTypeCustomFieldState {
    selected: string | undefined,
    statuses: StatusState,

    searchString: string | undefined,

    addMemberWorkflowType?: string,
    editMemberWorkflowType?: string,
    addGroupWorkflowType?: string,
    editGroupWorkflowType?: string,

    areWorkflowTypesReordered: boolean,

    reOrderedCustomFields: ReOrderedListsForEntity,
    reOrderedStatuses: ReOrderedListsForEntity,
}

export interface SelectWorkflowTypeAction {
    type: typeof SELECT_WORKFLOW_TYPE,
    id: string,
}

export interface UnSelectWorkflowTypeAction {
    type: typeof UN_SELECT_WORKFLOW_TYPE,
}

export interface ReOrderWorkflowTypesAction {
    type: typeof RE_ORDER_WORKFLOW_TYPES,
    sourceIndex: number,
    destinationIndex: number,
}

export interface AddWorkflowTypeRequestAction {
    type: typeof ADD_WORKFLOW_TYPE_REQUEST,
    payload: INewWorkflowTypeData,
}

export interface AddWorkflowTypeAction {
    type: typeof ADD_WORKFLOW_TYPE,
    payload: IWorkflowType,
    currentTime: string,
}

export interface InstantiateWorkflowTypeAction {
    type: typeof INSTANTIATE_WORKFLOW_TYPE,
    payload: IWorkflowType,
    currentTime: string,
}

export interface DeleteWorkflowTypeAction {
    type: typeof DELETE_WORKFLOW_TYPE,
    id: string,
    currentTime: string,
}

export interface UpdateWorkflowTypeAction {
    type: typeof UPDATE_WORKFLOW_TYPE,
    payload: IUpdateableWorkflowTypeData,
    currentTime: string,
}

export interface UpdateWorkflowTypeRichTextAction {
    type: typeof UPDATE_WORKFLOW_TYPE_RICH_TEXT,
    richText: string,
    workflowTypeId: string,
    currentTime: string,
}

export interface SetWorkflowTypeSearchStringAction {
    type: typeof SET_WORKFLOW_TYPE_SEARCH_STRING;
    searchString: string;
}

export interface AddWorkflowToWorkflowTypeAction {
    type: typeof ADD_WORKFLOW_TO_WORKFLOW_TYPE,
    workflowTypeId: string,
    workflowId: string,
}

export interface RemoveWorkflowFromWorkflowTypeAction {
    type: typeof REMOVE_WORKFLOW_FROM_WORKFLOW_TYPE,
    workflowTypeId: string,
    workflowId: string,
}

export interface AddStatusToWorkflowTypeAction {
    type: typeof ADD_STATUS_TO_WORKFLOW_TYPE,
    workflowTypeId: string,
    statusId: string,
}

export interface RemoveStatusFromWorkflowTypeAction {
    type: typeof REMOVE_STATUS_FROM_WORKFLOW_TYPE,
    workflowTypeId: string,
    statusId: string,
}


export interface UpdateWorkflowTypeStartPieceAction {
    type: typeof UPDATE_WORKFLOW_TYPE_START_PIECE,
    payload: PiecePositionState,
    workflowTypeId: string,
    currentTime: string,
}

export interface SetIsolatedWorkflowTypePieceAction {
    type: typeof SET_ISOLATED_WORKFLOW_TYPE_PIECE,
    payload: PiecePositionState,
    workflowTypeId: string,
    currentTime: string,
}

export interface RemoveIsolatedWorkflowTypePieceAction {
    type: typeof REMOVE_ISOLATED_WORKFLOW_TYPE_PIECE,
    pieceId: string,
    workflowTypeId: string,
    currentTime: string,
}

export interface RegisterWorkflowTypeVariableAction {
    type: typeof REGISTER_WORKFLOW_TYPE_VARIABLE,
    variableId: string,
    workflowTypeId: string,
    currentTime: string,
}


export interface UpdateWorkflowTypeBetaStartPieceAction {
    type: typeof UPDATE_WORKFLOW_TYPE_BETA_START_PIECE,
    payload: PiecePositionState,
    workflowTypeId: string,
    currentTime: string,
}

export interface SetBetaIsolatedWorkflowTypePieceAction {
    type: typeof SET_BETA_ISOLATED_WORKFLOW_TYPE_PIECE,
    payload: PiecePositionState,
    workflowTypeId: string,
    currentTime: string,
}

export interface RemoveBetaIsolatedWorkflowTypePieceAction {
    type: typeof REMOVE_BETA_ISOLATED_WORKFLOW_TYPE_PIECE,
    pieceId: string,
    workflowTypeId: string,
    currentTime: string,
}


export interface PublishFlowchartToLiveAction {
    type: typeof PUBLISH_FLOWCHART_TO_LIVE,
    workflowTypeId: string,
    currentTime: string,
}



export interface SelectWorkflowTypeCustomFieldAction {
    type: typeof SELECT_WORKFLOW_TYPE_CUSTOM_FIELD,
    id: string,
}

export interface UnSelectWorkflowTypeCustomFieldAction {
    type: typeof UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD,
}

export interface AddWorkflowTypeCustomFieldAction {
    type: typeof ADD_WORKFLOW_TYPE_CUSTOM_FIELD,
    payload: IUpdateableWorkflowTypeCustomFieldData,
    workflowTypeId: string,
    currentTime: string,
}

export interface InstantiateWorkflowTypeCustomFieldAction {
    type: typeof INSTANTIATE_WORKFLOW_TYPE_CUSTOM_FIELD,
    payload: CustomField,
    workflowTypeId: string,
    currentTime: string,
}

export interface DeleteWorkflowTypeCustomFieldAction {
    type: typeof DELETE_WORKFLOW_TYPE_CUSTOM_FIELD,
    id: string,
    workflowTypeId: string,
    currentTime: string,
}

export interface UpdateWorkflowTypeCustomFieldAction {
    type: typeof UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD,
    payload: IUpdateableWorkflowTypeCustomFieldData,
    currentTime: string,
}


export interface UpdateWorkflowTypeCustomFieldStartPieceAction {
    type: typeof UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_START_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
    currentTime: string,
}

export interface SetIsolatedWorkflowTypeCustomFieldPieceAction {
    type: typeof SET_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE,
    payload: PiecePositionState,
    customFieldId: string,
    currentTime: string,
}

export interface RemoveIsolatedWorkflowTypeCustomFieldPieceAction {
    type: typeof REMOVE_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE,
    pieceId: string,
    customFieldId: string,
    currentTime: string,
}

export interface RegisterWorkflowTypeCustomFieldVariableAction {
    type: typeof REGISTER_WORKFLOW_TYPE_CUSTOM_FIELD_VARIABLE,
    variableId: string,
    customFieldId: string,
    currentTime: string,
}


export interface SelectWorkflowTypeCustomFieldOptionAction {
    type: typeof SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION,
    id: string,
}

export interface UnSelectWorkflowTypeCustomFieldOptionAction {
    type: typeof UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION,
}

export interface ReOrderWorkflowTypeCustomFieldOptionAction {
    type: typeof RE_ORDER_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddWorkflowTypeCustomFieldOptionAction {
    type: typeof ADD_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
    parentId: string,
    currentTime: string,
}

export interface InstantiateWorkflowTypeCustomFieldOptionAction {
    type: typeof INSTANTIATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION,
    payload: FieldChoice,
    parentId: string,
    currentTime: string,
}

export interface DeleteWorkflowTypeCustomFieldOptionAction {
    type: typeof DELETE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION,
    id: string,
    parentId: string,
    currentTime: string,
}

export interface UpdateWorkflowTypeCustomFieldOptionAction {
    type: typeof UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION,
    payload: IUpdateableFieldChoiceData,
    currentTime: string,
}

export interface UpdateWorkflowTypesData {
    type: typeof UPDATE_WORKFLOW_TYPES_DATA,
    data: Array<IWorkflowType>,
}

export interface UpdateWorkflowTypeCustomFieldsData {
    type: typeof UPDATE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA,
    data: Array<CustomField>,
}

export interface UpdateWorkflowTypeCustomFieldOptionsData {
    type: typeof UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA,
    data: Array<FieldChoice>,
}

export interface SynchronizeWorkflowTypesData {
    type: typeof SYNCHRONIZE_WORKFLOW_TYPES_DATA,
    data: Array<IWorkflowType>,
    reOrder: Array<string>,
}

export interface SynchronizeWorkflowTypeCustomFieldsData {
    type: typeof SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA,
    data: Array<CustomField>,
}

export interface SynchronizeWorkflowTypeCustomFieldOptionsData {
    type: typeof SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA,
    data: Array<FieldChoice>,
    reOrderedCustomFieldOptions: ReOrderedListsForEntity;
}

export interface ClearWorkflowTypesDelta {
    type: typeof CLEAR_WORKFLOW_TYPES_DELTA,
}

type CustomFieldActions = SelectWorkflowTypeCustomFieldAction | UnSelectWorkflowTypeCustomFieldAction | AddWorkflowTypeCustomFieldAction | InstantiateWorkflowTypeCustomFieldAction | DeleteWorkflowTypeCustomFieldAction | UpdateWorkflowTypeCustomFieldAction;

type CustomFieldChoiceActions = SelectWorkflowTypeCustomFieldOptionAction | UnSelectWorkflowTypeCustomFieldOptionAction | ReOrderWorkflowTypeCustomFieldOptionAction | AddWorkflowTypeCustomFieldOptionAction | InstantiateWorkflowTypeCustomFieldOptionAction | DeleteWorkflowTypeCustomFieldOptionAction | UpdateWorkflowTypeCustomFieldOptionAction;

export type WorkflowTypeActionTypes = SelectWorkflowTypeAction | UnSelectWorkflowTypeAction | ReOrderWorkflowTypesAction | AddWorkflowTypeRequestAction | AddWorkflowTypeAction | InstantiateWorkflowTypeAction | DeleteWorkflowTypeAction | UpdateWorkflowTypeAction | UpdateWorkflowTypeRichTextAction | SetWorkflowTypeSearchStringAction | AddWorkflowToWorkflowTypeAction | RemoveWorkflowFromWorkflowTypeAction | AddStatusToWorkflowTypeAction | RemoveStatusFromWorkflowTypeAction | UpdateWorkflowTypeStartPieceAction | SetIsolatedWorkflowTypePieceAction | RemoveIsolatedWorkflowTypePieceAction | RegisterWorkflowTypeVariableAction | UpdateWorkflowTypeBetaStartPieceAction | SetBetaIsolatedWorkflowTypePieceAction | RemoveBetaIsolatedWorkflowTypePieceAction | PublishFlowchartToLiveAction | UpdateWorkflowTypeCustomFieldStartPieceAction | SetIsolatedWorkflowTypeCustomFieldPieceAction | RemoveIsolatedWorkflowTypeCustomFieldPieceAction | RegisterWorkflowTypeCustomFieldVariableAction | CustomFieldActions | CustomFieldChoiceActions | UpdateWorkflowTypesData | UpdateWorkflowTypeCustomFieldsData | UpdateWorkflowTypeCustomFieldOptionsData | SynchronizeWorkflowTypesData | SynchronizeWorkflowTypeCustomFieldsData | SynchronizeWorkflowTypeCustomFieldOptionsData | ClearWorkflowTypesDelta;
import { combineReducers } from 'redux';

import { StructureState, StructureActionTypes } from './types';
import { projectsReducer } from './project/reducer';
import { levelsReducer } from './level/reducer';
import { rolesReducer } from './role/reducer';

import { locationsReducer } from './location/reducer';

export const structureReducer = combineReducers<StructureState, StructureActionTypes>({
    projects: projectsReducer,
    levels: levelsReducer,
    roles: rolesReducer,
    
    locations: locationsReducer,
});
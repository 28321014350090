import axios from "axios";
import { call, takeEvery } from "redux-saga/effects";
import { BASE_URL } from "../url";
import { AddWorkflowPackageAction, ADD_DATA_PACKAGE, ADD_WORKFLOW_PACKAGE, DeletePackageAction, DELETE_PACKAGE, MarketplacePackage } from "./types";

async function callServerWithData(marketplacePackage: MarketplacePackage) {
    await axios.post(BASE_URL + '/add-package/', marketplacePackage);
}

async function callServerForDeletePackage(marketplacePackageId: string) {
    await axios.get(BASE_URL + '/delete-package/?packageId=' + marketplacePackageId);
}

function* addPackageOnServer(action: AddWorkflowPackageAction) {
    yield call(callServerWithData, action.payload);
}

function* deletePackageOnServer(action: DeletePackageAction) {
    yield call(callServerForDeletePackage, action.id);
}

export function* watchPackageCreationChanges() {
    yield takeEvery([ADD_WORKFLOW_PACKAGE, ADD_DATA_PACKAGE], addPackageOnServer);
}

export function* watchPackageDeletionChanges() {
    yield takeEvery(DELETE_PACKAGE, deletePackageOnServer);
}
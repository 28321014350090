import { select } from '@angular-redux/store';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { translatePhrase } from 'src/shared/helpers/translation';
import { IWidget, WidgetState } from 'src/shared/store/widgets/types';

@Component({
  selector: 'app-message-widget',
  templateUrl: './message-widget.component.html',
  styleUrls: ['./message-widget.component.scss'],
})
export class MessageWidgetComponent implements OnInit, OnDestroy {
  @Input() widgetId: string;
  @Input() textToShow: string;
  widget: IWidget;
  @select(['widgets']) widgetStateSource: Observable<WidgetState>;
  widgetStateSubscription: Subscription;
  widgetState: WidgetState;

  constructor() { }

	translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

  ngOnInit() {
    this.widgetStateSubscription = this.widgetStateSource.subscribe(widgetState => {
      this.widgetState = widgetState;
      this.widget = widgetState.byId[this.widgetId];
    });
  }

  ngOnDestroy() {
    if (this.widgetStateSubscription) {
      this.widgetStateSubscription.unsubscribe();
    }
  }

}

import { Component, HostListener } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { ApplicationState } from '../shared/store/types';
import { fetchLocalData } from '../shared/store/actions';
import { Observable, Subscription } from 'rxjs';
import { IntroductionService } from './services/introduction.service';
import { ToastController } from '@ionic/angular';
import { translatePhrase } from 'src/shared/helpers/translation';
import { getCurrentSnapshot } from 'src/shared/store/database';
import { AlertController } from '@ionic/angular';
import { clearCache } from "clear-cache";
import { initiateFetchAppData } from 'src/shared/store/my-data/actions';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    introduction = {
        title: '',
        description: ''
    };
    punch_hole_coordinates: any;
    punch_rectangle_coordinates: any;
    counter: number = 0;
    changed_url: string = '';
    introductionPageSubscription: Subscription;
    deferredPrompt: any;
    isTabsIntro: boolean = false;
    updateSubscription : Subscription;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private ngRedux: NgRedux<ApplicationState>,
        private introductionService: IntroductionService,
        public toastController: ToastController,
        public alertController: AlertController
    ) {
        this.initializeApp();
    }

    public async showInstallToast() {
		const toast = await this.toastController.create({
			message: 'Install DICEFlow App?',
			position: 'bottom',
			duration: 7000,
            cssClass: 'home-screen-toast',
			buttons: [{
					text: 'Yes',
					handler: () => {
                        this.showInstallBanner();
                        this.toastController.dismiss();
                    }
				}, {
                    text: 'No',
                    role: 'cancel'
                }
			]
		});
		toast.present();
    }
    

    public async showRefreshToast() {
		const toast = await this.toastController.create({
			message: 'UPDATE APPLICATION',
			position: 'bottom',
			duration: 5000,
            cssClass: 'home-screen-toast',
			buttons: [{
					text: 'Yes',
					handler: () => {
                        clearCache(true);
                    }
				}, {
                    text: 'No',
                    role: 'cancel'
                }
			]
		});
		toast.present();
	}

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            this.autoAuthenticate();

            this.introductionService.getPageUrl().then((data: Observable<string>) => {
                this.introductionPageSubscription = data.subscribe((response: string) => {
                    this.changed_url = response;

                    if (!localStorage.introduction_pages) {
                        localStorage.introduction_pages = JSON.stringify([]);
                    }

                    this.intiateIntro();

                });
            });

            // this.update.checkIfUpdateAvailable().then((flag) => {
            //     console.log(flag);

            //     if (flag) {
            //         this.update.presentToastWithOptions(this.translate("UPDATE APPLICATION"), 5000);
            //     }
            // });
        });
    }

    async introConfirmation() {
		const alert = await this.alertController.create({
			header: this.translate('Welcome') + '!',
            mode: 'ios',
			message: this.translate('Would you like an introduction') + '?',
			buttons: [{
                text: this.translate('No'),
                cssClass: 'alert-danger',
                role: 'cancel',
                handler: () => {                    
                    localStorage.introduction_pages = JSON.stringify(['dashboard','more','workflows','members_and_groups', 'maps']);
                }
            }, {
                text: this.translate('Yes'),
                cssClass: 'alert-confirm',
                handler: async () => {
					this.startTabIntro();
                }
            }]
		});

		await alert.present();
	}

    @HostListener('window:beforeinstallprompt', ['$event'])onbeforeinstallprompt(e) {
        // console.log(e);
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        // this.showInstallToast();
    }

    translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

    showInstallBanner() {
        if (this.deferredPrompt !== undefined && this.deferredPrompt !== null) {
            // Show the prompt
            this.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            this.deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                this.deferredPrompt = null;
            });
        }
    }

    intiateIntro() {
        let urls: Array<string> = [];        

        setTimeout(() => {
            switch (this.changed_url) {
                case 'maps':
                    urls = JSON.parse(localStorage.introduction_pages);
                    if (!urls.includes('maps')) {
                        this.startMapsIntro();
                        urls.push('maps');
                        localStorage.introduction_pages = JSON.stringify(urls);
                    }
                    break;
                case 'dashboard':
                    urls = JSON.parse(localStorage.introduction_pages);
                    if (!urls.includes('dashboard')) {
                        this.introConfirmation();
                        urls.push('dashboard');
                        localStorage.introduction_pages = JSON.stringify(urls);
                    }
                    break;
                case 'members_and_groups':
                    urls = JSON.parse(localStorage.introduction_pages);
                    if (!urls.includes('members_and_groups')) {
                        this.startMembersAndGroupsIntro(0);
                        urls.push('members_and_groups');
                        localStorage.introduction_pages = JSON.stringify(urls);
                    }
                    break;
                case 'workflows':
                    urls = JSON.parse(localStorage.introduction_pages);
                    if (!urls.includes('workflows')) {
                        this.startWorkflowsIntro(0);
                        urls.push('workflows');
                        localStorage.introduction_pages = JSON.stringify(urls);
                    }
                    break;
                case 'more':
                    urls = JSON.parse(localStorage.introduction_pages);
                    if (!urls.includes('more')) {
                        this.startMoreIntro(0);
                        urls.push('more');
                        localStorage.introduction_pages = JSON.stringify(urls);
                    }
                    break;
            }
        }, 500);
    }

    async autoAuthenticate() {
        const myId = window.localStorage.getItem('myId');
        const token = window.localStorage.getItem('token');
        if (myId && token) {
            const snapshot = await getCurrentSnapshot(myId);
            if (!!snapshot) {
                this.ngRedux.dispatch(fetchLocalData(snapshot));
            } else {
                if (window.navigator.onLine) {
                    this.ngRedux.dispatch(initiateFetchAppData());
                }
            }
            this.router.navigate(['/tabs']);
        } else {
            this.router.navigate(['login/']);
        }
    }

    isObjectEmpty(object: any) {
        if (object === null || typeof object === 'undefined') {
            return true;
        }

        return Object.entries(object).length === 0 && object.constructor === Object
    }

    clearTabRounds() {
        let i: number;
        for (i = 0; i < document.querySelectorAll('ion-tab-button').length; i += 1) {
            document.querySelectorAll('ion-tab-button')[i].classList.remove('round');
        }
    }

    startTabIntro() {
        setTimeout(() => {
            this.changed_url = '';
            this.isTabsIntro = true;
            this.counter = 0;
            this.punch_hole_coordinates = document.querySelectorAll('ion-tab-button')[0].getBoundingClientRect();
            this.introduction = {
                title: this.translate('Dashboard'),
                description: this.translate('To view statistical data & widgets')
            };
        }, 500);
    }

    getClicks(count: number) {
        this.counter = count;

        switch (this.changed_url) {
            case '': this.continueTabsFlow(count); break;
            case 'maps': this.continueMapsFlow(count); break;
            case 'members_and_groups': this.startMembersAndGroupsIntro(count); break;
            case 'workflows': this.startWorkflowsIntro(count); break;
            case 'more': this.startMoreIntro(count); break;
        }
    }

    startMoreIntro(count: number) {
        switch (count) {
            case 0:
                this.punch_rectangle_coordinates = document.querySelector('.sync-button-holder').getBoundingClientRect();
                this.introduction = {
                    title: this.translate('Sync Data'),
                    description: this.translate('Sync your data with the server')
                };
                break;
            default: this.punch_rectangle_coordinates = '';
                this.counter = 0;
                this.introduction = {
                    title: '',
                    description: ''
                };
                break;
        }
    }


    startWorkflowsIntro(count: number) {
        switch (count) {
            case 0:
                this.punch_hole_coordinates = document.querySelectorAll('ion-tab-button')[2].getBoundingClientRect();
                this.introduction = {
                    title: this.translate('Add New Workflow'),
                    description: this.translate('Tap to view the list of workflow types that can be fired')
                };
                break;
            case 1:
                this.punch_hole_coordinates = '';
                document.querySelector('.tabs-options-holder').classList.add('active');
                document.querySelector('.tabs-option').classList.add('active');
                setTimeout(() => {
                    this.punch_rectangle_coordinates = document.querySelector('.tabs-option').getBoundingClientRect();
                    this.introduction = {
                        title: this.translate('Select a Workflow type'),
                        description: this.translate('Tap on any core or non-core workflow type to start')
                    };
                }, 500);
                break;
            default: this.punch_rectangle_coordinates = '';
                this.punch_hole_coordinates = '';
                this.counter = 0;
                document.querySelector('.tabs-options-holder').classList.remove('active');
                document.querySelector('.tabs-option').classList.remove('active');
                this.introduction = {
                    title: '',
                    description: ''
                };
                break;
        }
    }


    startMembersAndGroupsIntro(count: number) {
        switch (count) {
            case 0:
                this.punch_rectangle_coordinates = document.querySelectorAll('.tabs-holder .tab')[0].getBoundingClientRect();
                this.introduction = {
                    title: this.translate('Members segment'),
                    description: this.translate('List of members under your location')
                };
                break;
            case 1:
                this.punch_rectangle_coordinates = document.querySelectorAll('.tabs-holder .tab')[1].getBoundingClientRect();
                this.introduction = {
                    title: this.translate('Groups segment'),
                    description: this.translate('List of groups under your location')
                };
                break;
            default: this.punch_hole_coordinates = '';
                this.punch_rectangle_coordinates = '';
                document.querySelector('.tabs-options-holder').classList.remove('active');
                document.querySelector('.tabs-option').classList.remove('active');
                this.counter = 0;
                this.introduction = {
                    title: '',
                    description: ''
                };
                break;
        }
    }


    startMapsIntro() {
        setTimeout(() => {
            this.changed_url = 'maps';
            this.counter = 0;
            this.punch_rectangle_coordinates = document.querySelector('.search-input').getBoundingClientRect();            
            this.introduction = {
                title: this.translate('Select Location'),
                description: this.translate('Type to search and select the location')
            };
        }, 500);
    }

    continueMapsFlow(count: number) {
        switch (count) {
            case 1:
                this.punch_rectangle_coordinates = '';
                this.punch_hole_coordinates = document.querySelector('.locate-me').getBoundingClientRect();
                this.introduction = {
                    title: this.translate('Select Location'),
                    description: this.translate('Tap this to go back to default location')
                };
                break;
            default: this.punch_hole_coordinates = '';
                this.counter = 0;
                this.introduction = {
                    title: '',
                    description: ''
                };
                break;
        }
    }

    continueTabsFlow(count: number) {
        switch (count) {
            case 1:
                this.punch_hole_coordinates = document.querySelectorAll('ion-tab-button')[1].getBoundingClientRect();
                this.introduction = {
                    title: this.translate('Members & Groups Tab'),
                    description: this.translate('To view list of members & groups under your location')
                };
                break;
            case 2:
                this.punch_hole_coordinates = document.querySelectorAll('ion-tab-button')[3].getBoundingClientRect();
                this.introduction = {
                    title: this.translate('Workflows Tab'),
                    description: this.translate('To view list of workflows which were fired by you')
                };
                break;
            case 3: this.punch_hole_coordinates = document.querySelectorAll('ion-tab-button')[4].getBoundingClientRect();
                this.introduction = {
                    title: this.translate('More Tab'),
                    description: this.translate('To view other options like language settings, edit profile etc')
                };
                break;
            default: this.punch_hole_coordinates = '';
                this.counter = 0;
                this.isTabsIntro = false;
                this.introduction = {
                    title: '',
                    description: ''
                };
                break;
        }
    }
}

import { StructureActionTypes, UPDATE_STRUCTURE_DATA } from '../types'
import { SELECT_LOCATION, UN_SELECT_LOCATION, ADD_LOCATION, DELETE_LOCATION, UPDATE_LOCATION, RE_ORDER_LOCATIONS, ADD_USER_TO_LOCATION, REMOVE_USER_FROM_LOCATION, LocationState, ADD_MEMBER_TO_LOCATION, REMOVE_MEMBER_FROM_LOCATION, ADD_GROUP_TO_LOCATION, REMOVE_GROUP_FROM_LOCATION, UPDATE_LOCATION_CUSTOM_FIELD_DATA, ILocation, UPDATE_LOCATIONS_DATA, SYNCHRONIZE_LOCATIONS_DATA, CLEAR_LOCATIONS_DELTA, BULK_REMOVE_MEMBERS_FROM_LOCATIONS, BULK_ADD_MEMBERS_TO_LOCATIONS, BULK_ADD_GROUPS_TO_LOCATIONS, BULK_REMOVE_GROUPS_FROM_LOCATIONS } from './types';
import { reOrderList } from '../../../helpers/utilities';
import { ADD_PROJECT, UN_SELECT_PROJECT, SELECT_PROJECT, UPDATE_PROJECTS_DATA } from '../project/types';
import { addEntity, deleteEntity, updateEntityWithCustomFields, updateEntries, synchronizeEntriesForCustomModels, clearDelta, synchronizeReverseLinks } from '../../normalized-model';
import { IMember, SYNCHRONIZE_MEMBERS_DATA } from '../../members/types';
import { IGroup, SYNCHRONIZE_GROUPS_DATA } from '../../groups/types';

const initialState: LocationState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    byProject: {},
    selected: [],

    reOrderedTopLevelLocations: {},
    reOrderedChildLocations: {},
}

export function locationsReducer(state = initialState, action: StructureActionTypes): LocationState {
    let newState: LocationState;

    switch (action.type) {

        // PROJECT ACTIONS

        case ADD_PROJECT:
            return {
                ...state,
                byProject: {
                    ...state.byProject,
                    [action.payload.id]: [],
                }
            }

        case SELECT_PROJECT:
        case UN_SELECT_PROJECT:
            return {
                ...state,
                selected: [],
            }

        case UPDATE_PROJECTS_DATA:
            newState = {
                ...state,
                byProject: {
                    ...state.byProject,
                }
            }

            for (const project of action.data) {
                newState.byProject[project.id] = project.locations ? project.locations.slice() : [];
            }

            return newState;

        // LOCATION ACTIONS

        case SELECT_LOCATION:
            return {
                ...state,
                selected: state.selected.slice(0, action.index + 1).concat([action.id]),
            }

        case UN_SELECT_LOCATION:
            return {
                ...state,
                selected: state.selected.slice(0, action.index),
            }

        case RE_ORDER_LOCATIONS:
            let reOrderedList: Array<string> = [];

            if (action.parentId in state.byProject) {
                reOrderedList = reOrderList(state.byProject[action.parentId], action.sourceIndex, action.destinationIndex);
                // This is a top level location
                return {
                    ...state,
                    byProject: {
                        ...state.byProject,
                        [action.parentId]: reOrderedList,
                    },
                    reOrderedTopLevelLocations: {
                        ...state.reOrderedTopLevelLocations,
                        [action.parentId]: reOrderedList,
                    },
                }

            } else {
                reOrderedList = reOrderList(state.byId[action.parentId].children, action.sourceIndex, action.destinationIndex);

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.parentId]: {
                            ...state.byId[action.parentId],
                            children: reOrderedList,
                        }
                    },
                    reOrderedChildLocations: {
                        ...state.reOrderedChildLocations,
                        [action.parentId]: reOrderedList,
                    },
                }
            }

        case ADD_LOCATION:
            state = addEntity<LocationState, ILocation>(state, action.payload);

            if (action.parentId in state.byProject) {
                // This is a top level location
                return {
                    ...state,
                    byProject: {
                        ...state.byProject,
                        [action.parentId]: state.byProject[action.parentId].concat([action.payload.id]),
                    },
                    reOrderedTopLevelLocations: {
                        ...state.reOrderedTopLevelLocations,
                        [action.parentId]: state.byProject[action.parentId].concat([action.payload.id]),
                    },
                };
            } else {
                // This is the child of another location
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.parentId]: {
                            ...state.byId[action.parentId],
                            children: state.byId[action.parentId].children.concat([action.payload.id]),
                        }
                    },
                    reOrderedChildLocations: {
                        ...state.reOrderedChildLocations,
                        [action.parentId]: state.byId[action.parentId].children.concat([action.payload.id]),
                    },
                };
            }

        case DELETE_LOCATION:
            const newSelectedList = state.selected.slice(0, state.selected.indexOf(action.id) + 1);
            state = deleteEntity<LocationState, ILocation>(state, action.id, action.currentTime);

            if (action.parentId in state.byProject) {
                // This is a top level location
                return {
                    ...state,
                    byProject: {
                        ...state.byProject,
                        [action.parentId]: state.byProject[action.parentId].filter(entry => entry !== action.id),
                    },
                    reOrderedTopLevelLocations: {
                        ...state.reOrderedTopLevelLocations,
                        [action.parentId]: state.byProject[action.parentId].filter(entry => entry !== action.id),
                    },
                    selected: newSelectedList,
                }
            } else {
                /// This is the child of another location
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.parentId]: {
                            ...state.byId[action.parentId],
                            children: state.byId[action.parentId].children.filter(entry => entry !== action.id),
                        },
                    },
                    reOrderedChildLocations: {
                        ...state.reOrderedChildLocations,
                        [action.parentId]: state.byId[action.parentId].children.filter(entry => entry !== action.id),
                    },
                    selected: newSelectedList,
                }
            }

        case UPDATE_LOCATION:
            return updateEntityWithCustomFields<LocationState, ILocation>(state, action.payload, action.currentTime);

        case UPDATE_LOCATION_CUSTOM_FIELD_DATA:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.locationId]: {
                        ...state.byId[action.locationId],
                        customFields: {
                            ...state.byId[action.locationId].customFields,
                            ...action.customFieldData,
                        }
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.locationId]),
            }

        case ADD_USER_TO_LOCATION:
            if (action.locationId in state.byId) {
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.locationId]: {
                            ...state.byId[action.locationId],
                            users: state.byId[action.locationId].users.concat([action.userId]),
                        }
                    }
                }
            } else {
                return state;
            }

        case REMOVE_USER_FROM_LOCATION:
            if (action.locationId in state.byId) {
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.locationId]: {
                            ...state.byId[action.locationId],
                            users: state.byId[action.locationId].users.filter(userId => userId !== action.userId),
                        }
                    }
                }
            } else {
                return state;
            }

        case ADD_MEMBER_TO_LOCATION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.locationId]: {
                        ...state.byId[action.locationId],
                        members: state.byId[action.locationId].members.concat([action.memberId]),
                    }
                }
            }

        case REMOVE_MEMBER_FROM_LOCATION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.locationId]: {
                        ...state.byId[action.locationId],
                        members: state.byId[action.locationId].members.filter(memberId => memberId !== action.memberId),
                    }
                }
            }

        case BULK_ADD_MEMBERS_TO_LOCATIONS:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                },
            };

            for (const linkData of action.links) {
                newState.byId[linkData.locationId] = {
                    ...newState.byId[linkData.locationId],
                    members: newState.byId[linkData.locationId].members.concat([linkData.memberId]),
                };
            }

            return newState;

        case BULK_REMOVE_MEMBERS_FROM_LOCATIONS:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                },
            };

            for (const linkData of action.links) {
                newState.byId[linkData.locationId] = {
                    ...newState.byId[linkData.locationId],
                    members: newState.byId[linkData.locationId].members.filter(memberId => memberId !== linkData.memberId),
                };
            }

            return newState;

        case ADD_GROUP_TO_LOCATION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.locationId]: {
                        ...state.byId[action.locationId],
                        groups: state.byId[action.locationId].groups.concat([action.groupId]),
                    }
                }
            }

        case REMOVE_GROUP_FROM_LOCATION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.locationId]: {
                        ...state.byId[action.locationId],
                        groups: state.byId[action.locationId].groups.filter(groupId => groupId !== action.groupId),
                    }
                }
            }

        case BULK_ADD_GROUPS_TO_LOCATIONS:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                },
            };

            for (const linkData of action.links) {
                newState.byId[linkData.locationId] = {
                    ...newState.byId[linkData.locationId],
                    groups: newState.byId[linkData.locationId].groups.concat([linkData.groupId]),
                };
            }

            return newState;

        case BULK_REMOVE_GROUPS_FROM_LOCATIONS:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                },
            };

            for (const linkData of action.links) {
                newState.byId[linkData.locationId] = {
                    ...newState.byId[linkData.locationId],
                    groups: newState.byId[linkData.locationId].groups.filter(groupId => groupId !== linkData.groupId),
                };
            }

            return newState;

        case UPDATE_LOCATIONS_DATA:
            return updateEntries<LocationState, ILocation>(state, action.data);

        case SYNCHRONIZE_LOCATIONS_DATA:
            state = synchronizeEntriesForCustomModels<LocationState, ILocation>(state, action.data);

            for (const locationId in action.reOrderedChildLocations) {
                state.byId[locationId].children = action.reOrderedChildLocations[locationId];
            }

            return state;

        case SYNCHRONIZE_MEMBERS_DATA:
            return synchronizeReverseLinks<LocationState, ILocation, IMember>(state, action.data, 'location', 'members');

        case SYNCHRONIZE_GROUPS_DATA:
            return synchronizeReverseLinks<LocationState, ILocation, IGroup>(state, action.data, 'location', 'groups');

        case CLEAR_LOCATIONS_DELTA:
            newState = clearDelta<LocationState, ILocation>(state);
            newState.reOrderedTopLevelLocations = {};
            newState.reOrderedChildLocations = {};
            return newState;

        // Structure actions

        case UPDATE_STRUCTURE_DATA:
            return {
                ...action.data.locations,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
            }


        default:
            return state
    }
}
import { Component, OnInit, ViewChild, SecurityContext, OnDestroy } from '@angular/core';
import { isWebUri } from 'valid-url';
import { ModalController, NavParams } from '@ionic/angular';
import { IWorkflow, WorkflowState, WorkflowProcessState, IUpdateableWorkflowData, IWorkflowScreenInput } from '../../shared/store/workflows/types';
import { getUserComputedFieldValue } from 'src/shared/store/flowchart/helpers/custom-fields/user';
import { IWorkflowType } from '../../shared/store/workflows/types/types';
import { select, NgRedux } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import store from 'src/shared/store/main';
import { ApplicationState } from 'src/shared/store/types';
import { MemberState, IUpdateableMemberData } from 'src/shared/store/members/types';
import { getReadableDataForCustomField } from 'src/shared/store/custom-fields';
import { FieldType, CustomFieldValueType, CustomFieldDataForIndividualMembers, WorkflowTypeCustomField } from 'src/shared/store/custom-fields/types';
import { startOrResumeWorkflow, getWorkflowPieceValue } from 'src/shared/store/flowchart/helpers/workflow';
import { updateStatus, updateDueDate, updateProcessState, addToHistory, addWorkflow, navigateBack, navigateForward, addToScreenInputs, updateWorkflowCustomFieldData } from 'src/shared/store/workflows/actions';
import { IStatus } from 'src/shared/store/workflows/types/statuses/types';
import { PieceState, PieceType } from 'src/shared/store/flowchart/pieces/types';
import { getWorkflowQuestionValidation, getWorkflowQuestionValidationValue } from 'src/shared/store/flowchart/helpers/question';
import { VariableType, VariableState } from 'src/shared/store/flowchart/variables/types';
import { IUpdateableGroupData, GroupState } from 'src/shared/store/groups/types';
import { updateLocationCustomFieldData } from 'src/shared/store/structure/location/actions';
import { updateUserCustomFieldData } from 'src/shared/store/users/actions';
import { updateMemberCustomFieldData, addMember, updateMembersLocation } from 'src/shared/store/members/actions';
import { updateGroupCustomFieldData, addGroup, setMembersForGroup, updateGroupsLocation } from 'src/shared/store/groups/actions';
import { DefaultFlowchartProcessState } from 'src/shared/store/flowchart/types';
import { getMemberComputedFieldValue } from 'src/shared/store/flowchart/helpers/custom-fields/member';
import { getGroupComputedFieldValue } from 'src/shared/store/flowchart/helpers/custom-fields/group';
import { isUUID, dataURLtoFile } from 'src/shared/helpers/utilities';
import { getPieceValueType } from 'src/shared/store/flowchart/helpers';
import { translatePhrase } from 'src/shared/helpers/translation';
import * as moment from 'moment';
import { ToastService, ToastType } from '../services/toast.service';
import draftToHtml from 'draftjs-to-html';
import { DomSanitizer } from '@angular/platform-browser';
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';
import { IonContent } from '@ionic/angular';
import * as isValidCoordinates from 'is-valid-coordinates';
import { completionPercentageOfWorkflow } from 'src/shared/store/flowchart/helpers/progress';
import { VariableValueType } from 'src/shared/helpers/common-types';
import { BASE_URL } from 'src/shared/store/url';

export interface ShowWidgetProps {
    entityIds: Array<string>;
    type: string;
    typeId?: string;
    customFields: Array<string>;

    xAxis?: string;
    yAxis?: string;
    yAxisAggregation?: 'sum' | 'average';
    selectedWidgetType?: 'table' | 'line' | 'bar' | 'donut' | 'message';

    aggregation?: 'none' | 'count' | 'sum' | 'average';

};

type Option = {
    name: string;
    value: string;
}

@Component({
    selector: 'app-workflow-executor',
    templateUrl: './workflow-executor.page.html',
    styleUrls: ['./workflow-executor.page.scss'],
})
export class WorkflowExecutorPage implements OnInit, OnDestroy {
    @ViewChild(IonContent) content: IonContent;

    @select(state => state) applicationStateSource: Observable<ApplicationState>;
    applicationState: ApplicationState;
    @select('members') membersDataSource: Observable<MemberState>;
    membersData: MemberState;
    groupsData: GroupState;
    @select('workflows') workflowDataSource: Observable<WorkflowState>;
    @select(['flowchart', 'pieces']) piecesDataSource: Observable<PieceState>;
    piecesData: PieceState;
    variablesData: VariableState;
    isTriggered: boolean;

    public heading: string;
    public display: 'question' | 'choose' | 'start' | 'end' | 'transfer' | 'switch' | 'continue' | 'group' | 'show' | 'report' | 'add-workflow';

    public workflowData: WorkflowState;
    public workflow: IWorkflow;
    workflow_details: IWorkflow;
    workflow_details_customfields: Array<WorkflowTypeCustomField> = [];
    public workflowType: IWorkflowType;
    public workflowStatus: IStatus;

    public transferId: string;
    public questionId: string;
    public customFieldIdForQuestion: string;

    showDataFragmentList: any = {};

    public showDataText: string | undefined;
    public showDataType: string = '';
    public showDataWidgetProps: ShowWidgetProps | undefined | any;
    public continuePieceId: string;
    public groupId: string;
    public questionIdsInGroup: Array<string>;
    public chooseIdsInGroup: Array<string>;
    public groupLoopVariable: string | undefined;
    public groupIterableValue: Array<string> | undefined;
    public groupHeading: string | undefined;
    applicationStateSubscription: Subscription;
    workflow_process_state: WorkflowProcessState;
    isLoading: boolean = false;
    showLoader: boolean = false;
    richText: string;

    showReportDetails: boolean = false;

    routineSubscription: Subscription;

    normalEntries: Array<Option> = [];
    perMemberEntries: Array<{
        name: string,
        subTitle: string,
        value: Array<Option>,
    }> = [];

    userEntries: Array<{
        userId: string,
        time: string,
        normalEntries: Array<Option>,
        transferedUser: string,
        perMemberEntries: Array<{
            name: string,
            subTitle: string,
            value: Array<Option>,
        }>
    }> = [];

    isBetaTester = false;

    nextWorkflowId: string | undefined;
    endWorkflowText: string = '';
    addWorkflowErrorMessage = '';
    isShowingAddWorkflowPiece = false;

    validatedChoicesForId: any = {};

    cachedCustomFieldValues: Array<{
        entityId: string,
        type: VariableType,
        fieldId: string,
        value: CustomFieldValueType,
    }> = [];
    public choiceInputsIsExpanded: {
        [questionId: string]: boolean
    }


    public userInputs: {
        [customFieldId: string]: CustomFieldValueType,
    };

    public userInputsForList: {
        [listItem: string]: {
            [customFieldId: string]: CustomFieldValueType,
        }
    };

    public expandedInputs: {
        [customFieldId: string]: boolean,
    };

    public expandedInputsForList: {
        [listItem: string]: {
            [customFieldId: string]: boolean,
        }
    };

    public errorMessages: {
        [questionId: string]: string,
    };

    public errorMessagesForList: {
        [listItem: string]: {
            [questionId: string]: string,
        }
    };

    public choiceInputs: {
        [questionId: string]: string | Array<string>,
    };

    public choiceErrorMessages: {
        [questionId: string]: string,
    };

    constructor(
        private modalController: ModalController,
        private navParams: NavParams,
        private ngRedux: NgRedux<ApplicationState>,
        public toastService: ToastService,
        private sanitizer: DomSanitizer,
        private router: Router
    ) {
        this.choiceInputsIsExpanded = {};
        this.userInputs = {};
        this.errorMessages = {};

        this.expandedInputs = {};
        this.expandedInputsForList = {};

        this.choiceInputs = {};
        this.choiceErrorMessages = {};

        this.userInputsForList = {};
        this.errorMessagesForList = {};
    }

    getValidChoiceForId(data) {
        this.validatedChoicesForId = data;
    }

    translate(phrase: string) {
        if (typeof phrase === 'string') {
            return translatePhrase(phrase.trim());
        } else {
            return '';
        }
    }

    transformYourHtml(htmlTextWithStyle: any) {
        return this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle));
    }

    customFieldUpdateTimeout: number;
    toggleInputExpansionForChoose(questionId: string) {
        if (this.choiceInputsIsExpanded[questionId]) {
            this.choiceInputsIsExpanded[questionId] = !this.choiceInputsIsExpanded[questionId];
        } else {
            this.choiceInputsIsExpanded[questionId] = true;
        }
    }

    updateCustomFieldValue = (workflowId: string, entityId: string, type: VariableType, fieldId: string, value: CustomFieldValueType, memberId?: string) => {

        if (type === VariableType.LOCATION) {
            this.ngRedux.dispatch(updateLocationCustomFieldData(entityId, { [fieldId]: value }));
        } else if (type === VariableType.USER) {
            this.ngRedux.dispatch(updateUserCustomFieldData(workflowId, entityId, { [fieldId]: value }));
        } else if (type === VariableType.MEMBER) {
            this.ngRedux.dispatch(updateMemberCustomFieldData(workflowId, entityId, { [fieldId]: value }));
        } else if (type === VariableType.GROUP) {
            this.ngRedux.dispatch(updateGroupCustomFieldData(workflowId, entityId, { [fieldId]: value }));
        } else if (type === VariableType.WORKFLOW) {
            if (memberId) {
                this.ngRedux.dispatch(updateWorkflowCustomFieldData(workflowId, entityId, { [fieldId]: { [memberId]: value } }));
            } else {
                this.ngRedux.dispatch(updateWorkflowCustomFieldData(workflowId, entityId, { [fieldId]: value }));
            }
        }
    }

    updateLocation = (entityIds: Array<string>, type: VariableType, locationId: string) => {
        if (type === VariableType.MEMBER || type === VariableType.MEMBERS_LIST) {
            this.ngRedux.dispatch(updateMembersLocation(entityIds, locationId));
        } else if (type === VariableType.GROUP || type === VariableType.GROUPS_LIST) {
            this.ngRedux.dispatch(updateGroupsLocation(entityIds, locationId));
        }
    }

    navigateFlowBack(workflowId: string) {
        if (this.workflow.historyIndex < 2 || (typeof this.workflow.restrictedHistoryIndex !== 'undefined' &&
            this.workflow.historyIndex <= this.workflow.restrictedHistoryIndex + 1)) {
            this.toastService.presentToastWithOptions("You are not allowed to go back from this point of the flow.", ToastType.ERROR);
        } else {
            this.ngRedux.dispatch(navigateBack(workflowId));
        }
    };

    getWorkflowProcessState = () => {
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: this.workflow.history[this.workflow.historyIndex].customFields,
            lastComputedPiece: this.workflow.history[this.workflow.historyIndex].lastComputedPiece,
            executionStack: this.workflow.history[this.workflow.historyIndex].executionStack,
            forIterationCounts: this.workflow.history[this.workflow.historyIndex].forIterationCounts,
            variables: this.workflow.history[this.workflow.historyIndex].variables,
            displayingQuestionPieceId: this.workflow.history[this.workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: this.workflow.history[this.workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: this.workflow.history[this.workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: this.workflow.history[this.workflow.historyIndex].displayingTransferPieceId,
            displayingContinuePieceId: this.workflow.history[this.workflow.historyIndex].displayingContinuePieceId,
            displayingAddWorkflowPieceId: this.workflow.history[this.workflow.historyIndex].displayingAddWorkflowPieceId,
            createdWorkflowId: this.workflow.history[this.workflow.historyIndex].createdWorkflowId,
        }));

        return processState;
    }

    getCompletionRate() {
        try {
            return Number((completionPercentageOfWorkflow(this.workflow_details.id) / 100).toPrecision(1));
        } catch {
            return 0;
        }
    }

    updateStatus = (workflowId: string, statusId: string) => {
        this.ngRedux.dispatch(updateStatus(workflowId, statusId));
    }

    updateDueDate = (workflowId: string, dueDate: string) => {
        this.ngRedux.dispatch(updateDueDate(workflowId, dueDate));
    }

    addToHistory = (processState: WorkflowProcessState, workflowId: string, userId: string) => {
        this.ngRedux.dispatch(addToHistory(processState, workflowId, userId));
    }

    addToScreenInputs = (workflowId: string, screenInput: IWorkflowScreenInput) => {
        this.ngRedux.dispatch(addToScreenInputs(workflowId, screenInput));
    }

    addWorkflow = (payload: IUpdateableWorkflowData) => {
        this.ngRedux.dispatch(addWorkflow(payload));
    }

    updateWorkflowProcessState = (processState: WorkflowProcessState, workflowId: string, userId: string) => {
        this.ngRedux.dispatch(updateProcessState(processState, workflowId, userId));
    }

    addMember = (memberData: IUpdateableMemberData) => {
        this.ngRedux.dispatch(addMember(memberData));
    }

    addGroup = (groupData: IUpdateableGroupData) => {
        this.ngRedux.dispatch(addGroup(groupData));
    }

    setMembersForGroup = (groupId: string, memberTypes: 'representatives' | 'all_members', memberIds: Array<string>) => {
        this.ngRedux.dispatch(setMembersForGroup(groupId, memberTypes, memberIds));
    }

    doesWorkflowConditionPass = (workflowId: string) => {
        const processState = this.getWorkflowProcessState();

        if (!processState.displayingContinuePieceId) {
            return false;
        }

        const continuePiece = this.applicationState.flowchart.pieces.byId[processState.displayingContinuePieceId];

        if (continuePiece.type !== PieceType.CONTINUE) {
            return false;
        }

        if (!continuePiece.condition) {
            return false;
        }

        const isConditionPassing = !!getWorkflowPieceValue(this.applicationState, processState, workflowId, continuePiece.condition);

        return isConditionPassing;
    }

    startOrResumeWorkflow = () => {

        const processState = this.getWorkflowProcessState();
        let display: string;

        const workflowStatus = this.workflowData.types.statuses.byId[this.workflow.status];

        let workflowAddSuccessful = true;

        if (processState.displayingQuestionPieceId) {
            const questionPiece = this.applicationState.flowchart.pieces.byId[processState.displayingQuestionPieceId];

            if (questionPiece.type === PieceType.QUESTION) {
                display = 'question';
            } else {
                display = 'choose';
            }

        } else if (processState.displayingShowPieceId) {
            display = 'show';
        } else if (processState.displayingGroupPieceId) {
            display = 'group';
        } else if (processState.displayingTransferPieceId) {
            display = 'transfer';
        } else if (processState.displayingContinuePieceId) {
            display = 'continue';
        } else if (workflowStatus.isTerminal) {
            display = 'end';
        } else if (processState.createdWorkflowId) {
            display = 'switch';
        } else if (processState.displayingAddWorkflowPieceId) {
            display = 'add-workflow';
            workflowAddSuccessful = this.addWorkflowFromPiece(processState.displayingAddWorkflowPieceId);
        } else {
            display = 'start';
        }

        let shouldWorkflowExecute = false;

        if (display === 'start') {
            shouldWorkflowExecute = true;
        } else if (display === 'continue') {
            const doesContinueConditionPass = this.doesWorkflowConditionPass(this.workflow.id);
            shouldWorkflowExecute = doesContinueConditionPass;
            processState.displayingContinuePieceId = undefined;
        } else if (display === 'add-workflow') {
            shouldWorkflowExecute = workflowAddSuccessful;
        }

        if (shouldWorkflowExecute) {
            startOrResumeWorkflow(this.applicationState, processState, this.workflow.id, this.updateStatus,
                this.updateDueDate, this.updateCustomFieldValue, this.updateLocation, this.addToHistory, this.addMember,
                this.addGroup, this.setMembersForGroup, this.addWorkflow);
        }

    }

    callNumber(number: string) {
        window.open('tel:' + number);
    }

    showMap(latlng: string) {
        var url = "https://maps.google.com/?q=" + latlng.split(' ')[0] + ',' + latlng.split(' ')[1];
        window.open(url);
    }

    continueAfterDisplay = () => {

        const workflow = this.workflow;

        if (!workflow.history[workflow.historyIndex].displayingShowPieceId) {
            throw new Error('This can only be called when a display piece is being shown')
        }

        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].displayingShowPieceId,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingGroupPieceId: undefined,
            displayingTransferPieceId: undefined,
            displayingContinuePieceId: undefined,
            displayingAddWorkflowPieceId: undefined,
            createdWorkflowId: undefined,
        }));

        startOrResumeWorkflow(this.applicationState, processState, this.workflow.id, this.updateStatus,
            this.updateDueDate, this.updateCustomFieldValue, this.updateLocation, this.addToHistory, this.addMember,
            this.addGroup, this.setMembersForGroup, this.addWorkflow);
    }

    getNewWorkflowHeading = () => {
        const newWOrkflowId = this.workflow.history[this.workflow.historyIndex].createdWorkflowId;

        if (!newWOrkflowId) {
            throw new Error('Cannot get the heading of a workflow that does not exist');
        }

        const workflow = this.applicationState.workflows.byId[newWOrkflowId];
        const workflowType = this.applicationState.workflows.types.byId[workflow.type];

        if (workflowType.affiliation === 'member') {
            const member = this.membersData.byId[workflow.affiliatedEntity];
            const memberType = this.membersData.types.byId[member.type];
            let memberName: VariableValueType;

            const nameField = this.membersData.types.customFields.byId[memberType.nameFieldId];

            if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
                const processState: DefaultFlowchartProcessState = {
                    displayingContinuePieceId: undefined,
                    displayingAddWorkflowPieceId: undefined,
                    customFields: { ...member.customFields },
                    variables: {
                        [nameField.seedEntityVariable]: member.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                };

                memberName = getMemberComputedFieldValue(this.applicationState, processState, nameField.startPiece.piece, member.id, nameField);
            } else {
                memberName = member.customFields[memberType.nameFieldId];
            }

            if (Array.isArray(memberName)) {

                if (memberName.length > 0 && Array.isArray(memberName[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                memberName = memberName as Array<string>;
            }

            memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

            return workflowType.name + ' for ' + memberName;
        } else {
            const group = this.groupsData.byId[workflow.affiliatedEntity];
            const groupType = this.groupsData.types.byId[group.type];
            let groupName: VariableValueType;

            const nameField = this.groupsData.types.customFields.byId[groupType.nameFieldId];

            if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
                const processState: DefaultFlowchartProcessState = {
                    displayingContinuePieceId: undefined,
                    displayingAddWorkflowPieceId: undefined,
                    customFields: { ...group.customFields },
                    variables: {
                        [nameField.seedEntityVariable]: group.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                };

                groupName = getGroupComputedFieldValue(this.applicationState, processState, nameField.startPiece.piece, group.id, nameField);
            } else {
                groupName = group.customFields[groupType.nameFieldId];
            }

            if (Array.isArray(groupName)) {

                if (groupName.length > 0 && Array.isArray(groupName[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                groupName = groupName as Array<string>;
            }

            groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');

            return workflowType.name + ' for ' + groupName;
        }
    }

    async switchToNewWorkflow(newWorkflowId: string) {

        this.updateWorkflowProcessState({
            customFields: this.workflow.history[this.workflow.historyIndex].customFields,
            lastComputedPiece: this.workflow.history[this.workflow.historyIndex].lastComputedPiece,
            executionStack: this.workflow.history[this.workflow.historyIndex].executionStack,
            forIterationCounts: this.workflow.history[this.workflow.historyIndex].forIterationCounts,
            variables: this.workflow.history[this.workflow.historyIndex].variables,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingGroupPieceId: undefined,
            displayingTransferPieceId: undefined,
            createdWorkflowId: undefined,
            displayingContinuePieceId: undefined,
            displayingAddWorkflowPieceId: undefined
        }, this.workflow.id, this.workflow.user);

        this.display = 'start';

        const modal = await this.modalController.create({
            component: WorkflowExecutorPage,
            componentProps: {
                workflow_id: newWorkflowId,
                isTriggered: true
            }
        });

        modal.onDidDismiss().then(() => {
            this.renderWorkflow();
        });

        return await modal.present();
    }

    async switchToNextWorkflow() {

        await this.modalController.dismiss();

        if (!this.nextWorkflowId) {
            return;
        }

        const modal = await this.modalController.create({
            component: WorkflowExecutorPage,
            componentProps: {
                workflow_id: this.nextWorkflowId,
                isTriggered: true
            }
        });

        modal.present()
    }

    exitTransferScreen() {

        this.updateWorkflowProcessState({
            customFields: this.workflow.history[this.workflow.historyIndex].customFields,
            lastComputedPiece: this.workflow.history[this.workflow.historyIndex].displayingTransferPieceId,
            executionStack: this.workflow.history[this.workflow.historyIndex].executionStack,
            forIterationCounts: this.workflow.history[this.workflow.historyIndex].forIterationCounts,
            variables: this.workflow.history[this.workflow.historyIndex].variables,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingGroupPieceId: undefined,
            displayingTransferPieceId: undefined,
            createdWorkflowId: undefined,
            displayingAddWorkflowPieceId: undefined,
            displayingContinuePieceId: undefined
        }, this.workflow.id, this.workflow.user);

        this.modalController.dismiss();
        this.back();
        this.router.navigate(['tabs/workflows']);
    }

    getShowDataFromPieceId = (showPieceId: string, workflowProcessState: WorkflowProcessState): ShowWidgetProps | string | Array<string> => {
        const showPiece = this.piecesData.byId[showPieceId];
        const workflowId = this.workflow.id;

        if (showPiece.type !== PieceType.SHOW && showPiece.type !== PieceType.GROUPED_SHOW) {
            throw new Error('The piece must be a show piece');
        }

        if (!showPiece.variableToShow) {
            throw new Error('There is no variable to show');
        }

        const variableData: {
            [variableId: string]: string | Array<string>,
        } = {};

        if (this.choiceInputs) {
            for (const choosePieceId in this.choiceInputs) {
                const choosePiece = this.piecesData.byId[choosePieceId];

                if (choosePiece.type !== PieceType.CHOOSE && choosePiece.type !== PieceType.GROUPED_CHOOSE) {
                    throw new Error('This piece must be a choose piece');
                }

                if (!choosePiece.choiceVariable) {
                    throw new Error('The choose piece must have a variable selected');
                }

                variableData[choosePiece.choiceVariable] = this.choiceInputs[choosePieceId];
            }
        }

        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflowProcessState.customFields,
            lastComputedPiece: workflowProcessState.displayingShowPieceId,
            executionStack: workflowProcessState.executionStack,
            forIterationCounts: workflowProcessState.forIterationCounts,
            variables: {
                ...workflowProcessState.variables,
                ...variableData,
            },
            displayingQuestionPieceId: workflowProcessState.displayingQuestionPieceId,
            displayingShowPieceId: workflowProcessState.displayingShowPieceId,
            displayingGroupPieceId: workflowProcessState.displayingGroupPieceId,
            displayingTransferPieceId: workflowProcessState.displayingTransferPieceId,
            displayingContinuePieceId: workflowProcessState.displayingContinuePieceId,
            displayingAddWorkflowPieceId: workflowProcessState.displayingAddWorkflowPieceId,
            createdWorkflowId: workflowProcessState.createdWorkflowId,
        }));

        if (!isUUID(showPiece.variableToShow)) {
            return showPiece.variableToShow;
        }

        const allAnswers = {
            ...this.userInputsForList,
            ...this.userInputs,
        };

        let variableValue = getWorkflowQuestionValidationValue(this.applicationState, processState, this.workflow.id, showPiece.id, undefined, allAnswers, showPiece.variableToShow);

        const showVariableType = getPieceValueType(showPiece.variableToShow, this.applicationState.flowchart.pieces, this.applicationState.flowchart.variables);

        let selectedTypeForShow: 'User' | 'Member' | 'Group' | 'Workflow' | 'Text' = 'Text';

        if (typeof showVariableType === 'undefined' || showVariableType === VariableType.TEXT) {
            if (typeof variableValue === 'string') {
                return translatePhrase(String(variableValue));
            }
            return String(variableValue);
        } else if (showVariableType === VariableType.TEXT_LIST) {

            if (typeof variableValue === 'undefined') {
                return '';
            }

            if (!Array.isArray(variableValue)) {
                throw new Error('Variable value must be a string');
            }

            const stringValues = variableValue as Array<string>;

            const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
                customFields: workflowProcessState.customFields,
                lastComputedPiece: workflowProcessState.displayingShowPieceId,
                executionStack: workflowProcessState.executionStack,
                forIterationCounts: workflowProcessState.forIterationCounts,
                variables: {
                    ...workflowProcessState.variables,
                    ...variableData,
                },
                displayingQuestionPieceId: workflowProcessState.displayingQuestionPieceId,
                displayingShowPieceId: workflowProcessState.displayingShowPieceId,
                displayingGroupPieceId: workflowProcessState.displayingGroupPieceId,
                displayingTransferPieceId: workflowProcessState.displayingTransferPieceId,
                displayingContinuePieceId: workflowProcessState.displayingContinuePieceId,
                displayingAddWorkflowPieceId: workflowProcessState.displayingAddWorkflowPieceId,
                createdWorkflowId: workflowProcessState.createdWorkflowId,
            }));

            let widgetTitle = showPiece.widgetTitle ? showPiece.widgetTitle : 'Text list';

            if (typeof widgetTitle === 'string' && isUUID(widgetTitle)) {
                const pieceValue = getWorkflowPieceValue(this.applicationState, processState, this.workflow.id, widgetTitle);

                if (typeof pieceValue === 'string') {
                    widgetTitle = pieceValue;
                }
            }

            const listValues: Array<string> = [translatePhrase(widgetTitle)];

            for (const value of stringValues) {
                listValues.push(translatePhrase(value));
            }

            return listValues;
        } else if (showVariableType === VariableType.USER || showVariableType === VariableType.USERS_LIST) {
            selectedTypeForShow = 'User';
        } else if (showVariableType === VariableType.MEMBER || showVariableType === VariableType.MEMBERS_LIST) {
            selectedTypeForShow = 'Member';
        } else if (showVariableType === VariableType.GROUP || showVariableType === VariableType.GROUPS_LIST) {
            selectedTypeForShow = 'Group';
        } else if (showVariableType === VariableType.WORKFLOW || showVariableType === VariableType.WORKFLOWS_LIST) {
            selectedTypeForShow = 'Workflow';
        } else if (showVariableType === VariableType.DATA_FRAGMENT) {
            if (typeof variableValue === 'undefined') {
                return '';
            }

            if (typeof variableValue !== 'string') {
                throw new Error('Variable value must be a string');
            }

            if (variableValue in this.applicationState.staticInfo.fragments.byId) {
                return this.applicationState.staticInfo.fragments.byId[variableValue].name;
            }
        } else if (showVariableType === VariableType.DATA_FRAGMENTS_LIST) {
            if (typeof variableValue === 'undefined') {
                return '';
            }

            if (!Array.isArray(variableValue)) {
                throw new Error('Variable value must be a string');
            }

            const stringValues = variableValue as Array<string>;

            const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
                customFields: workflowProcessState.customFields,
                lastComputedPiece: workflowProcessState.displayingShowPieceId,
                executionStack: workflowProcessState.executionStack,
                forIterationCounts: workflowProcessState.forIterationCounts,
                variables: {
                    ...workflowProcessState.variables,
                    ...variableData,
                },
                displayingQuestionPieceId: workflowProcessState.displayingQuestionPieceId,
                displayingShowPieceId: workflowProcessState.displayingShowPieceId,
                displayingGroupPieceId: workflowProcessState.displayingGroupPieceId,
                displayingTransferPieceId: workflowProcessState.displayingTransferPieceId,
                displayingContinuePieceId: workflowProcessState.displayingContinuePieceId,
                displayingAddWorkflowPieceId: workflowProcessState.displayingAddWorkflowPieceId,
                createdWorkflowId: workflowProcessState.createdWorkflowId,
            }));

            let widgetTitle = showPiece.widgetTitle ? showPiece.widgetTitle : 'Data fragments';

            const workflowId = this.workflow.id;

            if (typeof widgetTitle === 'string' && isUUID(widgetTitle)) {
                const pieceValue = getWorkflowPieceValue(this.applicationState, processState, workflowId, widgetTitle);

                if (typeof pieceValue === 'string') {
                    widgetTitle = pieceValue;
                }
            }

            return [widgetTitle, ...stringValues.map(value => this.applicationState.staticInfo.fragments.byId[value].name)];
        } else {
            return String(variableValue);
        }

        if (typeof variableValue === 'undefined') {
            variableValue = [];
        }

        if (!Array.isArray(variableValue) && typeof variableValue !== 'string') {
            throw new Error('The variable value must be an iterable');
        }

        if (Array.isArray(variableValue)) {

            if (variableValue.length > 0 && Array.isArray(variableValue[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            variableValue = variableValue as Array<string>;
        }

        return {
            entityIds: typeof variableValue === 'string' ? [variableValue] : variableValue,
            type: selectedTypeForShow,
            typeId: showPiece.entityType,
            customFields: showPiece.customFieldIds || [],
            selectedWidgetType: showPiece.startingDisplayType,
            xAxis: showPiece.xAxis,
            yAxis: showPiece.yAxis,
            yAxisAggregation: showPiece.yAxisAggregation,
        };
    }

    updateUserInput = (questionId: string, value: CustomFieldValueType) => {
        this.userInputs = {
            ...this.userInputs,
            [questionId]: value,
        };
    }

    updateUserInputForChoice = (questionId: string, value: string | Array<string>) => {
        this.choiceInputs = {
            ...this.choiceInputs,
            [questionId]: value,
        };
    }

    updateUserInputForList = (listId: string, customFieldId: string, value: CustomFieldValueType) => {
        if (typeof this.userInputsForList[listId] === 'undefined') {
            this.userInputsForList[listId] = {};
        }

        this.userInputsForList = {
            ...this.userInputsForList,
            [listId]: {
                ...this.userInputsForList[listId],
                [customFieldId]: value,
            }
        }
    }

    toggleInputExpansion = (customFieldId: string) => {
        this.expandedInputs = {
            ...this.expandedInputs,
            [customFieldId]: !this.expandedInputs[customFieldId],
        };
    }

    toggleInputExpansionForList = (listId: string, customFieldId: string) => {

        if (typeof this.expandedInputsForList[listId] === 'undefined') {
            this.expandedInputsForList[listId] = {};
        }

        this.expandedInputsForList = {
            ...this.expandedInputsForList,
            [listId]: {
                ...this.expandedInputsForList[listId],
                [customFieldId]: !this.expandedInputsForList[listId][customFieldId],
            }
        }
    }

    getAllQuestionsInGroup = (groupPieceId: string) => {
        const questionIds: Array<string> = [];
        const chooseIds: Array<string> = [];

        const groupPiece = this.piecesData.byId[groupPieceId];

        if (groupPiece.type !== PieceType.GROUP && groupPiece.type !== PieceType.GROUP_FOR_LIST && groupPiece.type !== PieceType.SECTION) {
            throw new Error('The id must be a group ID');
        }

        if (!groupPiece.innerPiece) {
            throw new Error('The group piece must have an inner piece');
        }

        let pieceIdToConsider = groupPiece.innerPiece;

        do {
            const pieceToConsider = this.piecesData.byId[pieceIdToConsider];

            if (pieceToConsider.type === PieceType.GROUPED_QUESTION) {
                questionIds.push(pieceIdToConsider);
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.GROUPED_CHOOSE) {
                chooseIds.push(pieceIdToConsider);
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.SECTION) {
                const questionsInSection = this.getAllQuestionsInGroup(pieceToConsider.id);
                questionIds.push.apply(questionIds, questionsInSection.questionIds);
                chooseIds.push.apply(chooseIds, questionsInSection.chooseIds);
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.GROUPED_SHOW) {
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else {
                throw new Error('This piece can only be a grouped question, a grouped show, or a section');
            }

        } while (pieceIdToConsider);

        return {
            questionIds,
            chooseIds,
        };
    }

    updateWorkflowWithAnswer = (
        workflowId: string,
        customField: WorkflowTypeCustomField,
        questionId: string,
        processState: WorkflowProcessState,
        answer: CustomFieldValueType
    ) => {
        const workflow = this.workflowData.byId[workflowId];
        const workflowType = this.workflowData.types.byId[workflow.type];

        // Store the answer in a custom field
        const isForSingleMember = workflowType.affiliation === 'group' && customField.affiliation === 'member';
        let customFieldData = processState.customFields[customField.id];

        const questionPiece = this.piecesData.byId[questionId];

        if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
            throw new Error('This is not a question piece');
        }

        if (isForSingleMember) {
            if (!questionPiece.memberVariablePiece) {
                throw new Error('This piece needs to have a member variable along with this custom field');
            }

            const memberVariablePiece = this.piecesData.byId[questionPiece.memberVariablePiece];

            if (memberVariablePiece.type !== PieceType.VARIABLE) {
                throw new Error('The answer variable must be pointed to a variable piece');
            }

            if (!memberVariablePiece.variable) {
                throw new Error('The variable value for the piece must point to an actual variable');
            }

            const memberId = processState.variables[memberVariablePiece.variable];

            if (typeof memberId !== 'string') {
                throw new Error('The member ID must be a string');
            }

            if (typeof customFieldData === 'undefined') {
                customFieldData = {};
            }

            if (Array.isArray(customFieldData) || typeof customFieldData !== 'object') {
                throw new Error('The custom field data must be an object');
            }

            customFieldData[memberId] = answer;
        } else {
            customFieldData = answer;
        }

        processState.customFields[customField.id] = customFieldData;
        this.isLoading = false;

        return processState;
    }

    showErrorMessage = (message: string, questionId: string) => {
        this.errorMessages[questionId] = message;
        this.isLoading = false;

        this.toastService.presentToastWithOptions(message, ToastType.ERROR);

        document.getElementById(questionId).scrollIntoView({ block: "center" });

        setTimeout(() => {
            this.errorMessages[questionId] = '';
        }, 5000);
    }

    showErrorMessageForChoice = (message: string, questionId: string) => {
        this.choiceErrorMessages[questionId] = message;
        this.isLoading = false;

        this.toastService.presentToastWithOptions(message, ToastType.ERROR);

        document.getElementById(questionId).scrollIntoView({ block: "center" });

        setTimeout(() => {
            this.choiceErrorMessages[questionId] = '';
        }, 5000);
    }

    showErrorMessageForList = (listId: string, message: string, questionId: string) => {
        if (!this.errorMessagesForList[listId]) {
            this.errorMessagesForList[listId] = {};
        }

        this.errorMessagesForList[listId][questionId] = message;
        this.isLoading = false;

        this.toastService.presentToastWithOptions(message, ToastType.ERROR);

        document.getElementById(listId + '-' + questionId).scrollIntoView({ block: "center" });

        setTimeout(() => {
            this.errorMessagesForList[listId][questionId] = '';
        }, 5000);
    }

    validateAnswer = (questionId: string, answer: CustomFieldValueType, processState: WorkflowProcessState) => {
        const questionPiece = this.piecesData.byId[questionId];

        if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
            throw new Error('The ID should point to a piece of the question type');
        }

        if (!questionPiece.customFieldId) {
            throw new Error('The question must be attached to a valid custom field');
        }

        const customField = this.workflowData.types.customFields.byId[questionPiece.customFieldId];

        const workflow = this.workflow;

        if (customField.type === FieldType.SINGLE_SELECT && typeof answer === 'string' && isUUID(answer)) {
            answer = this.workflowData.types.customFieldOptions.byId[answer].name;
        } else if (customField.type === FieldType.MULTI_SELECT && Array.isArray(answer)) {
            answer = answer.map(optionId => isUUID(optionId) ? this.workflowData.types.customFieldOptions.byId[optionId].name : optionId);
        } else if (customField.type === FieldType.NUMBER && typeof answer !== 'undefined' && !isNaN(Number(answer))) {
            answer = Number(answer);
        } else if (customField.type === FieldType.BOOLEAN) {
            if (answer === 'Yes') {
                answer = true;
            } else if (answer === 'No') {
                answer = false;
            }
        }

        if (customField.type === FieldType.PHONE) {
            if (typeof answer !== 'undefined') {
                if (typeof answer !== 'string') {
                    throw new Error('The answer type must be string');
                }

                if (answer.split(' ').length !== 2) {
                    return 'Invalid phone number';
                }

                const phoneCountryCode = answer.split(' ')[0];
                const phoneNumber = answer.split(' ')[1];

                if (!['+91', '+1'].includes(phoneCountryCode)) {
                    return 'Invalid country code';
                }

            }
        }

        const allAnswers = {
            ...this.userInputsForList,
            ...this.userInputs,
        };

        const requiredProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
        const isRequired = questionPiece.isRequiredPiece ?
            !!getWorkflowQuestionValidationValue(this.applicationState, requiredProcessState, workflow.id,
                questionId, answer, allAnswers, questionPiece.isRequiredPiece)
            :
            false;

        if (isRequired) {
            if (customField.type === FieldType.BOOLEAN) {
                if (typeof answer === 'undefined') {
                    return 'This answer is required';
                }
            } else if (customField.type === FieldType.NUMBER) {
                if (!answer && answer !== 0) {
                    return 'This answer is required';
                }
            } else if (!answer || (Array.isArray(answer) && answer.length === 0)) {
                return 'This answer is required';
            }
        }

        const validationProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
        let errorMessage = getWorkflowQuestionValidation(this.applicationState, validationProcessState, questionPiece.innerPiece,
            workflow.id, questionId, answer, allAnswers);

        if (typeof errorMessage === 'undefined') {
            errorMessage = '';
        }

        if (typeof errorMessage !== 'string') {
            throw new Error('Invalid value for validation');
        }

        return errorMessage;
    }

    validateChoice = (questionId: string, answer: string | Array<string>, processState: WorkflowProcessState) => {
        const questionPiece = this.piecesData.byId[questionId];

        if (questionPiece.type !== PieceType.CHOOSE && questionPiece.type !== PieceType.GROUPED_CHOOSE) {
            throw new Error('The ID should point to a piece of the question type');
        }

        if (!questionPiece.variablePiece) {
            throw new Error('The choose piece must point to a valid choice list');
        }

        if (!questionPiece.choiceVariable) {
            throw new Error('The choose piece must point to a valid choice variable');
        }

        const choiceListVariableType = getPieceValueType(questionPiece.variablePiece, this.piecesData, this.variablesData);

        switch (choiceListVariableType) {
            case VariableType.PROJECTS_LIST:
            case VariableType.LEVELS_LIST:
            case VariableType.ROLES_LIST:
            case VariableType.LOCATIONS_LIST:
            case VariableType.USERS_LIST:
            case VariableType.MEMBERS_LIST:
            case VariableType.GROUPS_LIST:
            case VariableType.TEXT_LIST:
            case VariableType.WORKFLOWS_LIST:
            case VariableType.DATA_FRAGMENT:
            case VariableType.DATA_FRAGMENTS_LIST:
                break;
            default:
                throw new Error('Unknown type for list variable');
        }

        const allAnswers = {
            ...this.userInputsForList,
            ...this.userInputs,
        };

        const requiredProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
        const isRequired = questionPiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(this.applicationState,
            requiredProcessState, this.workflow.id, questionId, answer, allAnswers, questionPiece.isRequiredPiece) : false;

        if (isRequired && typeof answer === 'undefined') {
            return 'This answer is required';
        }

        const validationProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
        let errorMessage = getWorkflowQuestionValidation(this.applicationState, validationProcessState,
            questionPiece.innerPiece, this.workflow.id, questionId, answer, allAnswers);

        if (typeof errorMessage === 'undefined') {
            errorMessage = '';
        }

        if (typeof errorMessage !== 'string') {
            throw new Error('Invalid value for validation');
        }

        return errorMessage;
    }

    updateAnswers = (
        workflowId: string,
        questionIds: Array<string>,
        processState: WorkflowProcessState,
        userInputs: { [key: string]: CustomFieldValueType },
        showErrorMessage: (message: string, questionId: string) => void
    ) => {

        for (const questionId of questionIds) {
            const questionPiece = this.piecesData.byId[questionId];

            if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
                throw new Error('The ID should point to a piece of the question type');
            }

            if (!questionPiece.customFieldId) {
                throw new Error('The question must be attached to a valid custom field');
            }

            const customField = this.workflowData.types.customFields.byId[questionPiece.customFieldId];
            const updateAnswerShorthand = this.updateWorkflowWithAnswer.bind(this, workflowId, customField, questionId, processState);
            let errorMessage: CustomFieldValueType = '';

            errorMessage = this.validateAnswer(questionId, userInputs[questionPiece.customFieldId], processState);

            if (!!errorMessage) {
                if (typeof errorMessage !== 'string') {
                    throw new Error('An error message must be a string type');
                }
                showErrorMessage(errorMessage, questionId);
                return;
            }

            switch (customField.type) {
                case FieldType.BOOLEAN:
                    let booleanValue: boolean | undefined;

                    if (!!userInputs[questionPiece.customFieldId]) {
                        booleanValue = userInputs[questionPiece.customFieldId] === 'Yes';
                    }

                    processState = updateAnswerShorthand(booleanValue);
                    break;

                case FieldType.TEXT:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.NUMBER:
                    processState = updateAnswerShorthand(Number(userInputs[questionPiece.customFieldId]));
                    break;

                case FieldType.DATE:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.SINGLE_SELECT:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.MULTI_SELECT:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.LOCATION:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.PHONE:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.FILE:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                default:
                    throw new Error('Answering has not been implemented for this type of question');
            }

        }

        return processState;

    }

    updateChoices = (questionIds: Array<string>, processState: WorkflowProcessState, choiceInputs: { [key: string]: string | Array<string> }) => {

        for (const questionId of questionIds) {
            const questionPiece = this.piecesData.byId[questionId];

            if (questionPiece.type !== PieceType.CHOOSE && questionPiece.type !== PieceType.GROUPED_CHOOSE) {
                throw new Error('The ID should point to a piece of the choose type');
            }

            if (!questionPiece.choiceVariable) {
                throw new Error('The choice must be pointed to a valid variable');
            }

            let errorMessage: CustomFieldValueType = '';

            errorMessage = this.validateChoice(questionId, choiceInputs[questionId], processState);

            if (!!errorMessage) {
                if (typeof errorMessage !== 'string') {
                    throw new Error('An error message must be a string type');
                }
                this.showErrorMessage(errorMessage, questionId);
                return;
            }

            processState.variables[questionPiece.choiceVariable] = choiceInputs[questionId];

        }

        return processState;

    }

    submitChoice = (workflowId: string, questionId: string) => {
        this.isLoading = true;

        setTimeout(() => {
            const workflow = this.workflowData.byId[workflowId];
            const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
                customFields: workflow.history[workflow.historyIndex].customFields,
                lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
                executionStack: workflow.history[workflow.historyIndex].executionStack,
                forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
                variables: workflow.history[workflow.historyIndex].variables,
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                createdWorkflowId: undefined,
            }));

            const updatedProcessState = this.updateChoices([questionId], processState, this.choiceInputs);

            if (typeof updatedProcessState === 'undefined') {
                this.isLoading = false;
                return;
            }

            this.addToScreenInputs(workflow.id, {
                pieceId: questionId,
                workflowIndex: workflow.historyIndex,
                answers: {},
                groupedAnswers: {},
                choices: this.choiceInputs,
            });

            this.userInputs[questionId] = '';
            updatedProcessState.lastComputedPiece = questionId;

            startOrResumeWorkflow(this.applicationState, processState, workflowId, this.updateStatus, this.updateDueDate,
                this.updateCustomFieldValue, this.updateLocation, this.addToHistory, this.addMember, this.addGroup, this.setMembersForGroup, this.addWorkflow);

            this.scrollToTop();

            this.isLoading = false;
        }, 100);
    }

    getFileName(questionId: string, overWrittenValue?: string) {
        let key = this.workflow.id + questionId;

        if (overWrittenValue) {
            key += overWrittenValue;
        }

        if (!localStorage.getItem('filenames')) {
            localStorage.setItem('filenames', JSON.stringify({}));
        }

        const fileNames = JSON.parse(localStorage.getItem('filenames'));

        if (key in fileNames) {
            return fileNames[key];
        } else {
            undefined;
        }
    }

    async uploadFiles(questionIds: Array<string>, processState: WorkflowProcessState, overWrittenValue?: string) {
        const uploadUrl = BASE_URL + '/file-upload/';

        for (let i = 0; i < questionIds.length; i += 1) {
            const questionId = questionIds[i];
            const questionPiece = this.piecesData.byId[questionId];

            if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
                throw new Error('The ID should point to a piece of the question type');
            }

            if (!questionPiece.customFieldId) {
                throw new Error('The question must be attached to a valid custom field');
            }

            const customField = this.workflowData.types.customFields.byId[questionPiece.customFieldId];

            if (customField.type === FieldType.FILE) {
                let fileToUpload: File | undefined;
                const customFieldData = processState.customFields[customField.id];

                if (overWrittenValue) {
                    if (typeof customFieldData !== 'object' || Array.isArray(customFieldData)) {
                        throw Error('The data for the field must be an object')
                    }

                    const fileDataURI = customFieldData[overWrittenValue];

                    if (typeof fileDataURI === 'string') {
                        const startingIndex = fileDataURI.indexOf('/') + 1;
                        const endingIndex = fileDataURI.indexOf(';');
                        const extension = fileDataURI.substring(startingIndex, endingIndex);
                        const fallbackFileName = uuid.v4() + '.' + extension;
                        const trueFileName = this.getFileName(questionId, overWrittenValue)
                        const filename = trueFileName ? trueFileName : fallbackFileName
                        fileToUpload = dataURLtoFile(fileDataURI, filename);
                    }

                } else {
                    const fileDataURI = customFieldData;

                    if (typeof fileDataURI === 'string') {
                        const startingIndex = fileDataURI.indexOf('/') + 1;
                        const endingIndex = fileDataURI.indexOf(';');
                        const extension = fileDataURI.substring(startingIndex, endingIndex);
                        const fallbackFileName = uuid.v4() + '.' + extension;
                        const trueFileName = this.getFileName(questionId, overWrittenValue)
                        const filename = trueFileName ? trueFileName : fallbackFileName
                        fileToUpload = dataURLtoFile(fileDataURI, filename);
                    }
                }

                if (!!fileToUpload) {
                    var formData = new FormData();
                    formData.append('file', fileToUpload);

                    const cacheAvailable = 'caches' in self;
                    let filename = ('/file-f123f-' + fileToUpload.name.toString()).toString();

                    if (cacheAvailable) {
                        caches.open('diceflow-files').then((cache) => {
                            cache.put(filename, new Response(formData)).then(() => { }, () => {
                                this.toastService.presentToastWithOptions("No memory available to store the file locally!", ToastType.ERROR);
                            });
                        });
                    } else {
                        this.toastService.presentToastWithOptions("No memory available to store the file locally!, We have cleared the cache for you. Please try again", ToastType.ERROR);
                        caches.delete('diceflow-files');
                    }

                    if (overWrittenValue) {
                        customFieldData[overWrittenValue] = filename;
                    } else {
                        processState.customFields[customField.id] = filename;
                    }
                }
            }
        }

        return processState;
    }

    submitAnswer(workflowId: string, questionId: string) {
        this.isLoading = true;

        setTimeout(() => {
            const workflow = this.workflowData.byId[workflowId];
            const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
                customFields: workflow.history[workflow.historyIndex].customFields,
                lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
                executionStack: workflow.history[workflow.historyIndex].executionStack,
                forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
                variables: workflow.history[workflow.historyIndex].variables,
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                createdWorkflowId: undefined,
            }));

            let updatedProcessState = this.updateAnswers(workflowId, [questionId], processState, this.userInputs, this.showErrorMessage);

            if (typeof updatedProcessState === 'undefined') {
                this.isLoading = false;
                return;
            }

            this.uploadFiles([questionId], updatedProcessState).then((data) => {
                updatedProcessState = data;

                this.addToScreenInputs(workflow.id, {
                    pieceId: questionId,
                    workflowIndex: workflow.historyIndex,
                    answers: this.userInputs,
                    groupedAnswers: {},
                    choices: {},
                });

                this.userInputs[questionId] = '';

                updatedProcessState.lastComputedPiece = questionId;

                startOrResumeWorkflow(this.applicationState, processState, workflowId, this.updateStatus, this.updateDueDate,
                    this.updateCustomFieldValue, this.updateLocation, this.addToHistory, this.addMember, this.addGroup, this.setMembersForGroup,
                    this.addWorkflow);

                this.scrollToTop();

                this.isLoading = false;
            }, (err) => {
                console.log(err);
            });
        }, 100);
    }

    scrollToTop() {
        this.content.scrollToTop(1500);
    }

    submitGroup = async (workflowId: string, groupId: string, questionIds: Array<string>, choiceIds: Array<string>) => {
        this.isLoading = true;

        setTimeout(async () => {
            const workflow = this.workflowData.byId[workflowId];
            const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
                customFields: workflow.history[workflow.historyIndex].customFields,
                lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
                executionStack: workflow.history[workflow.historyIndex].executionStack,
                forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
                variables: workflow.history[workflow.historyIndex].variables,
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                createdWorkflowId: undefined,
            }));
            let updatedProcessState: WorkflowProcessState | undefined = {
                ...processState,
                variables: {
                    ...processState.variables,
                },
                customFields: {
                    ...processState.customFields,
                }
            };

            const groupPiece = this.piecesData.byId[groupId];

            if (groupPiece.type === PieceType.GROUP) {

                updatedProcessState = this.updateAnswers(workflowId, questionIds, updatedProcessState, this.userInputs, this.showErrorMessage);

                if (typeof updatedProcessState === 'undefined') {
                    this.isLoading = false;
                    return;
                }

                updatedProcessState = this.updateChoices(choiceIds, updatedProcessState, this.choiceInputs);

                if (typeof updatedProcessState === 'undefined') {
                    this.isLoading = false;
                    return;
                }

                updatedProcessState = await this.uploadFiles(questionIds, updatedProcessState);

                if (typeof updatedProcessState === 'undefined') {
                    this.isLoading = false;
                    return;
                }

            } else if (groupPiece.type === PieceType.GROUP_FOR_LIST) {
                if (!groupPiece.iterableVariable) {
                    this.isLoading = false;
                    throw new Error('This piece must have an iterable variable');
                }

                if (!groupPiece.loopVariable) {
                    this.isLoading = false;
                    throw new Error('This piece must have a loop variable');
                }

                const loopVariable = groupPiece.loopVariable;

                let iterableValue = getWorkflowPieceValue(this.applicationState, processState, workflowId, groupPiece.iterableVariable);

                if (!Array.isArray(iterableValue)) {
                    this.isLoading = false;
                    throw new Error('The iterable value must be an array');
                }

                if (Array.isArray(iterableValue)) {

                    if (iterableValue.length > 0 && Array.isArray(iterableValue[0])) {
                        // Cannot be a multidimensional array
                        this.isLoading = false;
                        throw new Error('The value cannot be a multi-dimensional array')
                    }

                    iterableValue = iterableValue as Array<string>;
                }

                for (const listItem of iterableValue) {
                    updatedProcessState.variables[loopVariable] = listItem;
                    let userInputs = this.userInputsForList[listItem];

                    if (typeof userInputs === 'undefined') {
                        this.isLoading = false;
                        userInputs = {};
                    }

                    updatedProcessState = this.updateAnswers(workflowId, questionIds, updatedProcessState,
                        userInputs, this.showErrorMessageForList.bind(this, listItem));

                    updatedProcessState = await this.uploadFiles(questionIds, updatedProcessState, listItem);

                    if (typeof updatedProcessState === 'undefined') {
                        this.isLoading = false;
                        return;
                    }
                }
            }

            if (groupPiece.type === PieceType.GROUP) {
                this.addToScreenInputs(workflow.id, {
                    pieceId: groupPiece.id,
                    workflowIndex: workflow.historyIndex,
                    answers: this.userInputs,
                    groupedAnswers: {},
                    choices: this.choiceInputs,
                });
            } else if (groupPiece.type === PieceType.GROUP_FOR_LIST) {
                this.addToScreenInputs(workflow.id, {
                    pieceId: groupPiece.id,
                    workflowIndex: workflow.historyIndex,
                    answers: {},
                    groupedAnswers: this.userInputsForList,
                    choices: {},
                });
            }

            this.userInputs = {};
            this.choiceInputs = {};
            this.userInputsForList = {};

            if (typeof updatedProcessState === 'undefined') {
                this.isLoading = false;
                return;
            }

            updatedProcessState.lastComputedPiece = groupId;

            startOrResumeWorkflow(this.applicationState, updatedProcessState, workflowId, this.updateStatus,
                this.updateDueDate, this.updateCustomFieldValue, this.updateLocation, this.addToHistory, this.addMember,
                this.addGroup, this.setMembersForGroup, this.addWorkflow);
            this.scrollToTop();

            this.isLoading = false;
        }, 100);
    }

    addWorkflowFromPiece = (pieceId: string) => {
        const piece = this.piecesData.byId[pieceId];

        if (piece.type !== PieceType.ADD_WORKFLOW) {
            throw new Error('The piece must be an add workflow');
        }

        if (!piece.workflowType) {
            throw new Error('The start workflow piece must have a type');
        }

        if (typeof piece.variable === 'undefined') {
            throw new Error('This piece must point to a variable');
        }

        const newWorkflowType = this.workflowData.types.byId[piece.workflowType];

        const newWorkflowStatuses = newWorkflowType.statuses.map(statusId => this.workflowData.types.statuses.byId[statusId]).filter(workflowStatus => !workflowStatus.isTerminal);

        if (newWorkflowStatuses.length === 0) {
            this.addWorkflowErrorMessage = 'A workflow of this type should have at least one non terminal status';
            return false;
        }

        const workflowId = this.workflow.id;

        let addWorkflowAffiliationValue: VariableValueType = '';

        if (newWorkflowType.affiliation !== 'none') {

            if (!piece.affiliationVariable) {
                this.addWorkflowErrorMessage = 'The start workflow piece must have an affiliation variable';
                return false;
            }

            const addWorkflowAffiliationVariablePiece = this.piecesData.byId[piece.affiliationVariable];

            if (addWorkflowAffiliationVariablePiece.type !== PieceType.VARIABLE) {
                this.addWorkflowErrorMessage = 'This piece must be a variable piece';
                return false;
            }

            const processState = this.getWorkflowProcessState();

            addWorkflowAffiliationValue = getWorkflowPieceValue(this.applicationState, processState, workflowId, addWorkflowAffiliationVariablePiece.id);

            if (typeof addWorkflowAffiliationValue !== 'string' || !isUUID(addWorkflowAffiliationValue)) {
                this.addWorkflowErrorMessage = 'This value must be an ID for the affiliated entity';
                return false;
            }

        }

        // Do not add the workflow if an instance for that group already exists
        if (!newWorkflowType.areMultipleInstancesAllowed && Array.isArray(newWorkflowType.workflows)) {
            if (newWorkflowType.affiliation !== 'none') {
                for (let i = 0; i < newWorkflowType.workflows.length; i += 1) {
                    const workflowOfType = this.workflowData.byId[newWorkflowType.workflows[i]];

                    if (workflowOfType && !workflowOfType.archived && workflowOfType.affiliatedEntity === addWorkflowAffiliationValue && !this.workflowData.types.statuses.byId[workflowOfType.status].isTerminal) {
                        this.addWorkflowErrorMessage = 'A workflow with this affiliation already exists';
                        return false;
                    }
                }
            } else {
                for (let i = 0; i < newWorkflowType.workflows.length; i += 1) {
                    const workflowOfType = this.workflowData.byId[newWorkflowType.workflows[i]];
                    const assignedUserId = this.workflowData.byId[workflowId].user;

                    if (workflowOfType && !workflowOfType.archived && workflowOfType.user === assignedUserId && !this.workflowData.types.statuses.byId[workflowOfType.status].isTerminal) {
                        this.addWorkflowErrorMessage = 'A workflow with this affiliation already exists';
                        return false;
                    }
                }
            }
        }

        return true;
    }

    ngOnDestroy() {
        if (this.applicationStateSubscription) {
            this.applicationStateSubscription.unsubscribe();
        }

        if (this.routineSubscription) {
            this.routineSubscription.unsubscribe();
        }
    }

    getFormattedDate(date: any) {
        return moment(date).format('D MMM YYYY');
    }

    getFormattedTime(date: any) {
        return moment(date).format('D MMM YYYY, hh:mm:ss A');
    }

    getCustomFieldOptionDetails(id: any) {
        try {
            return translatePhrase(this.applicationState.workflows.types.customFieldOptions.byId[id].name);
        } catch (err) {
            return '-';
        }
    }

    isObject(obj: any) {
        return obj != null && obj.constructor.name === "Object"
    }

    getCustomfieldData(id: string) {
        return this.applicationState.workflows.types.customFields.byId[id];
    }

    getObjectKeys(object: any) {
        return Object.keys(object);
    }

    getWorkflowStatus(statusId: string) {
        return this.applicationState.workflows.types.statuses.byId[statusId] ? translatePhrase(this.applicationState.workflows.types.statuses.byId[statusId].name) : '-';
    }

    ngOnInit() {
        this.showLoader = true;
        setTimeout(() => {
            this.renderWorkflow();
            this.showLoader = false;
        }, 200);
    }

    getShowMarkupForRichText(value: string) {
        let stringifiedHtml: string;
        try {
            stringifiedHtml = draftToHtml(JSON.parse(value));
        } catch (e) {
            stringifiedHtml = '<p>Incorrect HTML</p>';
        }

        return stringifiedHtml;
    }


    getShowMarkupForLink(link: string) {
        if (!isWebUri(link)) {
            return undefined;
        }

        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
        const audioExtensions = ['.mp3'];
        const videoExtensions = ['.mp4'];
        const downloadableExtensions = ['.pdf', '.css'];

        for (const extension of imageExtensions) {
            if (link.endsWith(extension)) {
                return '<a target="_blank" href="' + link + '"><img src="' + link + '" style="width: 100%; display: block;" alt="Image to show"></a>';
            }
        }

        for (const extension of audioExtensions) {
            if (link.endsWith(extension)) {
                return '<audio controls><source src="' + link + '"></audio>';
            }
        }

        for (const extension of videoExtensions) {
            if (link.endsWith(extension)) {
                return '<video controls  style="width: 100%; display: block;"><source src="' + link + '"></video>';
            }
        }

        if (
            link.startsWith('https://youtube.com') ||
            link.startsWith('https://www.youtube.com')
        ) {
            try {
                const youtubeUrl = new URL(link);

                for (const extension of imageExtensions) {
                    if (link.endsWith(extension)) {
                        return '<a target="_blank" href="' + link + '"><img src="' + link + '" style="width: 100%; display: block;" alt="Image to show"></a>';
                    }
                }

                for (const extension of audioExtensions) {
                    if (link.endsWith(extension)) {
                        return '<audio controls><source src="' + link + '"></audio>';
                    }
                }

                for (const extension of videoExtensions) {
                    if (link.endsWith(extension)) {
                        return '<video controls  style="width: 100%; display: block;"><source src="' + link + '"></video>';
                    }
                }

                if (
                    link.startsWith('https://youtube.com') ||
                    link.startsWith('https://www.youtube.com')
                ) {
                    try {
                        const youtubeUrl = new URL(link);

                        let embedLink = '';

                        if (link.includes('embed')) {
                            embedLink = link;
                        } else if (youtubeUrl.searchParams.get('v')) {
                            embedLink = 'https://www.youtube.com/embed/' + youtubeUrl.searchParams.get('v');
                        }

                        return `<section style="margin: 20px auto;">
                                <iframe 
                                    width="560" 
                                    height="315" 
                                    style="width: 100%;"
                                    src="${embedLink}"
                                    title="YouTube video player" 
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen>
                
                                </iframe>
                            </section>`
                    } catch {
                        return '<div>Could not load YouTube video</div>';
                    }
                }

                for (const extension of downloadableExtensions) {
                    if (link.endsWith(extension)) {
                        return '<a download href="' + link + '" target="_blank">Download</a>';
                    }
                }

                return '<a href="' + link + '" target="_blank">Link</a>';
            } catch (e) {
                console.error(e);
            }
        }
    }


    getFragmentDataToTable(data) {
        if (data.length > 0) {
            let tableHeadings = [],
                tableEntries = [];

            tableHeadings = [{
                name: translatePhrase('Sl. no'),
            }, {
                name: data[0],
            }];

            for (let i = 1; i < data.length; i += 1) {
                let entries = [];
                entries.push(i);
                entries.push(data[i]);
                tableEntries.push({
                    entries: entries
                });
            }

            return {
                tableHeadings,
                tableEntries
            }
        } else {
            return {
                tableHeadings: [],
                tableEntries: []
            }
        }
    }

    updateWorkflow(applicationState: ApplicationState, workflowId: string, showEnd: any, isReport?: boolean) {
        this.applicationState = applicationState;
        this.membersData = applicationState.members;
        this.groupsData = applicationState.groups;
        this.piecesData = applicationState.flowchart.pieces;
        this.variablesData = applicationState.flowchart.variables;
        this.workflowData = applicationState.workflows;
        this.workflow = this.workflowData.byId[workflowId];
        this.workflowType = this.workflowData.types.byId[this.workflow.type];
        this.workflowStatus = this.workflowData.types.statuses.byId[this.workflow.status];

        this.workflow_details = this.applicationState.workflows.byId[workflowId];

        const executingUser = applicationState.users.byId[this.workflow_details.user];

        this.isBetaTester = executingUser && executingUser.isBetaTester;

        let workflowProcessState = this.workflow.history[this.workflow.historyIndex],
            normalCustomFields: Array<WorkflowTypeCustomField> = [],
            perMemberCustomFields: Array<WorkflowTypeCustomField> = [];

        this.normalEntries = [];
        this.perMemberEntries = [];

        if (this.workflowStatus.isTerminal || showEnd) {
            if (isReport) {
                this.display = 'report';
            } else {
                this.display = 'end';
            }

            const allCustomFields = this.workflowType.customFields.map(customFieldId => this.workflowData.types.customFields.byId[customFieldId]);

            let userEntries = [];

            for (let i = 0; i < this.workflow.history.length; i += 1) {
                let normalEntries = [],
                    perMemberEntries = [];

                workflowProcessState = this.workflow.history[i];

                const lastUserEntry = userEntries.length > 0 ? userEntries[userEntries.length - 1] : undefined;

                let transferredUserId = '';

                if (lastUserEntry) {
                    if (lastUserEntry.userId !== workflowProcessState.executingUser) {
                        transferredUserId = lastUserEntry.userId;
                    }
                }

                if (this.workflowType.affiliation === 'group') {
                    normalCustomFields = allCustomFields.filter(customField => customField.affiliation === 'group');
                    perMemberCustomFields = allCustomFields.filter(customField => customField.affiliation === 'member');
                } else {
                    normalCustomFields = allCustomFields.slice(0);
                }

                if (lastUserEntry) {
                    if (lastUserEntry.userId !== workflowProcessState.executingUser) {
                        transferredUserId = lastUserEntry.userId;
                    }
                }

                if (this.workflowType.affiliation === 'group') {
                    normalCustomFields = allCustomFields.filter(customField => customField.affiliation === 'group');
                    perMemberCustomFields = allCustomFields.filter(customField => customField.affiliation === 'member');
                } else {
                    normalCustomFields = allCustomFields.slice(0);
                }

                for (const customField of normalCustomFields) {
                    let customFieldValue = workflowProcessState.customFields[customField.id];

                    if (!Array.isArray(customFieldValue) && customFieldValue !== null && typeof customFieldValue === 'object') {
                        throw new Error('There should not be any custom fields for individual members in normal entries');
                    }

                    let readableCustomFieldValue = getReadableDataForCustomField(customFieldValue as CustomFieldValueType, customField, this.workflow.id, 'workflow');

                    if (customField.type === FieldType.SINGLE_SELECT) {
                        readableCustomFieldValue = translatePhrase(readableCustomFieldValue);
                    } else if (customField.type === FieldType.MULTI_SELECT) {
                        readableCustomFieldValue = readableCustomFieldValue.split(',').map(value => translatePhrase(value.trim())).join(', ');
                    }

                    let hasValueChanged = true;
                    const customFieldName = translatePhrase(customField.name);

                    let lastCustomFieldEntry: Option | undefined;

                    for (const userEntry of userEntries) {
                        if (!lastCustomFieldEntry) {
                            lastCustomFieldEntry = userEntry.normalEntries.find(normalEntry => normalEntry.name === customFieldName);
                        }
                    }

                    const transformedCustomFieldValue = isWebUri(readableCustomFieldValue) ? this.getShowMarkupForLink(readableCustomFieldValue) : readableCustomFieldValue;

                    if (lastCustomFieldEntry && lastCustomFieldEntry.value === transformedCustomFieldValue) {
                        hasValueChanged = false;
                    }

                    if (hasValueChanged && readableCustomFieldValue.trim() !== '-') {
                        normalEntries.push({
                            name: translatePhrase(customField.name),
                            value: transformedCustomFieldValue,
                        });
                    }

                }

                if (this.workflowType.affiliation === 'group') {
                    const group = this.groupsData.byId[this.workflow.affiliatedEntity];

                    for (const memberId of group.members) {
                        const memberName = this.getMemberName(memberId);
                        const memberSubTitle = this.getMemberSubtitle(memberId);

                        let tableValues: Array<Option> = [];

                        for (const customField of perMemberCustomFields) {
                            let customFieldValue = workflowProcessState.customFields[customField.id] as CustomFieldDataForIndividualMembers;

                            if (typeof customFieldValue === 'undefined') {
                                customFieldValue = {};
                            }

                            if (typeof customFieldValue !== 'object') {
                                throw new Error('There should only be custom fields for individual members in per-member entries');
                            }

                            let hasValueChanged = true;
                            const customFieldName = translatePhrase(customField.name);

                            let lastCustomFieldEntry: Option | undefined;

                            for (const userEntry of userEntries) {
                                if (!lastCustomFieldEntry) {
                                    const memberEntry = userEntry.perMemberEntries.find(memberEntry => memberEntry.name === memberName && memberEntry.subTitle === memberSubTitle);
                                    if (!!memberEntry) {
                                        lastCustomFieldEntry = memberEntry.value.find(normalEntry => normalEntry.name === customFieldName);
                                    }
                                }
                            }

                            const readableCustomFieldValue = getReadableDataForCustomField(customFieldValue[memberId], customField, this.workflow.id, 'workflow');

                            if (lastCustomFieldEntry && lastCustomFieldEntry.value === readableCustomFieldValue) {
                                hasValueChanged = false;
                            }

                            if (hasValueChanged && readableCustomFieldValue !== '-') {
                                tableValues.push({
                                    name: customFieldName,
                                    value: readableCustomFieldValue,
                                })
                            }
                        }

                        if (tableValues.length > 0) {
                            perMemberEntries.push({
                                name: memberName,
                                subTitle: memberSubTitle,
                                value: tableValues,
                            });
                        }
                    }
                }

                userEntries.push({
                    userId: workflowProcessState.executingUser,
                    time: workflowProcessState.executionTime,
                    transferedUser: transferredUserId,
                    normalEntries,
                    perMemberEntries
                });

            }

            this.userEntries = userEntries;

            const endPieceId = workflowProcessState.lastComputedPiece;
            if (typeof endPieceId !== 'undefined') {
                const endPiece = this.applicationState.flowchart.pieces.byId[endPieceId];
                try {
                    switch (endPiece.type) {
                        case PieceType.END:
                            if (endPiece.message) {
                                const message = endPiece.message;
                                if (isUUID(message)) {
                                    const messageVariableValue = getWorkflowPieceValue(this.applicationState, workflowProcessState, workflowId, message);

                                    if (typeof messageVariableValue === 'string') {
                                        this.endWorkflowText = messageVariableValue;
                                    }
                                } else {
                                    this.endWorkflowText = message;
                                }
                            }
                            if (endPiece.workflow) {
                                const nextWorkflowId = workflowProcessState.variables[endPiece.workflow];

                                if (typeof nextWorkflowId !== 'string') {
                                    throw new Error('The next workflow ID must be a string')
                                }
                                this.nextWorkflowId = nextWorkflowId;
                            }

                            break;
                        default: break;
                    }
                } catch {

                }
            }

            setTimeout(() => {
                try {
                    document.querySelector('#timeline-' + (this.workflow.historyIndex - 1)).scrollIntoView({ block: "center", behavior: 'smooth' });
                } catch (err) {
                    console.log(err);
                }
            }, 500);

            return;
        }

        if (!!workflowProcessState.displayingTransferPieceId) {
            this.transferId = workflowProcessState.displayingTransferPieceId;
            this.display = 'transfer';
            return;
        }

        if (!!workflowProcessState.displayingQuestionPieceId) {
            this.questionId = workflowProcessState.displayingQuestionPieceId;
            const questionPiece = this.piecesData.byId[workflowProcessState.displayingQuestionPieceId];

            if (questionPiece.type === PieceType.QUESTION) {
                this.display = 'question';
                this.customFieldIdForQuestion = questionPiece.customFieldId;
            } else {
                this.display = 'choose';
            }

            return;
        }

        if (workflowProcessState.displayingAddWorkflowPieceId) {
            this.display = 'add-workflow';
            this.isShowingAddWorkflowPiece = true;
            return;
        }

        if (!!workflowProcessState.createdWorkflowId) {
            this.display = 'switch';
            this.switchToNewWorkflow(workflowProcessState.createdWorkflowId);
            return;
        }

        if (!!workflowProcessState.displayingContinuePieceId) {
            this.display = 'continue';
            this.continuePieceId = workflowProcessState.displayingContinuePieceId;
        }

        if (workflowProcessState.displayingShowPieceId) {
            const showPiece = this.applicationState.flowchart.pieces.byId[workflowProcessState.displayingShowPieceId];

            if (showPiece.type !== PieceType.SHOW && showPiece.type !== PieceType.GROUPED_SHOW) {
                throw new Error('This piece must be a show piece');
            }

            if (showPiece.isRichText) {
                const richTextMarkup = this.getShowMarkupForRichText(showPiece.variableToShow || '');
                this.richText = richTextMarkup;
                // TODO: Bind this to inner HTML
            } else {
                const showData = this.getShowDataFromPieceId(workflowProcessState.displayingShowPieceId, workflowProcessState);

                let displayPiece: any = this.applicationState.flowchart.pieces.byId[workflowProcessState.displayingShowPieceId];
                let piece: any = this.applicationState.flowchart.pieces.byId[displayPiece.variableToShow];
                let fieldType: string = '';
                if (piece && piece.customField) {
                    fieldType = this.applicationState.workflows.types.customFields.byId[piece.customField].type;
                    this.showDataType = fieldType;
                }

                if (typeof showData === 'string') {
                    if ((showData.split(' ')[0] && showData.split(' ')[1]) && (showData.split(' ')[0].includes('+') &&
                        showData.split(' ')[1].length >= 10 && showData.split(' ')[1].length <= 15)) {
                        this.showDataType = 'PHONE';
                    }

                    if ((showData.split(' ')[0] && isValidCoordinates.latitude(Number(showData.split(' ')[0]))) &&
                        (showData.split(' ')[1] && isValidCoordinates.longitude(Number(showData.split(' ')[1])))) {
                        this.showDataType = 'LOCATION';
                    }

                    if (isWebUri(showData)) {
                        // A link that needs to be shown
                        this.richText = this.getShowMarkupForLink(showData);
                    } else {
                        // Normal string
                        this.showDataText = showData;
                        this.showDataWidgetProps = undefined;
                    }
                } else if (Array.isArray(showData)) {
                    this.showDataFragmentList = {
                        tableHeadings: this.getFragmentDataToTable(showData).tableHeadings,
                        tableEntries: this.getFragmentDataToTable(showData).tableEntries
                    }
                } else {
                    this.showDataText = undefined;
                    this.showDataWidgetProps = showData;
                    this.showDataFragmentList = {};
                }
            }

            this.display = 'show';
            return;
        }

        if (workflowProcessState.displayingGroupPieceId) {
            const groupPiece = this.piecesData.byId[workflowProcessState.displayingGroupPieceId];
            this.groupId = groupPiece.id;
            const { questionIds, chooseIds } = this.getAllQuestionsInGroup(workflowProcessState.displayingGroupPieceId);
            this.questionIdsInGroup = questionIds;
            this.chooseIdsInGroup = chooseIds;
            if (groupPiece.type === PieceType.GROUP) {
                this.groupLoopVariable = undefined;
                this.groupIterableValue = undefined;
                const groupHeadingWorkflowProcessState = this.getWorkflowProcessState();
                this.groupHeading = groupPiece.heading && isUUID(groupPiece.heading) ? getWorkflowPieceValue(this.applicationState, groupHeadingWorkflowProcessState, workflowId, groupPiece.heading) as string : groupPiece.heading;
            } else if (groupPiece.type === PieceType.GROUP_FOR_LIST) {

                if (!groupPiece.iterableVariable) {
                    throw new Error('This piece must have an iterable variable');
                }

                if (!groupPiece.loopVariable) {
                    throw new Error('This piece must have a loop variable');
                }

                this.groupLoopVariable = groupPiece.loopVariable;

                let iterableValue = getWorkflowPieceValue(this.applicationState, workflowProcessState, workflowId,
                    groupPiece.iterableVariable);

                if (!Array.isArray(iterableValue)) {
                    throw new Error('The iterable value must be an array');
                }

                if (Array.isArray(iterableValue)) {

                    if (iterableValue.length > 0 && Array.isArray(iterableValue[0])) {
                        // Cannot be a multidimensional array
                        throw new Error('The value cannot be a multi-dimensional array')
                    }

                    iterableValue = iterableValue as Array<string>;
                }

                this.groupHeading = groupPiece.heading ? groupPiece.heading : '';
                this.groupIterableValue = iterableValue;
            }

            this.display = 'group';
            return;
        }
    }

    renderWorkflow = (newWorkflowId?: string) => {
        let workflowId: string;

        if (!newWorkflowId) {
            workflowId = this.navParams.get('workflow_id');
        } else {
            workflowId = newWorkflowId;
        }

        const showEnd = this.navParams.get('isEnd');
        const workflowSource: Observable<IWorkflow> = this.ngRedux.select(['workflows', 'byId', workflowId]);

        if (this.routineSubscription) {
            this.routineSubscription.unsubscribe();
        }

        this.routineSubscription = workflowSource.subscribe(workflow => {
            const applicationState = store.getState();
            this.updateWorkflow(applicationState, workflowId, showEnd);
        });

        this.display === 'start' || !this.display ? this.startOrResumeWorkflow() : null;
    }

    showWorkflowReport() {
        let workflowId = this.navParams.get('workflow_id');
        const workflowSource: Observable<IWorkflow> = this.ngRedux.select(['workflows', 'byId', workflowId]);

        if (this.routineSubscription) {
            this.routineSubscription.unsubscribe();
        }

        this.routineSubscription = workflowSource.subscribe(workflow => {
            const applicationState = store.getState();
            this.updateWorkflow(applicationState, workflowId, true, true);
        });
    }

    back() {
        if (this.display === 'report') {
            this.renderWorkflow();
        } else {
            this.modalController.dismiss();
        }
    }

    getUserName(userId: string) {
        try {
            const user = this.applicationState.users.byId[userId];
            let userName: VariableValueType;

            const nameField = this.applicationState.users.customFields.byId[this.applicationState.users.nameFieldId];

            if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
                const processState: DefaultFlowchartProcessState = {
                    displayingContinuePieceId: undefined,
                    displayingAddWorkflowPieceId: undefined,
                    customFields: { ...user.customFields },
                    variables: {
                        [nameField.seedEntityVariable]: user.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                };

                userName = getUserComputedFieldValue(this.applicationState, processState, nameField.startPiece.piece, user.id, nameField);
            } else {
                userName = user.customFields[this.applicationState.users.nameFieldId];
            }

            if (Array.isArray(userName)) {

                if (userName.length > 0 && Array.isArray(userName[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                userName = userName as Array<string>;
            }

            userName = getReadableDataForCustomField(userName, this.applicationState.users.customFields.byId[this.applicationState.users.nameFieldId], user.id, 'user');

            return userName;
        } catch {
            return '-';
        }
    }

    getGroupName(groupId: string) {
        const group = this.applicationState.groups.byId[groupId];
        const groupType = this.applicationState.groups.types.byId[group.type];
        let groupName: CustomFieldValueType;
        const nameField = this.applicationState.groups.types.customFields.byId[groupType.nameFieldId];

        groupName = group.customFields[groupType.nameFieldId];

        groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');

        return groupName;
    }

    getMemberName(memberId: string) {
        const member = this.membersData.byId[memberId];
        const memberType = this.membersData.types.byId[member.type];
        let memberName: VariableValueType;

        const nameField = this.membersData.types.customFields.byId[memberType.nameFieldId];

        if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
            const processState: DefaultFlowchartProcessState = {
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                customFields: { ...member.customFields },
                variables: {
                    [nameField.seedEntityVariable]: member.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
            };

            memberName = getMemberComputedFieldValue(this.applicationState, processState, nameField.startPiece.piece, member.id, nameField);
        } else {
            memberName = member.customFields[memberType.nameFieldId];
        }

        if (Array.isArray(memberName)) {

            if (memberName.length > 0 && Array.isArray(memberName[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            memberName = memberName as Array<string>;
        }

        memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

        return memberName;
    }

    getMemberSubtitle(memberId: string) {
        try {
            const member = this.membersData.byId[memberId];
            const memberType = this.membersData.types.byId[member.type];
            let memberSubtitle: VariableValueType;

            const subTitleField = this.membersData.types.customFields.byId[memberType.subTitleFieldId];

            if (subTitleField.isComputed && typeof subTitleField.startPiece !== 'undefined') {
                const processState: DefaultFlowchartProcessState = {
                    displayingContinuePieceId: undefined,
                    displayingAddWorkflowPieceId: undefined,
                    customFields: { ...member.customFields },
                    variables: {
                        [subTitleField.seedEntityVariable]: member.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                };

                memberSubtitle = getMemberComputedFieldValue(this.applicationState, processState, subTitleField.startPiece.piece, member.id, subTitleField);
            } else {
                memberSubtitle = member.customFields[memberType.nameFieldId];
            }

            if (Array.isArray(memberSubtitle)) {

                if (memberSubtitle.length > 0 && Array.isArray(memberSubtitle[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                memberSubtitle = memberSubtitle as Array<string>;
            }

            memberSubtitle = getReadableDataForCustomField(memberSubtitle, subTitleField, member.id, 'member');

            return memberSubtitle;
        } catch {
            return '-';
        }
    }

    getAffiliatedEntityName(affiliatedEntity: string) {
        let member = this.membersData.allEntries.find(member => affiliatedEntity === member);

        let group = this.applicationState.groups.allEntries.find(group => affiliatedEntity === group);

        return member ? this.getMemberName(member) : group ? this.getGroupName(group) : '-';
    }

    getTypeName(type_id: string) {
        return this.applicationState.workflows.types.byId[type_id].name;
    }

}

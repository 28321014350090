import { ReOrderedListsForEntity } from '../../../helpers/common-types';
import { NormalizedModel, Synchronizable } from '../../normalized-model';

export const SELECT_DATA_FRAGMENT = 'SELECT_DATA_FRAGMENT';
export const UN_SELECT_DATA_FRAGMENT = 'UN_SELECT_DATA_FRAGMENT';
export const RE_ORDER_DATA_FRAGMENTS = 'RE_ORDER_DATA_FRAGMENTS';
export const ADD_DATA_FRAGMENT_REQUEST = 'ADD_DATA_FRAGMENT_REQUEST';
export const ADD_DATA_FRAGMENT = 'ADD_DATA_FRAGMENT';
export const DELETE_DATA_FRAGMENT = 'DELETE_DATA_FRAGMENT';
export const UPDATE_DATA_FRAGMENT = 'UPDATE_DATA_FRAGMENT';

export const UPDATE_DATA_FRAGMENTS_DATA = 'UPDATE_DATA_FRAGMENTS_DATA';
export const SYNCHRONIZE_DATA_FRAGMENTS_DATA = 'SYNCHRONIZE_DATA_FRAGMENTS_DATA';
export const CLEAR_DATA_FRAGMENTS_DELTA = 'CLEAR_DATA_FRAGMENTS_DELTA';


export interface INewDataFragmentData {
    name: string;
}

export interface IUpdateableDataFragmentData extends INewDataFragmentData {
    id: string;
}

export interface IDataFragment extends IUpdateableDataFragmentData, Synchronizable {
    children: Array<string>,
    parent?: string,
}

export interface DataFragmentState extends NormalizedModel<IDataFragment> {
    byStaticDataHolder: {
        [key: string]: Array<string>, // Array of top level dataFragments for each project
    },
    selected: Array<string>,
    reOrderedTopLevelDataFragments: ReOrderedListsForEntity,
    reOrderedChildDataFragments: ReOrderedListsForEntity,
}

export interface SelectDataFragmentAction {
    type: typeof SELECT_DATA_FRAGMENT,
    id: string,
    index: number,
}

export interface UnSelectDataFragmentAction {
    type: typeof UN_SELECT_DATA_FRAGMENT,
    index: number,
}

export interface ReOrderDataFragmentsAction {
    type: typeof RE_ORDER_DATA_FRAGMENTS,
    sourceIndex: number,
    destinationIndex: number,
    parentId: string,
}

export interface AddDataFragmentRequestAction {
    type: typeof ADD_DATA_FRAGMENT_REQUEST,
    payload: INewDataFragmentData,
    parentId: string,
}

export interface AddDataFragmentAction {
    type: typeof ADD_DATA_FRAGMENT,
    payload: IDataFragment,
    parentId: string,
    currentTime: string,
}

export interface DeleteDataFragmentAction {
    type: typeof DELETE_DATA_FRAGMENT,
    id: string,
    parentId: string,
    currentTime: string,
}

export interface UpdateDataFragmentAction {
    type: typeof UPDATE_DATA_FRAGMENT,
    payload: IUpdateableDataFragmentData,
    currentTime: string,
}

export interface UpdateDataFragmentsData {
    type: typeof UPDATE_DATA_FRAGMENTS_DATA,
    data: Array<IDataFragment>,
}

export interface SynchronizeDataFragmentsData {
    type: typeof SYNCHRONIZE_DATA_FRAGMENTS_DATA,
    data: Array<IDataFragment>,
}

export interface ClearDataFragmentsDelta {
    type: typeof CLEAR_DATA_FRAGMENTS_DELTA,
}

export type DataFragmentActionTypes = SelectDataFragmentAction | UnSelectDataFragmentAction | ReOrderDataFragmentsAction | AddDataFragmentRequestAction | AddDataFragmentAction | DeleteDataFragmentAction | UpdateDataFragmentAction | UpdateDataFragmentsData | SynchronizeDataFragmentsData | ClearDataFragmentsDelta;
import { ReOrderedListsForEntity } from '../../../helpers/common-types';
import { NormalizedModel, Synchronizable } from '../../normalized-model';

export const SELECT_PROJECT = 'SELECT_PROJECT';
export const UN_SELECT_PROJECT = 'UN_SELECT_PROJECT';
export const RE_ORDER_PROJECTS = 'RE_ORDER_PROJECTS';
export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';
export const ADD_PROJECT = 'ADD_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';

export const INSTANTIATE_LEVELS_FOR_PROJECT = 'INSTANTIATE_LEVELS_FOR_PROJECT';

export const ADD_LEVEL_TO_PROJECT = 'ADD_LEVEL_TO_PROJECT';
export const REMOVE_LEVEL_FROM_PROJECT = 'REMOVE_LEVEL_FROM_PROJECT';

export const ADD_LOCATION_TO_PROJECT = 'ADD_LOCATION_TO_PROJECT';
export const REMOVE_LOCATION_FROM_PROJECT = 'REMOVE_LOCATION_FROM_PROJECT';

export const UPDATE_PROJECTS_DATA = 'UPDATE_PROJECTS_DATA';
export const SYNCHRONIZE_PROJECTS_DATA = 'SYNCHRONIZE_PROJECTS_DATA';
export const CLEAR_PROJECTS_DELTA = 'CLEAR_PROJECTS_DELTA';


export interface INewProjectData {
    name: string;
}

export interface IUpdateableProjectData extends INewProjectData {
    id: string,
}

export interface IProject extends IUpdateableProjectData, Synchronizable {
    children: Array<string>,
    locations: Array<string>,
    archived?: boolean,

    'add-member': string|undefined,
    'edit-member': string|undefined,
    'add-group': string|undefined,
    'edit-group': string|undefined,
}

export interface ProjectState extends NormalizedModel<IProject> {
    selected: string|undefined,

    areProjectsReordered: boolean,
    reOrderedLevels: ReOrderedListsForEntity,
}

export interface SelectProjectAction {
    type: typeof SELECT_PROJECT,
    id: string,
}

export interface UnSelectProjectAction {
    type: typeof UN_SELECT_PROJECT,
}

export interface ReOrderProjectsAction {
    type: typeof RE_ORDER_PROJECTS,
    sourceIndex: number,
    destinationIndex: number,
}

export interface AddProjectRequestAction {
    type: typeof ADD_PROJECT_REQUEST,
    payload: INewProjectData,
}

export interface AddProjectAction {
    type: typeof ADD_PROJECT,
    payload: IProject,
    currentTime: string,
}

export interface DeleteProjectAction {
    type: typeof DELETE_PROJECT,
    id: string,
    currentTime: string,
}

export interface UpdateProjectAction {
    type: typeof UPDATE_PROJECT,
    payload: IUpdateableProjectData,
    currentTime: string,
}

export interface AddLevelToProjectAction {
    type: typeof ADD_LEVEL_TO_PROJECT,
    projectId: string,
    levelId: string,
}

export interface RemoveLevelFromProjectAction {
    type: typeof REMOVE_LEVEL_FROM_PROJECT,
    projectId: string,
    levelId: string,
}

export interface AddLocationToProjectAction {
    type: typeof ADD_LOCATION_TO_PROJECT,
    projectId: string,
    locationId: string,
}

export interface RemoveLocationFromProjectAction {
    type: typeof REMOVE_LOCATION_FROM_PROJECT,
    projectId: string,
    locationId: string,
}

export interface InstantiateLevelsForProjectAction {
    type: typeof INSTANTIATE_LEVELS_FOR_PROJECT,
    projectId: string,
    levelIds: Array<string>,
}

export interface UpdateProjectsData {
    type: typeof UPDATE_PROJECTS_DATA,
    data: Array<IProject>,
}

export interface SynchronizeProjectsData {
    type: typeof SYNCHRONIZE_PROJECTS_DATA,
    data: Array<IProject>,
    reOrder: Array<string>,
}

export interface ClearProjectsDelta {
    type: typeof CLEAR_PROJECTS_DELTA,
}

export type ProjectActionTypes = SelectProjectAction | UnSelectProjectAction | ReOrderProjectsAction | AddProjectRequestAction | AddProjectAction | DeleteProjectAction | UpdateProjectAction | AddLevelToProjectAction | RemoveLevelFromProjectAction | AddLocationToProjectAction | RemoveLocationFromProjectAction | InstantiateLevelsForProjectAction | UpdateProjectsData | SynchronizeProjectsData | ClearProjectsDelta;
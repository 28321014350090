import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { WidgetComponent } from '../widgets/widget/widget.component';
import { AggregatedWidgetComponent } from '../widgets/aggregated-widget/aggregated-widget.component';
import { MessageWidgetComponent } from '../widgets/message-widget/message-widget.component';
import { ShowWidgetComponent } from '../show-widget/show-widget.component';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { BarChartComponent } from '../bar-chart/bar-chart.component';
import { DonutChartComponent } from '../donut-chart/donut-chart.component';
import { TableChartComponent } from '../table-chart/table-chart.component';
import { RichTextComponent } from '../rich-text/rich-text.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';


@NgModule({
	declarations: [
		WidgetComponent,
		MessageWidgetComponent,
		AggregatedWidgetComponent,
		ShowWidgetComponent,
		LineChartComponent,
		BarChartComponent,
		DonutChartComponent,
		TableChartComponent,
		RichTextComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
        RoundProgressModule
	],
	exports: [
		WidgetComponent,
		MessageWidgetComponent,
		AggregatedWidgetComponent,
		ShowWidgetComponent,
		LineChartComponent,
		BarChartComponent,
		DonutChartComponent,
		TableChartComponent,
		RichTextComponent
	]
})
export class SharedModule { }

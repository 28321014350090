import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as moment from 'moment';

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.page.html',
	styleUrls: ['./calendar.page.scss'],
})
export class CalendarPage implements OnInit {
	currentDate: number;
	currentMonth: string;
	currentYear: number;
	selectedDate: number;
    selectedMonth: string;
    selectedYear: number;
    dayMap: Array<string> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    monthMap: Array<string> = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    preceedingDays: Array<number> = [];
    succeedingDays: Array<number> = [];
    selectedMonthDays: Array<number> = [];

	constructor(
		private modalController: ModalController,
		private navParams: NavParams
	) { }

	ngOnInit() {
		this.resetCalendar();
	}

	selectToday() {
		this.selectedMonth = this.monthMap[(new Date()).getMonth()];
        this.selectedYear = (new Date()).getFullYear();
		this.selectedDate = new Date().getDate();

		this.currentYear = (new Date()).getFullYear();
		this.currentDate = new Date().getDate();
		this.currentMonth = this.monthMap[(new Date()).getMonth()];

        this.renderCalendar();
	}

	resetCalendar() {
		let preSelectedDate = this.navParams.get('preSelectedDate');

		this.selectedMonth = this.monthMap[moment(preSelectedDate).month()];
		this.selectedYear = moment(preSelectedDate).year();
		this.selectedDate = moment(preSelectedDate).date();

		this.currentYear = (new Date()).getFullYear();
		this.currentDate = new Date().getDate();
		this.currentMonth = this.monthMap[(new Date()).getMonth()];

		this.renderCalendar();
	}

	back() {
		this.modalController.dismiss();
	}

	getFormattedDate(date: any, format: string) {
		return moment(date.toString()).format(format);
	}

	getMonthNumberFromMap(month: string) {
		let index = this.monthMap.findIndex((mapmonth => mapmonth === month));
		return (index + 1).toString().padStart(2, '0');
	}

	renderCalendar() {
        // Generate dates for the calendar
        let i = 1,
            no_of_preceeding_days,
            no_of_days_in_selected_month,
            no_of_succeeding_days,
            last_date_of_previous_month,
            first_date_of_selected_month,
            last_date_of_selected_month;

        first_date_of_selected_month = new Date(this.selectedYear, this.monthMap.indexOf(this.selectedMonth), 1);

        // Add one month, and subtract one day to the selected month and year
        last_date_of_selected_month = new Date(this.selectedYear, this.monthMap.indexOf(this.selectedMonth) + 1, 0);
        last_date_of_previous_month = new Date(this.selectedYear, this.monthMap.indexOf(this.selectedMonth), 0);

        no_of_preceeding_days = first_date_of_selected_month.getDay();
        no_of_days_in_selected_month = last_date_of_selected_month.getDate();
        no_of_succeeding_days = 6 - last_date_of_selected_month.getDay();

        this.preceedingDays = [];
        this.selectedMonthDays = [];
        this.succeedingDays = [];

        for (i = no_of_preceeding_days - 1; i >= 0; i -= 1) {
            this.preceedingDays.push(last_date_of_previous_month.getDate() - i);
        }

        for (i = 1; i <= no_of_days_in_selected_month; i += 1) {
            this.selectedMonthDays.push(i);
        }

        for (i = 1; i <= no_of_succeeding_days; i += 1) {
            this.succeedingDays.push(i);
        }
    }

	selectNextMonth() {
        let next_month_index = (this.monthMap.indexOf(this.selectedMonth) + 1) % this.monthMap.length;
        this.selectedMonth = this.monthMap[next_month_index];
        if (next_month_index == 0) {
            this.selectedYear += 1;
        }

        this.renderCalendar();
    }

	selectPreviousMonth() {
        let previous_month_index = (this.monthMap.indexOf(this.selectedMonth) + (this.monthMap.length - 1)) % this.monthMap.length;
        this.selectedMonth = this.monthMap[previous_month_index];
        if (previous_month_index == (this.monthMap.length - 1)) {
            this.selectedYear -= 1;
        }

        this.renderCalendar();
    }

	selectPreviousYear() {
		this.selectedYear -=1;
	}

	selectNextYear() {
		this.selectedYear +=1;
	}

	saveCalendar() {
		this.modalController.dismiss({
			newDate: this.selectedYear + '-' + this.getMonthNumberFromMap(this.selectedMonth) + '-' + this.selectedDate.toString().padStart(2, '0')
		});
	}

}

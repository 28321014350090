import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CountrySelectorPage } from '../country-selector/country-selector.page';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { v4 as uuid } from 'uuid';
import { IUpdateableMemberData } from '../../shared/store/members/types';
import { IUser, UserState } from '../../shared/store/users/types';
import { NgRedux } from '@angular-redux/store';
import { addMember, updateMemberRequest } from '../../shared/store/members/actions';
import { MemberState } from '../../shared/store/members/types';
import { getReadableDataForCustomField } from 'src/shared/store/custom-fields';
import { CustomFieldValueType, IUpdateableCustomFieldData } from '../../shared/store/custom-fields/types';
import { IGroup, GroupState } from 'src/shared/store/groups/types';
import { IGroupType } from 'src/shared/store/groups/types/types';
import { IMemberType } from 'src/shared/store/members/types/types';
import { translatePhrase } from 'src/shared/helpers/translation';
import { getAllLeafLocationsOfUser } from 'src/shared/helpers/locations';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { PermissionSet, Permissions } from 'src/shared/store/permissions/types';
import { ApplicationState } from 'src/shared/store/types';
import { ToastService } from '../services/toast.service';
import { AddWorkflowPage } from '../add-workflow/add-workflow.page';
import { ToastType } from '../services/toast.service';

@Component({
	selector: 'app-add-or-edit-member',
	templateUrl: './add-or-edit-member.page.html',
	styleUrls: ['./add-or-edit-member.page.scss'],
})
export class AddOrEditMemberPage implements OnInit, OnDestroy {
	@select(['myData', 'id']) myData: Observable<string>;
	@select('users') UsersData: Observable<UserState>;
	@select('members') MembersData: Observable<MemberState>;
	@select('groups') GroupsData: Observable<GroupState>;
	@select(state => state) applicationStateSource: Observable<ApplicationState>;
	membersData: MemberState;
	groupsData: GroupState;
	current_heading: string = 'Add';
	save_state: string = 'inactive';
	current_member: IUpdateableMemberData;
	users: Array<IUser> = [];
	customFields: Array<IUpdateableCustomFieldData> = [];
	memberTypes: Array<IMemberType>;
	all_groups: Array<IGroup> = [];
	all_group_types: Array<IGroupType> = [];
	calculated_groups: any = {};
	country_code: string = '+91';
	leafLocations: Array<string>;
	applicationState: ApplicationState;
	applicationStateSubscription: Subscription;

	userPermission: PermissionSet;
	selectMemberTypeModal: boolean = false;
	memberTypeSearchTerm: string = '';

	constructor(
		public modalController: ModalController,
		private geolocation: Geolocation,
		private ngRedux: NgRedux<ApplicationState>,
		public navParams: NavParams,
		public toastService: ToastService
	) { }

	translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

	async addNewWorkflowWithType(id: string) {
		const modal = await this.modalController.create({
			component: AddWorkflowPage,
			componentProps: {
				workflow_type_id: id
			}
		});

		return await modal.present();
	}

	isActionWorkflowAvailable(actionId: string) {
		return this.membersData.types.actions.byId[actionId].workflowType ? true : false;
	}

	updateCustomFields() {
		if (this.current_member.type) {

			let memberType = this.membersData.types.byId[this.current_member.type];

			if (memberType.actions && memberType.actions[0] &&
				this.membersData.types.actions.byId[memberType.actions[0]].workflowType &&
				this.current_heading !== 'Edit') {
				this.back();
				this.addNewWorkflowWithType(this.membersData.types.actions.byId[memberType.actions[0]].workflowType);
			} else {
				if (this.getMemberTypePermission(this.current_member.type) === 'WRITE') {
					this.customFields = this.current_member.type ?
						this.membersData.types.byId[this.current_member.type].customFields.map((fieldId) => {
							return this.membersData.types.customFields.byId[fieldId];
						}) : [];

					let i: number;
					for (i = 0; i < this.customFields.length; i += 1) {
						if (this.current_member.customFields[this.customFields[i].id] === undefined && this.customFields[i].type === 'PHONE') {
							this.current_member.customFields[this.customFields[i].id] = this.country_code + ' ';
						}
					}

					this.selectMemberTypeModal = false;
				} else {
					this.toastService.presentToastWithOptions("You do not have permission to add member of this type", ToastType.WARNING);
				}
			}
		} else {
			this.toastService.presentToastWithOptions("Please select a member type", ToastType.INFO);
		}
	}

	isAllFieldsFilled() {
		let flag = true;

		for (let i = 0; i < this.customFields.length; i += 1) {
			if (!this.current_member.customFields[this.customFields[i].id]) {
				if (!(this.customFields[i].isComputed || !this.customFields[i].isEditable)) {
					this.toastService.presentToastWithOptions("Missing " + this.customFields[i].name, ToastType.ERROR);
					flag = false;
				}
				break;
			}
		}

		return flag;
	}

	getCustomFieldOptionDetails(id: string) {
		return this.membersData.types.customFieldOptions.byId[id].name;
	}

	getGroupName(groupId: string) {
		const group = this.groupsData.byId[groupId];
		const groupType = this.groupsData.types.byId[group.type];
		let groupName: CustomFieldValueType;

		groupName = group.customFields[groupType.nameFieldId];
		const nameField = this.groupsData.types.customFields.byId[groupType.nameFieldId];

		groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');

		return groupName;
	}

	getLocation(id: string) {
		this.geolocation.getCurrentPosition().then((resp) => {
			this.current_member.customFields[id] = resp.coords.latitude + ' ' + resp.coords.longitude;
		}).catch(() => {
			this.toastService.presentToastWithOptions('Error getting location', ToastType.ERROR);
		});
	}

	getMemberTypePermission(id: string) {
		if (this.userPermission.members && this.userPermission.members[id]) {
			return this.userPermission.members[id];
		} else {
			return Permissions.WRITE;
		}
	}

	updateGroupInfo() {

		const currentMemberType = this.membersData.types.byId[this.current_member.type];

		if (currentMemberType) {
			this.all_group_types = this.groupsData.types.allEntries
				.map(typeId => {
					return this.groupsData.types.byId[typeId];
				})
				.filter(type => type.project === currentMemberType.project);

			this.all_groups = this.groupsData.allEntries.map((groupId) => {
				return this.groupsData.byId[groupId];
			});

			for (let i = 0; i < this.all_group_types.length; i += 1) {
				this.calculated_groups[this.all_group_types[i].id] = null;
				this.calculated_groups[this.all_group_types[i].id] = this.all_groups.filter((group) => {
					return group.type.indexOf(this.all_group_types[i].id) > -1;
				});
			}

			this.leafLocations = getAllLeafLocationsOfUser(this.applicationState.myData.id, currentMemberType.project);

			if (this.leafLocations.length > 0) {
				this.current_member.location = this.leafLocations[0];
			}
		}

	}

	ngOnInit() {
		this.applicationStateSubscription = this.applicationStateSource.subscribe((applicationState) => {
			this.applicationState = applicationState;

			this.groupsData = this.applicationState.groups;

			this.userPermission = this.applicationState.permissions.myPermissions;

			this.membersData = this.applicationState.members;

			if (this.navParams.get('member')) {
				this.current_member = JSON.parse(this.navParams.get('member'));
				this.current_heading = 'Edit';
				this.memberTypes = this.membersData.types.allEntries.map(typeId => this.membersData.types.byId[typeId]);

				let userProjectIds = this.applicationState.users.byId[this.applicationState.myData.id].projects;

				this.memberTypes = this.memberTypes.filter((type) => {
					return userProjectIds.includes(type.project) && this.getMemberTypePermission(type.id) !== 'NONE';
				});

				this.updateCustomFields();
			} else {
				this.current_member = {
					id: uuid(),
					type: '',
					location: '',
					groups: {},
					customFields: {}
				};

				this.memberTypes = this.membersData.types.allEntries.map(typeId => this.membersData.types.byId[typeId]);

				let userProjectIds = this.applicationState.users.byId[this.applicationState.myData.id].projects;

				this.memberTypes = this.memberTypes.filter((type) => {
					return userProjectIds.includes(type.project) && this.getMemberTypePermission(type.id) !== 'NONE';
				});

				if (this.memberTypes.length > 0) {
					this.current_member.type = '';
					this.selectMemberTypeModal = true;
				}

				if (this.memberTypes.length === 1) {
					this.current_member.type = this.memberTypes[0].id;
					this.updateGroupInfo();
				}
			}

		});
	}

	getlocationName(id: string) {
		return this.applicationState.structure.locations.byId[id].name;
	}

	back() {
		this.modalController.dismiss();
	}

	save() {
		if (this.selectMemberTypeModal) {
			return;
		}

		if (this.current_heading === 'Add') {
			if (this.isAllFieldsFilled()) {
				this.save_state = 'initiated';
				this.ngRedux.dispatch(addMember(this.current_member));
				this.save_state = 'saved';
				this.back();
			}
		} else {
			this.save_state = 'initiated';
			this.ngRedux.dispatch(updateMemberRequest(this.current_member));
			this.save_state = 'saved';
			this.back();
		}
	}

	async selectCountryModal(custom_field_id: string) {
		if (this.current_member.customFields[custom_field_id] === undefined) {
			const modal = await this.modalController.create({
				component: CountrySelectorPage,
				componentProps: {
					countryCode: this.country_code
				}
			});

			modal.onDidDismiss().then((data) => {
				this.country_code = data.data;
				this.current_member.customFields[custom_field_id] = this.country_code + ' ';
			});

			return await modal.present();
		} else if (this.current_member.customFields[custom_field_id].toString().split(' ').length < 2) {
			const modal = await this.modalController.create({
				component: CountrySelectorPage,
				componentProps: {
					countryCode: this.country_code
				}
			});

			modal.onDidDismiss().then((data) => {
				this.country_code = data.data;
				this.current_member.customFields[custom_field_id] = this.country_code + ' ';
			});

			return await modal.present();
		}
	}

	ngOnDestroy() {
		if (this.applicationStateSubscription) {
			this.applicationStateSubscription.unsubscribe();
		}
	}

	getQuestionInputData(e: any) {
		this.current_member.customFields[e.customFieldId] = e.data;
	}

	pushToGroup(typeId: string, groupId: string) {
		if (this.current_member.groups[typeId]) {
			if (this.current_member.groups[typeId].includes(groupId)) {
				this.current_member.groups[typeId].splice(this.current_member.groups[typeId].indexOf(groupId), 1);
			} else {
				this.current_member.groups[typeId].push(groupId);
			}
		} else {
			this.current_member.groups[typeId] = [];
			this.current_member.groups[typeId].push(groupId);
		}
	}

}

import { SELECT_STATIC_DATA_HOLDER, UN_SELECT_STATIC_DATA_HOLDER, ADD_STATIC_DATA_HOLDER, DELETE_STATIC_DATA_HOLDER, UPDATE_STATIC_DATA_HOLDER, RE_ORDER_STATIC_DATA_HOLDERS, StaticDataHolderState, IStaticDataHolder, UPDATE_STATIC_DATA_HOLDERS_DATA, CLEAR_STATIC_DATA_HOLDERS_DELTA, SYNCHRONIZE_STATIC_DATA_HOLDERS_DATA, StaticDataHolderActionTypes, ADD_DATA_FRAGMENT_TO_STATIC_DATA_HOLDER, REMOVE_DATA_FRAGMENT_FROM_STATIC_DATA_HOLDER } from './types';
import { reOrderList } from '../../helpers/utilities';
import { addEntity, updateEntity, deleteEntity, updateEntries, clearDelta, synchronizeEntries } from '../normalized-model';
import { dataFragmentsReducer, initialState as dataFragmentsInitialState } from './data-fragment/reducer';

const initialState: StaticDataHolderState = {  
    byId:{},
    allEntries: [],
    filteredEntries: [],

    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),
    fragments: dataFragmentsInitialState,

    areStaticDataHoldersReordered: false,
};

export function staticDataHoldersReducer(state = initialState, action: StaticDataHolderActionTypes) {
    state = {
        ...state,
        fragments: dataFragmentsReducer(state.fragments, action),
    };

    switch(action.type) {

        case SELECT_STATIC_DATA_HOLDER:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_STATIC_DATA_HOLDER:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_STATIC_DATA_HOLDERS:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
                areStaticDataHoldersReordered: true,
            };

        case ADD_STATIC_DATA_HOLDER:
            return addEntity<StaticDataHolderState, IStaticDataHolder>(state, action.payload);
        
        case UPDATE_STATIC_DATA_HOLDER:
            return updateEntity<StaticDataHolderState, IStaticDataHolder>(state, action.payload, action.currentTime);
        
        case DELETE_STATIC_DATA_HOLDER:
            state = deleteEntity<StaticDataHolderState, IStaticDataHolder>(state, action.id, action.currentTime);

            return {
                ...state,
                selected: undefined,
            };

        case ADD_DATA_FRAGMENT_TO_STATIC_DATA_HOLDER:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.staticDataHolderId]: {
                        ...state.byId[action.staticDataHolderId],
                        children: state.byId[action.staticDataHolderId].children.concat([action.dataFragmentId]),
                    }
                }
            }

        case REMOVE_DATA_FRAGMENT_FROM_STATIC_DATA_HOLDER:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.staticDataHolderId]: {
                        ...state.byId[action.staticDataHolderId],
                        children: state.byId[action.staticDataHolderId].children.filter(dataFragmentId => dataFragmentId !== action.dataFragmentId),
                    }
                }
            }

        case UPDATE_STATIC_DATA_HOLDERS_DATA:
            return updateEntries<StaticDataHolderState, IStaticDataHolder>(state, action.data);

        case SYNCHRONIZE_STATIC_DATA_HOLDERS_DATA:
            return synchronizeEntries<StaticDataHolderState, IStaticDataHolder>(state, action.data);

        case CLEAR_STATIC_DATA_HOLDERS_DELTA:
            state = clearDelta<StaticDataHolderState, IStaticDataHolder>(state);
            state.areStaticDataHoldersReordered = false;
            return state;
        

        default:
            return state
    }
}
import { FETCH_LOCAL_DATA, FETCH_LOCAL_DATA_REQUEST, RESET_SESSION, ApplicationState, FetchLocalDataAction, FetchLocalDataRequestAction, ResetSessionAction } from './types';

export function fetchLocalDataRequest(): FetchLocalDataRequestAction {
    return {
        type: FETCH_LOCAL_DATA_REQUEST,
    }
}

export function fetchLocalData(state: ApplicationState): FetchLocalDataAction {
    return {
        type: FETCH_LOCAL_DATA,
        data: state,
    }
}

export function resetSession(): ResetSessionAction {
    return {
        type: RESET_SESSION,
    }
}
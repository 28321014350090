import { Component, OnInit, Input, EventEmitter, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { ICard } from '../models/list-card';
import { translatePhrase } from 'src/shared/helpers/translation';
import { IonInfiniteScroll } from '@ionic/angular';
import * as moment from 'moment';

// Used in HTML
import * as Hammer from 'hammerjs';

@Component({
	selector: 'app-list-card',
	templateUrl: './list-card.component.html',
	styleUrls: ['./list-card.component.scss'],
})

export class ListCardComponent implements OnInit, OnChanges {
	@Input() header: string;
	@Input() cardList: Array<ICard>;
	@Output() clickDetector = new EventEmitter();
	@ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
	selected_card: ICard;

	@Output() loadMoreDetector = new EventEmitter();

	constructor() { }

	ngOnChanges(simpleChanges: SimpleChanges) {
		this.cardList = simpleChanges.cardList.currentValue;
	}

    getFormattedDate(date: any) {
        return moment(date).format('DD MMM YYYY');
    }

	ngOnInit() {
	}

    getCompletionRate(completionPercentage: number) {
        return completionPercentage.toString() + '%';
    }

	detectClick(button_type: string, id: string) {
		this.clickDetector.emit({
			button_type: button_type,
			id: id
		});
	}

	translate(phrase: string) {
		return translatePhrase(phrase.trim());
	}

	loadData(event: any) {
		setTimeout(() => {
			this.loadMoreDetector.emit(this.cardList.length - 1);

			event.target.complete();			
		}, 1000);
	}

}

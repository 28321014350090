import { ProjectActionTypes, ProjectState } from './project/types';
import { LevelActionTypes, LevelState } from './level/types';
import { RoleActionTypes, RoleState } from './role/types';

import { LocationActionTypes, LocationState } from './location/types';

export const UPDATE_STRUCTURE_DATA = 'UPDATE_STRUCTURE_DATA';

export interface StructureState {
    projects: ProjectState,
    levels: LevelState,
    roles: RoleState,

    locations: LocationState,
}

interface IUpdateStructureDataAction {
    type: typeof UPDATE_STRUCTURE_DATA,
    data: StructureState,
}

export type StructureActionTypes = ProjectActionTypes | LevelActionTypes | RoleActionTypes | LocationActionTypes | IUpdateStructureDataAction;
import { takeEvery, put, select, all, call } from 'redux-saga/effects'
import { AddUserCustomFieldAction, ADD_USER, UPDATE_USER_REQUEST, DELETE_USER, UN_ARCHIVE_USER, ADD_USER_CUSTOM_FIELD, UPDATE_USER_CUSTOM_FIELD, AddUserAction, DeleteUserAction, UnArchiveUserAction, UpdateUserRequestAction, IUser, AddUserRequestAction, ADD_USER_REQUEST, BulkRecalculateComputedFieldsForUserAction, ChangeDeltaForUserComputedFields, ComputedFieldUpdatePayloadForUser, RecalculateComputedFieldsForUserAction, BULK_RECALCULATE_COMPUTED_FIELDS_FOR_USER, RECALCULATE_COMPUTED_FIELDS_FOR_USER, RECOMPUTE_ALL_USERS } from './types';
import { updateUserCustomFieldStartPiece, registerUserCustomFieldVariable, updateUser, addUser, bulkRecalculateComputedFieldsForUser, bulkUpdateUserComputedFieldData, updateUserComputedFieldData, recalculateComputedFieldsForUser } from './actions';
import { addUserToLocation, removeUserFromLocation } from '../structure/location/actions';
import { addVariable } from '../flowchart/variables/actions';
import { VariableType } from '../flowchart/variables/types';
import { addPiece } from '../flowchart/pieces/actions';
import uuid from 'uuid';
import axios, { AxiosResponse } from 'axios';
import { IVariablePiece, PieceType } from '../flowchart/pieces/types';
import { ApplicationState } from '../types';
import { setToastMessage } from '../my-data/actions';
import { translatePhrase } from '../../helpers/translation';
import { UpdateProjectAction, UPDATE_PROJECT } from '../structure/project/types';
import { UpdateLevelAction, UPDATE_LEVEL } from '../structure/level/types';
import { UpdateRoleAction, UPDATE_ROLE } from '../structure/role/types';
import { UpdateLocationAction, UPDATE_LOCATION } from '../structure/location/types';
import { SearchIndex } from '../../helpers/common-types';
import { BASE_URL } from '../url';
import { getValueForComputedField } from '../custom-fields';
import { CustomField, CustomFieldDataHolder } from '../custom-fields/types';
import { getAllPiecesInPiece } from '../flowchart/helpers/pieces';
import { ChangeDeltaForWorkflowComputedFields } from '../workflows/types';
import { bulkRecalculateComputedFieldsForWorkflow } from '../workflows/actions';

function* createSeedFlowchartForUserCustomField(action: AddUserCustomFieldAction) {

    const state: ApplicationState = yield select();

    // Creating seed flowcharts are only required for computed fields
    if (action.payload.isComputed) {

        if (!action.payload.seedEntityVariable) {
            throw new Error('Computed fields need to have the seed workflow variable ID defined');
        }

        // Only seed the flochart if it doesn't already exist
        if (!state.flowchart.variables.byId.hasOwnProperty(action.payload.seedEntityVariable)) {

            const startPieceId = uuid.v4();

            yield all([
                put(addVariable({
                    id: action.payload.seedEntityVariable,
                    name: 'User',
                    type: VariableType.USER,
                })),

                put(registerUserCustomFieldVariable(action.payload.seedEntityVariable, action.payload.id)),

                put(addPiece(startPieceId, PieceType.START)),

                put(updateUserCustomFieldStartPiece({
                    piece: startPieceId,
                    position: {
                        x: 0,
                        y: 0,
                    }
                }, action.payload.id)),
            ]);

        }

        const changeDeltas: Array<ChangeDeltaForUserComputedFields> = Object.keys(state.users.byId).map(userId => {
            return {
                userId,
                userChanged: true,
                locationsChanged: false,
                workflowTypesChanged: [],
            }
        })

        yield put(bulkRecalculateComputedFieldsForUser(changeDeltas));

    }
}

function* provideReverseLocationLinkForNewUser(action: AddUserAction) {
    let allPutActions: any = [];
    allPutActions = action.payload.locations.map(locationId => put(addUserToLocation(action.payload.id, locationId)));

    yield all(allPutActions);

    yield put(recalculateComputedFieldsForUser(action.payload.id, false, true, []));
}

function* provideReverseLocationLinkForExistingUser(action: UnArchiveUserAction) {
    const user: IUser = yield select(state => state.users.byId[action.id]);
    yield all(user.locations.map(locationId => put(addUserToLocation(action.id, locationId))));
}

function* removeReverseLocationLinkForOldUser(action: DeleteUserAction) {
    const state: ApplicationState = yield select();
    yield all(state.users.byId[action.id].locations.map(locationId => put(removeUserFromLocation(action.id, locationId))));
}

function fetchUserCount() {

    const serverUrl = BASE_URL + '/user-count/';

    return axios.get(serverUrl, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });

}

function* validateUserAdd(action: AddUserRequestAction) {
    const state: ApplicationState = yield select();

    if (!window.navigator.onLine) {
        yield put(setToastMessage(translatePhrase('Users can only be added when online')));
        return;
    }

    let responseData: AxiosResponse<Number>;

    try {
        responseData = yield call(fetchUserCount);
    } catch (e) {
        yield put(setToastMessage(translatePhrase('Error while fetching user count. Please try again')));
        return;
    }
    const serverUserCount = responseData.data as number;  // Coerce the data to be in the expected format
    const totalUserCount = serverUserCount + state.users.createdIds.size;

    if (isNaN(Number(totalUserCount))) {
        yield put(setToastMessage(translatePhrase('Incorrect data format')));
    } else if (state.organization.plan !== 'unlimited' && totalUserCount >= 10) {
        yield put(setToastMessage(translatePhrase('You have reached the maximum limit')));
    } else {
        yield put(addUser(action.payload));
        yield put(setToastMessage(translatePhrase('The user has been created!')));
    }

}

function* updateLocationsAndUserAndIndex(action: UpdateUserRequestAction) {
    let state: ApplicationState = yield select();

    yield all(state.users.byId[action.payload.id].locations.map(locationId => put(removeUserFromLocation(action.payload.id, locationId))));

    yield all(action.payload.locations.map(locationId => put(addUserToLocation(action.payload.id, locationId))));

    yield put(updateUser(action.payload));

    yield put(recalculateComputedFieldsForUser(action.payload.id, false, true, []));

    const linkedWorkflowIds = Object.keys(state.users.byId[action.payload.id].workflows).map(workflowTypeId => state.users.byId[action.payload.id].workflows[workflowTypeId]).flat().filter(workflowId => workflowId in state.workflows.byId);

    const changeDelta: Array<ChangeDeltaForWorkflowComputedFields> = linkedWorkflowIds.map(workflowId => {
        return {
            workflowId,
            workflowChanged: false,
            usersChanged: true,
            affiliationChanged: false,
        };
    });

    yield put(bulkRecalculateComputedFieldsForWorkflow(changeDelta));
}

function* updateUserIndicesForProject(action: UpdateProjectAction) {
    const state: ApplicationState = yield select();

    const allUserIdsInProject = state.users.allEntries.filter(userId => {
        const user = state.users.byId[userId];
        return user.projects.includes(action.payload.id);
    });

    const changeDeltas: Array<ChangeDeltaForUserComputedFields> = allUserIdsInProject.map(userId => {
        return {
            userId,
            userChanged: true,
            locationsChanged: false,
            workflowTypesChanged: [],
        }
    })

    yield put(bulkRecalculateComputedFieldsForUser(changeDeltas));
}

function* updateUserIndicesForLevel(action: UpdateLevelAction) {
    const state: ApplicationState = yield select();

    const allUserIdsInLevel = state.users.allEntries.filter(userId => {
        const user = state.users.byId[userId];
        return user.levels.includes(action.payload.id);
    });

    const changeDeltas: Array<ChangeDeltaForUserComputedFields> = allUserIdsInLevel.map(userId => {
        return {
            userId,
            userChanged: true,
            locationsChanged: false,
            workflowTypesChanged: [],
        }
    })

    yield put(bulkRecalculateComputedFieldsForUser(changeDeltas));
}

function* updateUserIndicesForRole(action: UpdateRoleAction) {
    const state: ApplicationState = yield select();

    const allUserIdsInRole = state.users.allEntries.filter(userId => {
        const user = state.users.byId[userId];
        return user.roles.includes(action.payload.id);
    });

    const changeDeltas: Array<ChangeDeltaForUserComputedFields> = allUserIdsInRole.map(userId => {
        return {
            userId,
            userChanged: true,
            locationsChanged: false,
            workflowTypesChanged: [],
        }
    })

    yield put(bulkRecalculateComputedFieldsForUser(changeDeltas));
}

function shouldUpdateCustomField(customField: CustomField, changeDelta: ChangeDeltaForUserComputedFields, variablePiecesInFlowchart: Array<IVariablePiece>) {

    if (!customField.isComputed || !customField.startPiece) {
        return false;
    }

    if (changeDelta.userChanged) {
        return true;
    }

    if (changeDelta.locationsChanged) {
        const usesLocation = variablePiecesInFlowchart.some(variablePiece => {
            const isUserVariable = variablePiece.variable === customField.seedEntityVariable;

            if (!isUserVariable) {
                return false;
            }

            const nestingObject = variablePiece.nesting;

            if (!nestingObject) {
                return false;
            }

            if (nestingObject.length === 0) {
                return false;
            }

            return nestingObject[0].type === 'LOCATIONS_LIST';
        });

        return usesLocation;
    }

    for (const workflowTypeId of changeDelta.workflowTypesChanged) {
        const usesWorkflowType = variablePiecesInFlowchart.some(variablePiece => {
            const isUserVariable = variablePiece.variable === customField.seedEntityVariable;

            if (!isUserVariable) {
                return false;
            }

            const nestingObject = variablePiece.nesting;

            if (!nestingObject) {
                return false;
            }

            if (nestingObject.length === 0) {
                return false;
            }

            return nestingObject[0].type === 'WORKFLOWS_LIST' && nestingObject[0].value === workflowTypeId;
        });

        return usesWorkflowType;
    }

    return false;
}

function* recalculateComputedFieldsDataForUser(action: RecalculateComputedFieldsForUserAction) {
    const state: ApplicationState = yield select();
    const user = state.users.byId[action.userId];
    const roles = user.roles.map(roleId => state.structure.roles.byId[roleId]);
    const changeDelta: ChangeDeltaForUserComputedFields = {
        userId: action.userId,
        userChanged: action.userChanged,
        locationsChanged: action.locationsChanged,
        workflowTypesChanged: action.workflowTypesChanged,
    };

    const roleCustomFieldIds = roles.map(role => role.customFields).flat();

    const roleCustomFieldIdsToUpdate = roleCustomFieldIds.filter(customFieldId => {
        const customField = state.structure.roles.customFields.byId[customFieldId];

        if (!customField.isComputed || !customField.startPiece) {
            return false;
        }

        const piecesInFlowchart = getAllPiecesInPiece(state.flowchart.pieces, customField.startPiece.piece);

        const variablePiecesInFlowchart: Array<IVariablePiece> = piecesInFlowchart.filter(piece => piece.type === PieceType.VARIABLE) as Array<IVariablePiece>;
        return shouldUpdateCustomField(customField, changeDelta, variablePiecesInFlowchart);
    });

    if (roleCustomFieldIdsToUpdate.length > 0) {
        const customFieldData: CustomFieldDataHolder = {};

        for (const customFieldId of roleCustomFieldIdsToUpdate) {
            const customField = state.structure.roles.customFields.byId[customFieldId];
            const fieldValue = getValueForComputedField(customField, action.userId, 'role', state);

            if (fieldValue !== user.customFields[customFieldId]) {
                customFieldData[customFieldId] = fieldValue;
            }
        }

        if (Object.keys(customFieldData).length > 0) {
            yield put(updateUserComputedFieldData(action.userId, customFieldData));
        }

    }

    const userCustomFieldIds = state.users.customFields.allFields;

    const userCustomFieldIdsToUpdate = userCustomFieldIds.filter(customFieldId => {
        const customField = state.users.customFields.byId[customFieldId];

        if (!customField.isComputed || !customField.startPiece) {
            return false;
        }

        const piecesInFlowchart = getAllPiecesInPiece(state.flowchart.pieces, customField.startPiece.piece);

        const variablePiecesInFlowchart: Array<IVariablePiece> = piecesInFlowchart.filter(piece => piece.type === PieceType.VARIABLE) as Array<IVariablePiece>;
        return shouldUpdateCustomField(customField, changeDelta, variablePiecesInFlowchart);
    });

    if (userCustomFieldIdsToUpdate.length > 0) {
        const customFieldData: CustomFieldDataHolder = {};

        for (const customFieldId of userCustomFieldIdsToUpdate) {
            const customField = state.members.types.customFields.byId[customFieldId];
            const fieldValue = getValueForComputedField(customField, action.userId, 'user', state);

            if (fieldValue !== user.customFields[customFieldId]) {
                customFieldData[customFieldId] = fieldValue;
            }
        }

        if (Object.keys(customFieldData).length > 0) {
            yield put(updateUserComputedFieldData(action.userId, customFieldData));
        }

    }

}

function* bulkRecalculateComputedFieldsDataForUser(action: BulkRecalculateComputedFieldsForUserAction) {
    const state: ApplicationState = yield select();

    const variablePiecesForFields: {
        [roleId: string]: {
            [customFieldId: string]: Array<IVariablePiece>,
        }
    } = {
        user: {},
    };

    for (const customFieldId of state.users.customFields.allFields) {
        const customField = state.users.customFields.byId[customFieldId];

        if (!customField.isComputed || !customField.startPiece) {
            continue;
        }

        const piecesInFlowchart = getAllPiecesInPiece(state.flowchart.pieces, customField.startPiece.piece);
        const variablePiecesInFlowchart: Array<IVariablePiece> = piecesInFlowchart.filter(piece => piece.type === PieceType.VARIABLE) as Array<IVariablePiece>;
        variablePiecesForFields['user'][customFieldId] = variablePiecesInFlowchart;
    }

    for (const roleId of state.structure.roles.allEntries) {
        variablePiecesForFields[roleId] = {};
        const role = state.structure.roles.byId[roleId];

        for (const customFieldId of role.customFields) {
            const customField = state.structure.roles.customFields.byId[customFieldId];

            if (!customField.isComputed || !customField.startPiece) {
                continue;
            }

            const piecesInFlowchart = getAllPiecesInPiece(state.flowchart.pieces, customField.startPiece.piece);
            const variablePiecesInFlowchart: Array<IVariablePiece> = piecesInFlowchart.filter(piece => piece.type === PieceType.VARIABLE) as Array<IVariablePiece>;
            variablePiecesForFields[roleId][customFieldId] = variablePiecesInFlowchart;
        }
    }

    const bulkRecalculatePayload: Array<ComputedFieldUpdatePayloadForUser> = action.payload.map(changeDelta => {
        const user = state.users.byId[changeDelta.userId];
        const roles = user.roles.map(roleId => state.structure.roles.byId[roleId]);

        const roleCustomFieldIds = roles.map(role => role.customFields).flat();

        const userCustomFieldIdsToUpdate = state.users.customFields.allFields.filter(customFieldId => {
            const customField = state.users.customFields.byId[customFieldId];

            if (!customField.isComputed || !customField.startPiece) {
                return false;
            }

            const variablePiecesInFlowchart = variablePiecesForFields['user'][customFieldId];
            return shouldUpdateCustomField(customField, changeDelta, variablePiecesInFlowchart);
        });

        const roleCustomFieldIdsToUpdate = roleCustomFieldIds.filter(customFieldId => {
            const customField = state.structure.roles.customFields.byId[customFieldId];

            if (!customField.parentId) {
                return false;
            }

            if (!customField.isComputed || !customField.startPiece) {
                return false;
            }

            const variablePiecesInFlowchart = variablePiecesForFields[customField.parentId][customFieldId];
            return shouldUpdateCustomField(customField, changeDelta, variablePiecesInFlowchart);
        });

        const customFieldData: CustomFieldDataHolder = {};

        for (const customFieldId of roleCustomFieldIdsToUpdate) {
            const customField = state.structure.roles.customFields.byId[customFieldId];

            if (!customField.parentId) {
                continue;
            }

            const fieldValue = getValueForComputedField(customField, changeDelta.userId, 'role', state);

            if (fieldValue !== user.customFields[customFieldId]) {
                customFieldData[customFieldId] = fieldValue;
            }
        }

        for (const customFieldId of userCustomFieldIdsToUpdate) {
            const customField = state.users.customFields.byId[customFieldId];

            const fieldValue = getValueForComputedField(customField, changeDelta.userId, 'user', state);

            if (fieldValue !== user.customFields[customFieldId]) {
                customFieldData[customFieldId] = fieldValue;
            }
        }

        return {
            userId: changeDelta.userId,
            customFieldData,
        };
    });

    const filteredBulkRecalculatePayload = bulkRecalculatePayload.filter(payload => Object.keys(payload.customFieldData).length > 0);

    if (filteredBulkRecalculatePayload.length > 0) {
        yield put(bulkUpdateUserComputedFieldData(bulkRecalculatePayload));
    }

}

function* recalculateAllUserCustomFieldsData() {
    const state: ApplicationState = yield select();

    const changeDeltas: Array<ChangeDeltaForUserComputedFields> = Object.keys(state.users.byId).map(userId => {
        return {
            userId,
            userChanged: true,
            locationsChanged: false,
            workflowTypesChanged: [],
        }
    })

    yield put(bulkRecalculateComputedFieldsForUser(changeDeltas));
}

export function* watchUserCustomFieldChanges() {
    yield takeEvery([ADD_USER_CUSTOM_FIELD, UPDATE_USER_CUSTOM_FIELD], createSeedFlowchartForUserCustomField);
}

export function* watchUserCreationChanges() {
    yield takeEvery(ADD_USER, provideReverseLocationLinkForNewUser);
}

export function* watchUserDeletionChanges() {
    yield takeEvery(DELETE_USER, removeReverseLocationLinkForOldUser);
}

export function* watchUserUnarchiveChanges() {
    yield takeEvery(UN_ARCHIVE_USER, provideReverseLocationLinkForExistingUser);
}

export function* watchUserCreationRequestChanges() {
    yield takeEvery(ADD_USER_REQUEST, validateUserAdd);
}

export function* watchUserUpdateChanges() {
    yield takeEvery(UPDATE_USER_REQUEST, updateLocationsAndUserAndIndex);
}

export function* watchProjectUpdateChanges() {
    yield takeEvery(UPDATE_PROJECT, updateUserIndicesForProject);
}

export function* watchLevelUpdateChanges() {
    yield takeEvery(UPDATE_LEVEL, updateUserIndicesForLevel);
}

export function* watchRoleUpdateChanges() {
    yield takeEvery(UPDATE_ROLE, updateUserIndicesForRole);
}

export function* watchUserCustomFieldRecalculation() {
    yield takeEvery(RECALCULATE_COMPUTED_FIELDS_FOR_USER, recalculateComputedFieldsDataForUser);
}

export function* watchBulkUserCustomFieldRecalculation() {
    yield takeEvery(BULK_RECALCULATE_COMPUTED_FIELDS_FOR_USER, bulkRecalculateComputedFieldsDataForUser);
}

export function* watchRecomputeAllUsers() {
    yield takeEvery(RECOMPUTE_ALL_USERS, recalculateAllUserCustomFieldsData);
}
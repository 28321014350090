import { SELECT_LEVEL, UN_SELECT_LEVEL, RE_ORDER_LEVELS, ADD_LEVEL_REQUEST, ADD_LEVEL, DELETE_LEVEL, UPDATE_LEVEL, SELECT_LEVEL_CUSTOM_FIELD, UN_SELECT_LEVEL_CUSTOM_FIELD, ADD_LEVEL_CUSTOM_FIELD, DELETE_LEVEL_CUSTOM_FIELD, UPDATE_LEVEL_CUSTOM_FIELD, SELECT_LEVEL_CUSTOM_FIELD_OPTION, UN_SELECT_LEVEL_CUSTOM_FIELD_OPTION, RE_ORDER_LEVEL_CUSTOM_FIELD_OPTION, ADD_LEVEL_CUSTOM_FIELD_OPTION, DELETE_LEVEL_CUSTOM_FIELD_OPTION, UPDATE_LEVEL_CUSTOM_FIELD_OPTION, UPDATE_LEVEL_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE, REGISTER_LEVEL_CUSTOM_FIELD_VARIABLE, ADD_ROLE_TO_LEVEL, REMOVE_ROLE_FROM_LEVEL, AddLevelAction, SelectLevelAction, UnSelectLevelAction, ReOrderLevelsAction, AddLevelRequestAction, UpdateLevelAction, DeleteLevelAction, AddRoleToLevelAction, RemoveRoleFromLevelAction, SelectLevelCustomFieldAction, UnSelectLevelCustomFieldAction, AddLevelCustomFieldAction, DeleteLevelCustomFieldAction, UpdateLevelCustomFieldAction, UpdateLevelCustomFieldStartPieceAction, SetIsolatedLevelCustomFieldPieceAction, RemoveIsolatedLevelCustomFieldPieceAction, RegisterLevelCustomFieldVariableAction, SelectLevelCustomFieldOptionAction, UnSelectLevelCustomFieldOptionAction, ReOrderLevelCustomFieldOptionAction, AddLevelCustomFieldOptionAction, DeleteLevelCustomFieldOptionAction, UpdateLevelCustomFieldOptionAction, ILevel, INSTANTIATE_LEVEL, InstantiateLevelAction, INSTANTIATE_LEVEL_CUSTOM_FIELD, InstantiateLevelCustomFieldAction, INSTANTIATE_LEVEL_CUSTOM_FIELD_OPTION, InstantiateLevelCustomFieldOptionAction } from './types';
import { INewLevelData, IUpdateableLevelData } from './types';
import { v4 as uuidv4 } from 'uuid';
import { INewCustomFieldData, IUpdateableCustomFieldData, INewFieldChoiceData, IUpdateableFieldChoiceData, CustomField, FieldChoice } from '../../custom-fields/types';
import moment from 'moment';
import { PiecePositionState } from '../../../helpers/common-types';

export function selectLevel(id: string): SelectLevelAction {
    return {
        type: SELECT_LEVEL,
        id,
    }
}

export function unSelectLevel(): UnSelectLevelAction {
    return {
        type: UN_SELECT_LEVEL,
    }
}

export function reOrderLevels(sourceIndex: number, destinationIndex: number, parentId: string): ReOrderLevelsAction {
    return {
        type: RE_ORDER_LEVELS,
        sourceIndex,
        destinationIndex,
        parentId,
    }
}

export function addLevelRequest(payload: INewLevelData, parentId: string): AddLevelRequestAction {
    return {
        type: ADD_LEVEL_REQUEST,
        payload,
        parentId,
    }
}

export function addLevel(payload: INewLevelData, parentId: string): AddLevelAction {
    const now = moment.utc().format();
    return {
        type: ADD_LEVEL,
        payload: {
            id: uuidv4(),
            ...payload,
            project: parentId,
            children: [],
            customFields: [],
            createdTime: now,
            lastUpdatedTime: now,
        },
        parentId,
        currentTime: now,
    }
}

export function instantiateLevel(payload: ILevel, parentId: string): InstantiateLevelAction {
    const now = moment.utc().format();
    return {
        type: INSTANTIATE_LEVEL,
        payload,
        parentId,
        currentTime: now,
    }
}

export function updateLevel(payload: IUpdateableLevelData): UpdateLevelAction {
    return {
        type: UPDATE_LEVEL,
        payload,
        currentTime: moment.utc().format(),
    }
}

export function deleteLevel(id: string, parentId: string): DeleteLevelAction {
    return {
        type: DELETE_LEVEL,
        id,
        parentId,
        currentTime: moment.utc().format(),
    }
}

export function addRoleToLevel(roleId: string, levelId: string): AddRoleToLevelAction {
    return {
        type: ADD_ROLE_TO_LEVEL,
        roleId,
        levelId,
    };
}

export function removeRoleFromLevel(roleId: string, levelId: string): RemoveRoleFromLevelAction {
    return {
        type: REMOVE_ROLE_FROM_LEVEL,
        roleId,
        levelId,
    };
}



export function selectLevelCustomField(id: string): SelectLevelCustomFieldAction {
    return {
        type: SELECT_LEVEL_CUSTOM_FIELD,
        id,
    }
}

export function unSelectLevelCustomField(): UnSelectLevelCustomFieldAction {
    return {
        type: UN_SELECT_LEVEL_CUSTOM_FIELD,
    }
}

export function addLevelCustomField(payload: INewCustomFieldData, levelId: string): AddLevelCustomFieldAction {
    const now = moment.utc().format();

    return {
        type: ADD_LEVEL_CUSTOM_FIELD,
        payload: {
            id: uuidv4(),
            ...payload,
        },
        levelId,
        currentTime: now,
    }
}

export function instantiateLevelCustomField(payload: CustomField, levelId: string): InstantiateLevelCustomFieldAction {
    const now = moment.utc().format();

    return {
        type: INSTANTIATE_LEVEL_CUSTOM_FIELD,
        payload,
        levelId,
        currentTime: now,
    }
}

export function deleteLevelCustomField(id: string, levelId: string): DeleteLevelCustomFieldAction {
    return {
        type: DELETE_LEVEL_CUSTOM_FIELD,
        id,
        levelId,
        currentTime: moment.utc().format(),
    }
}

export function updateLevelCustomField(payload: IUpdateableCustomFieldData): UpdateLevelCustomFieldAction {
    return {
        type: UPDATE_LEVEL_CUSTOM_FIELD,
        payload,
        currentTime: moment.utc().format(),
    }
}

export function updateLevelCustomFieldStartPiece(payload: PiecePositionState, customFieldId: string): UpdateLevelCustomFieldStartPieceAction {
    return {
        type: UPDATE_LEVEL_CUSTOM_FIELD_START_PIECE,
        payload,
        customFieldId,
        currentTime: moment.utc().format(),
    }
}

export function setIsolatedLevelCustomFieldPiece(payload: PiecePositionState, customFieldId: string): SetIsolatedLevelCustomFieldPieceAction {
    return {
        type: SET_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE,
        payload,
        customFieldId,
        currentTime: moment.utc().format(),
    }
}

export function removeIsolatedLevelCustomFieldPiece(pieceId: string, customFieldId: string): RemoveIsolatedLevelCustomFieldPieceAction {
    return {
        type: REMOVE_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE,
        pieceId,
        customFieldId,
        currentTime: moment.utc().format(),
    }
}

export function registerLevelCustomFieldVariable(variableId: string, customFieldId: string): RegisterLevelCustomFieldVariableAction {
    return {
        type: REGISTER_LEVEL_CUSTOM_FIELD_VARIABLE,
        variableId,
        customFieldId,
        currentTime: moment.utc().format(),
    }
}


export function selectLevelCustomFieldOption(id: string): SelectLevelCustomFieldOptionAction {
    return {
        type: SELECT_LEVEL_CUSTOM_FIELD_OPTION,
        id,
    }
}

export function unSelectLevelCustomFieldOption(): UnSelectLevelCustomFieldOptionAction {
    return {
        type: UN_SELECT_LEVEL_CUSTOM_FIELD_OPTION,
    }
}

export function reOrderLevelCustomFieldOption(sourceIndex: number, destinationIndex: number, parentId: string): ReOrderLevelCustomFieldOptionAction {
    return {
        type: RE_ORDER_LEVEL_CUSTOM_FIELD_OPTION,
        sourceIndex,
        destinationIndex,
        parentId,
    }
}

export function addLevelCustomFieldOption(payload: INewFieldChoiceData, parentId: string): AddLevelCustomFieldOptionAction {
    return {
        type: ADD_LEVEL_CUSTOM_FIELD_OPTION,
        payload: {
            id: uuidv4(),
            ...payload,
        },
        parentId,
        currentTime: moment.utc().format(),
    }
}

export function instantiateLevelCustomFieldOption(payload: FieldChoice, parentId: string): InstantiateLevelCustomFieldOptionAction {
    return {
        type: INSTANTIATE_LEVEL_CUSTOM_FIELD_OPTION,
        payload,
        parentId,
        currentTime: moment.utc().format(),
    }
}

export function deleteLevelCustomFieldOption(id: string, parentId: string): DeleteLevelCustomFieldOptionAction {
    return {
        type: DELETE_LEVEL_CUSTOM_FIELD_OPTION,
        id,
        parentId,
        currentTime: moment.utc().format(),
    }
}

export function updateLevelCustomFieldOption(payload: IUpdateableFieldChoiceData): UpdateLevelCustomFieldOptionAction {
    return {
        type: UPDATE_LEVEL_CUSTOM_FIELD_OPTION,
        payload,
        currentTime: moment.utc().format(),
    }
}
import { put, takeEvery, select, all, delay, takeLatest, call } from 'redux-saga/effects'
import { AddWorkflowAction, ADD_WORKFLOW, UPDATE_WORKFLOW, DELETE_WORKFLOW, UN_ARCHIVE_WORKFLOW, IWorkflow, DeleteWorkflowAction, UnArchiveWorkflowAction, UpdateWorkflowStatusAction, UpdateWorkflowDueDateAction, AddToWorkflowHistory, UpdateWorkflowProcessStateAction, TransferWorkflowAction, ADD_TO_WORKFLOW_HISTORY, TRANSFER_WORKFLOW, UPDATE_WORKFLOW_DUE_DATE, UPDATE_WORKFLOW_PROCESS_STATE, UPDATE_WORKFLOW_STATUS, FILTER_WORKFLOW_TABLE, FilterWorkflowTableAction, UpdateWorkflowAction, ChangeDeltaForWorkflowComputedFields, RecalculateComputedFieldsForWorkflowAction, BulkRecalculateComputedFieldsForWorkflowAction, ComputedFieldUpdatePayloadForWorkflow, BULK_RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW, RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW, RECOMPUTE_ALL_WORKFLOWS, GO_TO_PAGE_WORKFLOW_TABLE, SEARCH_WORKFLOW_TABLE, SET_PAGE_SIZE_WORKFLOW_TABLE, WorkflowFilters } from './types';
import { AddWorkflowTypeAction, AddWorkflowTypeCustomFieldAction, ADD_WORKFLOW_TYPE_CUSTOM_FIELD, UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD, IWorkflowType, PUBLISH_FLOWCHART_TO_LIVE, PublishFlowchartToLiveAction } from './types/types';
import { addPiece } from '../flowchart/pieces/actions';
import { IStartPiece, IVariablePiece, PieceType } from '../flowchart/pieces/types';
import { addVariable } from '../flowchart/variables/actions';
import { VariableType } from '../flowchart/variables/types';
import { registerWorkflowTypeVariable, registerWorkflowTypeCustomFieldVariable, updateWorkflowTypeCustomFieldStartPiece, addWorkflowToWorkflowType, removeWorkflowFromWorkflowType, addStatusToWorkflowType, removeStatusFromWorkflowType, updateWorkflowTypeBetaStartPiece } from './types/actions';
import { ApplicationState } from '../types';

import { addWorkflowToMember, appendMembers, clearMemberEntries, recalculateComputedFieldsForMember, removeWorkflowFromMember } from '../members/actions';
import { addWorkflowToGroup, appendGroups, clearGroupEntries, recalculateComputedFieldsForGroup, removeWorkflowFromGroup } from '../groups/actions';
import { ADD_WORKFLOW_TYPE } from './types/types';
import uuid from 'uuid';
import { bulkRecalculateComputedFieldsForUser, recalculateComputedFieldsForUser, updateUserWorkflows } from '../users/actions';
import { AddStatusAction, DeleteStatusAction, ADD_STATUS, DELETE_STATUS } from './types/statuses/types';
import { appendWorkflows, bulkRecalculateComputedFieldsForWorkflow, bulkUpdateWorkflowComputedFieldData, clearWorkflowEntries, recalculateComputedFieldsForWorkflow, setTotalNumberOfWorkflows, updateWorkflowComputedFieldData } from './actions';
import { ChangeDeltaForUserComputedFields, IUser } from '../users/types';
import { WorkflowTypeCustomField, WorkflowTypeCustomFieldDataHolder } from '../custom-fields/types';
import { getAllPiecesInPiece } from '../flowchart/helpers/pieces';
import { getValueForComputedField } from '../custom-fields';
import axios, { AxiosResponse } from 'axios';
import { PageDataForOnlineEntities, OnlineWorkflowQueryData, FilterResponseForOnlineEntities } from '../../helpers/synchronize/types';
import { isUUID } from '../../helpers/utilities';
import { setInfoMessage, clearInfoMessage, setLastRefreshTime } from '../my-data/actions';
import { BASE_URL } from '../url';

function* createSeedFlowchartForWorkflowType(action: AddWorkflowTypeAction) {

    let allPutActions = [];

    allPutActions.push(put(addVariable({
        id: action.payload.seedEntityVariable,
        name: 'Workflow',
        type: VariableType.WORKFLOW,
    })));

    yield put(registerWorkflowTypeVariable(action.payload.seedEntityVariable, action.payload.id));

    if (action.payload.affiliation === 'member') {

        allPutActions.push(put(addVariable({
            id: action.payload.seedAffiliationVariable,
            name: 'Member',
            type: VariableType.MEMBER,
        })));

    } else if (action.payload.affiliation === 'group') {

        allPutActions.push(put(addVariable({
            id: action.payload.seedAffiliationVariable,
            name: 'Group',
            type: VariableType.GROUP,
        })));

    } else if (action.payload.affiliation === 'none') {

        allPutActions.push(put(addVariable({
            id: action.payload.seedAffiliationVariable,
            name: 'User',
            type: VariableType.USER,
        })));

    }

    allPutActions.push(put(addPiece(action.payload.startPiece.piece, PieceType.START)));
    allPutActions.push(put(addPiece(action.payload.betaStartPiece.piece, PieceType.START)));

    yield all(allPutActions);
    yield put(registerWorkflowTypeVariable(action.payload.seedAffiliationVariable, action.payload.id));
}

function* createSeedFlowchartForWorkflowTypeCustomField(action: AddWorkflowTypeCustomFieldAction) {

    const state: ApplicationState = yield select();

    // Creating seed flowcharts are only required for computed fields
    if (action.payload.isComputed) {

        if (!action.payload.seedEntityVariable) {
            throw new Error('Computed fields need to have the seed workflow variable ID defined');
        }

        if (!action.payload.seedAffiliationVariable) {
            throw new Error('Computed fields need to have the seed affiliation variable ID defined');
        }


        // Only seed the flochart if it doesn't already exist
        if (!state.flowchart.variables.byId.hasOwnProperty(action.payload.seedEntityVariable)) {

            yield put(addVariable({
                id: action.payload.seedEntityVariable,
                name: 'Workflow',
                type: VariableType.WORKFLOW,
            }));

            yield put(registerWorkflowTypeCustomFieldVariable(action.payload.seedEntityVariable, action.payload.id));

            if (action.payload.affiliation === 'member') {

                yield put(addVariable({
                    id: action.payload.seedAffiliationVariable,
                    name: 'Member',
                    type: VariableType.MEMBER,
                }));

            } else if (action.payload.affiliation === 'group') {

                yield put(addVariable({
                    id: action.payload.seedAffiliationVariable,
                    name: 'Group',
                    type: VariableType.GROUP,
                }));

            }

            yield put(registerWorkflowTypeCustomFieldVariable(action.payload.seedAffiliationVariable, action.payload.id));

            const startPieceId = uuid.v4();

            yield put(addPiece(startPieceId, PieceType.START));

            yield put(updateWorkflowTypeCustomFieldStartPiece({
                piece: startPieceId,
                position: {
                    x: 0,
                    y: 0,
                }
            }, action.payload.id));
        }

        const changeDeltas: Array<ChangeDeltaForWorkflowComputedFields> = Object.keys(state.workflows.byId).map(workflowId => {
            return {
                workflowId,
                workflowChanged: true,
                usersChanged: false,
                affiliationChanged: false,
            }
        })

        yield put(bulkRecalculateComputedFieldsForWorkflow(changeDeltas));

    }
}

function* indexAndProvideReverseLinksForNewWorkflow(action: AddWorkflowAction) {
    const workflowType: IWorkflowType = yield select(state => state.workflows.types.byId[action.payload.type]);
    let allPutActions = [];

    allPutActions.push(put(addWorkflowToWorkflowType(action.payload.id, action.payload.type)));
    allPutActions.push(put(recalculateComputedFieldsForWorkflow(action.payload.id, true, false, false)));

    const changeDeltas: Array<ChangeDeltaForUserComputedFields> = Array.from(new Set(action.payload.trackingUsers.concat(action.payload.user))).map(userId => {
        return {
            userId,
            userChanged: false,
            locationsChanged: false,
            workflowTypesChanged: [action.payload.type],
        }
    });

    yield put(bulkRecalculateComputedFieldsForUser(changeDeltas));

    if (workflowType.affiliation === 'member') {
        allPutActions.push(put(addWorkflowToMember(action.payload.affiliatedEntity, action.payload.type, action.payload.id)));
    } else if (workflowType.affiliation === 'group') {
        allPutActions.push(put(addWorkflowToGroup(action.payload.affiliatedEntity, action.payload.type, action.payload.id)));
    }

    yield all(allPutActions);
}

function* reIndexWorkflowOnExecution(action: UpdateWorkflowStatusAction | UpdateWorkflowDueDateAction | AddToWorkflowHistory | UpdateWorkflowProcessStateAction | TransferWorkflowAction) {
    const workflow: IWorkflow = yield select(state => state.workflows.byId[action.workflowId]);
    const workflowType: IWorkflowType = yield select(state => state.workflows.types.byId[workflow.type]);

    yield put(recalculateComputedFieldsForWorkflow(action.workflowId, true, false, false));

    const changeDeltas: Array<ChangeDeltaForUserComputedFields> = Array.from(new Set(workflow.trackingUsers)).map(userId => {
        return {
            userId,
            userChanged: false,
            locationsChanged: false,
            workflowTypesChanged: [workflow.type],
        }
    });

    yield put(bulkRecalculateComputedFieldsForUser(changeDeltas));

    if (workflowType.affiliation === 'member') {
        yield put(recalculateComputedFieldsForMember(workflow.affiliatedEntity, false, false, [], [workflow.type]));
    } else if (workflowType.affiliation === 'group') {
        yield put(recalculateComputedFieldsForGroup(workflow.affiliatedEntity, false, false, false, [workflow.type]));
    }
}

function* provideReverseLinksForExistingWorkflow(action: UnArchiveWorkflowAction) {
    const workflow: IWorkflow = yield select(state => state.workflows.byId[action.id]);
    const workflowType: IWorkflowType = yield select(state => state.workflows.types.byId[workflow.type]);
    let allPutActions = [];

    allPutActions.push(put(addWorkflowToWorkflowType(action.id, workflow.type)));

    if (workflowType.affiliation === 'member') {
        allPutActions.push(put(addWorkflowToMember(workflow.affiliatedEntity, workflow.type, action.id)));
    } else if (workflowType.affiliation === 'group') {
        allPutActions.push(put(addWorkflowToGroup(workflow.affiliatedEntity, workflow.type, action.id)));
    }

    yield all(allPutActions);
}

function* removeReverseLinksForWorkflow(action: DeleteWorkflowAction) {
    const currentWorkflow: IWorkflow = yield select(state => state.workflows.byId[action.id]);
    const workflowType: IWorkflowType = yield select(state => state.workflows.types.byId[currentWorkflow.type]);
    let allPutActions = [];

    allPutActions.push(put(removeWorkflowFromWorkflowType(action.id, currentWorkflow.type)));

    if (workflowType.affiliation === 'member') {
        allPutActions.push(put(removeWorkflowFromMember(currentWorkflow.affiliatedEntity, currentWorkflow.type, action.id)));
    } else if (workflowType.affiliation === 'group') {
        allPutActions.push(put(removeWorkflowFromGroup(currentWorkflow.affiliatedEntity, currentWorkflow.type, action.id)));
    }

    yield all(allPutActions);
}

function shouldUpdateCustomField(customField: WorkflowTypeCustomField, changeDelta: ChangeDeltaForWorkflowComputedFields, variablePiecesInFlowchart: Array<IVariablePiece>) {

    if (!customField.isComputed || !customField.startPiece) {
        return false;
    }

    if (changeDelta.workflowChanged) {
        return true;
    }

    if (changeDelta.usersChanged) {
        const usesUser = variablePiecesInFlowchart.some(variablePiece => {
            const isWorkflowVariable = variablePiece.variable === customField.seedEntityVariable;

            if (!isWorkflowVariable) {
                return false;
            }

            const nestingObject = variablePiece.nesting;

            if (!nestingObject) {
                return false;
            }

            if (nestingObject.length === 0) {
                return false;
            }

            return nestingObject[0].type === 'USERS_LIST' || nestingObject[0].type === 'USER';
        });

        return usesUser;
    }

    if (changeDelta.affiliationChanged) {
        const usesAffiliation = variablePiecesInFlowchart.some(variablePiece => variablePiece.variable === customField.seedAffiliationVariable);
        return usesAffiliation;
    }

    return false;
}

function* recalculateComputedFieldsDataForWorkflow(action: RecalculateComputedFieldsForWorkflowAction) {
    const state: ApplicationState = yield select();
    const workflow = state.workflows.byId[action.workflowId];
    const workflowType = state.workflows.types.byId[workflow.type];
    const changeDelta: ChangeDeltaForWorkflowComputedFields = {
        workflowId: action.workflowId,
        workflowChanged: action.workflowChanged,
        usersChanged: action.usersChanged,
        affiliationChanged: action.affiliationChanged,
    };

    const customFieldIdsToUpdate = workflowType.customFields.filter(customFieldId => {
        const customField = state.workflows.types.customFields.byId[customFieldId];

        if (!customField.isComputed || !customField.startPiece) {
            return false;
        }

        const piecesInFlowchart = getAllPiecesInPiece(state.flowchart.pieces, customField.startPiece.piece);

        const variablePiecesInFlowchart: Array<IVariablePiece> = piecesInFlowchart.filter(piece => piece.type === PieceType.VARIABLE) as Array<IVariablePiece>;
        return shouldUpdateCustomField(customField, changeDelta, variablePiecesInFlowchart);
    });

    if (customFieldIdsToUpdate.length > 0) {
        const customFieldData: WorkflowTypeCustomFieldDataHolder = {};

        for (const customFieldId of customFieldIdsToUpdate) {
            const customField = state.workflows.types.customFields.byId[customFieldId];
            const fieldValue = getValueForComputedField(customField, action.workflowId, 'workflow', state);
            const processState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];

            if (fieldValue !== processState.customFields[customFieldId]) {
                customFieldData[customFieldId] = fieldValue;
            }
        }

        if (Object.keys(customFieldData).length > 0) {
            yield put(updateWorkflowComputedFieldData(action.workflowId, customFieldData));
        }

    }

}

function* bulkRecalculateComputedFieldsDataForWorkflow(action: BulkRecalculateComputedFieldsForWorkflowAction) {
    const state: ApplicationState = yield select();

    const variablePiecesForFields: {
        [workflowTypeId: string]: {
            [customFieldId: string]: Array<IVariablePiece>,
        }
    } = {};

    for (const workflowTypeId of Object.keys(state.workflows.types.byId)) {
        variablePiecesForFields[workflowTypeId] = {};
        const workflowType = state.workflows.types.byId[workflowTypeId];

        for (const customFieldId of workflowType.customFields) {
            const customField = state.workflows.types.customFields.byId[customFieldId];

            if (!customField.isComputed || !customField.startPiece) {
                continue;
            }

            const piecesInFlowchart = getAllPiecesInPiece(state.flowchart.pieces, customField.startPiece.piece);
            const variablePiecesInFlowchart: Array<IVariablePiece> = piecesInFlowchart.filter(piece => piece.type === PieceType.VARIABLE) as Array<IVariablePiece>;
            variablePiecesForFields[workflowTypeId][customFieldId] = variablePiecesInFlowchart;
        }
    }

    const bulkRecalculatePayload: Array<ComputedFieldUpdatePayloadForWorkflow> = action.payload.map(changeDelta => {
        const workflow = state.workflows.byId[changeDelta.workflowId];
        const workflowType = state.workflows.types.byId[workflow.type];

        const customFieldIdsToUpdate = workflowType.customFields.filter(customFieldId => {
            const customField = state.workflows.types.customFields.byId[customFieldId];

            if (!customField.isComputed || !customField.startPiece) {
                return false;
            }

            const variablePiecesInFlowchart = variablePiecesForFields[workflowType.id][customFieldId];
            return shouldUpdateCustomField(customField, changeDelta, variablePiecesInFlowchart);
        });

        const customFieldData: WorkflowTypeCustomFieldDataHolder = {};

        for (const customFieldId of customFieldIdsToUpdate) {
            const customField = state.workflows.types.customFields.byId[customFieldId];
            const fieldValue = getValueForComputedField(customField, changeDelta.workflowId, 'workflow', state);
            const currentProcessState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];

            if (fieldValue !== currentProcessState.customFields[customFieldId]) {
                customFieldData[customFieldId] = fieldValue;
            }
        }

        return {
            workflowId: changeDelta.workflowId,
            customFieldData,
        };
    });

    const filteredBulkRecalculatePayload = bulkRecalculatePayload.filter(payload => Object.keys(payload.customFieldData).length > 0);

    if (filteredBulkRecalculatePayload.length > 0) {
        yield put(bulkUpdateWorkflowComputedFieldData(bulkRecalculatePayload));
    }

}

function* recalculateAllWorkflowCustomFieldsData() {
    const state: ApplicationState = yield select();

    const changeDeltas: Array<ChangeDeltaForWorkflowComputedFields> = Object.keys(state.workflows.byId).map(workflowId => {
        return {
            workflowId,
            workflowChanged: true,
            usersChanged: false,
            affiliationChanged: false,
        }
    })

    yield put(bulkRecalculateComputedFieldsForWorkflow(changeDeltas));
}

function* provideReverseLinksForNewStatus(action: AddStatusAction) {
    yield put(addStatusToWorkflowType(action.payload.id, action.parentId));
}

function* removeReverseLinksForStatus(action: DeleteStatusAction) {
    yield put(removeStatusFromWorkflowType(action.id, action.parentId));
}

function* provideReverseLinkForAffiliation(action: AddWorkflowAction | UpdateWorkflowAction) {
    const state: ApplicationState = yield select();
    const workflowType = state.workflows.types.byId[action.payload.type];

    yield (put(updateUserWorkflows(action.payload.user, action.payload.type, action.payload.id)));

    yield put(recalculateComputedFieldsForUser(action.payload.user, false, false, [action.payload.type]));

    if (workflowType.affiliation === 'member') {
        const member = state.members.byId[action.payload.affiliatedEntity];
        yield (put(addWorkflowToMember(member.id, workflowType.id, action.payload.id)));
        yield put(recalculateComputedFieldsForMember(action.payload.affiliatedEntity, false, false, [], [action.payload.type]));
    } else if (workflowType.affiliation === 'group') {
        const group = state.groups.byId[action.payload.affiliatedEntity];
        yield (put(addWorkflowToGroup(group.id, workflowType.id, action.payload.id)));
        yield put(recalculateComputedFieldsForGroup(action.payload.affiliatedEntity, false, false, false, [action.payload.type]));
    }
}

function fetchWorkflowPageData(pageSize: number, currentPageNumber: number) {

    const serverUrl = new URL('/online-workflows/', BASE_URL);

    serverUrl.searchParams.set('pageSize', String(pageSize));
    serverUrl.searchParams.set('currentPageNumber', String(currentPageNumber));

    return axios.get<PageDataForOnlineEntities>(serverUrl.toString(), {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

function fetchWorkflowQueryData(filters: WorkflowFilters, searchTerm: string, pageSize: number) {

    const serverUrl = new URL('/online-workflow-filters/', BASE_URL);

    const queryData: OnlineWorkflowQueryData = {
        filters,
        searchTerm,
        pageSize,
    }

    return axios.post(serverUrl.toString(), queryData, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });
}

function* getNewPageDataForWorkflow() {
    yield delay(200);

    const myId: string = yield select(state => state.myData.id);
    const user: IUser | undefined = isUUID(myId) ? yield select(state => state.users.byId[myId]) : undefined;

    if (user && user.isOnline) {
        const pageSize: number = yield select((state: ApplicationState) => state.workflows.pageSize);
        const currentPageNumber: number = yield select((state: ApplicationState) => state.workflows.currentPageNumber);

        yield all([
            put(clearMemberEntries()),
            put(clearGroupEntries()),
            put(clearWorkflowEntries()),
        ]);

        const pageResponseData: AxiosResponse<PageDataForOnlineEntities> = yield call(fetchWorkflowPageData, pageSize, currentPageNumber);

        if (pageResponseData.status === 200) {
            yield all([
                put(appendMembers(pageResponseData.data.members)),
                put(appendGroups(pageResponseData.data.groups)),
                put(appendWorkflows(pageResponseData.data.workflows)),
                put(setLastRefreshTime(pageResponseData.data.syncTime)),
            ]);
        }
    }
}

function* getFilteredDataForWorkflow() {

    yield delay(1000);

    const myId: string = yield select(state => state.myData.id);
    const user: IUser | undefined = isUUID(myId) ? yield select(state => state.users.byId[myId]) : undefined;

    if (user && user.isOnline) {
        const workflowFilters: WorkflowFilters = yield select((state: ApplicationState) => state.workflows.filters);
        const workflowSearchTerm: string = yield select((state: ApplicationState) => state.workflows.searchTerm);
        const pageSize: number = yield select((state: ApplicationState) => state.workflows.pageSize);

        yield all([
            put(clearMemberEntries()),
            put(clearGroupEntries()),
            put(clearWorkflowEntries()),
            put(setInfoMessage('Applying workflow search/filters')),
        ]);

        const pageResponseData: AxiosResponse<FilterResponseForOnlineEntities> = yield call(fetchWorkflowQueryData, workflowFilters, workflowSearchTerm, pageSize);

        if (pageResponseData.status === 200) {
            yield all([
                put(appendMembers(pageResponseData.data.pageData.members)),
                put(appendGroups(pageResponseData.data.pageData.groups)),
                put(appendWorkflows(pageResponseData.data.pageData.workflows)),
                put(setTotalNumberOfWorkflows(pageResponseData.data.totalNumber)),
                put(setLastRefreshTime(pageResponseData.data.pageData.syncTime)),
                put(clearInfoMessage()),
            ]);
        }
    }
}

export function* initializeNextBetaFlowchart(action: PublishFlowchartToLiveAction) {
    const startPieceId = uuid.v4();
    yield put(addPiece(startPieceId, PieceType.START));
    yield put(updateWorkflowTypeBetaStartPiece({
        piece: startPieceId,
        position: {
            x: 0,
            y: 0,
        },
    }, action.workflowTypeId));
}

export function* watchWorkflowChanges() {
    yield takeEvery([ADD_WORKFLOW, UPDATE_WORKFLOW], provideReverseLinkForAffiliation);
}

export function* watchWorkflowCreationChanges() {
    yield takeEvery(ADD_WORKFLOW, indexAndProvideReverseLinksForNewWorkflow);
}

export function* watchWorkflowExecutionChanges() {
    yield takeEvery([
        UPDATE_WORKFLOW_STATUS,
        UPDATE_WORKFLOW_DUE_DATE,
        ADD_TO_WORKFLOW_HISTORY,
        UPDATE_WORKFLOW_PROCESS_STATE,
        TRANSFER_WORKFLOW,
    ], reIndexWorkflowOnExecution)
}

export function* watchWorkflowDeletionChanges() {
    yield takeEvery(DELETE_WORKFLOW, removeReverseLinksForWorkflow);
}

export function* watchWorkflowUnarchiveChanges() {
    yield takeEvery(UN_ARCHIVE_WORKFLOW, provideReverseLinksForExistingWorkflow);
}

export function* watchStatusCreationChanges() {
    yield takeEvery(ADD_STATUS, provideReverseLinksForNewStatus);
}

export function* watchStatusDeletionChanges() {
    yield takeEvery(DELETE_STATUS, removeReverseLinksForStatus);
}

export function* watchWorkflowTypeCreation() {
    yield takeEvery(ADD_WORKFLOW_TYPE, createSeedFlowchartForWorkflowType);
}

export function* watchWorkflowTypeCustomFieldCreation() {
    yield takeEvery([ADD_WORKFLOW_TYPE_CUSTOM_FIELD, UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD], createSeedFlowchartForWorkflowTypeCustomField);
}

export function* watchWorkflowCustomFieldRecalculation() {
    yield takeEvery(RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW, recalculateComputedFieldsDataForWorkflow);
}

export function* watchBulkWorkflowCustomFieldRecalculation() {
    yield takeEvery(BULK_RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW, bulkRecalculateComputedFieldsDataForWorkflow);
}

export function* watchRecomputeAllWorkflows() {
    yield takeEvery(RECOMPUTE_ALL_WORKFLOWS, recalculateAllWorkflowCustomFieldsData);
}

export function* watchWorkflowPagePageChanges() {
    yield takeLatest([GO_TO_PAGE_WORKFLOW_TABLE, SET_PAGE_SIZE_WORKFLOW_TABLE], getNewPageDataForWorkflow);
}

export function* watchWorkflowQueryChangesForOnline() {
    yield takeLatest([SEARCH_WORKFLOW_TABLE, FILTER_WORKFLOW_TABLE], getFilteredDataForWorkflow)
}

export function* watchWorkflowTypeFlowchartPublish() {
    yield takeLatest(PUBLISH_FLOWCHART_TO_LIVE, initializeNextBetaFlowchart);
}
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PieceType } from 'src/shared/store/flowchart/pieces/types';
import { isUUID } from 'src/shared/helpers/utilities';
import { WorkflowProcessState, IWorkflow } from 'src/shared/store/workflows/types';
import { getWorkflowPieceValue } from 'src/shared/store/flowchart/helpers/workflow';
import { select, NgRedux } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { ApplicationState } from 'src/shared/store/types';
import { first, debounceTime } from 'rxjs/operators';
import store from 'src/shared/store/main';

@Component({
  selector: 'app-continue',
  templateUrl: './continue.component.html',
  styleUrls: ['./continue.component.scss'],
})
export class ContinueComponent implements OnInit, OnDestroy {
  @Input() workflowId: string;
  @Input() continuePieceId: string;
  continueText = '';


  constructor(private ngRedux: NgRedux<ApplicationState>,) { }

  getContinueText() {
    const applicationState = store.getState();
    const continuePiece = applicationState.flowchart.pieces.byId[this.continuePieceId];

    if (continuePiece.type !== PieceType.CONTINUE) {
      throw new Error('This must be a continue piece');
    }

    let message = continuePiece.message;

    if (typeof continuePiece.message === 'string' && isUUID(continuePiece.message)) {

      const workflow = applicationState.workflows.byId[this.workflowId];
      const workflowProcessState = workflow.history[workflow.historyIndex];
  
      const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
        customFields: workflowProcessState.customFields,
        lastComputedPiece: workflowProcessState.displayingShowPieceId,
        executionStack: workflowProcessState.executionStack,
        forIterationCounts: workflowProcessState.forIterationCounts,
        variables: workflowProcessState.variables,
        displayingQuestionPieceId: workflowProcessState.displayingQuestionPieceId,
        displayingShowPieceId: workflowProcessState.displayingShowPieceId,
        displayingGroupPieceId: workflowProcessState.displayingGroupPieceId,
        displayingTransferPieceId: workflowProcessState.displayingTransferPieceId,
        createdWorkflowId: workflowProcessState.createdWorkflowId,
      }));

      const messageValue = getWorkflowPieceValue(applicationState, processState, this.workflowId, continuePiece.message);
      message = String(messageValue);

    }

    this.continueText = message;
  }

  workflowSubscription: Subscription;

  ngOnInit() {
    const workflowSource: Observable<IWorkflow> = this.ngRedux.select(['workflows', 'byId', this.workflowId]);

		if (this.workflowSubscription) {
			this.workflowSubscription.unsubscribe();
    }

		this.workflowSubscription = workflowSource.subscribe(workflow => {
			this.getContinueText();
		});
  }

  ngOnDestroy() {

		if (this.workflowSubscription) {
			this.workflowSubscription.unsubscribe();
    }
  }

}

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { IWorkflow, IUpdateableWorkflowData, WorkflowState } from '../../shared/store/workflows/types';
import { IWorkflowType } from '../../shared/store/workflows/types/types';
import { IStatus } from '../../shared/store/workflows/types/statuses/types';
import { IUser } from '../../shared/store/users/types';
import { addWorkflow } from '../../shared/store/workflows/actions';
import { GroupState } from 'src/shared/store/groups/types';
import { MemberState } from '../../shared/store/members/types';
import { WorkflowExecutorPage } from '../workflow-executor/workflow-executor.page';
import { ModalController, NavParams } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { NgRedux } from '@angular-redux/store';
import { ApplicationState } from '../../shared/store/types';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { getReadableDataForCustomField } from 'src/shared/store/custom-fields';
import { CustomFieldValueType } from 'src/shared/store/custom-fields/types';
import { DefaultFlowchartProcessState } from 'src/shared/store/flowchart/types';
import { getMemberComputedFieldValue } from 'src/shared/store/flowchart/helpers/custom-fields/member';
import { translatePhrase } from 'src/shared/helpers/translation';
import { CalendarPage } from '../calendar/calendar.page';
import { ToastService } from '../services/toast.service';
import { PermissionSet, Permissions } from 'src/shared/store/permissions/types';
import { getVisibleMemberIds, getVisibleGroupIds } from 'src/shared/helpers/visible-entities';

import * as moment from 'moment';
import { getAllPiecesInPiece } from 'src/shared/store/flowchart/helpers/pieces';
import { PieceType } from 'src/shared/store/flowchart/pieces/types';
import { VariableValueType } from 'src/shared/helpers/common-types';

import { ToastType } from '../services/toast.service';

@Component({
    selector: 'app-add-workflow',
    templateUrl: './add-workflow.page.html',
    styleUrls: ['./add-workflow.page.scss'],
})
export class AddWorkflowPage implements OnInit, OnDestroy {
    @select(state => state) applicationStateSource: Observable<ApplicationState>;
    my_id: string;
    workflowsData: WorkflowState;
    groupsData: GroupState;
    workflow_types: Array<IWorkflowType> = [];
    workflow_statuses: Array<IStatus> = [];
    allowed_statuses: Array<IStatus> = [];
    new_workflow: IUpdateableWorkflowData;
    users: Array<IUser> = [];
    save_state: string = 'inactive';
    selected_affiliation: IWorkflowType;
    all_members: Array<{
        id: string,
        type: string,
        name: string,
    }> = [];
    selected_affiliated_entity: {
        id: string,
        type: string,
        name: string,
    } = {
            id: '',
            type: '',
            name: ''
        };
    all_groups: Array<{
        id: string,
        type: string,
        name: string,
    }> = [];
    membersData: MemberState;
    applicationState: ApplicationState;
    applicationStateSubscription: Subscription;
    selectAffiliationModal: boolean = false;
    affiliationSearchTerm: string = '';
    isAffiliationError: boolean = false;
    isAffiliationDateError: boolean = false;
    userPermission: PermissionSet;
    showing_member_list: { id: string; type: string; name: string; }[] = [];

    PAGE_SIZE: number = 20;
    showing_groups: { id: string; type: string; name: string; }[] = [];

    defaultDueDate: string;
    tempDateInput: string;
    invalidDate: boolean = false;

    constructor(
        private ngRedux: NgRedux<ApplicationState>,
        private modalController: ModalController,
        public alertController: AlertController,
        public navParams: NavParams,
        private toastService: ToastService
    ) { }

    translate(phrase: string) {
        return translatePhrase(phrase.trim());
    }

    getFormattedDate(date: any, format: string) {
        return moment(date).format(format);
    }

    getWorkflowTypePermission(id: string) {
        if (this.userPermission.workflows && this.userPermission.workflows[id]) {
            return this.userPermission.workflows[id];
        } else {
            return Permissions.WRITE;
        }
    }

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        // visible height + pixel scrolled >= total height 
        if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 100)) {
            if (this.selected_affiliation.affiliation === 'member') {
                this.showMoreCards('member');
            }

            if (this.selected_affiliation.affiliation === 'group') {
                this.showMoreCards('group');
            }
        }
    }

    ngOnInit() {
        this.new_workflow = {
            id: uuid(),
            type: undefined,
            status: undefined,
            dueDate: '',
            user: undefined,
            affiliatedEntity: undefined,
        };

        if (this.navParams.get('workflow_type_id')) {
            this.new_workflow.type = this.navParams.get('workflow_type_id');
        }

        this.applicationStateSubscription = this.applicationStateSource.subscribe(applicationState => {
            this.applicationState = applicationState;

            this.my_id = this.applicationState.myData.id;
            this.new_workflow.user = this.my_id;

            this.userPermission = this.applicationState.permissions.myPermissions;

            this.workflowsData = this.applicationState.workflows;

            if (this.new_workflow.type) {
                this.checkIfAffiliated();
            }

            if (this.navParams.get('selected_affiliated_entity')) {
                this.selected_affiliated_entity = JSON.parse(this.navParams.get('selected_affiliated_entity'));
            }

            this.calculateGroups();
            this.calculateMembers();


        });
    }

    getWorkflowType(typeId: string) {
        return this.applicationState.workflows.types.byId[typeId];
    }

    calculateMembers() {
        const visibleMembers = getVisibleMemberIds(this.applicationState);

        // Populating Member List & Types
        this.membersData = this.applicationState.members;

        this.all_members = visibleMembers.map(memberId => {
            const member = this.membersData.byId[memberId];

            return {
                id: member.id,
                type: member.type,
                name: this.getMemberName(member.id),
            };
        });

        this.all_members = this.all_members.filter((member) => this.getMemberTypePermission(member.type) !== 'NONE');

        const workflowType = this.getWorkflowType(this.new_workflow.type);

        if (workflowType.affiliatedEntity) {
            this.all_members = this.all_members.filter(member => member.type === workflowType.affiliatedEntity);
        }

        this.showing_member_list = [];

        this.showing_member_list = this.all_members.slice(0, this.showing_member_list.length + this.PAGE_SIZE);
    }

    showMoreCards(type: 'user' | 'member' | 'group' | 'workflow') {
        console.log("Called");

        switch (type) {
            case 'member':
                if (this.showing_member_list.length >= this.all_members.length) {
                    return;
                }

                this.showing_member_list = this.all_members.slice(0, this.showing_member_list.length + this.PAGE_SIZE);
                break;
            case 'group':
                if (this.showing_groups.length >= this.all_groups.length) {
                    return;
                }

                this.showing_groups = this.all_groups.slice(0, this.showing_groups.length + this.PAGE_SIZE);
                break;
            default:
                break;
        }
    }

    getMemberTypePermission(id: string) {
        if (this.userPermission.members && this.userPermission.members[id]) {
            return this.userPermission.members[id];
        } else {
            return Permissions.WRITE;
        }
    }

    calculateGroups() {
        const visibleGroups = getVisibleGroupIds(this.applicationState);

        this.groupsData = this.applicationState.groups;

        this.all_groups = visibleGroups.map((groupId) => {
            const group = this.groupsData.byId[groupId];

            return {
                id: group.id,
                type: group.type,
                name: this.getGroupName(group.id)
            }
        });

        this.all_groups = this.all_groups.filter((group) => this.getGroupTypePermission(group.type) !== 'NONE');

        const workflowType = this.getWorkflowType(this.new_workflow.type);

        if (workflowType.affiliatedEntity) {
            this.all_groups = this.all_groups.filter(group => group.type === workflowType.affiliatedEntity);
        }

        this.showing_groups = [];

        this.showing_groups = this.all_groups.slice(0, this.showing_groups.length + this.PAGE_SIZE);
    }

    getGroupTypePermission(id: string) {
        if (this.userPermission.groups && this.userPermission.groups[id]) {
            return this.userPermission.groups[id];
        } else {
            return Permissions.WRITE;
        }
    }

    searchAffiliationList() {
        if (this.selected_affiliation.affiliation === 'member') {
            this.calculateMembers();

            this.all_members = this.all_members.filter((member) => {
                return member.name.toLowerCase().includes(this.affiliationSearchTerm.toLowerCase());
            });


            this.showing_member_list = this.all_members.slice(0, this.showing_member_list.length + this.PAGE_SIZE);
        }

        if (this.selected_affiliation.affiliation === 'group') {
            this.calculateGroups();

            this.all_groups = this.all_groups.filter((group) => {
                return group.name.toLowerCase().includes(this.affiliationSearchTerm.toLowerCase());
            });

            this.showing_groups = this.all_groups.slice(0, this.showing_groups.length + this.PAGE_SIZE);
        }
    }

    getMemberName(memberId: string) {
        try {
            const member = this.membersData.byId[memberId];
            const memberType = this.membersData.types.byId[member.type];
            let memberName: VariableValueType;

            const nameField = this.membersData.types.customFields.byId[memberType.nameFieldId];

            if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
                const processState: DefaultFlowchartProcessState = {
                    displayingContinuePieceId: undefined,
                    displayingAddWorkflowPieceId: undefined,
                    customFields: { ...member.customFields },
                    variables: {
                        [nameField.seedEntityVariable]: member.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                };

                memberName = getMemberComputedFieldValue(this.applicationState, processState, nameField.startPiece.piece, member.id, nameField);
            } else {
                memberName = member.customFields[memberType.nameFieldId];
            }

            if (Array.isArray(memberName)) {

                if (memberName.length > 0 && Array.isArray(memberName[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                memberName = memberName as Array<string>;
            }

            memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

            return memberName;
        } catch {
            return '-';
        }
    }

    getWorkflowStatus(id: string) {
        return this.allowed_statuses.find(status => status.id === id);
    }

    getGroupName(groupId: string) {
        try {
            const group = this.groupsData.byId[groupId];
            const groupType = this.groupsData.types.byId[group.type];
            let groupName: CustomFieldValueType;
            const nameField = this.groupsData.types.customFields.byId[groupType.nameFieldId];

            groupName = group.customFields[groupType.nameFieldId];

            groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');

            return groupName;
        } catch {
            return '-';
        }
    }


    checkIfAffiliated() {
        setTimeout(() => {
            this.allowed_statuses = this.workflowsData.types.byId[this.new_workflow.type].statuses.map(statusId => this.workflowsData.types.statuses.byId[statusId]);

            if (this.allowed_statuses.length > 0) {
                this.new_workflow.status = this.allowed_statuses.filter((status) => {
                    return !status.isTerminal;
                })[0].id;

                let dueDays = this.allowed_statuses.filter((status) => {
                    return !status.isTerminal;
                })[0].dueInDays;

                this.new_workflow.dueDate = moment(new Date().setDate(new Date().getDate() + dueDays)).format('YYYY-MM-DD');
                this.defaultDueDate = moment(new Date().setDate(new Date().getDate() + dueDays)).format('YYYY-MM-DD');
            }

            this.selected_affiliation = this.workflowsData.types.byId[this.new_workflow.type];

            if (this.selected_affiliation.affiliation === 'group') {
                this.all_groups = this.all_groups.filter((group) => {
                    return this.applicationState.groups.byId[group.id].type.indexOf(this.selected_affiliation.affiliatedEntity) > -1;
                });
            }

            if (this.selected_affiliation.affiliation === 'none') {
                this.new_workflow.affiliatedEntity = null;
            }

        }, 10);
    }

    setNewDate() {
        setTimeout(() => {
            let dueDays = this.allowed_statuses.filter((status) => {
                return !status.isTerminal;
            })[0].dueInDays;

            this.new_workflow.dueDate = moment(new Date().setDate(new Date().getDate() + dueDays)).format('YYYY-MM-DD');
        }, 10);
    }

    back() {
        this.modalController.dismiss();
    }

    isWorkflowTerminal(status: string) {
        return this.workflowsData.types.statuses.byId[status].isTerminal;
    }

    save() {
        if (this.getWorkflowTypePermission(this.new_workflow.type) === 'WRITE') {
            if (this.selected_affiliation.affiliation === 'member' ||
                this.selected_affiliation.affiliation === 'group') {
                let typeWorkflowIds: Array<string>, typeWorkflows: Array<IWorkflow>,
                    isWorkflowPresent: boolean = false;
                typeWorkflowIds = this.applicationState.workflows.activeWorkflowEntries.filter(activeEntryId => {
                    const workflow = this.applicationState.workflows.byId[activeEntryId];
                    return workflow && workflow.type === this.new_workflow.type;
                });

                if (typeWorkflowIds) {
                    typeWorkflows = typeWorkflowIds.map(id => {
                        return this.workflowsData.byId[id];
                    });

                    typeWorkflows.forEach(workflow => {
                        if (workflow &&
                            workflow.affiliatedEntity === this.selected_affiliated_entity.id
                            && !this.isWorkflowTerminal(workflow.status)
                            && !isWorkflowPresent
                            && !workflow.archived) {
                            isWorkflowPresent = true;

                            const workflowType = this.getWorkflowType(this.new_workflow.type);
                            if (workflowType.areMultipleInstancesAllowed) {
                                this.startWorkflowConfirmation();
                            } else {
                                if (workflow.user === this.my_id) {
                                    this.resumeWorkflow(workflow.id);
                                } else {
                                    this.showAlertForUnavailableWorkflow(workflow.id);
                                }
                            }
                        }
                    });
                }

                if (!typeWorkflowIds || !isWorkflowPresent) {
                    this.startWorkflowConfirmation();
                }
            } else if (this.getWorkflowType(this.new_workflow.type).areMultipleInstancesAllowed) {
                this.startWorkflowConfirmation();
            } else {
                let typeWorkflowIds: Array<string>, typeWorkflows: Array<IWorkflow>,
                    isWorkflowPresent: boolean = false;
                typeWorkflowIds = this.applicationState.users.byId[this.my_id].workflows[this.new_workflow.type];

                if (typeWorkflowIds) {
                    typeWorkflows = typeWorkflowIds.map(id => {
                        return this.workflowsData.byId[id];
                    });

                    typeWorkflows.forEach(workflow => {
                        if (workflow &&
                            !this.isWorkflowTerminal(workflow.status)
                            && !isWorkflowPresent
                            && workflow.user === this.my_id
                            && !workflow.archived) {
                            isWorkflowPresent = true;
                            this.resumeWorkflow(workflow.id);
                        }
                    });

                    if (!isWorkflowPresent) {
                        this.startWorkflowConfirmation();
                    }

                } else {
                    this.startWorkflowConfirmation();
                }
            }
        } else {
            this.toastService.presentToastWithOptions("You do not have permission to execute this workflow", ToastType.WARNING);
        }
    }

    async showAlertForUnavailableWorkflow(id: string) {
        const alert = await this.alertController.create({
            header: `${this.translate('Wait')}!`,
            message: this.translate('Another user already has a workflow of this type open for this member/group. View data?'),
            mode: 'ios',
            buttons: [
                {
                    text: this.translate('Cancel'),
                    role: 'cancel',
                    cssClass: 'alert-danger',
                }, {
                    text: this.translate('Okay'),
                    cssClass: 'alert-confirm',
                    handler: () => {
                        this.executeWorkflow(id, true);
                        this.back();
                    }
                }
            ]
        });

        await alert.present();
    }

    async resumeWorkflow(id: string) {
        const alert = await this.alertController.create({
            header: `${this.translate('Wait')}!`,
            message: this.translate('Seems like there is already a workflow existing... Would you like to resume it?'),
            mode: 'ios',
            buttons: [
                {
                    text: this.translate('Cancel'),
                    role: 'cancel',
                    cssClass: 'alert-danger',
                }, {
                    text: this.translate('Okay'),
                    cssClass: 'alert-confirm',
                    handler: () => {
                        this.executeWorkflow(id);
                        this.back();
                    }
                }
            ]
        });

        await alert.present();
    }

    async executeWorkflow(workflowId: string, isEnd?: boolean) {
        const workflow = this.workflowsData.byId[workflowId];
        const workflowType = this.workflowsData.types.byId[workflow.type];

        const allPiecesInWorkflow = getAllPiecesInPiece(this.applicationState.flowchart.pieces, workflowType.startPiece.piece);

        const getLocationPiece = allPiecesInWorkflow.find(piece => piece.type === PieceType.GET_CURRENT_LOCATION);

        const navigator = window.navigator as any;  // Hack because the earlier typescript has no type definitions for navigator

        if (!!getLocationPiece) {
            if (navigator.permissions) {
                const status = await navigator.permissions.query({ name: 'geolocation' });
                if (status.state === 'denied') {
                    this.toastService.presentToastWithOptions('You need location permissions to execute this workflow', ToastType.ERROR);
                    return;
                } else if (status.state === 'prompt') {
                    this.toastService.presentToastWithOptions('Please provide location permissions and try again', ToastType.ERROR);
                    navigator.geolocation.getCurrentPosition(() => { });
                    return;
                }
            }
        }

        const modal = await this.modalController.create({
            component: WorkflowExecutorPage,
            componentProps: {
                workflow_id: workflowId,
                isEnd: !!isEnd,
            }
        });

        return await modal.present();
    }

    startWorkflowConfirmation() {
        if (this.selected_affiliated_entity) {
            this.new_workflow.affiliatedEntity = this.selected_affiliated_entity.id;
        }

        if (this.invalidDate) {
            this.isAffiliationDateError = true;
            this.toastService.presentToastWithOptions("Invalid Date, Format: DD-MM-YYYY", ToastType.ERROR);

            setTimeout(() => {
                this.isAffiliationDateError = false;
            }, 3000);
        } else if (this.selected_affiliation.affiliation === 'group' && !this.new_workflow.affiliatedEntity) {
            this.isAffiliationError = true;
            this.toastService.presentToastWithOptions("Please Select Group", ToastType.ERROR);

            setTimeout(() => {
                this.isAffiliationError = false;
            }, 3000);
        } else if (this.selected_affiliation.affiliation === 'member' && !this.new_workflow.affiliatedEntity) {
            this.isAffiliationError = true;
            this.toastService.presentToastWithOptions("Please Select Member", ToastType.ERROR);

            setTimeout(() => {
                this.isAffiliationError = false;
            }, 3000);
        } else {
            this.save_state = 'initiated';
            this.ngRedux.dispatch(addWorkflow(this.new_workflow));
            this.save_state = 'saved';
            this.executeWorkflow(this.new_workflow.id);
            this.back();
        }
    }

    ngOnDestroy() {
        if (this.applicationStateSubscription) {
            this.applicationStateSubscription.unsubscribe();
        }
    }


    async showCalendar(isDate: boolean, isTime: boolean, preSelectedDate?: string) {
        const modal = await this.modalController.create({
            component: CalendarPage,
            componentProps: {
                isDate: isDate,
                isTime: isTime,
                preSelectedDate: preSelectedDate ? preSelectedDate : ''
            }
        });

        modal.onDidDismiss().then((data) => {
            if (data.data) {
                this.new_workflow.dueDate = data.data.newDate;
            }
        });

        return await modal.present();
    }

    getDateInput(tempDateInput: any) {
        if (!moment(tempDateInput, 'DD-MM-YYYY', true).isValid()) {
            this.toastService.presentToastWithOptions("Invalid Date, Format: DD-MM-YYYY", ToastType.ERROR);
            this.invalidDate = true;
        } else {
            this.new_workflow.dueDate = moment(tempDateInput, 'DD-MM-YYYY').format('YYYY-MM-DD');
            this.invalidDate = false;
        }
    }

    getMemberSubtitle(memberId: string) {
        try {
            const member = this.membersData.byId[memberId];
            const memberType = this.membersData.types.byId[member.type];

            const subTitleField = this.membersData.types.customFields.byId[memberType.subTitleFieldId];
            const memberSubtitle = getReadableDataForCustomField(member.customFields[subTitleField.id], subTitleField, memberId, 'member', this.applicationState);
            return memberSubtitle;
        } catch (e) {
            // console.error(e);
            return '-';
        }
    }

    getGroupSubtitle(groupId: string) {
        try {
            const group = this.groupsData.byId[groupId];
            const groupType = this.groupsData.types.byId[group.type];

            const subTitleField = this.groupsData.types.customFields.byId[groupType.subTitleFieldId];
            const groupSubtitle = getReadableDataForCustomField(group.customFields[subTitleField.id], subTitleField, groupId, 'group', this.applicationState);
            return groupSubtitle;
        } catch (e) {
            console.error(e);
            return '-';
        }
    }

}
